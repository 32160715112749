import React from "react";

// Customizable Area Start
import {  Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Paper, Tooltip, TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import MaterialUIBox from '@material-ui/core/Box';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import DoubleLineChart from "../../../components/src/Charts/LineChart.web";
import SearchIcon from '@material-ui/icons/Search';

// Customizable Area End

import AdminReportController, {
  Props,
  WatchedUser,
  configJSON
} from "./AdminReportController.web";
import { NotFoundImage } from "./assets";
import CustomDatePicker from "../../../components/src/DatePicker";

type CourseVideoTableData =  {
  count: number
  id: number
  title: string
  total_time_watched: number
  watched_user: WatchedUser[],
  
  time_spent: number
}
interface TableProps { 
  title: string; 
  columns: string[]
  rows: CourseVideoTableData[];
  tableType: string;
}

export default class AdminReport extends AdminReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGroupAssigneeTextFieldBox = () => {
    return (
      <>
        <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
          <Typography className="labelStyle" >{configJSON.groupField}:</Typography>
          <StyledDropdownClickBoxs
            onClick={this.toggleGroupdropdown}
            data-test-id="select_group"
            style={{...webStyle.dropDown, backgroundColor: this.state.selectedGroup.value ? "#fff" : "#FCF8F8"}}
          >
            <MaterialUIBox className="contentContainer content_role">
              <MaterialUIBox className="innerContentBox"
                style={{
                  color: this.state.selectedGroup.value ? '#1C1B1B' : '#ADAAAA',
                  background: this.state.selectedGroup.value ? "#fff" : "#FCF8F8", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                }}>
                {this.state.selectedGroup.name || 'Select from list'}
              </MaterialUIBox>
            </MaterialUIBox>
            {this.state.groupDropdown ? <ExpandLess /> : <ExpandMore />}
          </StyledDropdownClickBoxs>
          {this.state.groupDropdown && (
            <div ref={this.dropdownRef}>
              <DropdownGroupContent>
                <MaterialUIBox className="checkboxList">
                  <RadioGroup
                    data-test-id="radioGroup"
                    aria-labelledby="group-selection"
                    name="group"
                    value={this.state.selectedGroup.value}
                    onChange={this.handleGroupChange}
                  >
                    <FormControlLabel name="Users" className={"checkboxContent"} value="user" control={<Radio style={webStyle.dropDownLableName} />} label="Users" />
                    <FormControlLabel name="Teams" className={"checkboxContent"} value="team" control={<Radio style={webStyle.dropDownLableName} />} label="Teams" />
                    <FormControlLabel name="Branches" className={"checkboxContent"} value="branch" control={<Radio style={webStyle.dropDownLableName} />} label="Branches" />
                  </RadioGroup>
                </MaterialUIBox>
              </DropdownGroupContent>
            </div>
          )}
        </FormControl>

        {this.renderNameDropDown()}

        {this.renderCustomDateRange()}
      </>
    );
  }

  renderNameDropDown = () => {
    return (
      <>
        <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
          <Typography className="labelStyle" >{configJSON.nameField}:</Typography>
          <StyledDropdownClickBoxs
            onClick={this.toggleNamedropdown}
            data-test-id="select_name"
            style={{ ...webStyle.dropDown, background: this.state.selectedName?.id ? '#fff' : "#FCF8F8" }}
          >
            <MaterialUIBox className="contentContainer content_role">
              <MaterialUIBox className="innerContentBox"
                style={{
                  color: this.state.selectedName?.id ? '#1C1B1B' : '#ADAAAA',
                  background: this.state.selectedName?.id ? '#fff' : "#FCF8F8", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                }}>
                {this.state.selectedName?.name || 'Select from list'}
              </MaterialUIBox>
            </MaterialUIBox>
            {this.state.nameDropdown ? <ExpandLess /> : <ExpandMore />}
          </StyledDropdownClickBoxs>
          {this.state.nameDropdown && (
            <div ref={this.dropdownRef}>
              <DropdownGroupContent style={{ maxHeight: "300px" }}>
                <TextFieldSearch
                  data-test-id="userBranchSearch"
                  variant="outlined"
                  placeholder="Search"
                  value={this.state.searchText}
                  onChange={this.handleSearchUser}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon />
                    ),
                  }}
                />
                <MaterialUIBox className="checkboxList">
                  <RadioGroup
                    data-test-id="radioName"
                    aria-labelledby="group-selection"
                    name="name"
                    value={this.state.selectedName?.id}
                    onChange={this.handleNameChange}
                  >
                    {this.state.nameListing.map((nameValue) => {
                      return (
                        <>
                          <FormControlLabel className={"checkboxContent"} name={nameValue.name} value={nameValue.id} control={<Radio style={webStyle.dropDownLableName} />} label={nameValue.name} />
                        </>
                      )
                    })
                    }
                  </RadioGroup>
                </MaterialUIBox>
              </DropdownGroupContent>
            </div>
          )}
        </FormControl>
      </>
    )
  }
  renderCustomDateRange = () => {
    return (
      <>
        <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
          <Typography className="labelStyle" >{configJSON.dateRangeField}:</Typography>
          <StyledDropdownClickBoxs
            onClick={this.toggleDateRangedropdown}
            data-test-id="select_datedropdown"
            style={webStyle.dropDown}
          >
            <MaterialUIBox className="contentContainer content_role">
              <MaterialUIBox className="innerContentBox"
                style={{
                  color: this.state.selectedDateDropdownValue.value ? '#1C1B1B' : '#ADAAAA',
                  background: '#fff', fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                }}>
                {this.state.selectedDateDropdownValue.name || 'Select from list'}
              </MaterialUIBox>
            </MaterialUIBox>
            {this.state.dateRangeDropdown ? <ExpandLess /> : <ExpandMore />}
          </StyledDropdownClickBoxs>
          {this.state.dateRangeDropdown && (
            <div ref={this.dropdownRef}>
              <DropdownGroupContent>
                <MaterialUIBox className="checkboxList">
                  <RadioGroup
                    data-test-id="radioDateSelection"
                    aria-labelledby="group-selection"
                    name="group"
                    value={this.state.selectedDateDropdownValue.value}
                    onChange={this.handleDateChange}
                  >
                    <FormControlLabel className={"checkboxContent"} name="Last 30 days" value="last_30_days" control={<Radio style={webStyle.dropDownLableName} />} label="Last 30 days" />
                    <FormControlLabel className={"checkboxContent"} name="This week" value="this_week" control={<Radio style={webStyle.dropDownLableName} />} label="This week" />
                    <FormControlLabel className={"checkboxContent"} name="Last week" value="last_week" control={<Radio style={webStyle.dropDownLableName} />} label="Last week" />
                    <FormControlLabel className={"checkboxContent"} name="This Month" value="this_month" control={<Radio style={webStyle.dropDownLableName} />} label="This Month" />
                    <FormControlLabel className={"checkboxContent"} name="Last Month" value="last_month" control={<Radio style={webStyle.dropDownLableName} />} label="Last Month" />
                    <FormControlLabel className={"checkboxContent"} name="YTD" value="ytd" control={<Radio style={webStyle.dropDownLableName} />} label="YTD" />
                    <FormControlLabel className={"checkboxContent"} name="Custom Range" value="custom_date_range" control={<Radio style={webStyle.dropDownLableName} />} label="Custom Range" />
                    {this.state.displayCustomRangeDateSelection ? this.renderStartDateEndDate() : ""}
                  </RadioGroup>
                </MaterialUIBox>
              </DropdownGroupContent>
            </div>
          )}
          <FormControlLabel onChange={this.togglePreviousPeriodDropdown} control={<StyledCheckbox checked={this.state.isComparePreviousPeriod} />} label="Compare to previous period" />
        </FormControl>
      </>
    )
  }
  
  renderStartDateEndDate = () => {
    return(
      <>
         <StyledBox>
        <Box>
          <Typography
            variant="subtitle2"
            style={webStyle.startEndDateLable}
          >
            Start Date
          </Typography>
          <CustomDatePicker
            placeHolder="Due Date"
            selectedDate={this.state.startDate}
            top="70px"
            data-test-id="start_date"
            left={window.innerWidth<600?"0px":"63px"} 
            handleChange={this.handleStartDate}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={webStyle.startEndDateLable}
          >
            Last Date
          </Typography>
          <CustomDatePicker
            placeHolder="Due Date"
            selectedDate={this.state.endDate}
            top="70px"
            data-test-id="end_date"
            left={window.innerWidth<600?"0px":"63px"} 
            handleChange={this.handleEndDate}
            minDate={this.getEndDateMinimumValue(this.state.startDate as Date)}
          />
        </Box>
      </StyledBox>
      </>
    )
  }
  
  displayTotalWatchUserInToolTip = (tableRow: CourseVideoTableData) => {
    return(
      <>
        <CustomTooltip
          title={
            <>
              <Typography variant="subtitle1" style={{ fontWeight: 600, paddingBottom: '8px' }}>
                Users
              </Typography>
              <TableContainer component={Paper} style={{ boxShadow: 'none', maxHeight: "260px", }}>
                <Table size="small" aria-label="users table">
                  <TableBody>
                    {tableRow.watched_user?.length ?
                      tableRow.watched_user.map((user, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: '4px 8px' }}>{user.name}</TableCell>
                        </TableRow>
                      ))
                      :
                      <TableRow>
                        <TableCell style={{ padding: '4px 8px' }}>-</TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
          arrow
          placement="right"
          interactive={true}
        >
          <TableCell
            style={{ textAlign: "center", padding: 0, margin: 0 }}
          >
            {tableRow?.count}
          </TableCell>
        </CustomTooltip>
      </>
    )
  }

  StyledTable = (props: TableProps ) => {

    const tableType = props.tableType;
    const title = props.title;
    const tableColumns = props.columns;
    let tableRows = props.rows as unknown as CourseVideoTableData[];
  
    return(   
    <Card variant="outlined" style={webStyle.tableWrapper}>
      <CardContent>
        <Typography variant="h6" style={webStyle.titleName} gutterBottom>{title}</Typography>
        {tableRows?.length ?
        <TableContainer style={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableColumns.map((column, index) => (
                  <TableCell key={column} style={{...webStyle.columnName, width: index === 0 ? "50%": "inherit", textAlign: index > 0 ? "center": "inherit" }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
              tableRows?.map((tableRow , index) => (
                <TableRow key={index}>
                  <TableCell>{tableRow.title}</TableCell>
                  <TableCell style={{textAlign: "center"}}>{tableType === "course" ? tableRow?.total_time_watched: tableRow?.time_spent}</TableCell>
                  {tableType === "course" ?
                  this.displayTotalWatchUserInToolTip(tableRow)
                  :
                  <TableCell 
                    style={{textAlign: "center", padding: 0 , margin: 0}}
                  >
                    {tableRow?.count}
                  </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        : 
        <Box style={{
          display: "flex" ,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "250px",
          gap: "10px",
      }}>
          <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID"/>
          <Typography style={webStyle.noDataFound}
          >{tableType ? "No courses taken yet": "No videos watched yet"}</Typography>
        </Box>
        }
      </CardContent>
    </Card>
    );
  };

  // Customizable Area End

  render() {
   
    const MostWatchedCourses = this.state.adminReportData?.most_watched_course;
    const MostWatchedVideos = this.state.adminReportData?.most_watched_video;
    const timeSpentInTraining = this.state.adminReportData?.time_spent_in_training;
    const videoPerDay = this.state.adminReportData?.video_watched;
    const newCourseStarted = this.state.adminReportData?.course_started;
    const newProgramStarted = this.state.adminReportData?.program_started;
    const pastPreviousLable = this.displayPastPreviousLableName(this.state.selectedDateDropdownValue.value);
    return (
      // Customizable Area Start
      <>
      <AdminReportWrapper>
      <Box sx={gridStyle}>
        {this.renderGroupAssigneeTextFieldBox()}
      </Box>

        <StyleTableWrapper>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <DoubleLineChart 
                chartTitle={"Time spent in training"}
                discription={""}
                currentData={timeSpentInTraining?.current_data} 
                previousData={timeSpentInTraining?.previous_data}
                currentTotal={timeSpentInTraining?.current_data_total}
                previousTotal={timeSpentInTraining?.previous_data_total}
                displayPreviousData={this.state.isComparePreviousPeriod}
                pastPreviousLable={pastPreviousLable}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DoubleLineChart 
                chartTitle={"# of Videos per Day"}
                discription={"videos"}
                currentData={videoPerDay?.current_data} 
                previousData={videoPerDay?.previous_data}
                currentTotal={videoPerDay?.current_data_total}
                previousTotal={videoPerDay?.previous_data_total}
                displayPreviousData={this.state.isComparePreviousPeriod}
                pastPreviousLable={pastPreviousLable}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
            <DoubleLineChart 
                chartTitle={"New Courses Started"}
                discription={"courses"}
                currentData={newCourseStarted?.current_data} 
                previousData={newCourseStarted?.previous_data}
                currentTotal={newCourseStarted?.current_data_total}
                previousTotal={newCourseStarted?.previous_data_total}
                displayPreviousData={this.state.isComparePreviousPeriod}
                pastPreviousLable={pastPreviousLable}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DoubleLineChart 
                chartTitle={"New Program Started"}
                discription={"programs"}
                currentData={newProgramStarted?.current_data} 
                previousData={newProgramStarted?.previous_data}
                currentTotal={newProgramStarted?.current_data_total}
                previousTotal={newProgramStarted?.previous_data_total}
                displayPreviousData={this.state.isComparePreviousPeriod}
                pastPreviousLable={pastPreviousLable}
              />
            </Grid>
          </Grid>
        </StyleTableWrapper>

      <StyleTableWrapper>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
          {this.StyledTable(
              {
                title:"Most Taken Courses",
                columns:['Course', 'Total Times Watched', '# of Users Watched'],
                rows:MostWatchedCourses  as unknown as CourseVideoTableData[],
                tableType:"course"
              }
          )}
          </Grid>
          <Grid item md={6} xs={12}>
            {this.StyledTable(
              {
                title:"Most Watched Videos",
                columns:['Video', 'Total Times Watched', '# of Users Watched'],
                rows:MostWatchedVideos  as unknown as CourseVideoTableData[],
                tableType:"video"
              }
            )}
          </Grid>
        </Grid>
      </StyleTableWrapper>
      </AdminReportWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const gridStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "24px",
  boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
  padding: "24px",
  flexWrap: "wrap",
  width: "100%",
  borderRadius: "18px",

};

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#652786"
  },
});
const StyledDropdownClickBoxs = styled('div')({
  width: "392px",
  height: "52px",
  marginTop: "8px",
  display: "flex",
  alignItems: "center",
  gap: "12px",
  padding: "16px 20px !important",
  border: "1px solid #E5E2E1",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  position: "relative",
  "@media (max-width:1000px)": {
      width: "100%",
      height: "52px",
  },
  "&.selectCommonStyle .contentContainer": {
      display: "flex",
      gap: "3px",
      width: "100%"
  },
  "&.selectActive": {
      backgroundColor: "#EEE8F2",
  },
  "&.selectActive .title": {
      color: "#652786"
  },
  "&.creationDate": {
      display: "flex",
      justifyContent: "space-between"
  },
  "& .content_role ": {
      width: "100% !important",
      display: "flex !important",
      justifyContent: "space-between !important"
  },
  "& .innerContentBox": {
      display: "flex",
      gap: "3px",
      fontSize: "16px",
      fontWeight: 500,
      color: '#ADAAAA'
  },
  "& .title": {
      whiteSpace: "nowrap",
      fontSize: "16px",
      fontWeight: 400,
      color: "#484646",
      fontFamily: "Poppins, sans-serif"
  },
  "& .arrowIconStyle": {
      color: "#652786"
  },
  "&:focus": {
      outline: "none",
      border: "1px solid #652786"
  },
  "&:hover": {
      border: "1px solid #652786"
  }
});

const DropdownGroupContent = styled(MaterialUIBox)({
  position: "absolute",
  top: "100px",
  zIndex: 9999,
  background: "#fff",
  width: "100%",
  padding: "8px 16px",
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: "12px",
  overflowY: "auto",
  "& .checkboxList": {
      flexDirection: "column",
      marginTop: "5px",
      gap: "8px",
      display: "flex",
      marginBottom: "5px"
  },
  "& .checkboxContent": {
      display: "flex",
      fontFamily: "Poppins , sans-serif",
      alignItems: "center",
      textTransform: "capitalize",
      borderBottom: "1px solid transparent",
  }, "& .MuiTypography-body1": {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
      fontSize: "16px",
      color: "#1C1B1B"
  },
  "& .checkboxContent:hover": {
      borderBottom: "1px solid transparent",
      borderRadius: "8px",
      backgroundColor: "#EEE8F2",
  },
  "& .selectedStyle": {
      borderBottom: "1px solid transparent !important",
      borderRadius: "8px",
      backgroundColor: "#EEE8F2",
  },
  "& .selectedStyle .MuiTypography-body1": {
      color: "#542070 !important"
  },
  "& .applyBtn": {
      margin: "auto",
      display: "block",
      textTransform: "capitalize",
      fontSize: "16px",
      color: "#542070",
  },
  "& .MuiFormControlLabel-root": {
      borderBottom: "1px solid transparent",
      margin: 0,
      fontFamily: "Poppins, sans-serif"
  },
});

const StyleTableWrapper = styled("div")({
  marginTop: "20px",
  "& .MuiTableBody-root .MuiTableRow-root": {
    boxShadow: "1px 2px 10px 0px #00000011 !important"
  },
  "& .MuiPaper-outlined": {
    boxShadow: "1px 2px 10px 0px #00000011",
    border: "none"
  },
});

const NoDataImg = styled("img")({
    width: "275px",
    height: "175px",
    "@media (max-width:960px)": {
        width: "250px",
    },
})

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  marginTop: "20px",
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#fff",
    color: "#333",
    maxWidth: "400px",
    borderRadius: "8px",
    boxShadow: theme.shadows[3],
    marginLeft: "-25px"
  },
  '& .MuiTooltip-arrow': {
    backgroundColor: "#FFFFFF !important",
  }
}));

const AdminReportWrapper = styled("div")({
 "&::-webkit-scrollbar": {
  width: "8px"
 },
 "&::-webkit-scrollbar-track": {
  backgroundColor: "#E5E2E1",
  borderRadius: "16px"
},
"&::-webkit-scrollbar-thumb": {
  backgroundColor: "#ADAAAA",
  borderRadius: "16px"
}
});

const TextFieldSearch = styled(TextField)({
  "& .MuiOutlinedInput-notchedOutline":{
    backgroundColor: "#fcf6f6",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray"
  },
  "& .MuiSvgIcon-root":{
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root":{
    borderRadius: "12px",
    height: "48px"
  },
  "& .MuiOutlinedInput-input":{
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart":{
    paddingLeft: "6px"
  }
});

const webStyle = {
  tableWrapper: {
    height: '100%', 
    borderRadius: "16px" 
  },
  titleName: {
    fontSize: "22px",
    fontWeight: 600,
  },
  columnName: {
    fontSize: "18px",
    fontWeight: 600,
  },
  textCenter: {
    textAlign: "center"
  },
  noDataFound: {
    fontSize: "20px",
    fontWeight: 600,
  },
  dropDownLableName: {
    color: "#652786"
  },
  dropDown: {
    color: '#1C1B1B', 
  },
  startEndDateLable: {
    fontSize: "14px", 
    marginBottom: "6px"
  }
};
// Customizable Area End
