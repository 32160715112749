import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Customizable Area Start
import invert from "invert-color";
// Customizable Area End
export const configJSON = require("./config");

export interface ITheme {
  backgroundColor: string;
  textColor: string;
  fontType: "normal" | "italic";
  isBold: boolean;
  underlined: boolean;
  textBackgroundColor: string;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  theme: ITheme;
  loginToken: string | null;
  themeId: string | null;
  themesData: Array<Object>;
  themes: ITheme[];
  allThemesModalOpen: boolean;
  isItalic: boolean;
  // isPlatformWeb: booolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

const DEFAULT_THEME: ITheme = {
  backgroundColor: "#ffffff",
  textColor: "#000000",
  fontType: "normal",
  isBold: false,
  underlined: false,
  textBackgroundColor: "#ffffff",
};

export default class ThemeBlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createThemeApiCallId: string = "";
  updateThemeApiCallId: string = "";
  deleteThemeApiCallId: string = "";
  getThemeApiCallId: string = "";
  getAllThemesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      theme: DEFAULT_THEME,
      loginToken: null,
      themeId: null,
      themesData: [],
      themes: [],
      allThemesModalOpen: false,
      isItalic: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let loginToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", loginToken);
      this.setState({ loginToken });

      let storedThemeString;
      if (this.isPlatformWeb()) {
        storedThemeString = await localStorage.getItem("currentTheme");
      } else {
        storedThemeString = await AsyncStorage.getItem("currentTheme");
      }

      
      if (!storedThemeString) {
        this.createNewTheme();
        return;
      }
      
      const storedThemeData = JSON.parse(storedThemeString);
      
      const themeId = storedThemeData.id;
      const storedTheme = storedThemeData.theme;
      
      if (themeId) {
        this.setState({
          theme: storedTheme,
          themeId,
        });

        return this.getTheme(themeId);
      }

      this.createNewTheme();
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createThemeApiCallId !== null &&
      apiRequestCallId === this.createThemeApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        const themeId = responseJson.data?.id;
        const themeToStore = JSON.stringify({
          id: themeId,
          theme: responseJson.data.attributes.settings,
        });

        const receivedTheme = responseJson.data.attributes.settings;
        this.setState({
          theme: {
            backgroundColor: receivedTheme.backgroundColor,
            textColor: receivedTheme.textColor,
            fontType: receivedTheme.fontType,
            isBold: receivedTheme.isBold,
            underlined: receivedTheme.underlined,
            textBackgroundColor: receivedTheme.textBackgroundColor,
          },
          themeId: responseJson.data.id,
        });

        if (this.isPlatformWeb()) {
          localStorage.setItem("currentTheme", themeToStore);
        } else {
          AsyncStorage.setItem("currentTheme", themeToStore);
        }

        this.showAlert("Theme created", "");
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateThemeApiCallId !== null &&
      apiRequestCallId === this.updateThemeApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        //Need To send Login loginToken message to save for future call
        this.setState({ themesData: responseJson.data });
        this.showAlert("Theme updated", "");
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteThemeApiCallId !== null &&
      apiRequestCallId === this.deleteThemeApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        //Need To send Login loginToken message to save for future call
        this.showAlert("Theme deleted", "");
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getThemeApiCallId !== null &&
      apiRequestCallId === this.getThemeApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        const receivedTheme = responseJson.data.attributes.settings;
        this.setState({
          theme: {
            backgroundColor: receivedTheme.backgroundColor,
            textColor: receivedTheme.textColor,
            fontType: receivedTheme.fontType,
            isBold: receivedTheme.isBold,
            underlined: receivedTheme.underlined,
            textBackgroundColor: receivedTheme.textBackgroundColor,
          },
          themeId: responseJson.data.id,
        });
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        //Need To send Login loginToken message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllThemesApiCallId !== null &&
      apiRequestCallId === this.getAllThemesApiCallId
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({
          themes: responseJson.data,
          themesData: responseJson.data[0].attributes,
        });
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.loginToken) {
        //Need To send Login loginToken message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  toggleItalic = () => {
    this.setState({
      theme: {
        ...this.state.theme,
        fontType: this.state.theme.fontType === "italic" ? "normal" : "italic",
      },
    });
  };

  setColor = (colors: string) => {
    this.setState({
      theme: {
        ...this.state.theme,
        textColor: invert(colors),
        backgroundColor: colors,
      },
    });
  };

  setTextColor = (colors: string) => {
    this.setState({ theme: { ...this.state.theme, textColor: colors } });
  };

  setBoldType = () => {
    this.setState({
      theme: { ...this.state.theme, isBold: !this.state.theme.isBold },
    });
  };

  setUnderlined = () => {
    this.setState({
      theme: { ...this.state.theme, underlined: !this.state.theme.underlined },
    });
  };

  setBackgroundTextColor = (colors: string) => {
    this.setState({
      theme: { ...this.state.theme, textBackgroundColor: colors },
    });
  };

  resetTheme = () => {
    this.setState({
      theme: DEFAULT_THEME,
    });
  };

  toggleThemesModal = (allThemesModalOpen: boolean) => {
    this.setState({ allThemesModalOpen });
  };

  createNewTheme = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.loginToken,
    };

    const httpBody = {
      theme: {
        name: configJSON.themeConstant,
        settings: {
          backgroundColor: this.state.theme.backgroundColor,
          textColor: this.state.theme.textColor,
          fontType: this.state.theme.fontType,
          isBold: this.state.theme.isBold,
          underlined: this.state.theme.underlined,
          textBackgroundColor: this.state.theme.textBackgroundColor,
        },
      },
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createThemeApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createThemeApiEndpoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createApiMethodType
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  updateTheme = (id?: string | null) => {
    if (!id) {
      return;
    }

    const theme: ITheme = {
      backgroundColor: this.state.theme.backgroundColor,
      textColor: this.state.theme.textColor,
      fontType: this.state.theme.fontType,
      isBold: this.state.theme.isBold,
      underlined: this.state.theme.underlined,
      textBackgroundColor: this.state.theme.textBackgroundColor,
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.loginToken,
    };

    const httpBody = {
      theme: { name: "Theme", settings: theme },
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateThemeApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateThemeApiEndpoint}${id}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethodType
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    const storedThemeString = JSON.stringify({ id, theme });

    if (this.isPlatformWeb()) {
      localStorage.setItem("currentTheme", storedThemeString);
    } else {
      AsyncStorage.setItem("currentTheme", storedThemeString);
    }
  };

  deleteTheme = (id?: string | null) => {
    if (!id) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.loginToken,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteThemeApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteThemeApiEndpoint}${id}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getTheme = (id?: string | null) => {
    if (!id) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getThemeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getThemeApiEndpoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllThemes = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllThemesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllThemesApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
