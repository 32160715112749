import React from 'react'
import {
    InputAdornment,
    IconButton,
} from "@material-ui/core";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const IconEYE = ({ setFieldValue, values }: any) => {

    return (<InputAdornment position="end">
        <IconButton
            id="show-password-field-icon"
            aria-label="toggle password visibility"
            onClick={() => {
                setFieldValue(
                    "showPassword",
                    !values.showPassword
                );
            }}
            edge="end"
        >
            {values.showPassword ? (
                <VisibilityOff />
            ) : (
                <Visibility />
            )}
        </IconButton>
    </InputAdornment>)
}

export default IconEYE