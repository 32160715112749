// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    Typography
} from '@material-ui/core';
import {Search} from "@material-ui/icons";
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import '../../videos/src/video.css';
import { Caret1} from './assets';
import ContentRoleTable from '../../../components/src/ContentRoleTable';
class ContentRoleList extends CourseCreationController {
    constructor(props: any) {
        super(props);
    }
    
    render() {
        return (<>
            <div className="reward-list">
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        <Typography className='content-role-heading'>Current Courses/Programs For "Content Role Name"</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{paddingBottom:"30px"}} spacing={4}>

                    <Grid item md={6} xs={10}>
                        <label className='input-label'>Add New Content Role</label>
                        <TextField
                        fullWidth
                        id="add_content_role"
                        name="add_content_role"
                        variant="outlined"
                        placeholder="Add content role"
                        InputLabelProps={{
                        shrink: true
                        }}
                        helperText="(Once you add a new role you will need to assign courses to the role and then assign users to the role)"
                       className=""
                      
                      />

                    </Grid>
                    <Grid item md={2} style={{textAlign:"end",marginTop:"23px"
                }}>
                        <Button className='create-reward-btn'>+ Add Role</Button>
                    </Grid>
                    

                </Grid>
                <Grid container spacing={4}>
                <Grid item md={3}>
                <TextField
                        fullWidth
                        id="search"
                        name="search"
                        variant="outlined"
                        placeholder="Search"
                        InputLabelProps={{
                        shrink: true
                        }}
                       className="search"
                       InputProps={{
                       startAdornment: (
                      <InputAdornment position="start">
                      <Search />
                      </InputAdornment>
                      ),  
                      }}
                      />

                </Grid>
                    <Grid item md={2} xs={12}>
                    <FormControl className="bulk-action">
                    <Select
                     labelId="bulk action"
                     id="bulk action"
                     name='branch_name'
                     variant="outlined"
                     data-test-id = "startAdornment1"
                     IconComponent={() => null}
                     endAdornment={
                     <InputAdornment position="end">
                     <img src={Caret1} />
                    </InputAdornment>
   
                    }
                    startAdornment={
                        <InputAdornment position="end">
                            <p className='filter-heading'>Filter By: </p>
                       </InputAdornment>
                    }
                    fullWidth> 
                   <MenuItem value="" selected>
                   <em>User</em>
                  </MenuItem>
                 <MenuItem value={1}>User</MenuItem>
                 </Select>
                </FormControl>

                    </Grid>
                </Grid>

            </div>
            <Grid container md={12} xs={12}>
                    <Grid item md={12} xs={12}>
                        <ContentRoleTable/>
                    </Grid>
                </Grid>
        </>);
    }
}
export default ContentRoleList;
// Customizable Area End