import React from "react";
import { FormControl, InputBase, MenuItem, Select } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 16,
      position: "relative",
      border: "2px solid #202020",
      fontSize: 13,
      fontWeight: 600,
      lineHeight: "13px",
      color: "#202020",
      padding: "10px",
      "&:focus": {
        borderRadius: 16,
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 18,
        lineHeight: "27px",
        padding: "14px 40px 14px 20px",
      },
    },
  })
)(InputBase);

export default function SelectDropdown({
  options,
  name,
  value,
  disabled = false,
  handleChange,
}: any) {
  const classes = useStyles();

  return (
    <FormControl>
      <Select
        displayEmpty
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        input={<BootstrapInput />}
        classes={{
          icon: classes.icon
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.id}
            value={option.value}
            className={classes.option}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: "13px",
      color: "#202020",
      [theme.breakpoints.up("sm")]: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "27px",
      },
    },
    icon: {
      fill: "#202020"
    }
  })
);
