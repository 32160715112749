import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  Dialog,
  TextField,
  Popover,
  Badge,
  Modal,
  Slider,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { newCourseContent,NotFImage,NewFIlter,FilterContent, Video, DocumentIcon } from "./assets";
import { createTheme } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import ContentLibraryTable from "../../../components/src/ContentLibraryTable";
import FilterItems from "../../filteritems/src/FilterItems.web";
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from "react-player";
import VolumeOffOutlinedIcon from '@material-ui/icons/VolumeOffOutlined';
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal"
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { backwardIcon, collapseIcon, expandIcon, forwardIcon, pauseIcon, playIcon, startIcon, volumeIcon } from "../../../../packages/blocks/Library2/src/assets";
import { Document, Page } from "react-pdf";
import { FaMusic } from "react-icons/fa";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import VideoLibraryController, {
  Props,
  configJSON,
} from "./VideoLibraryController";

export default class VideoLibrary extends VideoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

 renderInputFilterRow = () => {
  const {anchorFilterContentEl } = this.state;
  const openFilterContent = Boolean(this.state.anchorFilterContentEl)
    return (
      <>
       <InputSearch item md={6} xs={12} lg={7} style={{ display: 'flex', alignItems: 'center', borderRight: "none" }}>
        <StyledWrapperContainer>
        <AdvancedSearch 
            data-test-id="btn_change_one"
            handleListItemClick={this.handleListItemClickFilter}
              searchInputValue={this.state.searchContentValue}
              handleInputChange={this.handleInputChangeSearch}
              filterList={this.state.filteredContentList}
              isFilterDropActive={openFilterContent}
              showList={this.state.showContentList} navigation={undefined} id={""}            />

            <div data-test-id="filterOpenContentTestID" style={{ marginLeft: "0px" }} className={anchorFilterContentEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterOpen}>
            {this.state.contentCreationDate[0]?.value.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0 ) || this.state.tagsSelectedItems.length > 0 || this.state.creationSelectedItems.length > 0 || this.state.fileSelectedItems.length > 0 ? (
                <BadgeContentComponent color="secondary" variant="dot"></BadgeContentComponent>):(
                  ""
                ) 
              }
              {anchorFilterContentEl ? (
                 <img src={FilterContent} data-test-id="filterOpenTestID" alt="Filter" />
              ):(
                <img src={NewFIlter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
            data-test-id="filterPropsContentID"
            className="filterContainerPopover"
            open={openFilterContent}
            id="myPopover"
            anchorEl={this.state.anchorFilterContentEl}
            onClose={this.filterContentDropdownCloseHandler}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            >
                 <FilterItems
                 data-test-id="FilterItemsContentTestID"
              handleClear={this.handleClearFilter}
              creationDateChangeHandler={this.creationChangeHandlerDate} 
              dropdownOpenHandler={this.dropdownHandlerOpen} 
              handleCalendarChange={this.handleChangeCalendar} 
              dropdownCloseHandler={this.dropdownHandlerClose } 
              handleCalendarOpen={this.handleOpenCalendar}
               anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType} 
                categorySelectedItems={[]} 
                contentRoleSearchText={""} 
                contentRoleSelectedItems={[]} 
                categorySearchText={""} 
                placeholderSelectedItems={[]} 
                placeholderSearchText={this.state.tagsSearchText} 
                placeholderCreatorSearchText={this.state.creatorSearchText} 
                contentCreationDate={this.state.contentCreationDate} 
                handleClick={this.handleClickContentFilter} 
              selectedStatus={""} 
              tagListing={this.state.tagsListing} 
              adminCatagoryList={[]} 
              adminContentRolesList={[]} 
              createrNameListing={this.state.createrNameListing}
              isCalendarOpen={this.state.isCalendarOpen}
               tagsSelectedItems={this.state.tagsSelectedItems} tagsSearchText={""} 
               handleTagsSearchChange={this.handleTagsSearchChange} 
               handleCreatorSearchChange={this.handleCreatorSearchChange}
              tagsCheckboxChangeHandler={this.tagsCheckboxChangeHandler} 
              creationCheckboxChangeHandler={this.creationCheckboxChangeHandler}
              creationSelectedItems={this.state.creationSelectedItems}
              fileCheckboxChangeHandler={this.fileCheckboxChangeHandler}
              fileSelectedItems={this.state.fileSelectedItems}
              />
            
            </Popover>
          </StyledWrapperContainer>
        </InputSearch>
        <CreateGrid item md={6} xs={12} lg={2}>
           <Box sx={webStyle.Accordion} className="btnBox">
             <Box>
             <Dialog
                  data-test-id="Tooltip"
                  open={this.state.openContentNavigate}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      margin: 'auto',
                      maxWidth: 'fit-content',
                      maxHeight: '80vh',
                      overflowY: 'auto',
                      zIndex: 9999,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      borderRadius: "10px"
                    },
                  }}   >                    
                <CreateContentModalContainer>
                  <CreateContentButtons  data-test-id="btn_click_video" onClick={()=>this.gotoContent('video')}>
                    <ImageInnerComponent src={newCourseContent} />
                    <ComponentButton >
                      Video
                    </ComponentButton>
                  </CreateContentButtons>
                  <CreateContentButtons data-test-id="btn_click_audio" onClick={()=>this.gotoContent('audio')}>
                    <ImageInnerComponent src={Video} />
                    <ComponentButton>
                     Audio
                    </ComponentButton>
                  </CreateContentButtons>
                  <CreateContentButtons data-test-id="btn_click_document"onClick={()=>this.gotoContent('document')}>
                    <ImageInnerComponent src={DocumentIcon} />
                    <ComponentButton>
                      Document
                    </ComponentButton>
                  </CreateContentButtons>
                </CreateContentModalContainer>
              </Dialog>
            </Box>
          <Button
           onClick={() => this.handleOpen()}
            style={webStyle.AccordionSummary}
            data-test-id='panel1a-content-new'
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CreateNewComponent>
              <span style={{ fontSize: "16px", fontWeight: 600, paddingRight:"10px",fontFamily:"Poppins, sans-serif" }}>
                + 
              </span>
              Add content
            </CreateNewComponent>
          </Button>
        </Box>
        <DeleteModal
                data-test-id='delete-content'
               openDialog={this.state.deleteModalOpen}
               headingText={`Are you sure you want to delete this content?`}
               btnCancelText="Cancel"
               btnOkText= "Delete"
               subHeading= "Lessons that use this content will also be deleted."
               handleCancel={this.cancelContentDeleteDialog}
               handleOk={()=>this.handleDeleteContent(this.state.latestId)}
        />
          <DeleteModal
               data-test-id='delete-all-content'
               openDialog={this.state.deleteAllOpen}
               headingText={`Are you sure you want to delete this content?`}
               btnCancelText="Cancel"
               btnOkText= "Delete"
               subHeading= "Lessons that use this content will also be deleted."
               handleCancel={this.cancelContentAllDeleteDialog}
               handleOk={this.handleDeleteAllContent}
        />
      </CreateGrid>
      </>
     
    )
  }

  renderTableOrNotFound = () => {
    return(
      <TableWrapper className="contentTable">
    { this.state.contentUserData ? 
     
      <ContentLibraryTable
            data-test-id='contentTableTestId'
            users={this.state.contentUserData}
            onSortIconClick={this.sortUsers}
            prevPageExists={!!this.state.pagination.prevPage}
            nextPageExists={!!this.state.pagination.nextPage}
            handleNextPageNavigation={this.handleNextNavigation}
            handlePrevPageNavigation={this.handlePrevNavigation}
            getSelectedChecboxID={this.getSelectedIDChecbox}
            deleteItem={this.deleteItemSingle} 
            handleDeleteAll={this.handleDeleteBulk}
            handleOpenObjectModal={this.handleOpenModalObject}
            closeDeleteButton={this.state.RemoveDeleteButton}
            updateChildState={this.updateChildStates}
            gotoEditContent={this.gotoEditContent}
/> 
:
       <MainNotFoundBox>
         <MainInnerBox>
        <Box>
           <ImageComponent src={NotFImage} />
           </Box>
           <NotFoundText>No results founds</NotFoundText>
         </MainInnerBox>
       </MainNotFoundBox>
   
    }
    </TableWrapper>
    )
  }

  ValueLabelComponent(props:any) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  videoComponent = () => {
    return (
        <VideoPlayerBox
        className={"videoComponentid"}
        ref={this.videoContainerRef}
        data-test-id="videoPlayerId"
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        >
<ReactPlayer
data-test-id="reactPlayerId"
ref={this.videoPlayerRef}
onProgress={this.handleProgress}
playing={this.state.isVideoPlaying}
onDuration={this.handleDuration}
url={this.state.contentItemDetail?.data?.attributes?.attachment?.url}
onEnded={this.handleVideoEnd}
muted={this.state.isVideomute}
playbackRate={this.state.playbackRate}
controls={this.state.contentItemDetail?.data?.attributes?.attachment.url.includes("www.youtube")}
/>
  {!this.state.contentItemDetail?.data?.attributes?.attachment.url.includes("www.youtube") && <div style={webStyle.videoControlSection}>
      <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.handleSeekBackward}/>
      <img className="cursor" data-test-id="playPauseId" style={{width:"24px",height:"24px"}} src={this.state.isVideoPlaying ? pauseIcon : startIcon} onClick={this.handleToggleVideo}/>
      <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.handleSeekForward}/>
      <select
            id="speedSelect"
            value={this.state.playbackRate}
            data-test-id="speedSelect"
            style={webStyle.selectBox}
            onChange={this.handleSpeedChange}
          >
            {this.state.speedData.map((speed)=><option value={speed}>{`${speed}x`}</option>)}
          </select>
      <Slider
    ValueLabelComponent={this.ValueLabelComponent}
    defaultValue={0}
    data-test-id="sliderchangeId"
    value={this.state.videoCurrentTime}
    max={this.state.videoDuration || 0}
    style={{margin:"0 5px"}}
    onChange={this.handleSeekChange}
    />
      <Typography style={{color:"white"}}>{this.formatTime(this.state.videoCurrentTime)}</Typography>
      {this.state.isVideomute ? <VolumeOffOutlinedIcon style={{fontSize:"24px",color:"white"}} onClick={this.handleToggleMute}/> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.handleToggleMute}/>}
      <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
    </div>}
    {(!this.state.contentItemDetail?.data?.attributes?.attachment.url.includes("www.youtube") && (!this.state.isVideoPlaying || this.state.isActionButtonShow)) && <Box style={{
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    width: "auto",
    color:"white",
  }}>
<img 
  className="cursor" 
  src={this.state.isVideoPlaying ? pauseIcon : playIcon} 
  style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
  onClick={this.handleToggleVideo} 
/>
</Box>
}
    </VideoPlayerBox>
    )
}


renderDocumentSection = () => {
  return (
      <Box style={webStyle.downloadFileSectionCss}>
          <Typography style={webStyle.sorryText}>{configJSON.sorryMsg}</Typography>
          <Typography style={{...webStyle.sorryText,fontWeight:400}}>Click <span data-test-id="downloadBtn" style={{...webStyle.sorryText,cursor:"pointer"}} onClick={this.downloadFile}>here</span> to download</Typography>
      </Box>
  )
}

renderPdfComponent = () => {
  return (
    <Box className={this.state.isFullScreenView ? "pdfDialogBox" : ""}>
      <div 
      ref={this.videoContainerRef}
      className={"documentConteiner"} style={{position:"relative",display:"flex",justifyContent:"center",height:"482px"}}>
          <Box className="pdfDocumentBox" style={{display:"flex",justifyContent:"center",width:"100%"}}>
              <Document
              data-test-id="DocumentComponent"
                file={this.state.contentItemDetail?.data?.attributes?.attachment?.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >

  <Page pageNumber={this.state.pdfActivePage} />
</Document>
</Box>
<div style={{background:"#1C1B1B",color:"white",position:"absolute",bottom:0,width:"100%",height:"44px",
  display:"flex",justifyContent:"space-between",alignItems:"center"
}}>
<Box style={{display:"flex",justifyContent:"flex-end",alignItems:"center",width:"56%",gap:"10px",height:"100%"}}>
  <Typography data-test-id="prevBtnId" className="cursor" style={{paddingBottom:"5px"}} onClick={() => this.state.pdfActivePage >= 1 && this.setPageNumber(this.state.pdfActivePage - 1)}>
  ❮
</Typography>
  <span style={{fontWeight:600,fontSize:"18px"}}>{this.state.pdfActivePage}</span>/<span style={{fontWeight:400,fontSize:"16px"}}>{this.state.pdfTotalPages}</span>
  <Typography data-test-id="nextBtnId" className="cursor" style={{paddingBottom:"5px"}} onClick={() => this.state.pdfActivePage < this.state.pdfTotalPages && this.setPageNumber(this.state.pdfActivePage + 1)}>
  ❯
</Typography>
</Box>
<Box style={{marginRight:"10px"}}>
<img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
</Box>
</div>
<div style={{color:"black",position:"absolute",top:"40%",width:"100%",height:"44px",
  display:"flex",justifyContent:"space-between",alignItems:"center",gap:"16px"
}}>
  <button data-test-id="prevBtnTestId" disabled={this.state.pdfActivePage <= 1} className="carousel-button prev" onClick={() => this.setPageNumber(this.state.pdfActivePage - 1)}>
  ❮
</button>
<button data-test-id="nextBtnTestId" disabled={this.state.pdfActivePage === this.state.pdfTotalPages} className="carousel-button next" onClick={() => this.setPageNumber(this.state.pdfActivePage + 1)}>
  ❯
</button>
</div>
</div>
</Box>
  )
}

imagePreview = () => {
  return (
                         <div
                         ref={this.videoContainerRef}
                          style={{...webStyle.mainImg_box,alignItems:this.state.isFullScreenView ? "center" : "start"}}>
                          <img data-test-id="imageid" src={this.state.contentItemDetail?.data?.attributes?.attachment?.url} style={{...webStyle.main_courseImg,maxHeight:this.state.isFullScreenView ? "100%" : "406px"}} alt="img"/>
    <div style={{background:"#1C1B1B",color:"white",position:"absolute",bottom:0,width:"100%",height:"44px",
        display:"flex",justifyContent:"flex-end",alignItems:"center",paddingRight:"10px"
      }}>
    <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
      </div>
                      </div>
  )
}


audioComponent = () => {
  return (
      <div
      style={{
          position:"relative",
          height:"100%",
          background:"black"
      }}
      ref={this.videoContainerRef}
      data-test-id="videoPlayerId"
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      >
          <Box style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>
          {this.state.contentItemDetail.data.attributes.thumbnail ? <img style={{maxHeight:"100%",maxWidth:"100%"}} src={this.state.contentItemDetail.data.attributes.thumbnail.url} /> :  <FaMusic fontSize="200px" color="white"/>}
          </Box>
          <ReactPlayer
    ref={this.audioRef}
    data-test-id="audioReactPlayer"
    onDuration={this.handleAudioDuration}
    url={this.state.contentItemDetail?.data?.attributes?.attachment?.url}
    playing={this.state.isAudioPlaying}
    onProgress={this.handleProgressAudio}
    onEnded={this.handleAudioEnd}
    playbackRate={this.state.playbackRate}
    muted={this.state.isAudioMuted}
    width="100%"
    height="50px"
  />
  <div style={{position:"absolute",bottom:0,left:0,backgroundColor:"#1C1B1B",width:"100%",height:"44px",display:"flex",alignItems:"center",
      gap:"8px",padding:"0 8px"
  }}>
    <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.handleSeekBackwardAudio}/>
    <img className="cursor" data-test-id="playPauseId" style={{width:"24px",height:"24px"}} src={this.state.isAudioPlaying ? pauseIcon : startIcon} onClick={this.togglePlay}/>
    <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.handleSeekForwardAudio}/>
    <select
            value={this.state.playbackRate}
            id="speedSelect"
            data-test-id="speedSelect"
            onChange={this.handleSpeedChange}
            style={webStyle.selectBox}
          >
            {this.state.speedData.map((speed)=><option value={speed}>{`${speed}x`}</option>)}
          </select>
    <Slider
    step={1}
  ValueLabelComponent={this.ValueLabelComponent}
  data-test-id="sliderchangeId"
  max={this.state.audioLessonTotalDuration || 0}
  value={this.state.audioActiveTime}
  onChange={this.handleSliderChange}
  style={{margin:"0 5px"}}
  />
    <Typography style={{color:"white"}}>{this.formatTime(this.state.audioActiveTime)}</Typography>
    {this.state.isAudioMuted ? <VolumeOffOutlinedIcon data-test-id="toggleMute" style={{fontSize:"24px",color:"white"}} onClick={this.handleToggleAudioMute}/> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.handleToggleAudioMute}/>}
    <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.handleFullScreenToggle}/>
  </div>
  {(!this.state.isAudioPlaying || this.state.isActionButtonShow) && <Box style={{
      top: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  left: "50%",
  justifyContent: "center",
  display: "flex",
  width: "auto",
  alignItems: "center",
  color:"white",
  gap: "20px",
}}>

<img 
className="cursor" 
src={this.state.isAudioPlaying ? pauseIcon : playIcon} style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }} 
onClick={this.togglePlay} 
/>
</Box>
}
  </div>
  )
}

renderComponentOnPopUp = () => {
  const fileExtension = this.state.contentItemDetail?.data?.attributes?.attachment?.url && this.state.contentItemDetail?.data?.attributes?.attachment?.url.substring(this.state.contentItemDetail?.data?.attributes?.attachment?.url.lastIndexOf('.') + 1);

  if(this.state.contentItemDetail?.data?.attributes?.content_type === "video"){
    return this.videoComponent()
  }else if(this.state.contentItemDetail?.data?.attributes?.content_type === "document"){
     if(this.state.contentItemDetail?.data?.attributes?.file_extension && this.state.contentItemDetail?.data?.attributes?.file_extension !== "application/pdf" && this.state.contentItemDetail?.data?.attributes?.file_extension.includes("application")){
      return this.renderDocumentSection()
    }else if(this.state.contentItemDetail?.data?.attributes?.file_extension && this.state.contentItemDetail?.data?.attributes?.file_extension.includes("image")){
        return this.imagePreview()
    }else if(fileExtension && fileExtension === "pdf"){
      return this.renderPdfComponent()
    }
  }else if(this.state.contentItemDetail?.data?.attributes?.content_type === "audio"){
    return this.audioComponent()
  }
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Grid container style={{ marginTop: '7px', justifyContent:"space-between" }}>
           {this.renderInputFilterRow()}
          {this.renderTableOrNotFound()}
        </Grid>
         <Modal
         onClose={this.handleCloseModal}
         aria-labelledby="custom-modal-title"
         aria-describedby="custom-modal-description"
         open={this.state.isContentModal}
         style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           marginTop: "10px",
           borderRadius: '20px'
         }}
        >
         <ResponsiveBox>
           <Box
             style={{
               backgroundColor: '#FFF',
               height:"100%",
               borderRadius: '12px',
               display: 'flex',
               flexDirection: 'column',
               minHeight: 0,
               overflow: "hidden"
             }}
           >
         <StyledIconButton onClick={this.handleCloseModal} ><CloseIcon/></StyledIconButton>
         <Typography variant="h4" id="custom-modal-title" gutterBottom className="modalTitle">
           {this.state.contentItemDetail?.data?.attributes?.title}
         </Typography>
        
         <ScrollableContainer> 
           <Box width="100%" height="450px" bgcolor="lightgrey" mb={2}>
          {this.renderComponentOnPopUp()}
           </Box>
           <Box mt={2}>
             <ExpertNameComponent variant="h5" gutterBottom>
             {this.state.contentItemDetail.data.attributes.expert_name} | <span title={this.state.contentItemDetail.data.attributes.tags.length > 1 ? this.state.contentItemDetail.data.attributes.tags.map((tagObject: { name: string; }) => tagObject.name).join(", ") : ""}>{this.state.contentItemDetail.data.attributes.tags.length > 1 ? `${this.state.contentItemDetail.data.attributes.tags[0].name} & ${this.state.contentItemDetail.data.attributes.tags.length - 1} more` : this.state.contentItemDetail.data.attributes.tags.map((tagObject: { name: string; }) => tagObject.name).join(", ")}</span>
             </ExpertNameComponent>
             <Typography variant="body1" gutterBottom>
               Created on: {this.state.contentItemDetail?.data?.attributes.created_at
                      ? this.formatDateModal(this.state.contentItemDetail?.data?.attributes.created_at)
                      : "-"}
             </Typography>
           </Box>
           <Box sx={{marginTop:"15px"}}>
           <DescriptionHeading variant="h5" gutterBottom>
              Video description
           </DescriptionHeading>
           <DescriptionComponent variant="body1" id="custom-modal-description">
            {this.state.showFullDescription ? this.state.contentItemDetail?.data?.attributes?.description : `${this.state.contentItemDetail?.data?.attributes?.description?.slice(0, 100)}`}
            {!this.state.showFullDescription && (
              <button data-test-id="btn_toggle-des" style={{color: "#6C328B",backgroundColor:"#FFF", border:"none", fontFamily:"Poppins, sans-serif", fontSize:"16px", fontWeight:400}} onClick={this.toggleDescription}>Read More...</button>
            )}
          </DescriptionComponent>
           </Box>

           {this.state.contentItemDetail.data.attributes.lessons.length > 0 && <Box sx={{marginTop:"15px"}}>
              <Typography style={{
               fontWeight:600,fontSize:"18px"
              }}>Lessons that use this object</Typography>
                  <ul>
                    {this.state.contentItemDetail.data.attributes.lessons.map((lesson)=> (
                      <li style={{color:"black",fontWeight:400}}>{`Course: ${lesson.course_title} > Lesson: ${lesson.lesson_title}`}</li>
                    ))}
                  </ul>
            </Box>}
         </ScrollableContainer>
        
        <FixedFooter>
         <Box p={2} display="flex" justifyContent="flex-end">
           <ResponsiveButtonFirst data-test-id="deleteBtn" style={{border: this.state.contentItemDetail?.data?.attributes.lessons.length > 0 ? "1px solid #90877f" : "1px solid #e47004",
  color: this.state.contentItemDetail?.data?.attributes.lessons.length > 0 ? "#90877f" : "#e47004",}} onClick={()=> this.state.contentItemDetail?.data?.attributes.lessons.length === 0 && this.deleteItemSingle(this.state.contentItemDetail.data.attributes.id as number)} variant="contained" color="primary" title={this.state.contentItemDetail?.data?.attributes.lessons.length > 0 ? "Cannot be deleted when in use" : ""}>
             Delete
           </ResponsiveButtonFirst>
           <ResponsiveButtonSecond data-test-id="editContent" onClick={()=>this.gotoEditContent(this.state.contentItemDetail.data.attributes.content_type,this.state.contentItemDetail.data.id as number)} variant="contained" color="secondary">
             Edit
           </ResponsiveButtonSecond>
         </Box>
        </FixedFooter>
        </Box>
        
        </ResponsiveBox>
        </Modal>
      </>
        
        // Customizable Area End
    );
  }
}

// Customizable Area Start

const DescriptionHeading = styled(Typography)({
  color: "#1C1B1B",
  fontSize: "18px",
  fontWeight: 600,
  fontFamily: "Poppins , sans-serif"
})

const DescriptionComponent = styled(Typography)({
  fontSize:"16px",
  fontWeight:400,
  fontFamily: "Poppins, sans-serif"
});

const ExpertNameComponent = styled(Typography)({
  fontSize:"16px",
  fontWeight:400,
  fontFamily: "Poppins, sans-serif"
})

const ResponsiveBox = styled(Box)({
  maxWidth: "899px",
  width: "calc(100% - 40px)",
  height: "calc(100% - 100px)",
  position: "relative",
  backgroundColor: "#ffffff",
  borderRadius: "17px",
  "& .modalTitle":{
    padding: "16px",
    fontFamily: "Poppins,sans-serif",
    fontWeight: 600,
    fontSize: "24px"
  }
});

const StyledIconButton = styled(Box)({
  position: "absolute",
  top: "-20px",
  right: "-15px",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "purple",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
})

const ScrollableContainer = styled(Box)({
  flex: "1",
  height: "100%",
  overflowY: "auto",
  padding: "0 16px 16px 16px",
  
  "& .react-player": {
    position: "static",
    top: 0,
    left: 0,
  },
});

const FixedFooter = styled(Box)({
  width: "100%",
  boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
  borderTop: "1px solid #e0e0e0",
  backgroundColor: "#ffffff"
});

const ResponsiveButtonFirst = styled(Button)({
  display: "flex !important",
  width: "256px",
  maxWidth: "100%",
  height: "40px !important",
  padding: "16px 32px !important",
  justifyContent: "center !important",
  alignItems: "center !important",
  gap: "10px !important",
  borderRadius: "12px !important",
  backgroundColor: "#fff !important",
  border: "1px solid #e47004",
  color: "#e47004",
  fontSize: "16px !important",
  fontStyle: "normal !important",
  fontWeight: 600,
  lineHeight: "16px !important",
  marginRight: "30px",
  textTransform: "capitalize"
});

const ResponsiveButtonSecond = styled(Button)({
  display: "flex !important",
  width: "256px",
  maxWidth: "100%",
  height: "40px !important",
  padding: "16px 32px !important",
  justifyContent: "center !important",
  alignItems: "center !important",
  gap: "10px !important",
  borderRadius: "12px !important",
  backgroundColor: "#652786 !important",
  color: "#fff !important",
  fontSize: "16px !important",
  fontStyle: "normal !important",
  fontWeight: 600,
  lineHeight: "16px !important",
  textTransform: "capitalize"
});

const TableWrapper = styled(Box)({
  width: "100%",
  "& .table thead .tr .th:nth-child(2)":{
    "& p":{
      marginLeft: "92px !important",
      justifyContent: "flex-start !important",
    }
  },
  "& .table tbody .tr .td:nth-child(2)":{
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px"
  },
  "& .table tbody .tr":{
   cursor:"pointer"
  },
  "& .table tbody .rowBg":{
    backgroundColor: "#80008012"
  }
})

const CreateGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox":{
    width: "max-content",
    marginLeft: "auto"
  }
});

const ImageComponent = styled("img")({
  width: "100%",
  height: "100%"
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const MainInnerBox = styled(Box)({
  width: "727px",
  height: "540px",
  marginTop: "40px"
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-1px"
});

const BadgeContentComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});

const StyledWrapperContainer = styled(Box)({
  position: "relative",
  display: "flex",
  maxWidth: "520px",
  width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%"
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .allBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2",
  }
});

const InputSearch = styled(Grid)({
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },
});

const DialogueMainComponent = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#652786",
  width: "729px",
  height: "221px"
});

const ImageInnerComponent = styled("img")({
  width: "48px !important",
  height: "48px !important"
});

const ComponentButton = styled(Box)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif"
});

const CreateNewComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: ' flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize'
})

const CreateContentModalContainer =  styled(Box)({
  display:'flex',
  justifyContent:'center',
  borderRadius:"10px",
  gap:'1px',
  background:"white",
  "@media (max-width:500px)": {
    flexDirection:"column"
  },
})


const VideoPlayerBox =  styled('div')({
  position: "relative",
  '& > div:first-of-type': {
    width: "100% !important",
  height: "100% !important",
  }
})


const CreateContentButtons = styled(Box)({
  display: "flex",
  flexDirection: "column", 
  alignItems: "center", 
  background:"#652786",
  gap:'5px',
  padding:"75px",
  width:"241px",
  height:"221px",
  justifyContent:"center",
  '&:hover':{
    cursor:"pointer"
  },
  "@media (max-width:991px)": {
    width:"100px",
    height:"100px",
    padding:"80px",
  },
  "@media (min-width:600px)": {
    padding:"80px",
  },
})
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  sorryText:{
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center" as 'center',
    color:"#787776"
  },
  mainImg_box: {
    borderRadius:"16px 16px 0px 0px",
    position:"relative" as "relative",
    display:"flex",
    justifyContent:"center",
    maxWidth:"100%",
    height:"100%"
},
selectBox:{
  padding: '2px 8px',
  width: 'auto',
  height: 'auto',
  borderRadius: '88px',
  gap: '10px',
  backgroundColor: '#787776',
  appearance: 'none' as 'none',
  textAlign:"center" as 'center',
  border: 'none',
  color:"white",
   fontWeight:500,
   fontSize:"10px",
},
  downloadFileSectionCss:{
    justifyContent:"center",
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
    height:"100%",background:"#FCF8F8",
    gap:"16px",width:"100%",
},
main_courseImg: {
  cursor:"pointer",
  maxWidth:"100%"
},
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  Accordion: {
    height: '56px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  },
  videoControlSection:{
    position:"absolute" as 'absolute',
  bottom:0,left:0,backgroundColor:"#1C1B1B",
  width:"100%",height:"44px",display:"flex",alignItems:"center",
  gap:"8px",padding:"0 8px"
},
downloadTextCss:{
  fontWeight:600,
  fontSize:"18px",
  color:"#787776"
      },
  accordionContent: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
  AccordionDetails: {
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: 999,
  },
  AccordionSummary: {
    backgroundColor: '#6C328B',
    height: '56px',
    borderRadius: '15px',
    width: '192px',
  },
  course1_btn: {
    background: '#6C328B',
    borderRadius: "16px",
    height: "56px",
    width: "196px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize"
  },
  course2_btn: {
    background: '#6C328B',
    borderRadius: "16px",
    height: "56px",
    width: "196px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize"
  }
};
// Customizable Area End
