import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  // Customizable Area Start
  fullScreen: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  currRating: any;
  grapValues: any;
  allCourseLibraryData: any;
  open: any;
  value: number;
  priceFree: any;
  toggleState: any;
  tabIndex: any;
  dropdownState: boolean;
  folderData: any;
  checked: boolean;
  folderData1: any;
  folderData2: any;
  homeScreenData: any;
  count: number;
  userSearch: string;
  getUserData: any;
  userData: any;
  page:number;
  checked1:any;
  dropdownAnchor: boolean;
  dropdownTwoAnchor: boolean;
  dropdownGroups: {
    title: string;
    toFilter: string;
  }[];
  dropdownTwoOptions: {
    id: number,
    name: string
  }[];
  selectedGroup: {
    title: string;
    toFilter: string;
  } | null;
  selectedOption: {
    id: number,
    name: string
  } | null;
  displayData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  GetUserData: any;
  GettabUserData: any;
  GettabBranchData: any;
  GetSearchData: any;
  groupListApiCallId: any;
  displayDataApiCallId: any;
  dropdownRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: "",
      grapValues: "Week",
      currRating: "",
      allCourseLibraryData: {},
      priceFree: false,
      open: true,
      value: 1,
      toggleState: 1,
      tabIndex: [],
      dropdownState: true,
      homeScreenData: {},
      userData: {},
      checked: false,
      checked1: [],
      count: 1,
      page:1,
      userSearch: "",
      getUserData: {},
      folderData: {},
      folderData1: [
        {
          id: 1,
          team: "Team Name",
          user: "User Name",
          name: "None",
        },
        {
          id: 2,
          team: "Team Name",
          user: "User Name",
          name: "Branch",
        },
        {
          id: 3,
          team: "Team Name",
          user: "User Name",
          name: "Team",
        },
      ],
      folderData2: [
        {
          id: 1,
          name: "Branch",
        },
        {
          id: 2,
          name: "Team",
        },
      ],
      dropdownAnchor: false,
      dropdownTwoAnchor: false,
      dropdownGroups: [
        { title: "Users", toFilter: "user" },
        { title: "Teams", toFilter: "team" },
        { title: "Branches", toFilter: "branch" },
      ],
      dropdownTwoOptions: [],
      selectedGroup: null,
      selectedOption: null,
      displayData: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.dropdownRef = React.createRef();
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
    // Customizable Area Start
    // this.getXpandLibraryData();
    // this.tabUserData();
    // this.tabBranchData();
    this.fetchGroupList();
    this.fetchDisplayData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetXpandLibraryData) {
          this.setState({ homeScreenData: responseJson });
            this.getUserData();
        }
        if (apiRequestCallId === this.GetUserData) {
          this.setState({ getUserData: responseJson });
        }
        if (apiRequestCallId === this.GettabUserData) {
          this.setState({ userData: responseJson });
        }
        if (apiRequestCallId === this.GettabBranchData) {
          this.setState({ folderData: responseJson });
        }
        if (apiRequestCallId === this.GetSearchData) {
          this.setState({ userData: responseJson });
          if (this.state.userSearch === "") {
            this.tabUserData();
          }
        }
        if (apiRequestCallId === this.groupListApiCallId) {
          this.setState({dropdownTwoOptions: responseJson.data})
        }
        if (apiRequestCallId === this.displayDataApiCallId) {
          this.setState({homeScreenData: responseJson})
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
    },
  };

  btnShowHideImageProps = {};

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleChange = (event: any) => {
    this.setState({ checked: event.target.checked });
  };

  handleChange1 = (event: any,id:number) => {
     console.log(id);
    this.setState({ checked1: id});
  };

  handleDropdownClick = () => {
    this.setState({ dropdownState: !this.state.dropdownState });
  };

  handleTabChange = (event: any, newTabIndex: any) => {
    this.setState({ tabIndex: newTabIndex });
    this.setState({ open: !this.state.open });
  };

  handleTabClick = () => {
    this.setState({tabIndex:[]})
  }

  pagination = (evaent:any,value:any) => {
    this.setState({page:value}, ()=> 
      // this.getXpandLibraryData()
      this.fetchDisplayData()
    ) 
   
}

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  tabBranchData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GettabBranchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchData = (event: any) => {
    this.setState({ userSearch: event.target.value });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search?query=${this.state.userSearch}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tabUserData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GettabUserData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetUserData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home?page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Handles dropdown state
  hanldeDropdown = (event: React.MouseEvent<HTMLDivElement>, dropdownNum: number) => {
    if (dropdownNum === 1) {
      this.setState((prevState) => ({
        dropdownAnchor: !prevState.dropdownAnchor,
      }))
    } else {
      this.setState((prevState) => ({
        dropdownTwoAnchor: !prevState.dropdownTwoAnchor,
      }))
    }
  }

  // Handles dropdown background color
  handleBackgroundColor = (value: any) => {
    return value ? "#FFFFFF" : "#fcf6f6"
  }

  // Handles dropdown text color
  handleTextColor = (value: any) => {
    return value ? "#1C1B1B" : "#ADAAAA"
  }

  // Handles dropdown select changes
  handleDropdownGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const selectedGroup = 
    this.state.selectedGroup?.toFilter === selectedValue 
      ? null 
      : this.state.dropdownGroups.find((group) => group.toFilter === selectedValue) || null;
    
    this.setState(({
      selectedGroup: selectedGroup,
      dropdownAnchor: false,
      selectedOption: null,
    }), () => {
      this.fetchGroupList();
      this.fetchDisplayData();
    });
  };

  // Handles dropdown label
  handleLabel = () => {
    if (this.state.selectedGroup?.title === "Users") {
      return "User"
    } else if (this.state.selectedGroup?.title === "Teams") {
      return "Team"
    } else if (this.state.selectedGroup?.title === "Branches") {
      return "Branch"
    } else {
      return "Name"
    }
  }

  // Handles Dropdown Changes
  handleDropdownTwoChange = (value: any) => {
    this.setState(({
        selectedOption: value
    }), () => {
      this.fetchGroupList();
      this.fetchDisplayData();
    });
  }

  // API request to Fetch Groups
  fetchGroupList = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.groupListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupListApi + this.state.selectedGroup?.toFilter.toLocaleLowerCase()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // API request to Fetch Display Data
  fetchDisplayData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.displayDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_admin/home?page=${this.state.page}&filter_by=${this.state.selectedGroup?.toFilter || ""}&filter_id=${this.state.selectedOption?.id || ""}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}