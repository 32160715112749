import React from 'react'
// Customizable Area Start
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import AddvideoController from "./AddvideoController.web";
import './style.css';

const Play_video = require("../assets/Edit_play.png");

class Editvideo extends AddvideoController {
    constructor(props: any) {
        super(props);
    }
    async componentDidMount() {
        this.getEditVideoData();
    }

    render() {
        return (
            <Box>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={styles.title_text}>"{this.state.editVideoData?.data?.attributes?.title}" Preview</Typography>
                    </Grid>
                    <Box style={styles.container_box}>
                        <Box style={styles.img_box}>
                            {/* <Box style={{ backgroundColor: 'black', width: '100%', height: '420px',borderRadius:'20px' }}>
                        </Box> */}
                            <img style={styles.img} src={this.state.editVideoData?.data?.attributes?.video_thumb?.url} />
                            {this.state.themeMode ? <Button style={styles.play_img} component="label">
                                <img src={Play_video} />
                                <input hidden accept="video/*"
                                test-id="handleImageChange"
                                    type="file" onChange={(e) => this.handleImageChange(e)} />
                            </Button> : <img src={Play_video} style={styles.play_img} />}

                        </Box>

                        <Paper style={styles.paper}>
                            <Grid container spacing={2} style={styles.first_grid}>
                                <Grid item lg={3}>
                                    <Box style={styles.mainBox_grid}>
                                        <Typography variant="subtitle2" style={styles.typography}>Add To Content Library Folder</Typography>
                                        <FormControl variant="outlined" style={styles.form_control}>
                                            <InputLabel id="demo-simple-select-outlined-label">{this.state.editVideoData?.data?.attributes?.folder_name}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                               
                                                label="Age"
                                                disabled={this.state.themeMode ? false : true}
                                            >
                                                
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item lg={3}>
                                    <Box style={styles.mainBox_grid}>
                                        <Typography variant="subtitle2" style={styles.typography}>Tag (optional)</Typography>
                                        <FormControl variant="outlined" style={styles.form_control}>
                                            <InputLabel id="demo-simple-select-outlined-label">Tag</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                               
                                                label="Age"
                                                disabled={this.state.themeMode ? false : true}
                                            >
                                                <MenuItem value="">
                                                    <em>Tag</em>
                                                </MenuItem>
                                                <MenuItem value={10}>Tag</MenuItem>
                                                <MenuItem value={20}>Tag</MenuItem>
                                                <MenuItem value={30}>Tag</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item lg={3}>
                                    <Box style={styles.mainBox_grid}>
                                        <Typography variant="subtitle2" style={styles.typography}>Link</Typography>
                                        <FormControl variant="outlined" style={styles.form_control}>
                                            <InputLabel id="demo-simple-select-outlined-label">{this.state.editVideoData?.data?.attributes?.link}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                
                                                label="Age"
                                                disabled={this.state.themeMode ? false : true}
                                            >
                                                
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item lg={3}>
                                    <Box style={styles.mainBox_grid}>
                                        <Typography variant="subtitle2" style={styles.typography}>Thumbnail</Typography>
                                        <img style={styles.video_thumb} src={this.state.editVideoData?.data?.attributes?.video_thumb?.url} />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box style={styles.second_box}>
                                <Typography variant="subtitle2" style={styles.typography}>Title</Typography>
                                <TextField className='Enter_Title' disabled={this.state.themeMode ? false : true} value={this.state.editVideoData?.data?.attributes?.title} fullWidth placeholder="Enter Title" variant="outlined" size="small"
                                    name="Enter_Title"
                                />
                            </Box>

                            <Box style={styles.third_box}>
                                <Typography variant="subtitle2" style={styles.typography}>Description</Typography>
                                <TextField className='Enter_Description' disabled={this.state.themeMode ? false : true} value={this.state.editVideoData?.data?.attributes?.description} fullWidth placeholder="Enter Description" variant="outlined" size="small"
                                    name="Description"
                                />
                            </Box>
                        </Paper>
                    </Box>
                    <Box style={styles.button_box}>
                        {this.state.themeMode ?
                            <Button style={styles.button} test-id="Save1" onClick={() => this.props.history.push('/ManageContentLibrary')} >Save Changes</Button> :
                            <Button style={styles.button} test-id="Save2" onClick={() => this.setState({ themeMode: true })}>Edit</Button>}
                    </Box>
                </Grid>
            </Box>
        )
    }
}

const styles = {
    title_text: {
        fontSize: '48px',
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize"
    },
    container_box: {
        padding: '0 5%',
        width: '100%'
    },
    img_box: {
        width: '100%',
        height: '420px',
        marginTop: '25px'
    },
    img: {
        width: '100%',
        height: '420px',
        borderRadius: '20px'
    },
    paper: {
        width: '100%',
        height: '600px',
        boxShadow: 'rgb(213 201 201 / 25%) -6px -6px 25px, rgb(199 187 187 / 25%) 6px 6px 25px',
        marginTop: '5%',
        borderRadius: '15px'
    },
    first_grid: {
        padding: '0 2%'
    },
    mainBox_grid: {
        marginTop: '25px'
    },
    form_control: {
        width: '100%'
    },
    typography: {
        marginBottom: '10px'
    },
    second_box: {
        padding: '0 2%',
        marginTop: '2%'
    },
    third_box: {
        padding: '0 2%',
        marginTop: '3%'
    },
    video_thumb: {
        width: '100%',
        height: '115px'
    },
    button: {
        width: '200px',
        height: '56px',
        background: '#653889',
        color: '#fff',
        marginTop: '30px',
        borderRadius: '12px',
    },
    button_box: {
        margin: 'auto auto auto 82%',
    },
    play_img: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        marginTop: '-24%',
        textAlign: 'center' as 'center',
    }

}

export default Editvideo;
// Customizable Area End