import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, emoji1, emoji2, emoji3, emoji4, verysadselectedImg, sadselectedImg, happyselectedImg, veryHappyunselectedImg } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
const Emoji3 = "";
const Emoji1 = "";
const Emoji2 = "";
const Emoji4 = "";

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  allXpandLibararyData: any;
  grapValues: any;
  postXpandLibararyData: any;
  search: any;
  value: any;
  RatingEmoji: number;
  txtCommentMsg: string;
  ExtCommentMsg: string;
  selectedEmojiId:string;
  Emoji: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseCompletionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  Last_VideoTrackData: any;
  MessageReward: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtCommentMsg: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      allXpandLibararyData: {},
      postXpandLibararyData: {},
      search: "",
      value: {},
      RatingEmoji: 0,
      ExtCommentMsg: "",
      selectedEmojiId: "love",
      Emoji: [
        {
          id: "no_mood",
          emoji: emoji3,
          selected: verysadselectedImg
        },
        {
          id: "sad",
          emoji: emoji1,
          selected: sadselectedImg
        },
        {
          id: "happy",
          emoji: emoji2,
          selected: happyselectedImg
        },
        {
          id: "love",
          emoji: veryHappyunselectedImg,
          selected: emoji4
        },

      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.Last_VideoTrackData) {
          this.setState({ allXpandLibararyData: responseJson }, () => {
            if (this.props.history.location.state.data?.achievements?.data) {
              this.props.history.push('AchievementListing')
            } else if (this.props.history.location.state.data?.rewards?.data) {
              this.props.history.push('AllRewardsListing')
            } else {
              this.props.history.push('library');
            }
          }
          )
        }
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getStatus = (): string => {
    if(this.props.history.location.state.data.achievements?.data) {
      return " and an achievement was completed"
    } else if(this.props.history.location.state.data.rewards?.data) {
      return " and earned a reward"
    } else {
      return ""
    }
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleRating = (newRating: any) => {
    this.setState({ RatingEmoji: newRating })
  }

  handleEmojiClick = (id: any) => {
    this.setState({
      selectedEmojiId: id
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };



  handleNavigate = (data: any) => {
    if (data.attributes.expand_type === 'Course') {
      this.props.history.push('/Course', { name: data })
    } else if (data.attributes.expand_type === 'Program') {
      this.props.history.push('/Program', { name: data })
    }
  }



  postplayButton = async (id: any) => {
    this.props.history.push('/Course', { name: id })
  }

  reviewSubmit = () => {
    let formdata = new FormData();
    formdata.append("rating", this.state.value);
    formdata.append("comment", this.state.txtCommentMsg);
    formdata.append("emoji", this.state.selectedEmojiId);
    formdata.append("extra_comment", this.state.ExtCommentMsg);
    formdata.append("reviewable_id", this.props.history.location.state.data.course_id);
    formdata.append("reviewable_type", "BxBlockCoursecreation::Course");

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.Last_VideoTrackData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_reviews/reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Customizable Area End
}