Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiContentType = undefined;
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "bx_block_videos4/channel_videos/";
exports.exampleAPiMethod = "POST";
exports.reviewAPiEndPoint = "bx_block_library2/reviews";
exports.reviewAPiMethod = "POST";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
exports.stockImageApiEndPoints = "bx_block_admin/thumbnail_libraries";
exports.getFreeTrialApiEndPoint = "bx_block_coursecreation/courses/check_course_free_or_not";
exports.stockImageApiEndPoint = "bx_block_attachment/stock_images";
exports.addVideoApiEndPoint = "bx_block_coursecreation/course_videos";
exports.getCourseDetailApiEndPoint = "bx_block_coursecreation/courses";
// Customizable Area End
