import { Box, Button, Dialog ,makeStyles} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
//@ts-ignore
import { Accept, useDropzone, FileRejection } from 'react-dropzone';
import {styled} from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import DeleteModal from './DeleteDialog/DeleteModal';
const closeIcon = require("./icon_close.svg");

const useStyles = makeStyles((theme) => ({
  ConfirmationButton: {
    display: 'flex',
    flexDirection: 'row',
    width:"100%",
    justifyContent: 'space-between', 
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
      gap:10,
    },
  },
  SelectedFileDetailsContainer:{
    alignItems: 'center', 
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
    },
  },
  acceptedFileMessage:{
    margin: 0,
    fontSize: "22px",
    fontWeight: 600,
    color: "#1C1B1B",
    lineHeight: "33px",
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down(700)]: {
      textAlign:"center"
    },
    
  }
  , close_icon:{
    position:"absolute",
    top:"-8px",
    right:"-8px",
  },
}));

const DragAndDropFileUploadWithProgress = (props: any) => {
  const classes = useStyles()
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('notStarted');
  const [isOpenFileChangeModal, setIsOpenFileChangeModal] = useState(false)
  const [selectFileImage, setSelectFileImage] = useState<any>(null)
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [fileSelectionError, setFileSelectionError] = useState<any>(null)
  const inputRef = useRef(null);
  const imageFileTypes = ['image/jpeg', 'image/png', 'image/x-raw', 'image/webp'];
  const tiffFileType = ['image/tiff', 'image/x-tiff',]
  const videoFileTypes = ['video/mp4', 'video/quicktime', 'video/x-h264', 'video/x-h265','video/x-matroska'];
  const audioFileTypes = ['audio/mpeg', 'audio/aac', 'audio/x-m4a', 'audio/wav']
  const imageFile = ['image/jpeg', 'image/png', 'image/webp', 'image/heif', 'image/heic']
  const documentFileTypes = ['application/pdf']
  const [onClickOnDropZone, setOnClickOnDropZone] = useState(false)
  const [maxFileSize, setMaxFileSize] = useState(1073418240)
  useEffect(() => {
    setUploadStatus(props.uploadStatus);
    setUploadProgress(props.progress)
    if (!!props.selectedFile) {
      setOnClickOnDropZone(true)
    }
    if (props.type === 'video') {
      setMaxFileSize(1073741824)
    } else if (props.type === 'audio') {
      setMaxFileSize(209715200)
    } else if (props.type === 'images') {
      setMaxFileSize(5242880)
    } else if(props.isThumbnail){
      setMaxFileSize(52428800)
    } else{
      setMaxFileSize(104857600)
    }

  }, [props])

  const onClose = () => {
    setIsOpenFileChangeModal(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    setUploadProgress(0);
    const file = acceptedFiles[0];
    if ([...imageFileTypes,...tiffFileType].includes(file.type) && file.size > 5242880) {
      setFileSelectionError("Image file size exceeds the 5MB limit. Please upload a smaller image.");
      return;
    }
    setSelectFileImage(imageType(file));
    props.onSelectFile(file);
    props.setFieldTouched();
    setIsFileSelected(true)
    setFileSelectionError(null)
  }, [])

  const handleDropRejected = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 1) {
      toast.error("Please select one file at a time!")
    } else {
      const rejectedFileErrorCode = rejectedFiles[0].errors[0].code;
      if (rejectedFileErrorCode === 'file-too-large') {
        if (!!props.selectedFile) {
          toast.error(props.isThumbnail?"File size exceeds the limit. Please upload files up to 50MB.":`Maximum ${getFileSize(props.type)} file is allowed for ${props.type}`)
        } else {
          setFileSelectionError(props.isThumbnail?"File size exceeds the limit. Please upload files up to 50MB":getFileSizeError(props.type))
        }
      } else if (rejectedFileErrorCode === 'file-invalid-type') {
        if (!!props.selectedFile) {
          toast.error(props.isThumbnail?"Only jpg, png, tif are supported file types":`Please try with file type with ${
            getFileType(props.type)}`)
        } else {
          setFileSelectionError(props.isThumbnail?"Only jpg, png, tif are supported file types":getFileTypeError(props.type))
        }
      } else {
        toast.error("This is not allowed")
      }
    }
  };
  const getFileSize = (type: string) => {
    if (type === 'video')
      return '1GB'
    else if (type === 'audio')
      return '200MB'
    else if (type === 'images')
      return '5MB'
    else
      return '100MB'
  }
  const getFileType = (type: string) => {
    if (type === 'video')
      return 'Mp4, MOV, h.264,x-matroska or h.265'
    else if (type === 'audio')
      return '2Mp3, AAC, m4a, or WAV'
    else if (type === 'images')
      return 'JPG, PNG, JPEG, WEBp,heif or heic'
    else
      return 'PDF, JPG, PNG, TIFF,zip or RAW'
  }
  const ThumbnailFileType = () => {
    return {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/tiff': ['.tiff', '.tif'],       
         'image/x-tiff': ['.tiff', '.tif'],

    };
  };
  const fileType = () => {
    switch (props.type) {
      case "video":
        return {
          'video/mp4': ['.mp4'],
          'video/quicktime': ['.mov'],
          'video/x-h264': ['.h264'],
          'video/x-h265': ['.h265'],
          "video/x-matroska":[".matroska"]
        };
      case "audio":
        return {
          'audio/mpeg': ['.mp3'],
          'audio/aac': ['.aac'],
          'audio/x-m4a': ['.m4a'],
          'audio/wav': ['.wav']
        };
      case "images":
        return {
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/webp': ['.webp'],
          'image/heif': ['.heif'],
          'image/heic': ['.heic']
        };
      default:
        return {
          'application/pdf': ['.pdf'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/tiff': ['.tiff', '.tif'],
          'image/x-raw': ['.raw'],
          'image/x-tiff': ['.tiff', '.tif'],
          'image/webp': ['.webP'],
          'application/zip':['.zip']
        }
    }
  }
  const getFileTypeError = (type: string) => {
    switch (type) {
      case "video":
        return "*The file you are trying to upload is not allowed. Please try with Mp4, MOV, h.264, or h.265";
      case "audio":
        return "*The file you are trying to upload is not allowed. Please try with Mp3, AAC, m4a, or WAV";
      case "images":
          return "*The file you are trying to upload is not allowed. Please try with JPG, PNG, JPEG, WEBp,heif or heic";
      default:
        return "*The file you are trying to upload is not allowed. Please try with PDF, JPG, PNG, TIFF, or RAW"
    }
  }
  
  const getFileSizeError = (type: string) => {
    if (type === 'video') {
      return "*Your file is larger than the 1GB limit. Please reduce the size of your file";
    } else if (type === 'audio') {
      return "*Your file is larger than the 200MB limit. Please reduce the size of your file";
    }else if (type === 'images') {
      return "*Your file is larger than the 5MB limit. Please reduce the size of your file";
    } else {
      return "*Your file is larger than the 100MB limit. Please reduce the size of your file";
    }
  }
  const subHeading = () => {
    switch (props.type) {
      case "video":
        return "Max. file size 1 GB and file formats Mp4, MOV, h.264, h.265";
      case "audio":
        return "Max. file size 200 MB and file formats Mp3, AAC, m4a, WAV";
      case "images":
          return "Max. file size 5 MB and file formats JPG, PNG, JPEG, WEBp,heif or heic";
      default:
        return "Max. file size 100 MB and file formats PDF, JPG, PNG, TIFF, ZIP,RAW"
    }
  }

  const imageType = (selectedFile: any) => {
    if (imageFileTypes.includes(selectedFile?.type)) {
      if(selectedFile?.url){
        return selectedFile.url ?? selectedFile.path;
      }
      if(selectedFile?.path){
        return URL.createObjectURL(selectedFile);
      }
      return "/icon_image.svg";
    } else if (tiffFileType.includes(selectedFile?.type)) {
      return "/icon_image.svg";
    } else if (videoFileTypes.includes(selectedFile?.type)) {
      return "/icon_video.svg";
    } else if (audioFileTypes.includes(selectedFile?.type)) {
      return "/icon_Audio.svg";
    } else if (documentFileTypes.includes(selectedFile?.type))
      return "/icon_Page.svg"
    else {
      return "/icon_Page.svg"
    }
  }
 
  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: handleDropRejected,
    accept: props.isThumbnail?ThumbnailFileType():fileType() as unknown as Accept,
    noClick: onClickOnDropZone,
    multiple: false,
    maxSize: maxFileSize,
  });
  const handleChangeClick=()=>{
    if(props.isThumbnail){
    props.selectedThumbnailFile();
    setIsFileSelected(false)
    }else if(props.isBulkUploading){
      setIsOpenFileChangeModal(true)
    }else{
      setIsOpenFileChangeModal(true)
    }
  }
  const handleDeletePopUp=()=>{
    if(props.isBulkUploading){
      props.selectedThumbnailFile();
      setIsFileSelected(false)
      onClose()
    }else{
      open()
      onClose()
    }
       
    }
  
    useEffect(() => {
      setSelectFileImage(imageType(props?.selectedFile));
    }, [props?.selectedFile]);
  
  return (
    <>
      <input {...getInputProps()} />
      {!props.selectedFile ?
           <div {...getRootProps()} ref={inputRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: isFileSelected ? "none" : '2px dashed ',
        borderColor: !!fileSelectionError ? "#EF2B4B" : "#929090",
        width: '100%',
        borderRadius: "8px",
        minHeight: '260px',
        backgroundColor: !!fileSelectionError ? "#E6234512" : "#FFFFFF",
        cursor: "pointer"
      }}>
        <div style={{ alignItems: 'center', display: "flex", flexDirection: "column", gap: "20px" ,}} onClick={props.isThumbnail || props.isBulkUploading ? open : () => {}}>
          {!!fileSelectionError ? <img style={{ width: "60px", height: "60px" }} src={props.errorImage} /> :
            <img style={{ width: "60px", height: "60px" }} src={props.image} />}
          <div style={{textAlign:"center", width:'100%', fontFamily: "'Poppins', sans-serif", fontSize: '22px', fontWeight: 600 }}>
            <span>Drag and Drop or </span>
            <span style={{ color: !!fileSelectionError ? "#EF2B4B" : "purple", cursor: "pointer", fontFamily: "'Poppins', sans-serif", fontSize: '22px', fontWeight: 600 }}>{' Browse '}</span>
            <span>to upload</span>
          </div>
          <p style={{ color: !!fileSelectionError ? "#EF2B4B" : "#ADAAAA", textAlign: "center",  fontFamily: "'Poppins', sans-serif", fontSize: "16px", fontWeight: 400 }}>{!!fileSelectionError ? fileSelectionError : props.isThumbnail?"Max. file size 50 MB and file formats jpg, png, tif":subHeading()}</p>
        </div></div>
        :
           <div {...getRootProps()} ref={inputRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: isFileSelected ? "none" : '2px dashed ',
        borderColor: !!fileSelectionError ? "#EF2B4B" : "#929090",
        width: '100%',
        borderRadius: "8px",
        minHeight: '260px',
        backgroundColor: !!fileSelectionError ? "#E6234512" : "#FFFFFF",
      }}>
        <>
          {uploadStatus === "uploading" ? <div style={{ display: 'flex', gap: 'px', flexDirection: "column", width: "100%", height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{fontFamily: "'Poppins', sans-serif", fontSize:"20px", fontWeight:600}}>{uploadProgress}%</span>
            <div style={{ width: '300px', height: '21px', backgroundColor: '#E0E0DF', borderRadius: '10px', overflow: 'hidden' }}>
              <div style={{
                width: `${uploadProgress}%`,
                height: '100%',
                background: 'linear-gradient(to right, #d2c0df, #652786 ',
                transition: 'width 0.1s ease-in-out',
                borderRadius: '10px'
              }} />
            </div>
            <p>{uploadProgress < 100 ? "Uploading File..." : "Uploaded"}</p>
            {!!props.uploadingMessage && uploadProgress < 100 && 
            <p style={{fontFamily: "'Poppins', sans-serif", fontSize:"16px", fontWeight:400,color:"#ADAAAA"}}>
               {props.uploadingMessage}</p>}
          </div> :
            <div className={classes.SelectedFileDetailsContainer}>
              {!!selectFileImage && <img src={selectFileImage} style={{ width: "80px", height: "80px" }} />}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {((uploadStatus === 'uploaded')||props?.selectedFile?.isEdit) 
                && <p className={classes.acceptedFileMessage}>File accepted</p>}
                {uploadStatus === 'upload_failed' && <p style={{
                  margin: 0,
                  fontSize: "22px",
                  fontWeight: 600,
                  color: "#1C1B1B",
                  lineHeight: "33px",
                  fontFamily: "'Poppins', sans-serif",
                }}>Failed</p>}
                <p style={{
                  marginBottom: 0,
                  marginTop: "10px",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#787776",
                  wordBreak:"break-all",
                  lineHeight: "22px",
                  fontFamily: "'Poppins', sans-serif",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '182px',
                }}>{props?.selectedFile?.name}</p>
                {!!fileSelectionError &&
                <p style={{ color: "#EF2B4B", textAlign: "center", fontSize: "16px", fontWeight: 400 }}>
                  {props.isThumbnail? "Max. file size 50 MB and file formats jpg, png, tif":subHeading()}
                </p>}
              </div>
              <Button style={{
                width: "186px",
                height: "56px",
                padding: "20px 32px 20px 32px",
                gap: "10px",
                borderRadius: "16px",
                border: "1px solid #E47004",
                opacity: "0px",
                color: "#E47004",
                textTransform: "capitalize",
                fontFamily: "'Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "14px",
                cursor: "pointer"

              }}
                onClick={handleChangeClick}
                data-test-id="btn_delete"
              >Change</Button>
            </div>}
        </>
        </div>
      }
       <DeleteModal
            data-test-id='delete-all-content'
            openDialog={isOpenFileChangeModal}
            headingText={`Change File`}
            btnCancelText="Cancel"
            btnOkText="Change"
            subHeading={props?.navigation?.getParam("type")==="editLesson"?"Are you sure you want to unlink this content?": "Are you sure you want to change the file? The current one will be cleared."}
            handleCancel={onClose}
            handleOk={handleDeletePopUp}
          />
     
    </>
  );
};

export default DragAndDropFileUploadWithProgress;
