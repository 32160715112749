// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";
const configJSON = require("./config.js");
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";

export interface BranchResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      branch_status: string,
      parent_id: number,
      teams: null,
      is_primary_branch: boolean,
      unique_accounts_count: number,
      created_by: string,
      parent_branch_name: string,
      active_user_accounts: number,
      branch_billing: {
        id: string
      }
    }
  }
}

interface TierPrice {
  branch_billing_id: number
}

export interface BranchBilling {
  id: string;
  type: string;
  attributes: BranchBillingAttributes;
}

export interface BranchBillingAttributes {
  id: number;
  billing_type: string;
  flat_fee: string;
  free_plan: boolean;
  is_flat_fee: boolean;
  payment_period: string;
  branch_id: number;
  flat_free_stripe_price_id: string;
  flat_free_stripe_product_id: string;
  pay_per_user_stripe_price_id: string;
  pay_per_user_stripe_product_id: string;
  session_id: string;
  stripe_subscription_id: string;
  pay_per_user: boolean;
  price_per_user: string;
  pay_per_active_user: boolean;
  estimated_total_amount: string;
  created_at: Date;
  updated_at: Date;
  tiered: boolean;
  total_price: string;
  total_user_count: number;
  tier_prices: TieredPrice[];
  payment_status: string;
  payment_history: PaymentHistory[];
}

export interface PaymentHistory {
  id: number;
  branch_billing_id: number;
  stripe_invoice_id: string;
  status: string;
  current_period_start: Date;
  current_period_end: Date;
  canceled_at: null;
  stripe_subscription_id: string;
  created_at: Date;
  updated_at: Date;
}

export interface TieredPrice {
  id: number;
  min_range: number;
  max_range: number;
  price_per_user: string;
  branch_billing_id: number;
  created_at: Date;
  updated_at: Date;
}

export const PAYMENT_STATUS = {
  PAID: "paid",
  PENDING: "pending"
}


interface UserTeams {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    is_selected: boolean,
  };
}

export interface AddContractResponse{
  id: number;
  type: string;
  attributes: {
      created_at: string;
      branch_detail_id: number;
      file: {
          id: number;
          url: string;
          filename: string;
      }
  }
}
interface ManageBranch {
  active_users: number;
  total_users: number;
}

interface BranchBillingData {
  url: string;
}

interface tierSection {
  id: string
  min: string;
  max: string;
  tierPrice: string;
}

export interface CheckboxSelectedListType { id: number, value: string, _destroy:boolean }

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  branchData: Array<BranchResponse>;
  branchId: number;
  parentBranch: string;
  branchName: string;
  branchManager: string;
  branchStatus: string;
  openUserDeleteDialog: boolean;
  openTeamDialog: boolean;
  dropdownAddTeams: boolean;
  anchorEl: HTMLElement | null;
  placeholderSearchTeamsText: string;
  teamsListing: Array<UserTeams>;
  teamsSelectedItems: Array<CheckboxSelectedListType>;
  teamsToDelete: Array<CheckboxSelectedListType>;
  loading: boolean;
  contracts: AddContractResponse[];
  selectedOption: string;
  isFreePlanEnabled: boolean;
  isFlatFeeEnabled: boolean;
  isPayPerUserEnabled:  boolean;
  isPerActiveUserEnabled: boolean;
  isTieredEnabled: boolean;
  paymentPeriodOption: string;
  amount: string;
  payPerUserAmount: string;
  totalUserBranchData: ManageBranch;
  tierSectionValues: tierSection[];
  errorMessage: string;
  activeUsers:number;
  totalUsers:number;
  addPaymentEnable: boolean;
  makeInactiveDialogue: boolean;
  branchBilling: BranchBillingData;
  branchBillingUrl: string;
  branchBillingId: string
  isEditBranchScreen: boolean;
  branchBillingDetails: BranchBilling
  isPaymentSuccess: boolean;
  isEditBranchBilling: boolean;
  saveChangesDisable: boolean;
}

interface SS {
  id: any;
}

export default class BranchDetailsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleSetSubmittingCallback: Function = () => { };
  branchListApiCallId: string = "";
  deleteUserDataApiCallId: string = "";
  getTeamListApiCallId: string = "";
  updateTeamsToUserApiCallId: string = "";
  postUserAPICallId: string = "";
  getTotalUsersBranchApiCallId: string = "";
  getBranchBillingIdApiCallId: string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;
  fileInputRef: React.RefObject<HTMLInputElement> | undefined;
  addContractApiCallId: string = "";
  deleteContractApiCallId: string = "";
  makeInActiveBranchApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      branchData: [],
      branchId: 0,
      parentBranch: "",
      branchName: "",
      branchManager: "",
      branchStatus: "",
      openUserDeleteDialog: false,
      openTeamDialog:false,
      dropdownAddTeams: false,
      anchorEl: null,
      placeholderSearchTeamsText: "",
      teamsListing: [],
      teamsSelectedItems: [],
      teamsToDelete: [],
      loading: false,
      contracts: [],
      selectedOption: "user_driven",
      isFreePlanEnabled: false,
      isFlatFeeEnabled:false,
      isPayPerUserEnabled:false,
      isPerActiveUserEnabled: false, 
      isTieredEnabled: false,
      paymentPeriodOption: "",
      amount: "",
      payPerUserAmount: "",
      totalUserBranchData: {
        active_users: 0,
        total_users: 0,
      },
      tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],
      errorMessage: "",
      activeUsers:0,
      totalUsers:0,
      addPaymentEnable: false,
      makeInactiveDialogue: false,
      branchBilling: {
        url: "",
      },
      branchBillingUrl: "",
      branchBillingId: "",
      isEditBranchScreen: true,
      branchBillingDetails: {} as BranchBilling,
      isPaymentSuccess: false,
      isEditBranchBilling: false,
      saveChangesDisable: false,
    };
    this.dropdownRef = React.createRef();
    this.fileInputRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    let userDataId = this.props?.navigation?.getParam('id')
    this.getBranchList(Number(userDataId));
    this.setState({ teamsSelectedItems: [] });
    this.getTeamsListings();
    this.getTotalUserBranch(userDataId);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.branchListApiCallId) {
        const teamsSelectedItemsData = responseJson.data.attributes.teams?.map((team: any) => ({
          id: Number(team.id),
          value: team.name
        })) || [];

        const teamsSelectedItemsIds: number[] = teamsSelectedItemsData.map((item: {id: number, name: string}) =>Number(item.id))
        const teamsSteamsListingFromStorage: Array<CheckboxSelectedListType> = await getStorageData("tempSelectedTeamInBranch", true) || [];
        const uniqueTeamData  = teamsSteamsListingFromStorage.filter((team) => !teamsSelectedItemsIds.includes(team.id))
        const combineItemsData = [...teamsSelectedItemsData, ...uniqueTeamData];

        const uniqueTeams: CheckboxSelectedListType[] = Array.from(new Set(teamsSelectedItemsData.map((team: CheckboxSelectedListType) => team.id)))
        .map(id => teamsSelectedItemsData.find((team: CheckboxSelectedListType) => team.id === id)!);
        const isbranchBillingGenerate: BranchBilling = responseJson.data?.attributes?.is_branch_billing_exist;
        const branchBilling: BranchBilling = responseJson.data?.attributes?.branch_billing;
        const paymentSelectedOption = branchBilling?.attributes?.billing_type
        const isFreePlan = branchBilling?.attributes?.free_plan;
        const isFlatFreePlan = branchBilling?.attributes?.is_flat_fee;
        const isPayPerUserPlan = branchBilling?.attributes?.pay_per_user;
        const isPerActiveUserPlan = branchBilling?.attributes?.pay_per_active_user;
        const isTieredPlan = branchBilling?.attributes?.tiered;
        const selectedPaymentPeriod = branchBilling?.attributes?.payment_period;
        const flatFee = branchBilling?.attributes?.flat_fee;
        const payPerUserAmount = branchBilling?.attributes?.price_per_user;
        const tierSectionValues = branchBilling?.attributes?.tier_prices.map((tier) => {
          let tierSectionValue = {
            id: String(tier.id),
            min: String(tier.min_range),
            max: String(tier.max_range),
            tierPrice: tier.price_per_user
          }
          return tierSectionValue;
        });
        const totalUsers = responseJson.data?.attributes?.total_users_count;
        const activeUsers = responseJson.data?.attributes?.active_user_accounts;
        const isPaymentSuccess = branchBilling?.attributes?.payment_status === PAYMENT_STATUS.PAID;
        const branchBillingId = responseJson.data.attributes?.branch_billing?.id;

        this.setState({
          branchData: responseJson.data,
          branchId: responseJson.data.attributes.id,
          parentBranch: responseJson.data.attributes.parent_branch_name,
          branchName: responseJson.data.attributes.name,
          branchManager: responseJson.data.attributes.created_by,
          branchStatus: responseJson.data.attributes.branch_status,
          teamsSelectedItems: combineItemsData,
          teamsToDelete: uniqueTeams,
          contracts: responseJson.data?.attributes?.branch_contracts,
          branchBillingDetails: responseJson.data?.attributes?.branch_billing,
          branchBillingId: branchBillingId,
          
          selectedOption: paymentSelectedOption || "user_driven",
          isFreePlanEnabled: isFreePlan,
          isFlatFeeEnabled: isFlatFreePlan,
          isPayPerUserEnabled: isPayPerUserPlan,
          isPerActiveUserEnabled: isPerActiveUserPlan, 
          isTieredEnabled: isTieredPlan,
          paymentPeriodOption: selectedPaymentPeriod,
          amount: flatFee,
          payPerUserAmount: payPerUserAmount,
          totalUsers: totalUsers,
          activeUsers: activeUsers,
          tierSectionValues: tierSectionValues || [],
          isPaymentSuccess: isPaymentSuccess,
          addPaymentEnable: isbranchBillingGenerate && !isPaymentSuccess ? true : false
        });
        if (branchBillingId) {
          this.getBranchBillingId();
        }
         await removeStorageData("tempSelectedTeamInBranch");
      } else if (this.getTeamListApiCallId === apiRequestCallId) {
        this.setState({
          teamsListing: responseJson.data
        })
      }
      if (apiRequestCallId === this.deleteUserDataApiCallId) {
        toast.success("Branch deleted successfully");
        this.props.navigation.navigate("ShowBranch")
      }
      if (apiRequestCallId === this.updateTeamsToUserApiCallId) {
        this.updateTeamsToUserResponse(from, message, responseJson)
      }
      this.handleAddContractResponse(apiRequestCallId, responseJson)
      this.handleDeleteContractResponse(apiRequestCallId, responseJson)
      if (apiRequestCallId === this.postUserAPICallId) {
        this.handleCreateUserResponse(responseJson)
      }else if (apiRequestCallId === this.getTotalUsersBranchApiCallId) {
        this.setState({
          totalUserBranchData: responseJson,
          activeUsers: responseJson.active_users,
          totalUsers: responseJson.total_users,
        })
      } else if (apiRequestCallId === this.getBranchBillingIdApiCallId) {
        this.setState({
          branchBilling: responseJson.data,
          branchBillingUrl: responseJson.data.url
        })
      }
      this.handleMakeInActiveBranchResponse(apiRequestCallId, responseJson)
    }
  }

  handleAddPayment = async () => {
    window.location.href = this.state.branchBillingUrl;
};
 
  handleAddContractResponse = (apiRequestCallId: string, responseJson: {data: AddContractResponse}) => {
    if(apiRequestCallId === this.addContractApiCallId) {
      this.setState({contracts: [...this.state.contracts, responseJson.data] as AddContractResponse[]})
    }
  }

  handleDeleteContractResponse = (apiRequestCallId: string, responseJson: {data: {}}) => {
    if(apiRequestCallId === this.deleteContractApiCallId) {
      toast.success(`Contracts PDF deleted successfully`);
    }
  }

  navigateToBranchDetailsEdit = (branchId: number) => {
    this.props.navigation.navigate("editBranch", {id: branchId})
  };

  handleCreateUserResponse = async (response: any) => {
    const {selectedOption, isFreePlanEnabled} = this.state;
    if (response?.data) {
      toast.success(`Saved Billing Details`);
      this.setState({addPaymentEnable: true, saveChangesDisable: true})
      let userDataId = this.props?.navigation?.getParam('id')
      if((selectedOption === "user_driven") || (selectedOption === "admin_driven") && isFreePlanEnabled) {
        this.navigateToBranchDetailsEdit(Number(userDataId));
        this.setState({isEditBranchScreen: true})
      } else {
        this.getBranchList(Number(userDataId));
      }
      return;
    } else  {
      if(response?.error) {
        toast.error(response?.error);
      }else {
        toast.error("The min_range of a tier should be one more than the max_range of the previous tier");
      }
    }
  };

  handleMakeInActiveBranchResponse = (apiRequestCallId: string, responseJson: {data: {}}) => {
    if(apiRequestCallId === this.makeInActiveBranchApiCallId) {
      this.setState({makeInactiveDialogue: false})
      let userDataId = this.props?.navigation?.getParam('id')
      this.getBranchList(Number(userDataId));
    }
  }

  updateTeamsToUserResponse = (from: string, message: Message, responseJson: any) => {
    this.setState({ loading: true });
    if (responseJson?.data) {
      toast.success(`Teams update successfully`, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload(); 
      }, 1000); 
    } else {
      responseJson?.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
    setTimeout(() => {
      this.setState({ placeholderSearchTeamsText:"",openTeamDialog: !this.state.openTeamDialog, loading: false }); 
  }, 3000);
  };

  handleAssignContent = (id: number) =>{
    const branchId = this.state.branchId;
    this.props.navigation.navigate("EditBranchAssignContent", {id: branchId});
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownAddTeams: !prevState.dropdownAddTeams,
      placeholderSearchTeamsText:""
    }));
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownAddTeams: false,
    })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.dropdownHandlerClose();
    }
  };

  handleTeamsSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchTeamsText: event.target.value,
    })
  }

  tagsCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, teamId: number) => {
    const { checked, name } = event.target;
    const parsedTeamId = Number(teamId);
    if (checked) {
      const isAlreadySelected = this.state.teamsSelectedItems.find(team => team.id === parsedTeamId);
      if (!isAlreadySelected) {
        this.setState(prevState => ({
          teamsSelectedItems: [...prevState.teamsSelectedItems, { id: parsedTeamId, value: name, _destroy: false }]
        }));
      }
    } else {
      this.setState(prevState => ({
        teamsSelectedItems: prevState.teamsSelectedItems.filter(team => team.id !== parsedTeamId)
      }));
    }
  }

  handleClearTeamNames = (team: CheckboxSelectedListType) => {
    const numericTeamId = Number(team.id);
    this.setState(prevState => ({
      teamsSelectedItems: prevState.teamsSelectedItems.filter(t => t.id !== numericTeamId),
    }));
  };

  getBranchList = async (branchId: number) => {
    this.branchListApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.branchListEndPoint2}/${branchId}`,
    });
  };

  handleDeleteUserData = () => {
    this.deleteBranchValue();
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  UpdateData = ( teamsAccountsAttributes: any[]) => {
    const selectedTeamIds = this.state.teamsSelectedItems.map((team: any) => team.id);
    const teamsToDeleteIds = this.state.teamsToDelete.map((team: any) => team.id
    );
    const teamsToRemove = teamsToDeleteIds.filter((id: number) => !selectedTeamIds.includes(id));
    const teamsToAdd = selectedTeamIds.filter((id: number) => !teamsToDeleteIds.includes(id));
    if (teamsToRemove.length) {
      teamsToRemove.forEach((id: number) => {
        teamsAccountsAttributes.push({
          id: id,        
          _destroy: true,
        });
      });
    }
    if (teamsToAdd.length) {
      teamsToAdd.forEach((team_id: number) => {
        teamsAccountsAttributes.push({
          team_id: team_id, 
          _destroy: false,
        });
      });
    }
    return teamsAccountsAttributes;
  };

  updateTeamsToUser = async (values: any) => {
    const teamsAccountsAttributes: any[] = [];
    this.UpdateData(teamsAccountsAttributes);
    let userData = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const userApiData = {
      "branch_data": {
        "branch_teams_attributes": teamsAccountsAttributes
      }
    };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateTeamsToUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userApiData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.branchListEndPoint2}/${userData}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  deleteBranchValue() {
    let branchData = this.props?.navigation?.getParam('id')
    this.deleteBranchData({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      end_Point: `${configJSON.branchListEndPoint2}/${branchData}`,
    });
  }

  getTeamsListings = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamListingApiEndPoint}?search=${this.state.placeholderSearchTeamsText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  deleteBranchData(data: {
    content_Types?: string;
    end_Point?: string;
    method?: string;
    body?: {};
    type?: string;
  }) {
    let branchData = this.props?.navigation?.getParam('id')
    const { content_Types, method, end_Point } = data;
    const header = {
      "Content-Type": content_Types,
      token: localStorage.getItem("token"),
    };
    const deleteBody = {
      "branch_data":{
          "parent_id": branchData
      }
    }
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(deleteBody)
    );
    this.deleteUserDataApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      end_Point
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  cancelLessonDeleteDialog = () => {
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  teamLessonDialog = () =>{
    this.setState({openTeamDialog:!this.state.openTeamDialog})
  }
  
  navigateToManageTeam = (teamId: number) => {
    setStorageData("tempSelectedTeamInBranch", JSON.stringify(this.state.teamsSelectedItems))
    this.props.navigation.navigate("CreateTeam", {type:"Edit", id: teamId, redirectToBranch: "redirectToBranch"})
  } 
  openPDFFileSelect = () => {
    if(this.state.contracts.length >= 3) {
      toast.warning("You can not add more than 3 contract files")
    } else{
    this.fileInputRef?.current?.click()}
  }

  handleFileChange = (event: any) => {
    const file = event?.target?.files[0];
    if (file.type !== "application/pdf") {
      toast.warning("Please upload a PDF file.");
    } 
    else if (file.size > 2 * 1024 * 1024) {
      toast.warning("You can not upload pdf size more than 2 MB")
    }
    else {
      this.addContractFile(file);
    }
    event.target.value = null
  };

  addContractFile = (file: File) => {

    let branchId = this.props?.navigation?.getParam('id')
    const header = {
      token: localStorage.getItem("token"),
    };

    const formData = new FormData();
    formData.append("branch_id", branchId)
    formData.append("file", file);

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.addContractApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addContractEndPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  deleteContractFile = (contractId: number) => {

    const filterContractFiles = this.state.contracts.filter((contract) => Number(contract.id) !== contractId)
    this.setState({contracts: filterContractFiles});

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteContractApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteContractEndPoint}${contractId}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }


  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    this.setState({
      selectedOption: selectedValue,
      isFlatFeeEnabled: false,
      isPayPerUserEnabled: false,
      isTieredEnabled: false,
      isPerActiveUserEnabled: false,
      isFreePlanEnabled: selectedValue === 'admin_driven' ? true : false,
      amount: "",
      payPerUserAmount: "",
      paymentPeriodOption: "",
      tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],

    });
  };

  handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState({ isFreePlanEnabled: isChecked });
    if (isChecked) {
      this.setState({
        isFlatFeeEnabled: false,
        isPayPerUserEnabled: false,
        isTieredEnabled: false,
        amount: "",
        payPerUserAmount: "",
        paymentPeriodOption: "",
        tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],
      });
    } else {
      this.setState({
        isFlatFeeEnabled: true
      })
    }
  };

  handleisFlatFeeEnabledChange = () => {
    this.setState((prevState) => ({
      isFlatFeeEnabled: !prevState.isFlatFeeEnabled,
      amount: "",
    }));
  };

  handlePayPerUserChange = () => {
    this.setState((prevState) => ({
      isPayPerUserEnabled: !prevState.isPayPerUserEnabled,
      isPerActiveUserEnabled: false,
      isTieredEnabled: false,
      payPerUserAmount: "",
      tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],
    }));
  };

  handlePerActiveUserChange = () => {
    this.setState((prevState) => ({
      isPerActiveUserEnabled: !prevState.isPerActiveUserEnabled,
      isTieredEnabled: prevState.isPerActiveUserEnabled ? false : this.state.isPerActiveUserEnabled,
      tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],
    }));
  };

  handleTieredChange = () => {
    this.setState((prevState) => ({
      isTieredEnabled: !prevState.isTieredEnabled,
      isPerActiveUserEnabled: prevState.isTieredEnabled ? false : this.state.isTieredEnabled,
      payPerUserAmount: "",
      tierSectionValues: [{ id: "0", min: "0", max: "", tierPrice: "" }],
    }));
  };

  handlePaymentPeriodOptionChange = (event: React.ChangeEvent<{ value: unknown }>, child: React.ReactNode) => {
    this.setState({ paymentPeriodOption: event.target.value as string });
  };

  handleIncrement = () => {
    this.setState((prevState) => {
      const currentAmount = Number(prevState.amount);
      const newAmount = Math.min(currentAmount + 1, 100000).toFixed(2); 
      return { amount: newAmount };
    });
  };

  handleDecrement = () => {
    this.setState((prevState) => {
      const currentAmount = Number(prevState.amount);
      const newAmount = Math.max(0, currentAmount - 1).toFixed(2); 
      return { amount: newAmount };
    });
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    let value = input.value;
    const cursorPosition = input.selectionStart;
  
    if (value === "") {
      this.setState({ amount: "" });
      return;
    }
  
    let numericValue = parseFloat(value.replace(/,/g, ''));
  
    if (!isFinite(numericValue)) {
      numericValue = parseFloat(this.state.amount || "0");
    }
    if(numericValue>100000){
      numericValue = 100000;
    }
  
    const formattedValue = numericValue ? numericValue.toFixed(2) : "";
  
    this.setState({ amount: formattedValue }, () => {
      if (document.activeElement === input) {
        input.selectionStart = cursorPosition;
        input.selectionEnd = cursorPosition;
      }
    });
  };
  
  

  handlePayPerUserAmountIncrement = () => {
    this.setState((prevState) => {
      const currentAmount = Number(prevState.payPerUserAmount);
      const newAmount = Math.min(currentAmount + 1, 100000).toFixed(2); 
      return {
        payPerUserAmount: newAmount,
      };
    });
  };

  handlePayPerUserAmountDecrement = () => {
    this.setState((prevState) => {
      const currentAmount = Number(prevState.payPerUserAmount);
      const newAmount = Math.max(0, currentAmount - 1).toFixed(2);
      return {
        payPerUserAmount: newAmount,
      };
    });
  };

  handlePayPerUserAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const value = input.value;
    const cursorPosition = input.selectionStart; 
    if (value === "") {
      this.setState({ payPerUserAmount: value });
      return;
    }
  
    let numericValue = parseFloat(value.replace(/,/g, ''));
    if (!isFinite(numericValue)) {
      numericValue = parseFloat(this.state.payPerUserAmount || "0");
    }
    if(numericValue>100000){
      numericValue = 100000;
    }
  
    const formattedValue = numericValue ? numericValue.toFixed(2) : "";
    this.setState({ payPerUserAmount: formattedValue }, () => {
      if (document.activeElement === input) {
        input.selectionStart = cursorPosition;
        input.selectionEnd = cursorPosition;
      }
    });
  };

  getTotalUserBranch = async (branchId: number) => {
    this.getTotalUsersBranchApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.totalUserBranchEndPoint}/${branchId}/total_and_active_users`
    });
  };

  getBranchBillingId = async () => {
    this.getBranchBillingIdApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.createBillingEndPoint}/${this.state.branchBillingId}/add_payment`
    });
  };
  
  postBilling = async (event?: any) => {
    const { tierSectionValues, totalUsers } = this.state;

    if (this.state.isTieredEnabled) {
      const lastTier = tierSectionValues[tierSectionValues.length - 1];
      if (lastTier.min === "" || lastTier.max === "" || lastTier.tierPrice === "") {
        this.setState({ errorMessage: "All fields are required" });
        return;
      }
      if (Number(lastTier.max) <= Number(lastTier.min)) {
        this.setState({ errorMessage: "Max value must be greater than Min value." });
        return;
      }
      if (Number(lastTier.max) > totalUsers) {
        this.setState({ errorMessage: `The total user limit of ${totalUsers} has been exceeded. Please adjust the range.` });
        
        return;
      }
      this.setState((prevState) => {
        const lastMax = prevState.tierSectionValues.length > 0 ? prevState.tierSectionValues[prevState.tierSectionValues.length - 1].max : "0";
        return {
          tierSectionValues: [...prevState.tierSectionValues],
          errorMessage: "",
        };
      });
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const tierPricesAttributes = this.state.tierSectionValues.map((tier) => ({
      id: Number(tier.id),
      price_per_user: Number(tier.tierPrice),
    }));
  
    const userApiData = {
      branch_billing: {
        "billing_type": this.state.selectedOption,
        "branch_id": this.state.branchId,
    
        ...(this.state.selectedOption !== "user_driven" && {
          "free_plan": this.state.isFreePlanEnabled,
          ...(!this.state.isFreePlanEnabled && {
            "payment_period": this.state.paymentPeriodOption,
          }),
          ...(this.state.isPayPerUserEnabled && {
            "pay_per_user": this.state.isPayPerUserEnabled,
            ...(!this.state.isTieredEnabled && {
              "price_per_user": Number(this.state.payPerUserAmount),
            }),
          }),
          ...(this.state.isTieredEnabled && {
            "tiered": this.state.isTieredEnabled,
            "tier_prices_attributes": tierPricesAttributes,
          }),
          ...(this.state.isFlatFeeEnabled && {
            "is_flat_fee": this.state.isFlatFeeEnabled,
            "flat_fee": Number(this.state.amount),
          }),
          ...(this.state.isTieredEnabled && this.state.isFlatFeeEnabled && { 
              "tier_prices_attributes": tierPricesAttributes,
              "flat_fee": Number(this.state.amount),
          }),
        }),
      },
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postUserAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(userApiData)
    );
  
    const branchBillingId  = this.state.branchBillingDetails?.id ? `/${this.state.branchBillingDetails?.id}` : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBillingEndPoint + branchBillingId
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isEditBranchScreen ? configJSON.putApiMethod : configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleClickAdd = () => {
    const { tierSectionValues, totalUsers } = this.state;
    const lastTier = tierSectionValues[tierSectionValues.length - 1];

    if (lastTier.min === "" || lastTier.max === "" || lastTier.tierPrice === "") {
      this.setState({ errorMessage: "All fields are required." });
      return;
    }
    if (Number(lastTier.max) <= Number(lastTier.min)) {
      this.setState({ errorMessage: "Max value must be greater than Min value." });
      return;
    }
    if (Number(lastTier.max) > totalUsers) {
      this.setState({ errorMessage: `The total user limit of ${totalUsers} has been exceeded. Please adjust the range.` });
      return;
    }
    if (this.state.tierSectionValues.length >= 5) {
      return;
    }
    const lastMax = tierSectionValues.length > 0 ? Number(tierSectionValues[tierSectionValues.length - 1].max) : 0;
    const remainingUsers = totalUsers - lastMax;

    if (remainingUsers <= 0) {
      this.setState({ errorMessage: "User limit reached, cannot add more ranges." });
      return;
    }
    this.setState((prevState) => {
      const lastMax = prevState.tierSectionValues.length > 0 ? prevState.tierSectionValues[prevState.tierSectionValues.length - 1].max : "0";
      const newTier = { id: "0", min: (Number(lastMax) + 1).toString(), max: "", tierPrice: "" };
      return {
        tierSectionValues: [...prevState.tierSectionValues, newTier],
        errorMessage: "",
      };
    });
  };

  handleDeleteButton = (index: number) => {
    this.setState((prevState) => {
      const tierSectionValues = [...prevState.tierSectionValues];
      tierSectionValues.splice(index, 1);
      return { tierSectionValues };
    });
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const input = event.target;
    let { name, value } = input;
    const cursorPosition = input.selectionStart;
  
    if (value === "") {
      this.setState((prevState) => {
        const tierSectionValues = [...prevState.tierSectionValues];
        tierSectionValues[index] = {
          ...tierSectionValues[index],
          [name]: value,
        };
        return { tierSectionValues };
      });
      return;
    }
  
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return;
    }
    if (name === "tierPrice") {
      if (numericValue > 100000) {
        numericValue = 100000;
      }
      value = numericValue ? numericValue.toFixed(2) : "";
    } else {
      value = Math.floor(numericValue).toString();
    }
    this.setState(
      (prevState) => {
        const tierSectionValues = [...prevState.tierSectionValues];
        tierSectionValues[index] = {
          ...tierSectionValues[index],
          [name]: value,
        };
        if (name === "max") {
          const nextTier = tierSectionValues[index + 1];
          if (nextTier) {
            const newMax = Number(value);
            nextTier.min = (newMax + 1).toString();
          }
        } else if (name === "min" && index < tierSectionValues.length - 1) {
          const nextTier = tierSectionValues[index + 1];
          if (nextTier && Number(value) >= Number(nextTier.min)) {
            nextTier.min = (Number(value) + 1).toString();
          }
        }
        return { tierSectionValues };
      },
      () => {
        if (document.activeElement === input) {
            input.selectionStart = cursorPosition;
            input.selectionEnd = cursorPosition;
        }
      }
    );
  };
  
  handleTierIncrement = (index: number, field: keyof tierSection) => {
    this.setState((prevState) => {
      const tierSectionValues = [...prevState.tierSectionValues];
      const currentValue = Number(tierSectionValues[index][field]) || 0;
      const newValue = field === 'max'
        ? Math.min(100000, currentValue + 1).toString()
        : Math.min(100000, currentValue + 1).toFixed(2);
  
      tierSectionValues[index][field] = newValue;
      return { tierSectionValues };
    });
  };
  
  handleTierDecrement = (index: number, field: keyof tierSection) => {
    this.setState((prevState) => {
      const tierSectionValues = [...prevState.tierSectionValues];
      const currentValue = Number(tierSectionValues[index][field]) || 0;
      const newValue = field === 'max'
        ? Math.max(0, currentValue - 1).toString()
        : Math.max(0, currentValue - 1).toFixed(2);
  
      tierSectionValues[index][field] = newValue;
      return { tierSectionValues };
    });
  };
  

  calculateEstimatedTotal = () => {
    const userCount = this.state.isPerActiveUserEnabled ? this.state.activeUsers : this.state.totalUsers 
    const payPerUserAmountCount = Number(this.state.payPerUserAmount || 0);
    const additionalAmount = Number(this.state.amount || 0);
    let totalFromTiers = 0;
    let lastMaxUsers = 0;
    this.state.tierSectionValues.forEach((tier, index) => {
      const minUsers = Number(tier.min); 
      const maxUsers = Number(tier.max);
      const setPrice = Number(tier.tierPrice || 0);
      const adjustedMinUsers = Math.max(minUsers, lastMaxUsers + 1);
      const usersInRange = (adjustedMinUsers <= maxUsers) ? (maxUsers - adjustedMinUsers + 1) : 0; 
      
      totalFromTiers += usersInRange * setPrice;
      lastMaxUsers = maxUsers;
    });
    if(!this.state.isTieredEnabled){
      const estimatedTotal = userCount * payPerUserAmountCount + additionalAmount
      const formattedEstimatedTotal = estimatedTotal === 0 ? "00.00" : estimatedTotal.toFixed(2);
      return formattedEstimatedTotal;
    }
    const estimatedTotal = additionalAmount + totalFromTiers;
    const formattedEstimatedTotal = estimatedTotal === 0 ? "00.00" : estimatedTotal.toFixed(2);
    return formattedEstimatedTotal;
  };

handleSaveChangesDisable = () => {
  const { 
    isFreePlanEnabled, 
    selectedOption, 
    isTieredEnabled, 
    tierSectionValues, 
    isFlatFeeEnabled, 
    paymentPeriodOption, 
    amount, 
    isPayPerUserEnabled, 
    payPerUserAmount 
  } = this.state;

  const areAllTiersFilled = tierSectionValues.every(tier => 
    tier.min !== "" && tier.max !== "" && tier.tierPrice !== ""
  );

  if (isTieredEnabled && !areAllTiersFilled) {
    return true;
  }

  if (selectedOption === "admin_driven") {
    if (!isFreePlanEnabled && isFlatFeeEnabled && (!paymentPeriodOption || !amount)) {
      return true;
    }
    if (!isFreePlanEnabled && !isFlatFeeEnabled && !isPayPerUserEnabled) {
      return true;
    }
  }

  if (isPayPerUserEnabled && !isTieredEnabled && isFlatFeeEnabled && (!paymentPeriodOption || !amount || !payPerUserAmount)) {
    return true;
  }

  if (isTieredEnabled && (!paymentPeriodOption || !areAllTiersFilled)) {  
    return true;
  }

  if (isPayPerUserEnabled && !isTieredEnabled && (!paymentPeriodOption || !payPerUserAmount)) {
    return true;
  }

  if (isTieredEnabled && isFlatFeeEnabled && (!paymentPeriodOption || !amount || !areAllTiersFilled)) {
    return true;
  }

  return false;
}
  toggleMakeInactiveDialog = () => {
    this.setState({ makeInactiveDialogue: !this.state.makeInactiveDialogue })
  }

  makeInActiveBranchAPI = () => {

    let branchId = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.makeInActiveBranchApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeInactiveBranchEndPoint + `/${branchId}/inactive_branch`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  editBranchBilling = () => {
    this.setState({isEditBranchBilling: true})
  }

  disablePaymentField(){
    let disableField = false;
    if(this.state.isEditBranchScreen) {
      disableField = true;
      if(this.state.isEditBranchBilling ) {
        if(this.state.isPaymentSuccess) {
          disableField = false;
        } else if(!this.state.isPaymentSuccess) {
          disableField = true;
        }
      }
    }

    return disableField;
  }
  
  displayPaymentStatus = (status: string) => {
    let displayStaus = status;
    switch(status) {
      case PAYMENT_STATUS.PAID: 
        displayStaus = "Paid"
      break;
      case PAYMENT_STATUS.PENDING: 
        displayStaus = "Payment Pending"
      break;
    }
    return displayStaus;
  }
}
// Customizable Area End