// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  InputLabel,
  CircularProgress,
  Typography,
  Radio,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  withStyles,
  styled
} from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik, Form } from "formik";
import "react-calendar/dist/Calendar.css";
import { SelectArrow, infoIcon2 } from "./assets";
import EditUsersController from "./EditUsersController.web";

export interface Props {
  classes: any;
}

const configJSON = require("./config.js");

const validationSchema = yup.object({
  middleName: yup.string()
    .matches(/^[a-zA-Z\s'-]+$/, 'Middle name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'Middle name must be between 2 and 50 characters long')
    .max(50, 'Middle name must be between 2 and 50 characters long'),
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s'-]+$/, 'First name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'First name must be between 2 and 50 characters long')
    .max(50, 'First name must be between 2 and 50 characters long'),
  lastName: yup.string()
    .required("Last name is required")
    .matches(/^[a-zA-Z\s'-]+$/, 'Middle name can only include alphabetic characters, spaces, hyphens, and apostrophes')
    .min(2, 'Middle name must be between 2 and 50 characters long')
    .max(50, 'Middle name must be between 2 and 50 characters long'),
    phone: yup
    .string()
    .typeError("Mobile number can only contain numeric characters")
    .matches(
      /^\(\d{3}\)\s\d{3}\s\d{4}$/,
      "Mobile number must be in the format (xxx) xxx xxxx"
    ),
  title: yup.string(),
});


export const SelectArrowIcon = () => {
  return (
    <img
      src={SelectArrow}
      alt="down arrow"
      style={{ marginRight: 10, width: 13 }}
    />
  );
};





class EditUser extends EditUsersController {
  render() {
    const { firstName, middleName, lastName, title, email, countryCode, phoneNumber } = this.state;
    const initialValues = {
      firstName: firstName || "",
      middleName: middleName || "",
      lastName: lastName || "",
      title: title,
      email: email,
      phone: phoneNumber,
      teamId: title,
      activeUser: "no",
      CountryCode: countryCode,
    };
    return (

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(event) => { this.updateUserForm(event) }}
        data-test-id="userFormTest"
      >
        {(formikProps) => {
          return (
            <Form>
              <div className="user-invite">
                <div className={this.props.classes.root1}>
                  <Typography
                    className={this.props.classes.userInformationHeading1}
                  >
                    {configJSON.userInformation}
                  </Typography>
                  <div className={this.props.classes.fullWidth1}>
                    <Grid spacing={4} container>
                      <Grid item xs={12} md={6}>
                        <InputLabel
                          className={this.props.classes.label1}
                          htmlFor="first_name"
                        >
                          {configJSON.firstName}
                        </InputLabel>
                        <TextField
                          fullWidth
                          name="firstName"
                          id="first_name"
                          placeholder="First Name"
                          variant="outlined"
                          onChange={formikProps.handleChange}
                          value={formikProps.values.firstName}
                          className={this.props.classes.textField1}
                          onBlur={formikProps.handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{
                            background: formikProps.values.firstName ? '#FFFFFF' : '#FCF8F8',
                            borderRadius:"8px"
                          }}
                        />
                       {this.renderFirstNameError(formikProps)}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          className={this.props.classes.label1}
                          htmlFor="middle_name"
                        >
                          {configJSON.middleName}

                        </InputLabel>
                        <TextField
                          fullWidth
                          name="middleName"
                          id="middle_name"
                          placeholder="Middle Name"
                          variant="outlined"
                          onChange={formikProps.handleChange}
                          value={formikProps.values.middleName}
                          className={this.props.classes.textField1}
                          onBlur={formikProps.handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{
                            background: formikProps.values.middleName ? '#FFFFFF' : '#FCF8F8',
                            borderRadius:"8px"
                          }}
                        />
                      {this.renderMiddleNameError(formikProps)}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          className={this.props.classes.label1}
                          htmlFor="last_name"
                        >
                          {configJSON.lastName}
                        </InputLabel>
                        <TextField
                          fullWidth
                          name="lastName"
                          id="last_name"
                          placeholder="Last Name"
                          variant="outlined"
                          value={formikProps.values.lastName}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          className={this.props.classes.textField1}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={this.getlastName(formikProps.values.lastName)}
                        />
                      {this.renderEditLastNameError(formikProps)}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          className={this.props.classes.label1}
                          htmlFor="add_to_team"
                        >
                          {configJSON.title}
                        </InputLabel>
                        <FormControl className={this.props.classes.selectFormControl1}>
                          <Select
                            IconComponent={(props) => (
                              <div {...props} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingRight: "10px" }}>
                                <img
                                  src={SelectArrow}
                                  alt="down arrow"
                                  style={{ paddingRight: "10px", width: "24px", height: "24px" }}
                                />
                              </div>
                            )}
                            displayEmpty
                            data-test-id="selectTitle"
                            labelId="select-teams"
                            id="select-teams"
                            name="teamId"
                            placeholder="dropdown"
                            onChange={formikProps.handleChange}
                            variant="outlined"
                            onBlur={formikProps.handleBlur}
                            className={this.props.classes.select}

                            renderValue={(selected) => {
                              if (!selected) {
                                return <span className={this.props.classes.dropDownPlaceHolder}>Select from dropdown</span>;
                              }
                              if (selected === 'prefer_not_to_say') {
                                return 'Prefer not to say';
                              }
                              return selected as string;
                            }}
                            value={formikProps.values.teamId}
                            style={this.getCardStyle(formikProps.values.teamId)}
                          >
                            {['mr', 'mrs', 'miss', 'ms', 'dr', 'prefer_not_to_say'].map((option) => (
                              <MenuItem className={this.props.classes.menuItem} key={option} value={option}>
                                <FormControlLabel
                                  control={
                                    <Radio
                                      checked={formikProps.values.teamId?.toString() === option}
                                      size="small"
                                      style={{ color: this.getEditColor(formikProps.values.teamId, option) }}
                                    />
                                  }
                                  className={this.props.classes.radio}
                                  style={{ marginRight: '8px' }}
                                  label=""
                                />
                                {option === 'prefer_not_to_say' ? 'Prefer not to say' : option.charAt(0).toUpperCase() + option.slice(1)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className={this.props.classes.emailPhoneDiv}>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          htmlFor="email_address"
                          className={this.props.classes.emailLabel}
                        >
                          {configJSON.emailAddress}
                        </InputLabel>
                        <TextField
                          disabled
                          fullWidth
                          id="email_address"
                          variant="outlined"
                          name="email"
                          value={formikProps.values.email}
                          placeholder="Email Address"
                          onBlur={formikProps.handleBlur}
                          className={this.props.classes.textField2}
                          onChange={formikProps.handleChange}
                          helperText={
                            formikProps.touched.email &&
                            formikProps.errors.email
                          }
                          error={
                            formikProps.touched.email &&
                            Boolean(formikProps.errors.email)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <div className={this.props.classes.infoDiv1}>
                          <img src={infoIcon2} style={{ width: "20px", height: "20px" }} />
                          <p className={this.props.classes.emailText1}>{configJSON.emailText}</p>
                        </div>
                      </Grid>
                      <Grid item md={6} xs={10} className={this.props.classes.phoneNumberDiv} >
                        <Grid item md={12} xs={12}>
                          <InputLabel
                            htmlFor="phone_number"
                            className={this.props.classes.label1}
                          >
                            {configJSON.mobileNumber}
                          </InputLabel>
                          <div style={{ display: "flex" }} >

                            <CustomSelect
                              name="CountryCode"
                              id="CountryCode"
                              value={formikProps.values.CountryCode}
                              displayEmpty
                              onOpen={this.handleSelectOpen}
                              onChange={formikProps.handleChange}
                              onClose={this.handleSelectClose}
                              MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                              inputProps={{ 'aria-label': 'Without label' }}
                              className={this.props.classes.countryCodeDropdown}
                            >
                             {this.state.CountryFlagCode &&this.state.CountryFlagCode
                                .filter((name) => name.id === 'US' || name.attributes.name === 'Canada') 
                                .map(name => (
                                  <MenuItem
                                    key={name.id}
                                    value={name.id}
                                    className="menu_box"
                                  >
                                    <span className="menu_arrow"></span>
                                    <span className={`flag_img fi fi-${name.id.toLowerCase()}`}></span>
                                    <span className="flag_Text">{`+${name.attributes.country_code}`}</span>
                                  </MenuItem>
                                ))
                            }
                            </CustomSelect>
                            {this.state.selectOpen && <div className="triangle"></div>}
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <TextField
                                fullWidth
                                name="phone"
                                id="phone_number"
                                placeholder="Mobile Number"
                                variant="outlined"
                                onChange={(event:any) => this.handleEditPhoneChange(event, formikProps)}
                                value={formikProps.values.phone}
                                className={this.props.classes.textField1}
                                onBlur={formikProps.handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{
                                  width: "496px",
                                  background:'#FCF8F8',
                                }}
                              />
                             {this.renderEditPhoneError(formikProps)}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item md={4} xs={12}></Grid>
                  <Grid
                    item
                    md={8}
                    container
                    justifyContent="space-between"
                    xs={12}
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        disableElevation
                        data-test-id="submit-btn"
                        type="submit"
                        variant="contained"
                        className={`${this.props.classes.btn} ${this.props.classes.createUserBtn}`}
                        onClick={() => { this.updateUserForm(formikProps.values) }}
                        endIcon={
                          this.state.isLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                            />
                          ) : null
                        }
                      >
                        {configJSON.saveChange}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>

    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    dropDownPlaceHolder: {
      fontSize: "16px",
      color: "#ADAAAA",
      fontFamily: "Poppins !important",
      fontWeight: 400,
    },
    root1: {
      marginBottom: 20,
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: "20px 24px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      background: "#FFFFFF",
      "&:hover": {
        background: "#EEE8F2 !important",
      },
    },
    hiddenRadio: {
      display: 'none',
    },
    countryCodeDropdown: {
      height: "56px",
      width: "144px",
      backgroundColor: "#FCF8F8",
      marginRight: "10px",
      borderRadius: "8px",
      border: "1px solid #929090",
      "@media (max-width: 960px)": {
        width: "50% !important",
        "& .MuiFormControl-root": {
          minWidth: "none !important",
        }
      },
    },
    phoneNumberDiv: {
      marginLeft: "30px",
      "@media (max-width: 960px)": {
        marginLeft: "0px !important",
        maxWidth: "none !important",
        marginTop: "10px",

        "& .MuiGrid-grid-xs-10": {
          maxWidth: "none !important"
        },
        "& .MuiGrid-grid-xs-12": {
          width: "100% !important",
        }
      },

    },
    emailPhoneDiv: {
      display: "flex",
      marginTop: "25px",
      justifyContent: "space-evenly",
      alignItems: "start",
      "@media (max-width: 960px)": {
        width: "100% !important",
        flexDirection: "column"
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: '10px',
      position: 'relative',
      padding: theme.spacing(2, 4, 3),
      width: '517px'
    },
    btn: {
      fontWeight: 600,
      borderRadius: "16px",
      lineHeight: "16px",
      fontSize: "16px",
      textAlign: "center",
      textTransform: "capitalize",
      fontFamily: "Poppins"
    },
    createUserBtn: {
      marginTop: "15px",
      background: "#ffffff",
      border: "1px solid #E47004",
      height: "56px",
      width: "411px",
      color: "#E47004",
      "&:hover": {
        background: "#ffffff",
      },
      "@media (max-width: 500px)": {
        width: "100% !important",
      },
    },
    select: {
      textTransform: "lowercase",
      padding: "0 15px 0 0",
      height: '56px',
      borderRadius: '8px',
      '& .MuiSelect-select:focus': {
        background: 'white !important',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #929090",
      },
      "& ::first-letter": {
        textTransform: "capitalize",
      },
    },
    inviteBtn: {
      width: "185px",
      background: "#fff",
      color: "#6C328B",
      height: "56px",
      border: "1.5px solid #6C328B",
    },
    selectFormControl1: {
      width: "100%",
    },
    selectDropdown: {
      background: "#fafafa",
      height: "55px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #d6d6d6",
        borderRadius: 15,
      },
      "&.MuiSelect-outlined.MuiSelect-outlined": {
        padding: "8px !important"
      },
      "& .MuiSelect-select:focus": {
        borderRadius: 18,
        background: "#fff",
      },
    },
    teamSelectContainer: {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
    justifyEnd: {
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
      justifyContent: "flex-start",
    },
    fullWidth1: {
      width: "100%",
    },
    radio: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    userInformationHeading1: {
      fontSize: "22px",
      color: "#1C1B1B",
      lineHeight: "33px",
      fontWeight: 600,
      fontFamily: "Poppins !important",
      padding: "14px 0px",
    },
    label1: {
      fontSize: "16px",
      color: "#1C1B1B",
      fontWeight: 400,
      fontFamily: "Poppins !important",
      marginBottom: 5,
      lineHeight: "24px",
    },
    emailLabel: {
      fontSize: "16px",
      color: "#787776",
      fontWeight: 400,
      fontFamily: "Poppins !important",
      marginBottom: 5,
      lineHeight: "24px",
    },
    textField1: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        color: "#1C1B1B"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #929090",
      },
    },
    textField2: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        background: "#E5E2E1",
        color: "#787776"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #929090",
      },
    },
    outlinedBtn: {
      background: "#ffffff",
      border: "1px solid #6c328b",
      fontWeight: 600,
      borderRadius: "12px",
      lineHeight: "16px",
      fontSize: "16px",
      color: "#6c328b",
      textAlign: "center",
      width: "180px",
      height: "50px",
      textTransform: "capitalize",
    },
    emailText1: {
      margin: "5px 0px",
      padding: "0px",
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#787776"
    },
    titleRadio: {
      "&.MuiSelect-outlined.MuiSelect-outlined": {
        padding: "8px"
      }
    },
    infoDiv1: {
      gap: "5px",
      display: "flex",
      alignItems: "center"
    }

  });

const CustomSelect = styled(Select)({
  '& .MuiSelect-root': {
    display: "flex",
    position: 'relative',
    gap: "25px",
    padding: "0 15px 0 0",
    alignItems: "center",
  },
  '& .MuiPopover-paper': {
    zIndex: 999,
    position: 'relative',
  },
  "& .MuiList-padding": {
    paddingTop: "0px"
  },
  "& .MuiListItem-gutters": {
    paddingRight: "8px !important",
    paddingLeft: "2px !important",
    color: "#6C328B !important",
    gap: "10px !important",
    fontWeight: 500,
    fontSize: "14px !important",
  },
  '& .MuiSelect-icon': {
    display: "none"
  }
})

const CustomMenuProps = {
  PaperProps: {
    style: {
      marginTop: '22px' as const,
      height: "85px" as const,
      width: "86px" as const,
      marginLeft: "40px" as const,
      left: "10px",
      minWidth: "86px",
      background: "#FCF8F8"
    },
  },
  getContentAnchorEl: null
};
export default withStyles(styles)(EditUser);
// Customizable Area End