import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { addDays} from 'date-fns';
import moment from "moment";

// Customizable Area Start

export interface AdminReportResponse {
  time_spent_in_training: ChartsData
  video_watched: ChartsData
  course_started: ChartsData
  program_started: ChartsData
  most_watched_course: MostWatchedCourse[]
  most_watched_video: MostWatchedVideo[]
}

export interface ChartsData {
  current_data_total: string;
  previous_data_total: string;
  current_data: SeriesData[];
  previous_data: SeriesData[];
}

export interface SeriesData {
  cdate: string
  count: number
}

export interface MostWatchedCourse {
  count: number
  id: number
  title: string
  total_time_watched: number
  watched_user: WatchedUser[]
}

export interface WatchedUser {
  name: string
  id: number
}

export interface MostWatchedVideo {
  count: number
  id: number
  title: string
  time_spent: number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  groupDropdown: boolean;
  selectedGroup: {name: string, value: string};
  nameDropdown: boolean;
  selectedName: {id: number , name: string} | null;
  initialNameListing: {id:number, name: string}[];
  nameListing: {id:number, name: string}[];
  dateRangeDropdown: boolean;
  selectedDateDropdownValue: {name: string, value: string};
  isComparePreviousPeriod: boolean;
  adminReportData: AdminReportResponse;
  watched_users: WatchedUser[],
  displayCustomRangeDateSelection: boolean;
  startDate: Date | null;
  endDate: Date | null;
  searchText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  dropdownRef: React.RefObject<HTMLDivElement>;
  getNameListBasedOnGroupApiCallID: string = "";
  getAdminReportApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      groupDropdown: false,
      nameDropdown: false,
      selectedGroup: {name: "", value: ""},
      selectedName: null,
      dateRangeDropdown: false,
      initialNameListing: [],
      nameListing: [],
      selectedDateDropdownValue: { name: "Last 30 days",value:"last_30_days"},
      isComparePreviousPeriod: false,
      adminReportData: {} as AdminReportResponse,
      watched_users: [],
      displayCustomRangeDateSelection: false,
      startDate: null,
      endDate: null,
      searchText: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.dropdownRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getNameListBasedOnGroupApiCallID) {
        const listing = responseJson?.data?.length ? responseJson?.data : []
        this.setState({ 
          nameListing: listing,  
          initialNameListing: listing 
        })
      } else if (apiRequestCallId === this.getAdminReportApiCallID) {
        this.setState({ adminReportData: responseJson?.data })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAdminReportData();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  toggleGroupdropdown = () => {
    this.setState({
      groupDropdown: !this.state.groupDropdown,
    })
  }

  toggleNamedropdown = () => {
    if(this.state.selectedGroup.value) {
      this.setState({
        nameDropdown: !this.state.nameDropdown,
      }, () => {
        if(this.state.nameDropdown) {
          this.setState({nameListing: this.state.initialNameListing, searchText: ""})
        }
      })
    }
  }

  toggleDateRangedropdown = () => {
    this.setState({
      dateRangeDropdown: !this.state.dateRangeDropdown,
    })
  }

  togglePreviousPeriodDropdown = () => {
    this.setState({
      isComparePreviousPeriod: !this.state.isComparePreviousPeriod,
    })
  }

  handleGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedGroup = event.target.value;
    const selectedGroupName = event.target?.name;
    this.setState({
      selectedGroup: {name: selectedGroupName, value: selectedGroup},
      groupDropdown: false,
      selectedName: null,
      nameListing: [],
      initialNameListing: []
    });
    this.getListBasedOnGroupSelection(selectedGroup);
    this.getAdminReportData()
  };

  handleNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    const selectedNameLable = event.target?.name;
    this.setState({
      selectedName: {id: Number(selectedId), name: selectedNameLable},
      nameDropdown: false
    }, () => this.getAdminReportData());
  };

  handleDateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    const dateName = event.target?.name;
    this.setState({
      selectedDateDropdownValue: {name: dateName ,value: dateValue},
      dateRangeDropdown: dateValue !== "custom_date_range" ? false : true,
      displayCustomRangeDateSelection: dateValue === "custom_date_range" ? true : false
     }, () => {
      if(this.state.selectedDateDropdownValue.value !== "custom_date_range") {
        this.getAdminReportData()
      }
     });
  };

  getListBasedOnGroupSelection = (selectedGroup: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getNameListBasedOnGroupApiCallID = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListingBasedOnGroup + `${selectedGroup}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  handleQueryString = () => {
    let queryString = "?";

    if(this.state.selectedName?.id) {
      switch(this.state.selectedGroup.value) {
        case "user": 
          queryString = queryString + `user_id=${this.state.selectedName.id}`
          break;
        case "team": 
          queryString = queryString + `team_id=${this.state.selectedName.id}`
          break;
        case "branch": 
          queryString = queryString + `branch_id=${this.state.selectedName.id}`
          break;
      }
    }

    if (this.state.selectedDateDropdownValue.value) {
      queryString = this.state.selectedGroup.value ? queryString.concat("&") : queryString;
      switch (this.state.selectedDateDropdownValue.value) {
        case "last_30_days":
          break;
        case "custom_date_range":
          queryString = queryString + `date_range=${this.state.selectedDateDropdownValue.value}&start_date=${moment(this.state.startDate).format("DD/MM/yyyy")}&end_date=${moment(this.state.endDate).format("DD/MM/yyyy")}`
          break;
        default:
          queryString = queryString + `date_range=${this.state.selectedDateDropdownValue.value}`
          break;
      }
    }
   
    return queryString;
  }

  getAdminReportData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    let queryString = this.handleQueryString() ;

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdminReportApiCallID = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminReportGroupApi + `${queryString}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }
  displayPastPreviousLableName = (dateValue: string) => {
    let lableName = ""
    let startDate = this.state.startDate ? moment(this.state.startDate).format("DD/MM/YYYY"): "";
    let endDate = this.state.endDate ? moment(this.state.endDate).format("DD/MM/YYYY"): "";
    switch(dateValue) {
      case "last_30_days":
        lableName = "30 Days";
        break;
      case "this_week":
        lableName = "Week";
        break;
      case "last_week":
        lableName = "Last Week";
        break;
      case "this_month":
        lableName = "Month";
        break;
      case "last_month":
        lableName = "Last Month";
        break;
      case "ytd":
        lableName = "YTD";
        break;
      case "custom_date_range":
        lableName = `${startDate} - ${endDate}`;
        break;
    };

    return lableName;
  }

  handleStartDate = (value: Date) => {
    this.setState({ startDate: value, endDate: null });
  };
  handleEndDate = (value: Date) => {
    this.setState({ endDate: value, dateRangeDropdown: false }, () =>{
      this.getAdminReportData()
    });
  };
  

getEndDateMinimumValue = (startDate: Date) => { 
  if(startDate) {
    return addDays(startDate,1) 
  } 
}

  handleSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const searchText = event.target.value;
    const nameListing = this.state.initialNameListing;

    this.setState({ searchText: searchText }, 
      () => {
        const filterNameList = 
        nameListing.filter((dataValue) => dataValue.name.toLowerCase().includes(searchText.toLowerCase()));
        this.setState({
          nameListing: filterNameList
        })
      }
    );
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) { this.setState({  groupDropdown: false, nameDropdown: false, dateRangeDropdown: false }) }
  };

  // Customizable Area End
}
