import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addIconHover, audioIcon, deleteIcon, documentImage, dotsIcon, editIcon, iconClose, videoIcon } from "./assets";


type LessonDragDropProps = {
  onDragEndLesson:any;
  contentLesson:string[];
  handleMouseEnter:any;
  handleLessonDeleteDialog:any;
  handleLessonEdit:(id:string, type:string, previousContentId:string)=>void;
  addDialogBoxopen:boolean;
  dialogImageToggle:boolean;
  handleAddIconClick:any;
  handleAddIconOpenLessonNew:(previousLessonId?:string)=>void;
  hoverLessonId:any
  webStyle:any
}

const LessonDragDrop= (props:LessonDragDropProps) => {
    const icons:any = {
      video: videoIcon,
      audio: audioIcon,
      document: documentImage
    };
    
    return (
      <DragDropContext data-test-id="drag" onDragEnd={props.onDragEndLesson}>
        <Droppable droppableId="droppablePhases" >
          {(provided, snapshot) => {
            return(
            <div data-test-id="DIV" {...provided.droppableProps} ref={provided.innerRef}>
              {props.contentLesson.map((content:any, index:any) => {
                const icon = icons[content.type]  
                const titlePrefix = `Lesson ${index+1}`;
                const title = `${content.title}`;
                const previousContentId = content.id;

                return (
                  <Draggable key={content.id.toString()} draggableId={content.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: snapshot.isDragging ? "#f0f0f0" : "white",
                        }}
                      >
                         <Box data-testid={`hover_id${index}`} style={props.webStyle.innnerRowWrapper}
        onMouseEnter={props.handleMouseEnter.bind(this, index)}
      >
        <Box style={props.webStyle.innerContainer} >
          <Box style={props.webStyle.innerRightContainer} >
            <img {...provided.dragHandleProps} src={dotsIcon} alt="dotsIcon" style={{ width: "20.55px", height: "20px" }} />
            <img src={icon} alt="dotsIcon" style={{ width: "24.66px", height: "24px" }} />
            <Typography style={props.webStyle.primaryText} >{titlePrefix} - <span style={props.webStyle.spanText} >{title}</span></Typography>
          </Box>
          <Box style={props.webStyle.innerLeftContainer} >
            <img src={editIcon} alt="editIcon" onClick={()=>props.handleLessonEdit(content.id,content.type, previousContentId)}/>
            <img src={deleteIcon} data-testid={`DeleteBtn${index}`} alt="deleteIcon" onClick={()=>props.handleLessonDeleteDialog(titlePrefix,content.id)} />
          </Box>
        </Box>
        {
          props.hoverLessonId !== "" && props.hoverLessonId == index && (
            <>
              <Box sx={props.webStyle.hoverContainer}>
                <Button>
                  {
                    props.dialogImageToggle ? (
                      <img src={iconClose} alt="iconClose" data-testid="hoverDialogClose" onClick={() => props.handleAddIconClick(false,previousContentId)} />
                    ) : (
                      <img src={addIconHover} alt="addIconHover" data-testid="hoverDialogAdd" onClick={() => props.handleAddIconClick(true, previousContentId)} />
                    )
                  }
                </Button>
                {
                  props.addDialogBoxopen && (
                    <Box style={props.webStyle.dialogBox}>
                      <Typography 
                      data-test-id="add-btn"
                      style={props.webStyle.dialogBoxLabel}
                       onClick={()=> props.handleAddIconOpenLessonNew(previousContentId)}
                       >
                        Add Lesson
                      </Typography>
                    </Box>
                  )
                }
              </Box>
            </>
          )
        }
      </Box>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}}
        </Droppable>
      </DragDropContext>
    );
  }


export default LessonDragDrop