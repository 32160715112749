import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SelectDropdown from "./SelectDropdown.web";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface Props {
  editQuiz: boolean;
  values: any;
  handleChange: any;
  handleGoBack: any;
  phaseOptions: any;
}

export default function QuizTitle({
  editQuiz,
  values,
  handleChange,
  handleGoBack,
  phaseOptions,
}: Props) {
  const classes = useStyles();

  // Show the phase selection button only if the course has any phases or free trial
  let selectDropdownContent = null;
  if (phaseOptions.length > 0) {
    selectDropdownContent = (
      <SelectDropdown
        options={phaseOptions}
        name="phase"
        value={values.phase}
        handleChange={handleChange}
      />
    );
  }

  return (
    <Box className={classes.phaseContainer}>
      <Box className={classes.pageTitleContainer}>
        <IconButton onClick={handleGoBack}>
          <ChevronLeftIcon fontSize="large" style={{ color: "#92929D" }} />
        </IconButton>
        <Typography data-test-id="quiz-title" className={classes.title}>
          {editQuiz ? "edit quiz" : "create new quiz"}
        </Typography>
      </Box>
      {selectDropdownContent}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    phaseContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 20,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "20px",
      color: "#1A1A1A",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        lineHeight: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "48px",
        lineHeight: "72px",
      },
    },
  })
);
