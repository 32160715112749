export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const InsightsPERMONTH = require("../assets/InsightsPERMONTH.png");
export const Add = require("../assets/Add.png");
export const Rectangle = require("../assets/Rectangle.png");
export const Rectangle1 = require("../assets/Rectangle1.png");
export const Rectangle2 = require("../assets/Rectangle2.png");
export const Rectangle3 = require("../assets/Rectangle3.png");
export const play = require("../assets/play.png");
export const Display_ICON = require("../assets/display.png");
export const DOTMENU = require("../assets/threedot.png");
export const RewardFlag = require("../assets/icon_cup.svg");
export const EyeIcon = require("../assets/eye_icon.svg");
export const TeacherIcon = require("../assets/icon_teacher.svg");
export const WavingHand = require("../assets/wave.png");
export const ReminderIcon = require("../assets/reminder.png");
export const SelfEnrollmentIcon = require("../assets/self_enrollment.png");
export const RewardsBg = require("../assets/rewards_bg.png");
export const AchievementsBg = require("../assets/achievements_bg.png");
export const CalendarIcon = require("../assets/reward_calendar.png");
export const RewardAdminIcon = require("../assets/reward_admin.png");
export const AchievementAdminIcon = require("../assets/achievement_admin.png");
export const CheckedRadio = require("../assets/checkedRadio.png");
export const UncheckedRadio = require("../assets/uncheckedRadio.png");
export const UpArrow = require("../assets/uparrow.png");
export const CheckIcon = require("../assets/check_icon.png");