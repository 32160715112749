import React from "react";
// Customizable Area Start
import ManageRewardController, {
    Props
} from "./ManageRewardController.web";
import { IoIosArrowDown } from "react-icons/io";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Filter, manageImg , NewFilter, NotFoundImage} from "./assets";
import { Box, Button, Avatar, Chip, Typography, Badge, Grid, Popover } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { IoIosArrowUp } from "react-icons/io";
import {UserRewardListing} from "./ManageRewardController.web"
import FilterItems from "../../../blocks/filteritems/src/FilterItems.web";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
// Customizable Area End

export default class ManageAchievement extends ManageRewardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  renderNotFound = () => {
    return (
      <>
        <MainNotFoundBox>
          <MainInnerBox>
            <Box>
              <ImageComponent src={NotFoundImage} />
            </Box>
            <NotFoundText>No results founds</NotFoundText>
          </MainInnerBox>
        </MainNotFoundBox>
      </>
    )
  };

    renderTitle = () => {
      return(
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconBack
            onClick={this.handleBackAchievement}
          />
          <ManageTitle
            className="manage Class">
            Manage {this.state.rewardName}
            <ResponsiveImage
              src={manageImg}
              data-test-id="edit-icon"
              onClick={this.editAchievementNavigationHandler}
            />
          </ManageTitle>
        </div>
      )
    };

  renderContentBody = () => {
    return (
      <Box style={{ display: "grid", gap: "12px", width:'100%' }}>
        {this.state.rewardsData?.data?.map((user, index: number) => (
          <StyledTableRow key={index}>
            <StyledTableCell style={{ placeSelf: "flex-start" }}>
              {
                user.attributes?.profile_image == null ?
                  <Avatar src="/broken-image.jpg" />
                  :
                  <Avatar src={user.attributes?.profile_image?.url} style={{ width: "56px", height: "56px" }} />
              }
              <Box marginLeft="25px" style={{ fontSize: "16px", fontWeight: 400, color: "#1C1B1B" }}>{user.attributes.user_name}</Box>
            </StyledTableCell>
            <StyledTableCell>
              {user.attributes.branch_name}
            </StyledTableCell>
            <StyledTableCell className="endPosition">
              {user.attributes.user_progress === "earned" &&
                <StyledChip
                  label={user.attributes.user_progress}
                  style={{ fontSize: "14px", fontWeight: 400, color: "#542070", textTransform: "capitalize", backgroundColor: "#E0D4E7", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent" }}
                />
              }
              {(user.attributes.user_progress === "progress") &&
                <StyledChip
                  label={"In progress"}
                  style={{
                    fontSize: "14px", fontWeight: 400, color: "#E47004", textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", background: "var(--sys-color-SecondaryContainer, #FFEDE4)"
                  }}
                />
              }
              {user.attributes.user_progress === "redeemed" &&
                <StyledChip
                  label={user.attributes.user_progress}
                  style={{ fontSize: "14px", fontWeight: 400, color: "#005B1D", textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", backgroundColor: "#D7FFE7" }}
                />
              }
              {user.attributes.user_progress === "pending" &&
                <StyledChip
                  label={"Unstarted"}
                  style={{
                    fontSize: "14px", fontWeight: 400, color: "#BF0032"
                    , textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", backgroundColor: "#FFD9DF"
                  }}
                />
              }
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </Box>
    )
  };

    renderSorting(column: keyof UserRewardListing['attributes']) {
        const { orderBy, orderDirection } = this.state;
    
        return (
          <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px', alignItems: "center", justifyContent: "center" }}>
            <IoIosArrowUp onClick={() => this.handleSortAsc(column)} color={orderBy === column && orderDirection === "asc" ? "#1C1B1B" : "#1C1B1B"} style={{fontSize: "14px", color: "#1C1B1B",fontWeight: 600}}/>
            <IoIosArrowDown
              onClick={() =>{
                 this.handleSortDesc(column)}} color={orderBy === column && orderDirection === "desc" ? "#1C1B1B" : "#1C1B1B"} style={{fontSize: "14px", color: "#1C1B1B", fontWeight: 600}}/>
          </span>
        );
      };

      renderSearchFilter() {
        const { anchorFilterContainerEl, searchInputValue, filteredList, showList, } = this.state;
        const openFilterContainer = Boolean(this.state.anchorFilterContainerEl);
        return (
          <Grid
            item
            container
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <InputMain
              item
              md={6}
              xs={12}
              lg={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRight: "none",
                marginTop:'20px'
              }}>
              <StyledContentWrapper >
                <AdvancedSearch
                  data-test-id="btn_change"
                  isFilterDropActive={openFilterContainer}
                  handleListItemClick={this.searchListItemPressHandler}
                  searchInputValue={searchInputValue}
                  handleInputChange={this.inputChangeHandler}
                  filterList={filteredList}
                  showList={showList}
                  navigation={undefined}
                  id={""}
                />
                <div
                  style={{ marginLeft: "0px" }}
                  className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}
                  onClick={this.handleFilterDrop} 
                  aria-describedby={"myPopover"}
                >
                  {(this.state.placeholderSelectedItems.length > 0 || this.state.placeholderSelectsBranchItems.length > 0 || this.state.userProgress || this.state.redeemptionStatus ) ? (
                    <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                    ""
                  )}
    
                  {anchorFilterContainerEl ? (
                    <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
                  ) : (
                    <img src={NewFilter} data-test-id="filterOpenTestID" />
                  )}
                </div>
                <Popover
                  data-test-id="filterPropsID"
                  open={openFilterContainer}
                  className="filterContainerPopover"
                  id="myPopover"
                  onClose={this.filterContainerDropdownCloseHandler}
                  anchorEl={this.state.anchorFilterContainerEl}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                >
                  <FilterItems
                    isContentRole={false}
                    data-test-id="FilterItemsTestID"
                    handleClear={this.clearFilterHandler}
                    creationDateChangeHandler={()=>{}}
                    dropdownOpenHandler={this.dropdownOpenHandler}
                    handleCalendarChange={()=>{}}
                    dropdownCloseHandler={this.dropdownCloseHandler}
                    handleCalendarOpen={()=>{}}
                    anchorEl={this.state.anchorEl}
                    dropdownType={this.state.dropdownType}
                    placeholderSelectedItems={this.state.placeholderSelectedItems}
                    handleClick={this.clickFilterHandler}
                    isCalendarOpen={false}
                    placeholderCheckboxUserManageChangeHandlerBranch={
                      this.placeholderCheckboxUserManageChangeBranchHandler
                    }
                    placeholderSearchBranchText={
                      this.state.placeholderSearchBranchText
                    }
                    handlePlaceholderUserSearchChangeBranch={
                      this.placeholderUserBranchSearchChangesHandler
                    }
                    placeholderSelectedBranchItems={
                      this.state.placeholderSelectsBranchItems
                    }
                    userBranchList={this.state.userBranchList}
                    placeholderCheckboxChangeHandler={
                      this.handleplaceholderCheckboxAssignedExpertChange
                    }
                    handlePlaceholderSearchChange={
                      this.placeholderAssignedSearchChangeHandler
                    }
                    expertAdminList={this.state.expertAdminList}
                    redeemptionStatus={this.state.redeemptionStatus}
                    userProgress={this.state.userProgress}
                    handleRedeemptionStatus={this.redeemptionStatusHandler}
                    handleUserProgress={this.userProgressHandler}
                    userProgressOptions={this.userProgressOptions}
                  />
                </Popover>
    
              </StyledContentWrapper>
            </InputMain>
          </Grid>
        );
      }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
          <>
            {this.renderTitle()}
                <Box style={{ minWidth: "750px" }}>
                  {this.renderSearchFilter()}
              {
                this.state.rewardsData?.data.length > 0 ?
                <>
                    <Box display="grid" gridTemplateColumns="repeat(3,1fr)" padding="16px" marginTop="30px" width={'100%'}>
                      <StyledTableHeading className="namePosition">
                      <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>Name</span>
                      {this.renderSorting("user_name")}
                    </StyledTableHeading>
                    <StyledTableHeading>
                      <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>Branch name</span>
                      {this.renderSorting("branch_name")}
                    </StyledTableHeading>
                      <StyledTableHeading className="endPosition">
                      <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>User progress</span>
                      {this.renderSorting("user_progress")}
                    </StyledTableHeading>
                  </Box>
                  {this.renderContentBody()}
                </>
                  :
                this.renderNotFound()
              }
                </Box>
          </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledTableCell = styled(Box)({
    justifyContent: "center",
    padding: "8px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
});
  
const StyledChip = styled(Chip)({
    width: "150px",
    borderRadius: "16px",
    background: "transparent",
});

const ManageTitle = styled("span")({
  position: "absolute",
  top: "8px",
  zIndex: 9999,
  left: "168px",
  fontSize: "48px",
  fontWeight: 700,
  margin: "0px",

  "@media (max-width:1200px)": {
    fontSize: '28px',
    left: '155px',
    top: '24px'
  },
  "@media (max-width:960px)": {
    fontSize: '24px',
    left: '140px',
    top: '26px'
  },
  "@media (max-width:720px)": {
    fontSize: '18px',
    left: '120px',
    top: "28px"
  },
  '@media (max-width: 480px)': {
    fontSize: '18px',
    left: '120px',
    top: "28px"
  },
});
  
const RedeemedIcon = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "green",
});
  
const StyledTableHeading = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const ImageComponent = styled("img")({
  width: "100%",
  height: "90%",
});

const MainInnerBox = styled(Box)({
  width: "727px",
  height: "540px",
  marginTop: "40px",
});

const StyledTableRow = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  borderRadius: "8px",
  overflow: "hidden",
  padding: "4px 16px",
  background: "#FFF",
  boxShadow: "0px -2px 6px 0px #0001, 0px 2px 6px 0px #0001",
  "& .redeemBtn":{
    border: "1px solid var(--sys-color-DisabledText, #E5E2E1)",
    color: "#929090"
  },
  "& .redeemBtnActive":{
    border: "1px solid var(--sys-color-Secondary, #E47004)",
    color: "#E47004"
  }
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-1px",
});
  
const IconBack = styled(ArrowBackIosIcon)({
  cursor: "pointer",
  color: "#92929D",
  position: "absolute",
  zIndex: 1300,
  left: "135px",
  top: "33px",
  "@media (max-width:960px)": {
    left: '120px',
  },
  "@media (max-width:720px)": {
    left: '100px',
  },
  '@media (max-width: 480px)': {
    left: '68px',
  },
});

const ResponsiveImage = styled("img")({
  width: '32px',
  height: '32px',
  marginLeft:"10px",
  "@media (max-width:1200px)": {
    width: "20px", 
    height: "20px"
  },
  "@media (max-width:960px)": {
    width: "20px", 
    height: "20px",
    marginTop:'4px',
  },
  "@media (max-width:720px)": {
    width: "20px", 
    height: "20px",
    marginTop:'4px',
  },
  "@media (max-width: 480px)": {
    width: "20px", 
    height: "20px",
    marginTop:'4px',
  }
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const InputMain = styled(Grid)({
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "0px",
  },
});

const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  maxWidth: "520px",
  width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%",
  },
  "& .filterIconBox:hover": {
    borderColor: "#652786",
  },
  "& .allBorder": {
    borderColor: "#652786",
    backgroundColor: "#EEE8F2",
  },
});
const FiterImage = styled("img")({});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px",
  },
});

// Customizable Area Ende