export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const thumbnailImg = require("../assets/thumbailEmptyImg.png");
export const icon_upload = require("../assets/upload.svg");
export const Filter = require("../assets/filter.svg");
export const upload_error = require("../assets/upload_error.svg");
export const BackArrow = require("../assets/button_Back.svg");
export const UpArrow = require("../assets/UpArrow.svg");
export const DownArrow = require("../assets/DownArrow.svg");
export const RightArrorw = require("../assets/rightArrow.svg");