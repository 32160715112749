import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Dialog,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
const closeIcon = require("./icon_close.svg");


interface NoteType1 {
  id:string;
  type:string;
  attributes:{
    title:string;
    description:string;
    is_private:boolean;
    created_at:string;
    link:string | null;
    note_type:string;
    course_timestamp:string;
    is_read:boolean;
    image:{
        id:number;
        file_name:string;
        url:string;
    }
    is_editable:boolean;
    course:{
      course_id:number;
      chapter_id:number;
      lesson_name:string
    },
    chapter_title?:string
  }
  }

interface IProps {
  classes?: any;
  notes:NoteType1[];
  openNoteModal:boolean;
  handleOpenNote:(id:string) => void;
  noteId:string;
  iconType:string;
  handleOpenEditNote:(id:string) => void;
  handleDeleteNote:() => void;
  deleteNoteModal:boolean;
  handleDeleteApiCall:(id:string) => void;
  profilenote:boolean | undefined;
}

class PreviewNote extends React.Component<IProps, {}> {

  render() {
    const {
      classes,
      notes,
      openNoteModal,
      handleOpenNote,
      noteId,
      iconType,
      handleOpenEditNote,
      handleDeleteNote,
      deleteNoteModal,
      handleDeleteApiCall,
      profilenote
    } = this.props;
    return (
        <Dialog 
        open={openNoteModal}
        onClose={handleOpenNote}
        aria-labelledby="simple-dialog-title"
        style={{padding:'20px'}}
        PaperProps={{
            style: {
              margin: 'auto',
              maxWidth: '100%',
              overflowY: 'auto',
              zIndex: 9999,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: "16px",
              padding:'25px'
            },
          }}
        >
            <Box>
                {notes.filter(data => data.id === noteId).map(item => 
                <>
                <Box className={classes.firstContainer}>
                    <Typography className={classes.previewNote}>Preview Note</Typography>
                    <img className={classes.arrowBackImg} src={closeIcon} alt="close" onClick={() => handleOpenNote(item.id)}/>
                </Box>
                <Box className={classes.secondContainer}>
                    <Box>
                    <Typography className={classes.titleName}>{item.attributes.title}</Typography>
                    {profilenote ?
                    <Typography className={classes.lessonName}>{item.attributes.chapter_title}</Typography>:
                    <Typography className={classes.lessonName}>{item.attributes.course.lesson_name}</Typography>
                    }
                    </Box>
                    {(iconType === "video" || iconType === "audio" ) &&
                    (item.attributes.course_timestamp !== null &&
                    <Typography className={classes.timeStamp}>{item.attributes.course_timestamp}</Typography>
                    )
                    }
                    </Box>
                    {item.attributes.image &&
                    <img className={classes.noteImage} src={item.attributes.image?.url} alt="note"/>
                    }
                <Typography className={classes.description}>Description</Typography>
                <Typography className={classes.detailedDescription}>{item.attributes.description}</Typography>
                <Box className={classes.buttonBox}>
                    <Button className={classes.deleteButton} onClick={handleDeleteNote}>Delete</Button>
                    <Button variant="contained" className={classes.editButton} onClick={() => handleOpenEditNote(item.id)}>Edit note</Button>
                </Box>
                {deleteNoteModal &&
                <Box className={classes.deleteModal}>
                     <Typography className={classes.deleteText}>Delete Note</Typography>
                    <Box className={classes.deleteButtonBox}>
                        <Button
                        variant="contained"
                        onClick={handleDeleteNote}
                        className={classes.cancelDeleteButton}
                        >Cancel</Button>
                        <Button
                        variant="contained"
                        className={classes.deletButton}
                        onClick={() => handleDeleteApiCall(item.id)}
                        >Delete</Button>
                    </Box>
                </Box>
                    }
                </>
                )}
            </Box>
        </Dialog >
    );
  }
}


const styles = createStyles({
    firstContainer:{
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center'
    },
    arrowBackImg:{
        cursor:'pointer'
    },
    previewNote:{
        color: '#1C1B1B',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px'
    },
    secondContainer:{
        display: 'flex',
        gap: '20px',
        padding: '20px 0 10px',
        alignItems: 'center'
    },
    titleName:{
        color: '#1C1B1B',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    lessonName:{
        color:' #787776',
        lineHeight: '22px'
    },
    timeStamp:{
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        color: '#1C1B1B',
        boxShadow: '0px 0px 16px 0px #22222000',
        background: '#EEE8F2',
        padding: '5px 15px',
        borderRadius: '10px'
    },
    noteImage:{
        width:'100%',
        height:'222px',
    },
    description:{
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        margin: '15px 0 5px'
    },
    detailedDescription:{
        fontSize: '16px',
        color: '#484646',
        lineHeight: '22px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    buttonBox:{
        display: 'flex',
        justifyContent: 'end',
        gap: '80px',
        margin: '25px 0 0'
    },
    deleteButton:{
        color: '#652786',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '16px',
        textTransform: 'capitalize'
    },
    editButton:{
        background: '#E47004',
        boxShadow: 'none',
        borderRadius: '88px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '16px',
        textTransform: 'inherit',
        width:'300px',
        height:'48px',
        '&:hover':{
          background: '#E47004',
          boxShadow: 'none',
        }
    },
    deleteModal:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: "translate(-50%,-50%)",
      background: '#fff',
      borderRadius: '12px',
      boxShadow: '-6px -6px 28px 0px #85858540',
      padding: '20px 70px',
      textAlign: 'center'
    },
    deleteText:{
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '33px',
      color: '#1C1B1B',
      marginBottom: '25px'
    },
    deleteButtonBox:{
      display:'flex',
      justifyContent:'center',
      gap:'20px'
    },
    cancelDeleteButton:{
      background:'#E47004',
      borderRadius:'88px',
      boxShadow:'none',
      color:'#fff',
      fontWeight:600,
      padding: '8px 35px',
      textTransform: 'capitalize',
      '&:hover':{
          background:'#E47004',
          boxShadow:'none',
      }
    },
    deletButton:{
      background:' linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)',
      borderRadius:'88px',
      color:'#fff',
      fontWeight:600,
      padding: '8px 35px',
      textTransform: 'capitalize',
    }
    
  });

export default withStyles(styles)(PreviewNote);
// Customizable Area End