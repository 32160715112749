import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface DataItem {
  count: number;
  track_date?: string;
}

interface LineChartProps {
  data: DataItem[];
  dataweek: DataItem[];
}

const getMaxValue = (data: DataItem[]): number => {
  if (!data || data.length === 0) {
    return 0;
  }

  let max = data[0].count;

  for (let i = 1; i < data.length; i++) {
    if (data[i].count > max) {
      max = data[i].count;
    }
  }

  return max;
};

const LineChart1: React.FC<LineChartProps> = ({ data, dataweek }) => {
  const lastTimeLabels = (dataweek || []).map((item) => item.track_date || '');
  const thisTimeLabels = (data || []).map((item) => item.track_date || '');
  const allLabels = Array.from(new Set(lastTimeLabels.concat(thisTimeLabels))); 
  
  const chartData = {
    responsive: true,
    labels: allLabels,
  
    datasets: [
      {
        id: 1,
        label: '',
        data: data?.map((item) => item.count || 0),
        backgroundColor: 'transparent',
        borderColor: '#6C5DD3',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
      {
        id: 2,
        label: '',
        data: dataweek?.map((item) => item.count || 0),
        backgroundColor: 'transparent',
        borderColor: 'rgb(143 138 138)',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ],
  };

  const maxValue = Math.max(getMaxValue(data), getMaxValue(dataweek));

  const options:any = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: maxValue,
        ticks: {
          stepSize: 1,
          callback: (value: any) => value,
        },
      },
    },
  };

  return (
    <>
      <div style={{ width: '385px', height: '200px', marginTop: '20px' }}>
        <Line width="385px" height="200px" data={chartData} options={options} />
      </div>
    </>
  );
};

export default LineChart1;
