// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { courseImage, smallCheckIcon, startIcon, viewShape,startRating,emptyStart, lockIcon } from "./assets";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LinearProgress from '@material-ui/core/LinearProgress';


import ProgramPageController, {
    Props,
    configJSON
} from "./ProgramPageController.web";
import { lessonpic } from "../../../../packages/blocks/catalogue/src/assets";

// Customizable Area End
export default class ProgramPage extends ProgramPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    handleAddToCartBtnRender = () => {
      return (
          <Box style={{
              display:"flex",
              flexDirection:"column",
              justifyContent:"flex-end",
              alignItems:"end"
          }}>
              <AddToCartBox>
          <Typography style={{
              fontWeight:700,fontSize:"24px",fontFamily:"Poppins"
          }}>${this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.price}{this.state.allProgramLibraryData.data.attributes.button === "subscription" && <>/<span style={{
              fontWeight:400,fontSize:"16px",fontFamily:"Poppins"
          }}>{this.state.allProgramLibraryData.data.attributes.interval}</span></>}</Typography>
                      <Button onClick={this.addToCart}
                          startIcon={<ShoppingCartOutlinedIcon style={{fontSize:"24px"}}/>}
                          data-testid="program-cart"
                          style={{
                              width:"220px",height:"48px",
                              background: '#1C1B1B', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",
                              fontWeight:800,fontSize:"16px",fontFamily:"Poppins"
                   }}
                      >Add to cart</Button>
                      </AddToCartBox>
          </Box>
      )
  }

  handleStartBtnRender = () => {
      return (
                      <Button
                          startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
                          data-testid="program-start"
                          onClick={() => this.handleNavigateStart('start')}
                          style={{
                              width:"220px",height:"48px",
                              background: '#1C1B1B', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",textTransform:"none",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins"
                   }}>Start</Button>
      )
  }

  handleContinueBtnRender = () => {
      return (
                      <Button
                      startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
                      data-testid="program-continue"
                      onClick={() => this.handleNavigateStart('continue')}
                      style={{
                              width:"220px",height:"48px",
                              background: '#E47004', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins",textTransform:"none"
                   }}>Continue</Button>
      )
  }

  handleCompletedBtnRender = () => {
      return (
                      <Button
                          startIcon={<img src={smallCheckIcon} style={{width:"24px",height:"24px"}}/>}
                          style={{
                              width:"220px",height:"48px",
                              background: '#652786', color: '#fff',
                              borderRadius: '88px',
                              padding: '8px 25px',
                              cursor: 'pointer',
                              marginTop:"10px",textTransform:"none",
                              fontWeight:600,fontSize:"16px",fontFamily:"Poppins"
                   }}>{configJSON.Completed}</Button>
      )
  }

  handleBtnRender = () =>{
      if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_start){
        return this.handleStartBtnRender()
      }else if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_continue){
        return this.handleContinueBtnRender()
      }else if(this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.is_completed){
        return this.handleCompletedBtnRender()
      }else if(this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled){
        return this.handleAddToCartBtnRender()
      }
  }
    // Customizable Area Start

    renderImage = (item:any) => {
      if((this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || item.is_lock){
        return lockIcon
      }else{
        return item.thumbnail_image
      }
    }

    renderImageBox = (item:any) => {
      const isLock = (this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || item.is_lock
      return (
        <Box style={{...webStyle.flexCenter,height:"100%",marginTop:"5px"}}><img width={isLock ? "24px" : "80px"} height={isLock ? "24px" : "80px"} src={this.renderImage(item)}/></Box>
        
      )
    }
     renderPhasesData = (item:any) => {
      const isLock = (this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || item.is_lock

        return (
            <CustomAccordionDetails key={item} data-testid={"phase-data-" + item.id} onClick={() =>  !isLock && this.handleICorseNavigate(item)}>
               {item.description.length > 100 ?
                    (<LightNewTooltip
                      placement="top"enterDelay={500}
                      leaveDelay={100}enterTouchDelay={5}
                      leaveTouchDelay={10000}
                      interactive={true}
                      data-test-id="lightNewTooltip"
                      arrow={true}
                      title={
                        <NewToolTipMain>
                      <Typography style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            color:"black",
            }}>{item.description}</Typography>
                        </NewToolTipMain>
                      }
                    >
                    <Box data-testid={"phase-data1-" + item.id} style={{...webStyle.flexBox,gap:"12px",alignItems:"start",cursor:"pointer"}} onClick={() => !isLock && this.handleICorseNavigate(item)}>
                        {this.renderImageBox(item)}
                        <Box>
                  <Typography style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "22px",
            }}>{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</Typography>
                  <Typography style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            }}>{`${item.description.slice(0,100)}...`}</Typography>
            {item.rating > 0 &&
                  <Typography style={{display:"flex",
                  alignItems:"center",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    color:"#FF8B3F"
                  }}>{item.rating % 1 === 0 ? item.rating : item.rating.toFixed(1)}
                  <Rating
                    name='read-only'
                    icon={<img src={startRating}/>}
                    value={item.rating.toFixed(1)}
                    precision={0.5}
                    emptyIcon={<img src={emptyStart}/>}
                    /></Typography>
                  }
                  <Typography  style={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "22px",
            }}>{item.author_name}</Typography>
                  </Box>
                  </Box>
                  </LightNewTooltip>) : (
                    <Box style={{...webStyle.flexBox,gap:"12px",alignItems:"start",cursor:"pointer"}} data-testid={"phase-description-data-" + item.id} onClick={() => !isLock && this.handleICorseNavigate(item)}>
                     {this.renderImageBox(item)} <Box>
                  <Typography style={{
            fontSize: "16px",fontWeight: 700,
            lineHeight: "22px",
            }}>{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</Typography>
                  <Typography style={{
            fontSize: "14px",fontWeight: 500,
            lineHeight: "22px",
            }}>{item.description.split(0,500)}</Typography>
            {item.rating > 0 &&
                  <Typography style={{display:"flex",
                  alignItems:"center",
                    fontSize: "16px",
                    fontWeight: 600,lineHeight: "22px",
                    color:"#FF8B3F"}}>{item.rating % 1 === 0 ? item.rating : item.rating.toFixed(1)}<Rating
                    name='read-only'
                    value={item.rating.toFixed(1)}
                    readOnly
                    precision={0.5}
                    icon={<img src={startRating}/>}
                    emptyIcon={<img src={emptyStart}/>}
                    /></Typography>
                  }
                  <Typography  style={{
            fontSize: "12px",fontWeight: 400,
            lineHeight: "22px",
            }}>{item.author_name}</Typography>
                  </Box>
                  </Box>
                  )}
          </CustomAccordionDetails>
        )
     }

     renderWithoutPhaseData = (phases:any) => {
      const isLock = (this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || phases.is_lock

        return (
            phases.description.length > 100 ?
            (
                <LightNewTooltip
                placement="top"enterDelay={500}
                leaveDelay={100}enterTouchDelay={5}
                leaveTouchDelay={10000}interactive={true}
                arrow={true}title={
                  <NewToolTipMain>
                <Typography style={{
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      color:"black",
      }}>{phases.description}</Typography>
                  </NewToolTipMain>
                }
              ><CustomBox data-testid={"withoutphase-description-data-" + phases.id} key={phases} onClick={() => !isLock && this.handleICorseNavigate(phases)}>
                    <Box style={{...webStyle.flexBox,marginTop:"20px",gap:"12px",alignItems:"start"}}>
                        <img width="80px" height="80px" style={{
                            borderRadius:"8px"
                        }} src={phases.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",fontWeight: 700,
            lineHeight: "22px",}}>{phases.title.charAt(0).toUpperCase() + phases.title.slice(1)}</Typography>
                  <Typography style={{fontSize: "14px",fontWeight: 500,lineHeight: "22px",}}>{`${phases.description.slice(0,100)}...`}</Typography>
                  {phases.rating > 0 &&
                  <Typography style={{display:"flex",
                  alignItems:"center",gap:'2px',fontSize: "16px",fontWeight: 600,lineHeight: "22px",color:"#FF8B3F"}}>
                    {phases.rating % 1 === 0 ? phases.rating : phases.rating.toFixed(1)}
                  <Rating
                    name='read-only'
                    readOnly
                    precision={0.5}
                    value={phases.rating.toFixed(1)}
                    icon={<img src={startRating}/>}
                    emptyIcon={<img src={emptyStart}/>}
                    />
                    </Typography>}
                  <Typography  style={{fontSize: "12px",
            fontWeight: 400,
            lineHeight: "22px",
            }}>{phases.author_name}</Typography>
                  </Box>
                  </Box>
          </CustomBox></LightNewTooltip>)
          : (
            <CustomBox key={phases}>
                    <Box data-testid={"withoutphase-data-" + phases.id}  onClick={() =>!isLock &&  this.handleICorseNavigate(phases)} style={{...webStyle.flexBox,marginTop:"20px",gap:"12px",alignItems:"start",cursor:"pointer"}}>
                        <img width="80px" height="80px" style={{borderRadius:"8px"}} src={phases.thumbnail_image}/>
                        <Box>
                  <Typography style={{
            fontSize: "16px",
            fontWeight: 700,lineHeight: "22px",
            }}>{phases.title.charAt(0).toUpperCase() + phases.title.slice(1)}</Typography>
                  <Typography style={{
            fontSize: "14px",
            fontWeight: 500,lineHeight: "22px",
            }}>{phases.description}</Typography>
            {phases.rating > 0 &&
                  <Typography style={{display:"flex",
                  alignItems:"center",
                  gap:'2px',
                    fontSize: "16px",fontWeight: 600,lineHeight: "22px",color:"#FF8B3F"
                  }}>{phases.rating % 1 === 0 ? phases.rating : phases.rating.toFixed(1)}<Rating
                    name='read-only'
                    precision={0.5}
                    icon={<img src={startRating}/>}
                    emptyIcon={<img src={emptyStart}/>}
                    value={phases.rating.toFixed(1)}
                    />
                    </Typography>
            }
                  <Typography  style={{
            fontSize: "12px",fontWeight: 400,lineHeight: "22px",
            }}>{phases.author_name}</Typography>
                  </Box></Box>
          </CustomBox>
          )
        )
     }

     renderCourses = () => {
      return (
        <Box>
                           {this.state.allProgramLibraryData.data.attributes.content_release_schedule ? this.state.allProgramLibraryData.data.attributes.phases.map((phases:any,index:number)=>(
                       <CustomizeAccordion key={index}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
           disabled={(this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || phases.is_lock}
         >
          {(this.state.allProgramLibraryData.data && !this.state.allProgramLibraryData.data.attributes.is_enrolled) || phases.is_lock ? (
            <div style={webStyle.flexBox}>
              <img style={{marginRight:"10px"}} width={"24px"} height={"24px"} src={lockIcon}/>
            <p style={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "22px",
            }}>{phases.phase_name}</p>
            </div>
          ) : (
           <p style={{
           fontSize: "16px",
           fontWeight: 700,
           lineHeight: "22px",
           }}>{phases.phase_name}</p>
          )}
         </AccordionSummary>
           {phases.program_include.map((item: any) => {
             return (
                 this.renderPhasesData(item)
             );
           })}
           
       </CustomizeAccordion>
                           )) : (
                               <Box style={{marginTop:"10px"}}>
                               {this.state.allProgramLibraryData.data.attributes.program_include.map((phases:any) =>
                                   this.renderWithoutPhaseData(phases)
   )}
                           </Box>
                           )}
                       </Box>
      )
     }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Loader loading={this.state.isLoading} />
                {this.state.allProgramLibraryData.data &&
                   <Grid container key={this.state.allProgramLibraryData.data.id}>
                   <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '14px' }}>
                       <Box style={webStyle.flexBox}>
                   <img src={viewShape} data-test-id="backButtonId" style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}} onClick={this.gotoBackSide}/>
                       <Typography variant="h3" style={{ fontSize:"48",fontWeight: 700,color:"#1C1B1B" }}>{this.state.allProgramLibraryData.data.attributes.title.charAt(0).toUpperCase() + this.state.allProgramLibraryData.data.attributes.title.slice(1)}
                       </Typography>
                          <Box style={webStyle.statusBox}>
                          <FiberManualRecordIcon 
                          style={{
                              color:`${this.state.allProgramLibraryData?.data?.attributes?.course_color?.course_status_dot_color}`,
                              width:'14px',
                              paddingRight:'3px'
                          }}/>
                          <Typography
                          style={{
                           color:`${this.state.allProgramLibraryData?.data?.attributes?.course_color?.course_status_color}`,
                           fontSize:'14px'
                         }}
                          >{this.state.allProgramLibraryData.data.attributes.course_status}</Typography>
                          </Box>
                       </Box>
                       <Grid container spacing={4} style={{ marginTop: '4px' }}>

                           <Grid item lg={8} xs={12}>
                               <Box
                                   style={{
                                       width: "100%",
                                       height: "344px",
                                       borderRadius:"16px 16px 0px 0px",

                                   }}>
                                   <img src={this.state.allProgramLibraryData.data.attributes.thumbnail_image.url} style={{ width: '100%', height: '344px', borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }} alt="img" />
                               </Box>
                               <Grid container style={{ marginTop: '20px' }}>
                                   <Grid item lg={6} md={6} sm={12}>
                                       <Box style={{...webStyle.flexBox,gap:"14px"}}>
                                           <Box>
                                       <Typography style={{ fontSize: '12px', fontFamily:"Poppins",color:"#1C1B1B", fontWeight: 400 }}>Created By</Typography>
                                       <Box style={{ fontSize: '16px', fontFamily:"Poppins",color:"#787776", fontWeight: 500 }}>{this.state.allProgramLibraryData.data.attributes.created_by}</Box>
                                       </Box>
                                       <Box>
                                       <Typography style={{ fontSize: '12px', fontFamily:"Poppins",color:"#1C1B1B", fontWeight: 400 }}>Category</Typography>
                                       <Box style={{ fontSize: '16px', fontFamily:"Poppins",color:"#787776", fontWeight: 500 }}>{this.state.allProgramLibraryData.data.attributes.categories} </Box>
                                       </Box>
                                       {this.state.allProgramLibraryData?.data?.attributes?.average_rating > 0 &&
                                         <Box>
                                             <Typography style={webStyle.rattingTypo}>{configJSON.ratings}</Typography>
                                             <Box style={webStyle.rattingBox}>
                                                 <span style={webStyle.ratting}>
                                                  {this.state.allProgramLibraryData.data.attributes.average_rating % 1 === 0 ? this.state.allProgramLibraryData.data.attributes.average_rating : this.state.allProgramLibraryData.data.attributes.average_rating.toFixed(1)}</span>
                                                 <Rating
                                                     name='read-only'
                                                     value={this.state.allProgramLibraryData.data.attributes.average_rating.toFixed(1)}
                                                     readOnly
                                                     precision={0.5}
                                                     icon={<img src={startRating}/>}
                                                     emptyIcon={<img src={emptyStart}/>}
                                                 />
                                             </Box>
                                         </Box>
                                       }
                                       </Box>
                                       <Box style={{marginTop:"20px"}}>
                                           <Typography
                                           style={{
                                               fontSize: "16px",
                                               fontWeight: 600,
                                               lineHeight: "16px",
                                               color:"#652786",
                                               gap:"16px"
                                           }}>Due Date:&nbsp;&nbsp;&nbsp;<span style={{
                                               fontSize: "16px",
                                               fontWeight: 400,
                                               lineHeight: "16px",
                                               color:"#484646",
                                           }}>{this.state.allProgramLibraryData.data.attributes.due_date}</span></Typography>
                                       </Box>
                                   </Grid>
                                   <Grid item lg={6} md={6} sm={12} style={{display:"flex",justifyContent:"flex-end"}}>
                                       {this.handleBtnRender()}
                                   </Grid>
                               </Grid>
                               <Typography style={{
                                        marginTop:"10px",
                                        fontWeight:600,fontSize:"20px",fontFamily:"Poppins",color:"#484646"
                              }}>Description</Typography>
                               <Typography style={{
                                        marginTop:"5px",
                                        fontWeight:400,fontSize:"16px",fontFamily:"Poppins",color:"#484646"
                              }}>{this.state.allProgramLibraryData.data.attributes.description}</Typography>
                              
                              {(this.state.allProgramLibraryData.data.attributes.rewards.length > 0 || 
                                this.state.allProgramLibraryData.data.attributes.achievements.length > 0
                              ) &&
                            <Box>
                                <Typography style={webStyle.programPart}>
                                  {configJSON.programPart}</Typography>
                                <Box style={webStyle.programRewardContainer}>
                                    <CustomProgramReward>
                                        <Typography style={webStyle.rewardText}>{configJSON.rewards}</Typography>
                                        {this.state.allProgramLibraryData?.data?.attributes?.rewards?.map((data:{icon_url:string,name:string}) =>
                                        <Box  style={webStyle.rewardImgBox}>
                                            <img style={webStyle.rewardImg} 
                                            src={data.icon_url} alt="reward"/>
                                            <RewardName>{data.name}</RewardName>
                                        </Box>
                                        )}
                                    </CustomProgramReward>
                                    <CustomProgramReward>
                                    <Typography style={webStyle.rewardText}>{configJSON.achievement}</Typography>
                                    {this.state.allProgramLibraryData?.data?.attributes?.achievements?.map((item:{icon_url:string,name:string}) =>
                                        <Box style={webStyle.rewardImgBox}>
                                            <img style={webStyle.rewardImg} src={item.icon_url} alt="reward"/>
                                            <RewardName>{item.name}</RewardName>
                                        </Box>
                                        )}
                                    </CustomProgramReward>
                                </Box>
                            </Box>
                            }
                           </Grid>
                           <Grid item lg={4} xs={12}>
                               <Box style={{
                                   boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                   padding:"24px",
                               }}>
                                 {(this.state.allProgramLibraryData.data.attributes.is_completed || this.state.allProgramLibraryData.data.attributes.is_continue) ? 
                                 <>
                                 <ProgressContainer>
                                 <ProgramSetting>{configJSON.programCompleted}</ProgramSetting>
                               <ProgressTop>
                                   <CourseCount ><span data-testid="total_courses">{this.state.allProgramLibraryData.data.attributes.total_courses}</span> {configJSON.courses}</CourseCount>
                                   <ProgramSettingAfter>{this.state.allProgramLibraryData.data?.attributes.due_in_days}</ProgramSettingAfter>
                               </ProgressTop>
                               <Box><StyledProgressBar variant="determinate" value={this.state.allProgramLibraryData.data?.attributes?.progress_bar_percentage * 100} />
</Box>
                               <ProgressBottom>
                                   <LessonsFont>{this.state.allProgramLibraryData.data?.attributes?.completed_lesson} of {this.state.allProgramLibraryData.data?.attributes?.total_lessons} lessons completed</LessonsFont>
                                   <LessonsFont>{configJSON.phaseRelease} <span style={{fontWeight:700}}>{this.state.allProgramLibraryData.data?.attributes?.content_release_schedule_value}</span></LessonsFont>
                               </ProgressBottom>
                              </ProgressContainer>
                                 </>: 
                                 <>
                               <Typography style={{
                                fontFamily: "Poppins",fontSize: "24px",fontWeight: 600,lineHeight: "36px",
                               }}>Program includes</Typography>
                       <Box style={{...webStyle.flexBox,...webStyle.gapTwentyFour,marginTop:"15px"}}>
                           <Box style={{
                         display: "flex",
                         maxWidth: "304px",
                         gap: "8px",
                         alignItems: "center",
                         flexWrap: "wrap",
                          borderRight:"2px solid #E5E2E1",
                          padding:"0 15px 0px 0px"
                       }}>
                         <div>
                           <img src={courseImage} />
                         </div>
                         <div>
                           <Typography style={{
                             fontWeight: 700,
                             fontSize: "18px",
                             fontFamily: "Poppins",
                             lineHeight: "24px",
                             color: "black",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                           }}
                           > {this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.total_courses} </Typography>
                           <Typography style={{
                             fontWeight: 400,
                             fontSize: "14px",
                             fontFamily: "Poppins",
                             lineHeight: "16px",
                             color: "#484646"
                           }}> Courses </Typography>
                         </div>

                       </Box>
                       <Box style={{
                         display: "flex",
                         maxWidth: "304px",
                         gap: "8px",
                         alignItems: "center",
                         flexWrap: "wrap"
                       }}>
                         <div>
                           <img src={lessonpic} />
                         </div>
                         <div>
                           <Typography style={{
                             fontWeight: 700,
                             fontSize: "18px",
                             fontFamily: "Poppins",
                             lineHeight: "24px",
                             color: "black",
                             display: "flex",
                             justifyContent: "center",
                             alignItems: "center"
                           }}
                           > {this.state.allProgramLibraryData.data && this.state.allProgramLibraryData.data.attributes.total_lessons} </Typography>
                           <Typography style={{
                             fontWeight: 400,
                             fontSize: "14px",
                             fontFamily: "Poppins",
                             lineHeight: "16px",
                             color: "#484646"
                           }}> Lessons </Typography>
                         </div>
                       </Box>
                       </Box>
                       </>
                       }
                       {(this.state.allProgramLibraryData.data.attributes.content_release_schedule) && <AfterCountLesson>
                                           <Typography className="weightSet">Phases are released</Typography>
                                           <ContentReleaseStatus style={{...webStyle.flexBox,justifyContent:"center"}}>
                                               {this.state.allProgramLibraryData.data.attributes.content_release_schedule_value}</ContentReleaseStatus>
                                       </AfterCountLesson>}
                       {this.renderCourses()}
                       </Box>
                           </Grid>
                       </Grid>
                   </Grid>
               </Grid>
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    flexCenter:{
     display:"flex",
     justifyContent:"center",
     alignItems:"center"
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    flexBox:{
        display:"flex",
        justifyContent:"start",
        alignItems:"center"
    },
    gapTwentyFour:{
        gap:"24px"
    },
    rattingTypo:{
      fontSize: '12px',
      fontWeight: 400
    },
    rattingBox:{
      display: 'flex',
      alignItems: 'center',
      gap:'2px'
    },
    ratting:{
      marginTop: '3px',
      color: '#FF8B3F',
      fontSize: '16px',
      fontWeight:600
    },
    statusBox:{
      display:'flex',
      paddingLeft:'20px'
    },
    programRewardContainer:{
      display:'flex',
      justifyContent:'space-between',
      marginBottom:'10px',
      gap:'20px',
      flexWrap:'wrap' as 'wrap'
    },
    programPart:{
      fontSize:'22px',
      fontWeight:600,
      lineHeight:'33px',
      color: '#1C1B1B',
      marginBottom:'20px'
    },
    rewardText:{
      fontSize:'20px',
      fontWeight:600,
      color:'#1C1B1B',
      lineHeight:'24px',
      marginBottom:'10px'
    },
    rewardImgBox:{
      display:'flex',
      background:'#FFFFFF',
      padding:'24px',
      borderRadius:'8px',
      gap:'10px',
      height: '129px',
      alignItems: 'center',
      boxShadow: 'rgba(0, 0, 0, 0.07) 1px 2px 9px 3px',
      marginBottom:'20px'
    },
    rewardImg:{
      width: '81px',
      height: '81px',
      borderRadius:'8px'
    }
};

const CustomProgramReward = styled(Box)({
  width:'45%',
    '@media(max-width: 680px)': {
        width:'100%',
    },
})

const CustomAccordionDetails = styled(AccordionDetails)({
    "&:hover": {
        border:"1px solid #542070",
        backgroundColor: '#EEE8F2',
        borderRadius:"12px"
    },
})

const CustomBox = styled(Box)({
    padding:"10px",
    "&:hover": {
        backgroundColor: '#EEE8F2',
        border:"1px solid #542070",
        borderRadius:"12px"
      },
})

const ProgressContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  margin: "20px 0",
  paddingBottom:"35px",
  borderBottom: " 1px solid #E5E2E1"
});

const ProgressTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const ProgressBottom = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const ProgramSetting = styled(Typography)({
  fontSize: "18px !important",
  fontWeight: 600
});

const ProgramSettingAfter = styled(Typography)({
  fontSize: "12px !important",
  fontWeight: 700 
});

const CourseCount = styled(Typography)({
  fontSize: "18px !important",
  fontWeight: 600 
});

const StyledProgressBar = styled(LinearProgress)({
  height: "8px !important",
  borderRadius: "5px",
  backgroundColor: "#EEE8F2 !important",
  "& .MuiLinearProgress-barColorPrimary":{
      background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
      borderRadius:"4px"
  }

});

const LessonsFont = styled(Typography)({
  fontWeight: 400,
  fontSize: "12px !important"
});

const LightNewTooltip = withStyles((theme) => ({
    tooltip: {
        boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
      backgroundColor: theme.palette.common.white,
      border: '15px',
      maxWidth: '341px',
      padding: '10px',
      borderRadius: "14px",
      top: theme.props?.MuiTooltip?.placement === "top" ? "auto" : 40,
    },
    arrow: {
        color: "white",
      },
  }))(Tooltip);

  const NewToolTipMain = styled("div")({
    width: "100%"})
const AfterCountLesson = styled(Box)({
    display: "flex", 
    alignContent: "center", 
    justifyContent: "space-between", 
    alignItems: "center",
     gap: "10px",
     height: "64px", 
         fontSize: "14px",
        fontWeight: 400,
        marginTop:"10px"
});

const ContentReleaseStatus = styled(Typography)({
    borderRadius: "88px",
    background: "#E0D4E7",
    padding: "6px 9px 6px 9px",
    height: "40px",
    width: "86px",
    fontWeight: 400,
    textAlign: "center",
    color: "#652786",
    fontSize: "14px !important",
});

const CustomizeAccordion = styled(Accordion)({
    "&.MuiAccordion-root .Mui-expanded":{
        maxWidth:"100% !important"
    }
})

const AddToCartBox = styled(Box)({
        textAlign:"center",
        gap:"16px",
        "@media (max-width:960px)": {
            display:"flex",
            marginTop:"20px",
            alignItems:"center"
          },
})

const RewardName = styled(Typography)({
  fontSize:'14px',
  fontWeight:500,
  color:' #484646',
  lineHeight:'22px',
  '&::first-letter':{
    textTransform: 'uppercase'
  }
})



// Customizable Area End