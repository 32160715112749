import React, { useState, useEffect } from "react";
import { Form } from "formik";
import QuizTitle from "./QuizTitle.web";
import QuizBasics from "./QuizBasics.web";
import QuizDetails from "./QuizDetails.web";
import QuizActions from "./QuizActions.web";
import RouteLeavingGuard from "./RouteLeavingGuard.web";

export default function QuizForm({
  handleRemoveOptionsForMcqQuestions,
  handleCancelSavingQuestion,
  handleQuestionTypeChange,
  handleDeleteQuestion,
  handleEditQuestion,
  handleAddMcqOption,
  handleBulkActions,
  handleAddQuestion,
  handleCreateQuiz,
  isSubmitting,
  setValues,
  handleChange,
  handleBlur,
  navigation,
  resetForm,
  courseId,
  location,
  editQuiz,
  history,
  touched,
  errors,
  values,
  dirty,
}: any) {
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const phaseOptions = [];

  // Generate the options for the phase select button
  if (location.state.hasFreeTrial) {
    phaseOptions.push({ id: 0, value: 0, label: "Free Trial" });
  }
  
  Object.values(location.state.phases).forEach((phase: any) => {
    const curPhase = phase[0].phase;
    if (curPhase > 0) {
      phaseOptions.push({
        id: curPhase,
        value: curPhase,
        label: `Phase ${curPhase}`,
      });
    }
  });

  // Blocks navigation if current path & next path doesn't match
  // const shouldBlockNavigation = (nextLocation: any) => {
  //   return dirty && location.pathname !== nextLocation.pathname ? true : false;
  // };

  // Detect when to disable the quiz save button
  useEffect(() => {
    if (values.questions.length === 0) {
      setDisableSaveBtn(true);
      return;
    }
    const unsavedQuestionFound = values.questions.some(
      (question: any) => !question.isSaved
    );
    setDisableSaveBtn(unsavedQuestionFound);
  }, [values]);

  // Clear questions ids from local storage those are
  // not included inside any quizzes
  useEffect(() => {
    const { questions } = values;
    if (questions.length === 0) {
      localStorage.removeItem("question_ids");
    }
  }, [values]);

  return (
    <Form noValidate autoComplete="off">
      {/* <RouteLeavingGuard
        when={dirty}
        navigate={history.push}
        handleBulkActions={handleBulkActions}
        shouldBlockNavigation={shouldBlockNavigation}
      /> */}
      <QuizTitle
        editQuiz={editQuiz}
        values={values}
        handleChange={handleChange}
        handleGoBack={navigation.goBack}
        phaseOptions={phaseOptions}
      />
      <QuizBasics
        values={values}
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <QuizDetails
        errors={errors}
        values={values}
        touched={touched}
        courseId={courseId}
        resetForm={resetForm}
        setValues={setValues}
        isSubmitting={isSubmitting}
        handleBulkActions={handleBulkActions}
        handleEditQuestion={handleEditQuestion}
        deleteQuestion={handleDeleteQuestion}
        handleChange={handleChange}
        handleAddQuestion={handleAddQuestion}
        handleAddMcqOption={handleAddMcqOption}
        handleQuestionTypeChange={handleQuestionTypeChange}
        handleCancelSavingQuestion={handleCancelSavingQuestion}
        handleRemoveOptionsForMcqQuestions={handleRemoveOptionsForMcqQuestions}
      />
      <QuizActions
        values={values}
        resetForm={resetForm}
        disableSaveBtn={disableSaveBtn}
        handleGoBack={navigation.goBack}
        handleCreateQuiz={handleCreateQuiz}
      />
    </Form>
  );
}
