export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const DummyImage = require("../assets/theme_card_icon.png")
export const themeImg = require("../assets/themeImg.png");
export const attch = require("../assets/attachment.png");
export const newCourseContent = require("../assets/newContentCourse.svg");
export const FilterContent = require("../assets/filter.svg");
export const NotFImage = require("../assets/NotFoundImage.jpeg")
export const NewFIlter = require("../assets/NewFilter.svg");
export const DeleteAll = require("../assets/deleteAll.svg");
export const TrashIcon = require("../assets/trashIcon.svg");
export const DocumentIcon = require("../assets/document.svg");
export const Video = require("../assets/video.svg");
export const EditLibrary = require("../assets/editIcon.svg");
export const DownloadIcon = require("../assets/downloadIcon.svg");
export const ContentDelete = require("../assets/contentDelete.svg");
export const UpArrow = require("../assets/UpArrow.svg");
export const DownArrow = require("../assets/DownArrow.svg");
export const checkboxIcon = require("../assets/button_dots1.svg");
export const CheckedIcon = require("../assets/checkedIcon.svg");
export const iconCheckbox = require("../assets/icon_checkbox.svg");
