Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.apiContentTypeApplicationJson = "application/json";
exports.faqUrl = "bx_block_settings/faqs";
exports.notificationSettingsUrl = "bx_block_notifsettings/notifications_settings";
exports.deactivateAccountUrl = "account_block/account/deactive_account";

exports.btnExampleTitle = "CLICK ME";
exports.NotificationSettings = "Notification Settings"
// Customizable Area End