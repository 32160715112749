import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  handleClose: () => void;
  handleOpen: () => void;
  mainModalClose: any;
  open1: any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  allXpandLibararyData: any;
  grapValues: any;
  postXpandLibararyData: any;
  search: any;
  themeMode: boolean;
  themeModeDelete: boolean;
  dialogopen: boolean;
  createFolder: any;
  dataID: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageContentLibrarylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  PostCeateNewFolder: any;
  GetSearchData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: "",
      grapValues: "Week",
      allXpandLibararyData: {},
      postXpandLibararyData: {},
      search: "",
      createFolder: "",
      themeMode: false,
      themeModeDelete: false,
      dialogopen: false,
      dataID: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
    this.getXpandLibraryData();
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    switch (message.id) {
      case getName(MessageEnum.AccoutLoginSuccess): {
        const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
        this.showAlert("Change Value", `From: ${this.state.txtSavedValue} To: ${value}`);
        this.setState({ txtSavedValue: value });
        break;
      }

      case getName(MessageEnum.RestAPIResponceMessage): {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId && responseJson) {
          switch (apiRequestCallId) {
            case this.GetXpandLibraryData:
              this.setState({ allXpandLibararyData: responseJson });
              break;
            case this.PostCeateNewFolder:
              if (responseJson.folder_type === "content_library") {
                this.setState({ createFolder: "" });
                this.getXpandLibraryData();
                this.dialogHandleClose();
                this.setState({ dialogopen: false });
                toast.success("Folder created successfully!");
              } else if (responseJson.errors && responseJson.errors.length > 0) {
                const errorMessages = responseJson.errors.map((error: { message: any; }) => error.message);
                toast.error(errorMessages[0]);
              } else {
                toast.success("Folder creation failed. Unknown error.");
              }
              break;

            case this.GetSearchData:
              this.setState({ allXpandLibararyData: responseJson });
              if (this.state.search === "") {
                this.getXpandLibraryData();
              } else if (responseJson.data.length === 0 && responseJson.videos.data.length === 0) {
                toast.error("No data available here")
              }
              break;
          }
        }
        break;
      }
      default:
        break;
    }
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNavigate = (data: any) => {
    if (this.props.open1 === false) {
      this.props.handleOpen();
      this.setState({dataID:data})
    } else {
      this.props.history.push(`/ManageContentFolder/${data}`, { id: data });
    }
  };

  tostSuccessfully = () => {
    toast.success("Coming soon");
  }

  dialogHandleClose = () => {
    this.setState({ dialogopen: false });
  };

  dialogHandleOpen = () => {
    this.setState({ dialogopen: true });
  };

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/?folder_type=content_library`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewFolder = () => {
    let formdata = new FormData();
    formdata.append("name", this.state.createFolder);
    formdata.append("folder_type", "content_library");

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)   
    );
    this.PostCeateNewFolder = requestMessage.messageId;    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchData = async (event: any) => {
    this.setState({ search: event.target.value })
    console.log(this.state.search);

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder?folder_type=content_library&search=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Customizable Area End
}
