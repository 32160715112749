import React from "react";
import { Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { OutlinedButton } from "../../../components/src/Buttons";

interface Props {
  values: any;
  resetForm: any;
  handleGoBack: any;
  handleCreateQuiz: any;
  disableSaveBtn: boolean;
}

export default function QuizActions({
  values,
  resetForm,
  handleGoBack,
  disableSaveBtn,
  handleCreateQuiz,
}: Props) {
  const classes = useStyles();

  const onSaveBtnClick = async () => {
    const savedQuiz = await handleCreateQuiz(values, resetForm);
    if (savedQuiz) {
      handleGoBack();
    }
  }

  return (
    <Grid container justifyContent="flex-end">
      <OutlinedButton
        style={{ marginRight: 10 }}
        onClick={handleGoBack}
      >
        cancel
      </OutlinedButton>
      <Button
        data-test-id="quiz-save-button"
        variant="contained"
        color="primary"
        disabled={disableSaveBtn}
        className={classes.containedBtn}
        onClick={onSaveBtnClick}
      >
        save
      </Button>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedBtn: {
      backgroundColor: "#6C328B",
      color: "#FFFFFF",
      fontSize: "15px",
      fontWeight: 600,
      textTransform: "capitalize",
      borderRadius: "16px",
      padding: "10px",
      "&:hover": {
        backgroundColor: "#6C328B",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "14px 26px",
        fontSize: "18px",
      },
    },
  })
);
