import React from "react";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps {}

function RouteProtection({ location }: Props) {
  const { pathname } = location;
  const token = localStorage.getItem("token");

  if (!token) {
    // Stop redirection for the following routes
    if (
      pathname.startsWith("/reset_password") ||
      pathname.startsWith("/signup") ||
      pathname.startsWith("/forgotpassword") 
    ) {
      return null;
    }
    // Redirect to login route if no token found
    return <Redirect to="login" />;
  } else {
    // Render noting if token is found
    return null;
  }
}

// @ts-ignore
export default withRouter(RouteProtection);
