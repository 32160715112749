// Customizable Area Start
import React from 'react'
import {
    Box,
    Typography,
    Grid,
    Button,
    Card,
    CardContent,


} from "@material-ui/core";
import './SplashScreen.css'
import SplashscreenController, { Props } from './SplashscreenController.web';
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'
const styles: any = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            maxHeight: '100vh',

        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,

        },
    });

class TermPage extends SplashscreenController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const currentUrl = new URL(window.location.href);
        const isSignupPage = currentUrl.pathname.includes("signup");
        return (
            <>
                {this.state.isLoading ? <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%" }}></Box> : <>
                    <Grid container className="splash term">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                    <Card className="custom-card">
                                        <p style={{ textAlign: "left" }}  className="mainheading">
                                            Terms and Conditions
                                        </p>
                                        <CardContent style={{ textAlign: "left" }}>
                                            <div className='MuiList-root'
                                                dangerouslySetInnerHTML={{ __html: `${this.state.data}` }} />

                                        </CardContent>

                                    </Card>
                                    <Grid container style={{ justifyContent: "center", marginTop: "2%", marginBottom: "6%" }}>
                                        <Grid item md={6} sm={6}>
                                            <Button 
                                                date-test-id="back-btn"
                                                fullWidth 
                                                className="back-btn" 
                                                onClick={
                                                    isSignupPage
                                                    ? this.props.handleClose 
                                                    : this.props.navigation.goBack
                                                }
                                            >
                                                Back
                                            </Button> 
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>


                        </Box>
                    </Grid>
                </>}
            </>
        )
    }
}

export default (withStyles(styles)(TermPage));
// Customizable Area End