import React, { useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { NotesIcon, FullScreenOnIcon, FullScreenOffIcon } from "../assets";

export interface IPdfViewer {
  url: string;
  fullScreenPdf: boolean;
  onNoteBtnClick?: () => void;
  onFullScreenBtnClick: () => void;
}

export default function PdfViewer({ 
  url, 
  fullScreenPdf, 
  onNoteBtnClick,
  onFullScreenBtnClick,
}: IPdfViewer) {
  const classes = useStyles();
  const [numOfPages, setNumOfPages] = useState(0);
  const [curPageNumber, setCurPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumOfPages(numPages);
  };

  const changePage = (offSet: number) => {
    setCurPageNumber(curPageNum => curPageNum + offSet);
  }

  const handleGoToPrevPage = () => {
    changePage(-1);
  }

  const handleGoToNextPage = () => {
    changePage(+1);
  }

  let floatingLeftIcon = null;
  if (curPageNumber > 1) {
    floatingLeftIcon = (
      <IconButton className={classes.leftArrowBtn} onClick={handleGoToPrevPage}>
        <KeyboardArrowLeftIcon className={classes.arrowIconLarge} />
      </IconButton>
    );
  }

  let floatingRightIcon = null;
  if (curPageNumber < numOfPages) {
    floatingRightIcon = (
      <IconButton className={classes.rightArrowBtn} onClick={handleGoToNextPage}>
        <KeyboardArrowRightIcon className={classes.arrowIconLarge} />
      </IconButton>
    );
  }

  let notesIcon = null;
  if (fullScreenPdf) {
    notesIcon = (
      <Button onClick={onNoteBtnClick}>
        <img src={NotesIcon} alt="notes icon" />
      </Button>
    );
  }

  let bottomToolbarContent = null;
  if (numOfPages > 0) {
    bottomToolbarContent = (
      <div className={fullScreenPdf ? classes.toolbarBottom : classes.toolbar}>
        <IconButton 
          onClick={handleGoToPrevPage}
          disabled={curPageNumber === 1}
        >
          <KeyboardArrowLeftIcon className={classes.arrowIcon} />
        </IconButton>
        <p>{curPageNumber}</p>
        <p>/{numOfPages}</p>
        <IconButton 
          onClick={handleGoToNextPage} 
          disabled={curPageNumber === numOfPages}
        >
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </IconButton>
        <div className={classes.nestedIcons}>
          {notesIcon}
          <Button 
            className={classes.fullScreenBtn}
            onClick={onFullScreenBtnClick}
          >
            <img 
              src={fullScreenPdf ? FullScreenOffIcon : FullScreenOnIcon} 
              alt="full screen icon" 
            />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.attachmentContainer}>
      <div 
        className={classes.pdfContainer}
        style={{ 
          height: fullScreenPdf ? "100vh" : 600,
          borderRadius: fullScreenPdf ? 0 : "10px 10px 0 0"
        }}
      >
        <div 
          className={classes.doc}
          style={{ margin: fullScreenPdf ? 0 : "10px 0" }}
        >
          <Document 
            file={url} 
            onLoadSuccess={onDocumentLoadSuccess} 
            onLoadError={console.error}
          >
            <Page 
              height={!fullScreenPdf ? 600 : undefined} 
              pageNumber={curPageNumber} 
            />
          </Document>
        </div>
      </div>
      {bottomToolbarContent}
      {floatingLeftIcon}
      {floatingRightIcon}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    attachmentContainer: {
      width: "100%",
      overflow: "hidden",
      position: "relative",
    },
    pdfContainer: {
      background: "#525659",
    },
    doc: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 10,
    },
    arrowIconLarge: {
      fontSize: 50,
      color: "#000",
      [theme.breakpoints.up("sm")]: {
        color: "#fff",
      }
    },
    rightArrowBtn: {
      position: "absolute",
      top: "40%",
      right: 0
    },
    leftArrowBtn: {
      position: "absolute",
      top: "40%",
      left: 0
    },
    toolbar: {
      background: "black",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "& > *": {
        marginRight: 15
      }
    },
    toolbarBottom: {
      background: "black",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      "& > *": {
        marginRight: 15
      }
    },
    arrowIcon: {
      color: "white",
    },
    nestedIcons: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      marginRight: 0,
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        height: "100%",
        borderRadius: 0,
        padding: 0,
      }
    },
    fullScreenBtn: {
      backgroundColor: "#2B2B2B",
    }
  })
);
