import React from "react";
import {
    Box,
    Grid,
    styled,
    Typography,
} from "@material-ui/core";

interface RewardsType {
    name: string;
    description: string;
    id: number;
    icon: {
        id: number;
        url: string;
    }
}

interface Props {
    rewardsList: RewardsType[];
    achievementsList: RewardsType[]
}

export default function ReleatedRewardsAndAchievements({
    rewardsList = [],
    achievementsList = []
}: Props) {
    return rewardsList.length > 0 || achievementsList.length > 0 ? (
        <RewardsAchievementsBox>
            <RewardsAchievementsTitle>
                This course is part of:
            </RewardsAchievementsTitle>
            <Grid container style={{ display: "flex", flexWrap: "wrap" }} spacing={2}>
                {rewardsList.length > 0 && (
                    <Grid item xs={12} md={6} lg={6} >
                        <Typography style={webStyle.rewardsTitle}>Rewards</Typography>
                        {rewardsList.map(reward => {
                            return (
                                <Box style={webStyle.rewardBox}>
                                    <RewardIconContainer style={{ height: '81px' }}>
                                        <img style={{ width: "44px", height: "44px" }} src={reward.icon.url} />
                                    </RewardIconContainer>
                                    <Typography style={webStyle.rewardName}>{reward.name}</Typography>
                                </Box>
                            )
                        })}
                    </Grid>
                )}
                {achievementsList.length > 0 && (
                    <Grid item xs={12} md={6} lg={6} >
                        <Typography style={webStyle.rewardsTitle}>Achievement</Typography>
                        {achievementsList.map(achievement => {
                            return (
                                <Box style={webStyle.rewardBox}>
                                    <RewardIconContainer style={{ height: '81px' }}>
                                        <img style={{ width: "44px", height: "44px" }} src={achievement.icon.url} />
                                    </RewardIconContainer>
                                    <Typography style={webStyle.rewardName}>{achievement.name}</Typography>
                                </Box>
                            )
                        })}
                    </Grid>
                )}
            </Grid>
        </RewardsAchievementsBox>
    ) : null;
}

const RewardsAchievementsBox = styled(Box)({
    backgroundColor: "white",
    padding: "24px",
    overflowY: "scroll",
    borderRadius: "16px",
    boxShadow: '1px 2px 10px 0px rgba(0, 0, 0, 0.07)',
})

const RewardsAchievementsTitle = styled(Typography)({
    fontSize: '22px',
    fontWeight: 'bolder',
    lineHeight: "33px",
    textAlign: 'left',
    marginBottom: '16px'
})

const RewardIconContainer = styled(Box)({
    width: '81px',
    heigth: '81px',
    boxShadow: '0px 11px 11px -6px #F42074A6',
    borderRadius: '12px',
    background: '#E70E6B',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
})

const webStyle = {
    rewardsTitle: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 600,
        marginBottom: '8px'
    },
    rewardBox: {
        height: '129px',
        padding: '24px',
        gap: '10px',
        borderRadius: '8px',
        border: '1px dashed #E5E2E1',
        background: 'rgb(253, 241, 241)',
        display: 'flex',
        alignItems: 'center',
    },
    rewardName: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 500,
    }
}