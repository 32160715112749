import React from "react";
import clsx from "clsx";
import {Button, Grid} from "@material-ui/core";

import { createStyles, makeStyles, Theme, } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";

interface ICartItems {
  cartItems: any;
  payableAmount: number;
  orderConfirmed: boolean;
  onRemove: (id: string) => void;
  checkoutProceed: ()=> Promise<void>;
  subscriptionCartItems:any;
}

export default function CartItems({
  cartItems,
  onRemove,
  payableAmount,
  orderConfirmed,
  checkoutProceed,
  subscriptionCartItems
}: ICartItems) {
  const getPeriod = (value:string) => {
    switch(value){
     case "monthly_subscription":
       return "Monthly"
     case "semi_anually":
       return "Semi anually"
     case "anually":
       return "Anually"
     default:
     return ""
    }
 }
  const classes = useStyles();
  let cartItemsContent;
  let getSubscriptionPeriod = "";
  if ((cartItems && cartItems?.length > 0) || (subscriptionCartItems && subscriptionCartItems.length > 0)) {
    const cartValue = subscriptionCartItems.length > 0 ? subscriptionCartItems : cartItems
   getSubscriptionPeriod = getPeriod(cartValue[0]?.attributes?.subscription_period)
    cartItemsContent = cartValue.map((data: any) => {
      const isCourse =
        data?.attributes?.purchasable_type === "BxBlockCoursecreation::Course"
          ? true
          : false;
      const thumbnail = isCourse
        ? data?.attributes?.course_detail?.thumbnail_image?.url
        : data?.attributes?.program_detail?.thumbnail_image?.url;
      const title = isCourse
        ? data?.attributes?.course_detail?.title
        : data?.attributes?.program_detail?.title;
      const author = isCourse
        ? data?.attributes?.course_detail?.created_by
        : data?.attributes?.program_detail?.created_by;
      const price = isCourse
        ? data?.attributes?.course_detail?.price
        : data?.attributes?.program_detail?.price;
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
          subscriptionPeriod={getSubscriptionPeriod}
          price={price}
          onRemoveClick={() => onRemove(data.id)}
        />
      );
    });
  } else {
    cartItemsContent = null;
  }

  if (payableAmount === 0) {
    return (
      <p className={clsx(classes.emphasisText, classes.emptyMsg)}>
        This cart is empty
      </p>
    )
  }

  return (
    <>
      <p className={clsx(classes.primaryText, classes.info)}>
      {subscriptionCartItems.length > 0  ? "Subscription" : "Order Summary"}
      </p>
      {cartItemsContent}
      <div className={classes.promotionContainer}>
        <Grid container className={classes.flexEnd}>
          <Grid item lg={6} md={6} sm={12}>
        <div className={classes.flexBetween}>
        <p className={clsx(classes.primaryText, classes.totalLabel)}
        style={{fontSize:subscriptionCartItems && subscriptionCartItems.length > 0?"20px":"24px"}}>{subscriptionCartItems && subscriptionCartItems.length > 0 ? "Total Subscription" : "Total"}</p>
        <p className={classes.emphasisText}>${payableAmount}{getSubscriptionPeriod && <span className={classes.emphasisTextPeriod}>{`/${getSubscriptionPeriod?.toLowerCase()}`}</span>}</p>
        </div>
      <div className={classes.flexEnd}>
        <Button className={classes.checkoutBtn} onClick={checkoutProceed}>Proceed to Checkout</Button>
      </div>
      </Grid>
      </Grid>
      </div>


    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      display: "flex",
      height: "35%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    flexEnd:{ 
      display:"flex",
      justifyContent:'flex-end',
      alignItems:"center"
    },
    flexBetween:{ 
      display:"flex",
      justifyContent:'space-between',
      alignItems:"center"
    },
    promotionContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection:"column",
      alignItems: "center",
      background:"#EEE8F2",
      maxHeight: "172px",
      width:"100%",
      padding: "20px 16px 20px 16px",
      gap: "40px",
      borderRadius:"12px"
    },
    primaryText: {
      color: "#1a1a1a",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    info: {
      marginLeft: "20px",
      fontSize:"24px",
      fontWeight:700,
      fontFamily:"poppins",
      color:'#484646'
    },
    totalLabel: {
      color:'#652786',
      fontSize:"24px",
      fontWeight:600
    },
    emphasisText: {
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "28px",
      textAlign: "right",
      color: "#652786",
    }, 
    emphasisTextPeriod: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px",
      textAlign: "right",
      color: "#652786",
    },
    emptyMsg: {
      textAlign: "left",
      marginLeft: 20,
    },
    checkoutBtn:{
      background: "linear-gradient(90deg, #6C328B, #F42074, #EF2B4B)",
      fontSize:"16px",
      fontWeight:600,
      fontFamily:"poppins", 
      maxWidth:"392px",
      color: "white", 
      border: "none", 
      padding: "10px 20px", 
      borderRadius: "88px", 
      textTransform:"none",
      width:"100%"
    }
  })
);