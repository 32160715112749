Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "servicespecificsettingsadmin2";
exports.labelBodyText = "servicespecificsettingsadmin2 Body";
exports.deleteApiMethod = "DELETE";
exports.putApiMethod = "PUT";

exports.btnExampleTitle = "CLICK ME";
exports.billingTitle = "Billing";
exports.addTierTitle = " Add Tier";
exports.setPriceTitle = " Set price";
exports.adminSide = "Admin Side";
exports.freePlan = "Free plan";
exports.flatFee = "Flat fee";
exports.payPerUser = "Pay per user";
exports.perActiveUser = "Per active user";
exports.tierTitle ="Tiered";
exports.paymentPeriod ="Payment period";
exports.billingSubtitle=" Configure how the combination of users and content assigned should be charged.";
exports.textCancel = "Cancel";
exports.listCourseDataEndPoint ='bx_block_account_groups/user_contents/library_courses?';
exports.listProgramDataEndPoint = 'bx_block_account_groups/user_contents/library_programs?';
exports.listContentRoleDataEndPoint="bx_block_account_groups/user_contents/library_content_roles?";
exports.userListEndPoint = "account_block/standard_users";
exports.branchListEndPoint = "account_block/standard_users/branch_list";
exports.createUserAssignContentEndPoint= 'bx_block_account_groups/user_assign_contents';
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";
exports.courseAndProgramAPIEndPoint = "/bx_block_catalogue/collection_lists";
exports.assignedCoursesLists="bx_block_account_groups/user_contents/course_contents?";
exports.assignedProgramsLists="bx_block_account_groups/user_contents/program_contents?";
exports.assignedContentRoleLists="/bx_block_account_groups/user_contents/content_roles_contents?";
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";
exports.catagoryListingApiEndPoint ="bx_block_categories/categories";
exports.postUserBranchEndPoint = "bx_block_account_groups/user_contents/create_user_assign_content";
exports.branchListEndPoint2 = "bx_block_organisationhierarchy/branch";
exports.teamListingApiEndPoint = "/account_block/standard_users/team_list";
exports.listCourseDataEndPoint ='bx_block_account_groups/user_contents/library_courses?';
exports.listProgramDataEndPoint = 'bx_block_account_groups/user_contents/library_programs?';
exports.createUserAssignContentEndPoint= 'bx_block_account_groups/user_assign_contents';
exports.createUserAssignEndPoint='bx_block_account_groups/user_assign_contents?'
exports.assignedCoursesLists="bx_block_account_groups/user_contents/course_contents?";
exports.assignedProgramsLists="bx_block_account_groups/user_contents/program_contents?";
exports.createUserEndPoint = "bx_block_organisationhierarchy/branch";
exports.downloadContractEndPoint =  "bx_block_documentstorage/contracts/download_file?id="
exports.addContractEndPoint =  "bx_block_documentstorage/contracts"
exports.deleteContractEndPoint =  "bx_block_documentstorage/contracts/"
exports.createBillingEndPoint = "bx_block_stripegatewayapifrontend/branch_billings";
exports.totalUserBranchEndPoint = "bx_block_organisationhierarchy/branch";
exports.makeInactiveBranchEndPoint = "bx_block_organisationhierarchy/branch";
// Customizable Area End