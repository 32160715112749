import React, { useEffect } from "react";
import { Container, Grid, Button, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";
import Loader from "../../../../components/src/Loader.web";
import { checkIcon } from "../assets";

interface IOrderSuccess {
  cartType: string;
  isLoading: boolean;
  orderItems: any;
  lastSubscriptionOrder: any;
  onGoToHomeClick: () => void;
  onGoToPersoalLibClick: () => void;
  onPayNextSubsClick: () => void;
  cartOrderDetails: () => Promise<void>;
}

export default function OrderSuccess({ 
  cartType,
  isLoading,
  orderItems, 
  onGoToHomeClick, 
  onGoToPersoalLibClick,
  lastSubscriptionOrder,
  onPayNextSubsClick,
  cartOrderDetails
}: IOrderSuccess) {
  const classes = useStyles();
  let orderItemsContent;
  if (orderItems && orderItems?.length > 0) {
    orderItemsContent = orderItems.map((data: any) => {
      const isCourse = data.attributes.item_details.type === "Program" ? false : true
      const thumbnail = data?.attributes?.item_details?.image_url?.url;
      const title = data?.attributes?.item_details?.title;
      const author = data?.attributes?.item_details?.created_by;
      const price = data?.attributes?.item_details?.price
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
          price={price}
          subscriptionPeriod={data?.attributes?.item_details?.subscription_period}
        />
      );
    });
  } else {
    orderItemsContent = null;
  }

  useEffect(()=>{
    cartOrderDetails()
  },[])

  return (
    <>
      <Loader loading={isLoading} />
      <Container maxWidth="sm" className={classes.mainContainer}>
        <Box className={classes.flexCenter}>
          <Box className={classes.centeredContainer}>
            <img 
              src={checkIcon} 
              alt="order success" 
              className={classes.img}
            />
          </Box>
          <Box style={{marginLeft:"22px"}} className={classes.centeredContainer}>
            <h1 className={classes.headingText}>Congratulations!</h1>
            <p className={classes.descriptionText}>
              Your order is successfully placed
            </p>
            <p className={classes.descriptionText}>
              Please go to the{" "}
              Personal Library to view
              your courses.
            </p>
          </Box>
        </Box>
        <p className={classes.secondaryText}>
          {cartType !== "subscription" ? "Order Summary" : "Content subscription"}
        </p>
        {orderItemsContent}
            <Grid container className={classes.btnContainer}>
              <Grid item xs={12}>
                <Button 
                  fullWidth 
                  className={classes.btn}
                  onClick={onGoToHomeClick}
                >
                  Go to Home Page
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  className={`${classes.btn} ${classes.containedBtn}`}
                  onClick={onGoToPersoalLibClick}
                >
                  Go to Personal Library
                </Button>
              </Grid>
            </Grid>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
      borderRadius: 16,
      marginTop: 50,
      maxWidth:"842px"
    },
    centeredContainer: {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    flexCenter:{
      display:"flex",
      alignItems:"center"
    },
    infoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headingText: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#1E1F20",
    },
    descriptionText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#1A1A1A",
    },
    img: {
      width: 140,
      height: 140,
    },
    secondaryText: {
      fontWeight: 800,
      fontSize: "24px",
      lineHeight: "24px",
      color: "#545650",
    },
    emphasis: {
      fontWeight: 700,
    },
    btnContainer: {
      justifyContent: "space-around",
      padding: "25px 0",
      alignItems:"center",
      flexDirection:"column"
    },
    btn: {
      width: "100%",
      height: "64px",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#6C328B",
      padding: "0 40px",
    },
    containedBtn: {
      borderRadius: "88px",
      background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
      maxWidth:"300px",
      height:"48px",
      color: "#fff",
      "&:hover": {
        background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
      },
    },
  })
);