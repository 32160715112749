import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  Button,
  Checkbox,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik} from "formik";
import * as yup from "yup";
import { LoginImage } from "./assets";
import AlertBlock from "../../alert/src/ActionAlert.web";
import IconEYE from "./components/IconEYE";
import "./EmailAccountLogin.css";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "end",
  color: theme.palette.text.secondary,
}));
// Customizable Area End

// Customizable Area Start
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
  // Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 handleSubmit(user: any) {
  localStorage.setItem("remember_me", (this.state.checkedRememberMe).toString())
  if(this.state.checkedRememberMe){
    localStorage.setItem( "email" , JSON.parse(user).email); localStorage.setItem("password", JSON.parse(user).password);

  } else{
    localStorage.setItem( "email" , ""); localStorage.setItem("password", "")
  }

  let payload = {
    data: {
      attributes: {
        email: JSON.parse(user).email,
        password: JSON.parse(user).password,
        remember_me: this.state.checkedRememberMe,
      },
    },
  };
  this.getLogin(payload);
}
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <>
      <AlertBlock
        data={{
          show: this.state.alert.isOpen,
          close: this.handleAlertClose,
          msg: this.state.alert.msg,
          type: this.state.alert.type,
          vertical: "top",
          horizontal: "right",
        }}
      />
      <Grid
        container
        style={{ height: "100vh", width: "100vw" }}
        className="login"
      >
        <Grid
          xs={"auto"}
          sm={4}
          md={4}
          lg={3}
          item 
          style={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={9}
          className="form-container"
          component={Paper}
          elevation={6}
          square
          style={{ overflowY: "scroll", height: "100vh" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid item  className="login-route">
              <Item
              >
               <Typography onClick={this.navigateToEmailAccountRegistration} className="text">
                    Don't have an account ? <span style={{ paddingLeft: '20px' }}>Sign up for free</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} className="login-route mt-10">
              <Formik
                initialValues={{
                  email: localStorage.getItem("email") || "",
                  showPassword: true,
                  password:  localStorage.getItem("password") || "",
                }}
                onSubmit={async (values) => {
                  this.handleSubmit(JSON.stringify(values));
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,"Enter valid email")
                    .required("Email is required"),
                  password: yup
                    .string()
                    .required("Password is required")

                })}
              >
                {(props) => {
                  const {
                    touched,
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  } = props;
                  return (
                    <form className="regform" onSubmit={handleSubmit}>
                      <Grid item xs={8} className="typo" lg={8}>
                        <Typography component="h6" variant="h5">
                          Login
                        </Typography>
                      </Grid>
                      <Grid className="form user-form" container spacing={5}>
                              <Grid item md={12} xs={12} sm={12}>
                                <InputLabel>Email</InputLabel>
                                <TextField
                                  id="email"
                                  onChange={handleChange}
                                  placeholder="Email address"
                                  className="email-input"
                                  value={values.email}
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                  name="email"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                />
                              </Grid>
                      </Grid>
                            <Grid container spacing={5} className="user-form">
                              <Grid item md={12} xs={12} sm={12}>
                                <InputLabel htmlFor="filled-adornment-password">
                                  Password
                                </InputLabel>
                                
                                <TextField
                                  id="filled-adornment-password"
                                  type={values.showPassword ? "password" : "text"}
                                  name="password"
                                  className="password-input"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Password"
                                  variant="outlined"
                                  value={values.password}
                                  error={
                                    touched.password && Boolean(errors.password)
                                  }
                                  helperText={touched.password && errors.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: <IconEYE setFieldValue={setFieldValue} values={values} />,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={5} style={{paddingBottom:"10px"}} className="user-form">
                              <Grid
                                item
                                md={12}
                                xs={12}
                          sm={12}
                          className="forgot-content"
                         
                        >
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                checked={this.state.checkedRememberMe}
                                onChange={(event) => {
                                  this.setState({ checkedRememberMe: event.target.checked });
                                }}
                                inputProps={{ 'aria-label': 'Remember me checkbox' }}
                              />
                              <Typography
                                className="forgot-text"
                              >
                                Remember Me
                              </Typography>
                            </Box>

                            <Typography className="forgot-text" onClick={this.navigateToForgotPassword}>Forgot password ?</Typography>

                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5} className="user-form">
                        <Grid item md={12} xs={12} sm={12}>
                          <Button disabled={!(values.email && values.password)}
                           className={values.email && values.password ? "enable" : "disable"} type="submit" fullWidth>
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
       // Customizable Area End
    );
  }
}
