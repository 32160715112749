import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";
import { getCurrentPathName } from "../../../components/src/Utilities";
export const configJSON = require("./config");
import * as H from "history";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { IconType } from "../../imagemanagement2/src/CommonSelectIconFromLibraryController.web";
const navigation = require("react-navigation");

interface ImageThumbnail {
    url: string;
    id: number;
}

interface LibraryCourseAttributes {
    id: string;
    content: {
        id: string;
        title: string;
        status: string;
        thumbnail_image: ImageThumbnail;
    }
    content_type: string;
    content_id: string;
}

interface LibraryItem {
    id: string; type: string; attributes: LibraryCourseAttributes
}

interface MessageError {
    message: string;
}

interface ErrorResponseIcon {
    icon: string;
}

interface ResponseError {
    errors: MessageError[];
}

interface OwnerAccount {
    id: string;
    type: string;
    attributes: {
        email: string;
        full_name: string;
        avatar: string | null;
        first_name: string;
        last_name: string;
        profession: string;
        is_admin: boolean;
    };
}

export interface OwnerData {
    data: Array<OwnerAccount>
}

export interface AssigneItem {
    type: string;
    checked?: boolean
    id: string;
    attributes: {
        name: string;
        id: number;
    };
}

export interface AssigneeData {
    data: Array<AssigneItem>
}

interface Reward {
    id: string;
    type: string;
    attributes: {
        name: string;
        status: string;
        complete_content: boolean;
        "is_num_of_days_login": boolean;
        "num_of_days_login": string;
        is_number_of_courses_completed: boolean;
        number_of_courses_completed: string;
        is_number_of_lessons_completed: boolean;
        number_of_lessons_completed: string;
        is_num_of_days_raw_login: boolean;
        num_of_days_raw_login: string;
        due_date: string;
        icon: {
            id: number;
            url: string;
        },
        created_by: string;
        branch: string;
        description: string;
        courses:LibraryItem[];
        programs: LibraryItem[];
    }
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: typeof navigation;
    navigation: typeof navigation;
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    rewardStatus: string;
    dueDate: Date | null;
    daysLoggedInToggle: boolean;
    lessonsCompletedToggle: boolean;
    checkvalue: boolean;
    statusActive: boolean;
    coursesCompletedToggle: boolean;
    user: string;
    icon: File | null;
    rewardName: string;
    ownerText: string;
    personName: string;
    completeContentToggle: boolean;
    contentToggle: boolean;
    names: AssigneeData;
    loggedInRowToggle: boolean;
    daysLoggedIn: number;
    lessonsCompleted: number;
    coursesCompleted: number;
    loggedInRow: number;
    anchorEl: boolean;
    ownerNameList: OwnerData;
    error: string;
    lableText: string;
    placeholderSearchText: string;
    achievementError: boolean;
    courseIdData: Array<string>;
    programIdData: Array<string>;
    person: AssigneItem;
    selectedUser: AssigneItem | null;
    isCreate: boolean;
    dataForContent: LibraryItem[];
    showChooseIconPrompt: boolean;
    ownerError: boolean;
    assignesError: boolean;
    rewardOwnerId: string;
    dueDateError: boolean;
    showIconListingDialogue: boolean;
    iconPreview: IconType;
    searchTerm: string;
    rewardError: boolean;
    rewardField: string;
    helperText: string;
    editRewardId:string;
    editRewardName:string;
    showLeaveModal:boolean;
    openEditAlert:boolean;
    idsToRemove: string[];
    dataTobeAdded: LibraryItem[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateRewardController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getRewardOwnerListCallId: string = "";
    getAssignesListCallId: string = "";
    postCreateRewardApiCallId: string = "";
    rewardDetailsApiCallId: string = "";
    iconLibraryListApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            programIdData: [],
            isCreate: false,
            dataForContent: [],
            showChooseIconPrompt: false,
            user: "",
            icon: null,
            rewardName: "",
            personName: "",
            names: {
                data: []
            },
            rewardStatus: "Not Active",
            contentToggle: false,
            courseIdData: [],
            showIconListingDialogue: false,
            lessonsCompleted: 0,
            coursesCompleted: 0,
            loggedInRow: 0,
            ownerNameList: {
                data: []
            },
            ownerText: "",
            error: "error msg",
            lableText: "Lable Text",
            placeholderSearchText: "",
            anchorEl: false,
            checkvalue: false,
            statusActive: true,
            achievementError: false,
            ownerError: false,
            assignesError: false,
            dueDate: null as Date | null,
            completeContentToggle: false,
            daysLoggedInToggle: false,
            lessonsCompletedToggle: false,
            person: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    name: ""
                }
            },
            coursesCompletedToggle: false,
            loggedInRowToggle: false,
            daysLoggedIn: 0,
            rewardOwnerId: "",
            dueDateError: false,
            iconPreview: {} as IconType,
            searchTerm: "",
            selectedUser: null,
            rewardError: false,
            rewardField: "",
            helperText: "",
            editRewardId:"",
            editRewardName:"",
            showLeaveModal:false,
            openEditAlert:false,
            idsToRemove: [],
            dataTobeAdded: [],
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getRewardOwnerList();
        this.getAssigneListApiCall();
        this.getIconFromAddIconPageAndSetFormValues()
        const ownerExpertId = localStorage.getItem("expert_id")
        this.setState({ rewardOwnerId: `${ownerExpertId}` });
        
        const id = this.props.navigation.getParam("rewardId");
        if (id) {
            this.setState({ editRewardId: id });
            this.rewardDetailsApiCallId = await this.rewardApiCallId({
                endPoint: `${configJSON.rewardsAPiEndPoint}/${id}`,
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
            });

            this.iconLibraryListApiCallId = await this.rewardApiCallId({
                endPoint: configJSON.iconListEndPoint,
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
            });
            const propsData = localStorage.getItem('assignedData');
            if(propsData){
                const info = JSON.parse(propsData);
                this.setState({
                    idsToRemove: (info.idsToRemove ?? []),
                    dataTobeAdded: (info.dataToAdd ?? []),
                });
                this.handleNavigationPropsData(info.propsData, info.assignedData);
                localStorage.removeItem('assignedData');
            }
        }
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson) {
                if (responseJson.data) {
                    this.apiSuccessFunctions(apiRequestCallId, responseJson);
                }
                if (responseJson.errors) {
                    this.apiFailureFunctions(apiRequestCallId, responseJson);
                }
            }
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let rewardData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            if (rewardData) {
                this.handleNavigationPropsData(rewardData.propsData, rewardData.assignedData);
            }
        }
        const foundObject = this.state.names.data.find((obje) => obje.id === this.state.searchTerm);
        if (foundObject) {
            this.setState({ selectedUser: foundObject });
        }
    };

    handleNavigationPropsData = (propsData: any, assignedData: any) => {
        if (propsData) {
            this.setState({
                daysLoggedIn: propsData.createData.daysLoggedIn,
                coursesCompletedToggle: propsData.createData.coursesCompletedToggle,
                coursesCompleted: propsData.createData.coursesCompleted,
                loggedInRow: propsData.createData.loggedInRow,
                searchTerm: propsData.createData.personName,
                iconPreview: propsData.createData.iconPreview,
                lessonsCompletedToggle: propsData.createData.lessonsCompletedToggle,
                lessonsCompleted: propsData.createData.lessonsCompleted,
                rewardOwnerId: propsData.createData.rewardOwnerId,
                rewardName: propsData.createData.rewardName,
                rewardStatus: propsData.createData.status,
                dueDate: new Date(propsData.createData.dueDate),
                contentToggle: propsData.createData.contentToggle,
                daysLoggedInToggle: propsData.createData.daysLoggedInToggle,
                loggedInRowToggle: propsData.createData.loggedInRowToggle,
                rewardField: propsData.createData.rewardFieldvalue
            });
        }
        if (assignedData) {
            const newAssignedData = typeof assignedData === 'string' ? JSON.parse(assignedData) : assignedData;
            this.setState({ dataForContent: newAssignedData });
            const courses = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Course");
            const programs = newAssignedData.filter((item: { attributes: { content_type: string; }; }) => item.attributes.content_type === "BxBlockCoursecreation::Program");

            const assignedIdsForCourse = courses.map((item: { id: string; attributes: {content_id:number;} }) => item.attributes.content_id);
            const assignedIdsForProgram = programs.map((item: { id: string; attributes: {content_id:number;} }) => item.attributes.content_id);
            {
                newAssignedData.length > 0 && toast.success("Content for has been selected successfully", { icon: false, closeButton: false })
            }
            this.setState({ courseIdData: assignedIdsForCourse, programIdData: assignedIdsForProgram });
        }
    }

    apiSuccessFunctions = async (apiCallId: string, response: OwnerData & AssigneeData & object & {data:Reward} & {data:IconType[]}) => {
        if (apiCallId === this.getAssignesListCallId) {
            this.getAssignessListSuccess(response)
        }
        if (apiCallId === this.getRewardOwnerListCallId) {
            this.getRewardOwnerListSuccess(response)
        }
        if (apiCallId === this.postCreateRewardApiCallId) {
            this.postRewardSuccessFunction(response)
        }
        if(apiCallId === this.rewardDetailsApiCallId){
            this.rewardDetailsHandler(response.data)
        }
        if(apiCallId === this.iconLibraryListApiCallId){
            this.iconLibrarySuccessResponseHandler(response.data)
        }
    };

    apiFailureFunctions = async (apiCallId: string, response: ResponseError & ErrorResponseIcon) => {
        if (apiCallId === this.postCreateRewardApiCallId) {
            this.postAchievementFailureCallBack(response)
        }
    };

    rewardApiCallId = async (valueData: {
        body?: {};
        type?: string;
        contentType?: string;
        method?: string;
        endPoint?: string;
    }) => {
        let token = localStorage.getItem("token");
        let { contentType, method, endPoint, body, type } = valueData;
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        let header = {
            "Content-Type": contentType,
            token,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getRewardOwnerList = async () => {
        this.getRewardOwnerListCallId = await this.rewardApiCallId({
            endPoint: configJSON.getOwnerListEndpoint,
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
        });
    };

    getRewardOwnerListSuccess = (response: OwnerData) => {
        this.setState({ ownerNameList: response });
    };

    getAssigneListApiCall = async () => {
        this.getAssignesListCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.getBranchNameEndpoint
        });
    };

    getAssignessListSuccess = (response: AssigneeData) => {
        response.data.forEach(item => {
            item.checked = false;
        });
        this.setState({ names: response });
    };

    handleContent = () => {
        if (this.state.contentToggle) {
            this.setState({ contentToggle: !this.state.contentToggle });
        } else {
            this.setState({
                contentToggle: !this.state.contentToggle,
                daysLoggedInToggle: false,
                lessonsCompletedToggle: false,
                coursesCompletedToggle: false,
                loggedInRowToggle: false,
            });
        }
    };

    handleNavigateContent = () => {
        if (this.state.rewardName && this.state.iconPreview?.id) {
            const createData = {
                rewardOwnerId: this.state.rewardOwnerId,
                rewardName: this.state.rewardName,
                status: this.state.rewardStatus,
                dueDate: this.state.dueDate,
                contentToggle: this.state.contentToggle,
                daysLoggedInToggle: this.state.daysLoggedInToggle,
                daysLoggedIn: this.state.daysLoggedIn,
                coursesCompletedToggle: this.state.coursesCompletedToggle,
                coursesCompleted: this.state.coursesCompleted,
                lessonsCompletedToggle: this.state.lessonsCompletedToggle,
                lessonsCompleted: this.state.lessonsCompleted,
                loggedInRowToggle: this.state.loggedInRowToggle,
                loggedInRow: this.state.loggedInRow,
                personName: this.state.searchTerm,
                iconPreview: this.state.iconPreview,
                rewardFieldvalue: this.state.rewardField,
                rewardId:'',
            };

            if(this.state.editRewardId){
                createData.rewardId = this.state.editRewardId;
            }

            localStorage.setItem("rewardName", this.state.rewardName);
            localStorage.setItem("isReward", "true");

            const manageAccountNavigationId: Message = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            const raiseMessage = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            if (this.state.courseIdData.length > 0 || this.state.programIdData.length > 0) {
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData, assigned: this.state.dataForContent });
            } else {
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { createData: createData });
                raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage1), { createData: createData });
            }
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "SelectContent");
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(manageAccountNavigationId);
        }
        else {
            toast.error("Please add icon and reward name")
        }
    };

    handleStatusChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        this.setState({ rewardStatus: event.target.value as string });
    };

    handleDueDate = (date: Date) => {
        this.setState({ dueDate: date });
    };

    handleAchievementName = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        const regex = /^[a-zA-Z0-9 _-]{0,30}$/;
        if (regex.test(newValue)) {
            this.setState({
                rewardName: newValue,
                achievementError: false
            });
        }
        else {
            this.setState({ achievementError: true });
        }
    };

    handleOwnerChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        const value = event.target.value as string;
        this.setState({
            rewardOwnerId: value
        });
    };

    handleDaysLoggedInToggle = () => {
        if (this.state.daysLoggedInToggle) {
            this.setState({ daysLoggedInToggle: !this.state.daysLoggedInToggle });
        } else {
            this.setState({
                daysLoggedInToggle: !this.state.daysLoggedInToggle,
                lessonsCompletedToggle: false,
                coursesCompletedToggle: false,
                loggedInRowToggle: false,
                contentToggle: false,
            });
        }
    };

    handleLessonsCompletedToggle = () => {
        if (this.state.lessonsCompletedToggle) {
            this.setState({ lessonsCompletedToggle: !this.state.lessonsCompletedToggle });
        } else {
            this.setState({
                lessonsCompletedToggle: !this.state.lessonsCompletedToggle,
                coursesCompletedToggle: false,
                loggedInRowToggle: false,
                daysLoggedInToggle: false,
                contentToggle: false,
            });
        }
    };

    handleCoursesCompletedToggle = () => {
        if (this.state.coursesCompletedToggle) {
            this.setState({ coursesCompletedToggle: !this.state.coursesCompletedToggle })
        } else {
            this.setState({
                coursesCompletedToggle: !this.state.coursesCompletedToggle,
                loggedInRowToggle: false,
                daysLoggedInToggle: false,
                lessonsCompletedToggle: false,
                contentToggle: false,
            });
        }
    };

    handleLoggedInRowToggle = () => {
        if (this.state.loggedInRowToggle) {
            this.setState({ loggedInRowToggle: !this.state.loggedInRowToggle });
        } else {
            this.setState({
                loggedInRowToggle: !this.state.loggedInRowToggle,
                coursesCompletedToggle: false,
                daysLoggedInToggle: false,
                lessonsCompletedToggle: false,
                contentToggle: false,
            });
        }
    };

    handleDaysLoggedin = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ daysLoggedIn: Number(newValue) });
        }
    };

    handleLessonsCompleted = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ lessonsCompleted: Number(newValue) });
        }
    };

    handleCoursesCompleted = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ coursesCompleted: Number(newValue) })
        }
    };

    handleLoggedInRow = (event: { target: { value: string } }) => {
        const newValue = event.target.value;
        if (/^[0-9]*$/.test(newValue)) {
            this.setState({ loggedInRow: Number(newValue) })
        }
    };

    handleIncrement = (inputName: string) => {
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true) {
            const newvalue = Number(this.state.loggedInRow) + 1;
            this.setState({ loggedInRow: newvalue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true) {
            const newValue = Number(this.state.lessonsCompleted) + 1;
            this.setState({ lessonsCompleted: newValue });
        }
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true) {
            const newValue = Number(this.state.coursesCompleted) + 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true) {
            const newValue = Number(this.state.daysLoggedIn) + 1;
            this.setState({ daysLoggedIn: newValue });
        } 
    };

    handleDecrement = (inputName: string) => {
        if (inputName === "coursesCompletedInput" && this.state.coursesCompletedToggle === true && Number(this.state.coursesCompleted) > 0) {
            const newValue = Number(this.state.coursesCompleted) - 1;
            this.setState({ coursesCompleted: newValue });
        }
        if (inputName === "daysLoggedInInput" && this.state.daysLoggedInToggle === true && Number(this.state.daysLoggedIn) > 0) {
            const newValue = Number(this.state.daysLoggedIn) - 1;
            this.setState({ daysLoggedIn: newValue });
        }
        if (inputName === "loggedInRowInput" && this.state.loggedInRowToggle === true && Number(this.state.loggedInRow) > 0) {
            const newvalue = Number(this.state.loggedInRow) - 1;
            this.setState({ loggedInRow: newvalue });
        }
        if (inputName === "lessonsCompletedInput" && this.state.lessonsCompletedToggle === true && Number(this.state.lessonsCompleted) > 0) {
            const newValue = Number(this.state.lessonsCompleted) - 1;
            this.setState({ lessonsCompleted: newValue });
        }
    };

    postCraeteReward = async () => {
        let formdata = new FormData();
        let courseableIds = [...this.state.courseIdData];
        let programIds = [...this.state.programIdData];

        if(this.state.rewardField.length < 5) { 
            return  toast.error("*Minimum 5 characters required.", { autoClose: 10000 }) 
        }

        if(this.state.rewardField.length >150) {
           return toast.error("*Maximum 150 characters required.", { autoClose: 10000 })    
        }

        let status = this.state.rewardStatus === "Not Active" ? "inactive" : "active";

        if (this.state.iconPreview?.id) {
            formdata.append("icon_id", `${this.state.iconPreview?.id}`)
        }

        formdata.append("reward[description]", this.state.rewardField)
        formdata.append("reward[name]", this.state.rewardName)
        formdata.append("reward[status]", status)
        formdata.append("admin_user_id", this.state.rewardOwnerId)
        formdata.append("reward[branch_id]", this.state.searchTerm)

        formdata.append("reward[due_date]", `${this.state.dueDate}`)
        formdata.append("reward[complete_content]", `${this.state.contentToggle}`)
        
        formdata.append("reward[is_number_of_courses_completed]", `${this.state.coursesCompletedToggle}`)
        if (this.state.coursesCompletedToggle) {
            formdata.append("reward[number_of_courses_completed]", `${this.state.coursesCompleted}`)
        }
        formdata.append("reward[is_num_of_days_login]", `${this.state.daysLoggedInToggle}`)
        if (this.state.daysLoggedInToggle) {
            formdata.append("reward[num_of_days_login]", `${this.state.daysLoggedIn}`)
        }
        formdata.append("reward[is_num_of_days_raw_login]", `${this.state.loggedInRowToggle}`)
        if (this.state.loggedInRowToggle) {
            formdata.append("reward[num_of_days_raw_login]", `${this.state.loggedInRow}`)
        }
        formdata.append("reward[is_number_of_lessons_completed]", `${this.state.lessonsCompletedToggle}`)
        if (this.state.lessonsCompletedToggle) {
            formdata.append("reward[number_of_lessons_completed]", `${this.state.lessonsCompleted}`)
        }

        if (this.state.editRewardId) {
            const dataToUpdate: { id: string; isRemove: boolean; type: string; }[] = [];

            if (this.state.idsToRemove.length > 0) {
                this.state.idsToRemove.map((courseableId, index) => {

                    dataToUpdate.push({ id: courseableId, isRemove: true, type: '' });
                })
            }

            if (this.state.dataTobeAdded.length > 0) {
                this.state.dataTobeAdded.map((courseable, index) => {

                    const courseableType = courseable.attributes.content_type === "BxBlockCoursecreation::Course" ? "BxBlockCoursecreation::Course" : "BxBlockCoursecreation::Program";
                    dataToUpdate.push({ id: courseable.attributes.content_id, isRemove: false, type: courseableType });
                })
            }

            dataToUpdate.map((item, index) => {
                if (item.isRemove) {
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][id]`, item.id);
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][_destroy]`, 'true');
                } else {
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_id]`, item.id);
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_type]`, item.type);
                }
            })

            this.postCreateRewardApiCallId = await this.rewardApiCallId({
                endPoint: `${configJSON.rewardAPiEndPoint}/${this.state.editRewardId}`,
                method: configJSON.examplePutMethod,
                body: formdata,
                type: 'formData'
            });
        } else {

            if (programIds.length > 0 && this.state.contentToggle) {
                programIds.forEach((programId, index) => {
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_id]`, programId);
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Program");
                });
            }
            if (courseableIds.length > 0 && this.state.contentToggle) {
                courseableIds.forEach((courseId, index) => {
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_id]`, courseId);
                    formdata.append(`reward[content_reward_achievements_attributes][${index}][courseable_type]`, "BxBlockCoursecreation::Course");
                });
            }

            this.postCreateRewardApiCallId = await this.rewardApiCallId({
                endPoint: configJSON.rewardAPiEndPoint,
                method: configJSON.exampleAPiMethod,
                body: formdata,
                type: 'formData'
            })
        }
    };

    postRewardSuccessFunction = (response: {data:Reward}) => {
        if (response) {
            this.setState({
                rewardOwnerId: "",
                rewardName: "",
                rewardStatus: "Active",
                dueDate: null,
                contentToggle: false,
                daysLoggedInToggle: false,
                daysLoggedIn: 0,
                coursesCompletedToggle: false,
                coursesCompleted: 0,
                lessonsCompletedToggle: false,
                lessonsCompleted: 0,
                loggedInRowToggle: false,
                loggedInRow: 0,
                personName: "",
                searchTerm: "",
                rewardField: "",
                idsToRemove:[],
                dataTobeAdded: [],
            }, () => {
                if (this.state.editRewardId) {
                    this.setState({editRewardName:response.data.attributes.name, editRewardId:response.data.id},()=>{
                        this.backNavigationHandler();
                        toast.success("Changes saved succesfully");
                    })
                } else {
                    this.props.history.push("/RewardsAndAchievement")
                    toast.success("Reward created successfully")
                }
                localStorage.removeItem("rewardName");
            });
        }
    };

    postAchievementFailureCallBack = (errorResponse: ResponseError & ErrorResponseIcon) => {
        if (errorResponse.errors.length > 0) {
            errorResponse.errors.forEach((error) => {
                toast.error(error.message, { autoClose: 10000 })
            });
        } else if (errorResponse.icon) {
            toast.error(errorResponse.icon, { autoClose: 10000 })
        }
    };

    updateStateWithConditions = () => {
        const {
            rewardOwnerId,
            rewardName, 
            dueDate,
            searchTerm,
            iconPreview,
            daysLoggedInToggle, 
            coursesCompletedToggle, 
            lessonsCompletedToggle, 
            loggedInRowToggle, 
            daysLoggedIn, 
            coursesCompleted, 
            lessonsCompleted, 
            loggedInRow, 
            contentToggle,
            programIdData,
            courseIdData
        } = this.state;

        const mandatoryStatesValid = rewardOwnerId.length > 0 && rewardName && dueDate && searchTerm !== "" && iconPreview?.id;

        const optionalStatesValid = (daysLoggedInToggle && daysLoggedIn >= 1) ||
            (coursesCompletedToggle && coursesCompleted >= 1) ||
            (lessonsCompletedToggle && lessonsCompleted >= 1) ||
            (loggedInRowToggle && loggedInRow >= 1) ||
            (contentToggle && (programIdData.length > 0 || courseIdData.length > 0));

        return mandatoryStatesValid && optionalStatesValid;
    };

    openAddIconDialogue = () => {
        this.setState({ showChooseIconPrompt: true, showIconListingDialogue: false })
    };

    closeAddIconDialogue = () => {
        this.setState({ showChooseIconPrompt: false })
    };

    openIconLibrary = () => {
        this.setState({ showIconListingDialogue: true })
    };

    closeIconLibrary = () => {
        this.setState({ showIconListingDialogue: false })
    };

    handleAddIconFromLocal = () => {
        setStorageData("originScreen", getCurrentPathName());
        const filledFormdata = {
            rewardOwnerId: this.state.rewardOwnerId,
            rewardName: this.state.rewardName,
            status: this.state.rewardStatus,
            dueDate: this.state.dueDate,
            contentToggle: this.state.contentToggle,
            daysLoggedInToggle: this.state.daysLoggedInToggle,
            daysLoggedIn: this.state.daysLoggedIn,
            coursesCompletedToggle: this.state.coursesCompletedToggle,
            coursesCompleted: this.state.coursesCompleted,
            lessonsCompletedToggle: this.state.lessonsCompletedToggle,
            lessonsCompleted: this.state.lessonsCompleted,
            loggedInRowToggle: this.state.loggedInRowToggle,
            loggedInRow: this.state.loggedInRow,
            personName: this.state.searchTerm,
            courseIdData: this.state.courseIdData,
            programIdData: this.state.programIdData,
            dataForContent: this.state.dataForContent
        }
        this.props.history.push({
            pathname: "/AddIcon",
            state: {
                formData: {
                    ...filledFormdata
                }
            }
        });
    };

    handleAddIconFromIconListDialogue = (selectIconResponse: IconType) => {
        this.setState({ iconPreview: selectIconResponse });
        this.closeAddIconDialogue();
        this.closeIconLibrary()
    };

    getIconFromAddIconPageAndSetFormValues = () => {
        if (this.props.location?.state) {
            this.handleAddIconFromIconListDialogue(this.props.location.state?.icon)
            const preFillFormdata: State = this.props.location?.state?.formData;
            this.setState({ ...preFillFormdata });
        }
    };

    handleTagUsers = (value: AssigneItem | null) => {
        this.setState({ searchTerm: `${value?.attributes.id}`, selectedUser: value })
    };

    handleReward = (event: {target: {value: string}}) => {
        const value = event.target.value;
        let error = false;
        let helperText = "";
        if (value.length < 5) {
            error = true;
            helperText = "*Minimum 5 characters required.";
        } else if (value.length > 150) {
            error = true;
            helperText = "*Maximum 150 characters allowed.";
        }
        this.setState({ rewardField: value, rewardError: this.state.rewardError, helperText });
    };

    rewardDetailsHandler = (reward: Reward) => {
        this.setState({
            rewardName: reward.attributes.name,
            editRewardName: reward.attributes.name,
            rewardStatus: reward.attributes.status === 'active' ? 'Active' : 'Not Active',
            dueDate: reward.attributes.due_date ? new Date(reward.attributes.due_date) : new Date(),
            rewardField: reward.attributes.description,
            iconPreview: {
                id: reward.attributes.icon.id.toString(),
                type: "",
                attributes: {
                    image: {
                        url: reward.attributes.icon.url,
                        id: reward.attributes.icon.id.toString(),
                        filename: ""
                    },
                    title: "",
                    created_at: "",
                    created_by: ""
                },
            },
            contentToggle: reward.attributes.complete_content,
            daysLoggedInToggle: reward.attributes.is_num_of_days_login,
            lessonsCompletedToggle: reward.attributes.is_number_of_lessons_completed,
            coursesCompletedToggle: reward.attributes.is_number_of_courses_completed,
            loggedInRowToggle: reward.attributes.is_num_of_days_raw_login,
            daysLoggedIn: reward.attributes.num_of_days_login ? Number(reward.attributes.num_of_days_login) : 0,
            lessonsCompleted: reward.attributes.number_of_lessons_completed ? Number(reward.attributes.number_of_lessons_completed) : 0,
            coursesCompleted: reward.attributes.number_of_courses_completed ? Number(reward.attributes.number_of_courses_completed) : 0,
            loggedInRow: reward.attributes.num_of_days_raw_login ? Number(reward.attributes.num_of_days_raw_login) : 0,
            dataForContent:[...reward.attributes.courses, ...reward.attributes.programs],
            courseIdData: reward.attributes.courses.length > 0 ? reward.attributes.courses.map(item => item.attributes.content_id) : [],
            programIdData: reward.attributes.programs.length > 0 ? reward.attributes.programs.map(item => item.attributes.content_id) : [],
        });

        const findBranch = this.state.names.data.find(item => item.attributes.name === reward.attributes.branch);
        if (findBranch) {
            this.setState({ selectedUser: findBranch, searchTerm: `${findBranch.attributes.id}` });
        }
    }

    backNavigationHandler = () => {
        const { editRewardName, editRewardId } = this.state;
        
        this.props.history.push({
            pathname: "/ManageReward",
            state: {
                rewardId: editRewardId,
                rewardName: editRewardName,
                isRewardsActive: "reward"
            }
        });
    }

    iconLibrarySuccessResponseHandler = (iconList: IconType[]) => {
        if (iconList?.length > 0) {
            const iconData = iconList.find(item => item.attributes.image?.id && item.attributes.image?.id?.toString() === this.state.iconPreview.id);
            if (iconData) {
                this.setState({ iconPreview: { ...iconData } });
            }
        }
    }

    // Customizable Area End
}