import {
  Box,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import React from "react";

interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  handleClickBack: () => void;
}

export default function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, handleClickBack, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children === "Settings" ? (
        <Typography variant="h6" className={classes.title}>
          {children}
        </Typography>
      ) : (
        <Box className={classes.titleContainer}>
          <IconButton onClick={handleClickBack} className={classes.backButton}>
            <KeyboardArrowLeftIcon fontSize="large" />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {children}
          </Typography>
        </Box>
      )}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "35px 0 10px 10px",
      [theme.breakpoints.up("sm")]: {
        padding: "35px 0 10px 45px",
      },
    },
    closeButton: {
      position: "absolute",
      right: "27px",
      top: "28px",
      backgroundColor: "#fff",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      color: "#11142D",
      [theme.breakpoints.up("sm")]: {
        top: "30px",
        right: "60px",
      },
    },
    backButton: {
      padding: 0,
    },
    title: {
      color: "#1D1F4F",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "39px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "28px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "32px",
      },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  })
);
