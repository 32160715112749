import React from 'react'
// Customizable Area Start
import {
  Grid,
  Button,
  IconButton,
  Avatar,
  Card,
  Box
} from "@material-ui/core";
import { CheckCircleOutline, Close } from "@material-ui/icons";
import { Link, withRouter } from 'react-router-dom';
import VideosController from "./VideosController.web";
import { uploadIcon } from "./assets"
import './video.css';
export  class VideoWeb extends VideosController {
  constructor(props: any) {
    super(props)
    this.selectThumbnail = this.selectThumbnail.bind(this);
  }
  selectThumbnail = (index: any) => {
    if (this.state.data.some((item: any) => item.selected === true)) {
      let findIndex = this.state.data.findIndex((item: any) => item.selected === true)
      if (index === findIndex) {
        this.state.data[index].selected = !this.state.data[index].selected
        this.setState({ data: this.state.data, imgUrl: "", file: "", id: this.state.data[index].id })
      }
      else {
        this.state.data[index].selected = !this.state.data[index].selected
        this.state.data[findIndex].selected = !this.state.data[findIndex].selected
        this.setState({ data: this.state.data, imgUrl: this.state.data[index].url, image: "", file: "", id: this.state.data[index].id })
      }
    }
    else {
      this.state.data[index].selected = !this.state.data[index].selected
      this.setState({ data: this.state.data, imgUrl: this.state.data[index].url, image: "", file: "", id: this.state.data[index].id })
    }

  }

  render() {

    return (
      <Grid container
        className='video'
      >
        <Grid container className='file-container' md={12}>
          {this.state.imgUrl === "" && this.state.image === "" ? <>
            <Grid className="file-content" item md={1}>
              <Button variant="contained" className="upload-btn" component="label">
                <input hidden data-test-id = "ImageChange1" accept="image/*" type="file" onChange={(e) => this.handleImageChange1(e)} />
                <Avatar src={uploadIcon} />
              </Button>

            </Grid>
            <Grid item md={12}>
              <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>
            </Grid>
            <Grid item md={12}>
              <p className="drag-subtext">Upload upto 15 MB and file dimension should be 768*1024</p>
            </Grid>
          </> : <>
            <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
              <Box style={{ minWidth: "200px", maxWidth: "200px", margin: "1px", textAlign: "center" }} >
                <Card >
                  <Box
                    className='card-header'


                  >
                    <IconButton data-test-id = "IconButton" aria-label="close" onClick={() => this.setState({ imgUrl: "", image: "" })}>
                      <Close />
                    </IconButton>
                  </Box>
                  <img className="select-img-preview" src={this.state.image === "" ? this.state.imgUrl : this.state.image} />
                </Card>
                <p className='file-name'>{this.state.filename}</p>
              </Box>
            </Box>
          </>}


        </Grid>
        <Grid container className="or-container">
          <Grid item md={12}>
            <p className="or">Or</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <p className="select-image">Select image</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card" >
              {this.state.data.map((item: any, index: any) => {
                return <>
                  <Box style={{ minWidth: "200px", maxWidth: "200px", margin: "20px" }} >
                    <Card
                      // className="custom-card"
                      data-test-id = "selectThumbnail"
                      onClick={() => this.selectThumbnail(index)}
                      style={{ position: "relative" }}
                    >
                      <img className="select-img" src={item.url} />
                      <Box className={item.selected ? "checked-content" : ""}>
                        {item.selected ? <CheckCircleOutline className="check-circle-icon" style={{ fontSize: "40px", color: "#fff" }} /> : ""}
                      </Box>
                    </Card>
                  </Box>
                </>
              })}
            </Box>

          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item md={2}>
            {(this.state.image !== "" || this.state.imgUrl !== "") && <Link to={{
              pathname: `${this.props?.location?.state?.redirectPath}`,
              state: { image: this.state.image, imgUrl: this.state.imgUrl, file: this.state.file, id: this.state.id, formObject: this.props?.location?.state.values, categories: this.props?.location?.state.categories, phase: this.props?.location?.state?.phase }
            }} style={{ textDecoration: "none !important" }}><Button className="btn-thumbnail">
                Add Thumbnail
              </Button>
            </Link>
            }
            {this.state.image === "" && this.state.imgUrl === "" && <Button className="btn-thumbnail" disabled={true}>
              Add Thumbnail
            </Button>}
          </Grid>
        </Grid>
      </Grid>

    )
  }
}
//@ts-ignore
export default withRouter(VideoWeb);
// Customizable Area End