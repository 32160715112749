// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleClose?: any;
  history?: any;
  location?: any;
  handleBackHome: any;
  handleBackPersonal: any;
}



interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  //isModalOpen:boolean;
  orderConfirmationOpen: any;
  recommendedCourseData: any;
  recommendedLoader: any;
  proceedModal: any;
  cartData: any;
  cartDataLoader: any;
  selectedOrder: any;
  saveOrder: any;
  totalAmount: any;
  paymentMethod: any;
  transitionMessage: any;
  transitionError: any;
  stripeUrl: any;
  stripeLoader: any;
  // settype:any;

}

interface SS {
  id: any;

}

export default class SubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {

  getRecommendedCourseID: string = "";
  getCartDataID: string = "";
  removeCartDataID: string = "";
  addCardPaymentID: string = "";
  param_id: any
  getStripeSubscriptionID: string = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),

    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      orderConfirmationOpen: true,
      recommendedCourseData: {},
      recommendedLoader: true,
      proceedModal: false,
      cartData: {},
      cartDataLoader: true,
      selectedOrder: [],
      saveOrder: false,
      totalAmount: '0',
      paymentMethod: 'debit',
      transitionMessage: '',
      transitionError: '',
      stripeUrl: '',
      stripeLoader: true,
      //isModalOpen:false,
      // settype:false,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.param_id = this.props?.navigation?.getParam();

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // api call start =======================================

      // add debit data api response 
      if (apiRequestCallId === this.addCardPaymentID) {

        if (responseJson && !responseJson.errors) {
          console.log(responseJson, "debit")
          this.setState({
            transitionError: ''
          })
          this.handleProceed('Congratulations')


        } else {
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          this.setState({
            transitionError: responseJson.errors
          })
          if (errorReponse === undefined) {
            console.log(errorReponse)
            this.handleProceed('OOPS!')
          } else {
            console.log(errorReponse, 'error')
            console.log(responseJson.errors, 'errors')
            this.handleProceed('OOPS!')

          }
        }
        // console.log(responseJson)
      }

      // get strip data api response 
      if (apiRequestCallId === this.getStripeSubscriptionID) {

        if (responseJson && !responseJson.errors) {
          console.log(responseJson, responseJson.data.success_url, "strip")
          if (responseJson.success) {
            this.setState({
              stripeUrl: responseJson.data.success_url,
              stripeLoader: false
            })
          }
          // this.handleProceed()
        } else {
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          console.log(errorReponse)

        }
      }

    }
  }

  debitCardPayment(user: any) {
    console.log("called API ")
    let data = new FormData()
    data.append("courseable_id", `${this.props?.location?.state ? this.props.location.state.name.id : ''}`);
    data.append("courseable_type", `BxBlockCoursecreation::${this.props?.location?.state ? this.props?.location?.state?.name?.attributes?.expand_type || this.props.location?.state?.type : ''}`);
    data.append("save_card", user.rememberCard);
    data.append("card_number", user.cardNumber);
    data.append("exp_month", user.MM);
    data.append("exp_year", user.YYYY);
    data.append("cvc", user.CVV);
    data.append("name", user.cardName);

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addCardPaymentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addSubscriptionPaymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  getStripSubscription() {
    console.log("called API ")
    let data = new FormData()
    data.append("courseable_id", `${this.props?.location?.state ? this.props.location.state.name.id : ''}`);
    data.append("courseable_type", `BxBlockCoursecreation::${this.props?.location?.state ? this.props.location.state.name.attributes.expand_type || this.props.location.state.type : ''}`);


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getStripeSubscriptionID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStripSubscriptionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }


  handleConfirmation = () => {
    this.setState({
      orderConfirmationOpen: false
    })
    console.log('open');
  }
  handleConfirmationClose = () => {
    this.setState({
      orderConfirmationOpen: true
    })
    console.log('open');
  }

  handleProceed = (data: any) => {
    this.setState({
      proceedModal: true,
      transitionMessage: data

    })
  }

  handleProceedModalClose = () => {
    this.setState({
      proceedModal: false,

    })
  }

  handleBackHome = () => {
    this.props.navigation.navigate('Dashboard')
  }

  handleBackPersonal = () => {
    this.props.history.push('/personal')
  }

  handlePaymentMethod = (e: any) => {
    if (e.target.value === 'stripe') {
      this.getStripSubscription()
    }
    this.setState({
      paymentMethod: e.target.value
    })
  }
  
  getTotal = () => {
    let amount = 0;
    this.state.cartData?.attributes?.cart_item.length > 0 && this.state.cartData?.attributes?.cart_item.forEach((element: any) => {
      amount = amount +
        +element?.attributes?.course_detail?.price
    });
    this.setState({
      totalAmount: amount
    })
    console.log('amount', amount);

  }

  onMessage = (data: any) => {
    const webData = JSON.parse(data.nativeEvent.data)
    console.log(webData.title, "data-------------------");
    this.props.navigation.navigate("OrderComplete", {
      item: webData.success === true ? "succeeded" : "failure",
      detail: {
        title: webData.title,
        image: webData.image
      }
    })
  }

}
// Customizable Area End