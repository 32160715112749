// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControlLabel,
  Radio,
  Chip,
  Dialog,
  Checkbox
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  withStyles,
  styled
} from "@material-ui/core/styles";
import "react-calendar/dist/Calendar.css";
import CloseIcon from "@material-ui/icons/Close";
import { EditIcon3, closeIcon, deleteIcon, plusIcon, searchIcon,checkedBox,unCheckBox,copyIcon,adminCopyIcon,copyCheck,activeCopyButton } from "./assets";
import UserManageController from "./UserManageController.web";
import MaterialUIBox from '@material-ui/core/Box';
const configJSON = require("./config.js");

export interface Props {
  classes: any;
}

class UserManage extends UserManageController {
  render() {
    return (
      <>
        <div className={this.props.classes.container} >
          <div className={this.props.classes.root2}>
            <div className={this.props.classes.userInfoDiv}>
              <div className={this.props.classes.userInfoInnerDiv} >
                <div className={this.props.classes.userInfoInnerDiv2} >
                  <Typography className={this.props.classes.userInformationHeading2}>
                    {configJSON.userInformation}
                  </Typography>
                  <img src={EditIcon3}
                    className={this.props.classes.editIconImg}
                    data-test-id="userEditUser"
                    onClick={() => this.handleEditUsersData(this.state.userId)} />
                </div>
                <div
                  className={
                    this.state.status === "Active"
                      ? this.props.classes.statusStyle
                      : this.props.classes.statusInvitedStyle
                  } >
                  {this.state.status}
                </div>
              </div>
              <div className={this.props.classes.userInfoInnerDiv3} >
                <div className={this.props.classes.userInfoInnerDiv4}>
                  <div className={this.props.classes.userInfoInnerDiv5}>
                    <div>
                      <span className={this.props.classes.userInformationName}>{configJSON.userName}</span>
                    </div>
                    <div>
                      <span className={this.props.classes.userInformationName}>{configJSON.emailAddress2}:</span>
                    </div>
                    <div>
                      <span className={this.props.classes.userInformationName}>{configJSON.mobileNumber}:</span>
                    </div>
                  </div>
                  <div className={this.props.classes.userInfoInnerDiv6} >
                    <span className={this.props.classes.userInformationsubName}>{this.state.title !== "prefer_not_to_say" ? this.state.title : ""} 
                    {` ${this.state.firstName} ${this.state.middleName} ${this.state.lastName}`}</span>
                    <span className={this.props.classes.userInformationsubName}>{this.state.email}</span>
                    <span className={this.props.classes.userInformationsubName}>+{this.state.countryCode} {this.formatPhoneNumber(this.state.phoneNumber)} </span>
                  </div>
                </div>
                <div className={this.props.classes.userInfoDeleteUserDiv} >
                  <div className={this.props.classes.userInfoDeleteUserInnerDiv} >
                    <div className={this.props.classes.userInformationDeleteDiv} onClick={this.cancelLessonDeleteDialog}>
                      <img src={deleteIcon} />
                      <span className={this.props.classes.userInformationDeleteText}>
                        {configJSON.deleteUser}
                      </span>

                      {<ModalMainBody
                        open={this.state.openUserDeleteDialog}
                      >
                        <ModalContent>
                          <IconAlign onClick={this.cancelLessonDeleteDialog}><CloseIcon /></IconAlign>
                          <ModalText>{`Are you sure you want to delete this user?`}</ModalText>
                          <ModalText1>{"User and all it's data will be deleted. This action cannot be undone."}</ModalText1>
                          <div style={{ width: "85%" }}>
                            <div className={this.props.classes.buttonContainer}>
                              <button data-test-id="cancelUserData" className={this.props.classes.cancelButton} onClick={this.cancelLessonDeleteDialog}>Cancel</button>
                              <button data-test-id="deleteUserData" className={this.props.classes.deleteButton} onClick={() => this.handleDeleteUserData()}>{configJSON.textDelete}</button>
                            </div>
                          </div>
                        </ModalContent>
                      </ModalMainBody>}
                    </div>
                    {this.state.status === "Invited" &&
                    <div className={this.props.classes.userInfoResendDiv} >
                      <button className={this.props.classes.userInformationResendText} data-testid="resend-invite" onClick={this.handleResendInvite}>
                        {configJSON.resendInvite}
                      </button>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={this.props.classes.assignContentDiv} >
              <div className={this.props.classes.assignContentInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading3}>
                  {configJSON.assignContent}
                </Typography>
              </div>
              <div className={this.props.classes.assignContentInnerDiv2} >
                <span className={this.props.classes.userInformationName}>{configJSON.userInformationText}</span>
                <button data-test-id="userAssign" onClick={() => this.handleAssignContent(this.state.userId)} className={this.props.classes.userInformationAssignText}>
                  {configJSON.assignContent}
                </button>
              </div>
            </div>
          </div>
          <div className={this.props.classes.root3}>
            <div className={this.props.classes.teamsDiv} >
              <div className={this.props.classes.teamsInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading4}>
                  {configJSON.teams}
                </Typography>
              </div>
              <div className={this.props.classes.teamsInnerDiv2} >
                <span className={this.props.classes.userInformationHeading5}>{configJSON.userInformationText2}</span>
              </div>
            </div>
            <div className={this.props.classes.assignContentBtnDiv} >
              <div className={this.props.classes.assignContentBtnInnerDiv} data-test-id="addTeam" onClick={this.toggleDropdown}>
                <img className={this.props.classes.assignContentImg} src={plusIcon} />
                <span className={this.props.classes.userInformationAssignText2}>{configJSON.addTeams}</span>
              </div>
              {this.state.dropdownAddTeams && (
                <div className={this.props.classes.dropdownOutsideDiv} ref={this.dropdownRef}>
                  <MaterialUIBox
                    p={3}
                    className={this.props.classes.teamsDropdown}
                  >
                    <DropdownContent>
                      <TextFieldSearch
                        data-test-id="teamSearch"
                        placeholder="Search Team Name"
                        variant="outlined"
                        value={this.state.placeholderSearchTeamsText}
                        onChange={this.handleTeamsSearchChange}
                        InputProps={{
                          startAdornment: <img src={searchIcon}
                            className={this.props.classes.teamSearchIcon}
                          />,
                        }}
                      />
                      <MaterialUIBox data-test-id="checkData1" className="checkboxList">
                        {this.state.teamsListing && this.state.teamsListing
                          .filter((team: any) => team.attributes.name.toLowerCase().includes(this.state.placeholderSearchTeamsText.toLowerCase()))
                          .map((team: any) => {
                            const isChecked = this.state.teamsSelectedItems && this.state.teamsSelectedItems.find((item: { id: number, value: string }) => item.value === team.attributes.name);
                            return (
                              <FormControlLabel
                                key={team.id}
                                className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"}
                                control={
                                  <CheckboxMain
                                    data-test-id="teamLabelsTestId"
                                    checked={isChecked ? true : false}
                                    name={team.attributes.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.tagsCheckboxChangeHandler(event, team.id);
                                    }}
                                  />
                                }
                                label={team.attributes.name}
                              />
                            );
                          })}
                      </MaterialUIBox>
                    </DropdownContent>
                  </MaterialUIBox>
                </div>
              )}
              {this.state.teamsSelectedItems.map(team => (
                <div key={team.id}>
                  <Chip
                    data-test-id="addTeamName"
                    label={team.value}
                    onDelete={() => this.handleClearTeamNames(team)}
                    variant="outlined"
                    className={this.props.classes.selectedTeamsList}
                    deleteIcon={<img src={closeIcon}
                      className={this.props.classes.selectedTeamsDeleteIcon}
                    />}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={this.props.classes.saveChangesDiv} >
            <SaveAndCreate
              data-test-id="saveChangesBtn"
              onClick={() => {
                this.updateTeamsToUser(this.state.teamsSelectedItems);
              }}
              className={this.state.teamsSelectedItems.length > 0 ? this.props.classes.saveChangesEnableBtn : this.props.classes.saveChangesDisableBtn}
              disabled={!this.state.teamsSelectedItems.length}
            >
              <Typography className={this.props.classes.typo}>Save Changes</Typography>
            </SaveAndCreate>
          </div>
        </div>
        <Dialog 
        open={this.state.resendInvitePopup}
        onClose={this.handleCloseResendInvite}
        aria-labelledby="simple-dialog-title"
        style={{padding:'20px'}}
        PaperProps={{
            style: {
              margin: 'auto',
              maxWidth: '578px',
              overflowY: 'auto',
              zIndex: 9999,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: "12px",
              padding:'16px 20px'
            },
          }}
        >
            <Box>
              <Box className={this.props.classes.sendInviteFirstBox}>
                <Typography className={this.props.classes.sendInviteText}>{configJSON.sendInvite}</Typography>
                <Box className={this.props.classes.emailMainContainer}>
                    <Box className={this.props.classes.emailBox}>
                      <Radio
                      className={this.props.classes.checkboxContainer}
                      checkedIcon={<img src={checkedBox}/>}
                      icon={<img src={unCheckBox}/>}
                      value="email"
                      data-testid="email-checkbox"
                      checked={this.state.selectedCheck === "email"}
                      onChange={this.handleCheckboxChange}
                      />
                      <Typography className={this.props.classes.emailText}>{configJSON.email}</Typography>
                    </Box>
                    <Box className={this.props.classes.dividerBox}></Box>
                    <Box className={this.props.classes.emailBox}>
                      <Radio
                      checkedIcon={<img src={checkedBox}/>}
                      icon={<img src={unCheckBox}/>}
                      className={this.props.classes.checkboxContainer}
                      value="copy"
                      data-testid="copy-checkbox"
                      checked={this.state.selectedCheck === "copy"}
                      onChange={this.handleCheckboxChange}
                      />
                      <Typography className={this.props.classes.emailText}>{configJSON.copy}</Typography>
                    </Box>
                </Box>
              </Box>
              {this.state.selectedCheck === "copy" &&
              <Box className={this.props.classes.fieldBox}>
                <TextField
                variant="outlined"
                data-testid="copy-textfield"
                value={this.state.copyValue}
                className={this.state.copiedAlert ? this.props.classes.activeCopyField : this.props.classes.copyTextfield}
                disabled={!this.state.copiedAlert}
                error={true}
                size="small"
                InputProps={{
                  startAdornment: <img src={copyIcon} className={this.props.classes.copyImgIcon}/>,
                  endAdornment:  <Box>
                    {this.state.copiedAlert ?
                    <Box className={this.props.classes.copiedImgBox}>
                      <Box className={this.props.classes.inputDivider}></Box>
                    <img src={activeCopyButton} data-testid="copy-link-button" className={this.props.classes.endCopiedImg} onClick={this.handleCopyClick}/> 
                    </Box>
                    :
                    <img src={adminCopyIcon} data-testid="copy-link-button" className={this.props.classes.endCopyImg} onClick={this.handleCopyClick}/> 
                  }
                  </Box>
                }}
                />
                {this.state.copiedMessage !== "" &&
                <Box className={this.props.classes.copiedBox}>
                <img src={copyCheck}/>
                <Typography className={this.props.classes.copiedMessageText}>{this.state.copiedMessage}</Typography>
                </Box>
                }
              </Box> 
              }
              <Box className={this.props.classes.buttonBox}>
                <Button className={this.props.classes.cancelResendButton} variant="outlined" onClick={this.handleCloseResendInvite}>{configJSON.Cancel}</Button>
                <Button className={this.props.classes.sendButton} data-testid="send-button" variant="contained" disabled={this.state.selectedCheck === "copy"} onClick={this.handleSendLink}>{configJSON.Send}</Button>
              </Box>
            </Box>
        </Dialog >
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    dropdownOutsideDiv: {
      position: "relative",
      top: "42px",
      right: "185px"
    },
    typo: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
      textTransform: "none" as 'none'
    },
    saveChangesDisableBtn: {
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E5E2E1",
      borderRadius: "16px",
      color: "#929090",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      width: "411px",
      height: "56px",
      font: "normal 600 16px Poppins",
      textTransform: "capitalize"
    },
    saveChangesEnableBtn: {
      width: "411px",
      height: "56px",
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E47004",
      borderRadius: "16px",
      color: "#E47004",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "capitalize"

    },
    container: {
      width: "100%",
      margin: "0px 20px"
    },
    root2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    root3: {
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      marginTop: "25px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    statusStyle: {
      fontSize: "14px",
      width: '96px',
      height: '40px',
      borderRadius: '88px',
      backgroundColor: '#E0D4E7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '6px 9px',
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#542070",
    },
    userInfoDiv: {
      borderBottom: "1px solid #E5E2E1",
      padding: "15px 0px 22px 0px",
      width: "97%"
    },
    userInfoInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "18px"
    },
    userInfoInnerDiv3: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      flexWrap: "wrap",
    },
    userInfoInnerDiv4: {
      display: "flex"
    },
    userInfoInnerDiv5: {
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    },
    userInfoInnerDiv6: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "20px",
      gap: "8px"
    },
    userInfoDeleteUserDiv: {
      display: "flex",
      alignItems: "end",

      '@media (max-width: 960px)': {
        marginTop: "20px",
      },
    },
    userInfoDeleteUserInnerDiv: {
      display: "flex",
      alignItems: "center",
      '@media (max-width: 960px)': {
        flexWrap: "wrap"
      },
    },
    userInfoResendDiv: {
      display: "flex",
      alignItems: "center",
      marginLeft: "25px",
      cursor: 'pointer !important',
      '@media (max-width: 500px)': {
        margin: "10px 0 0 0 !important"
      },
    },
    assignContentDiv: {
      padding: "18px 0px 18px 0px",
      width: "97%"
    },
    assignContentInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    userInfoInnerDiv2: {
      display: "flex",
      alignItems: "center"
    },
    statusInvitedStyle: {
      height: '40px',
      width: '96px',
      backgroundColor: '#FFEDE4',
      borderRadius: '88px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      gap: "10px",
      padding: '6px 9px',
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#E47004",
    },
    userInformationHeading2: {
      font: "normal 600 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationName: {
      font: "normal 400 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationsubName: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationDeleteDiv: {
      display: "flex",
      alignItems: "center",
      width: "146px",
      height: "44px",
      cursor: 'pointer !important'
    },
    userInformationDeleteText: {
      font: "normal 600 16px Poppins",
      color: "#652786",
      marginLeft: "14px",
    },
    userInformationResendText: {
      font: "normal 600 16px Poppins",
      width: "183px",
      height: "42px",
      border: "1px solid #E47004",
      color: "#E47004",
      background: "white",
      borderRadius: "12px",
      cursor: 'pointer !important'
    },
    userInformationHeading3: {
      font: "normal 500 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText: {
      font: "normal 600 16px Poppins",
      width: "183px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "#FFFFFF",
      border: "none",
      cursor: 'pointer !important',
      "@media (max-width: 820px)": {
        marginTop: "15px"
      },
    },
    userInformationHeading4: {
      font: "normal 600 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationHeading5: {
      font: "normal 400 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText2: {
      font: "normal 600 16px Poppins",
      color: "#FFFFFF",
    },
    assignContentInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      alignItems: "center",
      textAlign: "center",
      "@media (max-width: 820px)": {
        alignItems: "flex-start",
        textAlign: "left",
        flexWrap: "wrap"
      },
    },
    teamsDiv: {
      padding: "20px 0px 20px 0px",
      marginLeft: "20px"
    },
    teamsInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    teamsInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      alignItems: "center",
      textAlign: "center"
    },
    assignContentBtnDiv: {
      border: "1px solid #E5E2E1",
      padding: "20px",
      borderRadius: "12px",
      width: "97%",
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "20px",
      background: "#FCF8F8"
    },
    assignContentBtnInnerDiv: {
      width: "182px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "white",
      gap: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: 'pointer !important'
    },
    assignContentImg: {
      height: "24px",
      width: "24px"
    },
    saveChangesDiv: {
      display: "flex",
      justifyContent: "center",
      marginTop: "35px",
      width: "97%"
    },
    editIconImg: {
      height: "24px",
      width: "24px",
      marginLeft: "15px",
      cursor: "pointer"
    },
    cancelButton: {
      width: "48%",
      height: "42px",
      fontWeight: 600,
      background: "white",
      fontSize: "16px",
      border: "1px solid #E47004",
      borderRadius: "12px",
      color: "#E47004",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    deleteButton: {
      width: "48%",
      border: "none",
      height: "42px",
      background: "#652786",
      borderRadius: "12px",
      fontWeight: 600,
      fontSize: "16px",
      color: "white",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "0px 2px 0px 2px"
    },
    selectedTeamsList: {
      borderColor: '#652786',
      color: '#652786',
      width: "170px",
      height: "40px",
      borderRadius: "12px !important",
      border: "1px solid #652786 !important",
      backgroundColor: "#FFFFFF !important",
      fontSize: "14px",
      fontFamily: "Poppins !important",
      fontWeight: 400,
      textAlign: "center",
      lineHeight: "16px",
      margin: "0 0 20px 15px",
      position: "relative"
    },
    selectedTeamsDeleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      width: "24px",
      height: "24px",
      cursor: "pointer"
    },
    teamSearchIcon: {
      width: "20px",
      height: "20px",
      position: "relative",
      zIndex: 999,
      marginRight: "6px"
    },
    teamsDropdown: {
      padding: '16px 16px 20px 16px',
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      width: "380px",
      boxSizing: "border-box",
      position: "absolute",
      zIndex: 9999,
      marginTop: "8px",
      overflow: "auto",
      height: "auto"
    },
    addToTeamBtn: {
      fontSize: "16px",
      fontFamily: "Poppins !important",
      fontWeight: 600,
      textAlign: "center",
      color: '#652786',
      border: "none",
      background: "#FFFFFF",
      width: "100%",
      height: "36px",
    },
    sendInviteFirstBox:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between'
    },
    sendInviteText:{
      fontSize:'20px',
      fontWeight:600,
      color:'#1C1B1B',
      lineHeight:'24px'
    },
    emailMainContainer:{
      display:'flex',
      alignItems:'center',
      gap:'30px',
      flexWrap:'wrap'
    },
    dividerBox:{
      border:'1px solid #707070',
      opacity:'0.7',
      height:'24px',
      marginLeft:'10px'
    },
    copiedImgBox:{
      display:'flex',
      alignItems:'center'
    },
    inputDivider:{
      border:'1px solid #1C1B1B',
      height:'24px !important',
      marginLeft:'26px'
    },
    emailBox:{
      display:'flex',
      alignItems:'center',
      gap:'10px'
      
    },
    checkboxContainer:{
      display:'flex',
      alignItems:'start',
      padding:'0'
    },
    emailText:{
      lineHeight:'22px',
      color:'#484646'
    },
    cancelResendButton:{
      borderColor:'#E47004',
      borderRadius:'12px',
      fontSize:'16px',
      fontWeight:600,
      color:'#E47004',
      textTransform:'capitalize',
      height:'42px',
      width:'277px',
      maxWidth:'100%'
    },
    sendButton:{
      background:'#652786',
      borderRadius:'12px',
      color:'#fff',
      fontSize:'16px',
      fontWeight:600,
      textTransform:'capitalize',
      height:'42px',
      width:'277px',
      maxWidth:'100%',
      boxShadow:'none',
      '&:hover':{
        background:'#652786',
        boxShadow:'none'
      }
    },
    buttonBox:{
      display:'flex',
      gap:'20px',
      marginTop:'35px'
    },
    fieldBox:{
      marginTop:'35px'
    },
    copyTextfield:{
      width:'100%',
      '& div':{
        borderRadius:'8px',
        height:'56px',
        background: '#fcf8f8',
        paddingRight:'0',
        '& input':{
          textOverflow:'ellipsis'
        }
      },
      '& .MuiInputBase-root.Mui-disabled':{
        color:'#ADAAAA'
      }
    },
    activeCopyField:{
      width:'100%',
      '& div':{
        borderRadius:'8px',
        height:'56px',
        background: '#fff',
        paddingRight:'0',
        '& input':{
          textOverflow:'ellipsis',
        }
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':{
        borderColor:'#652786'
      }
    },
    copyImgIcon:{
      padding:'10px'
    },
    endCopyImg:{
      paddingLeft:'30px',
      cursor:'pointer'
    },
    endCopiedImg:{
      paddingLeft:'34px',
      cursor:'pointer'
    },
    copiedBox:{
      display:'flex',
      alignItems:'center',
      marginTop:'5px'
    },
    copiedMessageText:{
      color:'#652786',
      fontSize:'12px',
      lineHeight:'18px'
    }
  });

const SaveAndCreate = styled(Button)({
  width: "411px",
  height: "56px",
  padding: "20px 32px",
  "@media (max-width:960px)": {
    width: "240px",
    height: "56px",
    padding: "10px 21px",
  },
})

const DropdownContent = styled(MaterialUIBox)({
  width: "348px",
  maxHeight: "204px",
  "& .checkboxList": {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "3px"
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins",
    textTransform: "capitalize"
  }, "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#484646"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
  },
  "& .selectedStyle .MuiTypography-body1": {
    color: "#652786 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
  },
  "& .applyBtn": {
    display: "block",
    margin: "auto",
    color: "#542070",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    borderBottom: "1px solid #E5E2E1",
    fontFamily: "Poppins"
  },
  "& .react-calendar": {
    border: "unset",
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .react-calendar abbr": {
    textDecoration: "none"
  },
  "& .react-calendar__month-view__days__day": {
    width: "31px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: 400,
    fontSize: "14px !important"
  },
  "& .react-calendar__month-view__weekdays": {
    fontWeight: 400,
    fontSize: "10px !important"
  },
  "& .react-calendar__tile--now:enabled": {
    backgroundColor: "#652786",
    color: "#fff"
  },
  "& .react-calendar__tile--now:enabled:hover": {
    backgroundColor: "#652786"
  },
  "& .react-calendar__month-view__days__day--weekend": {
    color: "rgba(0, 0, 0, 0.87)"
  },
  "& .react-calendar__month-view__days__day--neighboringMonth": {
    color: "#757575"
  },
  "& .react-calendar__tile--active": {
    color: "#fff",
    backgroundColor: "#652786 !important"
  },
  "& .react-calendar__year-view__months__month": {
    width: "74px",
    height: "74px",
    borderRadius: "50%"
  },
  "& .react-calendar__navigation__prev2-button": {
    display: "none"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  "& .react-calendar__navigation button:enabled:hover": {
    backgroundColor: "unset"
  },
  "& .react-calendar__navigation button": {
    minWidth: "auto"
  },
  "& .calendarBtnContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px"
  }
});

const TextFieldSearch = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: '#1C1B1B',
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    backgroundColor: "#FFFFFF",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiSvgIcon-root": {
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "56px",
    width: "100%",
    padding: "16px 20px",
    border: "1px solid #652786 !important"
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "6px"
  }
});

const CheckboxMain = styled(Checkbox)({
  color: '#652786 !important',
});

const ModalMainBody = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth: "400px",
  },
  "@media (max-width: 400px)": {
    maxWidth: "330px",
  }
});

const ModalText = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 600,
  fontSize: "22px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-10",
  right: "-10",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
  cursor: 'pointer !important'
});
export default withStyles(styles)(UserManage);
// Customizable Area End