import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  withStyles,
  LinearProgress,
  Button,
} from "@material-ui/core";
import { BoxImg, RightArrow, WhiteLogo,Arrow } from "../assets";
// Customizable Area Start
import { Line } from 'react-chartjs-2';
import './Style.css';
import Loader from "../../../../components/src/Loader";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "32px",
    borderRadius: "8px",
  },
  colorPrimary: {
    backgroundColor: "#66c9e840",
  },
  bar: {
    borderRadius: "8px",
    background: 'linear-gradient(90deg, #6C328B 0%, #F42074 72.63%, #EF2B4B 100%)',

  },
}))(LinearProgress);
// Customizable Area End

import AchievementListingController, {
  Props,
} from "./AchievementListingController.web";

export default class AchievementListing extends AchievementListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
       return (
      // Customizable Area Start
      <>
        <Container
          maxWidth="lg"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box style={{ display: 'flex', margin: '0px 40px 20px 10px' }}>
            <Box style={{marginTop: '8px'}}>        
             <img  src={Arrow}></img>
            </Box>
            <Typography
              variant='h5'
              style={{ fontWeight: 700, fontSize: '48px' }}
            >
              {'All Achievements'}
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              marginTop: "15px",
              background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
              padding: "20px",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700, fontSize: "24px" }}
                >
                  Most recent achievement
                </Typography>
              </Box>
            </Box>

            <Box sx={{ width: "100%", mt: "15px" }}>
              <Grid container spacing={3} style={{ alignItems: "center" }}>
                <Grid item xs={3}>
                  <Box
                    style={{
                      background: "#EBE3EF",
                      borderRadius: "14px",
                      height: "238px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "18px",
                        color: "#6C328B",
                        fontWeight: 600,
                        margin: "10px",
                      }}
                    >
                      {this.state.achievementListingData?.latest_achivement?.achievement?.attributes?.name}
                    </Typography>
                    <Box
                      sx={{ width: "116px" }}
                    >
                      <img width={126} height={132}  
                      src={this.state.achievementListingData?.latest_achivement?.achievement?.attributes?.thumbnail_image?.url ||BoxImg} alt={'icon'}></img>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}
                onClick={()=>this.handleNavigation(this.state?.achievementListingData?.latest_achivement?.last_course_program?.expand_type,this.state?.achievementListingData?.latest_achivement?.last_course_program?.id,this.state?.achievementListingData?.latest_achivement?.last_course_program?.title)}
                >
                  <Box
                    style={{ display: "flex", alignItems: "center", gap: "40px" ,cursor: 'pointer'}}
                  >
                    {
                      this.state?.achievementListingData?.latest_achivement?.last_course_program?.image === null ?
                        <Box
                          style={{
                            width: "132px",
                            height: "108px",
                            borderRadius: "10px",
                            background:
                              "linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.02) 99.97%, rgba(0, 0, 0, 0.00) 100%)",
                          }}
                        >
                        </Box>
                        :
                        <Box
                          style={{
                            width: "132px",
                            height: "108px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            style={{
                              width: "132px",
                              height: "108px",
                              borderRadius: "10px",
                            }}
                            src={this.state?.achievementListingData?.latest_achivement?.last_course_program?.image}
                            alt={'icon'}
                          />
                        </Box>
                    }
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 600,
                          color: "#1A1A1A",
                          fontSize: "16px",
                        }}
                      >
                        {this.state.achievementListingData?.latest_achivement?.last_course_program?.title}
                      </Typography>
                      <Typography
                        style={{
                          width: "100%",
                          display: "block",
                          color: "#7D7D7D",
                          fontSize: "16px",
                        }}
                      >
                        {this.state.achievementListingData?.latest_achivement?.last_course_program?.expert}
                      </Typography>

                      <Typography
                        style={{
                          width: "100%",
                          display: "block",
                          color: "#777E90",
                          fontSize: "12px",
                          fontWeight: 600,
                          marginTop: "15px",
                        }}
                      >
                        {this.state.achievementListingData?.latest_achivement?.last_course_program?.start_date}
                      </Typography>

                      <Typography
                        style={{
                          width: "100%",
                          display: "block",
                          color: "#777E90",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {this.state.achievementListingData?.latest_achivement?.last_course_program?.total_video_count + " Videos Completed"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ position: "relative", marginTop: "24px" }}>
                    <Typography
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        left: "0",
                        right: "0",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {this.state.achievementListingData?.latest_achivement?.last_course_program?.watched_video_count}/{this.state.achievementListingData?.latest_achivement?.last_course_program?.total_video_count}
                    </Typography>
                    <BorderLinearProgress variant="determinate"
                      value={this.state.achievementListingData?.latest_achivement?.last_course_program?.watched_video_count / this.state.achievementListingData?.latest_achivement?.last_course_program?.total_video_count * 100} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      width: "100%",
                      display: "block",
                      color: "#171725",
                      fontSize: "18px",
                      fontWeight: 600,
                      marginTop: "15px",
                    }}
                  >
                    Videos Watched
                  </Typography>
                  <Box sx={{ height: "160px" }}>
                    <LineChart2  state={this?.state}/>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '5px',
                  }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                        boxSizing: 'border-box',
                        padding: '5px', // Add spacing between items
                      }}
                    >
                      <Box style={{ display: 'flex', alignItems: 'center' , gap:"10px" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "8px",
                            backgroundColor: " #5542F6",
                            borderRadius: "6px",
                          }}></div>

                        <Typography component='p' style={{ width: '100%',fontSize: '0.8rem'}}>
                          {"This Week"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '50%',
                        boxSizing: 'border-box',
                        padding: '5px', // Add spacing between items
                      }}
                    >
                      <Box style={{ display: 'flex', alignItems: 'center',gap:"10px" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "8px",
                            backgroundColor: "#FF8B3F",
                            borderRadius: "6px",
                          }}></div>

                        <Typography component='p' style={{ width: '100%', fontSize: '0.8rem'}}>
                          {"Last Week"}
                        </Typography>
                      </Box>
                    </Box>

                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "end", marginTop: 30 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        height: "32px",
                        width: "112px",
                        borderRadius: "30px",
                        boxShadow: "none",
                        background: "#6C328B",
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            style={{
              width: "100%",
              marginTop: "30px",
              background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
              padding: "20px",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700, fontSize: "24px" }}
                  >
                    All achievements
                  </Typography>
                </Box>
                {
                  this.state?.viewAll ?
                    <Box onClick={() => { this.viewAllset() }}>
                      <Typography
                        style={{
                          color: "#6C328B",
                          fontSize: "16px",
                          fontWeight: 600,
                          display: "flex",
                        }}
                      >
                        View All
                        <Box
                          style={{
                            transition: "transform 0.3s ease",
                            transform: "rotate(0deg)"
                          }}
                        ><img src={RightArrow} alt={"icon"}></img></Box>
                      </Typography>
                    </Box>
                    :
                    <Box onClick={() => { this.viewLessSet() }}>
                      <Typography
                        style={{
                          color: "#6C328B",
                          fontSize: "16px",
                          fontWeight: 600,
                          display: "flex",
                        }}
                      >
                        View All
                        <Box
                          style={{
                            transition: "transform 0.3s ease",
                            transform: "rotate(90deg)"
                          }}
                        ><img src={RightArrow} alt={"icon"}></img></Box>
                      </Typography>
                    </Box>
                }

              </Box>

              <Box style={{ marginTop: "60px" }}>
                <Grid container spacing={8}>
                  {
                    this.state?.achievementListingData?.data?.slice(0, this.state?.arrayLen)?.map((val: any) => (

                      <Grid item xs={6} key={val?.id}>
                        <Box
                          style={{
                            background: "#fff",
                            boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)",
                            borderRadius: "16px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            position: "relative",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{ width: "72px", height: "72px" }}
                            >
                              <img width={82} height={88} src={val?.attributes?.achievement?.attributes?.thumbnail_image?.url || WhiteLogo}></img>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 600,
                                color: "#11142D",
                                fontSize: "18px",
                                marginBottom: "5px",
                                marginLeft: '5%'
                              }}
                            >
                              {val?.attributes?.achievement?.attributes?.name}
                            </Typography>
                            <Typography
                              style={{
                                width: "100%",
                                display: "block",
                                color: "#9F9F9F",
                                fontSize: "16px",
                                marginLeft: '5%'
                              }}
                            >
                              {val?.attributes?.reward_status}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "24px",
                              right: "24px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                height: "32px",
                                borderRadius: "30px",
                                boxShadow: "none",
                                background: "#FF8B3F",
                              }}
                              onClick={() => this.achievementView(val?.id, val?.attributes?.unlocked)}
                            >
                              View
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>
            </Box>
          </Box>
          {this.state.isloading ? <Loader loading={this.state.isloading} /> : null}
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const LineChart2 = ({ state }: any) => {
  const data = {
    responsive: true,
    labels: state?.date,
      datasets: [
      {
        id: 1,
        label: '',
        data: state?.thisWeekCount,
        backgroundColor: 'transparent',
        borderColor: '#5542F6',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
      {
        id: 2,
        label: '',
        data: state?.lastWeekCount,
        backgroundColor: 'transparent',
        borderColor: '#FF8B3F',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 10,
        ticks: {
          stepSize: 2,
          callback: (value: any) => value
        },
      }
    },
  };
  return (
    <>
      <div className="canvas" style={{ width: '100%', height: '120px', marginTop: '20px' }}>
        <Line width="100%" height={120} data={data} options={options} />
      </div>
    </>
  );
}
// Customizable Area End
