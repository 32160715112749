import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
const EmptyNote = require("./empty_notes.svg");
const AddIcon = require("./Icon_add.svg");
const EditIcon = require("./icon_edit.svg");
import PreviewNote from "./PreviewNote"
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import moment from "moment";

interface NoteType1 {
    id:string;
    type:string;
    attributes:{
      title:string;
      description:string;
      is_private:boolean;
      created_at:string;
      link:string | null;
      note_type:string;
      course_timestamp:string;
      is_read:boolean;
      image:{
          id:number;
          file_name:string;
          url:string;
      }
      is_editable:boolean;
      course:{
        course_id:number;
        chapter_id:number;
        lesson_name:string
      };
      created_date:string;
      chapter_title?:string
    }
    }

interface IProps {
  classes?: any;
  notes:NoteType1[];
  createNote:boolean;
  handleOpenNote:(id:string) => void;
  openNoteModal:boolean;
  noteId:string
  iconType:string;
  handleOpenEditNote:(id:string) => void;
  handleAddNote?:() => void;
  handleDeleteNote:() => void;
  deleteNoteModal:boolean;
  handleDeleteApiCall:(id:string) => void;
  profilenote?:boolean;
}

class Notes extends React.Component<IProps, {}> {

  render() {
    const {
      classes,
      notes,
      createNote,
      handleOpenNote,
      openNoteModal,
      noteId,
      iconType,
      handleOpenEditNote,
      handleAddNote,
      handleDeleteNote,
      deleteNoteModal,
      handleDeleteApiCall,
      profilenote
    } = this.props;
    return (
        <Box>
            {notes.length > 0 ?
            <Box className={classes.noteMainContainer} style={{height:notes.length > 4 ? '100vh' : 'auto',overflowY: notes.length > 4 ? 'scroll' : 'visible'}}>
                {notes.map(data => 
            <Box className={classes.noteCardBox}style={{borderLeft:(profilenote && data.attributes.note_type === "personal") ? "9px solid #E47004":"9px solid #652786"}} onClick={() => handleOpenNote(data.id)}>
                <Box style={{overflow:'hidden'}}>
                <Typography className={classes.noteTitle}>{data.attributes.title}{(iconType === "video" || iconType === "audio") && <span className={classes.noteTimeSpan}>{data.attributes.course_timestamp}</span>}</Typography>
                <Typography className={classes.lessonName}>{profilenote ? data.attributes?.chapter_title :data.attributes.course?.lesson_name}</Typography>
                <Typography className={classes.noteDescription}>{data.attributes.description}</Typography>
                {profilenote &&
                <Typography className={classes.createdDate}>Created on {moment(data.attributes.created_date).format('DD-MM-YY')}</Typography>
                }
                </Box>
                <img src={EditIcon} onClick={() => handleOpenEditNote(data.id)}/>
            </Box>
           )}
            </Box>
             :
             <>
             {profilenote ? 
             <Box className={classes.EmptyProfileNoteMainBox}>
             <img src={EmptyNote} alt="empty-note" className={classes.emptyNoteImg}/>
             <Typography className={classes.personalNoteText}>You can write personal notes anytime.</Typography>
             <Typography className={classes.DontHaveText} style={{padding:'5px 0 15px'}}>Create your first one</Typography>
             <Button
                 variant="contained"
                 startIcon={<AddCircleOutlineRoundedIcon style={{fontSize:'24px'}}/>}
                 className={classes.addNoteProfileButton}
                 onClick={handleAddNote}
             >
                 Add note
             </Button>
             </Box>
             :
            <Box className={classes.EmptyNoteMainBox}>
            <img src={EmptyNote} alt="empty-note" className={classes.emptyNoteImg}/>
            <Typography className={classes.DontHaveText}>You don’t have any notes yet.</Typography>
            {createNote &&
            <Button
                startIcon={<img src={AddIcon} alt="add"/>}
                className={classes.addNoteButton}
                onClick={handleAddNote}
            >
                Add note
            </Button>
            }
            </Box>
            }
            </>
            }
            <PreviewNote 
            notes={notes} 
            openNoteModal={openNoteModal} 
            handleOpenNote={handleOpenNote} 
            noteId={noteId} 
            iconType={iconType}
            handleOpenEditNote={handleOpenEditNote}
            handleDeleteNote={handleDeleteNote}
            deleteNoteModal={deleteNoteModal}
            handleDeleteApiCall={handleDeleteApiCall}
            profilenote={profilenote}
            />
        </Box>
    );
  }
}


const styles = createStyles({
    EmptyNoteMainBox:{
        padding:'10px',
        textAlign:'center'
    },
    EmptyProfileNoteMainBox:{
        paddingTop:'30px',
        textAlign:'center'
    },
    personalNoteText:{
        fontSize:'16px',
        fontWeight:400,
        lineHeight:'22px',
        paddingTop:'40px'
    },
    emptyNoteImg:{
        width:'100%',
        height:'260px'
    },
    DontHaveText:{
        textAlign:'center',
        fontWeight:600,
        color:'#1C1B1B',
        lineHeight:'16px',
        padding:'15px 0'
    },
    addNoteButton:{
        color:"#652786",
        fontSize:'16px',
        fontWeight:600,
        textTransform:'capitalize'
    },
    addNoteProfileButton:{
        borderRadius:'88px',
        width:'150px',
        height:'48px',
        background:'#E47004',
        color:'#fff',
        textTransform:'capitalize',
        fontSize:'16px',
        fontWeight:600,
        marginTop:'15px',
        boxShadow:'none',
        '&:hover':{
            background:'#E47004',
            boxShadow:'none'
        }
    },
    noteMainContainer:{
        margin:'15px 0 0'
    },
    noteCardBox:{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        boxShadow: '0px 3px 12px 0px #1A1A1A32',
        padding: '10px',
        borderRadius: '12px',
        marginBottom:'12px',
        cursor:'pointer'
    },
    noteTitle:{
        color: '#652786',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    noteTimeSpan:{
        paddingLeft: '25px',
        color: '#1C1B1B',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px'
    },
    lessonName:{
        color:' #484646',
        lineHeight: '18px',
        fontSize: '12px',
        padding: '4px 0 10px'
    },
    noteDescription:{
        fontSize: '12px',
        color: '#000',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    createdDate:{
        fontSize:'10px',
        fontWeight:500,
        lineHeight:'15px',
        color:'#787776'
    }
  });

export default withStyles(styles)(Notes);
// Customizable Area End