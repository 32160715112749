export const summary = require("./Shape .png");
export const dummy_img = require("./Frame.png");
export const UpArrowIcon = require("./sort.svg");
export const DownArrowIcon = require("./down_arrow.svg");
export const optionIcon = require("./option.svg");
export const editIcon = require("./editIcon.svg");
export const correctlyCheck = require("./correctlyCheck.svg");
export const circleCheck = require("./circleNew.svg");
export const editNew = require("./editNew.svg");
export const duplicate = require("./duplicate.svg");
export const preview = require("./preview.png");
export const archived = require("./archived.svg");
export const trash = require("./trash.svg");
export const exitCircle = require("./exitCircle.svg");
export const newUpArrow = require("./upArrow.svg");
export const newDownArrow = require("./downArrow.svg")


