export const iconImg = require("../assets/icon.png");
export const Filter = require("../assets/filter.svg");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg")
export const NewFilter = require("../assets/NewFilter.svg")
export const noDataImage = require("../assets/image.jpeg")
export const Vector = require("../assets/Vector.png")
export const GroupImg = require("../assets/Group.png")
export const rewards = require("../assets/reward.png")
export const achievement = require("../assets/achieve.png")
export const editIcon = require("../assets/edit.png")
export const manageImg = require("../assets/manageedit.png")