import React, { useEffect } from "react";

import {
  Box, Button, CircularProgress
  // Customizable Area End
  ,
  // Customizable Area Start
  Grid, IconButton, InputAdornment, Paper,
  TextField, Typography
} from "@material-ui/core";

// Customizable Area Start
import Backdrop from "@material-ui/core/Backdrop";
import Card from '@material-ui/core/Card';
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddNoteModal from "./AddNoteModal.web";
import Pagination from '@material-ui/lab/Pagination';
import './style.css';
const edit = require("../assets/Notes_edit.png");
const exmaplePic = require("../assets/exmaplePic.png");
const searchIcon = require("../assets/searchIcon.png");
const course = require("../assets/course.png");

// Customizable Area End

import CourseNotesController, {
  Props
} from "./CourseNotesController";


export default class CourseNotes extends CourseNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllNotesData();
  }



  handleTab = (value: any) => {
    this.getAllNotesData('', value == 0 ? '' : value == 1 ? 'personal' : 'course');
    this.setState({
      handleTabValue: value,
      searchValue: '',
      isloading: true
    })
  }
  handleSearch = (e: any) => {
    this.getAllNotesData(e.target.value, this.state.handleTabValue == 0 ? '' : this.state.handleTabValue == 1 ? 'personal' : 'course');
    this.setState({
      searchValue: e.target.value,
      isloading: true
    })
  }


  // Customizable Area End

  render() {
    const { allNotesData, isloading, searchValue, editData } = this.state;

    const handleNoteEdit = (data: any) => {
      this.setState({
        editData: data,
        isModalOpen: true
      })
    }


    return (
      // Customizable Area Start
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ padding: "20px" }}>
            <Typography variant="h4" className="profileName" style={{ fontWeight: "bold", marginBottom: "15px" }}>
              Notes
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                size="small"
                placeholder="Search"
                variant="outlined"
                style={{
                  width: "32%"
                }}
                className="inputRounded2"
                onChange={(e) => this.handleSearch(e)}
                value={searchValue}
                id="input-with-icon-adornment"
                InputProps={{
                  startAdornment: <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>,

                }}
              />
              {/* @ts-ignore */}
              <ColorButton size="small" test-id="click1" onClick={this.handleAddNoteOpenModal}>+ Add New Note</ColorButton>

            </Box>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "25px", marginLeft: "0px" }}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={5} style={{ borderRadius: "5px", marginRight: "15px" }}>
                <Box style={{ padding: "20px" }}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>

                  </Box>
                  <NoteTabs handleClick_title={this.handleClick_title} handleClick_course={this.handleClick_course} data={allNotesData.data} isloading={isloading} handleTab={this.handleTab} handleEdit={handleNoteEdit} />
                </Box>
                <Box style={{
                  display: 'flex',
                  marginLeft: '20%',
                  paddingBottom: '15px'
                }}>
                  <Pagination
                    count={this.state.allNotesData?.meta?.pagination?.total_pages}
                    page={this.state.page}
                    color="primary"
                    onChange={this.pagination}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>


            </Grid>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={this.state.isModalOpen}
          // @ts-ignore
          onClose={this.handleAddNoteCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={this.state.isModalOpen}>
            <AddNoteModal handleClose={this.handleAddNoteCloseModal} editFormData={editData} navigation={undefined} id={""} getAllData={undefined} />
          </Fade>
        </Modal>



      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Startp
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#6C328B",
    color: "white",
    borderRadius: "16px",
    borderColor: "#6C328B",
    textTransform: 'capitalize',
    width: '188px',
    height: '48px',
    fontSize: '16px',
    '&:hover': {
      borderColor: "#6C328B",
      color: "white",
      backgroundColor: "#6C328B",
    },
  },
}))(Button);

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const AntTabs:any = withStyles({
  root: {
    borderBottom: '0px solid #e8e8e8',
    marginTop: "10px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);


const AntTab:any = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Montserrat, sans-serif',
    ].join(','),
    '&:hover': {
      color: '#6c328b',
      opacity: 1,
    },
    '&$selected': {
      color: '#6c328b',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#6c328b',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const NoteTabs = ({ handleClick_title, data, isloading, handleTab, handleEdit, handleClick_course }: { handleClick_course: any, data: any, isloading: any, handleTab: any, handleEdit: any, handleClick_title: any }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [notesId, setNotesId] = React.useState(1);
  const [tempData, setTempData] = React.useState<any>([]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handleTab(newValue);
  };
  const handleNoteListClick = (id: any) => {
    //@ts-ignore 
    console.log("clickedd--->", id)
    setNotesId(id)

    let dummydata = data?.filter((item: any) => {
      return item.id === id
    })
    setTempData(dummydata)


    //@ts-ignore
  }



  useEffect(() => {

    setTempData(data && data.length > 0 ? [data[0]] : [])

  }, [data])

  return (
    <>
      <div>
        <AntTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {/* @ts-ignore */}
          <AntTab label="All Notes" {...a11yProps(0)} />
          {/* @ts-ignore */}
          <AntTab label="Personal Notes" {...a11yProps(1)} />
          {/* @ts-ignore */}
          <AntTab label="Course Notes" {...a11yProps(2)} />
        </AntTabs>
      </div>
      <div className={classes.root} style={{ display: "flex" }}>

        <Grid item xs={12} sm={12}>

          <TabPanel value={value} index={0}>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={4}>
                {
                  isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :

                    data && data.length > 0 ? data.map((item: any, key: any) => {
                      return (
                        <Grid item xs={12} key={key}
                          className={`box ${notesId === item.id && item.attributes.is_private === false
                            ? "public-active-border"
                            : notesId === item.id && item.attributes.is_private === true
                              ? "private-active-border"
                              : ""
                            }`}>
                          {item.attributes.note_type === "personal" ?
                            <Paper elevation={1} style={{ height: "100px", display: 'flex', marginBottom: "15px", borderRadius: '10px' }}>
                              {
                                item.attributes.is_private ?
                                  <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                  <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                              }
                              <Box style={{ width: '100%', padding: '10px 0 0 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                //@ts-ignore
                                id={`card${item.id}`}
                                className={`box ${notesId === item.id && item.attributes.is_private === false
                                  ? "public-active-box"
                                  : notesId === item.id && item.attributes.is_private === true
                                    ? "private-active-box"
                                    : ""
                                  }`}
                                test-id="click2"
                                onClick={() => handleNoteListClick(item.id)}>
                                <Box display="flex" style={{ alignItems: 'center' }}>

                                  <Box>
                                    <Typography variant="h6" className="profileName">
                                      {item.attributes.is_private ? `${item.attributes.title} - Personal` : `${item.attributes.title} - Public`}</Typography>

                                    <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                      <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "10px" }}>
                                      </Typography>
                                      {/* <Typography variant="subtitle1" color="textSecondary">
                                        {
                                          item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description
                                        }
                                      </Typography> */}
                                      <Typography dangerouslySetInnerHTML={{ __html: item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description }}></Typography>
                                    </Box>

                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton>
                                    <ArrowForwardIosIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Paper>
                            :
                            <Paper elevation={1} style={{ height: "100px", display: 'flex', marginBottom: "15px", borderRadius: '10px' }}>
                              {
                                item.attributes.is_private ?
                                  <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                  <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                              }
                              <Box style={{ width: '100%', padding: '10px 0 0 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                //@ts-ignore
                                id={`card${item.id}`}
                                className={`box ${notesId === item.id && item.attributes.is_private === false
                                  ? "public-active-box"
                                  : notesId === item.id && item.attributes.is_private === true
                                    ? "private-active-box"
                                    : ""
                                  }`}
                                test-id="click3"
                                onClick={() => handleNoteListClick(item.id)}>
                                <Box display="flex" style={{ alignItems: 'center' }}>

                                  {item.attributes.image ? (
                                    <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                  ) : (
                                    <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                  )}
                                  <Box>
                                    <Typography variant="h6" className="profileName">
                                      {item.attributes.is_private ? `${item.attributes.title}` : `${item.attributes.title}`}                                  </Typography>
                                    <Box style={{ display: 'flex', marginTop: "5px" }}>
                                      <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "10px" }}>
                                      </Typography>
                                      <Typography variant="subtitle1" className="profileName">
                                        {item.attributes.note_type}
                                      </Typography>
                                    </Box>

                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton>
                                    <ArrowForwardIosIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Paper>
                          }
                        </Grid>
                      )
                    }) :
                      <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>
                }
              </Grid>
              <Grid item xs={12} sm={8}>
                <Paper elevation={1} style={{ borderRadius: "10px", display: 'flex', marginTop: "-20px" }}>
                  {isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    tempData && tempData.length > 0 ? tempData.map((tempItem: any, key: any) => {

                      return (
                        <Box style={{ padding: "25px", width: "100%" }} key={key}>

                          <Box style={{ display: "flex", flexDirection: "row", padding: "5px", justifyContent: "space-between" }}>
                            <Box style={{ display: "flex" }}>
                              <Typography variant="h5" style={{ fontWeight: "bold", color: "#171725" }} >
                                {//@ts-ignore
                                  tempItem?.attributes.title}
                              </Typography>
                              <Typography style={{ padding: "10px", fontSize: '12px', textDecoration: 'underline' }} color="textSecondary">
                                + Add this note to assignment
                              </Typography>
                            </Box>
                            <Box style={{ marginLeft: '35%', marginTop: '4px' }}>
                              {/* <img src={msg_Image} style={{ width: "100%", height: "100%", paddingTop: "10px", cursor: 'pointer' }} /> */}
                            </Box>
                            <Box>
                              <img src={edit} test-id="click4" style={{ width: "100%", height: "85%", paddingTop: "12px", cursor: 'pointer' }} onClick={() => handleEdit(tempItem)} />
                            </Box>
                          </Box>
                          <Box style={{ margin: "15px 0px" }}>

                            {tempItem?.attributes.image ? (
                              <Box style={{ margin: "15px 0px", height: "180px", overflow: 'hidden' }}>
                                <img src={tempItem?.attributes.image.url} style={{ width: "100%", height: "350px", borderRadius: '16px' }} alt="Image" />
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box style={{ padding: "5px" }}>
                            <Typography dangerouslySetInnerHTML={{ __html: tempItem.attributes.description }}></Typography>
                          </Box>



                        </Box>)
                    }) :
                      <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>
                  }
                </Paper>

              </Grid>
            </Grid>   
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={4}>
                {
                  isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    data && data.length > 0 ? data.map((item: any, key: any) => {

                      return (
                        <Grid item xs={12} key={key}
                          className={`box ${notesId === item.id && item.attributes.is_private === false
                            ? "public-active-border"
                            : notesId === item.id && item.attributes.is_private === true
                              ? "private-active-border"
                              : ""
                            }`}>
                          <Paper elevation={1} style={{ height: "100px", display: 'flex', marginBottom: "15px", borderRadius: '10px' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '10px 0 0 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              //@ts-ignore
                              id={`card${item.id}`}
                              className={`box ${notesId === item.id && item.attributes.is_private === false
                                ? "public-active-box"
                                : notesId === item.id && item.attributes.is_private === true
                                  ? "private-active-box"
                                  : ""
                                }`}
                              test-id="click5"
                              onClick={() => handleNoteListClick(item.id)}>
                              <Box display="flex" style={{ alignItems: 'center' }}>

                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.title}
                                  </Typography>

                                  <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                    <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "10px" }}>
                                    </Typography>
                                    {/* <Typography variant="subtitle1" color="textSecondary">
                                      {
                                        item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description
                                      }
                                    </Typography> */}
                                    <Typography dangerouslySetInnerHTML={{ __html: item?.attributes?.description?.length > 30 ? `${item?.attributes?.description?.substring(0, 30)}...` : item?.attributes?.description }}></Typography>
                                  </Box>

                                </Box>
                              </Box>
                              <Box>
                                <IconButton>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      )
                    }) :
                      <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>
                }
              </Grid>
              <Grid item xs={12} sm={8}>
                <Paper elevation={1} style={{ borderRadius: "10px", display: 'flex', marginTop: "-20px" }}>
                  {
                    isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                      tempData && tempData.length > 0 ? tempData.map((tempItem: any, key: any) => {
                        return (
                          <Box style={{ padding: "25px", width: "100%" }} key={key}>

                            <Box style={{ display: "flex", flexDirection: "row", padding: "5px", justifyContent: "space-between" }}>
                              <Box style={{ display: "flex" }}>
                                <Typography variant="h5" style={{ fontWeight: "bold", color: "#171725" }} >
                                  {//@ts-ignore
                                    tempItem?.attributes.title}
                                </Typography>
                                <Typography style={{ padding: "10px", fontSize: '12px', textDecoration: 'underline' }} color="textSecondary">
                                  + Add this note to assignment
                                </Typography>
                              </Box>
                              <Box>
                                <img test-id="click6" src={edit} style={{ width: "30px", height: "33px", paddingTop: "10px", cursor: 'pointer' }} onClick={() => handleEdit(tempItem)} />
                              </Box>

                            </Box>
                            <Box style={{ margin: "15px 0px" }}>

                              {tempItem?.attributes.image ? (
                                <Box style={{ margin: "15px 0px", height: "180px", overflow: 'hidden' }}>
                                  <img src={tempItem?.attributes.image.url} style={{ width: "100%", height: "350px", borderRadius: '16px' }} alt="Image" />
                                </Box>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box style={{ padding: "5px" }}>
                              {/* <Typography>{
                                //@ts-ignore
                                tempItem?.attributes.description}</Typography> */}
                              <Typography dangerouslySetInnerHTML={{ __html: tempItem.attributes.description }}></Typography>
                            </Box>



                          </Box>)
                      }) :
                        <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>

                  }
                </Paper>

              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={4}>
                {
                  isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    data && data.length > 0 ? data.map((item: any, key: any) => {

                      return (
                        <Grid item xs={12} key={key}
                          className={`box ${notesId === item.id && item.attributes.is_private === false
                            ? "public-active-border"
                            : notesId === item.id && item.attributes.is_private === true
                              ? "private-active-border"
                              : ""
                            }`}>

                          <Paper elevation={1} style={{ height: "100px", display: 'flex', marginBottom: "15px", borderRadius: '10px' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '10px 0 0 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              //@ts-ignore
                              id={`card${item.id}`}
                              className={`box ${notesId === item.id && item.attributes.is_private === false
                                ? "public-active-box"
                                : notesId === item.id && item.attributes.is_private === true
                                  ? "private-active-box"
                                  : ""
                                }`}
                              test-id="click7"
                              onClick={() => handleNoteListClick(item.id)}>
                              <Box display="flex" style={{ alignItems: 'center' }}>

                                {item.attributes.image ? (
                                  <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                ) : (
                                  <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                )}
                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.is_private ? `${item.attributes.title}` : `${item.attributes.title}`}</Typography>

                                  <Box style={{ display: 'flex', marginTop: "5px" }}>
                                    <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: "10px" }}>
                                    </Typography>
                                    <Typography variant="subtitle1" className="profileName">
                                      {item.attributes.note_type}
                                    </Typography>
                                  </Box>

                                </Box>
                              </Box>
                              <Box>
                                <IconButton>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      )
                    }) :
                      <Box style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>

                }
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box style={{ display: "flex" }}>
                  <Grid item xs={12} sm={4}>
                    <TabPanel value={value} index={2}>
                      <Grid container spacing={3} style={{ marginTop: "-35px" }}>
                        {
                          tempData.map((item: any, key: any) => {
                            console.log(item, 'video_list')
                            return (
                              <Grid item xs={12} key={key}
                              >
                                <Card style={{ display: 'flex' }}>
                                  {
                                    key % 2 === 0 ?
                                      <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                      <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                                  }
                                  <Box style={{ width: '100%', padding: '14px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                  //@ts-ignore
                                  >
                                    <Box display="flex" style={{ alignItems: 'center', flexDirection: "column" }}>
                                      <Box>
                                        <Grid container style={{ paddingBottom: '0px' }}>
                                          <Grid item lg={6}>{
                                            <img test-id="click8" onClick={() => handleClick_title(item)} src={course} height="30px" width="30px" style={{ marginRight: "10px", cursor: 'pointer' }} />
                                          }</Grid>
                                          <Grid item lg={6}>
                                            <Box style={{ float: 'right', marginTop: '-8px' }}>
                                              <IconButton test-id="click9" onClick={() => handleClick_course(item)}>
                                                <ArrowForwardIosIcon />
                                              </IconButton>
                                            </Box></Grid>
                                        </Grid>

                                        <Box>
                                          <Box>
                                            <Typography style={{ fontSize: '17px' }} className="profileName">
                                              Chapter 1: {item.attributes.chapter_title}
                                            </Typography>

                                            {

                                              <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                                <Typography style={{ fontSize: '13px', color: '#0D0A19' }}>
                                                  {item.attributes.chapter_description}
                                                </Typography>
                                              </Box>
                                            }
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>

                                  </Box>
                                </Card>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </TabPanel>
                  </Grid>
                  <Grid item xs={12} sm={8}>

                    <Paper elevation={1} style={{ borderRadius: "10px", display: 'flex', marginTop: "-20px" }}>
                      {
                        isloading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                          tempData && tempData.length > 0 ? tempData.map((tempItem: any, key: any) => {
                            return (
                              <Box style={{ padding: "25px", width: "100%" }} key={key}>

                                <Box style={{ display: "flex", flexDirection: "row", padding: "5px", justifyContent: "space-between" }}>
                                  <Box style={{ display: "flex" }}>

                                    <Typography variant="h5" style={{ fontWeight: "bold", color: "#171725" }} >
                                      {//@ts-ignore
                                        tempItem?.attributes.title}
                                    </Typography>
                                    <Typography style={{ padding: "10px", fontSize: '12px', textDecoration: 'underline' }} color="textSecondary">
                                      + Add this note to assignment
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <img test-id="click10" src={edit} style={{ width: "30px", height: "33px", paddingTop: "10px", cursor: 'pointer' }} onClick={() => handleEdit(tempItem)} />
                                  </Box>

                                </Box>
                                <Box style={{ margin: "15px 0px" }}>
                                  {
                                    tempItem?.attributes.image === null ? '' :
                                      <img src={tempItem.attributes.image.url} style={{ width: "100%", height: "500px" }} />
                                  }
                                </Box>
                                <Box style={{ padding: "5px" }}>
                                  {/* <Typography>
                                    {tempItem?.attributes.description.length > 30 ? `${tempItem?.attributes.description.substring(0, 30)}...` : tempItem?.attributes.description}
                                  </Typography> */}
                                  <Typography dangerouslySetInnerHTML={{ __html: tempItem.attributes.description?.length > 100 ? `${tempItem.attributes.description.substring(0, 100)}...` : tempItem.attributes.description }}></Typography>
                                </Box>



                              </Box>)
                          }) :
                            <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>
                      }
                    </Paper>

                  </Grid>
                </Box>
              </Grid>
            </Grid>


          </TabPanel>
        </Grid>

      </div>
    </>

  );
}
// Customizable Area End
