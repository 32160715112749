export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const b6662536892a73acf765efdf133aaead0cf7b054 = require("../assets/b6662536892a73acf765efdf133aaead0cf7b054.png");
export const backgroundImage = require("../assets/backgroundImage.png");
export const Oval = require("../assets/Oval.png");
export const LoginImage = require("../assets/left_background_1.png");
export const edit = require("../assets/edit.svg");
export const Caret1 = require("../assets/Caret1.svg");
export const Caret2 = require("../assets/Caret2.svg");
export const searchIcon = require("../assets/searchIcon.png");
export const message = require("../assets/message.svg");
export const share = require("../assets/share.svg");
export const Download = require("../assets/Download.svg");
export const SelectArrow = require("../assets/SelectArrow.svg");
export const Edit = require("../assets/edit.png");
export const SearchIcon = require("../assets/search.png");
export const EditUsermgmtIcon = require("../assets/edit_user_mgmt.png");
