Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.gradeAPiEndPoint = "bx_block_question_bank/grades";
exports.downloadTemplateAPiEndPoint = "bx_block_question_bank/question_banks/download_template?grade_id=";
exports.uploadTemplateAPiEndPoint = "bx_block_question_bank/question_banks";
exports.questionBankObjectListAPiEndPoint = "bx_block_question_bank/questions/question_bank_wise_questions?question_bank_id=";
exports.questionBankListAPiEndPoint = "bx_block_question_bank/question_banks";
exports.ageListAPiEndPoint = "age_groups";
exports.exampleAPiMethod = "POST";
exports.multiPartAPiMethod = "multipart/form-data";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";
exports.noDataFound = "No Data Found";
exports.btnExampleTitle = "CLICK ME";
exports.listOfQuestionBank = "List of Question Bank";
exports.QuestionBankTitle = "Question Bank";
exports.Url = "https://prominlabs-225755-ruby.b225755.dev.eastus.az.svc.builder.cafe";
// Content types
exports.applicationJson = "application/json";
// Http methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDelMethod = "DELETE";
// Create question
exports.createQuestionUrl = "bx_block_questionbank/quize_questions";
// Create quiz
exports.quizUrl = "bx_block_questionbank/quizzes";
// Bulk delete questions
exports.bulkDeleteQuestionsUrl = "bx_block_questionbank/quize_questions/bulk_delete";
// Bulk actions
exports.bulkActionUrl = "bx_block_questionbank/quize_questions/bulk_action";
// Customizable Area End