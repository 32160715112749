Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.validationApiContentType = "application/json";
  exports.PostApiContenType = "multipart/form-data";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "CustomisableUserProfiles";
  exports.labelBodyText = "CustomisableUserProfiles Body";
  
  // USER DETAILS API END POINT
  exports.getUserDetailsAPiEndPoint = "/account_block/account";
  exports.getUserDetailsAPiMethod = "GET";
  exports.getUserDetailsApiContentType = "application/json";
  
  // MY PROFILE                       // /bx_block_customisableuserprofiles/profiles
  exports.getMyProfileAPiEndPoint = "/bx_block_customisableuserprofiles/profiles";
  exports.getMyProfileModalAPiEndPoint = "/account_block/account"
  exports.getMyInterestDataAPiEndPoint = "/account_block/account/my_interest";
  exports.getMyProfileAPiMethod = "GET";
  exports.getMyProfileApiContentType = "application/json";
  exports.updateMyProfileApiEndPoint = "/account_block/account/update_profile"
  exports.updateMyProfilePasswordApiEndPoint = "/account_block/account/change_password"
  
  
  // MY PROFILE REWARDS
  exports.getMyProfileRewardsAPiEndPoint = "/bx_block_customisableuserprofiles/profiles/rewards";
  exports.getMyProfileRewardsAPiMethod = "GET";
  exports.getMyProfileRewardsApiContentType = "application/json";
  
  // All Notes End Point
  exports.getAllNotesAPiEndPoint = "bx_block_notes/notes";
  exports.getAllNotesAPiMethod = "GET";
  exports.getAllNotesApiContentType = "application/json";
  
  // Recent Insights End Point
  exports.getRecentInsightsAPiEndPoint = "/bx_block_customisableuserprofiles/recent_insights";
  exports.getRecentInsightsAPiMethod = "GET";
  exports.getRecentInsightsApiContentType = "application/json";
  
  // Recent Activity End Point
  exports.getRecentActivityAPiEndPoint = "/bx_block_activitylog/activity_logs";
  exports.getRecentActivityAPiMethod = "GET";
  exports.getRecentActivityApiContentType = "application/json";
  
  // List Watched End Point
  exports.getListWatchedAPiEndPoint = "/bx_block_adhocreporting/course_video_track";
  exports.getAllVideoWatchDataEndPoint = "/bx_block_adhocreporting/course_video_track/video_web_track"
  
  // add time spent traning track api
  exports.getAddTimeSpentAPiEndPoint = "/bx_block_adhocreporting/training_track";
  
  // List Time End Point
  exports.getListTimeAPiEndPoint = "/bx_block_adhocreporting/training_track";
  exports.getTimeSpanddataAPiEndPoint = "/bx_block_adhocreporting/training_track/web_track"
  
  // all report End Point
  exports.getAllReportsEndPoint = "/bx_block_adhocreporting/course_video_track/all_report"
  
  // MY PROFILE Insights Overview
  exports.getMyProfileRewardsAPiEndPoint = "/bx_block_customisableuserprofiles/insights";
  exports.getMyProfileRewardsAPiMethod = "GET";
  exports.getMyProfileRewardsApiContentType = "application/json";
  
  // MY PROFILE Recent Insights 
  exports.getMyProfileInsightsAPiEndPoint = "{{site_url}}/bx_block_customisableuserprofiles/recent_insights";
  exports.getMyProfileInsightsAPiMethod = "GET";
  exports.getMyProfileInsightsApiContentType = "application/json";
  
  exports.btnExampleTitle = "CLICK ME";
  exports.MyNotes = "My Notes";
  exports.newNote = "New Note";
  exports.all = "All";
  exports.personale = "Personal";
  exports.course = "Course";
  exports.deleteNoteEndPoint = "bx_block_notes/notes/"
  exports.deleteApiMethod = "DELETE"
  exports.addPrsonalNoteEndPoint = "bx_block_notes/notes"
  exports.achievements = "Achievements"
  exports.rewards = "Rewards"
  exports.Incomplete = "Incomplete"
  exports.Inprogress = "In-Progress"
  exports.notStarted = "Not Started"
  exports.Completed = "Completed"
  exports.rewardAchievEndPoint = "bx_block_account_groups/profiles/rewards_achievements_for_profile"
  exports.noRewardText = "The achievements and rewards will appear based on the content you are assigned"
  exports.rewardredeem = "Rewards redeemed"
  exports.notYet = "None yet"
  exports.dismiss= "Dismiss"
  exports.popupCloseEndPoint = "bx_block_account_groups/profiles/dismiss_popup"
  exports.hey = "Hey"
  exports.rewardEmailNotify = "You’ll receive an email to redeem your reward."
  exports.resetPasswordViaEmailEndPoint = "/account_block/account/update_password";
  exports.varifyPasswordEndPoint = "account_block/account/otp_confirm_password";
  exports.CountryFlagApiEndPoint = "account_block/accounts/country_code_and_flags";
// Customizable Area End
