// Customizable Area 
import React from "react";

import {
  Button,
  InputLabel,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Card,

} from "@material-ui/core";

//@ts-ignore
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Publish, Close } from "@material-ui/icons";
import { Formik } from 'formik';
import * as yup from 'yup';
import CourseCreationController, {
} from "./CourseCreationController.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DynamicTitle from "../../../components/src/DynamicTitle";
import CloseIcon from "@material-ui/icons/Close";
import './CourseCreation.css';
import { Plus, upload, Vector } from "./assets";
import { MdOutlineLocationSearching } from "react-icons/md";
//@ts-ignore
const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });
//@ts-ignore
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
class AddReward extends CourseCreationController {
  constructor(props: any) {
    super(props);
  }
  selected(index: any) {
    //@ts-ignore
    if (this.state.data.some((item) => item.selected === true)) {
      //@ts-ignore
      this.state.data[index].selected = !this.state.data[index].selected;
      this.setState({ data: this.state.data });
    }
  }
  add() {
    //@ts-ignore
    this.state.data.push({ content: "", selected: false, file: "" });
    //@ts-ignore
    this.setState({ data: this.state.data });
  }

  render() {
    const onImageChange = (event: any) => {
      this.setState({
        rewardImg: event.target.files[0]
      })

    }


    const checkMessage = (content: any) => {
      if (content == "") {
        return 'Add Object'
      } else if (content.length > 30) {
        return `${content.substring(0, 30)}...`
      } else {
        return content
      }
    }

    return <>
      <DynamicTitle title="Add Reward" />
      <div className="add-reward">
        <Formik
          initialValues={{
            name: "",
            description: "",

          }}
          data-test-id="formik-add-award"
          onSubmit={(values) => {
            this.addReward(values);

          }}
          validationSchema={yup.object().shape({
            name: yup.string().required('Please enter name')
              .min(5, 'Name is too short (minimum is 5 characters)'),
            description: yup.string().required('Please enter description')
              .min(50, 'Description is too short (minimum is 150 characters)')
              .max(1500, 'Description is too long (maximum is 1500 characters)'),
          })}

        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Grid container md={12} xs={12} spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      placeholder="Reward name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                  </Grid>
                </Grid>
                <Grid container md={12} xs={12} spacing={4}>
                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="reward-desc" className="input-label" style={{ marginBottom: "10px" }}>Reward Description</InputLabel>
                    <TextField
                      fullWidth
                      id="course_desc"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      variant="outlined"
                      placeholder="Please write your description here"
                      multiline
                      rows={10}
                      maxRows={12}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                </Grid>

                <Grid item container md={12} xs={12} spacing={4}>
                  <Grid item container md={5} xs={12}>
                    <p className="inner-heading">Please add content for this reward</p>

                    <div className="outer-border">
                      {
                        //@ts-ignore
                        this.state.data.map((item, index) => (

                          <>
                            <Grid item md={12} xs={12}>
                              <div className="inner-container" >
                                <div className="inner-border" data-test-id = {`selected_index-${index}`} style={{ cursor: "pointer" }} onClick={() => {
                                  this.selected(index);
                                }}>

                                  <p data-test-id="checkMessage" className="inner-text" >{checkMessage(item.content)}</p>
                                  {
                                    item.file === "" ?
                                      <img src={Vector} /> : <img style={{ width: '40px' }} src={URL.createObjectURL(item.file)} />
                                  }
                                </div>
                              </div>
                            </Grid>

                          </>
                        ))
                      }
                      <Grid item md={12} xs={12}>
                        <div className="inner-container">
                          <Button data-test-id="add_more_btn" className="add-more-btn" onClick={() => this.add()}>+ Add more</Button>
                        </div>
                      </Grid>
                    </div>

                  </Grid>
                  <Grid item container md={7} xs={12} >
                    <p className="inner-heading">Upload Thumbnail</p>
                    <div className="dashed-border">
                      <Grid item md={1}></Grid>
                      <Grid item md={5} xs={12}>
                        <div data-test-id="btn_tostSuccessfully" onClick={() => this.tostSuccessfully()} className="inner-border2">
                          <img src={Plus} height="50" />
                          <p className="text">
                            Choose from icon Library
                          </p>
                        </div>

                      </Grid>
                      <Grid item md={1} >
                        <p className="or-text">Or</p>
                      </Grid>


                      <Grid item md={5} xs={12}>
                        <div className="inner-border2" >
                          <label htmlFor="uploadFile" style={{ display: "block" }}>
                            <input type="file" name="files" id="uploadFile" style={{ visibility: "hidden", display: 'none' }} data-test-id="onImageChange_file" accept="image/*" onChange={onImageChange} />

                            {this.state.rewardImg == "" ? <img src={upload} /> : <img width={200} src={URL.createObjectURL(this.state.rewardImg)} />}

                          </label>
                          <p className="text">
                            {this.state.rewardImg == "" ? "Upload from device" : 'Edit Image'}
                          </p>

                        </div>
                      </Grid>
                      <Grid item md={1}></Grid>

                    </div>
                  </Grid>
                </Grid>
                <Grid container md={12} xs={12}>
                  <Grid item md={12} xs={12} style={{ padding: "30px", textAlign: "center" }}>
                    <Button className="create-reward-btn" type="submit">Create Reward</Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>


      </div>

    </>
  }
}
export default AddReward;
// Customizable Area End