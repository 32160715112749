import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const OutlinedButton = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button {...props} variant="outlined" className={classes.outlinedBtn}>
      {props.children}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outlinedBtn: {
      color: "#202020",
      fontSize: "15px",
      fontWeight: 600,
      textTransform: "capitalize",
      border: "2px solid #202020",
      borderRadius: "16px",
      padding: "10px",
      [theme.breakpoints.up("sm")]: {
        padding: "14px 26px",
        fontSize: "18px",
      },
    },
  })
);
