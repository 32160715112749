import React from "react";

import {
  Box,
  // Customizable Area Start
  Dialog, DialogTitle, DialogContent, Popover, Typography, DialogActions,
  Checkbox,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { Filter, BackArrow } from "./assets";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../../components/src/CustomTableV3";
const headershow = [
  { id: 1, label: "Name", sort: true, dataType: "string", key: "name" },
  { id: 2, label: "ID", sort: true, dataType: "string", key: "id" },
  { id: 3, label: "# of Lessons", sort: true, dataType: "string", key: "uniq_id" },
  { id: 4, label: "Last Modified", sort: true, dataType: "date", key: "updated_at" },
  { id: 5, label: "Status", sort: true, dataType: "string", key: "status" },
]
import FilterItems from "../../filteritems/src/FilterItems.web"
import Loader from "../../../components/src/Loader"
import { thumbnailImg } from "../../../blocks/teambuilder/src/assets";
import { DownArrow, UpArrow } from "../../../blocks/imagemanagement2/src/assets";
// Customizable Area End

import CommonAddContentRoleController, {
  Props
} from "./CommonAddContentRoleController.web"

export default class CommonAddContentRoleDialogue extends CommonAddContentRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  statusOptionData = (status: string) => {
    if (status === "active") {
      return webStyle.statusNew
    } else if (status === "draft") {
      return webStyle.statusDraft
    } else {
      return webStyle.statusArchived
    }
  }
  renderTableBody = () => {
    return (
      <>
        {this.state.contentRoleList?.map((contentRole) => {
          const BgColors = this.state.selectedContentIds.includes(Number(contentRole.id)) ? true : false
          return (
            <Tr key={contentRole.id} rowBg={BgColors} data-test-id={`checkbox_${contentRole.id}`} RowClick={() => this.RowClick(Number(contentRole.id))}>
              <Td rowBg={BgColors}>
                <StyledCheckBox
                  aria-labelledby="demo-error-radios"
                  name="quiz"
                  checked={this.state.selectedContentIds.includes(Number(contentRole.id))}
                  onChange={() => this.RowClick(Number(contentRole.id))}
                  data-test-id={`row_${contentRole.id}`}
                />
              </Td>
              <Td rowBg={BgColors}>
                  {contentRole.attributes.title}
              </Td>
              <Td rowBg={BgColors}>
                {contentRole.attributes.uniq_id}
              </Td>
              <Td rowBg={BgColors}>
                  {contentRole.attributes.lessons_count}
              </Td>
              <Td rowBg={BgColors}>
                {contentRole.attributes.updated_at
                  ? this.formatDate(contentRole.attributes.updated_at)
                  : "-"}
              </Td>
              <Td rowBg={BgColors}>
                <span style={this.statusOptionData(contentRole.attributes.status)}>
                  {contentRole.attributes.status}
                </span>
              </Td>
            </Tr>)
        })}
      </>
    )
  }
  advancedSearch = () => {
    const openFilterContainer = Boolean(this.state.anchorFilterContentEl)
    return (<StyledContentWrapper>
      <AdvancedSearch
        data-test-id="searchAddContentRoleDialogue"
        searchInputValue={this.state.searchInputValue}
        handleInputChange={this.handleInputChangeSearch}
        handleListItemClick={this.handleListItemClickSuggestion}
        filterList={this.state.filteredList}
        inputWidth={window.innerWidth < 800 ? "240" : "480"}
        isFilterDropActive={openFilterContainer}  
        showList={this.state.showList} 
        navigation={undefined} id={""} />
      <div style={{ marginLeft: "0px" }} 
      className={this.state.anchorFilterContentEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
        {this.state.creationDate || this.state.selectedStatus ? (
          <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
          ""
        )
        }
        {this.state.anchorFilterContentEl ? (
          <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
        ) : (
          <img src={NewFilter} data-test-id="filterOpenTestID" />
        )}
      </div>
      <Popover
        data-test-id="filterPropsID"
        className="filterContainerPopover"
        open={openFilterContainer}
        id="myPopover"
        anchorEl={this.state.anchorFilterContentEl}
        onClose={this.filterContainerDropdownCloseHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}

      >
        <FilterItems
          navigation={this.props.navigation}
          data-test-id="FilterItemsTestID"
          isContentRole={true} 
          anchorEl={this.state.anchorEl} 
          dropdownType={this.state.dropdownType} 
          dropdownOpenHandler={this.dropdownHandlerOpen} 
          dropdownCloseHandler={this.dropdownHandlerClose} 
          
          selectedStatus={this.state.selectedStatus}
          handleChangeStatus={this.handleCatalogueChangeStatus}
          
          isCalendarOpen={this.state.isCalendarOpen}
          creationDate={this.state.creationDate}
          handleCalendarOpen={this.handleOpenCalendor} 
          creationDateChangeHandler={this.creationChangeHandlerDate} 
          handleCalendarChange={this.handleChangeCalendor}
          handleClick={this.handleClickFilter} 
          handleClear={this.handleClearFilter} 
          placeholderSelectedItems={[]} 
        />
      </Popover>
    </StyledContentWrapper>)

  }

  renderContent = () => {
    if (this.state.isLoading) {
      // Loader that will be shown until the thumbnails are fetched
      return <Loader loading={this.state.isLoading} />
    } else if (!this.state.isLoading && this.state.contentRoleList && this.state.contentRoleList.length > 0) {
      return (
        <>
          <DialogContent>
            <TableWrapper>
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                    </Th>
                    {headershow?.map((header: { id: number, label: string, sort: boolean, dataType: string, key: string }) => {
                      return (
                        <Th>
                          <p className="headingLabel">
                            {header.label}{" "}
                            <span className="arrowIconsContainer">
                              {header.sort && (
                                <>
                                  <img
                                    data-test-id={`sort_asc${header.label}`}
                                    src={DownArrow}
                                    width="12px"
                                    onClick={() =>
                                      this.sortContentList(
                                        header?.dataType ?? "",
                                        header?.key ?? "",
                                        "asc"
                                      )
                                    }
                                  />

                                  <img
                                    data-test-id={`sort_desc${header.label}`}
                                    src={UpArrow}
                                    width="12px"
                                    onClick={() =>
                                      this.sortContentList(
                                        header?.dataType ?? "",
                                        header?.key ?? "",
                                        "desc"
                                      )
                                    }
                                  />
                                </>
                              )}
                            </span>
                          </p>
                        </Th>
                      )
                    })}

                  </Tr>
                </Thead>
                <Tbody>
                  {this.renderTableBody()}
                </Tbody>
              </Table>
            </TableWrapper>
          </DialogContent>
          <DialogActions className="btnWrapper">
            <CancelButton
              onClick={() => { this.handleCancel() }}
              data-test-id="cancel_content_ids"
            >
              Cancel
            </CancelButton>
            <SelectAddContentButton
              onClick={() => this.handleSelectctedContentIds()}
              disabled={!this.state.selectedContentIds.length}
              data-test-id="select_content_ids"
            >
              Select
            </SelectAddContentButton>
          </DialogActions>
        </>
      )
    } else {
      return (
        <  AddIconNotFoundBoxs>
          <AddIconInnerBoxs>
            <Box>
              <AddImageComponents src={thumbnailImg} />
            </Box>
            <AddIconNotFoundTexts>No results</AddIconNotFoundTexts>
            <TryToWidenText>Try and widen your search</TryToWidenText>
            <DialogActions className="btnWrapper">
            <CancelButton
              onClick={() => { this.handleCancel() }}
              data-test-id="cancel_content_btn"
            >
              Cancel
            </CancelButton>
            <SelectAddContentButton
              disabled={true}
              data-test-id="select_content_btn"
            >
              Select
            </SelectAddContentButton>
          </DialogActions>
          </AddIconInnerBoxs>
        </  AddIconNotFoundBoxs>
      )
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box>
          <DialogWrapper
            open={this.props.displayAddContentRoleDialogue}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
              <Box className="heading_wrapper">
                <Box className="heading_inner">
                  <span style={{ cursor: "pointer" }}>
                    <img src={BackArrow} style={{ cursor: "pointer" }} alt="back_ing" onClick={() => this.handleCancel()} />
                  </span>
                  <p className="heading_text">
                    Select Content Roles
                  </p>
                </Box>
              </Box>
              {this.advancedSearch()}
            </DialogTitle>
            {this.renderContent()}
          </DialogWrapper>
        </Box>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AddImageComponents = styled("img")({
  maxWidth: "auto",
  maxHeight: "350px",
});

const AddIconNotFoundBoxs = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const AddIconInnerBoxs = styled(Box)({
  marginTop: "18px"
});

const AddIconNotFoundTexts = styled(Typography)({
  margin: "32px 0 16px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
});
const TryToWidenText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
  color: "#787776 !important",
  margin: "0 0 36px",
});
const BadgeComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});
const DialogWrapper = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: "1275px",
    width: "100% !important",
    height: "100%",
    borderRadius: "16px",
    padding: "30px",
    marginLeft: "96px",
    marginTop: "35px",
    "@media(max-width:600px)": {
      margin: "10px",
      marginTop: "80px"
    },
  },
  "& .heading_wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "@media(max-width:1120px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "20px"
    },
    "@media(max-width:600px)": {
      alignItems: "center",

    },
  },
  "& .heading_inner": {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },
  "& .heading_text": {
    fontSize: "40px",
    fontWeight: 700,
    fontFamily: "Poppins",
    margin: 0,
    padding: 0,

    "@media(max-width:1250px)": {
      fontSize: "35px",
    },
    "@media(max-width:600px)": {
      fontSize: "22px",
    },
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .btn": {
    marginTop: "30px",
    width: "228px",
    height: "56px",
    backgroundColor: "#E5E2E1",
    border: "0px",
    borderRadius: "16px",
    color: "#787776",
    fontFamily: "'Poppins', sans-serif" as const,
    fontSize: "16px",
    fontWeight: 600 as const,
    textTransform: "capitalize" as const,
  },
  "& .enablebtn": {
    backgroundColor: "#652786 !important",
    cursor: "pointer",
    color: "#fff"
  },
  "& .headingLabel": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "start",
    minWidth: "200px",
    "@media(max-width:600px)": {
      fontSize: "18px",
      minWidth: "140px"
    },
  },
  "& .arrowIconsContainer": {
    display: "flex",
    flexDirection: "column",
    margin: "3px 0px 0px 10px",
    "& > img": {
      cursor: "pointer",
    },
    "& > img:first-child": {
      marginBottom: 5,
    },
  },
  "& .MuiRadio-root": {
    color: "#787776",
    padding: "18px 20px"
  },
  "& .MuiFormControlLabel-root": {
    margin: "0px"
  },
  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "#652786",
    background: "transparent"
  },
  "& .MuiDialogContent-root": {
    padding: "0px"
  }
})
const TableWrapper = styled(Box)({
  width: "100%",
  "& .tr": {
    borderRadius: "12px",
  },
  "& .td": {
    textAlign: "center"
  },
  "& .table tbody .tr": {
    cursor: "pointer"
  },
  "& .td:first-child": {
    display: "flex",
    borderBottomLeftRadius: "12px",
    borderTopLeftRadius: "12px"
  },
  "& .td:last-child": {
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
    paddingLeft: "12px"
  },
  "& .table tbody .tr .td:nth-child(3) ,.td:nth-child(2),.td:nth-child(4),.td:nth-child(5)": {
    maxWidth: "200px",
    wordBreak: "break-word",
    paddingLeft: "20px"
  },
  "& .table thead .tr .th:nth-child(1)": {
    // width: "120px"
  },
  "& .table tbody .rowBg": {
    backgroundColor: "#EEE8F2 !important",
  },
  "& .table thead .tr .th:nth-child(2)": {
    "& p": {
      marginLeft: "20px",
      minWidth: "200px",
      justifyContent: "flex-start !important",
    }
  },
  "& .table tbody .tr .td:nth-child(2)": {
    textAlign: "start",
    paddingLeft: "20px"
  },
  "& .BackgroundCss": {
    background: "#EEE8F2"
  }
})
const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%"
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .allBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2",
  }
});

const StyledCheckBox = styled(Checkbox)({
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#6c328b !important"
  }
})

const CancelButton = styled(Button)({
  width: "300px",
  height: "56px",
  borderRadius: "16px",
  textTransform: "none",
  color: "#E47004 ",
  border: "1px solid var(--sys-color-Secondary, #E47004)",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 600,
  // background: "#6c328b",
  fontFamily: "'Poppins', sans-serif", "&:hover": {
    // background: "#6c328b"
  },

});
const SelectAddContentButton = styled(Button)({
  width: "300px",
  height: "56px",
  borderRadius: "16px",
  textTransform: "none",
  color: "#fff",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 600,
  background: "#6c328b",
  fontFamily: "'Poppins', sans-serif", "&:hover": {
    background: "#6c328b"
  },
  "&.Mui-disabled": {
    background: "rgb(229, 226, 225)",
    color: "rgb(146, 144, 144)",
  }
});

const webStyle = {
  statusNew: {
    display: 'block',
    margin: "0 auto",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    gap: "10px",
    borderRadius: "88px",
    background: "#E0D4E7",
    color: "#652786",
    textTransform: "capitalize" as 'capitalize'

  },
  statusDraft: {
    display: 'block',
    margin: "0 auto",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFEDE4",
    color: "#E47004",
    textTransform: "capitalize" as 'capitalize'


  },
  statusArchived: {
    display: 'block',
    margin: "0 auto",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFD9DF",
    color: "#E70E6B",
    textTransform: "capitalize" as 'capitalize'
  },

}
// Customizable Area End
