import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, } from "./assets";
// Customizable Area End

export const configJSON = require("./config");  

export interface Props {
  navigation: any;
  history:any;
  location:any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl:any;
  folderData:any;
  themeMode:boolean,
  bulkAction:boolean,
  dialogopen:boolean,
  createFolder:any,
  checked:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageThumbnailLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PostCeateNewFolder:any;
  GetFolderData:any;
  GetBulkDeleteData:any;
  bulk_deleteAction:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl:'',
      createFolder:'',
      themeMode:false,
      dialogopen:false,
      checked:[],
      folderData:{},
      bulkAction:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount(){
  this.getFolderData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiRequestCallId && responseJson) {
          // console.log('__api 157')
          if (apiRequestCallId === this.PostCeateNewFolder) {
                  this.getFolderData();
                  this.dialogHandleClose();
          }
          if(apiRequestCallId === this.GetFolderData){
            this.setState({folderData:responseJson})
          }
          if(apiRequestCallId === this.GetBulkDeleteData){
            this.getFolderData();
          }
        
         
        }
  
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl : event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl : null});
  };

 

  handleNavigate = (data:any) => {
        this.props.history.push(`/ManageThumbnailUser/${data?.id}`,{id:data?.id})    
}

handleChange = (data:any) => {
    this.bulk_deleteAction =  data.id
};

dialogHandleClose = () => {
    this.setState({dialogopen:false})
}

dialogHandleOpen = () => {
  this.setState({dialogopen:true})
}

bulk_delete_Action = () => {
  const header = {
    "Content-Type": "application/json",
    token: localStorage.getItem("token")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.GetBulkDeleteData = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_admin/folder/${this.bulk_deleteAction}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'get'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getFolderData = async () => {
  const header = {
    "Content-Type": "application/json",
    token: localStorage.getItem("token")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.GetFolderData = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_admin/folder?folder_type=thumbnail_library`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'get'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}


createNewFolder = () => {
    
  let formdata = new FormData();
formdata.append("name", this.state.createFolder);
formdata.append("folder_type", 'thumbnail_library')

  const header = {
    ContentType: "multipart/form-data",
    token: localStorage.getItem("token")
  };
    const httpBody = formdata;
  
  console.log("httpBody",(httpBody))
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.PostCeateNewFolder = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_admin/folder`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    header
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Post'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    httpBody
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}



  // Customizable Area End
}