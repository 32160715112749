import React from 'react'
// Customizable Area Start
import { Grid, Box, AppBar, Toolbar, Typography, Button, CircularProgress } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { ArrowBackIos,  } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import OnboardingguideController from './OnboardingguideController.web';
import './OnboardingGuide.css'
import AlertBlock from "../../alert/src/ActionAlert.web";

export default class OnboardingCategory extends OnboardingguideController {

    handleSubmit() {
        const ids = this.state.category.filter((item: any) => item.selected === true)
        const categoryId = ids.map((item: any) => item.attributes.id)
        const payload = { category_ids: categoryId }
        this.createCategory(payload)
    }
    render() {
        const selectCategory = (index: any) => {
            this.state.category[index].selected = !this.state.category[index].selected
            this.setState({ category: this.state.category })
        }
        if (!this.state.isLoading) {
            if (this.state.is_category_selected === false && this.state.is_expert_selected === false && this.state.is_category_skipped === false) {
            }
            else {
                if (this.state.is_category_skipped === true && this.state.is_expert_skipped === true) {
                    return <Redirect to='Dashboard' />
                }
                if (this.state.is_category_skipped === false && this.state.is_expert_skipped === false) {
                    if (this.state.is_category_selected === true && this.state.is_expert_selected === true) {
                        return <Redirect to='Dashboard' />
                    }
                    else if (this.state.is_category_selected === true && this.state.is_expert_selected === false) {
                        return <Redirect to='Categoriessubcategories' />
                    }

                }
                if (this.state.is_category_skipped === true && this.state.is_expert_skipped === false) {
                    if (this.state.is_expert_selected === true) {
                        return <Redirect to='Dashboard' />
                    }
                    else if (this.state.is_expert_selected === false) {
                        return <Redirect to='Categoriessubcategories' />
                    }

                }
                if (this.state.is_category_skipped === false && this.state.is_expert_skipped === true) {
                    if (this.state.is_category_selected === true) {
                        return <Redirect to='Dashboard' />
                    }
                }
            }
        }
        
        return (
            <>
                {this.state.isLoading ? <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}><CircularProgress size={100} color="inherit" /></Box> : <>

                    <AlertBlock data={{ show: this.state.alert.isOpen, close: () => { this.setState({ alert: { isOpen: false, msg: '', type: '' } }) }, msg: this.state.alert.msg, type: this.state.alert.type, vertical: "top", horizontal: "right" }} />
                    <Dialog
                        className="category"
                        open={this.state.open}
                        // onClose={this.handleClose}
                        BackdropComponent={Backdrop}
                        PaperProps={{ style: { backgroundColor: 'linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)', borderRadius: '24px' } }}
                        BackdropProps={{
                            style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <AppBar position="static">
                                <Toolbar>
                                    <Box className='leftButton'  onClick={() => { this.props.navigation.navigate("Onboardingguide") }}>
                                        <ArrowBackIos className="arrow-icon" />
                                    </Box>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                        How do you want to Xpand your life?
                                    </Typography>
                                    {/* <Button className="skip-btn" onClick={() => this.skipCategory()}>Skip</Button> */}
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} style={styles.mainGrid}>
                                {this.state.category.map((value: any, index: number) => (
                                    <Grid  key={index} item lg={4} md={4} sm={4} xs={4}>
                                        <Paper style={value.selected ? styles.onbaording : styles.dataBox} onClick={() => selectCategory(index)}>
                                            <Typography style={styles.nameType}>{value.attributes.name}</Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12} className='proceed-content'>
                                    <Grid container>
                                        <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Button fullWidth disabled={this.state.category.some((item: any) => item.selected === true) ? false : true} className={this.state.category.some((item: any) => item.selected === true) ? "proceed-btn" : "proceed-btn-disabled"}
                                                onClick={() => this.handleSubmit()}>Proceed</Button>
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={styles.skipGrid}>
                                    <Grid container>
                                        <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Button className="skip-btn" onClick={() => this.skipCategory()}>Skip</Button>
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
                                    </Grid>

                                </Grid>
                            </Grid>


                        </DialogActions>
                    </Dialog>
                </>}
            </>
        )
    }
}

const styles = {
    dataBox: {
        width: '100%',
        minHeight: '40px',
        boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
        borderRadius: '20px',
        marginBottom: '20px',
    },
    onbaording:{
        width: '100%',
        minHeight: '40px',
        boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
        borderRadius: '20px',
        marginBottom: '20px',
        color:'#fff',
        background:'#6C328B'
    },
    nameType: {
        fontSize: '15px',
        textAlign: 'center' as 'center',
        padding: '8px',
        fontWeight: 600,
        textTransform: 'capitalize' as 'capitalize',
    },
    skipGrid:{
        marginTop: '30px',
        marginBottom: '35px'
    },
    mainGrid:{
        marginTop: '35px',
        marginBottom: '20px'
    }
}
// Customizable Area End
