import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,makeStyles,withStyles  } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import AssignList from "./AssignList.web";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import AssignModal from "./AssignModal.web";
import "./style.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssignContentController, {
  Props,
} from "./AssignContentController.web";

export default class AssignContent extends AssignContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const {assignContent, userType}= this.state
    return (
      // Customizable Area Start
        <Grid container>
          <Typography variant="h3" className="profile_header">Assign Content</Typography>
          <Box className="select_box">
          <FormControl className="content_select">
        <NativeSelect
          value={assignContent}
          onChange={this.handleAssignContent}
        >
          <option value="">Assign Content</option>
          <option value='Course/Program'>Course/Program</option>
          <option value='ContentRole'>Content role</option>
          <option value='Bundle'>Bundle</option>
          <option value='Folder'>Folder</option>
        </NativeSelect>
        </FormControl>
        <Typography>To</Typography>
        <FormControl className="content_select" style={{width:'206px'}}>
        <NativeSelect
          value={userType}
          onChange={this.handleUserType}
        >
          <option value="">User Type</option>
          <option value="User">User</option>
          <option value="Branch">Branch</option>
          <option value="Team">Team</option>
        </NativeSelect>
        </FormControl>
      </Box>
        <AssignList handleOpen={this.handleAssignOpenModal} />
        {/* <ColorButton size="small" onClick={this.handleAssignOpenModal}>Assign</ColorButton> */}
        
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            // @ts-ignore
            open={this.state.closeModal}
            onClose={this.handleAssignCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
          >
            {/*@ts-ignore*/}
            <Fade in={this.state.closeModal} >
                <AssignModal handleClose={this.handleAssignCloseModal} />
            </Fade>
          </Modal>
        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    overallProgress: {
   width:'80%',
   height:'35px',
   backgroundColor:'#e4f5f8',
   "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: "red",
        },
  },
  overallProgress2: {
    width:'98%',
    height:'30px',
    backgroundColor:'#e4f5f8',
    paddingTop:'10px',
    borderRadius:'10px',
    "& .MuiLinearProgress-colorPrimary": {
             backgroundColor: "red",
         },
   },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  allCourseInnerContent:{
    display:'flex',
    justifyContent:'space-between',
    alignItem:'center',
    width:'97%',
    paddingBottom:'10px'
  }
};

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: purple[500],
        color:"white",
        borderRadius:"10px",
        borderColor: purple[500],
        textTransform:'capitalize',
        padding:'10px 50px',
        '&:hover': {
            borderColor: purple[700],
            color:"white",
            backgroundColor: purple[800],
        },
    },
  }))(Button);



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));



// Customizable Area End
