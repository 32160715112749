import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  width: any;
  experts: any;
  count: any;
  start: any;
  query: any;
  alert: { isOpen: boolean; msg: string; type: string };
  is_category_selected: any;
  is_expert_selected: any;
  is_expert_skipped: any;
  is_category_skipped: any;
  isLoading: boolean;
  isText: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      width: 1045,
      experts: [],
      count: 0,
      start: 1,
      query: "",
      alert: { isOpen: false, msg: "", type: "error" },
      is_category_selected: false,
      is_expert_selected: false,
      is_expert_skipped: false,
      is_category_skipped: false,
      isLoading: true,
      isText: false,
    };
    window.addEventListener("resize", this.update);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.update();
    this.getExpertsUserList();
    this.getPorfileData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getCategories();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson) {
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          let array = responseJson.data;
          array.forEach((element: any) => {
            element.selected = false;
            element.featured = true;
            element.img =
              "https://icon-library.com/images/user-image-icon/user-image-icon-11.jpg";
          });
          if (array.length === 0) {
            this.setState({ isText: true, experts: [], isLoading: false });
          }
          this.setState({
            experts: array,
            count: responseJson.meta.pagination.total_count,
            start: responseJson.meta.pagination.current_page,
          });
        } else if (this.createUserExpertApiCallId === apiRequestCallId) {
          if (!responseJson.errors) {
            setTimeout(() => {
              this.props.navigation.navigate("Dashboard");
            }, 3000);
          }
        } else if (this.skipExpertApiCallId === apiRequestCallId) {
          if (responseJson.suceess) {
            setTimeout(() => {
              this.props.navigation.navigate("Dashboard");
            }, 3000);
          }
        } else if (apiRequestCallId === this.profileUserApiCallId) {
          if (responseJson.is_category_selected) {
            window.history.pushState(
              null,
              document.title,
              window.location.href
            );
            window.addEventListener("popstate", function (event) {
              window.history.pushState(
                null,
                document.title,
                window.location.href
              );
            });
          }
          this.setState({
            is_category_selected: responseJson.is_category_selected,
            is_expert_selected: responseJson.is_expert_selected,
            is_category_skipped: responseJson.is_category_skipped,
            is_expert_skipped: responseJson.is_expert_skipped,
            isLoading: false,
          });
        } else if (apiRequestCallId === this.addCategoryApiCallId) {
          this.setState({ isVisible: false, category: "" }, () => {
            this.getCategories();
          });
        } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
          this.setState(
            {
              isVisible: false,
              category: "",
              subCategory: "",
              selectedCategoryID: [],
            },
            () => {
              this.getCategories();
            }
          );
        } else if (apiRequestCallId === this.deleteCategoriesApiCallId) {
          let arrays = responseJson.data;
          arrays.forEach((element: any) => {
            element.selected = false;
            element.featured = true;
            element.img =
              "https://icon-library.com/images/user-image-icon/user-image-icon-11.jpg";
          });
          if (arrays.length === 0) {
            this.setState({ isText: true, experts: [], isLoading: false });
          } else {
            this.setState({
              experts: arrays,
              count: responseJson.meta.pagination.total_count,
              start: responseJson.meta.pagination.current_page,
              isText: false,
              isLoading: false,
            });
          }
        }
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories();
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  createUserExpertApiCallId: string;
  skipExpertApiCallId: string;
  profileUserApiCallId: string;
  getExpertsUserList = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getExpertSearchList = (searchStr: any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchExpertsListAPIEndPoint + `?search=${searchStr}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createExpert(data: any) {
    this.doCreateUserExpert({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpPostType,
      endPoint: configJSON.createUserExpertEndPoint,
      body: JSON.stringify(data),
    });
  }

  skipExpert() {
    this.doSkipExpert({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.skipUserExpertEndPoint,
    });
  }

  getPorfileData = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPorfileDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleData({ msg, type }: any) {
    this.setState({ alert: { isOpen: true, msg, type } });
  }

  doCreateUserExpert(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.createUserExpertApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doSkipExpert(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.skipExpertApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus,
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let category of array) {
      if (category.id === id) {
        category.expand = !category.expand;
      }
    }  
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }],
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory,
        },
        parent_categories: this.state.selectedCategoryID,
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  update = () => {
    this.setState({
      width: window.innerWidth,
    });
  };
  getCategories = () => {
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
