import React from 'react'
// Customizable Area Start

import {
    Box,
    Typography,
    Paper,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import Button from '@material-ui/core/Button';

// import VideoComponent from './VideoComponent.web';
import ReactPlayer from 'react-player';
import './videostyles.css';

import { Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Card, Checkbox } from '@material-ui/core'
import { playimage } from './assets';
import { image1 } from './assets';
import { image2 } from './assets';
import { image3 } from './assets';
import Modal from '@material-ui/core/Modal';
import ReactQuill from "react-quill";
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
// import useWindowSize from 'react-use/lib/useWindowSize'
// @ts-ignore
import Confetti from 'react-confetti'
import StarOutlineIcon from '@material-ui/icons/StarOutline';
// import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './videostyles.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { group2image } from './assets';

import LinearProgress from '@material-ui/core/LinearProgress';
import TitlePageController from './TitlePageController.web';
const notes_icon = require("../assets/Notes.png");
const quizIcon = require("../assets/Quiz_purple.png")
const disable_quiz = require("../assets/quizIcon.png")
const Tick = require("../assets/Tick.png");
const closeIcon = require("../assets/imagenav_close.png");
const failed = require("../assets/Recurso 1.png");
const fail = require("../assets/fail.png");
const Resultfail = require("../assets/Resultfail.png");
const Resultpass = require("../assets/Resultpass.png");
const complete = require("../assets/complete.png");
const Upload_icon = require("../assets/Upload_icon.png")
const delete_image = require("../assets/Notes_delete.png")
const edit = require("../assets/Notes_edit.png");
const fill = require("../assets/Fill.png")

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#6b328a',
        },
    }),
)(LinearProgress);

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


interface Props {

}
// Customizable Area End
// Customizable Area Start
export default class TiltePage extends TitlePageController {
    // Customizable Area End

    toolbarOptions: any = [["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ["clean"],
    ];

    modules = {
        syntax: false,
        toolbar: this.toolbarOptions,
        clipboard: {
            matchVisual: false,
        },
    };

    formats: any = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video",];


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            // <Sidebar>
            <Grid container style={{ paddingTop: '0px' }}>
                <Typography variant="h3" className="profile_header" style={{ display: 'contents', fontSize: '40px', textTransform: 'capitalize' }}>{this.state.allTitleLibraryData.data?.attributes?.lessons?.attributes?.course_name}</Typography>
                <Box style={{ marginLeft: '18%', paddingTop: '26px', marginBottom: '-30px' }}>
                    {!this.state.show && <Typography variant="h3" className="profile_header" style={{ float: 'right', fontSize: '30px', textDecorationLine: 'underline' }}>Time Left: {this.state.time.minutes}:{this.state.time.seconds}
                    </Typography>}
                </Box>
                <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ThemeProvider theme={theme}>
                    {/* <Container> */}
                    <Grid container spacing={4} style={{ width: '100%', marginTop: '12px' }} >

                        <Grid item lg={8} md={8} xs={12} sm={12}>
                            {this.state.show ?
                                <>
                                    <Box
                                        style={{
                                            width: "100%",
                                            borderTopRightRadius: "10px",
                                            borderTopLeftRadius: "10px",
                                            overflow: 'hidden'
                                        }}>
                                        <Box className='player-wrapper'>
                                            {this.state.allTitleLibraryData.data?.attributes?.video_text_url ?
                                                <ReactPlayer
                                                    controls
                                                    className='react-player'
                                                    url={this.state.youtube_video_set ? this.state.youtube_video_set : "Video not found"}
                                                    file="HLS(m3u8)"
                                                    Speed={1}
                                                    playing={true}
                                                    width='100%'
                                                    height='100%'
                                                    played
                                                    notes={<img src={notes_icon} />}
                                                    ref={this.myRef}
                                                    onEnded={() => this.play_youtubeNextVideo()}
                                                />
                                                :
                                                <ReactPlayer
                                                    controls
                                                    className='react-player'
                                                    url={'https://youtu.be/FKtw0dEb9wA?si=gU0tLo5jQCONko3d'}
                                                    file="HLS(m3u8)"
                                                    config={{
                                                        file: {
                                                            attributes: {
                                                                controlsList: 'nodownload'
                                                            }
                                                        }
                                                    }}
                                                    Speed={1}
                                                    playing={true}
                                                    notes={<img src={notes_icon} />}
                                                    width='100%'
                                                    height='100%'
                                                    played
                                                    ref={this.myRef}
                                                    onEnded={() => this.playNextVideo()}
                                                />

                                            }
                                        </Box>

                                    </Box>
                                    <p
                                        style={{
                                            marginTop: "10px",
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            fontFamily: 'Montserrat',
                                            wordBreak: 'break-all',
                                        }}>
                                        {this.state.allTitleLibraryData?.data?.attributes?.title}</p>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: "Montserrat" }}>
                                        <Grid container>

                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Box><h1 style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Montserrat' }}>{this.state.allTitleLibraryData?.data?.attributes?.account?.name || this.state.allTitleLibraryData?.data?.attributes?.created_by}</h1></Box>
                                            </Grid>



                                            <Grid item lg={6} md={6} sm={6} xs={6} style={{
                                                display: 'flex',
                                                justifyContent: 'end', marginTop: '-20px'
                                            }}>
                                                <Box style={{ width: '56px', height: '56px', borderRadius: '10px', backgroundColor: '#6C328B', cursor: 'pointer' }}>
                                                    <img id="open-notes" onClick={this.OpenNotes} src={notes_icon} style={{ margin: 'auto', padding: '10px', display: 'flex' }} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Modal
                                            aria-labelledby="transition-modal-title"
                                            aria-describedby="transition-modal-description"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                                            open={this.state.openNotes}
                                            onClose={this.CloseNotes}
                                            closeAfterTransition
                                            BackdropComponent={Backdrop}
                                            BackdropProps={{
                                                timeout: 500,
                                            }}
                                            id="notes-modal"
                                        >

                                            <Box style={{
                                                width: '1100px', height: 'auto', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: theme.shadows[5],
                                                padding: theme.spacing(2, 4, 3),
                                            }}>
                                                <Grid container>
                                                    <Grid item lg={6} md={6} sm={6} xs={6} style={{ display: 'flex' }}>
                                                        <img onClick={this.CloseNotes} src={fill} style={{ margin: 'auto 0px', paddingRight: '20px', cursor: 'pointer' }} /><Typography style={{ fontSize: '32px', fontWeight: 700 }}>Add New Note</Typography>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <img onClick={this.CloseNotes} src={closeIcon} style={{ float: 'right', width: '12px', marginTop: '0px', marginBottom: '20px', marginRight: '-10px', cursor: 'pointer' }} />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2}>
                                                    <Grid item lg={6}>
                                                        <Box style={{ marginTop: '25px', marginBottom: '10px' }}>
                                                            <TextField id="outlined-basic" error={this.state.titleNotes.length < 5} helperText={this.state.titleNotes.length < 5 ? 'Minimum 5 characters is required' : ''} label="Title" variant="outlined" value={this.state.titleNotes} onChange={(event: any) => this.setState({ titleNotes: event.target.value })} fullWidth />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <Box style={{ marginTop: '25px', marginBottom: '10px' }}>
                                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    label="Type"
                                                                    value={this.state.selectedValue}
                                                                    onChange={this.handleSelectChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}>Public</MenuItem>
                                                                    <MenuItem value={20}>Private</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box style={{ marginTop: '15px', marginBottom: '30px', width: "100%", height: "150px", overflow: 'hidden', borderRadius: '10px', flexDirection: 'column', justifyContent: 'center', position: "relative", display: 'flex', alignItems: "center", backgroundColor: '#E6E8EC', boxShadow: '0px 0px 16px rgba(191, 190, 190, 0.350581' }}>
                                                    {this.state.imageDemo
                                                        ?
                                                        <>
                                                            <img src={this.state.imageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />
                                                            <label style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#fff', position: 'absolute', marginTop: '-7%', marginLeft: '75%', textAlign: 'center' }} htmlFor="file" >
                                                                <Box ><img style={{ width: '70%', marginTop: '3px' }} src={edit} /></Box>
                                                            </label>
                                                            <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={this.onImageChange} />
                                                            <Box onClick={() => this.onImageDelete()} style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#EF2B4B', position: 'absolute', marginTop: '-7%', marginLeft: '90%', textAlign: 'center' }}><img style={{ marginTop: '3px' }} src={delete_image} /></Box>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                this.state.imageDemo ?
                                                                    <img src={this.state.imageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />

                                                                    :
                                                                    <>
                                                                        <label htmlFor="file" >

                                                                            <img src={Upload_icon} />


                                                                        </label>
                                                                        <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={this.onImageChange} />
                                                                        <br />
                                                                        <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '10px', color: '#B5B5BE' }}>Drag and Drop or Browse to upload</Typography>
                                                                        <br />
                                                                        <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '0px', color: '#B5B5BE' }}>Upload upto 15 MB and file dimension should be 768*1024</Typography>
                                                                    </>
                                                            }
                                                        </>
                                                    }

                                                </Box>

                                                <Box style={{ margin: '10px 0' }}>
                                                    <ReactQuill
                                                        className="react-quill"
                                                        modules={this.modules}
                                                        theme="snow"
                                                        value={this.state.captionNotes}
                                                        onChange={(value) => this.richTextOnchange(value)}
                                                    />
                                                </Box>
                                                {this.state.richTextlimit && <p style={{ color: 'red', margin: '0 0 20 0', fontSize: '0.75rem' }}>Maximum limit is 50 characters.</p>}
                                                <Box style={{ width: '100%', textAlign: 'center', marginTop: '15px' }}>
                                                    <Button id="save-note-btn" variant="contained" color="primary" style={{ background: '#6c328b', width: '100%', fontWeight: 700, height: '50px', borderRadius: '15px' }}
                                                        onClick={() => this.createNotes(this.state.allTitleLibraryData)}>
                                                        Save
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Modal>
                                    </Box>
                                    <p style={{ fontFamily: "Montserrat", fontWeight: 400, color: "gray" }}> {this.state.allTitleLibraryData?.data?.attributes?.description}</p>
                                </>
                                :

                                <Paper style={{
                                    marginTop: '0px', width: '100%', height: '670px', backgroundColor: '#fff', border: '1px solid rgba(225, 225, 225, 0.577997)',
                                    boxShadow: '0px 0px 16px rgba(213, 213, 213, 0.350581)', borderRadius: '20px'
                                }}>
                                    <h1 style={{ paddingLeft: '25px' }}>Quiz</h1>

                                    <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                    <Box style={{ width: '90%', margin: 'auto', marginTop: '10px' }}>
                                        <h3><span>{this.state.currentQuestion + 1}</span>/
                                            {this.state.quizze?.data?.attributes?.questions.length}</h3>
                                        <BorderLinearProgress variant="determinate" value={(this.state.currentQuestion / this.state.quizze?.data?.attributes?.questions.length) * 100} />
                                    </Box>
                                    <Paper style={{
                                        width: '90%', minHeight: '495px', backgroundColor: '#fff', border: '1px solid rgba(225, 225, 225, 0.577997)',
                                        boxShadow: '0px 0px 16px rgba(213, 213, 213, 0.350581)', borderRadius: '20px', margin: '25px auto auto auto'
                                    }}>
                                        <p
                                            style={{
                                                margin: "5px auto auto auto",
                                                fontSize: "20px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "bold",
                                                padding: '20px'
                                            }}>
                                            {this.state.quizze?.data?.attributes?.questions[this.state.currentQuestion].attributes?.question}
                                        </p>
                                        <FormControl component="fieldset" style={{ minWidth: '100%' }}>
                                            <RadioGroup>
                                                {this.state.quizze?.data?.attributes?.questions[this.state.currentQuestion].attributes?.options.map((ele: any, index: any) => {
                                                    return (

                                                        <Paper style={{
                                                            width: '90%', height: '60px', backgroundColor: '#fff', border: '1px solid rgba(225, 225, 225, 0.577997)',
                                                            boxShadow: '0px 0px 16px rgba(213, 213, 213, 0.350581)', borderRadius: '20px', margin: '15px auto auto auto'
                                                        }} key={index}>
                                                            <Box style={{ display: 'flex', marginLeft: '10px' }}>

                                                                <FormControlLabel style={{ marginTop: '7px' }}
                                                                    id="quize-option"
                                                                    key={index}
                                                                    value={ele?.title}
                                                                    control={<Radio style={{ color: '#6C328B' }} />}
                                                                    label={ele?.title}
                                                                    onChange={() => this.checkedhandleChange(ele)}
                                                                    checked={this.state.option_ids[this.state.currentQuestion]?.id === ele?.id}
                                                                />
                                                            </Box>
                                                        </Paper>
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                        <Box style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                            <Grid container>
                                                <Grid item lg={6}>
                                                    <Button
                                                        onClick={() => this.handlePrevious()}
                                                        id="handle-prev-question"
                                                        disabled={this.state.currentQuestion === 0}
                                                        style={{ width: '90%', fontWeight: 700, padding: '15px', borderRadius: '20px', backgroundColor: '#6C328B' }} variant="contained" color="primary">
                                                        Back
                                                    </Button>
                                                </Grid>
                                                <Grid item lg={6}>
                                                    {this.state.quizze?.data?.attributes?.questions.length === this.state.currentQuestion + 1 ?
                                                        <Button
                                                            id="submit-quiz-btn"
                                                            onClick={() => this.postQuizzAnswer()}
                                                            style={{ width: '90%', fontWeight: 700, padding: '15px', borderRadius: '20px', backgroundColor: '#FF8B3F' }} variant="contained" color="primary">
                                                            Submit
                                                        </Button> :
                                                        <Button
                                                            id="handle-next-question"
                                                            onClick={() => this.hendleNext()}
                                                            style={{ width: '90%', fontWeight: 700, padding: '15px', borderRadius: '20px', backgroundColor: '#FF8B3F' }} variant="contained" color="primary">
                                                            Next
                                                        </Button>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Paper>
                                </Paper>
                            }

                        </Grid>


                        <Grid item lg={4} md={4} xs={12} sm={12} >
                            <Paper style={{
                                marginTop: '0px', width: '100%', height: '650px', backgroundColor: '#fff', border: '1px solid rgba(225, 225, 225, 0.577997)',
                                boxShadow: '0px 0px 16px rgba(213, 213, 213, 0.350581)', borderRadius: '20px', overflowX: 'hidden'
                            }}>
                                <Accordion style={{ boxShadow: 'none', }}>
                                    <AccordionDetails style={{ flexDirection: 'inherit', padding: 0, alignItems: 'center', width: '100%' }}>
                                        <SimpleTabs
                                            onImageDelete={this.editOnImageDelete}
                                            onImageChange={this.onImageChange}
                                            imageDemo={this.state.imageDemo}
                                            modules={this.modules}
                                            Quiz_Icon={this.state.show}
                                            activeLesson={this.state.isActiveLesson}
                                            minutes={this.state.minutes}
                                            seconds={this.state.seconds}
                                            editOnCchange1={this.editOnCchange1}
                                            editOnCchange={this.editOnCchange}
                                            editOnType={this.editOnType}
                                            editType={this.state.editType}
                                            editDescription={this.state.editDescription}
                                            editTitle={this.state.editTitle}
                                            openCloseModle={this.openCloseModle}
                                            openEditModle={this.state.openEditModle}
                                            openUpdateModle={this.openUpdateModle}
                                            is_quiz_completed={this.getQuizStatus()}
                                            getTitleLessonData={this.getTitleLessonData}
                                            showClick={this.getquizzesData}
                                            lessons={this.state.allTitleLibraryData?.data?.attributes?.lessons?.attributes}
                                            onChangeVideo={this.onChangeVideo}
                                            notes={this.state.allTitleLibraryData?.data?.attributes?.notes}
                                            updateNotes={this.updateNotes}
                                            editOnImageChange={this.editOnImageChange}
                                            editImageDemo={this.state.editImageDemo} />
                                    </AccordionDetails>
                                </Accordion>

                            </Paper>
                        </Grid>



                    </Grid>
                    {/* </Container> */}
                    <Box>
                        <Modal
                            style={{ margin: 'auto' }}
                            open={this.state.open}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            id="body-one-modal"
                        >
                            <Body navigateAchievement={this.navigateAchievement} data={this.state.quizAnswers} ModalhandleClose={this.ModalhandleClose} navigate={this.navigate} />
                        </Modal>
                    </Box>

                    <Box>
                        <Modal
                            style={{ margin: 'auto' }}
                            open={this.state.open1}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            id="body-two-modal"
                        >
                            <Body1 tryAGAIN={this.tryAGAIN} getquizzesData={this.getquizzesData} data={this.state.quizAnswers} ModalhandleClose={this.Modal1handleClose} navigate={this.navigate} />
                        </Modal>
                    </Box>

                    <Box>
                        <Modal
                            style={{ margin: 'auto' }}
                            open={this.state.open2}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            id="body-three-modal"
                        >
                            <Body2 navigateAchievement={this.navigateAchievement} data={this.state.quizAnswers} ModalhandleClose={this.Modal2handleClose} navigate={this.navigate} />
                        </Modal>
                    </Box>
                </ThemeProvider >
            </Grid >
            // </Sidebar>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


const BorderLinearProgress1 = withStyles((theme: any) => ({
    root: {
        height: 10,
        width: '70%',
        margin: 'auto',
        padding: '25px',
        display: 'flex',
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        background: `linear-gradient(90deg, #6C328B 0%, #EF2B4B 119.44%)`
    },
}))(LinearProgress);
export function Body({ ModalhandleClose, navigate, data, navigateAchievement }: any) {
    return (
        <Box style={{ top: '5%', left: '15%', position: "absolute", width: '70%', maxHeight: '90%', margin: 'auto', backgroundColor: theme.palette.background.paper, border: "none", borderRadius: '20px', boxShadow: theme.shadows[5], padding: theme.spacing(2, 4, 3) }}>
            {/* <img onClick={ModalhandleClose} src={closeIcon} style={{ float: 'right', width: '20px', marginTop: '15px', marginRight: '15px', cursor: 'pointer' }} /> */}
            <Box style={{ backgroundColor: "#6C328B", width: 120, height: 120, margin: '25px auto auto auto', borderRadius: "60px" }}>
                <img src={Tick} style={{ margin: '-10px auto auto -10px', width: '140px' }} />
            </Box>
            <Typography style={{ fontWeight: 700, fontSize: '24px', margin: '3% auto', textAlign: 'center' }}>Quiz Passed</Typography>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', marginTop: '-25px' }}> Minimum score was achieved</p>
            <p style={{ fontFamily: "Montserrat", color: '#6C328B', fontWeight: 600, textAlign: 'center', marginTop: '25px', fontSize: '15px' }}> {data.final_result.message}</p>
            <BorderLinearProgress1 variant="determinate" value={data.final_result.percentage} />
            <img src={Resultfail} style={{ marginTop: '-57px', marginLeft: '9%' }} />
            <img src={Resultpass} style={{ float: 'right', marginTop: '-57px', marginRight: '9%' }} />
            <Box style={{ width: '75%', margin: '25px auto' }}>
                <hr style={{ borderRadius: '5px', backgroundColor: 'black' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-22', marginLeft: '25px' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '59%' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '97%' }} />
            </Box>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', fontSize: '18px' }}>You have correctly answered {data.final_result.right_answer} / {data.final_result.total_question} questions</p>
            {/* <Box style={{display: 'flex', justifyContent: 'space-around'}}> */}
            <Grid container spacing={4}>
                <Grid item lg={1} md={1}></Grid>
                <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Button id="body-back-to-home" onClick={navigate} variant="outlined" style={{ border: '1px solid #6C328B', color: '#6C328B', width: '100%', padding: '10px', fontSize: '20px', borderRadius: '15px' }}>
                        BACK TO HOME
                    </Button>
                </Grid>
                <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Button id="body-continue" onClick={navigateAchievement} variant="outlined" style={{ backgroundColor: '#6C328B', width: '100%', padding: '10px', color: '#fff', fontSize: '20px', borderRadius: '15px' }}>
                        CONTINUE
                    </Button>
                </Grid>
                <Grid item lg={1} md={1}></Grid>
            </Grid>


            {/* </Box> */}
            <Confetti
                style={{
                    width: '100%',
                    height: '100%'
                }}
            />
        </Box>
    )
}



const BorderLinearProgress2 = withStyles((theme: any) => ({
    root: {
        height: 10,
        width: '70%',
        margin: 'auto',
        padding: '25px',
        display: 'flex',
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        background: `linear-gradient(90deg, #6C328B 0%, #EF2B4B 119.44%)`
    },
}))(LinearProgress);
export function Body1({ ModalhandleClose, navigate, data, getquizzesData, tryAGAIN }: any) {
    return (
        <Box style={{ top: '5%', left: '15%', position: "absolute", width: '70%', maxHeight: '90%', margin: 'auto', backgroundColor: theme.palette.background.paper, border: "none", borderRadius: '20px', boxShadow: theme.shadows[5], padding: theme.spacing(2, 4, 3) }}>
            {/* <img onClick={ModalhandleClose} src={closeIcon} style={{ float: 'right', width: '20px', marginTop: '15px', marginRight: '15px', cursor: 'pointer' }} /> */}
            <img src={failed} style={{ position: 'absolute', margin: 'auto 25% auto 25%' }} />
            <Box style={{ backgroundColor: "#EF2B4B", width: 120, height: 120, margin: '25px auto auto auto', borderRadius: "60px" }}>
                <img src={fail} style={{ margin: '-10px auto auto -10px', width: '140px' }} />
            </Box>
            {/* <SimpleModal /> */}
            <Typography style={{ fontWeight: 700, fontSize: '24px', margin: '3% auto', textAlign: 'center' }}>Quiz Failed</Typography>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', marginTop: '-25px' }}> Minimum score wasn't achieved</p>
            <p style={{ fontFamily: "Montserrat", color: '#EF2B4B', fontWeight: 600, textAlign: 'center', marginTop: '25px', fontSize: '15px' }}> {data.final_result.message}</p>
            <BorderLinearProgress2 variant="determinate" value={data.final_result.percentage} />
            <img src={Resultfail} style={{ marginTop: '-57px', marginLeft: '9%' }} />
            <img src={Resultpass} style={{ float: 'right', marginTop: '-57px', marginRight: '9%' }} />
            <Box style={{ width: '75%', margin: '25px auto' }}>
                <hr style={{ borderRadius: '5px', backgroundColor: 'black' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-22', marginLeft: '25px' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '59%' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '97%' }} />
            </Box>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', fontSize: '18px' }}>You have correctly answered {data.final_result.right_answer} / {data.final_result.total_question} questions</p>
            {/* <Box style={{display: 'flex', justifyContent: 'space-around'}}> */}
            <Grid container style={{ display: 'flex' }} spacing={4}>
                <Grid item lg={1} md={1}></Grid>
                <Grid item lg={5} md={5} sm={6} xs={6}>
                    <Button id="back-to-home" onClick={navigate} variant="outlined" style={{ borderRadius: '15px', border: '1px solid #EF2B4B', color: '#EF2B4B', width: '100%', padding: '10px', fontSize: '20px' }}>
                        BACK TO HOME
                    </Button>
                </Grid>
                <Grid item lg={5} md={5} sm={6} xs={6}>
                    <Button id="try-again" onClick={tryAGAIN} variant="outlined" style={{ borderRadius: '15px', backgroundColor: '#EF2B4B', width: '100%', padding: '10px', color: '#fff', fontSize: '20px' }}>
                        TRY AGAIN
                    </Button>
                </Grid>
                <Grid item lg={1} md={1}></Grid>
            </Grid>

        </Box>
    )
}


const BorderLinearProgress3 = withStyles((theme: any) => ({
    root: {
        height: 10,
        width: '70%',
        margin: 'auto',
        padding: '25px',
        display: 'flex',
    },
    colorPrimary: {
        // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        background: `linear-gradient(90deg, #6C328B 0%, #EF2B4B 119.44%)`
    },
}))(LinearProgress);
export function Body2({ ModalhandleClose, navigate, data, navigateAchievement }: any) {
    return (
        <Box style={{ top: '5%', left: '15%', position: "absolute", width: '70%', maxHeight: '90%', margin: 'auto', backgroundColor: theme.palette.background.paper, border: "none", borderRadius: '20px', boxShadow: theme.shadows[5], padding: theme.spacing(2, 4, 3) }}>
            {/* <img onClick={ModalhandleClose} src={closeIcon} style={{ float: 'right', width: '20px', marginTop: '15px', marginRight: '15px', cursor: 'pointer' }} /> */}
            <Box style={{ backgroundColor: '#F42074', width: 120, height: 120, margin: '25px auto auto auto', borderRadius: "60px" }}>
                <img src={Tick} style={{ margin: '-10px auto auto -10px', width: '140px' }} />
            </Box>
            {/* <SimpleModal /> */}
            <Typography style={{ fontWeight: 700, fontSize: '24px', margin: '3% auto', textAlign: 'center' }}>Quiz Completed</Typography>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', marginTop: '-25px' }}>No minimum score needed it</p>
            <p style={{ fontFamily: "Montserrat", color: '#F42074', fontWeight: 600, textAlign: 'center', marginTop: '25px', fontSize: '15px' }}>{data.final_result.message}</p>
            <BorderLinearProgress3 variant="determinate" value={data.final_result.percentage} />
            <img src={Resultfail} style={{ marginTop: '-57px', marginLeft: '9%' }} />
            <img src={Resultpass} style={{ float: 'right', marginTop: '-57px', marginRight: '9%' }} />
            <Box style={{ width: '75%', margin: '25px auto' }}>
                <hr style={{ borderRadius: '5px', backgroundColor: 'black' }} />
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-22', marginLeft: '25px' }} />
                {/* <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '59%' }} /> */}
                <Box style={{ borderLeft: '4px solid black  ', height: '30px', marginTop: '-30', marginLeft: '97%' }} />
            </Box>
            <p style={{ fontFamily: "Montserrat", fontWeight: 600, color: "gray", textAlign: 'center', fontSize: '18px' }}>You have correctly answered {data.final_result.right_answer} / {data.final_result.total_question} questions</p>
            {/* <Box style={{display: 'flex', justifyContent: 'space-around'}}> */}
            <Grid container spacing={4}>
                <Grid item lg={1} md={1}></Grid>
                <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Button id="back-to-home" onClick={navigate} variant="outlined" style={{ borderRadius: '15px', border: '1px solid #F42074', color: '#F42074', width: '100%', padding: '10px', fontSize: '20px' }}>
                        BACK TO HOME
                    </Button>
                </Grid>
                <Grid item lg={5} md={5} sm={6} xs={12}>
                    <Button id="continue" onClick={navigateAchievement} variant="outlined" style={{ borderRadius: '15px', backgroundColor: '#F42074', width: '100%', padding: '10px', color: '#fff', fontSize: '20px' }}>
                        CONTINUE
                    </Button>
                </Grid>
                <Grid item lg={1} md={1}></Grid>
            </Grid>


            {/* </Box> */}
            <Confetti
                style={{
                    width: '100%',
                    height: '100%'
                }}
            />
        </Box>
    )
}



export function MediaControlCard(props: any) {

    const handleClick = (data: any) => {

        if (props.is_quiz_completed[props.data] === true) {
            return;
        } else {
            if (data.QuizEnable.map((value: any) => value === false)) {
                data?.diSable?.phases.forEach((e: any, index: number) => {
                    if (e.is_video_completed === true) {
                        data.showClick(data?.titles?.quiz_id);
                    }
                });
            }
        }
    };


    let videoTime = parseFloat(props.time).toFixed(0);
    const totalSeconds: any = videoTime;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    function padTo2Digits(num: any) {
        return num.toString().padStart(2, '0');
    }
    const result = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    return (
        <>
            {props?.titles?.type === "video" ?
                <Paper
                    className={props?.titles?.id === props.activeLesson ? ` videoPlayActiv` : ` videoPlayList`}
                    // className={`${classes.root} videoPlayList`}
                    id="video-card-play"
                    onClick={() => { props?.onChangeVideo(props?.titles?.title, props?.titles?.url, props?.titles); props.getTitleLessonData(props?.titles?.id) }} style={{ justifyContent: 'space-between', minHeight: "90px", width: "100%", marginBottom: "10px", padding: '10px', borderRadius: '10px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 1px 1px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                    <Grid container>
                        <Grid item lg={10} md={10} sm={9} xs={9}>
                            <Box style={{ marginLeft: '10px', display: 'flex' }}>
                                <Box style={{ marginTop: '12px' }}>
                                    <Box className='numberStyles' >{props?.data + 1}</Box>
                                </Box>
                                <Box>
                                    <h5 style={{ margin: '5px 0', fontFamily: "Montserrat", wordBreak: 'break-all', }}>
                                        {props?.titles?.title}
                                    </h5>
                                    <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', fontFamily: "Montserrat" }}>

                                        {result}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={2} md={2} sm={3} xs={3} style={{ margin: 'auto' }}>
                            <Box>
                                {props.titles.is_video_completed ?
                                    <img src={complete} style={{ height: '25px', width: '35%%', borderRadius: '4px' }} />
                                    :
                                    <img
                                        src={playimage}
                                        style={{ height: '25px', width: '35%%', borderRadius: '4px' }}
                                    />
                                }
                            </Box>
                        </Grid>

                    </Grid>
                </Paper>
                : <Card className={` videoPlayList`}  id="playlist-card" onClick={() => handleClick(props)} style={{ justifyContent: 'space-between', height: "90px", width: "100%", marginBottom: "10px", padding: '10px', borderRadius: '10px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 1px 1px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                    <Grid container >
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <Box style={{ marginLeft: '10px', display: 'flex' }}>
                                <Box style={{ marginTop: '12px' }}>
                                    <Box className='numberStyles' >{(props?.data + 1)}</Box>
                                </Box>
                                <Box>
                                    <h5 style={{ margin: '5px 0', fontFamily: "Montserrat", wordBreak: 'break-all' }}>
                                        {props?.titles.title}
                                    </h5>
                                    <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', fontFamily: "Montserrat" }}>
                                        {props.titles.duration} minutes
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} style={{ margin: 'auto' }}>
                            <Button>
                                {props.Quiz_Icon ? <img
                                    src={quizIcon}
                                    style={{ height: '25px', width: '35%%', borderRadius: '4px' }}
                                /> :
                                    <img
                                        src={disable_quiz}
                                        style={{ height: '25px', width: '35%%', borderRadius: '4px' }}
                                    />}

                            </Button>
                        </Grid>

                    </Grid>
                </Card>
            }
        </>
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStylestest = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '400px',
        height: '330px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export const CustomTab = withStyles({
    root: {
        color: '#0D0A19',
    },
    selected: {
        color: '#6C328B',
        fontWeight: 600,
    },
})(Tab);

let QuizEnable: [];
export function SimpleTabs(props: any) {

    const classes = useStylestest();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    QuizEnable = props?.is_quiz_completed;

    const objOne = <>
        {props?.lessons?.phases?.map((e: any, index: any) => {
            return (
                <MediaControlCard
                    key={e.toString()}
                    Quiz_Icon={props.Quiz_Icon}
                    QuizEnable={QuizEnable}
                    diSable={props?.lessons}
                    level={props.level}
                    minutes={props.minutes}
                    seconds={props.seconds}
                    is_quiz_completed={props.is_quiz_completed}
                    showClick={props.showClick}
                    activeLesson={props.activeLesson}
                    getTitleLessonData={props.getTitleLessonData}
                    data={index}
                    time={e?.length}
                    titles={e}
                    onChangeVideo={props.onChangeVideo} />

            )
        })}</>

    return (
        <Box className={classes.root}>
            <AppBar position="static" style={{ boxShadow: 'none', padding: '20px', marginBottom: '-25px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{
                    style: {
                        backgroundColor: '#6C328B',
                        height: 3,
                    },
                }}>
                    <CustomTab label="Lessons" style={{ textTransform: 'none' }} {...a11yProps(0)} />
                    <CustomTab label="Notes" style={{ textTransform: 'none' }} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>

                {objOne}


            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    props?.notes?.length > 0 ? props.notes.map((e: any) => {
                        return (
                            <Card key={e.attributes.created_at} className={`${classes.root} videoPlayList`} style={{ justifyContent: 'space-between', height: "105px", width: "100%", marginBottom: "8px", padding: '10px', boxShadow: '0px 0px 16px rgba(213, 213, 213, 0.350581)', border: '1px solid rgba(225, 225, 225, 0.577997)', borderRadius: '10px' }}>
                                <Grid container>
                                    <Grid item lg={9} md={9} sm={9} xs={9}>
                                        <Box>
                                            <Box style={{ marginLeft: '10px' }}>

                                                <h5 style={{ margin: '5px 0', fontFamily: "Montserrat" }}>
                                                    {e.attributes.title}
                                                </h5>
                                                <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', fontFamily: "Montserrat" }}>
                                                    {e.attributes.note_type}
                                                </Typography>
                                                <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', fontFamily: "Montserrat" }}>
                                                    {e.attributes.created_at}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} style={{ margin: 'auto', }}>
                                        <Box id="open-update-modal" onClick={() => props.openUpdateModle(e)} >

                                            <img
                                                src={image1}
                                                style={{ height: '40px', width: '50%', borderRadius: '4px' }}
                                            />

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        )
                    }) : <h1>Notes Not available</h1>
                }
            </TabPanel>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.openEditModle}
                onClose={props.openCloseModle}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <Box style={{
                    width: '1100px', height: 'auto', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: theme.shadows[5],
                    padding: theme.spacing(2, 4, 3),
                }}>
                    <Grid container>
                        <Grid item lg={9} md={9} sm={9} xs={9} style={{ display: 'flex' }}>
                            <img onClick={props.openCloseModle} src={fill} style={{ margin: 'auto 0px', paddingRight: '20px', cursor: 'pointer' }} /><Typography style={{ fontSize: '32px', fontWeight: 700 }}>Edit "{props.editTitle}"</Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <img onClick={props.openCloseModle} src={closeIcon} style={{ float: 'right', width: '12px', marginTop: '0px', marginBottom: '5px', marginRight: '-10px', cursor: 'pointer' }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item lg={6}>
                            <Box style={{ marginTop: '25px', marginBottom: '10px' }}>
                                <TextField id="outlined-basic" label="Title" variant="outlined" fullWidth error={props.editTitle.length < 5} helperText={props.editTitle.length < 5 ? 'Minimum 5 characters is required' : ''} value={props.editTitle} onChange={props.editOnCchange} />
                            </Box>
                        </Grid>
                        <Grid item lg={6}>
                            <Box style={{ marginTop: '25px', marginBottom: '10px' }}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        className='edit-select'
                                        label="Type"
                                        value={props.editType}
                                        onChange={props.editOnType}
                                        disabled
                                    >
                                        <MenuItem value="personal">Personal</MenuItem>
                                        <MenuItem value="course">Course</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box style={{ marginTop: '15px', marginBottom: '30px', width: "100%", height: "150px", overflow: 'hidden', borderRadius: '10px', flexDirection: 'column', justifyContent: 'center', position: "relative", display: 'flex', alignItems: "center", backgroundColor: '#E6E8EC', boxShadow: '0px 0px 16px rgba(191, 190, 190, 0.350581' }}>
                        {props.editImageDemo
                            ?
                            <>
                                <img src={props.editImageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />
                                <label style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#fff', position: 'absolute', marginTop: '-7%', marginLeft: '75%', textAlign: 'center' }} htmlFor="file" >
                                    <Box ><img style={{ width: '70%', marginTop: '3px' }} src={edit} /></Box>
                                </label>
                                <input id="file" className='input-image-edit' type="file" accept="image/*" style={{ display: 'none' }} onChange={props.editOnImageChange} />
                                <Box id="delete-selected-img" onClick={() => props.onImageDelete()} style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#EF2B4B', position: 'absolute', marginTop: '-7%', marginLeft: '90%', textAlign: 'center' }}><img style={{ marginTop: '3px' }} src={delete_image} /></Box>
                            </>
                            :
                            <>
                                {
                                    props.editImageDemo ?
                                        <img src={props.editImageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />

                                        :
                                        <>
                                            <label htmlFor="file" >

                                                <img src={Upload_icon} />


                                            </label>
                                            <input id="file" className='input-image-edit' type="file" accept="image/*" style={{ display: 'none' }} onChange={props.editOnImageChange} />
                                            <br />
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '10px', color: '#B5B5BE' }}>Drag and Drop or Browse to upload</Typography>
                                            <br />
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '0px', color: '#B5B5BE' }}>Upload upto 15 MB and file dimension should be 768*1024</Typography>
                                        </>
                                }
                            </>
                        }

                    </Box>

                    <Box style={{ margin: '10px 0' }}>
                        <ReactQuill
                            className="react-quill"
                            modules={props.modules}
                            value={props.editDescription}
                            //  onChange={props.editOnCchange1} 
                            theme="snow"
                            onChange={(value) => props.editOnCchange1(value)}
                        />
                    </Box>
                    <Box style={{ width: '100%', textAlign: 'center', marginTop: '15px' }}>
                        <Button id="update-notes-btn" variant="contained" color="primary" style={{ background: '#6c328b', width: '100%', fontWeight: 700, height: '50px', borderRadius: '15px' }}
                            onClick={props.updateNotes}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </Box>
    );
}
// Customizable Area End