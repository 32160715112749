import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { debounce } from "lodash";
import debouce from "lodash.debounce";
import { ChangeEvent } from "react";
import { removeStorageData } from "framework/src/Utilities";
import * as H from "history";
interface PaginationResponse {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

interface Meta {
  pagination: PaginationResponse;
}

export interface CheckboxSelectedListTypes {
  value: string;
  id: string;
}

interface EnrollUserInterface {
  id: number;
  type: string;
  attributes: {
    id: number;
    full_name: string;
  };
}

export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}

export interface Styles {
  Accordion: {
    height: string;
    borderRadius: string;
    boxShadow: string;
    position: string;
  };
  accordionContent: {
    position: string;
    top: string;
    left: string;
    transform: string;
    zIndex: number;
  };
  Accordion_Typography: {
    margin: string;
    fontStyle: string;
    display: string;
    fontWeight: number;
    fontSize: string;
    lineHeight: string;
    textAlign: string;
    color: string;
    textTransform: string;
  };
  AccordionSummary: {
    backgroundColor: string;
    height: string;
    borderRadius: string;
    width: string;
  };
}

export interface ValidResponseType {
  id: string;
  data: [];
  message: "";
  meta: {
    pagination: PaginationResponse;
  };
}

export interface ValidResponseTypeForUpdate {
  data: {
    id: string;
    type: string;
    attributes: {
      course_number: string;
      title: string;
      description: string;
      status: string;
      is_sellable: boolean;
      price: string;
      is_forced_learning: boolean;
      is_notify_when_completed: boolean;
      add_preview: boolean;
      include_launch_date: boolean;
      launch_date: string;
      content_release_schedule: boolean;
      add_thumbnail: boolean;
      add_to_xpand_collection: boolean;
      days_to_complete_the_course: boolean;
      is_promoted: boolean;
      add_due_date: boolean;
      due_date: string;
      add_reward: boolean;
      add_achievement: boolean;
      subscription_period: string;
      created_by: string;
      preview_file: {
        id: number;
        url: string;
      };
      thumbnail_image: {
        id: number;
        url: string;
      };
      categories: {
        id: string;
        type: string;
        attributes: {
          id: number;
          created_at: string;
          updated_at: string;
          name: string;
          is_selected: boolean | null;
          image: {
            id: number;
            url: string;
          } | null;
        };
      }[];
      content_roles: {
        id: number;
        name: string;
      }[];
    };
  };
}

interface Reward {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}
interface Achievement {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}

export interface InvalidResponseType {
  errors: {
    message: string;
  }[];
}

export interface Course {
  id: string;
  title: string;
  errorMessageShow: string;
  editModeBtn: boolean;
  idEvent: string;
  description: string;
  price: string;
  created_at: string;
  updated_at: string;
  thumbnail_image: {
    id: number;
    url: string;
  } | null;
  expand_type: string;
  expert_name: string;
  categories: string[];
  content_roles: string[];
  number: string;
  total_chapters: number;
  average_rating: number;
  user_enrolled: number;
  status: string;
  attributes: {
    type: string;
    title: string;
    number: string;
  };
}

export interface ExpertListing {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    profession: string;
    full_name: string;
    avatar: null;
    is_admin: boolean;
  };
}

export interface AdminCatagoryListing {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface AdminContentRoles {
  id: string;
  type: string;
  attributes: {
    id: number;
    uniq_id: string;
    name: string;
    title: string;
    description: string;
    status: string;
    is_sellable: boolean;
    price: string;
    expert_id: number;
    expert_type: string;
    created_at: string;
    subscription_period: string;
    is_selected: boolean;
    thumbnail_image: null;
  };
}

export interface CoursePreviewRoles {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    course_number: string;
    expert_id: number;
    description: string;
    status: string;
    uniq_id: string;
    content_release_schedule: boolean;
    content_release_schedule_value: string;
    name: string;
    is_sellable: boolean;
    price: string;
    expert_type: string;
    created_at: string;
    subscription_period: string;
    is_selected: boolean;
    updated_at: string;
    due_date: string;
    thumbnail_image: {
      id: number;
      url: string;
    };
    lessons_count: number;
    created_by: string;

    associated_programs: {
      id: number;
      title: string;
      phases: string;
    }[];

    categories: {
      id: number;
      name: string;
      content_roles: string;
    }[];

    contents: {
      id: number;
      type: string;
      attributes: {
        id: number;
        name: string;
        phase_number: number;
        content: {
          id: number;
          lesson_type: string;
          type: string;
          title: string;
          duration: number;
          pdf_page: number;
          description: string;
          sequence_number: number;
        }[];
      };
    }[];
  };
}

export interface AttributesType {
  id: number;
  name: string;
  uniq_id: string;
  lessons_count: number;
  status: string;
  updated_at: string;
  is_selected: boolean;
}
export interface ContentRolesType {
  id: string;
  type: string;
  attributes: AttributesType;
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface ContentRoleDataType {
  id: number;
  name: string;
  oflesson: number;
  lastmodified: string;
  status: string;
}

export interface CheckboxSelectedListType {
  value: string;
  id: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

export interface UserBranchesListing {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isEditCourseIdOpen: boolean;
  data: Array<Reward>;
  enrolledUser: Array<EnrollUserInterface>;
  meta: Meta;
  text: string;
  isPreview: boolean;
  contentType: string;
  open: boolean;
  pagination: Pagination;
  isRewardsActive: string;
  isExist: string;
  filterItems: boolean;
  selectedStatus: string;
  rewardsList: Reward[];
  achievementsList: Achievement[];
  expertAdminList: Array<ExpertListing>;
  contentRoleSelectedItems: Array<CheckboxSelectedListType>;
  contentRoleSearchText: string;
  categorySelectedItems: Array<CheckboxSelectedListType>;
  categorySearchText: string;
  placeholderSelectedItems: Array<CheckboxSelectedListType>;
  placeholderSearchText: string;
  creationDate: string;
  adminCatagoryList: Array<AdminCatagoryListing>;
  adminContentRolesList: Array<AdminContentRoles>;
  contentRolesData: Array<ContentRolesType>;
  anchorEl: null | HTMLElement;
  anchorFilterContainerEl: null | HTMLElement;
  dropdownType: string;
  isCalendarOpen: boolean;
  placeholderParams: string;
  contentRolesParams: string;
  catagoryParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  forFailedId: string;
  searchInputValue: string;
  showList: boolean;
  filteredList: Array<string>;
  coursePreviewData: CoursePreviewRoles | null;
  isOpenContentRoleTable: boolean;
  contentRoleData: ContentRoleDataType[];
  contentRoleTableHeaderData: { name: string; key: keyof AttributesType }[];
  orderBy: string;
  order: string;

  sortConfig: {
    key: keyof AttributesType;
    direction: "asc" | "desc";
  };
  handleDuplicateOpen: boolean;
  duplicateCourseId: string;
  contentRoleTrue: boolean;
  userBranchList: Array<UserBranchesListing>;
  userBranchListParams: string;
  placeholderSearchBranchText: string;
  placeholderSelectsBranchItems: Array<CheckboxSelectedListTypes>;
  openModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  GetBranchListApiCallIds: string = "";
  getCourseAndProgramApiCallId: string = "";
  getRewardsApiCallId: string = "";
  getAchievementsApiCallId: string = "";
  getEnrolledUserCourseApiCallId: string = "";
  updateTableCourseIdAPICallId: string = "";
  updateTableProgramIdAPICallId: string = "";
  updateTableCourseArchivedAPICallId: string = "";
  updateTableProgramArchivedAPICallId: string = "";
  deleteCourseApiCallId: string = "";
  deleteTableProgramApiCallId: string = "";
  expertAdminListingAPICallId: string = "";
  adminContentRolesListingAPICallId: string = "";
  contentRolesApiCallId: string = "";
  adminCatagoryListingAPICallId: string = "";
  getCatalogueAdvanceSearchApiCallId: string = "";
  duplicateCoursesFromTableApiRequestId: string = "";
  coursePreviewApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      isEditCourseIdOpen: false,
      data: [],
      enrolledUser: [],
      meta: {
        pagination: {
          current_page: 0,
          next_page: null,
          prev_page: null,
        },
      },
      text: "",
      contentType: "course",
      open: false,
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      isRewardsActive: "reward",
      isExist: "",
      filterItems: true,
      selectedStatus: "",
      rewardsList: [],
      achievementsList: [],
      expertAdminList: [],
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: "",
      creationDate: "",
      adminCatagoryList: [],
      adminContentRolesList: [],
      contentRolesData: [],
      anchorEl: null,
      anchorFilterContainerEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: "",
      },
      sortConfig: {
        key: "name",
        direction: "asc",
      },
      contentRolesParams: "",
      catagoryParams: "",
      forFailedId: "",
      searchInputValue: "",
      showList: false,
      filteredList: [],
      coursePreviewData: null,
      isOpenContentRoleTable: false,
      contentRoleData: [],
      isPreview: false,
      contentRoleTableHeaderData: [
        { name: "Name", key: "name" },
        { name: "ID", key: "id" },
        { name: "# of Lessons", key: "uniq_id" },
        { name: "Last Modified", key: "updated_at" },
        { name: "Status", key: "status" },
      ],
      orderBy: "name",
      order: "asc",
      handleDuplicateOpen: false,
      duplicateCourseId: "",
      contentRoleTrue: false,
      userBranchList: [],
      userBranchListParams: "",
      placeholderSearchBranchText: "",
      placeholderSelectsBranchItems: [],
      openModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    removeStorageData("UpdateData");
    removeStorageData("save&close");

    if (this.state.text === "") {
      this.getRewards(
        {
          search: "",
          filter: "",
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        this.state.pagination.curPage
      );
      this.getAchievements(
        {
          search: "",
          filter: "",
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        this.state.pagination.curPage
      );
    }

    this.getUserBranchListing();
    this.getAdminExpertListing();

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.GetBranchListApiCallIds === apiRequestCallId) {
        this.setState({ userBranchList: [...responseJson.data] });
      }

      if (this.getRewardsApiCallId === apiRequestCallId) {
        this.setState({ rewardsList: [...responseJson.data] });
      }

      if (this.getAchievementsApiCallId === apiRequestCallId) {
        this.setState({ achievementsList: [...responseJson.data] });
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleListClickSuggestion = (item: string) => {
    this.setState(
      {
        searchInputValue: item,
        showList: false,
      },
      () => {
        if (this.state.isRewardsActive === "reward") {
          this.getRewards(
            {
              search: this.state.searchInputValue,
              filter: this.state.isRewardsActive,
              page: "",
              per: "",
              property: "",
              sortType: "",
            },
            1
          );
        } else if (this.state.isRewardsActive === "achievement") {
          this.getAchievements(
            {
              search: this.state.searchInputValue,
              filter: this.state.isRewardsActive,
              page: "",
              per: "",
              property: "",
              sortType: "",
            },
            1
          );
        }
      }
    );
  };

  handleInputChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value === "") {
      this.getRewards(
        {
          search: "",
          filter: this.state.isRewardsActive,
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        1
      );
    }
    this.setState(
      {
        searchInputValue: value,
        showList: value !== "",
      },
      () => {
        if (value !== "") {
          this.getAdvanceSearch(value);
        }
      }
    );
  };

  placeholderCheckboxUserManageChangeHandlersBranch = (
    event: React.ChangeEvent<HTMLInputElement>,
    itemId: string
  ) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(
        (prevState) => ({
          placeholderSelectsBranchItems: [
            ...prevState.placeholderSelectsBranchItems,
            { value: name, id: itemId },
          ],
        }),
        () => {
          const params = this.state.placeholderSelectsBranchItems
            .map(
              (item: { value: string; id: string }) =>
                `q[branch_ids][]=${item.id}`
            )
            .join("&");
          this.setState({
            userBranchListParams: params,
          });
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          placeholderSelectsBranchItems:
            prevState.placeholderSelectsBranchItems.filter(
              (item: { value: string; id: string }) => item.id !== itemId
            ),
        }),
        () => {
          const params = this.state.placeholderSelectsBranchItems
            .map(
              (item: { value: string; id: string }) =>
                `q[branch_ids][]=${item.id}`
            )
            .join("&");
          this.setState({
            userBranchListParams: params,
          });
        }
      );
    }
  };

  getAdvanceSearch = (value: string) => {
    this.doGetCatalogueAdvanceSearch({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint:
        configJSON.catagoryAdvanceSearch +
        `?type=${this.state.isRewardsActive}&search=${value}`,
    });
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (this.getRewardsApiCallId === apiRequestCallId) {
      const pagination: Pagination = {
        curPage: responseJson?.meta?.pagination?.current_page,
        nextPage: responseJson?.meta?.pagination?.next_page,
        prevPage: responseJson?.meta?.pagination?.prev_page,
      };
      responseJson.data.map((item: Reward) => {});
      this.setState({
        data: responseJson.data,
        meta: responseJson.meta,
        pagination,
      });
    }

    if (this.expertAdminListingAPICallId === apiRequestCallId) {
      this.setState({
        expertAdminList: responseJson.data,
      });
    }

    if (this.getCatalogueAdvanceSearchApiCallId === apiRequestCallId) {
      this.setState({
        filteredList: responseJson.data,
      });
    }
  };

  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    });
  };

  handleClearFilter = () => {
    this.setState(
      {
        userBranchListParams: "",
        anchorFilterContainerEl: null,
        selectedStatus: "",
        contentRolesParams: "",
        catagoryParams: "",
        contentRoleSelectedItems: [],
        contentRoleSearchText: "",
        categorySelectedItems: [],
        placeholderSearchBranchText: "",
        categorySearchText: "",
        placeholderSelectedItems: [],
        placeholderSearchText: "",
        placeholderSelectsBranchItems: [],
        creationDate: "",
        anchorEl: null,
        dropdownType: "",
        isCalendarOpen: false,
        placeholderParams: "",
        creationDateParams: {
          start_date: "",
          end_date: "",
        },
      },
      () => {
        if (this.state.isRewardsActive === "reward") {
          this.getRewards(
            {
              search: "",
              filter: "",
              page: "",
              per: "",
              property: "",
              sortType: "",
            },
            1
          );
        } else if (this.state.isRewardsActive === "achievement") {
          this.getAchievements(
            {
              search: "",
              filter: "",
              page: "",
              per: "",
              property: "",
              sortType: "",
            },
            1
          );
        }
      }
    );
  };

  placeholderDebounced: () => void = debouce(
    () => this.getAdminExpertListing(),
    700
  );

  handlePlaceholderUserBranchSearchChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState(
      {
        placeholderSearchBranchText: event.target.value,
      },
      () => {
        this.placeholderDebouncedBranch();
      }
    );
  };

  placeholderDebouncedBranch: () => void = debounce(
    () => this.getUserBranchListing(),
    700
  );

  handleToggleCourses = () => {
    this.setState(
      {
        isRewardsActive: "reward",
        contentRoleTrue: false,
      },
      () => {
        this.getRewards(
          {
            search: "",
            filter: "",
            page: "",
            per: "",
            property: "",
            sortType: "",
          },
          1
        );
      }
    );
    this.handleClearFilter();
  };

  handleTogglePrograms = () => {
    this.setState(
      {
        isRewardsActive: "achievement",
        contentRoleTrue: false,
      },
      () => {
        this.getAchievements(
          {
            search: "",
            filter: "",
            page: "",
            per: "",
            property: "",
            sortType: "",
          },
          1
        );
      }
    );
    this.handleClearFilter();
  };

  handlePageNavigationNext = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage + 1,
        },
      };
    });
  };

  handlePageNavigationPrev = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage - 1,
        },
      };
    });
  };

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.state.pagination.curPage !== prevState.pagination.curPage) {
      this.getRewards(
        {
          search: "",
          filter: "",
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        this.state.pagination.curPage
      );
    }
  }

  //Get Rewards List
  getRewards = (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number | Pagination
  ) => {
    let customEndPoint = this.constructRewardsEndPoint(params, pagination);
    this.getRewardsApiCallId = this.doGetRewards({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.rewardsAPiEndPoint + customEndPoint,
    });

    return customEndPoint;
  };

  //Get Achievements List

  getAchievements = (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number | Pagination
  ) => {
    let customEndPoint = this.constructRewardsEndPoint(params, pagination);

    this.getAchievementsApiCallId = this.doGetAchievements({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.achievementsAPiEndPoint + customEndPoint,
    });

    return customEndPoint;
  };

  constructRewardsEndPoint(
    params: {
      search: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number | Pagination
  ): string {
    let endPoint = "?";

    endPoint += `sort_value=${params.sortType}&sort=${params.property}&per=50&page=${pagination}`;

    if (params.search) {
      endPoint += `&search=${params.search}`;
    }

    endPoint += this.addPlaceholderParams();
    endPoint += this.addBranchListParams();

    return endPoint;
  }

  private addPlaceholderParams(): string {
    return this.state.placeholderParams
      ? `&${this.state.placeholderParams}`
      : "";
  }

  private addBranchListParams(): string {
    return this.state.userBranchListParams
      ? `&${this.state.userBranchListParams}`
      : "";
  }

  getAdminExpertListing() {
    this.doAdminExpertListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint:
        configJSON.expertAdminListApiEndPoint +
        `?search=${this.state.placeholderSearchText}`,
    });
  }

  getUserBranchListing() {
    this.doUserBranchListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint:
        configJSON.BranchesListingApiEndPoint +
        `?search=${this.state.placeholderSearchBranchText}`,
    });
  }

  placeholderCheckboxAssignedExpertChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    itemId: string
  ) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(
        (prevState) => ({
          placeholderSelectedItems: [
            ...prevState.placeholderSelectedItems,
            { value: name, id: itemId },
          ],
        }),
        () => {
          const params = this.state.placeholderSelectedItems
            .map(
              (item: { value: string; id: string }) =>
                `q[expert_ids][]=${item.id}`
            )
            .join("&");
          this.setState({
            placeholderParams: params,
          });
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          placeholderSelectedItems: prevState.placeholderSelectedItems.filter(
            (item: { value: string; id: string }) => item.id !== itemId
          ),
        }),
        () => {
          const params = this.state.placeholderSelectedItems
            .map(
              (item: { value: string; id: string }) =>
                `q[expert_ids][]=${item.id}`
            )
            .join("&");
          this.setState({
            placeholderParams: params,
          });
        }
      );
    }
  };

  handlePlaceholderAssignedSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState(
      {
        placeholderSearchText: event.target.value,
      },
      () => {
        this.placeholderDebounced();
      }
    );
  };

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value,
    });
  };

  handleClickFilters = () => {
    if (this.state.isRewardsActive === "reward") {
      this.getRewards(
        {
          search: this.state.searchInputValue,
          filter: this.state.isRewardsActive,
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        1
      );
    } else if (this.state.isRewardsActive === "achievement") {
      this.getAchievements(
        {
          search: this.state.searchInputValue,
          filter: this.state.isRewardsActive,
          page: "",
          per: "",
          property: "",
          sortType: "",
        },
        1
      );
    }

    this.setState({
      anchorFilterContainerEl: null,
    });
  };

  dropdownHandlerOpen = (
    event: React.MouseEvent<HTMLDivElement>,
    dropdownType: string
  ) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType,
    });
  };

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
    });
  };

  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true,
    });
  };

  handleChangeCalendor = () => {
    this.setState({
      isCalendarOpen: false,
    });
  };

  doAdminExpertListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.expertAdminListingAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doUserBranchListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.GetBranchListApiCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetCatalogueAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueAdvanceSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetRewards(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetAchievements(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAchievementsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleClose = () => {
    this.setState({
      openModal: false
    });
  };

  handleModalOpen = () => {
    this.setState({
      openModal: true
    });
  };

  handleAchievement = () => {
   this.props.history.push("/CreateAchievement")
  };

  handleRewardScreen = () => {
    this.props.history.push("/CreateReward")
  };

  // Customizable Area End
}