import React, { useState } from 'react'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, styled, Popover, IconButton, Typography, Badge } from '@material-ui/core'
import { BackArrow, UpArrow, DownArrow } from "./assets"
import Loader from '../Loader.web';
import { Table, Tbody, Td, Th, Thead, Tr } from '../CustomTableV3';
import { CalendarValue, ContentData, ExpertAccount } from '../../../blocks/videolibrary/src/VideoLibraryController';
import AdvancedSearch from '../../../blocks/advancedsearch/src/AdvancedSearch.web';
import FilterItems from '../../../blocks/filteritems/src/FilterItems.web';
import { NewFilter } from "../../../blocks/catalogue/src/assets";
import { Filter } from "../../../blocks/imagemanagement2/src/assets"
import { CheckboxSelectedListType } from '../../../blocks/customform/src/CustomFormController.web';
import Visibility from "@material-ui/icons/Visibility";
import { NotFImage } from '../../../blocks/videolibrary/src/assets';


type PickContentProps = {
    isOpen: boolean;
    onCloseModal: () => void;
    isLoading: boolean;
    data?: ContentData[],
    sortHeader: (dataType: string, keyType: string, sortType: string) => void;
    handleSelectedContent: (id: string) => void;
    searchInputValue: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleListItemClick: (value: string) => void;
    filterList: Array<string>;
    isFilterDropActive: boolean;
    showContentLists: boolean;
    anchorFilterContentEl: HTMLElement | null;
    anchorEl: HTMLElement | null;
    filterContentDropdownCloseHandler: () => void;
    navigation: unknown;
    handleFilterOpen?: (event: React.MouseEvent<HTMLDivElement>) => void;
    openFilterContent: boolean;
    handleClearFilter: () => void;
    creationDateChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dropdownHandlerOpen: (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => void;
    handleChangeCalendar: (value: CalendarValue) => void;
    dropdownCloseHandler: () => void;
    handleCalendarOpen: () => void;
    dropdownType: string;
    tagsSearchText: string;
    creatorSearchText: string;
    contentCreationDate: Array<CheckboxSelectedListType>;
    handleClickContentFilter: () => void;
    tagsListing: Array<ExpertAccount>;
    createrNameListing: Array<ExpertAccount>;
    isCalendarOpen: boolean;
    tagsSelectedItems: Array<CheckboxSelectedListType>;
    handleTagsSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCreatorSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tagsCheckboxChangeHandler: (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => void;
    creationCheckboxChangeHandler: (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => void;
    creationSelectedItems: Array<{ value: string, id: string }>;
    creationDateParams: {
        start_date: string;
        end_date: string;
      };
      openPreviewModal:(dataId:number) => void;
}
const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
};

const headershow = [
    { id: 5, label: "", sort: false, dataType: "", key: "" },
    { id: 1, label: "Name", sort: true, dataType: "string", key: "title" },
    { id: 2, label: "Type", sort: true, dataType: "string", key: "content_type" },
    { id: 3, label: "Created by", sort: true, dataType: "string", key: "expert_name" },
    { id: 4, label: "Created on", sort: true, dataType: "date", key: "created_at" },
]

const PickContentFromLibraryModal = (props: PickContentProps) => {
    const {
        isOpen, onCloseModal, isLoading, data,
        sortHeader,
        handleSelectedContent,
        searchInputValue, handleInputChange, handleListItemClick, filterList, isFilterDropActive, showContentLists,
        anchorFilterContentEl, anchorEl,
        navigation, handleFilterOpen, openFilterContent, filterContentDropdownCloseHandler,
        handleClearFilter, creationDateChangeHandler, dropdownHandlerOpen, handleChangeCalendar,
        dropdownCloseHandler, handleCalendarOpen,
        dropdownType, tagsSearchText, creatorSearchText, contentCreationDate,
        handleClickContentFilter, tagsListing, createrNameListing, isCalendarOpen, tagsSelectedItems,
        handleTagsSearchChange, handleCreatorSearchChange, tagsCheckboxChangeHandler, creationCheckboxChangeHandler,
        creationSelectedItems, creationDateParams, openPreviewModal
    } = props


    const [selectedId, setSelectedId] = useState("")

    if (isLoading) {
        return <Loader loading={isLoading} />
    }
    return (
        <DialogWrapper
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{ padding: "0" }}>
                <Box className="heading_wrapper">
                    <Box className="heading_inner">
                        <span style={{ cursor: "pointer" }}>
                            <img src={BackArrow} style={{ cursor: "pointer" }} alt="back_ing" onClick={onCloseModal} />
                        </span>
                        <p className="heading_text">
                            Content Library
                        </p>
                    </Box>
                    <Box>
                        <StyledContentWrapper>
                            <AdvancedSearch
                                data-test-id="btn_change_one"
                                searchInputValue={searchInputValue}
                                handleInputChange={handleInputChange}
                                handleListItemClick={handleListItemClick}
                                filterList={filterList}
                                inputWidth={window.innerWidth < 800 ? "240" : "480"}
                                isFilterDropActive={isFilterDropActive}
                                showList={showContentLists} navigation={undefined} id={""} />
                            <div style={{ marginLeft: "0px" }} className={anchorFilterContentEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={handleFilterOpen}>
                                {tagsSelectedItems.length > 0
                                    || contentCreationDate.length > 0
                                    || (creationDateParams.hasOwnProperty('start_date') && creationDateParams.start_date.length > 0)
                                    || creationSelectedItems.length > 0 ? (
                                    <BadgeComponent color="secondary" variant="dot"></BadgeComponent>
                                ) : null
                                }
                                {anchorFilterContentEl ? (
                                    <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
                                ) : (
                                    <img src={NewFilter} data-test-id="filterOpenTestID" />
                                )}
                            </div>
                            <Popover
                                data-test-id="filterPropsContentID"
                                className="filterContainerPopover"
                                open={openFilterContent}
                                id="myPopover"
                                anchorEl={anchorFilterContentEl}
                                onClose={filterContentDropdownCloseHandler}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                            >
                                <FilterItems
                                    data-test-id="FilterItemsContentTestID"
                                    handleClear={handleClearFilter}
                                    creationDateChangeHandler={creationDateChangeHandler}
                                    dropdownOpenHandler={dropdownHandlerOpen}
                                    handleCalendarChange={handleChangeCalendar}
                                    dropdownCloseHandler={dropdownCloseHandler}
                                    handleCalendarOpen={handleCalendarOpen}
                                    anchorEl={anchorEl}
                                    dropdownType={dropdownType}
                                    categorySelectedItems={[]}
                                    contentRoleSearchText={""}
                                    contentRoleSelectedItems={[]}
                                    categorySearchText={""}
                                    placeholderSelectedItems={[]}
                                    placeholderSearchText={tagsSearchText}
                                    placeholderCreatorSearchText={creatorSearchText}
                                    contentCreationDate={contentCreationDate}
                                    handleClick={handleClickContentFilter}
                                    selectedStatus={""}
                                    tagListing={tagsListing}
                                    adminCatagoryList={[]}
                                    adminContentRolesList={[]}
                                    createrNameListing={createrNameListing}
                                    isCalendarOpen={isCalendarOpen}
                                    tagsSelectedItems={tagsSelectedItems}
                                    tagsSearchText={""}
                                    handleTagsSearchChange={handleTagsSearchChange}
                                    handleCreatorSearchChange={handleCreatorSearchChange}
                                    tagsCheckboxChangeHandler={tagsCheckboxChangeHandler}
                                    creationCheckboxChangeHandler={creationCheckboxChangeHandler}
                                    creationSelectedItems={creationSelectedItems}
                                    fileCheckboxChangeHandler={() => { }}
                                    fileSelectedItems={[]}
                                />

                            </Popover>
                        </StyledContentWrapper>
                    </Box>
                </Box>
            </DialogTitle>
            <>
                <DialogContent>
                    {!data ?
                        <MainNotFoundBox>
                            <MainInnerBox>
                                <Box>
                                    <img src={NotFImage} width={500} />
                                </Box>
                                <NotFoundText>No results founds</NotFoundText>
                            </MainInnerBox>
                        </MainNotFoundBox>
                        :
                        <TableWrapper>
                            <Table>
                                <Thead>
                                    <Tr>
                                        {headershow?.map((header: any, index) => {
                                            return (
                                                <Th key={header.id}>
                                                    <p className="headingLabel">
                                                        {header.label}{" "}
                                                        <span className="arrowIconsContainer">
                                                            {header.sort && (
                                                                <>
                                                                    <img
                                                                        data-test-id={`sort_asc${header.label}`}
                                                                        src={DownArrow}
                                                                        width="12px"
                                                                        onClick={() =>
                                                                            sortHeader(
                                                                                header?.dataType ?? "",
                                                                                header?.key ?? "",
                                                                                "asc"
                                                                            )
                                                                        }
                                                                    />
                                                                    <img
                                                                        data-test-id={`sort_desc${header.label}`}
                                                                        src={UpArrow}
                                                                        width="12px"
                                                                        onClick={() =>
                                                                            sortHeader(
                                                                                header?.dataType ?? "",
                                                                                header?.key ?? "",
                                                                                "desc"
                                                                            )
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </span>
                                                    </p>
                                                </Th>
                                            )
                                        })}

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data?.map((content: ContentData) => {
                                        const BgColors = selectedId === content.id.toString()
                                        return (
                                            <Tr key={content.id} rowBg={BgColors}>
                                                <Td rowBg={BgColors} onClick={() => setSelectedId(content.id.toString())}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-error-radios"
                                                        name="quiz"
                                                        value={selectedId}
                                                        onChange={() => setSelectedId(content.id.toString())}
                                                        data-test-id={`radio_select${content.id}`}
                                                    >
                                                        <FormControlLabel value={content.id} control={<Radio />} label="" />
                                                    </RadioGroup>
                                                    <span>
                                                        <img
                                                            src={content.attributes?.image?.url ?? content.attributes?.thumbnail?.url}
                                                            style={{
                                                                borderRadius: '4px',
                                                                border: '1px solid #929090',
                                                                background: 'lightgray 50% / cover no-repeat',
                                                                boxShadow: '0px 1px 3px 0px rgba(140, 140, 140, 0.72)',
                                                                height: '56px',
                                                                width: '56px',
                                                                marginRight: "20px"
                                                            }}
                                                        />
                                                    </span>
                                                </Td>
                                                <Td rowBg={BgColors} onClick={() => setSelectedId(content.id.toString())}>
                                                    {content.attributes.title}
                                                </Td>
                                                <Td rowBg={BgColors} onClick={() => setSelectedId(content.id.toString())}>
                                                    {content.attributes.content_type}
                                                </Td>
                                                <Td rowBg={BgColors} onClick={() => setSelectedId(content.id.toString())}>
                                                    {content.attributes.expert_name}
                                                </Td>
                                                <Td rowBg={BgColors} onClick={() => setSelectedId(content.id.toString())}>
                                                    {content.attributes.created_at
                                                        ? formatDate(content.attributes.created_at)
                                                        : "-"}
                                                </Td>
                                                <Td rowBg={BgColors}>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => { 
                                                            openPreviewModal(Number(content.id))
                                                        }}
                                                        edge="end"
                                                    >
                                                        <Visibility />
                                                    </IconButton>
                                                </Td>

                                            </Tr>)
                                    })}
                                </Tbody>
                            </Table>
                        </TableWrapper>
                    }
                </DialogContent>
                <DialogActions className="btnWrapper">
                    <button
                        className={selectedId ? "enablebtn btn" : "btn"}
                        onClick={() => {
                            handleSelectedContent(selectedId)
                            setSelectedId('')
                        }}
                        disabled={!selectedId}
                        data-test-id="select_btn"
                    >
                        Select Content
                    </button>
                </DialogActions>
            </>
        </DialogWrapper>
    )
}

export default PickContentFromLibraryModal


const DialogWrapper = styled(Dialog)({
    "& .MuiDialog-paper": {
        maxWidth: "1275px",
        width: "100% !important",
        height: "100%",
        borderRadius: "16px",
        padding: "30px",
        marginLeft: "96px",
        marginTop: "35px",
        "@media(max-width:600px)": {
            margin: "10px",
            marginTop: "80px"
        },
    },
    "& .heading_wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        "@media(max-width:1120px)": {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px"
        },
        "@media(max-width:600px)": {
            alignItems: "center",

        },
    },
    "& .heading_inner": {
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    "& .heading_text": {
        fontSize: "48px",
        fontWeight: 700,
        fontFamily: "Poppins",
        margin: 0,
        padding: 0,

        "@media(max-width:1250px)": {
            fontSize: "35px",
        },
        "@media(max-width:600px)": {
            fontSize: "22px",
        },
    },
    "& .btnWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "@media(max-width:600px)": {
            justifyContent: "center",
        },
    },
    "& .btn": {
        marginTop: "30px",
        width: "228px",
        height: "56px",
        backgroundColor: "#E5E2E1",
        border: "0px",
        borderRadius: "16px",
        color: "#787776",
        fontFamily: "'Poppins', sans-serif" as const,
        fontSize: "16px",
        fontWeight: 600 as const,
        textTransform: "capitalize" as const,
    },
    "& .enablebtn": {
        backgroundColor: "#652786 !important",
        cursor: "pointer",
        color: "#fff"
    },
    "& .headingLabel": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "start",
        "@media(max-width:600px)": {
            fontSize: "18px",
            minWidth: "140px"
        },
    },
    "& .arrowIconsContainer": {
        display: "flex",
        flexDirection: "column",
        margin: "3px 0px 0px 10px",
        "& > img": {
            cursor: "pointer",
        },
        "& > img:first-child": {
            marginBottom: 5,
        },
    },
    "& .MuiRadio-root": {
        color: "#787776",
        padding: "18px 20px"
    },
    "& .MuiFormControlLabel-root": {
        margin: "0px"
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#652786",
        background: "transparent"
    },
    "& .MuiDialogContent-root": {
        padding: "0px"
    }
})

const TableWrapper = styled(Box)({
    width: "100%",
    "& .tr": {
        borderRadius: "12px",
    },
    "& .td": {
        textAlign: "start"
    },
    "& .table tbody .tr": {
        cursor: "pointer"
    },
    "& .td:first-child": {
        display: "flex",
        minWidth: "120px",
        borderBottomLeftRadius: "12px",
        borderTopLeftRadius: "12px"
    },
    "& .td:last-child": {
        borderBottomRightRadius: "12px",
        borderTopRightRadius: "12px"
    },
    "& .table tbody .tr .td:nth-child(3) ,.td:nth-child(4)": {
        width: "320px",
        minWidth: "200px",
        paddingLeft: "20px"
    },
    "& .table thead .tr .th:nth-child(1)": {
        width: "120px"
    },
    "& .table tbody .rowBg": {
        backgroundColor: "#EEE8F2 !important",
    },
    "& .table thead .tr .th:nth-child(2)": {
        "& p": {
            marginLeft: "20px",
            minWidth: "200px",
            justifyContent: "flex-start !important",
        }
    },
    "& .table tbody .tr .td:nth-child(2)": {
        textAlign: "start",
        paddingLeft: "20px",
        width: "180px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    "& .table tbody .tr .td:nth-child(3)": {
        textAlign: "start",
        width: "70px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    "& .BackgroundCss": {
        background: "#EEE8F2"
    }
})

const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    width: "100%",

    "& .filterIconBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        width: "63px",
        borderRadius: "8px",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        cursor: "pointer",
        border: "1px solid #C9C6C5",
        position: "relative",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        borderColor: '#652786',
        backgroundColor: "#EEE8F2",
    }
});



const MainNotFoundBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
});

const MainInnerBox = styled(Box)({
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
});

const NotFoundText = styled(Typography)({
    marginTop: "26px",
    color: "#1C1B1B !important",
    textAlign: "center",
    fontFamily: "Poppins Arial, Helvetica, sans-serif",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-1px"
});

const BadgeComponent = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-14px",
        left: "20px",
        right: " 0px",
        backgroundColor: " #FF883F",
        fontSize: "81px",
        width: "10px",
        height: "10px",
        borderRadius: "20px"
    }
  });