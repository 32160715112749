Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.getListingBasedOnGroup = "/bx_block_dashboard/user_branch_team_list?filter_by=";
exports.getAdminReportGroupApi = "/bx_block_adhocreporting/usage_reports";
exports.groupField = "Group";
exports.nameField = "Name";
exports.dateRangeField = "Date Range";
// Customizable Area End