import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import debouce from "lodash.debounce";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { CourseResponse } from "packages/components/src/ReusableEnums.web";
interface PaginationResponse {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

type Order = "asc" | "desc";

interface Meta {
  pagination: PaginationResponse;
}

interface EnrollUserInterface {
  id: number,
  type: string,
  attributes: {
    id: number,
    full_name: string
  }
}

export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}

export interface Styles {
  Accordion: {
    height: string;
    borderRadius: string;
    boxShadow: string;
    position: string;
  };
  accordionContent: {
    position: string;
    top: string;
    left: string;
    transform: string;
    zIndex: number;
  };
  Accordion_Typography: {
    margin: string;
    fontStyle: string;
    display: string;
    fontWeight: number;
    fontSize: string;
    lineHeight: string;
    textAlign: string;
    color: string;
    textTransform: string;
  };
  AccordionSummary: {
    backgroundColor: string;
    height: string;
    borderRadius: string;
    width: string;
  };
}

export interface ValidResponseType {
  id: string
  data: [];
  message: ""
  meta: {
    pagination: PaginationResponse;
  };
};

export interface ValidResponseTypeForUpdate {
  data: {
    id: string;
    type: string;
    attributes: {
      course_number: string;
      title: string;
      description: string;
      status: string;
      is_sellable: boolean;
      price: string;
      is_forced_learning: boolean;
      is_notify_when_completed: boolean;
      add_preview: boolean;
      include_launch_date: boolean;
      launch_date: string;
      content_release_schedule: boolean;
      add_thumbnail: boolean;
      add_to_xpand_collection: boolean;
      days_to_complete_the_course: boolean;
      is_promoted: boolean;
      add_due_date: boolean;
      due_date: string;
      add_reward: boolean;
      add_achievement: boolean;
      subscription_period: string;
      created_by: string;
      preview_file: {
        id: number;
        url: string;
      };
      thumbnail_image: {
        id: number;
        url: string;
      };
      categories: {
        id: string;
        type: string;
        attributes: {
          id: number;
          created_at: string;
          updated_at: string;
          name: string;
          is_selected: boolean | null;
          image: {
            id: number;
            url: string;
          } | null;
        };
      }[];
      content_roles: {
        id: number;
        name: string;
      }[];
    };
  },
  message?:string;
};


export interface InvalidResponseType {
  errors: {
    message: string;
  }[];
}

export interface Course {
  id: string;
  title: string;
  errorMessageShow: string;
  editModeBtn: boolean;
  idEvent: string;
  description: string;
  price: string;
  created_at: string;
  updated_at: string;
  thumbnail_image: {
    id: number;
    url: string;
  } | null;
  expand_type: string;
  expert_name: string;
  categories: string[];
  content_roles: string[];
  number: string;
  total_chapters: number;
  average_rating: number;
  user_enrolled: number;
  status: string;
  attributes: {
    type: string;
    title: string;
    number: string;
  }
}

export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    profession: string,
    full_name:string,
    avatar: null,
    is_admin: boolean,
  }
}

export interface AdminCatagoryListing {
  id: string,
  type: string,
  attributes: {
    name: string,
  }
}

export interface AdminContentRoles {
id: string;
   type: string;
   attributes:
    { id: number;
uniq_id:string; 
name:string;
title:string 
description:string; 
status:string;
is_sellable: boolean;
price: string;
expert_id: number;
expert_type: string;
created_at: string;
subscription_period: string;
is_selected: boolean;
 thumbnail_image: null;
 };
 }

 interface RewardsType {
    name: string;
    description: string;
    id: number;
    icon: {
      id: number;
      url: string;
    }
 }

export interface CoursePreviewRoles {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    course_number: string;
    expert_id: number;
    description: string;
    status: string;
    uniq_id: string;
    content_release_schedule: boolean;
    content_release_schedule_value: string;
    name: string;
    is_sellable: boolean;
    price: string;
    expert_type: string;
    created_at: string;
    subscription_period: string;
    is_selected: boolean;
    updated_at: string;
    due_date: string;
    thumbnail_image: {
      id: number;
      url: string
    };
    lessons_count: number;
    created_by: string;

    associated_programs: {
      id: number;
      title: string;
      phases: string;
    }[],

    categories: {
      id: number;
      name: string;
      content_roles: string;
    }[];

    contents: {
      id: number;
      type: string;
      attributes: {
        id: number;
        name: string;
        phase_number: number;
        content: {
          id: number;
          lesson_type: string;
          type: string;
          title: string;
          duration: number;
          pdf_page: number;
          description: string;
          sequence_number: number;
        }[];
      }
    }[];
    rewards_data: RewardsType[];
    achievement_data: RewardsType[];
  }
}

export interface AttributesType {
  id: number;
  name: string;
  title: string;
  uniq_id: string;
  lessons_count: number;
  status: string;
  updated_at: string;
  is_selected: boolean;
}
export interface ContentRolesType {
  id: string,
  type: string,
  attributes: AttributesType;
}


interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface ContentRoleDataType {
  id: number;
  name: string;
  oflesson: number;
  lastmodified: string;
  status: string
}

export interface ProgramPreviewType {
  id: string;
  type: string;
  attributes: {
      title: string;
      status: string;
      updated_at: string;
      description: string;
      program_for_sale: boolean;
      is_forced_learning: boolean;
      include_launch_date: boolean;
      content_release_schedule: boolean;
      content_release_schedule_value: string | null;
      price: string;
      add_due_date: boolean;
      due_date: string;
      days_to_complete_the_program: boolean;
      course_timeline: string | null;
      subscription_period: string;
      program_number: string;
      thumbnail_image_id: number;
      number_of_phases: number;
      launch_date: string | null;
      expert_id: number;
      is_notify_when_completed: boolean;
      expert_name: string;
      categories: Category[];
      is_sellable: boolean;
      has_user_purchased: boolean;
      notify_admin_list: Admin[];
      average_rating: number;
      thumbnail_image: Image;
      content_roles: Role[];
      courses_count: number;
      lessons_count: number;
      contents: Content[];
      rewards_data: RewardsType[];
      achievement_data: RewardsType[];
  }
}

type Category = {
  id: string;
  type: string;
  attributes: {
      id: number;
      created_at: string;
      updated_at: string;
      name: string;
      is_selected: boolean | null;
      image: string | null;
  };
};

type Admin = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
};

type Image = {
  id: number;
  url: string;
};

type Role = {
  id: number;
  name: string;
};

type Content = {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string | null;
      phase_number: number;
      courses: ProgramCourse[];
  };
};

export type ProgramCourse = {
  program_course_id: number;
  id: number;
  title: string;
  status: string;
  sequence_number: number;
  description: string;
  average_rating: number;
  expert_name: string;
  content_release_schedule: boolean;
  lessons: Lesson[];
  thumbnail_image: Image;
};

type Lesson = {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string | null;
      phase_number: number;
      content: ContentDetail[];
  };
};

type ContentDetail = {
  id: number;
  lesson_type: string;
  type: string;
  title: string;
  duration: string | null;
  pdf_page: number;
  description: string;
  sequence_number: number;
};

export interface CheckboxSelectedListType { value: string, id: string }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isEditCourseIdOpen:boolean;
  data: Array<Course>;
  enrolledUser: Array<EnrollUserInterface>;
  meta: Meta;
  text: string;
  isPreview:boolean;
  contentType: string;
  open: boolean;
  pagination: Pagination;
  isCourseActive: string;
  isExist: string;
  filterItems: boolean;
  selectedStatus: string;
  expertAdminList: Array<ExpertListing>;
  contentRoleSelectedItems: Array<CheckboxSelectedListType>;
  contentRoleSearchText: string;
  categorySelectedItems: Array<CheckboxSelectedListType>;
  categorySearchText: string;
  placeholderSelectedItems: Array<CheckboxSelectedListType>;
  placeholderSearchText: string;
  creationDate: string;
  adminCatagoryList: Array<AdminCatagoryListing>;
  adminContentRolesList: Array<AdminContentRoles>;
  contentRolesData:Array<ContentRolesType>
  anchorEl: null | HTMLElement;
  anchorFilterContainerEl: null | HTMLElement
  dropdownType: string;
  isCalendarOpen: boolean;
  placeholderParams: string;
  contentRolesParams: string;
  catagoryParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  forFailedId: string;
  searchInputValue: string;
  showList: boolean;
  filteredList: Array<string>;
  coursePreviewData:CoursePreviewRoles| null;
  isOpenContentRoleTable: boolean;
  contentRoleData: ContentRoleDataType[];
  contentRoleTableHeaderData:  {name: string, key: keyof AttributesType}[];
  orderBy:string;
  order:string;

  sortConfig: {
    key: keyof AttributesType;
  direction: 'asc' | 'desc';
  };
  handleDuplicateOpen:boolean;
  duplicateCourseId:string;
  contentRoleTrue:boolean;
  activeId?: string

  isEditContentIdOpen:boolean;
  editContentdata:ContentRolesType;
  updateContentIDErrorMessage: string;
  isProgramPreview: boolean;
  archiveName:string;
  programPreviewData: ProgramPreviewType| null;
  arrowClick: string[];
  toggleDropDown:boolean;
  openArchiveAlert:boolean;
  contentArchiveName:string;
  contentArchiveId:string;
  contentRoleArchived: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getCourseAndProgramApiCallId: string = "";
  getEnrolledUserCourseApiCallId: string = "";
  updateTableCourseIdAPICallId: string = "";
  updateTableProgramIdAPICallId: string = "";
  updateTableCourseArchivedAPICallId: string = "";
  updateTableProgramArchivedAPICallId: string = "";
  deleteCourseApiCallId: string = "";
  deleteTableProgramApiCallId: string = "";
  expertAdminListingAPICallId: string = "";
  adminContentRolesListingAPICallId: string = "";
  contentRolesApiCallId:string = "";
  adminCatagoryListingAPICallId: string = "";
  getCatalogueAdvanceSearchApiCallId: string = "";
  duplicateCoursesFromTableApiRequestId:string = "";
  coursePreviewApiCallId:string = "";
  updateContentIdAPICallId: string = "";
  unarchiveCourseApiCallId: string = "";
  unarchiveProgramApiCallId: string = "";
  programPreviewApiCallId: string = "";
  archiveContentRoleApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      isEditCourseIdOpen:false,
      data: [],
      enrolledUser: [],
      meta: {
        pagination: {
          current_page: 0,
          next_page: null,
          prev_page: null
        }
      },
      text: "",
      contentType: "course",
      open: false,
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      isCourseActive: "course",
      activeId: "",
      isExist: "",
      filterItems: true,
      selectedStatus: "",
      expertAdminList: [],
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: '',
      creationDate: "",
      adminCatagoryList: [],
      adminContentRolesList: [],
      contentRolesData:[],
      anchorEl: null,
      anchorFilterContainerEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      sortConfig: {
        key: 'name', 
        direction: 'asc',
      },
      contentRolesParams: "",
      catagoryParams: "",
      forFailedId: "",
      searchInputValue: '',
      showList: false,
      filteredList: [],
      coursePreviewData:null,
      isOpenContentRoleTable: false,
      contentRoleData: [],
      isPreview:false,
      contentRoleTableHeaderData: [
      {name:"Name",
        key:"title"
      },
      {name:"ID",
      key:"id"
    },
    {name:"# of Lessons",
    key:"uniq_id"
  },
      {name:"Last Modified",
    key:"updated_at"
    },
      {name:"Status",
        key:"status"
      },],
        orderBy:"title",
        order:"asc",
        handleDuplicateOpen:false,
        duplicateCourseId:"",
        contentRoleTrue:false,
        isEditContentIdOpen: false,
        editContentdata: {} as ContentRolesType,
        updateContentIDErrorMessage: "",
        archiveName:'',
        isProgramPreview: false,
        programPreviewData: null,
        arrowClick: [],
        toggleDropDown: false,
        openArchiveAlert:false,
        contentArchiveName:'',
        contentArchiveId:'',
        contentRoleArchived: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    removeStorageData("UpdateData")
    removeStorageData("save&close")
    if (this.state.text === "") {
      this.getCourse({ search: "", filter: "course", page: "", per: "", }, this.state.pagination.curPage)
    }
    const coursePreviewId = await getStorageData("coursePreview",true)
    if(coursePreviewId){
      await setStorageData("coursePreview","false")
      this.handlePreview(coursePreviewId);
    } 
    this.getAdminExpertListing();
    this.getAdminCategoryListing();
    this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage);

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.coursePreviewApiCallId === apiRequestCallId) {
  
        this.setState({
          coursePreviewData: responseJson.data
        })
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
      if (this.isValidResponseTwo(responseJson)) {
        this.apiSuccessCallBacksTwo(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId === this.duplicateCoursesFromTableApiRequestId){
        this.handleDuplicateResponse(responseJson)
      }
      else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
      if (this.programPreviewApiCallId === apiRequestCallId) {
        this.setState({
          programPreviewData: responseJson.data
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleisEditCourseIdOpenClose = () => {
    this.setState((prevState) => ({
      isEditCourseIdOpen: !prevState.isEditCourseIdOpen,
      activeId: ""
    }));
  };

  handleisEditContentIdOpenClose = (editContentData:ContentRolesType) => {
    this.setState((prevState) => ({
      isEditContentIdOpen: !prevState.isEditContentIdOpen,
      editContentdata: editContentData,
      updateContentIDErrorMessage: "Automatic ID Assigned"
    }));
  };

  handleChangeContentId = (contentId: string) => {
    this.setState({
      editContentdata: {
        ...this.state.editContentdata,
        attributes: {
          ...this.state.editContentdata.attributes,
          uniq_id: contentId
        }
      }
    })
  }

  handleListItemClickSuggestion = (item: string) => {
    this.setState({
      searchInputValue: item,
      showList: false
    }, () => {
      if (this.state.isCourseActive === 'contentRole') {
        this.getAdminContentRolesListing({
          search: this.state.searchInputValue,
          page: "",
          per: "",
        }, 1)
      } else {
        this.getCourse({
          search: this.state.searchInputValue,
          filter: this.state.isCourseActive,
          page: "",
          per: "",
        }, 1)
      }
    })
  }
  
  handleInputCatalogueChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value === "") {
      if(this.state.isCourseActive === 'contentRole'){
        this.getAdminContentRolesListing({
          search: "",
          page: "",
          per: "",
        }, 1)
      }else{
        this.getCourse({
          search: "",
          filter: this.state.isCourseActive,
          page: "",
          per: "",
        }, 1)
      }
    }
    this.setState({
      searchInputValue: value,
      showList: value !== "",
    }, () => {
      if (value !== "") {
        this.getCatalogueAdvanceSearch(value);
      }
    });
  }

  getCatalogueAdvanceSearch = (value: string) => {
    if (this.state.isCourseActive === 'contentRole') {
      this.doGetCatalogueAdvanceSearch({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.contentRoleListingApiEndPoint}?search=${value}`,
      });
    } else {
      this.doGetCatalogueAdvanceSearch({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.catagoryAdvanceSearch + `?search=${value}&type=${this.state.isCourseActive}`,
      });
    }

  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isValidResponseTwo = (responseJson: ValidResponseType) => {
    return responseJson.data || responseJson.message
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson.errors;
  };

  apiSuccessCallBacksTwo = (apiRequestCallId: string, responseJson: ValidResponseTypeForUpdate) => {
    if (this.updateTableCourseIdAPICallId === apiRequestCallId) {
      let forMessage = this.state.data;
      let idToUpdate = responseJson.data.id;
      this.handleUpdateAndAPICall(forMessage, idToUpdate, "course");
      this.handleisEditCourseIdOpenClose();
    }

    if (this.updateTableProgramIdAPICallId === apiRequestCallId) {
      let forMessage = this.state.data;
      let idToUpdate = responseJson.data.id;
      this.handleUpdateAndAPICall(forMessage, idToUpdate, "program");
    }

    if(this.updateContentIdAPICallId === apiRequestCallId) {
      this.handleContnetAPCall();
    }

    if (this.updateTableCourseArchivedAPICallId === apiRequestCallId) {
      toast.success(responseJson.message ?? `${this.state.archiveName} has been archived`, { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "course",
        page: "",
        per: "",
      }, 1)
      this.setState({archiveName:''});
    }

    if (this.updateTableProgramArchivedAPICallId === apiRequestCallId) {
      toast.success(responseJson.message, { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "program",
        page: "",
        per: "",
      }, 1)
      this.setState({archiveName:''});
    }

    if(this.unarchiveCourseApiCallId === apiRequestCallId){
      toast.success(responseJson.message, { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "course",
        page: "",
        per: "",
      }, 1)
      this.setState({archiveName:''});
    }

    if(this.unarchiveProgramApiCallId === apiRequestCallId){
      toast.success(responseJson.message, { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "program",
        page: "",
        per: "",
      }, 1)
      this.setState({archiveName:''});
    }

    if (this.deleteCourseApiCallId === apiRequestCallId) {
      toast.success("Course Deleted Successfully", { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "course",
        page: "",
        per: "",
      }, 1)
    }

    if (this.deleteTableProgramApiCallId === apiRequestCallId) {
      toast.success("Program Deleted Successfully", { autoClose: 1000 });

      this.getCourse({
        search: this.state.text,
        filter: "program",
        page: "",
        per: "",
      }, 1)
    }

    if (this.archiveContentRoleApiCallId === apiRequestCallId) {
      toast.success(responseJson.message);
      this.closeArchiveModal();
      this.getAdminContentRolesListing({
        search: this.state.searchInputValue,
        page: "",
        per: ""
      }, 1);
    }
  };

  handleUpdateAndAPICall = (forMessage: Course[], idToUpdate: string, filter: string) => {
    const forMessageIndex = forMessage.findIndex((item: Course) => Number(item.id) === Number(idToUpdate));
    if (forMessageIndex !== -1) {
      forMessage[forMessageIndex].attributes.number = forMessage[forMessageIndex].idEvent;
      forMessage[forMessageIndex].errorMessageShow = "Id correctly edited.";
      this.setState({
        isExist: "success",
        data: forMessage
      });
    }

    this.getCourse({
      search: this.state.text,
      filter: filter,
      page: "",
      per: "",
    }, 1);
    this.setState({
      isExist: "success"
    });
  };

  handleContnetAPCall = () => {
    this.setState({updateContentIDErrorMessage: "Id correctly edited."})
    
    this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage);
    setTimeout(() => {
      this.setState({isEditContentIdOpen: false, editContentdata: {} as ContentRolesType})
    }, 1000)
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (this.getCourseAndProgramApiCallId === apiRequestCallId) {
      const pagination: Pagination = {
        curPage: responseJson?.meta?.pagination?.current_page,
        nextPage: responseJson?.meta?.pagination?.next_page,
        prevPage: responseJson?.meta?.pagination?.prev_page,
      }
      responseJson.data.map((item: Course) => {
        item.errorMessageShow = "Automatic ID Assigned"
        item.idEvent = item.attributes.number
        item.editModeBtn = false
      })
      this.setState({ data: responseJson.data, meta: responseJson.meta, pagination });
    }

    if (this.expertAdminListingAPICallId === apiRequestCallId) {
      
      this.setState({
        expertAdminList: responseJson.data
      })
    }

    if (this.adminCatagoryListingAPICallId === apiRequestCallId) {
      this.setState({
        adminCatagoryList: responseJson.data
      })
    }

    if (this.adminContentRolesListingAPICallId === apiRequestCallId) {
      this.setState({
        adminContentRolesList: responseJson.data
      })
    }

    if (this.contentRolesApiCallId === apiRequestCallId) {
      this.setState({
        contentRolesData: responseJson.data
      })
      
    }

    if (this.getEnrolledUserCourseApiCallId === apiRequestCallId) {
      this.setState({
        enrolledUser: responseJson.data
      })
    }

    if (this.getCatalogueAdvanceSearchApiCallId === apiRequestCallId) {
      if (this.state.isCourseActive === 'contentRole') {
        this.setState({ filteredList: responseJson.data.map((item: any) => item.attributes.title) })
      } else {
        this.setState({
          filteredList: responseJson.data
        })
      }
    }

  }

  handleErrorMessage = (forFailedId: string, errorMessage: string) => {
        let forMessage = this.state.data;
    let forMessageIndex = forMessage.findIndex((item: Course) => Number(item.id) === Number(forFailedId));
    if (forMessageIndex !== -1) {
      forMessage[forMessageIndex].errorMessageShow = errorMessage;
      this.setState({
        isExist: "success",
        data: forMessage
      });
    }
    this.setState({
      isExist: "failed"
    });
  }

  errorsInvalidId = (responseJSON: InvalidResponseType) => {
    responseJSON.errors.forEach((error) => {
      if (error.message === "Course ID must start with 'CR' followed by minimum 1 numeric number" || error.message === "Course ID has already exist. Please try a different one." ) {
        this.handleErrorMessage(this.state.forFailedId, error.message);
      }
    });
  }
  
  errorsInvalidProgramID = (responseJSON: InvalidResponseType) => {
    responseJSON.errors.forEach((error) => {
      if (error.message === "Program ID must start with 'PR' followed by minimum 1 numeric number" || error.message === "Program ID has already exist. Please try a different one.") {
        this.handleErrorMessage(this.state.forFailedId, error.message);
      }
    });
  }

  errorsInvalidContentID = (responseJSON: InvalidResponseType) => {
    responseJSON.errors.forEach((error) => {
      if (error.message) {
        this.setState({updateContentIDErrorMessage: error.message})
      }
    });
  }

  apiFailureCallBacks = (apiRequestCallId: string, responseJSON: InvalidResponseType) => {
    if (this.updateTableCourseIdAPICallId === apiRequestCallId) {
      if (Array.isArray(responseJSON.errors) && responseJSON.errors.length > 0) {
        this.errorsInvalidId(responseJSON)
      }
    }

    if (this.updateTableProgramIdAPICallId === apiRequestCallId) {
      if (Array.isArray(responseJSON.errors) && responseJSON.errors.length > 0) {
        this.errorsInvalidProgramID(responseJSON)
      }
    }

    if (this.updateContentIdAPICallId === apiRequestCallId) {
      if (responseJSON.errors.length > 0) {
        this.errorsInvalidContentID(responseJSON)
      }
    }

    if (this.getCourseAndProgramApiCallId === apiRequestCallId) {
      this.setState({
        data: [], meta: {
          pagination: {
            current_page: 0,
            next_page: null,
            prev_page: null
          }
        }
      })
    }
  };

  NewCourse = () => {
    removeStorageData("currentCourseID")
    removeStorageData("thumbnailImg")
    this.props.navigation.navigate("CourseCreation", {
      id: "new",
      type: "addCourse",
    });
  };

  NewProgram = () => {
    this.props.navigation.navigate("ProgramCreation", {
      id: "new",
      type: "addProgram",
    });
  };

  redirectContentRolePage = () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage),"contentRoleCreation");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(toNavigate);
  }

  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    })
  }

  
  handleClearFilter = () => {
    this.setState({
      anchorFilterContainerEl: null,
      selectedStatus: "",
      contentRolesParams: "",
      catagoryParams: "",
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: '',
      creationDate: "",
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      }
    }, () => {
      if(this.state.contentRoleTrue){
        this.getAdminContentRolesListing({ 
          search: this.state.searchInputValue, 
          page: "", 
          per: "" },1);    
      }else{
        this.getCourse({
          search: this.state.text,
          filter: this.state.isCourseActive,
          page: "",
          per: "",
        }, 1);
      }
     
    })
  }

  placeholderDebounced: () => void = debouce(
    () => this.getAdminExpertListing(),
    700
  )

  handleToggleCourses = () => {
    this.setState({
      isCourseActive: "course",
      contentRoleTrue:false
    }, () => {
      this.getCourse({
        search: this.state.text,
        filter: "course",
        page: "",
        per: "",
      }, 1)
    })
    this.handleClearFilter()
  };

  handleTogglePrograms = () => {
    this.setState({
      isCourseActive: "program",
      contentRoleTrue:false
    }, () => {
      this.getCourse({
        search: this.state.text,
        filter: "program",
        page: "",
        per: "",
      }, 1)
    })
    this.handleClearFilter()
  };

 

  handleToggleContentRole = () => {
    this.setState({
      isCourseActive: "contentRole",
      contentRoleTrue:true
    },()=>{this.getAdminContentRolesListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)})
    this.handleClearFilter()

  };

  handlePageNavigationNext = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage + 1,
        }
      }
    })
  };

  handlePageNavigationPrev = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage - 1,
        }
      }
    })
  };


  handleSort = (key: keyof AttributesType) => {
    const { contentRolesData, sortConfig } = this.state;
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...contentRolesData].sort((a, b) => {
      if (key === 'updated_at') {
        const dateA = new Date(a.attributes[key]);
        const dateB = new Date(b.attributes[key]);
        return direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else if (typeof a.attributes[key] === 'string') { 
        return direction === 'asc'
          ? (a.attributes[key] as string).localeCompare(b.attributes[key] as string)
          : (b.attributes[key] as string).localeCompare(a.attributes[key] as string);
      } else {
        return direction === 'asc'
          ? (a.attributes[key] as number) - (b.attributes[key] as number)
          : (b.attributes[key] as number) - (a.attributes[key] as number);
      }
    });

    this.setState({
      contentRolesData: sortedData,
      sortConfig: { key, direction },
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null
    })
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    if (this.state.pagination.curPage !== prevState.pagination.curPage) {
      this.getCourse({ search: "", filter: this.state.contentType, page: "", per: "", }, this.state.pagination.curPage)
    }
  }


  apiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  updateCourseIdApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = valueData;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    let apiBody = body;
    if (type === "") {
      apiBody = JSON.stringify(body);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleEditSave = async (course_number: string | number, idSave: number, expand_type: string | number) => {
    let editIdData = this.state.data;
    let editIdDataIndex: number = this.state.data.findIndex((item: Course) => Number(item.id) === Number(idSave));

    if (expand_type === "Course") {
      let body = {
        course: {
          course_number: String(editIdData[editIdDataIndex].idEvent)
        }
      }
      this.updateTableCourseIdAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutMethod,
        endPoint: configJSON.putCourseIdApiEndPoint + idSave,
        body: body,
      })
    } else {
      let body = {
        program:
        {
          program_number: String(editIdData[editIdDataIndex].idEvent)
        }
      }
      this.updateTableProgramIdAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutMethod,
        endPoint: configJSON.putProgramIdApiEndPoint + idSave,
        body: body,
      })
    }
  };

  updateEditContentId = async (contentNumber: string, contentId: number) => {
    let body = {
      content_role: {
        uniq_id: contentNumber
      }
    }
    this.updateContentIdAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.examplePutMethod,
      endPoint: configJSON.putContentIdApiEndPoint + contentId,
      body: body,
    })
  };

  handleEditCourse = (editId: string, eventType: string, event: { target: { value: React.SetStateAction<string | number>; }; }) => {
    let editIdData = this.state.data;
    let editIdDataIndex: number = this.state.data.findIndex((item: Course) => Number(item.id) === Number(editId));
    if (editIdDataIndex !== -1) {
      if (eventType === 'editModeBtn') {
        this.handleisEditCourseIdOpenClose();
        editIdData[editIdDataIndex].editModeBtn = true;
      } else if (eventType === 'errorMessageShow') {
        editIdData[editIdDataIndex].errorMessageShow = "Automatic ID Assigned";
      } else if (eventType === 'idEvent') {
        let eventItem = event.target.value as string;
        editIdData[editIdDataIndex].idEvent = eventItem;
        this.setState({
          forFailedId: editId
        })
      }
      this.setState({
        data: editIdData,
        activeId: editId
      });
    }
  }

  handlePreview =(idValues:string, expandType: string | null = null)=>{
    if (expandType && expandType === "Course") {
      this.setState({isPreview:true})
      this.getCoursePreviewListing(idValues)
    } else if (expandType && expandType === "Program") {
      this.setState({isProgramPreview:true})
      this.getProgramPreview(idValues)
    }
  }

  handlePreviewClose =()=>{
    this.setState({isPreview:false, isProgramPreview: false})
  }

  deleteItemNew(idDelete: string | number, expand_type: string | number) {
    if (expand_type === "Course") {
      this.doDeleteCourseItem({
        contentType: configJSON.validationApiContentType,
        method: configJSON.deleteApiMethod,
        endPoint: configJSON.putTableCourseIdEndPoint + idDelete
      });
    } else {
      this.deleteProgramItem({
        contentType: configJSON.validationApiContentType,
        method: configJSON.deleteApiMethod,
        endPoint: configJSON.putTableProgramIdEndPoint + idDelete
      });
    }
  }

  archivedItemNew = async (idArchived: string | number, expand_type: string | number, title: string) => {
    this.setState({archiveName: title})
    
    if (expand_type === "Course") {
      this.updateTableCourseArchivedAPICallId = await this.updateCourseIdApiCall({
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.putTableCourseIdEndPoint}${idArchived}/archive`,
      })
    } else {
      this.updateTableProgramArchivedAPICallId = await this.updateCourseIdApiCall({
        method: configJSON.apiMethodTypeGet,
        endPoint: `/${configJSON.putTableProgramIdEndPoint}${idArchived}/archive`,
      })
    }
  }

  unArchivedItemNew = async (idArchived: string | number, expand_type: string | number, title: string) => {
    this.setState({archiveName: title})
    
    if (expand_type === "Course") {
      this.unarchiveCourseApiCallId = await this.updateCourseIdApiCall({
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.putTableCourseIdEndPoint}${idArchived}/unarchive`,
      })
    } else {
      this.unarchiveProgramApiCallId = await this.updateCourseIdApiCall({
        method: configJSON.apiMethodTypeGet,
        endPoint: `/${configJSON.putTableProgramIdEndPoint}${idArchived}/unarchive`,
      })
    }
  }

  getCourse(params: { search: string, filter: string, page: string, per: string }, pagination: number | Pagination) {
    let customEndPoint = this.constructEndPoint(params, pagination);


    this.doGetCourseAndProgram({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.courseAPiEndPoint + customEndPoint,
    });

    return customEndPoint;

  }

  constructEndPoint(params: { search: string, filter?: string, page: string, per: string }, pagination: number | Pagination): string {
    let endPoint = "?";

    if (!this.state.contentRoleTrue && params.filter) {
        endPoint += `course_type=${params.filter}&`;
    }
    endPoint += `per=${params.per}&page=${pagination}`;
    
    if (params.search) {
      endPoint += `&search=${params.search}`;
    }
    endPoint += this.addStatusParam();
    endPoint += this.addPlaceholderParams();
    endPoint += this.addContentRolesParams();
    endPoint += this.addCategoryParams();
    endPoint += this.addCreationDateParam();
    endPoint += this.addCreationDateRangeParam();

    return endPoint;

  }

  private addStatusParam(): string {
    return this.state.selectedStatus ? `&q[status]=${this.state.selectedStatus}` : '';
  }

  private addPlaceholderParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
  }

  private addContentRolesParams(): string {
    return this.state.contentRolesParams ? `&${this.state.contentRolesParams}` : '';
  }

  private addCategoryParams(): string {
    return this.state.catagoryParams ? `&${this.state.catagoryParams}` : '';
  }

  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `&q[created_at]=${dateParam}` : ''
  }

  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParams;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `&q[custom_range]=${queryString}`;
    }
    return '';
  }



  private getDateParam(): string {
    switch (this.state.creationDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }

  deleteProgramItem(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteTableProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  doDeleteCourseItem(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getEnrolledUser(enrollId: string | number, expandType: string | number) {
    if (expandType === "Course") {
      this.doGetEnrolledUserCourse({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.EnrolledUserAPiEndPoint + `?courseable_id=${enrollId}&courseable_type=BxBlockCoursecreation::Course`,
      });
    } else {
      this.doGetEnrolledUserCourse({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.EnrolledUserAPiEndPoint + `?courseable_id=${enrollId}&courseable_type=BxBlockCoursecreation::Program`,
      });
    }
  }


  getAdminExpertListing() {
    this.doAdminExpertListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.expertAdminListApiEndPoint + `?search=${this.state.placeholderSearchText}`,
    });
  }

  getAdminCategoryListing() {
    this.doAdminCategoryListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.catagoryListingApiEndPoint + `?search=${this.state.categorySearchText}`,
    });
  }

  getAdminContentRolesListing(params: { search: string, page: string, per: string }, pagination: number | Pagination) {
    let customEndPoint = this.constructEndPoint(params, pagination);
    this.doAdminContentRolesListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.contentRoleListingApiEndPoint,
    });
    this.doContentRolesListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.contentRoleListingApiEndPoint + customEndPoint,
    })
    // return customEndPoint;
  }

  placeholderCheckboxAssignedExpertChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectedItems: [...prevState.placeholderSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectedItems: prevState.placeholderSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    }
  }

  handlePlaceholderAssignedSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchText: event.target.value
    }, () => {
      this.placeholderDebounced();
    })
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value
    })
  }

  contentRoleCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        contentRoleSelectedItems: [...prevState.contentRoleSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        contentRoleSelectedItems: prevState.contentRoleSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    }
  }

  handleContentRoleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contentRoleSearchText: event.target.value
    })
  }

  categoryCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    this.setState(prevState => {
      let updatedItems;
      if (checked) {
        updatedItems = [...prevState.categorySelectedItems, { value: name, id: itemId }];
      } else {
        updatedItems = prevState.categorySelectedItems.filter(item => item.id !== itemId);
      }

      const params = this.buildCategoryParams(updatedItems);
      return { categorySelectedItems: updatedItems, catagoryParams: params };
    });
  }

  buildCategoryParams = (items: { value: string, id: string }[]) => {
    const categoryIdsKey = this.state.isCourseActive === "course" ? 'category_ids_c' : 'category_ids_p';
    return items.map(item => `q[${categoryIdsKey}][]=${item.id}`).join('&');
  }

  handleCategoryChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categorySearchText: event.target.value
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorEl: null,
      dropdownType: ""
    })
  }

  handleClickFilter = () => {
    if(this.state.contentRoleTrue){
    this.getAdminContentRolesListing({ 
      search: this.state.searchInputValue, 
      page: "", 
      per: "" },1);
      
    }else{
      this.getCourse({
        search: this.state.text,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1);
    }
  
    this.setState({
      anchorFilterContainerEl: null
    })
  }

  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false
    })
  }

  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true
    })
  }

  handleChangeCalendor = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationDate: dateRange,
      creationDateParams: {
        start_date,
        end_date
      }
    });
  }

  doAdminCategoryListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminCatagoryListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  doAdminContentRolesListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminContentRolesListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  doContentRolesListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.contentRolesApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doAdminExpertListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.expertAdminListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetEnrolledUserCourse(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getEnrolledUserCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetCatalogueAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueAdvanceSearchApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetCourseAndProgram(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseAndProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleEditCourseData = (id: number | undefined) =>{
    if (id && this.state.isCourseActive === "course") {
      this.props.navigation.navigate("CourseCreation", {type: "editCourse",id: id});
      this.state.isPreview ? setStorageData("ispreviewModalOpne",true) : setStorageData("ispreviewModalOpne","false")
    } else if (id && this.state.isCourseActive === "program") {
      this.props.navigation.navigate("ProgramCreation", {type: "editProgram",id: id});
    }
  }

  handleEditProgramData = (id: number | undefined) =>{
    if (id) {
      this.props.navigation.navigate("ProgramCreation", {type: "editProgram",id: id});
    }
  }

  navigateToEditContentRole = (id: number) => { this.props.navigation.navigate("contentRoleEdit", {id: id})}

  navigateToDuplicateContentRole = (id: number) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "contentRoleCreation");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationCustomFormMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationCustomFormMessage),
      { id, isDuplicateConentRole: true }
    );
    toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigate);
  }

   handleEditCourseContent = async (isPhases:boolean | undefined,id: number | undefined) =>{
     const type = isPhases ? "Phases" : "AddPhases"
    if (id && this.state.isCourseActive === "course") {
      await setStorageData("EditCourseContent",true)
      this.props.navigation.navigate("AddContentPage", {type: type, id: id, lesson: "lessonAdded" });
    } else if (id && this.state.isCourseActive === "program") {
      await setStorageData("EditProgramContent",true)
      this.props.navigation.navigate("ProgramContent", {type: type, id: id });
    }
  }

  handleEditProgramContent = async (isPhases:boolean | undefined,id: number | undefined) =>{
    const type = isPhases ? "Phases" : "AddPhases"
   if (id) {
     await setStorageData("EditProgramContent",true)
     this.props.navigation.navigate("ProgramContent", {type: type, id: id });
   }
 }

  handleDuplicate = (duplicateId:string) =>{
    this.setState({handleDuplicateOpen:true , duplicateCourseId:duplicateId})
  }
  handleCloseDuplicate = () =>{
    this.setState({handleDuplicateOpen:false})
  }
  handleDuplicateSubmit = ()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.duplicateCoursesFromTableApiRequestId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isCourseActive === "course" ? `${configJSON.duplicateCourseApi}/${this.state.duplicateCourseId}/duplicate_course` : `${configJSON.getDuplicateProgramApiEndPoint}${this.state.duplicateCourseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isCourseActive === "course" ? configJSON.exampleAPiMethod : configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  handleDuplicateResponse = (responseJson:CourseResponse) =>{
    if(responseJson.data){
      this.handleCloseDuplicate();
      toast.success(this.state.isCourseActive === "course" ? configJSON.duplicateSuccessTxt : configJSON.duplicateProgramSuccessTxt);
      this.getCourse({ search: "", filter: this.state.isCourseActive, page: "", per: "", }, this.state.pagination.curPage)
    }else{
      this.handleCloseDuplicate();
      toast.error(responseJson.errors[0].message, { autoClose: 2000 });
    }
  }


  doCoursePreviewListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );this.coursePreviewApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

getCoursePreviewListing(id:string) {
    this.doCoursePreviewListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.coursePreview + id,
    });
  }

// Function that will fetch the preview data from an API
fetchProgramPreview(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.programPreviewApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

// Function that calls and passes the needed data for fetching preview data
getProgramPreview(id:string) {
  this.fetchProgramPreview({
    contentType: configJSON.validationApiContentType,
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.getProgramPreviewApiEndPoint + id,
  });
}

// Handles rotating of arrow icon
rotateArrowIcon =(arrowId: string)=> {
  const match = this.state.arrowClick.find(item => item === arrowId);
  if(match){
    this.setState({arrowClick:this.state.arrowClick.filter(item => item !== arrowId)})
  }else {
    this.setState({arrowClick : [...this.state.arrowClick, arrowId]})
  }
  
}

// Prevent Click Propagation
handlePropagation = (event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()

openArchiveModal = (archiveName:string, archiveId:string, isArchived:boolean = false)=>{
  this.setState({
    contentArchiveId:archiveId, 
    contentArchiveName: archiveName, 
    openArchiveAlert:true,
    contentRoleArchived:isArchived,
  });
}

closeArchiveModal = () =>{
  this.setState({openArchiveAlert:false});
}

  handleArchiveContent = (archiveId:string, isArchived:boolean = false) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const endPoint = isArchived ? `${configJSON.contentRoleListingApiEndPoint}/unarchive?id=${archiveId}` : `${configJSON.contentRoleListingApiEndPoint}/archive?id=${archiveId}`;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.archiveContentRoleApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
