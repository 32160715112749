import React from "react";

// Customizable Area Start
import {
  Button,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Modal,
  CircularProgress,
} from "@material-ui/core";

// Customizable Area End

import CallRecordingController, {
  Props,
  RecordingDetail,
  configJSON,
} from "./CallRecordingController.web";

// Customizable Area Start
// Customizable Area End

export default class CallRecording extends CallRecordingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSearch = () => {
    return (
      <Box>
        <Box>
          <TextField
            data-test-id="room-id-input"
            label={configJSON.enterRoomId}
            variant="standard"
            value={this.state.roomId}
            onChange={(event) => this.handleRoomIdChange(event.target.value)}
            error={Boolean(this.state.roomIdError)}
            helperText={this.state.roomIdError}
          />
          <Button
            data-test-id="room-id-submit"
            onClick={this.getAllRecordings}
            style={webStyle.buttonStyle}
            variant="contained"
          >
            {configJSON.getRecordings}
          </Button>
        </Box>
        <Box>
          <TextField
            data-test-id="recording-id-input"
            label={configJSON.enterRecordingId}
            variant="standard"
            value={this.state.recordingId}
            onChange={(event) =>
              this.handleRecordingIdChange(event.target.value)
            }
            error={Boolean(this.state.recordingIdError)}
            helperText={this.state.recordingIdError}
          />
          <Button
            data-test-id="recording-id-submit"
            onClick={this.getRecording}
            style={webStyle.buttonStyle}
            variant="contained"
          >
            {configJSON.search}
          </Button>
        </Box>
      </Box>
    );
  };

  renderCallRecordingList = () => {
    return (
      <Box sx={webStyle.callRecordingsContainer}>
        <Typography variant="h5" gutterBottom>
          {configJSON.callRecordings}
        </Typography>

        <List>
          {this.state.allRecordings.length === 0 && (
            <Typography
              style={webStyle.noRecordingTextStyle}
              variant="subtitle2"
              gutterBottom
            >
              {configJSON.noRecording}
            </Typography>
          )}
          {this.state.allRecordings.map((recording) => (
            <Box key={recording.id}>
              <ListItem style={webStyle.recordingItemStyle}>
                <ListItemText
                  style={webStyle.itemTextStyle}
                  primary={recording.fileId}
                />
                <Button
                  data-test-id="recording-details-button"
                  onClick={() => this.handleRecordingDetailModalOpen(recording)}
                  style={webStyle.buttonStyle}
                  variant="contained"
                >
                  {configJSON.view}
                </Button>
                <Button
                  data-test-id="recording-delete-button"
                  onClick={() => this.deleteRecording(recording.id)}
                  style={webStyle.deleteButtonStyle}
                  variant="contained"
                >
                  {configJSON.delete}
                </Button>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>
          ))}
        </List>
      </Box>
    );
  };

  renderRecordingDetailsModal = () => {
    return (
      <Modal
        open={this.state.recordingDetailModalOpen}
        onClose={this.handleRecordingDetailModalClose}
      >
        <Box sx={webStyle.modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {configJSON.recordingDetails}
          </Typography>
          {Object.keys(this.state.recordingDetail).map((detailKey) => (
            <Box sx={webStyle.recordingDetailContainer} key={detailKey}>
              <Typography style={webStyle.recordingDetailKey}>
                {detailKey + ":"}
              </Typography>
              <Typography>
                {this.state.recordingDetail[detailKey as keyof RecordingDetail]}
              </Typography>
            </Box>
          ))}
          <Box sx={webStyle.modalButtonStyle}>
            <Button
              onClick={this.handleDownloadRecordingWeb}
              style={webStyle.buttonStyle}
            >
              {configJSON.downloadRecording}
            </Button>
            <Button
              onClick={this.handleRecordingDetailModalClose}
              style={webStyle.buttonStyle}
            >
              {configJSON.close}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={webStyle.mainWrapper}>
        {this.state.loading && (
          <CircularProgress style={webStyle.loaderStyle} />
        )}
        {this.renderSearch()}
        {this.renderCallRecordingList()}
        {this.renderRecordingDetailsModal()}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle:Record<string, React.CSSProperties> = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  buttonStyle: {
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    margin: 10,
  },
  deleteButtonStyle: {
    border: "none",
    backgroundColor: "rgb(209, 26, 42)",
    margin: 10,
  },
  callRecordingsContainer: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  itemTextStyle: {
    margin: 10,
  },
  recordingItemStyle: {
    flexWrap: "wrap" as "wrap",
  },
  noRecordingTextStyle: {
    color: "red",
    padding: 30,
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    border: "2px solid #000",
    boxShadow: "24",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "flexStart",
  },
  modalButtonStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 10,
  },
  recordingDetailContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  recordingDetailKey: {
    fontWeight: "bold",
    marginRight: 5,
  },
  loaderStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
  },
};
// Customizable Area End
