import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  Grid,
  TextField,
  Checkbox,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CheckBoxOutlineBlank, CheckBox, CancelOutlined, Search } from '@material-ui/icons';
import * as yup from "yup";
import { Formik } from "formik";
import Commonmanagement from "../../imagemanagement2/src/Commonmanagement.web"
import { icon_upload, icon_upload_error } from './assets';
import DragAndDropFileUploadWithProgress from "../../../components/src/DragAndDropFileUploadWithProgress";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";


const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.addContent = this.addContent.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderDrag=(values:any)=>{
    return(
      <>
        {!values.link && 
                        <Box>
                          <Grid>
                            <DragAndDropFileUploadWithProgress
                            data-test-id="drag_and_drop_id"
                              type={this.state.contentType}
                              image={icon_upload}
                              errorImage={icon_upload_error}
                              isBulkUploading 
                              selectedThumbnailFile={this.DeleteUploadFile}
                              onSelectFile={this.onSelectFile}
                              selectedFile={this.state.attachedFile}
                              progress={this.state.uploadProgress}
                              uploadStatus={this.state.uploadStatus}
                              uploadingMessage={"This can take a few minutes depending on the file size and connection speed"}
                            />
                          </Grid>
                        </Box>}</>
    )
  }

  renderAttachFileSection = (values:any,handleChange:any,handleBlur:any) => {
    return (
      (this.state.contentType === 'video' && !this.state.attachedFile) && (<Box>
        <Grid style={{ minHeight: "262px", border: "2px dashed #929090", display: "flex", flexDirection: "column", gap: "10px", padding: "24px", borderRadius: "8px", alignItems: "center" }}>
          <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Typography style={{
              fontFamily: "'Poppins', sans-serif",
              fontSize: "22px",
              fontWeight: 600,
              lineHeight: "33px",
              color: "#1C1B1B",
              textAlign: "center",
            }}>Paste link here</Typography>
            <Typography style={{
              fontFamily: "'Poppins', sans-serif",
              fontSize: "14px",
              fontWeight: 400,
              color: "#1C1B1B",
              lineHeight: "18px",
              textAlign: "center",
            }}>You can paste your Youtube link here</Typography>
          </div>
          <StyledTextField
            name="link"
            data-test-id="link_type"
            fullWidth
            style={{ background: !!values?.link ? "#fff" : "#fff7ff" }}
            InputProps={{
              style: { borderRadius: '8px' }
            }}
            variant="outlined"
            placeholder="Enter your Link here"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.link}
          />
        </Grid>
      </Box>)
    )
  }
  renderThumbnail=(values:any,touched:any,errors:any)=>{
    return(
      <>
         <Box style={{ display: "flex"}} >
                            <Box data-test-id='thumbnail_btn_container' style={thumbnailOuterBox}>
                              <label htmlFor='thumbnail_btn_container' style={webStyle.labelStyle}>
                                Thumbnail*
                              </label>
                              <StyledThumbnailContainer data-test-id='thumbnail_btn_container'>
                                <Button data-test-id="thumbnailButton" className="changethumb-btn" onClick={this.handleOpenOptionPrompt}>
                                  + Add Thumbnail
                                </Button>
                                <div className={values.thumbnail ? "":"thumbpreview"}>
                           {values.thumbnail ?
                           (<span>
                             <img data-test-id='thumbnailImg' src={values.thumbnail}  className={"thumbnailImg"}/>
                          </span>
                           ) : (
                            <div></div>
                          )}
                        </div>  
                              </StyledThumbnailContainer>
                              {touched.thumbnail && errors.thumbnail && (
                                <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.thumbnail}</Typography>
                              )}
                            </Box>
                            <StyledVerticalDivider />
                          </Box>
      </>
    )
  }
  validationSchema = yup.object().shape({
    videoName: yup
      .string()
      .required("Name is required")
      .min(5, "Min 5 characters required")
      .max(50, "Max 50 characters required"),
    content_description: yup
      .string()
      .max(500, "Max 500 characters required"),
    thumbnail: yup
      .string()
      .required("Thumbnail is required"),
    tags: yup
      .string()
      .required("Tags is required"),
  })
  initialFormikState = {
    videoName: this.props.location?.state?.formData?.videoName || "",
    link: this.props.location?.state?.formData?.link || "",
    tags: this.props.location?.state?.formData?.tags || [],
    content_description: this.props.location?.state?.formData?.content_description || "",
    thumbnail: this.props.location?.state?.formData?.thumbnail || ""
  }
   isFormikValueSubmitted = false
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box sx={webStyle.mainWrapper}>
            <Formik
              enableReinitialize = {true}
              data-test-id="createContentForm"
              innerRef={formik=> {this.formikRef = formik}}
              initialValues={this.initialFormikState}
              onSubmit={this.addContent}
              validationSchema={this.validationSchema}
            >
              {({ values, handleChange, handleSubmit, touched, errors, handleBlur, setFieldValue }) => {
                if(this.state.isEditContent && this.state.contentItemDetail && !this.isFormikValueSubmitted){
                  this.isFormikValueSubmitted = true
                  setFieldValue("videoName",this.state.contentItemDetail.data.attributes.title)
                  setFieldValue("link",this.state.contentItemDetail.data.attributes.attachment.url.includes("www.youtube") ? this.state.contentItemDetail.data.attributes.attachment.url : "")
                  setFieldValue("tags",this.state.contentItemDetail.data.attributes.tags.map((tagValue)=>{ return {type:"custom_tags",id:tagValue.id.toString(),attributes:{name:tagValue.name,id:tagValue.id.toString()}}}))
                  setFieldValue("content_description",this.state.contentItemDetail.data.attributes.description)
                  setFieldValue("thumbnail",this.state.contentItemDetail.data.attributes.thumbnail.url)
                }
                return (
                  <Box>
                    <div style={{ width: '100%' }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        padding: "24px",
                        boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.3)",
                        borderRadius: "18px"
                      }}>
                      {this.renderDrag(values)}
                      {this.renderAttachFileSection(values,handleChange,handleBlur)}
                        <StyledBox1>
                       {this.renderThumbnail(values,touched,errors)}
                            <Grid container style={{ display: 'flex' }}>
                              <StyledBox>
                                <label style={webStyle.labelText} >
                                  Tags*
                                </label>
                                <Autocomplete
                                  data-test-id="selectButtonID"
                                  style={webStyle.autoComplete}
                                  options={this.state.tagsList}
                                  freeSolo
                                  value={values?.tags}
                                  multiple
                                  getOptionLabel={(option: any) => option?.attributes.name}
                                  autoSelect
                                  onChange={(event, newValue) => {
                                    handleChange(event)
                                    const filtered = newValue.filter(item => typeof item !== 'string')
                                    setFieldValue("tags", filtered)
                                  }}
                                  renderOption={(option: any, { selected }) => (
                                    <li style={{ display: 'flex', alignItems: "center" }}>
                                      <Checkbox
                                        className="checkBox"
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8, color: "#652786", textTransform: "capitalize" }}
                                        checked={values.tags.map((tag:any) => tag.id).includes(option.id)}
                                        color="primary"
                                      />
                                      {option.attributes.name}
                                    </li>
                                  )}
                                  renderInput={(params) => {
                                    const { InputProps, ...rest } = params;
                                    return (
                                      <TextField
                                        {...rest}
                                        style={{ background: "#fff7ff", 'width':"100%" }}
                                        variant="outlined"
                                        placeholder="Find a tag"
                                        InputProps={{
                                          ...InputProps,
                                          style: { borderRadius: '8px', },
                                          startAdornment: (
                                            <Search style={{ color: "#ADAAAA" }} />
                                          )
                                        }}
                                      />
                                    )
                                  }}
                                />
                                {touched.tags && errors.tags && (
                                  <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.tags}</Typography>
                                )}
                              </StyledBox>
                              {values?.tags.map((tagSelected: any, index: any) => {
                                return (
                                  <Grid item key={index} style={{ alignItems: 'end', display: 'flex', paddingTop: '3px' }}>
                                    <Box
                                      style={{ display: 'inline-flex', alignItems: 'center', position: "relative", minWidth: '170px', justifyContent: 'center', margin: '5px 5px 3px 5px', padding: '11px', border: '1px solid #652786', borderRadius: '12px' }}
                                    >
                                      <Typography variant="body1" style={{ fontSize: "14px", textTransform: "capitalize", lineHeight: "16px", fontWeight: 400, color: "#652786", fontFamily: "'Poppins', sans-serif" }}>{tagSelected.attributes?.name}</Typography>
                                      <IconButton data-test-id={`removeTagButton-${index}`} style={{ position: "absolute", top: "-11px", right: "-12px", }} size="small" onClick={() => { setFieldValue("tags", values?.tags.filter((tagRemove: any) => tagRemove !== tagSelected)) }}>
                                        <CancelOutlined style={{ background: "#fff", borderRadius: "10px", color: "#652786" }} fontSize="small" />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                )
                              })}
                            </Grid>
                        </StyledBox1>
                        <Grid item xs={12} md={12} style={webStyle.formStyle}>
                          <label style={webStyle.labelStyle} data-test-id ='content-label'>
                            {`${this.state.title}*`}
                          </label>
                          <TextField
                            name='videoName'
                            placeholder="Enter Title"
                            variant="outlined"
                            error={touched.videoName && Boolean(errors.videoName)}
                            InputProps={{
                              style: { borderRadius: '8px' }
                            }}
                            value={values?.videoName}
                            style={{ background: !!values?.videoName ? "#fff" : "#fff7ff", }}
                            id="bootstrap-input"
                            onChange={handleChange} />
                          {touched.videoName && errors.videoName && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.videoName}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12} style={webStyle.formStyle}>
                          <label style={webStyle.descriptionLableStyle} >
                            Description&nbsp;<p style={{ color: "#ABAAAA", margin: "0" }}> - For internal use only</p>
                          </label>
                          <TextFieldForm
                            fullWidth
                            id="_desc"
                            name="content_description"
                            value={values?.content_description}
                            helperText={touched.content_description && errors.content_description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ background: !!values?.content_description ? "#fff" : "#fff7ff" }}
                            InputProps={{
                              style: { borderRadius: "8px" },
                            }}
                            variant="outlined"
                            placeholder="Please write your description here"
                            multiline
                            minRows={5}
                            maxRows={8}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </div>
                    </div>
                    <StyledSubmitCancelContainer>
                      <Box sx={saveDraftStyle} onClick={this.handleCancel}>Cancel</Box>
                      <Box sx={continueButtonContainer} data-test-id="handleSubmitButton" >
                        <HeaderContainer disabled={(this.state.uploadStatus==="uploaded" || values?.link )?false:true} style={(this.state.uploadStatus==="uploaded" || values?.link) ? {...webStyle.enableBTns}:{}} onClick={() => handleSubmit()}>{this.state.isEditContent ? "save changes" : "Add Content"}</HeaderContainer>
                      </Box>
                    </StyledSubmitCancelContainer>
                  </Box>)
              }}
            </Formik>
          </Box>
        </ThemeProvider>
        {this.state.thumbnailPopupShow &&
        (
          <Commonmanagement
          id="2"
          navigation={this.props.navigation}
             open={this.state.thumbnailPopupShow}
             onClose={this.handleThumbnailClose}
            CloseModal={this.closeThumbnailModal}
          />
        )
        }
        <DeleteModal
            data-test-id='option-prompt'
            openDialog={this.state.showOptionPrompt}
            headingText={`Where do you want to select the thumbnail from?`}
            btnOkText={"Thumbnail library"}
            btnCancelText={"Local storage"}
            handleCancel={() => this.closeOptionPrompt()}
            handleOk={() => this.handleThumbnailContent()}
            promptOption={true}
            handleOptionOne={() => this.handleAddThumbnailFromLocal()}
          />
          <DeleteModal
            data-test-id='save-change-modal'
            openDialog={this.state.saveChangesPopUp}
            subHeading={"This might confuse users who're viewing this content"}
            btnOkText={"Save"}
            headingText="Are you sure you want to save these changes?"
            btnCancelText={"Cancel"}
            handleCancel={() => this.closeSaveChanges()}
            handleOk={this.editContentApi}
          />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeaderContainer = styled(Button)({
  backgroundColor: "#E5E2E1",
  width: "230px", 
  height: "56px", 
  borderRadius: "16px", 
  textTransform: "none", 
  color: "#787776",
  fontSize: "16px", 
  lineHeight: "16px", 
  fontWeight: 600, 
  fontFamily: "'Poppins', sans-serif",
   "&:hover": {
    backgroundColor: "#E5E2E1",
  }
});

const StyledTextField = styled(TextField)({
  "@media (min-width: 760px)": {
    width: '480px'
  },
});

const StyledBox = styled(Box)({
  height: 'fit-content',
  display: "flex",
  flexDirection: "column",
  "@media (min-width:901px)": {
    width:"375px",
  },
  "@media (max-width:900px)": {
      width:"100%",
    },
});

const StyledBox1 = styled(Box)({
  display: "flex",
  gap:"30px",
  "@media (max-width: 900px)": {
    flexDirection:"column"
  },
})
const StyledSubmitCancelContainer = styled(Box)({
  display: "flex",
  gap: "20px",
  justifyContent: "end",
  marginTop: "40px",
  alignItems: "center",
  "@media (max-width: 700px)": {
    flexDirection:"column"
  },
})
const StyledThumbnailContainer = styled(Box)({
  display: "flex", 
  gap: "20px",
  "@media (max-width: 400px)": {
    flexDirection:"column"
  },
})
const StyledVerticalDivider = styled(Box)({
  height: "84px", width: "4px", backgroundColor: "#929090" , marginLeft:'30px',
  "@media (max-width: 400px)": {
    display:"none"
  },
})
const thumbnailOuterBox = { display: "flex", flexDirection: "column" as const, gap: "4px" };
const saveDraftStyle = { width: "230px", height: "56px", textTransform: "none", textAlign: "center", display: "grid", alignItems: "center", color: "#FF8D3B", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", border: "1px solid #FF8D3B", borderRadius: "16px", 
"&:hover": {
  cursor: "pointer",
},}
const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  thumbnailPreviewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },
  autoComplete: {
    width: "100%",
    background: "#fff7ff",
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root']": {
      padding: "12px",
      marginTop: "5px"
    }
  },
  labelStyle: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C1B1B"
  },
  descriptionLableStyle: {
    display: "flex",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C1B1B"
  },
  labelText: { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" },
  formStyle: {
    display: "grid",
    gap: "6px"
  },
  InputStyle: {
    "& .MuiInputBase-input": {
      padding: "16px 20px 16px 24px",
      gap: "0px",
      borderRadius: "8px",
    }
  },
  contentRoleContentbtn: {
    border: "1px solid #6c328b",
    width: "200px",
    borderRadius: "12px",
    height: "40px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "16px",
    textAlign: "center" as const,
    color: "#6c328b",
    textTransform: "capitalize" as const,
  },
  contentDiv: {
    display: "flex",
    gap: "20px",
    flexWrap: "nowrap" as const,
    "@media (max-width: 1300px)": {
      flexWrap: "nowrap",
    },
    "@media (max-width: 700px)": {
      flexWrap: "wrap",
    },
  },
  enableBTns:{
    backgroundColor:"#6C328B",
    color:"#FFFFFF"
  }

};

const TextFieldForm = styled(TextField)({
  "& #_desc-helper-text": {
    color:'red'
  }
});
// Customizable Area End
