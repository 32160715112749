import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MoreButton from "./NotificationMoreButton.web";

interface INotificationHeader {
  listType: string;
  onCloseClick: () => void;
  onDeleteClick: () => void;
  disabledMoreButton: boolean;
  disabledDeleteButton: boolean;
  disabledSilentButton: boolean;
  onMoreClick: (type: string) => void;
  onSilentClick: (status: boolean) => void;
}

export default function NotificationHeader({
  listType,
  onMoreClick,
  onCloseClick,
  onDeleteClick,
  onSilentClick,
  disabledMoreButton,
  disabledDeleteButton,
  disabledSilentButton,
}: INotificationHeader) {
  const classes = useStyles();
  let disabled;
  if (listType === "normal") {
    disabled = disabledMoreButton;
  } else if (listType === "delete") {
    disabled = disabledDeleteButton;
  } else {
    disabled = disabledSilentButton;
  }

  return (
    <Box className={classes.headingContainer}>
      <Typography variant="h6" className={classes.heading}>
        Notifications
      </Typography>
      <Box className={classes.buttonContainer}>
        <MoreButton
          data-test-id = "MoreButton"
          onClick={onMoreClick}
          listType={listType}
          onDeleteClick={onDeleteClick}
          onSilentClick={() => onSilentClick(true)}
          disabled={disabled}
        />
        <IconButton
          aria-label="delete"
          onClick={onCloseClick}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "15px 30px",
      borderBottom: "1px solid rgba(153, 153, 153, 0.16)",
    },
    heading: {
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#0A1629",
      padding: "12px 0px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    closeIcon: {
      display: "block",
      [theme?.breakpoints?.up("sm")]: {
        display: "none"
      }
    }
  })
);