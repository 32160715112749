import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import StyledCheckbox from "../../../../components/src/StyledCheckbox";
import { useStyles } from "./index";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="normal" className={classes.tableHeadCell} />
        <TableCell padding="checkbox" className={classes.tableHeadCell}>
          <StyledCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all questions",
            }}
          />
        </TableCell>
        <TableCell
          align="right"
          padding="normal"
          className={classes.tableHeadCell}
        >
          ID
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className={classes.tableHeadCell}
        >
          Type
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className={classes.tableHeadCell}
        >
          Questions
        </TableCell>
        <TableCell className={classes.tableHeadCell} />
      </TableRow>
    </TableHead>
  );
}
