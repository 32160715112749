// Customizable Area Start
import React from "react";
import './videostyles.css';
import MyContentController from "./MyContentController.web";
import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import CustomCarousel from "../../../../packages/components/src/CustomCarousel/CustomCarousel";
import { checkIcon, iconVideo,startRating,emptyStart } from "./assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DynamicTitle from "../../../components/src/DynamicTitle";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { LineChart2 } from "./Library2.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Rating from '@material-ui/lab/Rating';

export default class MyContentWeb extends MyContentController {
    renderContentData = (itemsToShow:number) => {
        return (
    <>
                    {this.state.inProgressData.filter((conent:any,index:number)=>index < 5).map((conent: any, index: number) => {
                      const completedVideoCount = conent.attributes.completed_video_count || 0;
                      const totalVideoCount = conent.attributes.total_video_count || 0;
                      const completionPercentage = totalVideoCount ? (completedVideoCount / totalVideoCount) * 100 : 0;
                      return (
                    <Box style={{
                      margin:"0 15px" ,height: "407px",
                      position: "relative",width:"270px",cursor:"pointer"
                    }} data-test-id={`contentBox-${index}`} key={index} onClick={()=>this.handleNavigateTOProduct(conent)}>
                      <Box
                        style={{
                          height: "181px", padding: "16px 0px 0px 0px", gap: "13px",position:"relative",
                          borderRadius: "12px 0px 0px 0px",opacity: "0px",
                        }}>
                        <img width="100%" height="100%" style={{borderRadius:"12px"}} 
                        src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                        {conent.attributes.collection.attributes.expand_type === "Program" && <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>}
                      </Box>
    
                      <Box style={{
                          width:"100%",height: "242px",padding: "24px",borderRadius:"12px ",
                          position: "absolute",bottom:5,backgroundColor:"white",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"
                        }}>
                          <Box style={{ display:"flex",gap: "16px",flexDirection:"column"
                          }}>
    
                         
                        <Typography
                          style={{fontFamily: "Poppins",fontSize: "16px",
                            fontWeight: 700,lineHeight: "22px",textAlign: "left",color:"#1C1B1B"
                          }}>{conent.attributes.collection.attributes.title}</Typography>
    
                        <Typography style={{
                            fontFamily: "Poppins",fontSize: "14px",fontWeight: 500, lineHeight: "22px",
                          }}>{conent.attributes.collection.attributes.created_by}</Typography>
    
                        <Typography
                          style={{
                            fontFamily: "Poppins",fontSize: "12px",fontWeight: 400,lineHeight: "18px",color:"#484646"
                          }}>{`Due in ${conent.attributes.due_in_days} Days`}</Typography>
    
                        <Box style={{display:"flex",justifyContent:"space-between",position:"absolute",bottom:"20px",width:"80%"}}><Box>
                          <Box style={{ 
        width: "154px", height: "10px", borderRadius: "88px", 
        backgroundColor: "rgba(236, 195, 195, 0.23)", position: "relative" 
      }}>
        <span style={{ 
          display: "block",  width: `${completionPercentage}%`, 
          height: "10px",  borderRadius: "88px", 
          background: "linear-gradient(155.66deg, #EF2B4B -1.02%, #FF8B3F 68.24%)",position: "absolute", 
          top: 0, left: 0 
        }}></span>
      </Box>
                            <Typography style={{
                              color: "var(--sys-color-Secondary, rgba(228, 112, 4, 1))",
                              fontWeight:400,fontSize:"12px",
                              marginTop:"10px"
                            }}>
                          {`${conent.attributes.completed_video_count}/${conent.attributes.total_video_count} Lessons Completed`}</Typography>
                          </Box><Box style={{
                              width:"40px",height:"40px",borderRadius:"8px",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                              display:"flex",justifyContent:"center",alignItems:"center" }}>
                            <img src={iconVideo}/>
                            </Box>
                         </Box>
                      </Box>
                      </Box>
                    </Box>
                  )})}</>
        )
      }

      renderRating = (rating:number) => {
        return(
          <Box style={{display:'flex',alignItems:'center',gap:'3px'}}>
                     <Typography style={{color: '#FF8B3F',
                        fontSize: '16px',
                        fontWeight:600}}>{rating % 1 === 0 ? rating : rating.toFixed(1)}</Typography>
                     <Rating
                        name='read-only'
                        value={rating}
                        precision={0.5}
                        readOnly
                        icon={<img src={startRating}/>}
                        emptyIcon={<img src={emptyStart}/>}
                    />
                     </Box>
        )
      }

      renderCompleted = (itemsToShow:number) => {
        return (
    <>
                    {this.state.completedData.filter((conent:any,index:number)=>index < 5).map((conent: any, index: number) => {
                      return (
                    <Box style={{
                      margin:"0 15px" ,height: "317px",
                      position: "relative",width:"270px",
                      cursor:"pointer"
                    }} data-test-id={`completedBox-${index}`} onClick={()=>this.handleNavigateTOProduct(conent)} key={index}>
                      <Box
                        style={{height: "181px", padding: "16px 0px 0px 0px", gap: "13px",
                          borderRadius: "12px 0px 0px 0px",opacity: "0px",position: "relative"}}>
                        <img width="100%" height="100%" style={{borderRadius:"12px"}} 
                        src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                        <Box style={{...styles.flexCenter,width:"100%",height:"100%",borderRadius: "12px",position:"absolute",top:"17px",background: "rgba(0, 0, 0, 0.3)"}}>
                          <img style={{width:"72px",height:"72px",marginTop:"40px"}} src={checkIcon} alt="image loading..."  /></Box>  
                      {conent.attributes.collection.attributes.expand_type === "Program" && <Typography style={{...styles.chipTypography,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>}
                      </Box>
                      <Box style={{
                          width:"100%",height: "152px",padding: "24px",borderRadius: "12px ",
                          position: "absolute",bottom:5,backgroundColor:"white",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"}}>
                          <Box style={{ display:"flex",gap: "16px",flexDirection:"column"}}>
                        <Typography style={{
                            fontFamily: "Poppins",fontSize: "16px",fontWeight: 700,lineHeight: "22px",textAlign: "left",color:"#1C1B1B"}}>{conent.attributes.collection.attributes.title}</Typography>
                        <Typography style={{fontFamily: "Poppins",fontSize: "14px",fontWeight: 500, lineHeight: "22px",}}>{conent.attributes.collection.attributes.created_by}</Typography>
                        {conent.attributes.review > 0 &&
                            this.renderRating(conent.attributes.review)         
                        }
                      </Box></Box>  </Box>
                  )})}</>
        )
      }

    renderInProgress = () => {
        return (
          <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
          <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
            <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>In progress</Typography>
            <Typography style={{...styles.flexBetween,color:"#652786",cursor:"pointer"}}
            id="navigate-personal-tst-btn" onClick={()=>this.navigateToAnyPage("InProgress")}>View More <ChevronRightIcon />
            </Typography>
              </Paper>
              <Box className="cutomCarouselBox">
            <CustomCarousel data-test-id="contentDataCallId" children={this.renderContentData} datavalue={this.state.inProgressData} getTransformValue={this.getTransformValue}/>
            </Box>
        </Box>
        )
      }

      renderCompletedData = () => {
        return (
          <Box style={{gap:"16px",margin:"10px 0px 10px 20px"}}>
          <Paper className="paper" style={{ display: "flex",justifyContent:"space-between",alignItems:"center", }}>
            <Typography style={{...styles.typography_personal,marginLeft:"5px"}}>Completed</Typography>
            <Typography style={{...styles.flexBetween,color:"#652786",cursor:"pointer"}} data-test-id="completedViewMore"
            id="navigate-personal-tst-btn" onClick={()=>this.navigateToAnyPage("CompletedPageWeb")}>View More <ChevronRightIcon />
            </Typography>
              </Paper>
              <Box className="cutomCarouselBox">
            <CustomCarousel data-test-id="completedDataCallID" children={this.renderCompleted} datavalue={this.state.completedData} getTransformValue={this.getTransformValue}/>
            </Box>
        </Box>
        )
      }

      learningprogressChart = () => {
        return (
          <Paper className="paper" style={styles.filterPaper}>
              <Grid container>
              <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
                    <Typography style={styles.learningHadding}>Learning Progress</Typography>
                    <Box style={{ display: 'flex'}}>
                      <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'none', marginTop: '17px', cursor: 'pointer' }}>
                        <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                          <Select
                          className="customSelect"
                            value={this.state.selected}
                            onChange={this.handleChange}
                            disableUnderline
                            IconComponent={KeyboardArrowDownIcon}
                            inputProps={{
                              name: "agent",
                              id: "age-simple"
                            }}
                          >
                            {this.state.values.map((value: any, index: any) => {
                              return <MenuItem id="unique-item-click-test-id" key={value} onClick={() => this.handleClickChart(value)} value={value.key}>{value.value}</MenuItem>;
                            })}
                          </Select>
                        </FormControl></Typography>
                    </Box>
                    </Box>
                <Grid item lg={10} md={10} sm={12} xs={12} style={{height:"100%"}}>
                  <Box>
                    <LineChart2 maxValue={this.state.chartData?.data?.max} stepValue={this.state.chartData?.data?.step_value} state={[this.state.chartData?.this_time_data,this.state.chartData?.last_time_data]} isWeekSelected={this.state.selected === "week"}/> 
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} >
                  <Box>
                    <Grid container>
                      <Grid className="graphGrid" item lg={12}>
                        <Box style={{ display: 'flex', marginTop: '30px',alignItems:"center" }}>
                          <Box style={{ width: '16px', height: '16px', backgroundColor: 'rgba(231, 14, 107, 1)', borderRadius: '10px' }}></Box>
                          <Typography style={{ fontWeight: 700, fontSize: '16px', paddingLeft: '5px',}}>{this.state.selected === "week" ? "This week" : "This month"}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', marginTop: '30px',alignItems:"center"  }}>
                          <Box style={{ width: '16px', height: '16px', backgroundColor: 'rgba(173, 170, 170, 1)', borderRadius: '10px' }}></Box>
                          <Typography style={{ fontWeight: 700, fontSize: '16px', paddingLeft: '5px',}}>{this.state.selected === "week" ? "Last week" : "Last month"}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
        )
      }
    render() {
        return (
            <Box>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <ArrowBackIosIcon
                data-test-id="gotoBackBtn"
                onClick={this.gotoBackSide}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="My content" />
            </div>
            {this.learningprogressChart()}
                     {this.state.inProgressData.length > 0 && this.renderInProgress()}
                     {this.state.completedData.length > 0 && this.renderCompletedData()}
            </Box>
        )
    }
}

const styles = {
    img_shape: {
        paddingLeft: '13px'
      },
      typography_personal: {
        fontWeight: 700,
        fontSize: '24px'
      },
      flexBetween:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
      },
      flexCenter:{
        display:"flex",
        justifyContent:"center",
      },
      chipTypography:{
        width: "117px",
        height:"28px",
        padding: "3px 0px",
        borderRadius: "16px",
        background: "#E70E6B",
        color:"white",
        fontWeight:400,
        fontSize:"14px",
      },
      filterPaper: {
        overflow: 'hidden',
        borderRadius: '24px',
        flex: 1,
        padding:"20px",
        margin: 'auto',
        marginTop: '25px'
      },
      learningHadding: {
        fontWeight: 600,
        fontSize: '24px'
      },
}
// Customizable Area End
