import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  TextField,
  CircularProgress,
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, makeStyles, styled, withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Line1 from "./LineChart1.web"
import './style.css'
const achivement = require("../assets/achivement.png");
const Avatar = require("../assets/avatar.jpg");
const crown = require("../assets/crrown.png");
const arrow = require("../assets/arrow.png");
const cardBackground = require("../assets/cardBackground.png");
const exmaplePic = require("../assets/exmaplePic.png");
const configJSON = require("./config");
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Note from '../../../components/src/Notes/Notes'
import AddNote from "../../../components/src/Notes/AddNote"
import RewardsFrame from "../../../components/src/RewardsAchievementFrame/RewardsFrame"
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import {emptyRewardImg,rewardCup,rewardCrown} from "./assets"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
interface StatusTabProps1 {
  label: string;
  statusKey: string;
  currentStatus: string;
  onClick: (status: string) => void;
  iconStyle?: React.CSSProperties;
}
export const StatusTab = ({ label, statusKey, currentStatus, onClick, iconStyle }:StatusTabProps1) => (
  <Box
    data-testid={"statustab"}
    style={currentStatus === statusKey ? webStyle.activeStatusText : webStyle.statusBox}
    onClick={() => onClick(statusKey)}
  >
    <FiberManualRecordRoundedIcon style={iconStyle} />
    <Typography style={{...webStyle.statusText,width:statusKey === 'all_courses' ? 'auto' : '100px'}}>{label}</Typography>
  </Box>
);
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController.web";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    this.getUserProfileData();
    this.getAllNotesData();
    this.getRecentInsightsData();
    this.getRecentActivityData();
    this.getListWatchedData();
    this.getListTimeData();
    this.rewardAchievementApiCall();
    // this.addTimeSpent()
  }

  handleTab = (value: any) => {
    this.getAllNotesData('', value == 0 ? '' : value == 1 ? 'personal' : 'course');
    this.setState({
      handleTabValue: value,
      searchValue: '',
      isloading: true,
    })
  }
  handleSearch = (e: any) => {
    this.getAllNotesData(e.target.value, this.state.handleTabValue == 0 ? '' : this.state.handleTabValue == 1 ? 'personal' : 'course');
    this.setState({
      searchValue: e.target.value,
      isloading: true
    })

  }

  handleRecentTab = (value: any) => {
    this.setState({
      recentSearchValue: '',
      recentTabValue: value,
      recentInsightLoading: true,
      recentLoading: true,
    })
    if (value == 0) {
      this.getRecentInsightsData()
    } else {
      this.getRecentActivityData();
    }

  }
  handleRecentSearch = (e: any) => {
    if (this.state.recentTabValue == 0) {
      this.setState({
        recentInsightLoading: true
      })
      this.getRecentInsightsData(e.target.value)

    } else {
      this.setState({
        recentLoading: true
      })
      this.getRecentActivityData(e.target.value);
    }
    this.setState({
      recentSearchValue: e.target.value,
    })
  }

  // Customizable Area End
  render() {
    
    const { userProfileData, isloading, listWatchedData, listTimeData, listTimeHours, achiveTab, statusTab } = this.state;
    const tabs = [
      { label: configJSON.achievements, index: 0,filter:'achievements' },
      { label: configJSON.rewards, index: 1,filter:'rewards' },
    ];
    const statusTabs = [
      { key: "all_courses", label: configJSON.all, iconStyle: webStyle.allIcon },
      { key: "past_dues", label: configJSON.Incomplete, iconStyle: webStyle.incompletedotIcon },
      { key: "in_progress", label: configJSON.Inprogress, iconStyle: webStyle.inprogressdotIcon },
      { key: "not_started", label: configJSON.notStarted, iconStyle: webStyle.notStarteddotIcon },
      { key: "completed", label: configJSON.Completed, iconStyle: webStyle.completeddotIcon },
    ];
    return (
      // Customizable Area Start
      <>
        <Header>
          <BackIcon data-test-id="back_icon" onClick={this.handleBackNavigation} />
          <Title>Profile</Title>
        </Header>
      <Grid container style={{marginTop:'32px'}}>
        <Grid item xs={12}>
            <Grid container spacing={4} alignItems="center">
              <Grid item={true} sm={12} md={8}>
                <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", display: "flex", alignItems: "center", boxShadow: "#d7cccc 0px 0px 7px" }}>
                <Grid container spacing={4} style={{ padding: "10px" }}>
                    <Grid item={true} xs={12} md={8} style={{ display: 'flex', flexDirection: "row", }}>
                      <Box style={{ display: "flex", alignItems: 'center' }}>
                        <Box style={{ width: "100px", height: "100px", position: "relative" }}>
                          <img src={userProfileData?.user_info?.avatar ? userProfileData?.user_info?.avatar : Avatar} height="100px" width="100px" style={{ borderRadius: "50px" }} />
                          <Box style={{ backgroundColor: "green", borderRadius: "15px", width: "15px", height: "15px", position: "absolute", top: "0px", right: "0px" }}></Box>
                        </Box>
                      </Box>
                      <Box style={{ marginLeft: "15px", display: "flex", justifyContent: "center", flexDirection: "column", }}>
                        <Typography variant="h5" className="profileName">{userProfileData?.user_info ? userProfileData?.user_info?.first_name + " " + userProfileData?.user_info?.last_name : ""}</Typography>
                        <Box style={{ display: 'flex', marginTop: "10px", alignItems: "center" }}>
                          <Typography variant="body1" color="secondary" style={{ marginRight: "15px" }}>
                            Last Online
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            {userProfileData?.user_info?.last_online} Hours ago
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
              <Grid item={true} xs={12} md={4} style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>
                <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                        <img src={achivement} height="50px" width="50px" />
                      </Box>
                <Typography variant="h5" style={{ margin: "10px" }}>
                          {userProfileData?.user_info?.active_day_count}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" style={{ textAlign: "center" }}>
                  Active No. of days on the app
                        </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item={true} sm={6} md={4}>
                {this.state.rewardCounter > 0 ?
                <RewardCrownCount>
                <Box style={webStyle.cupBox}>
                  <img src={rewardCup} alt="reward"/>
                  <Typography style={webStyle.rewardRedeemtext}>{configJSON.rewardredeem}</Typography>
                </Box>
                <Box style={{position:'relative'}}>
                <img src={rewardCrown} style={webStyle.rewardCrown} />
                <Typography style={webStyle.rewardCount}>{this.state.rewardCounter}</Typography>
                </Box>
              </RewardCrownCount>
                 :
                <Box style={webStyle.rewardRedeemEmptyBox}>
                  <Box style={webStyle.cupBox}>
                    <img src={rewardCup} alt="reward"/>
                    <Typography style={webStyle.rewardRedeemtext}>{configJSON.rewardredeem}</Typography>
                  </Box>
                  <Typography style={webStyle.noneYetText}>{configJSON.notYet}</Typography>
                </Box>
              }
              </Grid>
            </Grid>
          
          <Grid item={true} xs={12} style={{ marginTop: "30px" }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" className="profileName">
                Insights Overview
              </Typography>
              <Typography variant="h6" test-id="profileName" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => this.props.navigation.navigate("InsightOverview")}>
                View all
              </Typography>
            </Box>
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item={true} xs={12} style={{ display: 'flex' }} >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Box className="mask" test-id="mask" style={{ backgroundImage: `url(${cardBackground})`, cursor: 'pointer' }} onClick={() => this.props.navigation.navigate("Reports")}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Course Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_courses < 10 ? "0" + userProfileData?.insight_overview?.completed_courses : userProfileData?.insight_overview?.completed_courses}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_courses < 10 ? "0" + userProfileData?.insight_overview?.total_courses : userProfileData?.insight_overview?.total_courses}
                          </Typography>
                          {/* <Typography variant="h6" style={{color:"white",marginBottom:"5px"}}>
                                /{userProfileData?.insight_overview?.total_courses < 10 ? "0"+userProfileData?.insight_overview?.total_courses : userProfileData?.insight_overview?.total_courses}
                              </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" test-id="mask1" onClick={() => this.props.navigation.navigate("/AchievementListing")} style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Achievement Unlocked
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_achievements < 10 ? "0" + userProfileData?.insight_overview?.completed_achievements : userProfileData?.insight_overview?.completed_achievements}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_achievements < 10 ? "0" + userProfileData?.insight_overview?.total_achievements : userProfileData?.insight_overview?.total_achievements}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" test-id="mask2" onClick={() => this.props.navigation.navigate("/AllRewardsListing")} style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Rewards Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_rewards < 10 ? "0" + userProfileData?.insight_overview?.completed_rewards : userProfileData?.insight_overview?.completed_rewards}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_rewards < 10 ? "0" + userProfileData?.insight_overview?.total_rewards : userProfileData?.insight_overview?.total_rewards}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Goals Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Task Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ margin: '0px', marginTop: "25px" }}>
            <Grid item={true} lg={6} md={6} xs={12} sm={12}>
              <Paper elevation={5} className="paper" style={{
                overflow: 'hidden', padding: '30px',
                borderRadius: '24px',
                flex: 1, maxWidth: '100%', height: '384px', boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)"
              }}>
                <Box style={{ padding: "10px" }}>
                  <Typography variant="h6" className="profileName">
                    Videos Watched
                  </Typography>
                  <Grid container>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                      <Box style={{ display: 'flex', padding: "20px" }}>
                        <Line1 data={listWatchedData.this_time_data} dataweek={listWatchedData?.last_time_data} />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Box className="graph_num" >
                        <Typography className="graph2">{listWatchedData?.meta?.this_count}/{listWatchedData?.meta?.total_count[0]?.count}</Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="required" name="required" row >
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#5542F6', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>This Week</Typography>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#B6B4BA', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>Last Week</Typography>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item={true} lg={6} md={6} xs={12} sm={12}>
              <Paper elevation={5} className="paper" style={{
                overflow: 'hidden', padding: '30px',
                borderRadius: '24px',
                flex: 1, maxWidth: '100%', height: '384px', boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)"
              }}>
                <Box style={{ padding: "10px" }}>
                  <Typography variant="h6" className="profileName">
                    Time Watched
                  </Typography>
                  <Grid container>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                      <Box style={{ display: 'flex', padding: "20px" }}>
                        <Line1 data={listTimeData?.this_week_data} dataweek={listTimeData?.last_week_data} />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Box className="graph_num" >
                        <Typography className="graph1">{listTimeHours?.week_count}</Typography>
                        <Typography className="graph2">hrs</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="required" name="required" row>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#5542F6', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>This Week</Typography>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#B6B4BA', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>Last Week</Typography>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ margin: '0px', marginTop: "25px" }}>
            <Grid item={true} lg={6} md={6} xs={12} sm={12}>
                <Paper elevation={5} style={webStyle.rewardsPaper}>
                  <Box style={webStyle.rewardTitleBox}>
              {tabs.map((tab) => (
                <Box
                  key={tab.index}
                  style={achiveTab === tab.filter ? webStyle.activeRewardTitle : webStyle.inActiveReward}
                  onClick={() => this.handleAchiveTab(tab.filter)}
                  data-testid={"rewardtab-" + tab.index}
                >
                  {tab.label}
                </Box>
              ))}
                </Box>
                {(this.state.rewardData.length > 0 || this.state.statusTab !== 'all_courses') ? 
                <>
                <Box style={webStyle.noteMainContainer}>
                    <Box style={webStyle.subTabBox}>
                    {statusTabs.map((status) => (
                      <StatusTab
                        key={status.key}
                        data-testid="status-tab-box"
                        label={status.label}
                        statusKey={status.key}
                        currentStatus={statusTab}
                        onClick={this.handleActiveStatus}
                        iconStyle={status.iconStyle}
                      />
                    ))}
                    </Box> 
                </Box>
                {this.state.rewardLoading ? 
                  <Box style={webStyle.noData} > <CircularProgress /> </Box>:
                  <RewardsFrame rewardData={this.state.rewardData} activeTab={this.state.achiveTab}/>
                }
                </> : 
                <Box style={webStyle.norewardBox}>
                    <img src={emptyRewardImg} alt="no data"/>
                    <Typography style={webStyle.norewardText}>{configJSON.noRewardText}</Typography>
                </Box>}
              </Paper>
            </Grid>
            <Grid item={true} lg={6} md={6} xs={12} sm={12} >
              <Paper elevation={5} style={{ borderRadius: "16px", boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)" }}>
                <Grid container style={{ padding: "20px", display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item lg={3} style={webStyle.titleButtonBox}>
                    <Typography variant="h6" style={webStyle.myNoteText}>
                      {configJSON.MyNotes}
                    </Typography>
                    <NewNoteButton 
                        variant="contained" 
                        test-id="mask3" 
                        onClick={this.handleAddNotesOpenModal}
                        startIcon={<AddCircleOutlineRoundedIcon style={{fontSize:'24px'}}/>}
                      >
                        {configJSON.newNote}
                      </NewNoteButton>
                  </Grid>
                </Grid>
                <Box style={webStyle.noteMainContainer}>
                  {(this.state.allNotesData.length > 0 || this.state.handleTabValue !== 0) &&
                  <Box style={webStyle.noteMainBox}>
                    <Box style={webStyle.tabsMainBox}>
                      <Typography test-id="all-tab" style={this.state.handleTabValue === 0 ? webStyle.activetabtext : webStyle.tabtext} onClick={() => this.handleTab(0)}>{configJSON.all}</Typography>
                      <Typography test-id="personal-tab" style={this.state.handleTabValue === 1 ? webStyle.activetabtext : webStyle.tabtext} onClick={() => this.handleTab(1)}>{configJSON.personale}</Typography>
                      <Typography test-id="course-tab" style={this.state.handleTabValue === 2 ? webStyle.activetabtext : webStyle.tabtext} onClick={() => this.handleTab(2)}>{configJSON.course}</Typography>
                    </Box>
                      <ColorButton 
                        variant="contained" 
                        test-id="mask3" 
                        onClick={this.handleAddNotesOpenModal}
                        startIcon={<AddCircleOutlineRoundedIcon style={{fontSize:'24px'}}/>}
                      >
                        {configJSON.newNote}
                      </ColorButton>
                  </Box>
                  }
                  {isloading ? <Box style={webStyle.noData} > <CircularProgress /> </Box>:
                  <Note
                    notes={this.state.allNotesData}
                    createNote={true}
                    data-testid="personal-note"
                    handleOpenNote={this.handleOpenModal}
                    openNoteModal={this.state.isModalOpen}
                    noteId={this.state.notePreviewId}
                    iconType={'video'}
                    handleOpenEditNote={this.handleEditOpenModal}
                    handleAddNote={this.handleAddNotesOpenModal}
                    handleDeleteNote={this.handleOpendeleteModal}
                    deleteNoteModal={this.state.deletePopUpOpen}
                    handleDeleteApiCall={this.deleteNoteApiCall}
                    profilenote={true}
                  />
                }
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <AddNote 
          open={this.state.isNoteModalOpen} 
          data-testid="add-note-modal"
          handleCloseModal={this.handleAddNotesCloseModal}
          lessonName={''}
          selectedThumbnailFile={this.SelectedIconFileDeleted}
          type={this.state.FileType}
          onSelectFile={this.onSelectFile}
          selectedFile={this.state.attachedFile}
          progress={this.state.uploadFileProgress}
          uploadStatus={this.state.uploadFileStatus}
          timeStamp={''}
          iconType={''}
          titleValue={this.state.title}
          handleChangeTitle={this.handleTitle}
          titleError={this.state.titleValidationError}
          titleErrorMessage={this.state.titleErrorMessage}
          handleChangeDescription={this.handleDescription}
          descriptionError={this.state.descriptionValidateError}
          descriptionErrorMessage={this.state.descriptionValidateMessage}
          descriptionValue={this.state.description}
          handleCreateNote={this.handleAddNote}
          validationError={this.state.validationFieldError}
          editNoteModal={this.state.editNoteModal}
          deleteImagePopup={this.deleteImagePopup}
          deleteImageModal={this.state.deleteImageModal}
          handleEdit={this.handleEditPersonalNote}
        />
        <Dialog 
        open={this.state.openRewardpopUp}
        onClose={this.handleCloseRewardNotify}
        data-testid="reward-popup"
        aria-labelledby="simple-reward"
        style={{padding:'20px'}}
        PaperProps={{
            style: webStyle.rewardPopup
          }}
        >
          <Box style={webStyle.rewardPopupBox}>
            <img src={this.state.notificationData[0]?.icon} alt="reward" style={webStyle.rewardImage}/>
            <Typography style={webStyle.redeemHead}>{configJSON.hey} {this.state.currectUserData.first_name} {this.state.currectUserData.last_name}!</Typography>
            <Typography style={webStyle.redeemHead}>{this.state.notificationData[0]?.content}!</Typography>
            {this.state.notificationData[0]?.reward &&
            <Typography style={webStyle.rewardEmailNotify}>{configJSON.rewardEmailNotify}</Typography>
            }
            <DismissButton data-testid="close-reward" onClick={this.handleCloseRewardNotify}>{configJSON.dismiss}</DismissButton>
          </Box>
        </Dialog>
      </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  myNoteText:{
    fontSize:'24px',
    fontWeight:600,
    lineHeight:'36px',
    color:'#1C1B1B',
    fontFamily:'Poppins'
  },
  titleButtonBox:{
    display:'flex',
    justifyContent: 'space-between',
    alignItems:'center',
  },
  noteMainBox:{
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems:'center',
    flexWrap:'wrap' as 'wrap'
  },
  noteMainContainer:{
    padding: "0 20px 20px"
  },
  tabsMainBox:{
    display:'flex',
    borderBottom:'1px solid #E5E2E1',
  },
  tabtext:{
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'16px',
    color: '#1A1A1A',
    padding:'20px 30px',
    cursor:'pointer'
  },
  activetabtext:{
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'16px',
    color: '#6C328B',
    borderBottom:'4px solid #6C328B',
    borderRadius:'2px',
    padding:'20px 30px',
    cursor:'pointer',
    
  },
  noData:{
    display: 'flex',
    height: '35%',
    width: '100%',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    textAlign: 'center' as 'center'
  },
  rewardsPaper:{ 
    borderRadius: "16px", 
    marginRight: "15px", 
    boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)",
    padding:'20px 10px' 
  },
  rewardTitleBox:{
    display:'flex',
    gap:'20px',
    paddingBottom:'20px'
  },
  activeRewardTitle:{
    fontSize:'24px',
    fontWeight:600,
    lineHeight:'36px',
    color:'#1C1B1B',
    fontFamily:'Poppins',
    width:'fit-content',
    height:'4px',
    boxShadow: '0 40px 0 0 #484646',
    padding:'1px 5px',
    borderRadius:'4px',
    cursor:'pointer',
    background: 'transparent'
  },
  inActiveReward:{
    fontSize:'24px',
    fontWeight:600,
    lineHeight:'36px',
    color:'#ADAAAA',
    fontFamily:'Poppins',
    cursor:'pointer',
    padding:'1px 5px',
  },
  subTabBox:{
    display:'flex',
    overflowX:'scroll' as 'scroll',
    scrollbarWidth: 'thin' as 'thin'
  },
  activeStatusText:{
    display:'flex',
    gap:'4px',
    alignItems:'center',
    color: '#6C328B',
    borderBottom:'4px solid #6C328B',
    borderRadius:'2px',
    padding:'20px 10px',
    fontFamily:'Poppins',
  },
  statusBox:{
    display:'flex',
    gap:'4px',
    alignItems:'center',
    padding:'20px 10px',
    color: '#1A1A1A',
    borderBottom:'4px solid #fff',
  },
  statusText:{
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'16px',
    cursor:'pointer',
  },
  allIcon:{
    display:'none'
  },
  incompletedotIcon:{
    color:'#E62345',
    fontSize:'8px'
  },
  inprogressdotIcon:{
    color:'#E47004',
    fontSize:'8px'
  },
  notStarteddotIcon:{
    color:'#ADAAAA',
    fontSize:'8px'
  },
  completeddotIcon:{
    color:'#652786',
    fontSize:'8px'
  },
  norewardText:{
    fontSize:'18px',
    fontWeight:600,
    color:' #484646',
    lineHeight:'24px',
    padding:'20px'
  },
  norewardBox:{
    textAlign:'center' as 'center',
    marginTop:'20px'
  },
  rewardRedeemEmptyBox:{
    borderRadius:'12px',
    background:'#ADAAAA',
    display:'flex',
    justifyContent:'space-between',
    padding:'44px 20px',
    alignItems:'center',
    flexWrap:'wrap' as 'wrap'
  },
  cupBox:{
    display:'flex',
    alignItems:'center'
  },
  rewardRedeemtext:{
    fontSize:'20px',
    fontWeight:600,
    lineHeight:'30px',
    color:'#FFF',
    width:'104px'
  },
  noneYetText:{
    lineHeight:'24px',
    color:'#FFF',
    paddingLeft:'5px'
  },
  rewardCount:{
    fontSize:'40px',
    fntWeight:700,
    color:'#FFF',
    paddingRight:'15px'
  },
  rewardCrown:{
    position:'absolute' as 'absolute',
    bottom: '49px',
    left: '-29px',
    width: '97px',
    height: '55px'
  },
  rewardPopup:{
    margin: 'auto' as 'auto',
    maxWidth: '412px',
    overflowY: 'auto' as 'auto',
    zIndex: 9999,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: "16px",
    padding:'25px',
    overflow:'visible',
    position:'relative' as 'relative'
  },
  rewardImage:{
    width:'70px',
    height:'70px',
    borderRadius:'8px'
  },
  rewardPopupBox:{
    textAlign:'center' as 'center'
  },
  redeemHead:{
    fontSize:'18px',
    fontWeight:700,
    lineHeight:'24px',
    color:' #1C1B1B',
    margin:'18px 0'
  },
  redeemDescription:{
    lineHeight:'22px',
    color:' #484646',
    margin:'15px 0 31px'
  },
  closeIcon:{
    position: 'absolute' as 'absolute',
    top: '-8px',
    right: '-8px',
    cursor:'pointer'
  },
  rewardEmailNotify:{
    fontSize:'12px',
    lineHeight:'18px',
    color:'#652786',
    marginBottom:'20px'
  }
};

const DismissButton = withStyles((theme) => ({
  root:{
    background:'#E47004',
    boxShadow:'none',
    borderRadius:'88px',
    width:'300px',
    height:'48px',
    color:'#fff',
    fontFamily:'Poppins',
    fontWeight:600,
    fontSize:'16px',
    textTransform:'capitalize' as 'capitalize',
    '&:hover':{
      background: '#E47004',
      boxShadow:'none'
    },
  }
}))(Button)

const ColorButton = withStyles((theme) => ({
  root: {
    background: '#E47004',
    boxShadow:'none',
    borderRadius:'88px',
    width:'150px',
    height:'48px',
    color:'#fff',
    fontFamily:'Poppins',
    fontWeight:600,
    fontSize:'16px',
    textTransform:'capitalize' as 'capitalize',
    '&:hover':{
      background: '#E47004',
      boxShadow:'none'
    },
    '@media(max-width: 679px)': {
      display:'none',
    },
  },
}))(Button);

const NewNoteButton = withStyles((theme) => ({
  root: {
    background: '#E47004',
    boxShadow:'none',
    borderRadius:'88px',
    width:'150px',
    height:'48px',
    color:'#fff',
    fontFamily:'Poppins',
    fontWeight:600,
    fontSize:'16px',
    textTransform:'capitalize' as 'capitalize',
    '&:hover':{
      background: '#E47004',
      boxShadow:'none'
    },
    '@media(min-width: 680px)': {
      display:'none',
    },
  },
}))(Button);

const RewardCrownCount = withStyles((them) => ({
  root:{
    borderRadius:'12px',
    background:'linear-gradient(180deg, #F42074 0%, #DC1866 100%)',
    display:'flex',
    justifyContent:'space-between',
    padding:'44px 20px',
    alignItems:'center',
    flexWrap:'wrap' as 'wrap',
    '@media(min-width: 960px) and (max-width: 982px)':{
      justifyContent:'center',
      gap:'35px'
    },
    '@media(min-width: 600px) and (max-width: 712px)':{
      justifyContent:'center',
      gap:'35px'
    }
  }
}))(Box)

const Header = styled(Box)(({ theme }) =>({
  position: "absolute",
  zIndex: 9999,
  top: "22px",
  left: "60px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  cursor:"pointer",
  [theme.breakpoints.up("sm")]: {
      left: "110px",
      top: "8px"
  },
  [theme.breakpoints.up("md")]: {
      left: "110px",
      top: "8px"
  },
}));

const BackIcon = styled(ArrowBackIosIcon)({
  marginLeft: "8px",
  cursor: "pointer",
  color: "#92929D",
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "25px",
  lineHeight: "30px",
  color: "#1C1B1B",
  fontFamily: "Poppins, sans-serif",
  [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "30px",
  },
  [theme.breakpoints.up("md")]: {
      fontSize: "48px",
      lineHeight: "72px",
  },
}));

export function TabPanel(props: any): any {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  noData: {
    display: 'flex',
    height: '35%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  cardsPaper: {
    borderRadius: "10px", minHeight: "160px", display: 'flex', flexDirection: "column",
    margin: '8px 0'
  }
}));

const AntTabs: any = withStyles({
  root: {
    borderBottom: '0px solid #e8e8e8',
    marginTop: "10px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const TabsRecent : any= withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginTop: "15px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const AntTab :any = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Montserrat, sans-serif',
    ].join(','),
    '&:hover': {
      color: '#6c328b',
      opacity: 1,
    },
    '&$selected': {
      color: '#6c328b',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#6c328b',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export function NoteTabs({ data, isloading, handleTab, navigation }: { data: any, isloading: any, handleTab: any, navigation: any }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handleTab(newValue)
  };



  return (
    <div className={classes.root}>
      <AntTabs value={value} test-id="change1" onChange={(x: any, y: any) => handleChange(x, y)} aria-label="simple tabs example" >
        {/* @ts-ignore */}
        <AntTab label="All Notes" {...a11yProps(0)} />
        {/* @ts-ignore */}
        <AntTab label="Personal Notes" {...a11yProps(1)} />
        {/* @ts-ignore */}
        <AntTab label="Course Notes" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> : (
              <>
                {
                  data && data.length > 0 ? data.map((item: any, key: any) => {


                    return (
                      <Grid item xs={12} key={key}>
                        {item.attributes.note_type === "personal" ?
                          <Paper elevation={1} style={{ borderRadius: "10px", minHeight: "100px", display: 'flex' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>

                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.is_private ? `${item.attributes.title} - Personal` : `${item.attributes.title} - Public`}
                                  </Typography>
                                  {
                                    !item.attributes.image &&
                                    <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                      {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                      </Typography>}

                                      <Typography dangerouslySetInnerHTML={{ __html: item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description }}></Typography>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              <Box>
                                <IconButton test-id="mask4" onClick={() => navigation?.navigate("CourseNotes")}>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                          :
                          <Paper elevation={1} style={{ borderRadius: "10px", minHeight: "100px", display: 'flex' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                {item.attributes.image ? (
                                  <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                ) : (
                                  <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                )}
                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.title}
                                  </Typography>
                                  {
                                    !item.attributes.image &&
                                    <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                      {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                      </Typography>}
                                      <Typography variant="subtitle1" className="profileName">
                                        {item.attributes.note_type}
                                      </Typography>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              <Box>
                                <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        }
                      </Grid>
                    )


                  }) : <Box className={classes.noData} >Data Not Found</Box>}


                <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                  <Typography variant="h6" test-id="CourseNotes2" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation?.navigate("CourseNotes")}>
                    View all
                  </Typography>
                </Box>
              </>
            )

          }
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> : (
              <>{
                data && data.length > 0 ? data.map((item: any, key: any) => {
                  return (
                    <Grid item={true} xs={12} key={key}>

                      <Paper elevation={1} style={{ borderRadius: "10px", minHeight: "100px", display: 'flex' }}>
                        {
                          item.attributes.is_private ?
                            <Box style={{ backgroundColor: "#6c328b", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                            <Box style={{ backgroundColor: "#ff8b3f", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                        }
                        <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>

                            <Box>
                              <Typography variant="h6" className="profileName">
                                {item.attributes.is_private ? `${item.attributes.title} - Personal` : `${item.attributes.title} - Public`}
                              </Typography>
                              {
                                !item.attributes.image &&
                                <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                  {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                  </Typography>}
                                  <Typography dangerouslySetInnerHTML={{ __html: item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description }}></Typography>
                                </Box>
                              }
                            </Box>
                          </Box>
                          <Box>
                            <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )


                }) :
                  <Box className={classes.noData} >Data Not Found</Box>
              }
                <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                  <Typography variant="h6" test-id="CourseNotes" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation?.navigate("CourseNotes")}>
                    View all
                  </Typography>
                </Box>
              </>
            )
          }
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> :
              (
                <>{
                  data && data.length > 0 ? data.map((item: any, key: any) => {


                    return (
                      <Grid item={true} xs={12} key={key}>
    
                        <Paper elevation={1} style={{ borderRadius: "10px", minHeight: "100px", display: 'flex' }}>
                          {
                            item.attributes.is_private ?
                              <Box style={{ backgroundColor: "#6c328b", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                              <Box style={{ backgroundColor: "#ff8b3f", minHeight: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                          }
                          <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box display="flex" style={{ alignItems: 'center' }}>
                              {item.attributes.image ? (
                                <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                              ) : (
                                <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                              )}
                              <Box>
                                <Typography variant="h6" className="profileName">
                                  {item.attributes.title}
                                </Typography>
                                {
                                  !item.attributes.image &&
                                  <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                    {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                    </Typography>}
                                    <Typography variant="subtitle1" className="profileName">
                                      {item.attributes.note_type}
                                    </Typography>
                                  </Box>
                                }
                              </Box>
                            </Box>
                            <Box>
                              <IconButton test-id="CourseNotes3" onClick={() => navigation?.navigate("CourseNotes")}>
                                <ArrowForwardIosIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    )


                  }) :
                    <Box className={classes.noData} >Data Not Found</Box>
                }
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                    <Typography variant="h6" test-id="CourseNotes4" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation?.navigate("CourseNotes")}>
                      View all
                    </Typography>
                  </Box>
                </>
              )
          }
        </Grid>
      </TabPanel>
    </div>
  );
}

export function RecentTabs({ recentLoading, recentInsightLoading, recentInsight, recentActivity, handleRecentTab }: { recentLoading: any, recentInsightLoading: any, recentInsight: any, recentActivity: any, handleRecentTab: any }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handleRecentTab(newValue)
  };

  console.log(recentInsight, 'recent log');


  return (
    <div className={classes.root}>
      <TabsRecent value={value} test-id="change2" onChange={handleChange} aria-label="simple tabs example">
        {/* @ts-ignore */}
        <AntTab label="Recent Insights" {...a11yProps(0)} />
        {/* @ts-ignore */}
        <AntTab label="Recent Activity" {...a11yProps(1)} />
      </TabsRecent>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            recentInsightLoading ?
              <Box className={classes.noData} > <CircularProgress /> </Box> :
              <>
                <Grid item xs={12}>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Last video viewed
                    </Typography>
                    {
                      recentInsight.last_video_viewed ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.last_video_viewed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold", wordBreak:'break-all' }}>
                                {recentInsight?.last_video_viewed?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No any Recent Insights</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Last course completed
                    </Typography>
                    {
                      recentInsight.last_course_completed ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.last_course_completed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold", wordBreak: 'break-all' }}>
                                {recentInsight?.last_course_completed?.title}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have not completed any Course</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently purchased course
                    </Typography>
                    {
                      recentInsight.recent_purchase_course ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center', height: '50%' }}>
                            <img src={recentInsight?.recent_purchase_course?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {recentInsight?.recent_purchase_course?.title}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have not purchased any course</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recent Achievement
                    </Typography>
                    {
                      recentInsight.recent_achievement ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_achievement?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {recentInsight?.recent_achievement?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No Recent Achievement</Typography>
                        </Box>
                    }

                  </Paper>

                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recent Reward
                    </Typography>
                    {
                      recentInsight.recent_reward ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_reward?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {recentInsight?.recent_reward?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No Recent Reward</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently finished goal
                    </Typography>
                    {
                      recentInsight.recent_finished_goal ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_finished_goal?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {recentInsight?.recent_finished_goal?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have no recently finished goal</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently finished assignment
                    </Typography>
                    {
                      recentInsight.recent_finished_assignment ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_finished_assignment?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {recentInsight?.recent_finished_assignment?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have no  recently finished assignment</Typography>
                        </Box>
                    }

                  </Paper>
                </Grid>


              </>
          }


        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            recentLoading ? <Box className={classes.noData} > <CircularProgress /> </Box> :
              (
                <>
                  {
                    recentActivity && recentActivity.length > 0 ? recentActivity.slice(0, 8).map((item: any, index: any) => {

                      return (
                        <Grid item xs={12} key={index}>
                          <Paper elevation={5} style={{ borderRadius: "10px", minHeight: "160px", display: 'flex', flexDirection: "column" }}>
                            <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                              {item.attributes.activity_comment}
                            </Typography>
                            <Box style={{ width: '100%', paddingLeft: '15px' }}>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                {item.attributes.activity_model_name}
                              </Typography>
                              <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: "15px" }}>
                                  {item.attributes.duration}
                                </Typography>
                              </Box>
                            </Box>


                          </Paper>
                        </Grid>
                      )
                    }) :
                      <Box className={classes.noData} >Data Not Found</Box>
                  }
                </>
              )}

        </Grid>
      </TabPanel>
    </div>
  );
}
// Customizable Area End
