import React from "react";

// Customizable Area Start
import './ForgotPassword.css'
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  InputAdornment,
  Button,
  IconButton


} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom'
import { LoginImage, ErrorImageBlW } from "./assets";
import AlertBlock from "../../alert/src/ActionAlert.web";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'end',
  color: theme.palette.text.secondary,
}));

export default class EmailAccountLoginBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  handleSubmit(user: any) {
    console.log(JSON.parse(user).email, 'forgotPassword==========>');
    let payload = {
      data: {
        attributes: {
          email: JSON.parse(user).email,
          platform: "web"
        }
      }
    }
    this.getResetPassword(payload)
  }

  render() {
    return (
      // Required for all blocks
      <>
        <AlertBlock data={{ show: this.state.alert.isOpen, close: () => { this.setState({ alert: { isOpen: false, msg: '', type: '' } }) }, msg: this.state.alert.msg, type: this.state.alert.type, vertical: "top", horizontal: "right" }} />
        <Grid container style={{ height: "100vh", width: "100vw" }} className="forgotPassword">
          <Grid
            xs={'auto'}
            sm={4}
            md={4}
            lg={3}
            item
            style={{
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          />
          <Grid item xs={12} sm={8} md={8} lg={9} className="form-container" component={Paper} elevation={6} square style={{ overflowY: 'scroll', height: '100vh' }}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item className="login-route">
                <Item ><Link to={'/signup'} className="text">Don't have an account ?<span style={{ paddingLeft: '20px' }}> Sign up for free</span></Link></Item>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} className="login-route mt-10">

                <Formik
                  initialValues={{
                    email: '', showPassword: false, password: "",
                  }}
                  onSubmit={async values => {
                    this.handleSubmit(JSON.stringify(values))

                  }}
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      .email('Invalid email id, Please sign up')
                      .required('Email is required'),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                    } = props;
                    return (
                      <form className="regform" onSubmit={handleSubmit}>
                        <Grid item md={12} xs={12} sm={12} className="typo">
                          <Typography component='h5' variant="h4" className="forgot-heading">Forgot password</Typography>
                        </Grid>
                        <Grid className="form" container spacing={5}>
                          <Grid item md={12} xs={12} sm={12}>

                            <InputLabel className="label-input">Enter the account email</InputLabel>
                            {/* <TextField
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          variant="outlined"
                          placeholder="Your email"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="mail outlined"
                                  edge="end"
                                  
                                >
                                  <MailOutlineIcon fontSize="large" style={{color:"#7E8696"}} />
                                </IconButton>
                              </InputAdornment>
                          }}
                          fullWidth
                        /> */}
                            <TextField
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              error={touched.email && Boolean(errors.email)}
                              helperText={
                                touched.email && errors.email ? (
                                  <React.Fragment>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                      <img src={ErrorImageBlW} alt="Error" style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                                      {errors.email}
                                    </Box>
                                  </React.Fragment>
                                ) : null
                              }
                              variant="outlined"
                              placeholder="Your email"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="mail outlined" edge="end">
                                      <MailOutlineIcon fontSize="large" style={{ color: "#7E8696" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />

                          </Grid>
                        </Grid>
                        <Grid container spacing={5} className="form">
                          <Grid item md={12} xs={12} sm={12}>
                            <Button type="submit"
                              // className={values.email ? "enable" : "disable"}
                              className={values.email && errors.email !== 'Invalid email id, Please sign up' ? "enable" : "disable"}
                              fullWidth
                            >Continue</Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Box>
          </Grid>

        </Grid>
      </>
    );
  }
}
// Customizable Area End
