import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";
import DynamicTitle from "../../../components/src/DynamicTitle"

// Customizable Area Start
import { createStyles, Theme } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as yup from "yup";
import "react-calendar/dist/Calendar.css";
import "./CourseCreation.css";
import CourseCreationForm from "./components/CourseCreationForm";
import CourseCreationform from "./CourseCreationformTwo.web";
// Customizable Area End

import CourseCreationController, {
  Props,
} from "./CourseCreationController.web";
import { Calender, Caret, CirclePlusRA, Dollar, EditCourse } from "./assets";
import moment from "moment";
import NotifyAutocomplete from "../../../components/src/NotifyAutocomplete";


// Customizable Area Start
class CourseCreation extends CourseCreationController {
  wrapperRefLaunchDate: any;
  wrapperRefDueDate: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleSubmit(data: any) {
    if (this.props?.navigation.getParam("type") === "editCourse") {
      this.setState({
        editTrue: true
      })
    }
   let category=data.category || [];
    
    let fd = new FormData();
    if(this.state.createNewTitle){
      fd.append("course[title]", this.state.createNewTitle);
    }else if (data.title){
    fd.append("course[title]", data.title);
    }
    fd.append("course[description]", data.description);
    const number=this.state.editTrue?"course[course_number]":"course[number]"
    fd.append(number, data.course_id);
    fd.append("course[expert_id]", data.expert_id);
    fd.append("course[is_sellable]", data.is_sellable);
    data.price && fd.append("course[price]", data.price);
    fd.append("course[is_forced_learning]", data.is_forced_learning);
    fd.append("course[add_to_xpand_collection]", data.add_to_xpand_collection)
    fd.append(
      "course[is_notify_when_completed]",
      data.is_notify_when_completed
    );
    if (data.is_notify_when_completed) {
      for (const userId of data.notify_when_completed) {
          fd.append("admin_user_id[]", userId);
      }
  }
    fd.append(
      "course[days_to_complete_the_course]",
      data.days_to_complete_the_course
    );
    data.days_to_complete_the_course &&
      fd.append("course[course_timeline]", data.course_timeline);

    data.part_of_program &&
      data.program_id.forEach((data: any) => {
        fd.append("programs_id[]", data.id);
      });

    if(data.content_roles?.length){
      data.content_roles.forEach((data: any) => {
          fd.append("content_role_ids[]", data.id);
      });
    } else {fd.append("content_role_ids[]", "");}
    data.content_release_schedule && fd.append("course[number_of_phases]", data.phases);
    fd.append("course[include_launch_date]", data.include_launch_date);
    if(this.state.launchDate){
    data.include_launch_date &&
      fd.append(
        "course[launch_date]",
        this.state.launchDate.toLocaleString("en-GB", { timeZone: "UTC" }))}else if (data.launch_date){
          data.include_launch_date &&
          fd.append(
            "course[launch_date]",
            data.launch_date.toLocaleString("en-GB", { timeZone: "UTC" }))
        }
   
    fd.append("course[add_due_date]", data.add_due_date);
    if(this.state.dueDate){
    data.add_due_date &&
      fd.append(
        "course[due_date]",
        this.state.dueDate.toLocaleString("en-GB", { timeZone: "UTC" })
      );
    }else if (data.due_date){
    data.add_due_date &&
      fd.append(
        "course[due_date]",
        data.due_date.toLocaleString("en-GB", { timeZone: "UTC" })
      );
    }
  
    fd.append(
      "course[content_release_schedule]",
      data.content_release_schedule
    );
    data.content_release_schedule &&
      fd.append(
        "course[content_release_schedule_value]",
        data.content_release_schedule_value
      );
    data.subscription_period ? fd.append("course[subscription_period]", data.subscription_period):fd.append("course[subscription_period]", "one_time_purchase");
   if(this.state.isStatus)
     fd.append("course[status]", "draft")
    if (
      (((this.props?.navigation.getParam("id") === "new" &&
        this.props?.navigation.getParam("type") === "addCourse") ||
        this.props?.navigation.getParam("type") === "editCourse") &&
        this.props?.location?.state?.image) ||
      this.props?.location?.state?.imgUrl
    ) {
      //@ts-ignore
      fd.append("course[add_thumbnail]", true);
      this.props?.location?.state?.image !== ""
        ? fd.append(
          "course[thumbnail_image]",
          this.props?.location?.state?.file
        )
        : fd.append("course[stock_image_id]", this.props?.location?.state?.id);
    }
      const selectedCategories = category
      .filter((item: { name: string; attributes: { is_selected: boolean; }; }) => item.name || item.attributes.is_selected === true)
      .map((item: { id: number; }) => item.id);
    
    if (selectedCategories.length === 0) {
      fd.append("categories_id[]", "");
    } else {
      for (const categoryId of selectedCategories) {
        fd.append("categories_id[]", categoryId);
    }
          
    }

    fd.append("thumbnail_image_id",this.state.thumbnail)
    if(this.props.navigation.getParam("id") !== "new" && this.props.navigation.getParam("type") === "editCourse" ){
      this.updateCourse(fd, this.props.navigation.getParam("id"))
   }else if(this.props.navigation.getParam("id") === "new" && this.props.navigation.getParam("type") === "addCourse")
      {
        this.createCourse(fd);
     }else{
      this.updateCourse(fd, this.props.navigation.getParam("id"))
     }
  }

  handleFormValChange=(name:string, val:any)=>{
    if(name==="add_due_date"){
      this.setState({
        allData:{...this.state.allData,add_due_date:val,days_to_complete_the_course:!val},
        formDirty:true
      })
    }
   else if(name==="days_to_complete_the_course"){
    this.setState({
      allData:{...this.state.allData,days_to_complete_the_course:val,add_due_date:!val},
      formDirty:true
    })
    } else{
      this.setState({
        allData:{...this.state.allData,[name]:val},
        formDirty:true
      })
    }
    
  }
  // Customizable Area End

  render() {
    if (this.props?.location?.state?.selectedContentRole) {
      window.history.replaceState({ state: this.props?.location?.state }, "");
    }
    const { allData } = this.state;
        const { formObject } = this.props?.location?.state || {};
    return (
      // Customizable Area Start
      <>
        {(this.props.navigation.getParam("type") === "addCourse" || this.props?.navigation.getParam("type") !== "editCourse" ||
          this.state?.course?.attributes ||
          this.props?.location?.state?.formObject) && (
            <Formik
              data-test-id="btn_submit"
              enableReinitialize={true}
              initialValues={{
                id: allData.id,
                title: allData?.title || "",
                description: allData?.description || "",
                is_sellable: allData?.is_sellable || false,
                price: allData?.price || "",
                is_forced_learning: allData?.is_forced_learning || false,
                is_notify_when_completed: allData?.is_notify_when_completed || false,
                notify_when_completed: allData?.notify_when_completed || "",
                time_constraint: allData?.time_constraint || false,
                add_preview: allData?.add_preview || false,
                course_timeline: allData?.course_timeline || "",
                part_of_program: allData?.part_of_program || false,
                include_launch_date: allData?.include_launch_date || false,
                launch_date: allData?.launch_date ? new Date(allData?.launch_date) : null,
                category: allData?.categories || [],
                due_date: allData?.due_date ? new Date(allData?.due_date) : null,
                content_release_schedule: allData?.content_release_schedule || false,
                content_release_schedule_value: allData?.content_release_schedule_value || "",
                add_thumbnail: allData?.add_thumbnail || false,
                thumbnail_img:  allData?.thumbnail_image?.url || this.state.thumbnail,
                add_due_date: allData?.add_due_date || false,
                add_to_xpand_collection: allData?.add_to_xpand_collection || false,
                add_reward: allData?.add_reward || false,
                expert_id:  allData?.expert_id || this.state.programSelectedExpert?.id ||"",
                add_achievement: allData?.add_achievement || false,
                subscription_period: allData?.subscription_period || "",
                launchDateToggle: allData?.launchDateToggle || false,
                dueDateToggle: allData?.dueDateToggle || false,
                days_to_complete_the_course: allData?.days_to_complete_the_course || false,
                phases: allData.phases || allData.contents.length || "",
                is_free_trial: allData?.is_free_trial || false,
                isEdit: allData?.isEdit || false,
                course_id:allData?.course_number,
                program_id: allData?.program_id || [],
                start_date: allData?.start_date ? new Date(allData?.start_date) : new Date(),
                end_date: allData?.end_date ? new Date(allData?.end_date) : new Date(),
                status: allData?.status ?? '',
                content_roles: allData?.content_roles?.length ? allData.content_roles : [],
                has_user_purchased: allData.has_user_purchased,
                ...formObject,
              }}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
              validationSchema={yup.object().shape({
                title: yup
                  .string()
                  .required("Title is required")
                  // .min(5, "min 5 characters required")
                  .max(150, "max 50 characters required"),
                  thumbnail_img: yup
                  .string()
                  .required("Thumbnail is required")
                  .nullable(),
                description: yup
                  .string()
                  .max(1500, "max 1500 characters required"),
                  expert_id: yup
                  .string()
                  .required("Field is required"),
                  price: yup
                  .string()
                  .when('is_sellable', {
                    is: true, // Condition: if is_sellable is true
                    then: yup.string()
                      .required('Price is required')
                      // .min(5, "min 5 characters required")
                      .max(150, 'max 50 characters required'),
                    otherwise: yup.string().notRequired(), // Not required if is_sellable is false
                  }),

              })}
            >
              {(props) => {
                return (
                  <CourseCreationform
                    data-test-id="CourseCreation"
                    id=""
                    formikProps={props}
                    navigation={this.props?.navigation}
                    historyProp={this.props}
                    handleThumbnailChange={this.handleThumbnailChange}
                    handleLaunchDate={this.handleLaunchDate}
                    handleDueDate={this.handleDueDate}
                    onDraft={this.changeStatus}
                    handleToastMsg={this.handleToastMsg}
                    handleTitle={this.handleTitle}  
                    handleFormValChange={this.handleFormValChange}
                    location={this.props.location}
                    allData={allData}
                    handleArchived={async (id:string,expand_type:string)=> await this.handleArchived(id, expand_type)}
                    handleUnArchived={async (id:string,expand_type:string)=> await this.handleUnArchived(id, expand_type)}
                    />
                );
              }}
            </Formik>
          )}
        {this.props?.navigation.getParam("type") !== "addCourse" && this.props?.navigation.getParam("type") !== "editCourse" &&
          this.state.isLoading && (
            <Box
              style={{
                display: "flex",
                height: "35%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <CircularProgress />{" "}
            </Box>
          )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default CourseCreation;
// Customizable Area End
// Customizable Area End