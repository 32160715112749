import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  NEW_COURSE_ASSIGNED,
  NEW_COURSE_ENROLL,
  NEW_COURSES,
  DUE_REMINDER,
  EARNED_REWARD,
  EARNED_ACHIEVEMENT,
  EARNED_REWARD_ADMIN,
  EARNED_ACHIEVEMENT_ADMIN,
  COURSE_COMPLETED,
} from "./reminderTypes";

interface ITitle {
  type: string;
  courseOrProgramName: string;
  dueIn: number;
  rewardOrAchieventName?: string;
  userName?: string;
}

function DueInTitle({
  dueIn,
  courseOrProgramName,
}: Pick<ITitle, "dueIn" | "courseOrProgramName">) {
  const classes = useStyles();
  let positiveDueIn;

  if (dueIn > 0) {
    return (
      <h3 className={`${classes.cardTitle} ${classes.dueToday}`}>
        <span className={classes.title}>{courseOrProgramName}</span> is overdue!
        <br /> Yeah, you read that right.. OVERDUE!
      </h3>
    );
  } else if (dueIn === 0) {
    return (
      <h3 className={`${classes.cardTitle} ${classes.dueToday}`}>
        <span className={classes.title}>{courseOrProgramName}</span> is due
        today! Yeah, you read that right... TODAY!
      </h3>
    );
  } else if (dueIn >= -2 && dueIn < 0) {
    // [-2, -1, 0)
    positiveDueIn = Math.abs(dueIn);
    const dayText = positiveDueIn > 1 ? "days" : "day";
    return (
      <h3 className={`${classes.cardTitle} ${classes.dueInOne}`}>
        Last reminder!
        <br />
        <span className={classes.title}>{courseOrProgramName}</span> is due in{" "}
        {positiveDueIn} {dayText}!
      </h3>
    );
  } else if (dueIn >= -6 && dueIn <= -3) {
    // [-6, -5, -4, -3]
    positiveDueIn = Math.abs(dueIn);
    return (
      <h3 className={`${classes.cardTitle} ${classes.dueInThree}`}>
        Another reminder!
        <br />
        <span className={classes.title}>{courseOrProgramName}</span> is due in{" "}
        {positiveDueIn} days!
      </h3>
    );
  } else if (dueIn <= -7) {
    positiveDueIn = Math.abs(dueIn);
    return (
      <h3 className={`${classes.cardTitle} ${classes.dueInSeven}`}>
        Reminder!
        <br />
        <span className={classes.title}>{courseOrProgramName}</span> is due in{" "}
        {positiveDueIn} days!
      </h3>
    );
  }
  return null;
}

export default function Title({
  type,
  userName,
  courseOrProgramName,
  dueIn,
  rewardOrAchieventName,
}: ITitle) {
  const classes = useStyles();
  let firstName;
  switch (type) {
    case NEW_COURSES:
    case NEW_COURSE_ASSIGNED:
      firstName = getFirstName();
      return <h3 className={classes.cardTitle}>Great News {firstName}!</h3>;
    case NEW_COURSE_ENROLL:
      return (
        <h3 className={classes.cardTitle}>
          Congrats on enrolling in {courseOrProgramName}!
        </h3>
      );
    case DUE_REMINDER:
      return (
        <DueInTitle dueIn={dueIn} courseOrProgramName={courseOrProgramName} />
      );
    case EARNED_REWARD:
      firstName = getFirstName();
      return (
        <h3 className={`${classes.cardTitle} ${classes.rewardTitle}`}>
          Hey {firstName}! <br />
          Congrats on earning your {rewardOrAchieventName}!
        </h3>
      );
    case EARNED_ACHIEVEMENT:
      firstName = getFirstName();
      return (
        <h3
          className={`${classes.cardTitle} ${classes.rewardTitle} ${
            classes.emphasis
          }`}
        >
          Hey {firstName}! <br />
          Congrats on earning your achievement, {rewardOrAchieventName}!
        </h3>
      );
    case EARNED_REWARD_ADMIN:
      return (
        <h3 className={classes.cardTitle}>
          {userName} has earned {rewardOrAchieventName}!
        </h3>
      );
    case EARNED_ACHIEVEMENT_ADMIN:
      return (
        <h3 className={classes.cardTitle}>
          {userName} has achieved {rewardOrAchieventName}!
        </h3>
      );
    case COURSE_COMPLETED:
      return (
        <h3 className={classes.cardTitle}>
          {courseOrProgramName}
        </h3>
      );
    default:
      return null;
  }
}

function getFirstName() {
  const firstNameLC = localStorage.getItem("first_name");
  return firstNameLC ? firstNameLC : "User";
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textTransform: "capitalize",
    },
    cardTitle: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#000000",
    },
    rewardTitle: {
      marginTop: 155,
    },
    emphasis: {
      color: "#6C328B",
      fontWeight: 700,
    },
    dueToday: {
      color: "#EF2B4B",
    },
    dueInOne: {
      color: "#F42074",
    },
    dueInThree: {
      color: "#FF8B3F",
    },
    dueInSeven: {
      color: "#6C328B",
    },
  })
);