// Customizable Area Start
import React from "react";
import { Box, Typography, Switch } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { NotificationSettings } from "./Settings5Controller.web";

interface Props {
  settings: NotificationSettings;
  updateSettings: (key: string) => void;
}

export default function NotificationSettingsWeb({ settings, updateSettings }: Props) {
  const classes = useStyles();

  const listOfSettings = Object.keys(settings).map((key: string) => {
    return (
      <Box className={classes.listItem} key={key}>
        <Typography component="p" className={classes.listItemText}>
          {/* @ts-ignore */}
          {settings[key].label}
        </Typography>
        <Switch
          // @ts-ignore
          checked={settings[key].status}
          onChange={() => updateSettings(key)}
          name={key}
          // @ts-ignore
          inputProps={{ "aria-label": settings[key] }}
          className={classes.switch}
          classes={{
            track: classes.track,
          }}
        />
      </Box>
    );
  });

  return <>{listOfSettings}</>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 10px",
      [theme.breakpoints.up("sm")]: {
        width: "430px",
        padding: "12px 20px",
        paddingRight: 0,
      },
      [theme.breakpoints.up("md")]: {
        width: "570px",
      },
    },
    listItemText: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#11142D",
      lineHeight: "20px",
    },
    switch: {
      "& .Mui-checked": {
        color: "#9F2F72"
      },
    },
    track: {
      background: "linear-gradient(90deg, #6C328B 0%, #EF2B4B 119.44%)",
      mixBlendMode: "normal",
      opacity: "0.37",
    },
  })
);

// Customizable Area End
