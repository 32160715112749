import React from "react";
import Library2Controller from "./Library2Controller.web";
import {
    Box, Button, Grid, TextField
    // Customizable Area End
    ,
    // Customizable Area Start
    Paper, Typography, Radio, RadioGroup, FormControlLabel
} from "@material-ui/core";
import RangeSlider from '@material-ui/core/Slider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PersonalSlider from "../../../components/src/LibraryComponent/PersonalSlider";
import CorporateSlider from "../../../components/src/LibraryComponent/CorporateSlider";
import XpandSlider from "../../../components/src/LibraryComponent/XpandSlider";
import './videostyles.css';
const play = require("../assets/play.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")
const Shape = require("../assets/Shape.jpg");
const Short = require("../assets/Sort.png")
const fill = require("../assets/Fill.png")
const play1 = require("../assets/Object.png");
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End


export default class Search extends Library2Controller {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start
    // Customizable Area End
    componentDidMount() {
        this.GetAllSearch_data();
        window.scrollTo(0, 0)
    }

    render() {
        let AllSliderData = <>
            <Paper className="paper" style={styles.paper_personal}>
                <Typography style={styles.typography_personal}>Personal</Typography>
                <Typography style={styles.typography_view_personal}
                    onClick={() => { return this.props.navigation.navigate('PersonalPageWeb') }}
                >View More <img src={Shape} style={styles.img_shape} /></Typography>
                <PersonalSlider style={this.state.style} last_trackVideo={this.last_trackVideo} handleNavigate={this.handleNavigate} props={this.props} personalData={this.state.Search_DetailData?.personal_libraries} />
            </Paper>

            <Paper className="paper" style={styles.paper_corporate}>
                <Typography style={styles.typography_personal}>Corporate Courses</Typography>
                <Typography style={styles.typography_view_personal}
                    onClick={() => {
                        return this.tostSuccessfully()
                    }}
                >View More <img src={Shape} style={styles.img_shape} /></Typography>
                <CorporateSlider last_trackVideo={this.last_trackVideo} CorporateData={this.state.Search_DetailData} handleNavigate={this.handleNavigate} tostSuccessfully={this.tostSuccessfully} style={undefined} />
            </Paper>

            <Paper className="paper" style={styles.paper_xpand}>
                <Typography style={styles.typography_personal}>Xpand Collection</Typography>
                <Typography style={styles.typography_view_personal}
                    onClick={() => {
                        return this.props.navigation.navigate('XpandPageWeb')
                    }}
                >View More <img src={Shape} style={styles.img_shape} /></Typography>
                <XpandSlider style={this.state.style} postplayButton={this.postplayButton} handleNavigate={this.handleNavigate} props={this.props} XpandData={this.state.Search_DetailData} postXpandLibraryData={this.postXpandLibraryData} handleSubscription={this.handleSubscription} addCartItem={this.addCartItem} />
            </Paper>
        </>

        return (
            <Box style={{ marginTop: '-15px' }}>
                <Box onClick={() => this.props.history.push('/library')} style={{ paddingLeft: '15px', cursor: 'pointer' }}>
                    <img src={fill} style={{ marginBottom: '5px', paddingRight: '20px' }} />
                    <Typography style={styles.mainhadding} variant="h3" className="profile_header">Courses/Programs</Typography>
                </Box>
                <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2} style={{ marginTop: '30px' }}>
                    <Grid item lg={10} md={10} sm={6} xs={6}>
                        <SearchChild searchDetail={this.state.Search_DetailData} booleanValue={this.state.onClick} HandleChangeSeacrh={this.HandleChangeSeacrh} search={this.state.search} isToggled={this.state.isToggledSearch} handleisToggled1Click={this.handleisToggled1Click} handleNavigate={this.handleNavigate} searchData={this.state.searchData} XpandData={this.state.autoComplete} tostSuccessfully={this.topBarSearch} />
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                        <Box style={styles.mainContainer}>
                            <Box style={styles.BoxContainer}>
                                <Button
                                    id="filter-open-btn-tst"
                                    onClick={() => {
                                        return this.searchFilter()
                                    }}
                                    variant="outlined" style={styles.filterButton}>
                                    Filter
                                </Button>
                            </Box>
                            <Box style={styles.IconBox}>
                                <Button id="search-open-btn-tst" onClick={() => this.searchShort()} variant="outlined" style={styles.filterIcon}><img src={Short} /></Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Dialog className="dialog" hideBackdrop onClose={this.closeSearchFilter} aria-labelledby="simple-dialog-title" open={this.state.filter}>
                    <Paper style={{ borderRadius: '10px', width: '204px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)', overflow: 'hidden auto', paddingBottom: '60px' }}>
                        <Box style={{ padding: '15px' }}>
                            <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                                <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                                <hr style={{ width: '146px', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                            </Box>
                        </Box>
                        <Accordion defaultExpanded={true} style={{ marginTop: '-23px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontSize: '13px', fontWeight: 600 }}>Categories</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginTop: '-40px' }}>
                                <Box style={{ marginTop: '15px' }}>
                                    {this.state.libraryCheckedData?.data?.map((data: any, index: any) => (
                                        <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0 5px 0' }}>
                                            <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.attributes.name}</Typography>
                                            <Checkbox
                                                checked={this.state.checkboxChecked[data.attributes.id] || false}
                                                onChange={(event: any) => this.checkedhandleChange(data.attributes.id, data.attributes.is_selected, event)}
                                                // color="#6C328B"
                                                id="handle-checkbox-changed-btn-tst"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                style={{ marginTop: '-12px', marginRight: '-10px', color: '#6C328B' }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontSize: '13px', fontWeight: 600 }}>Payment Method</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginTop: '-40px' }}>
                                <Box style={{ marginTop: '15px' }}>
                                    {this.state.libraryCheckedData3?.map((data: any, index: any) => (
                                        <Box key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.text}</Typography>
                                            <Checkbox
                                                checked={this.state.checkboxChecked1[data.name] || false}
                                                onChange={(event: any) => this.checkedhandleChange3(data.name, event)}
                                                // color="primary"
                                                id="handle-checkbox0-changed-btn-tst"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                style={{ marginTop: '-12px', marginRight: '-17px', color: '#6C328B' }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                            <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Courses Only</Typography>
                            <Checkbox
                                onChange={this.handleCheckboxChange}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                            />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                            <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Programs Only</Typography>
                            <Checkbox
                                onChange={this.handleCheckboxChangeProgram}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                            />
                        </Box>

                        <Box style={{ padding: '15px' }}>
                            <Button id="apply-filter-submit-btn-tst" onClick={() => this.CheckedSearchDataSort()} variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', fontWeight: 700, border: '1.8px solid #6C328B', borderRadius: '10px' }}>
                                Apply
                            </Button>
                        </Box>
                    </Paper>
                </Dialog>

                <Dialog className="dialogSort" hideBackdrop onClose={this.closeSearchSort} aria-labelledby="simple-dialog-title" open={this.state.sort}>
                    <Paper style={{ borderRadius: '10px', width: '204px', padding: '15px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)' }}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
                            <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Sort Courses</Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '92%' }}>
                            <Typography style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                            <hr style={{ width: '146px', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                        </Box>

                        <Box style={{ marginTop: '15px' }}>
                            <RadioGroup value={this.state.selectedIndices} onChange={this.checkedhandleChange2}>
                                {this.state.libraryCheckedData2.map((data: any) => (
                                    <FormControlLabel
                                        key={data.id}
                                        value={data.name}
                                        control={<Radio style={{ color: '#6C328B' }} />}
                                        label={
                                            <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>
                                                {data.text}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                            <Typography style={{ paddingBottom: '10px', paddingTop: '10px', fontSize: '14px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Prices</Typography>
                            <Typography style={{ marginLeft: '140px', marginBottom: '-16px', fontSize: '10px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>$1000</Typography>
                            <Typography style={{ fontSize: '10px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>${this.state.percentage}</Typography>
                            <RangeSlider
                                min={50}
                                max={1000}
                                className="rangeInput"
                                defaultValue={this.state.percentage}
                                onChange={this.percentage_handler}
                                aria-label="Default"
                            />
                        </Box>

                        <Box>
                            <Button id="handle-apply-sort-tst" onClick={() => this.applySearchShorting()} variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', borderRadius: '10px', fontWeight: 700 }}>
                                Apply
                            </Button>
                        </Box>
                    </Paper>
                </Dialog>
                {this.state.isToggledSearch ? <XpandSlider1 AllSliderData={AllSliderData} isToggled={this.state.isToggledSearch} style={this.state.style} postplayButton={this.postplayButton} handleNavigate={this.handleNavigate} props={this.props} XpandData={this.state.collections} postXpandLibraryData={this.postXpandLibraryData} handleSubscription={this.handleSubscription} addCartItem={this.addCartItem} /> :
                    <Paper className="paper" style={this.state.isToggledSearch ? styles.paperStyleAfter : styles.paperStyleBefore}>
                        <Typography style={{ fontWeight: 700, fontSize: '24px' }}>Courses/Programs</Typography>
                        <Typography style={styles.subHadding}>This results shows courses based on the keywords of your search</Typography>
                        <Typography id="view-more-btn-tst" style={{ fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '-36px', cursor: 'pointer', color: 'rgba(97, 97, 97, 1)' }}
                            onClick={() => this.handleisToggledClick()}>View More <img src={Shape} style={{ paddingLeft: '13px' }} /></Typography>
                        <XpandSlider1
                            isToggled={this.state.isToggledSearch}
                            style={this.state.style}
                            postplayButton={this.postplayButton}
                            handleNavigate={this.handleNavigate}
                            props={this.props}
                            XpandData={this.state.collections}
                            postXpandLibraryData={this.postXpandLibraryData}
                            handleSubscription={this.handleSubscription}
                            addCartItem={this.addCartItem} />
                    </Paper>
                }
            </Box>
        )

    }

}

const styles = {
    paperStyleBefore: {
        overflow: 'hidden', flex: 1, height: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    paperStyleAfter: {
        overflow: 'hidden', flex: 1, minHeight: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    mainhadding: {
        display: 'contents',
        fontSize: '48px',
        fontWeight: 600,
    },
    subHadding: {
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '5px'
    },
    mainContainer: {
        display: 'flex'
    },
    BoxContainer: {
        width: '100%',
        height: '45px',
        marginTop: '-5px'
    },
    filterButton: {
        color: '#6C328B',
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        fontWeight: 700,
        border: '1px solid #6C328B'
    },
    IconBox: {
        width: '100%',
        marginTop: '-5px',
        paddingLeft: '15px'
    },
    filterIcon: {
        width: '75px',
        height: '45px',
        borderRadius: '12px',
        border: '1px solid #6C328B',
        cursor: 'pointer'
    },
    paper_personal: {
        flex: 1,
        overflow: 'hidden',
        height: '490px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    typography_personal: {
        fontWeight: 700,
        fontSize: '24px'
    },
    typography_view_personal: {
        fontWeight: 700,
        fontSize: '17px',
        float: 'right' as 'right',
        marginTop: '-36px',
        cursor: 'pointer',
        color: 'rgba(97, 97, 97, 1)'
    },
    img_shape: {
        paddingLeft: '13px'
    },
    paper_corporate: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    paper_xpand: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    }
}


const useStyles = makeStyles((theme: any) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));


export const SearchChild = ({ searchDetail, booleanValue, handleisToggled1Click, tostSuccessfully, XpandData, searchData, handleNavigate, isToggled, search, HandleChangeSeacrh }: any) => {

    const classes = useStyles();
    return (
        <>
            {isToggled ?
                <Box className={classes.search}>
                    <Box className={classes.searchIcon}>
                        <SearchIcon />
                    </Box>
                    <TextField className="Rounded" id="text-field-search-tst01" inputProps={{ maxlength: 50 }} value={searchData} onChange={handleisToggled1Click} style={{ width: '100%', borderRadius: '12px', marginTop: '-5px' }} placeholder=" Search" variant="outlined" />
                </Box> :
                <Box className={classes.search}>
                    <Box className={classes.searchIcon}>
                        <SearchIcon />
                    </Box>
                    <TextField className="Rounded" id="text-field-search-tst02" inputProps={{ maxlength: 50 }} value={searchData} onChange={tostSuccessfully} style={{ width: '100%', borderRadius: '12px', marginTop: '-5px' }} placeholder=" Search" variant="outlined" />
                </Box>
            }

            {searchData === "" ? <></> :
                XpandData?.data?.filter((item: any) => item?.attributes?.title.toLowerCase().includes(searchData.toLowerCase()))
                    .map((item: any, index: number) => {
                        return (
                            <Box key={index}>
                                <h1
                                    id="handle-change-search-text-click-tst"
                                    onClick={() => HandleChangeSeacrh(item)}
                                    style={{ fontSize: '20px', marginLeft: '40px', fontWeight: 500, cursor: 'pointer' }}>{booleanValue ? item?.attributes?.title : ""}</h1>
                            </Box>
                        )
                    })}
        </>
    )
}



export const XpandSlider1 = ({ AllSliderData, isToggled, style, XpandData, handleNavigate, props, postXpandLibraryData, postplayButton, addCartItem, handleSubscription }: any) => {
    const HandleChange = (data: any) => {
        if (data?.attributes?.expand_type === "Course") {
            handleNavigate("Course", data.id, `/course-detail/${data.attributes.expand_type}/${data.id}/${data.attributes?.title.replace(/\s/g, '-')}`, { name: data })
        } else if (data?.attributes?.expand_type === "Program") {
            handleNavigate("Program", data.id, `/program-detail/${data.attributes.expand_type}/${data.id}/${data.attributes?.title.replace(/\s/g, '-')}`, { name: data })
        }
    }

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };


    return (
        <>
            {isToggled ?
                <>
                    {AllSliderData}
                </>
                :
                <>
                    {XpandData?.data ?
                        <Slider {...settings}
                            className="slider1">

                            {XpandData.data.map((data: any, index: any) => {
                                let att = data?.attributes

                                return (
                                    <Box key={index}>
                                        <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                                            <CardMedia
                                                style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                                                image={att.thumbnail_image?.url}
                                            />
                                            <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                                                <Typography
                                                    id="handle-change-title-id-tst"
                                                    style={{ width: '100%', minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                                                    onClick={() => HandleChange(data)}>
                                                    {att.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p"
                                                    style={{ margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' }}>
                                                    {att.created_by}
                                                </Typography>

                                                <Typography variant="body2" color="textSecondary" component="p"
                                                    style={{
                                                        margin: 'auto', fontSize: '12px', marginBottom: '10px', padding: '0 17px',
                                                        color: '#44444F'
                                                    }}>
                                                    Due in {att.course_timeline} Days
                                                </Typography>

                                                {att.is_paid ?
                                                    <>
                                                        {att.is_enrolled ? <Card style={{
                                                            width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                                                            marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                                                        }}>
                                                            <CardActionArea id="action-paid-btn-tst" onClick={() => HandleChange(data)}>
                                                                <img src={play1} style={{ margin: 'auto', padding: '8px', display: 'flex' }} />
                                                            </CardActionArea>
                                                        </Card> :
                                                            <Card style={{
                                                                width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                                                                marginTop: '-2px', marginRight: '20px', background: '#1D1F4F'
                                                            }}>
                                                                {att.subscription_period === "one_time_purchase" ?
                                                                    <CardActionArea id="action-add-cart-btn-tst" onClick={() => addCartItem(data?.id, att.expand_type)}>
                                                                        <img src={shop} style={{ margin: 'auto', padding: '27px', display: 'flex', marginTop: '-21px', width: '100%', height: 'auto' }} />
                                                                    </CardActionArea> :
                                                                    <CardActionArea id="action-subscription-btn-tst" onClick={() => handleSubscription(data)}>
                                                                        <Typography style={{ display: 'flex', width: '100%', lineHeight: '45px', justifyContent: 'center', color: '#fff' }}>Subscribe</Typography>
                                                                    </CardActionArea>
                                                                }
                                                            </Card>}
                                                    </>
                                                    :
                                                    <>{att.is_enrolled ? <></> : <Card style={{
                                                        width: '87px', height: '45px', borderRadius: '30px', float: 'left',
                                                        marginTop: '-2px', marginLeft: '25px', background: '#EF2B4B'
                                                    }}>
                                                        <CardActionArea id="post-xpand-library-btn-tst" onClick={() => postXpandLibraryData(data?.id, att.expand_type)}>
                                                            <img src={group} style={{ margin: 'auto', padding: '15px', display: 'flex' }} />
                                                        </CardActionArea>
                                                    </Card>}

                                                        <Card style={{
                                                            width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                                                            marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                                                        }}>
                                                            <CardActionArea id="post-xpand-handle-change-btn-tst" onClick={() => HandleChange(data)}>
                                                                <img src={play1} style={{ margin: 'auto', padding: '8px', display: 'flex' }} />
                                                            </CardActionArea>
                                                        </Card>
                                                    </>}
                                            </Paper>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Slider>
                        :
                        <h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2>}
                </>}
        </>
    )
}
