import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: "20px",
      padding: 10,
      boxShadow:
        "-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)",
      borderRadius: "10px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 15px",
        borderRadius: "20px",
      },
    },
    label: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "25px",
      color: "#1A1A1A",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "21px",
        lineHeight: "36px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "24px",
      },
    },
    label2: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "24px",
      color: "#000000",
      marginBottom: 5,
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
        lineHeight: "32px",
      },
    },
    label3: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      color: "#000000",
    },
    textField: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D6D6D6",
      },
      "& .MuiOutlinedInput-root": {
        background: "#F9F9F9",
        borderRadius: "8px",
      },
    },
    transparentTextField: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D6D6D6",
      },
      "& .MuiOutlinedInput-root": {
        background: "transparent",
        borderRadius: "8px",
      },
    },
    textFieldDisabled: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(178 163 163 / 26%) !important",
      },
      "& .MuiOutlinedInput-root": {
        background: "#F9F9F9",
        borderRadius: "8px",
      },
      "& .MuiInputBase-input.Mui-disabled": {
        opacity: "0.7"
      }
    },
    radio: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    checkbox: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
  })
);
