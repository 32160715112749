import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,makeStyles,withStyles  } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import AddNoteModal from "./AddNoteModal.web"
import './style.css'
const edit = require("../assets/edit.png");
const exmaplePic = require("../assets/exmaplePic.png");
const pic1 = require("../assets/pic1.jpeg");
const searchIcon = require("../assets/searchIcon.png");

const notesList = [
  {
    id:1,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"public"
  },
  {
    id:2,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"private"
    
  },
  {
    id:3,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"public"
  },
  {
    id:4,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"private"
  },
  {
    id:5,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"public"
  },
  {
    id:6,
    title:"Sales Expert Course",
    date:"26/04/2022",
    caption:"Sample Caption",
    type:"private"
  },
]

const noteDataList = [
  {
    id:1,
    title:"Sales Expert Course 1",
    subtitle:"this is subtitle",
    pic:pic1,
    data1:"Sample Caption data 1.....1",
    data2:"Sample Caption data 1.....2",
    data3:"Sample Caption data 1.....3",
    type:"public"

  },
  {
    id:2,
    title:"Sales Expert Course 2",
    pic:exmaplePic,
    date:"26/04/2023",
    caption:"Sample Caption",
    data1:"Sample Caption data 2.....1",
    data2:"Sample Caption data 2.....2",
    data3:"Sample Caption data 2.....3",
    type:"private"
  },
  {
    id:3,
    title:"Sales Expert Course 3",
    pic:exmaplePic,
    date:"26/04/2022",
    caption:"Sample Caption for 3",
    data1:"Sample Caption data 3.....1",
    data2:"Sample Caption data 3.....2",
    data3:"Sample Caption data 3.....3",
    type:"public"
  },
  {
    id:4,
    title:"Sales Expert Course 4",
    pic:exmaplePic,
    date:"26/04/2022",
    caption:"Sample Caption for 4",
    data1:"Sample Caption data 4.....1",
    data2:"Sample Caption data 4.....2",
    data3:"Sample Caption data 4.....3",
    type:"private"
  },
  {
    id:5,
    title:"Sales Expert Course 5",
    pic:exmaplePic,
    date:"26/04/2022",
    caption:"Sample Caption",
    data1:"Sample Caption data 5.....1",
    data2:"Sample Caption data 5.....2",
    data3:"Sample Caption data 5.....3",
    type:"public"
    
  },
  {
    id:6,
    title:"Sales Expert Course 6",
    pic:exmaplePic,
    date:"26/04/2022",
    caption:"Sample Caption",
    data1:"Sample Caption data 6.....1",
    data2:"Sample Caption data 6.....2",
    data3:"Sample Caption data 6.....3",
    type:"private"
  },
]



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AllNoteListController, {
  Props,
} from "./AllNoteListController";

export default class AllNoteList extends AllNoteListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container>
          <Grid xs={12}>
            <Box style={{padding:"20px"}}>
            <Typography variant="h4" className="profileName" style={{fontWeight:"bold",marginBottom:"15px"}}>
                Notes
            </Typography>
              <Box style={{display:'flex',justifyContent:'space-between'}}>
                <TextField
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      style={{
                        width:"30%"
                      }}
                      id="input-with-icon-adornment"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>,
                      }}
                />
                {/* @ts-ignore */}
                <ColorButton variant="filled" size="small" onClick={this.handleAddNoteOpenModal}>+ Add New Note</ColorButton>
                
              </Box>        
            </Box> 
            <Grid container spacing={3} style={{marginTop:"25px",marginLeft:"5px"}}>
              <Grid xs={12} sm={12}>
                <Paper elevation={5} style={{borderRadius:"5px",marginRight:"15px"}}>
                  <Box style={{padding:"20px"}}>
                      <Box style={{display:'flex',justifyContent:'space-between'}}>                     
                        
                      </Box>
                      <NoteTabs/>
                  </Box>
                </Paper>
              </Grid>
              <Grid xs={12} sm={6}>
                
                  
              </Grid>
            </Grid>
          </Grid>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            // @ts-ignore
            open={this.state.isModalOpen}
            // @ts-ignore
            onClose={this.handleAddNoteCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
          >
            {/*@ts-ignore*/}
            <Fade in={this.state.isModalOpen}>
                {/* @ts-ignore */}
                <AddNoteModal />
            </Fade>
          </Modal>

      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const ColorButton = withStyles((theme) => ({
  root: {
      backgroundColor: purple[500],
      color:"white",
      borderRadius:"10px",
      borderColor: purple[500],
      textTransform:'capitalize',
      '&:hover': {
          borderColor: purple[700],
          color:"white",
          backgroundColor: purple[800],
      },
  },
}))(Button);

export function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const AntTabs: any = withStyles({
  root: {
    borderBottom: '0px solid #e8e8e8',
    marginTop:"10px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const TabsRecent: any = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginTop:"15px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Montserrat, sans-serif',
    ].join(','),
    '&:hover': {
      color: '#6c328b',
      opacity: 1,
    },
    '&$selected': {
      color: '#6c328b',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#6c328b',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export function NoteTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [notesId, setNotesId] = React.useState(1);
  const [tempData, setTempData] = React.useState([{}]);

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };
  const handleNoteListClick = (id: any) => {
    //@ts-ignore 
    // document.getElementById(`selected-card${id}`).style.backgroundColor = "red";
    // myselectid?.style.backgroundColor = "red"
    // console.log("my idd===>", myselectid)
    console.log("clickedd--->", id)
    setNotesId(id)
    
    let dummydata = noteDataList?.filter((item: any) => {
      return item.id === id
    })
    setTempData(dummydata)
    //@ts-ignore
  }
  
  React.useEffect(() => {
    let dummydata = noteDataList?.filter((item: any) => {
      return item.id === 1
    })
    setTempData(dummydata)
  },[])  
 

  return (
    <>
      <div>
        <AntTabs value={value} test-id="change1" onChange={handleChange} aria-label="simple tabs example">
            {/* @ts-ignore */}
            <AntTab label="All Notes" {...a11yProps(0)} />
            {/* @ts-ignore */}
            <AntTab label="Personal Notes" {...a11yProps(1)} />
            {/* @ts-ignore */}
            <AntTab label="Course Notes" {...a11yProps(2)} />
          </AntTabs>
      </div>
      <div className={classes.root} style={{display:"flex"}}>
        <Grid item xs={12} sm={4}>
            
            <TabPanel value={value} index={0}>
                <Grid container spacing={3} style={{marginTop:"10px"}}>
                {
                    notesList.map((item,key) => {
                    console.log(item)
                    return(
                        <Grid item xs={12} key={key} 
                        id={`card${item.id}`}
                        className={`box ${
                          notesId === item.id && item.type === "public"
                            ? "public-active-border"
                            : notesId === item.id && item.type === "private"
                            ? "private-active-border"
                            : ""
                        }`}>
                        <Paper elevation={1} style={{height:"100px",display:'flex'}}>
                            {
                                key % 2 === 0 ? 
                                <Box style={{backgroundColor:"#ff8b3f",height:"100%",width:"12px",borderRadius:"10px 0px 0px 10px"}} /> : 
                                <Box style={{backgroundColor:"#6c328b",height:"100%",width:"12px",borderRadius:"10px 0px 0px 10px"}} />
                            }
                            <Box style={{width:'100%',padding:'15px',display:'flex',alignItems:'center',justifyContent:'space-between'}}
                            //@ts-ignore
                            id={`card${item.id}`}
                            className={`box ${
                            notesId === item.id && item.type === "public"
                            ? "public-active-box"
                            : notesId === item.id && item.type === "private"
                            ? "private-active-box"
                            : ""
                             }`}
                            onClick={() => handleNoteListClick(item.id)}>
                              <Box display="flex" style={{alignItems:'center'}}>
                                  {
                                      // @ts-ignore
                                      item.pic &&
                                      // @ts-ignore
                                      <img src={item.pic} height="80%" width="80px" style={{marginRight:"10px"}} />
                                  }
                                  <Box>
                                  <Typography variant="h6" className="profileName">
                                      {item.title}
                                  </Typography>
                                  {
                                      // @ts-ignore
                                      !item.pic && 
                                      <Box style={{display:'flex',marginTop:"5px",justifyContent:"space-between"}}>
                                      <Typography variant="subtitle1" color="textSecondary">
                                          {moment(item.date).format("DD MMMM YYYY")}
                                      </Typography>
                                      <Typography variant="subtitle1" color="textSecondary">
                                          {item.caption}
                                      </Typography>
                                      </Box>
                                  }
                                  </Box>
                              </Box>
                            <Box>
                                <IconButton>
                                    <ArrowForwardIosIcon/>
                                </IconButton>
                            </Box>
                            </Box>
                        </Paper>
                        </Grid>
                    )
                    })
                }
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Grid>
        
        <Grid item xs={12} sm={8}>

            <Paper elevation={1} style={{borderRadius:"10px",display:'flex'}}>
                <Box style={{padding:"25px", width:"100%"}}>

                      <Box style={{display:"flex", flexDirection:"row", padding:"5px", justifyContent:"space-between"}}>
                         <Box style={{display:"flex"}}>
                          <Typography variant="h4" style={{fontWeight:"bold"}} color="textSecondary">
                            {//@ts-ignore
                              tempData?.[0]?.title}
                                                                                    
                          </Typography>

                          
                          <Typography variant="h6" style={{padding:"10px"}}color="textSecondary">
                                  + Add this note to assignment                       
                          </Typography>
                         </Box> 
                          <Box className="seicon">
                          <SmsOutlinedIcon style={{width:"35px", height:"35px", marginRight:"40px"}}/>
                          <img src={edit} style={{width:"30px", height:"30px"}}/>
                          </Box>
                          
                      </Box>

                      <Box style={{ padding:"5px", width:"100%"}}>
                              <img style={{width:"100%", height:"auto"}} src={
                            //@ts-ignore
                          tempData?.[0]?.pic} alt="" />
                      </Box>
                      <Box style={{padding:"5px"}}>
                          <Typography>{
                          //@ts-ignore
                          tempData?.[0]?.data1}</Typography>
                      </Box>
                      <Box style={{padding:"5px"}}>
                          <Typography>{
                          //@ts-ignore
                          tempData?.[0]?.data2}</Typography>
                      </Box>
                      <Box style={{padding:"5px"}}>
                          <Typography>{
                          //@ts-ignore
                          tempData?.[0]?.data3}</Typography>
                      </Box>


                </Box>   
            </Paper>

        </Grid>
    </div>
    </>
    
  );
}
// Customizable Area End
