// Customizable Area Start
import React from "react";
import UserAssignContentController, {
    Library,
    Props,
} from "./UserAssignContentController.web";
import Badge from "@material-ui/core/Badge";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    styled,
    Popover,
} from "@material-ui/core";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { Filter } from "../../catalogue/src/assets";
import { addContentImg, imageHtmlCourse2, NotFoundImage, rightArrow } from "./assets";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MaterialUIBox from '@material-ui/core/Box';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import FilterItems from "../../filteritems/src/FilterItems.web";

class UserAssignContent extends UserAssignContentController {
    wrapperRefDueDate: any;
    wrapperRefLaunchDate: any;
    constructor(props: Props) {
        super(props);
    }

    generateToggleBoxStyle(isActive: boolean) {
        return {
            height: "48px",
            backgroundColor: isActive ? "#FFF" : "#FCF8F8",
            boxShadow: isActive ? "1px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
            color: isActive ? "#1C1B1B" : "",
            alignItems: "center",
            display: "flex",
            minWidth: "170px",
            width: "170px",
            fontSize: "18px",
            fontWeight: isActive ? 600 : 400,
            borderRadius: "10px",
            justifyContent: "center",
            cursor: "pointer",
            maxWidth: "200px",
            padding: "14px 24px"
        };
    }

    renderGroupAssigneeTextFieldBox = () => {
        return (
            <>
                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle">Group:</Typography>
                    <StyledDropdownClickBoxs
                        data-test-id="select_ID"
                        style={{ color: '#1C1B1B', background: '#FFFFFF' }}
                    >
                        <MaterialUIBox className="contentContainer contentRole">
                            <MaterialUIBox className="innerContentBox"
                                style={{
                                    color: '#1C1B1B',
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "16px"
                                }}>
                                Users
                            </MaterialUIBox>
                        </MaterialUIBox>
                    </StyledDropdownClickBoxs>
                </FormControl>

                <FormControl variant="standard" className="customInput" style={{ width: '392px' }}>
                    <Typography className="labelStyle" >Assignee:</Typography>
                    <StyledDropdownClickBoxs
                        onClick={this.assigneedropdownOpen}
                        data-test-id="select_ID"
                        style={{ color: '#1C1B1B', background: '#FFFFFF' }}
                    >
                        <MaterialUIBox className="contentContainer contentRole">
                            <MaterialUIBox className="innerContentBox"
                                style={{
                                    color: '#1C1B1B',
                                    fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                                }}>
                                {`${this.state.firstName} ${this.state.lastName}` || 'Select group first'}
                            </MaterialUIBox>
                        </MaterialUIBox>
                        {this.state.assigneeDropdown ? <ExpandLess /> : <ExpandMore />}
                    </StyledDropdownClickBoxs>
                    {this.state.assigneeDropdown && (
                        <div ref={this.dropdownRef}>
                        <DropdownContent>
                            <MaterialUIBox className="checkboxList">
                                <RadioGroup
                                    data-test-id="radioGroup2"
                                    aria-labelledby="group-selection"
                                    name="group"
                                    value={this.state.selectedAssignee}
                                    onChange={this.handleAssigneeChange}
                                >
                                    <FormControlLabel className={"checkboxContent"} value={`${this.state.firstName} ${this.state.lastName}`} control={<Radio style={{ color: "#652786" }} />} label={`${this.state.firstName} ${this.state.lastName}`} />
                                </RadioGroup>
                            </MaterialUIBox>
                        </DropdownContent>
                        </div>
                    )}
                </FormControl>
            </>
        );
    }

    renderCoursesProgramSearchBox() {
        const { isCourseActive,anchorFilterContainerEl } = this.state;
        const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course");
        const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program");
        const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)
        return (
            <Grid item container style={{ margin: "20px", gap: "clamp(5px, 45px, 3%)" }} >
                <TabGrid >
                    <ToggleBox >
                        <ToggleInnerbox data-test-id="courseToggleId"
                            style={courseToggleStyle} onClick={this.handleToggleCourses} >
                            Course
                        </ToggleInnerbox>
                        <ToggleInnerbox data-test-id="programToggleId"
                            style={programToggleStyle} onClick={this.handleTogglePrograms}>
                            Program
                        </ToggleInnerbox>
                    </ToggleBox>
                </TabGrid>

                <InputMainBox style={{
                    display: 'flex', alignItems: 'center',
                    borderRight: "none", justifyContent: "center", width: "clamp(300px, 518px, 45%)"
                }}>
                    <StyledContentWrapper>
                        <AdvancedSearch
                            data-test-id="btn_chanLibraryBoxge"
                            searchInputValue={this.state.searchInputValue}
                            handleListItemClick={this.handleListItemClickSuggestion}
                            filterList={this.state.filteredList}
                            handleInputChange={this.handleInputCatalogueChangeSearch}
                            isFilterDropActive={openFilterContainer}
                            inputWidth="100%"
                            showList={this.state.showList} navigation={undefined} id={""}
                        />
                       <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={Filter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <FilterItems
                userAssignId={this.state.userId}
                isContentRole={this.state.contentRoleTrue}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleClick={this.handleClickFilter}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandlerChange}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>
           
                    </StyledContentWrapper>
                </InputMainBox>
            </Grid>
        )
    }

    renderTableData = (libraryStaticData: Library[], handleChange: (selectedData: Library) => void,
        tableName: string, checked: Library[] ) => {
        return (
            <Box style={{ overflowY: "scroll", height: "588px", width: "100%"}} >
                {libraryStaticData.map((data, index) => {
                    return (
                    <Box style={{...webStyle.renderTableStyle,
                        backgroundColor: this.isCheckedValue(checked, data) ? "rgba(238, 232, 242, 1)" : "#fff",cursor:"pointer"
                    }} onClick={() => handleChange(data)}>
                        <div style={webStyle.renderTableCheckbox}>
                            <Checkbox
                                className="checkBox"
                                style={{ color: this.isCheckedValue(checked, data) ? "#652786" : "grey",textTransform: "capitalize" }}
                                data-test-id={`selectedCheckBox${index}`}
                                checked={this.isCheckedValue(checked, data)}
                                onChange={(event) => {
                                    event.stopPropagation(); 
                                    handleChange(data);
                                }}
                            />
                            <div style={{ display: "flex", alignItems: "center",gap: "14px",}}>
                                <div>
                                <img style={thumb_img} src={data?.attributes?.thumbnail_image?.url ? data?.attributes.thumbnail_image.url : imageHtmlCourse2} />
                                </div>
                                    <TitleData>{data?.attributes?.title}</TitleData>
                            </div >
                        </div >
                        <Statuses style={webStyle.statusNew}>{data?.attributes?.status}</Statuses>
                    </Box>
                     );
                })}
            </Box>
        )
    }

    renderProgramCLickedData = () => {
        return (
            <>
                {this.state.removedAllData.length > 0 ?
                    (this.renderTableData(this.state.removedAllData,this.handleRemoveCheckboxChangeProgram, "Assigned",
                        this.state.removedProgramData)
                    ) : (<Box style={{
                        display: "flex" ,
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "10px",
                        alignItems: "center",
                    }}>
                        <Addedimg src={addContentImg} data-test-id="filterOpenTestID"/>
                        <Typography style={ webStyle.staticDataStyle }
                        >Select the content you want to add</Typography>
                    </Box>)}
                <Box style={webStyle.removeButtonBoxstyle}>
                    <Button style={this.state.removedProgramData.length? webStyle.removeButtonStyleAfter : webStyle.removeButtonStyleBefore}
                        data-test-id="removeButtonId" 
                        disabled={this.state.removedProgramData.length?false:true}
                        onClick={this.handleRemoveButtonClickProgram} >
                        <KeyboardBackspaceIcon/>
                        &nbsp;&nbsp;&nbsp;
                        <p style={{textTransform: "capitalize"}}>Remove</p>
                    </Button>
                </Box>
            </>
        )
    }

    libraryDataCheck = (data: any, value: any) => {
        if ( data.length > 0 ) {
            return this.renderTableData(value, this.handleCheckboxChange,
                "Library",this.state.selectedContent)
        }
        else {
            return <Box style={{
                display: "flex" ,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "695px",
                gap: "10px",
            }}>
                <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID"/>
                <Typography style={webStyle.staticDataStyle}
                >No results founds</Typography>
            </Box>
        }
    }

    renderDataBox() {
        const libraryData=this.state.libraryData.filter((library) => !this.state.removedAllData.includes(library))
        const programData=this.state.programData.filter((library) => !this.state.removedAllData.includes(library))

        return (

            <LibraryBox >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6} lg={6}  >
                        <Box style={{
                            display: "flex", flexDirection: "column", gap: "24px",
                            boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                            height: "695px", padding: "20px 20px 0px 20px", borderRadius: "16px"
                        }}>
                            <Box>
                                <Typography style={headStyle}>Library</Typography>
                            </Box>
                            {this.state.isCourseActive === "course" ? this.libraryDataCheck(this.state.libraryData, libraryData)
                                :
                                this.libraryDataCheck(this.state.programData, programData)
                            }
          <Box style={webStyle.addButtonBoxstyle}>
                                <Button
                                    disabled={!this.state.selectedContent.length}
                                    className={!this.state.selectedContent.length ? "add_btn" : "add_btn enable_btn"}
                                    onClick={this.handleAddButtonClick}
                                    data-test-id="testing">
                                    <Typography
                                        style={typo2}>Add</Typography>
                                    &nbsp;&nbsp;&nbsp;
                                    <img src={rightArrow}
                                        alt="arrow"
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            boxShadow: " 0px 2px 8px 0px rgba(0, 0, 0, 0.07)",
                            flexDirection: "column",
                            height: "695px", padding: "20px 20px 0px 20px", gap: "16px",
                            top: "616px", left: "134px", borderRadius: "16px"
                        }} >
                            <Box style={{width: "100%"}}>
                                <Typography style={headStyle}>Assigned</Typography>
                            </Box>
                            {this.renderProgramCLickedData()}
                        </Box>
                    </Grid>
                </Grid>
            </LibraryBox>
        )
    }

    render() {
        const isDisable=this.handleSaveChangesDisable();
        return (
            <>
                <Box sx={gridStyle} style={{ display: "flex", flexDirection: "row" }}>
                    {this.renderGroupAssigneeTextFieldBox()}
                </Box>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderCoursesProgramSearchBox()}
                </Grid>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderDataBox()}
                </Grid>
                < MainButton >
                    <SaveAndCreate
                        data-test-id="saveChangesBtn"
                        onClick={() => {
                            this.postUserAssign(this.state.removedAllData);
                        }}
                        style={isDisable ? webStyle.saveChangesEnableBtn : webStyle.saveChangesDisableBtn}
                        disabled={isDisable?false:true}
                    >
                    <Typography style={typo}>Save Changes</Typography>
                    </SaveAndCreate>
                </MainButton>
            </>
        )
    }
}

const thumb_img = {
    width: "40px",
    borderRadius: "4px",
    height: "40px",
    boxShadow: "0px 2px 14px 0px #8C8C8CB6",
    border: "1px solid #929090",
}

const headStyle = {
    fontSize: "22px",
    fontFamily: "Poppins",
    lineHeight: "33px",
    fontWeight: 600,
    color: "#1C1B1B"
};

const typo = {
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    lineHeight: "16px",
    fontWeight: 600,
    textTransform: "none" as 'none'
}

const typo2 = {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "16px",
    textTransform: "capitalize" as 'capitalize',
    color: "#FFFFFF"
}

const gridStyle = {
    flexDirection: "column",
    display: "flex",
    gap: "24px",
    boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    flexWrap: "wrap",
    padding: "24px",
    width: "100%",
    borderRadius: "18px",

};
const webStyle = {
    renderTableStyle:{
        display: "flex", 
        alignItems: "center",
        justifyContent: "space-between", 
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", 
        borderRadius: "12px",
        padding: "16px 24px", 
        height: "72px",
        maxWidth: "588px", 
        marginBottom: "10px"
    },
    renderTableCheckbox: {
        alignItems: "center",
        display: "flex", 
    },
    addButtonBoxstyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "16px 16px 20px",
        gap: "24px",
        alignItems: "end",
    },
    removeButtonBoxstyle: {
        display: "flex",
        width: "100%",
        height: "74px",
        padding: "16px 16px 20px",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "24px",
    },
    addButtonStyle: {
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0,
        backgroundColor: "#652786",
        borderRadius: "12px",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
    },
    removeButtonStyleBefore: {
        display: "flex",
        height: "42px",
        width: "165px",
        padding: "12px 24px 12px 16px",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        borderRadius: "12px",
        flexShrink: 0,
        border: "1px solid #E5E2E1",
        color: "#929090",
        fontSize: "16px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        lineHeight: "16px",
        fontWeight: 600,
        backgroundColor: "#FFFFFF"
    },
    removeButtonStyleAfter: {
        display: "flex",
        height: "42px",
        width: "165px",
        padding: "12px 24px 12px 16px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        gap: "10px",
        borderRadius: "12px",
        color: "#E47004",
        border: "1px solid #E47004",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
    },
    staticDataStyle: {
        fontSize: "20px",
        fontFamily: "Poppins",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "24px",
        color: "#1C1B1B",
    },
    saveChangesDisableBtn: {
        padding: "20px 32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #E5E2E1",
        gap: "10px",
        borderRadius: "16px",
        fontFamily: "Poppins",
        color: "#929090",
        fontSize: "16px",
        width: "411px",
        fontWeight: 600,
        height: "56px"
    },
    saveChangesEnableBtn: {
        width: "411px",
        height: "56px",
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #E47004",
        borderRadius: "16px",
        color: "#E47004",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,

    },
    roleButtonStyle1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#E5E2E1",
        borderRadius: "16px",
        color: "#929090"
    },
    roleButtonStyle2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#652786",
        borderRadius: "16px",
        color: "#fff"
    },
    statusNew: {
        display: 'flex',
        width: "125px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "88px",
        background: "#E0D4E7",
        color: "#542070",
        textTransform: "capitalize" as 'capitalize',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },
    statusArchived: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#FFD9DF",
        borderRadius: "88px",
        color: "#E70E6B",
        textTransform: "capitalize" as 'capitalize'
    },
    statusDraft: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
        background: "#FFEDE4",
        borderRadius: "88px",
        color: "#E47004",
        textTransform: "capitalize" as 'capitalize'
    },
}
const MainButton = styled(Box)({
    marginTop: "20px",
    padding: "20px 32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
});

const SaveAndCreate = styled(Button)({
    height: "56px",
    width: "411px",
    padding: "20px 32px",
    "@media (max-width:960px)": {
        height: "56px",
        width: "240px",
        padding: "10px 21px",
    },
})

const TitleData = styled("div")({
    width: "300px",
    fontSize: "16px",
    color: "#484646",
    fontFamily: "Poppins",
    lineHeight: "22px",
    fontWeight: 400,
    textTransform: "capitalize" as 'capitalize',
    "@media (max-width:960px)": {
        wordBreak: "break-all",
        width: "56px",
    },
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
    textOverflow: "ellipsis",
});

const Statuses = styled("div")({
    backgroundColor: "#E0D4E7",
    padding: "6px 9px",
    textAlign: "center",
    color: "#542070",
    borderRadius: "88px",
    fontSize: "14px",
    gap: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    width: "125px",
    height: "40px",
    "@media (max-width:960px)": {
        padding: "6px 9px",
        fontSize: "10px",
    },
});

const TabGrid = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    background: "#FCF8F8"
})

const LibraryBox = styled(Box)({
    width: "100%",
    gap: "10px",
    "& .add_btn": {
        display: "flex",
        width: "165px",
        height: "42px",
        justifyContent: "center",
        padding: "12px 24px 12px 16px",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#E5E2E1",
        flexShrink: 0,
        color: "#929090",
        borderRadius: "12px",
        cursor: "pointer"
    },
    "& .enable_btn": {
        color: "#FFFFFF !important",
        backgroundColor: "#652786 !important"
    }
})

const ToggleBox = styled(Box)({
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    backgroundColor: "#FCF8F8",
    cursor: "pointer",
    borderRadius: "12px ",
    flexWrap: "wrap",
    gap: "2px"
});

const FiterImage = styled( "img" )({
});

const StyledContentWrapper = styled(Box)({
    display: "flex",
    position: "relative",
    width: "100%",
    "& .filterIconBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "63px",
        backgroundColor: "#FAFAFA",
        borderRadius: "8px",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
        cursor: "pointer",
        position: "relative",
        border: "1px solid #C9C6C5",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        backgroundColor: "#EEE8F2",
        borderColor: '#652786',
    }
});

const ToggleInnerbox = styled(Box)({
    color: "#1c1818",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const BadgeComponent = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-14px",
        left: "20px",
        right: " 0px",
        backgroundColor: " #FF883F",
        fontSize: "81px",
        width: "10px",
        height: "10px",
        borderRadius: "20px"
    }
});

const Addedimg = styled( "img" )({
    "@media (max-width:960px)": {
        width: "250px",
    },
})

const NoDataImg = styled("img")({
    width: "80%",
    height: "80%",
    "@media (max-width:960px)": {
        width: "250px",
    },
})

const InputMainBox = styled(Grid)({
    '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px'
    }
});

const StyledDropdownClickBoxs = styled('div')({
    height: "52px",
    width: "392px",
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    gap: "12px",
    border: "1px solid #E5E2E1",
    padding: "16px 20px !important",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    position: "relative",
    cursor: "pointer",
    "@media (max-width:1000px)": {
        height: "52px",
        width: "100%",
    },
    "&.selectCommonStyle .contentContainer": {
        display: "flex",
        width: "100%",
        gap: "3px",
    },
    "&.selectActive": {
        backgroundColor: "#EEE8F2"
    },
    "&.selectActive .title": {
        color: "#652786",
    },
    "&.creationDate": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .contentRole ": {
        width: "100% !important",
        display: "flex !important",
        justifyContent: "space-between !important"
    },
    "& .innerContentBox": {
        display: "flex",
        fontSize: "16px",
        gap: "3px",
        fontWeight: 500,
        color: '#ADAAAA',
    },
    "& .title": {
        fontSize: "16px",
        fontWeight: 400,
        whiteSpace: "nowrap",
        color: "#484646",
        fontFamily: "Poppins, sans-serif",
    },
    "& .arrowIconStyle": {
        color: "#652786",
    },
    "&:focus": {
        outline: "none",
        border: "1px solid #652786",
    },
    "&:hover": {
        border: "1px solid #652786",
    }
});

const DropdownContent = styled(MaterialUIBox)({
    position: "absolute",
    zIndex: 9999,
    top: "100px",
    background: "#fff",
    width: "100%",
    padding: "8px 16px",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: "12px",
    "& .checkboxList": {
        display: "flex",
        flexDirection: "column",
        marginTop: "5px",
        gap: "8px",
        marginBottom: "5px"
    },
    "& .checkboxContent": {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid transparent",
        fontFamily: "Poppins , sans-serif",
        textTransform: "capitalize"
    }, "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1C1B1B"
    },
    "& .checkboxContent:hover": {
        borderBottom: "1px solid transparent",
        borderRadius: "8px",
        backgroundColor: "#EEE8F2",
    },
    "& .selectedStyle": {
        borderBottom: "1px solid transparent !important",
        borderRadius: "8px",
        backgroundColor: "#EEE8F2",
    },
    "& .selectedStyle .MuiTypography-body1": {
        color: "#542070 !important",
    },
    "& .applyBtn": {
        display: "block",
        margin: "auto",
        color: "#542070",
        textTransform: "capitalize",
        fontSize: "16px",
    },
    "& .MuiFormControlLabel-root": {
        margin: 0,
        borderBottom: "1px solid transparent",
        fontFamily: "Poppins, sans-serif",
    },
});


const BadgeComponents = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
    }
  });

export default UserAssignContent;
// Customizable Area End