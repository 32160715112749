import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GoalsIllustrator,
  AssignmentIllustrator,
  CalendarIllustrator,
  AssignmentIcon,
  CalenderIcon,
  ChannelsIcon,
  CoursesIcon,
  GoalsIcon,
  HomeIcon,
  Logo,
} from "./assets";
import Topbar from "../Topbar";
import RouteProtection from "../RouteProtection";
import ComingSoonDialog from "./ComingSoon";
const { baseURL } = require("../../../framework/src/config");

interface Props extends RouteComponentProps {
  render: any;
  roles: string[];
  navigation: any;
  routeNotFound: boolean;
}

interface NavItem {
  name: string;
  icon: any;
  route: string | string[];
  clickHandler: any;
}

const DRAWER_WIDTH = 96;
const CART_DATA_URL = `${baseURL}/bx_block_shopping_cart/cart`;

function Sidebar(props: Props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openComingSoon, setOpenComingSoon] = useState(false);
  const [comingSoonType, setComingSoonType] = useState("");
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const token = localStorage.getItem("token");
  const fullScreen = useMediaQuery("(max-width:600px)");
  const { history, roles, render } = props;
  const {
    location: { pathname },
  } = history;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleComingSoonDialogClose = () => {
    setOpenComingSoon(false);
  };

  const handleCartItemsCountInTopbar = (count: number) => {
    setCartItemsCount(count);
  }

  const getCartItems = async () => {
    try {
      const headers = { token };
      // @ts-ignore
      const response = await fetch(CART_DATA_URL, { headers })
      if (response.ok) {
        const cartData = await response.json();
        if (cartData?.data) {
          const oneTimePaymentCartItems = cartData.data.attributes.cart_item;
          const subscriptionCartItems = cartData.data.attributes.subscription_cart_item;
          setCartItemsCount(oneTimePaymentCartItems.length + subscriptionCartItems.length);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Returns whether the current route path is active or not
  const isActive = (route: string | string[], navItem: string) => {
    if (
      openComingSoon &&
      navItem === "Assignments" &&
      comingSoonType === "assignments"
    ) {
      return true;
    } else if (
      openComingSoon &&
      navItem === "Goals" &&
      comingSoonType === "goals"
    ) {
      return true;
    } else if (
      openComingSoon &&
      navItem === "Calendar" &&
      comingSoonType === "calendar"
    ) {
      return true;
    } else {
      return !openComingSoon && (Array.isArray(route) ? route.some((routeValue)=>pathname.startsWith(routeValue)) : pathname.startsWith(route));
    }
  };

  const navItems = [
    {
      name: "Home",
      icon: HomeIcon,
      route: ["/Dashboard", "/CustomisableUserProfiles"],
      clickHandler: () => history.push("/Dashboard"),
    },
    {
      name: "Courses",
      icon: CoursesIcon,
      route: ["/library","/course-detail","/program-detail","/lesson-detail"],
      clickHandler: () => history.push("/library"),
    },
    {
      name: "Assignments",
      icon: AssignmentIcon,
      route: "/assignments",
      clickHandler: () => {
        setOpenComingSoon(true);
        setComingSoonType("assignments");
      },
    },
    {
      name: "Goals",
      icon: GoalsIcon,
      route: "/goals",
      clickHandler: () => {
        setOpenComingSoon(true);
        setComingSoonType("goals");
      },
    },
    {
      name: "Calendar",
      icon: CalenderIcon,
      route: "/calendar",
      clickHandler: () => {
        setOpenComingSoon(true);
        setComingSoonType("calendar");
      },
    },
    {
      name: "Channels",
      icon: ChannelsIcon,
      route: "/channels",
      clickHandler: () => history.push("/Dashboard"),
    },
  ];

  const drawer = (
    <>
      <div className={classes.logoContainer}>
        <img src={Logo} alt="Logo" />
      </div>
      <List>
        {navItems.map((nav: NavItem, index: number) => (
          <ListItem
            button
            onClick={nav.clickHandler}
            className={classes.listItem}
            style={{
              background: clsx(
                isActive(nav.route, nav.name) && "rgba(0,0,0,0.41)"
              ),
              borderLeft: isActive(nav.route, nav.name)
                ? "5px solid #fff"
                : "5px solid rgba(0, 0, 0, 0.04)",
            }}
            key={index}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img src={nav.icon} alt={nav.name} className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={`${classes.listItemText} ${
                isActive(nav.route, nav.name) ? classes.active : ""
              }`}
              primary={nav.name}
            />
          </ListItem>
        ))}
      </List>
    </>
  );

  if (
    pathname === "/Onboardingguide" ||
    pathname === "/Categoriessubcategories" ||
    pathname === "/forgotpassword" ||
    pathname === "/terms"
  ) {
    // Skip the sidebar for these routes
    return render(props);
  }

  let comingSoon;
  if (comingSoonType === "goals") {
    comingSoon = (
      <ComingSoonDialog
        open={openComingSoon}
        onClose={handleComingSoonDialogClose}
        image={GoalsIllustrator}
        title="Set your goals"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
      />
    );
  } else if (comingSoonType === "calendar") {
    comingSoon = (
      <ComingSoonDialog
        open={openComingSoon}
        onClose={handleComingSoonDialogClose}
        image={CalendarIllustrator}
        title="Track your schedule"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
      />
    );
  } else if (comingSoonType === "assignments") {
    comingSoon = (
      <ComingSoonDialog
        open={openComingSoon}
        onClose={handleComingSoonDialogClose}
        image={AssignmentIllustrator}
        title="Talk to your expert"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
      />
    );
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getCartItems();
    }
  }, []);

  return (
    <>
      <RouteProtection />
      <div className={classes.root}>
        {token ? (
          <>
            <Topbar
              roles={roles}
              drawerWidth={DRAWER_WIDTH}
              navigation={props.navigation}
              cartItemsCount={cartItemsCount}
              handleDrawerToggle={handleDrawerToggle}
              routeNotFound={props.routeNotFound}
              locationSet={location}
            />
            <nav className={classes.drawer} aria-label="navigation">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor="left"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {render({ ...props, fullScreen: fullScreen, handleCartItemsCountInTopbar: handleCartItemsCountInTopbar })}
            </main>
          </>
        ) : (
          render({ ...props, fullScreen: fullScreen })
        )}
      </div>
      {comingSoon}
    </>
  );
}

export default Sidebar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: "flex",
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      background:
        "linear-gradient(360deg, #6C328B 0%, #F42074 72.63%, #EF2B4B 100%)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: DRAWER_WIDTH,
    },
    toolbar: theme.mixins.toolbar,
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "30px 0",
    },
    content: {
      // flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
    },
    listItem: {
      flexDirection: "column",
      borderRadius: "2px",
    },
    listItemIcon: {
      justifyContent: "center",
    },
    listItemText: {
      color: "#fff",
      "& .MuiTypography-body1": {
        fontSize: "12px",
      },
    },
    active: {
      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },
    },
    icon: {
      height: "20px",
      width: "20px",
    },
  })
);