import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
import { ApiResponse } from "../../../blocks/customform/src/ultils";
import {  FileWithPath } from 'react-dropzone';

interface NoteAddResponse {
  data:{
    id:string;
    type:string;
    attributes:{
      chapter_title:string;
      image:null | {
        id:number;
        file_name:string;
        url:string;
    }
    }
  }
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}

interface DeleteNoteResponse1 {
  message:string;
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}

interface NoteLessonType1 {
  id:string;
  type:string;
  attributes:{
    title:string;
    description:string;
    is_private:boolean;
    created_at:string;
    link:string | null;
    note_type:string;
    course_timestamp:string;
    is_read:boolean;
    image:{
        id:number;
        file_name:string;
        url:string;
    }
    is_editable:boolean;
    course:{
      course_id:number;
      chapter_id:number;
      lesson_name:string
    }
    created_date:string;
  }
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  otherProps?: any
  location?: any
  // Customizable Area Start
  history?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeTabIndex:number;
  isOpen:boolean;
  lessonData:any;
  activeLesson:null|number;
  muted:boolean;
  openImageDialog:boolean;
  progressPercentage:number;
  lessonId:string|null;
  playing:boolean;
  played:number;
  duration:number;
  isFullScreen: boolean;
  showPlayButton:boolean;
  playbackRate:number;
  pagNumber: number;
  numPages: number;
  pdfBlobUrl: any;
  speedData:number[];
  isLoading:boolean;
  pageRender:number
  lockedData:number[];
  activePhase: string;
  activePhaseName:string;
  isLastLessonPage:boolean;
  islastVideo:boolean;
  isAudioPlaying:boolean;
  audioCurrentTime:number;
  audioLessonDuration:number;
  pdfProgressLoaading:number;
  pdfLoaded:boolean;
  displayReviewPage: boolean;
  isReviewSubmitted: boolean;
  lessonNotedata:NoteLessonType1[];
  openNotePreview:boolean;
  notePreviewId:string;
  addNoteModal:boolean;
  editNoteModal:boolean;
  isAttachmentChange:boolean,
  attachedFile:FileWithPath | { id: number; url: string; file_name: string; } |null,
  FileType:string,
  uploadFileProgress:number;
  uploadFileStatus:string;
  titleValue:string;
  titleError:boolean;
  titleErrorMessage:string;
  descriptionValue:string;
  descriptionError:boolean;
  descriptionErrorMessage:string;
  validationError:boolean;
  programData:any;
  isReviewCourse:boolean;
  isLessonProgramPage:boolean;
  deleteImageModal:boolean;
  editNoteId:string;
  deleteModalOpen:boolean;
  currentActiveProgramPhase:any;
  showStartNewScreen:boolean;
  allLessonsCompleted:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LessonPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetLibraryData: any;
  PostLibraryData: any;
  GetLibraryChartData: any;
  GetAllcategoriesData: any;
  Last_VideoTrackData: any;
  myRef: any;
  popupRef: any;
  param_id: any;
  AddCartItemID: any;
  lessonDataCallId:any;
  lessonCompleteCallId:any;
  playerRef: any;
  audioPlayerRef:any;
  videoContainerRef:any;
  reviewCourseProgramApiId: string = ""
  noteDataApiId: string = ""
  addNoteApiId: string = ""
  GetProgramLibraryData:string =""
  updateNoteApiId:string = ""
  deleteNoteApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myRef = React.createRef();
    this.popupRef = React.createRef();
    this.playerRef = React.createRef();
    this.audioPlayerRef = React.createRef();
    this.videoContainerRef = React.createRef<HTMLDivElement>();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeTabIndex:0,
      lessonData:{},
      isOpen:true,
      activeLesson:null,
      openImageDialog:false,
      progressPercentage:0,
      lockedData:[],
      lessonId:"",
      playbackRate:1,
      playing:false,
      played:0,
      duration:0,
      muted:false,
      isFullScreen: false,
      showPlayButton:true,
      pagNumber:1,
      numPages:0,
      pageRender:0.5,
      pdfBlobUrl:null,
      activePhase:"",
      activePhaseName:"",
  islastVideo:false,
      speedData: [
        1,
        1.25,
        1.5,
        1.75,
        2
      ],
      isLoading:true,
      isLastLessonPage:false,
  isAudioPlaying:false,
  audioCurrentTime:0,
  audioLessonDuration:0,
  pdfProgressLoaading:0,
  pdfLoaded:false,
  displayReviewPage: false,
  isReviewSubmitted:false,
    lessonNotedata:[],
    openNotePreview:false,
    notePreviewId:'',
    addNoteModal:false,
    editNoteModal:false,
    isAttachmentChange:false,
    attachedFile:null,
    FileType:"images",
    uploadFileProgress:0,
    uploadFileStatus: "notStarted",
    titleValue:'',
    titleError:false,
    titleErrorMessage:'',
    descriptionValue:'',
    descriptionError:false,
    descriptionErrorMessage:'',
    validationError:false,
  programData:null,
  isReviewCourse:false,
  isLessonProgramPage:false,
  deleteImageModal:false,
  editNoteId:'',
  deleteModalOpen:false,
  currentActiveProgramPhase:null,
  showStartNewScreen:false,
  allLessonsCompleted:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }



  async componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('fullscreenchange', this.handleFullScreenChange);
    const lessonId = await getStorageData("lessonId",true)
    if(lessonId){
    this.setState({ lessonId:lessonId,activeLesson:lessonId });
      this.getLessonData(lessonId)
      this.getNoteData(lessonId)
    }
  }

  togglePlay = () => {
    this.setState(prevState => ({ isAudioPlaying: !prevState.isAudioPlaying }));
  };

  handleSliderChange = (event:any, newValue:any) => {
    this.setState({ audioCurrentTime: newValue });
    this.audioPlayerRef.current?.seekTo(newValue);
  };

  handleProgressAudio = (progress:any) => {
    this.setState({
      audioCurrentTime: progress.playedSeconds,
    });
  };

  handleFullScreenChange = () => {
    const isFullScreen = !!document.fullscreenElement;
    this.setState({ isFullScreen });
  };


  async componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('fullscreenchange', this.handleFullScreenChange);
  }

  handleKeyDown = (event:any) => {
    if (event.key === 'Escape') {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      this.setState({ isFullScreen: false });
    }
  };

  setPageNumber = (value:number) => {
    this.setState({pagNumber:value})
  }

  handleVideoEnd = () => {
    this.setState({ playing:false });
    this.state.lessonData.data.attributes.is_completed ? this.continueToLastLessonPage() : this.completeLesson() 
  }

  handleAudioEnd = () => {
    this.setState({ isAudioPlaying:false });
    this.state.lessonData.data.attributes.is_completed ? this.continueToLastLessonPage() : this.completeLesson() 
  }

  componentDidUpdate(prevProps:any, prevState:any) {
    if ((this.state.pagNumber !== prevState.pagNumber || this.state.numPages !== prevState.numPages) && !this.state.lessonData.data.attributes.is_completed) {
      this.state.numPages === this.state.pagNumber && this.completeLesson()
    }
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        this.apiResponse(apiRequestCallId,responseJson)
      }
    }
  }



  apiResponse = (apiRequestCallId:string,responseJson:any) => {
        this.lessonCallIdResponse(apiRequestCallId,responseJson)
        if(apiRequestCallId === this.lessonCompleteCallId && responseJson.data){
          this.setState({openImageDialog:false,isFullScreen:false})
            this.getLessonData(responseJson.data.attributes.lesson_id)
           this.isLastphaseVideo(responseJson)
        }
        if(apiRequestCallId === this.reviewCourseProgramApiId) {
          this.handleReviewCourseResponse(responseJson)
        }
        if(apiRequestCallId === this.noteDataApiId && responseJson.data){
          this.setState({
            lessonNotedata:responseJson.data
          })
        }
        if(apiRequestCallId === this.GetProgramLibraryData && responseJson.data){
          this.setState({programData:responseJson,isLoading:false})
        }
        if(apiRequestCallId === this.addNoteApiId){
          this.handleAddNoteApiResponse(responseJson)
        }
        if(apiRequestCallId === this.updateNoteApiId){
          this.handleEditApiResponse(responseJson)
        }
        if(apiRequestCallId === this.deleteNoteApiCallId){
          this.handleDeleteNoteApiResponse(responseJson)
        }
  }

  lessonCallIdResponse = (apiRequestCallId:string,responseJson:any) => {
    if(apiRequestCallId === this.lessonDataCallId && responseJson.data){
      const totalVideoCount = responseJson.data.attributes.total_video_count || 0;
      const completedVideoCount = responseJson.data.attributes.completed_video_count || 0;
      const completionPercentage = totalVideoCount ? (completedVideoCount / totalVideoCount) * 100 : 0;
      this.setState({playbackRate:1,displayReviewPage: false,allLessonsCompleted:false,showStartNewScreen:false,lessonData:responseJson,audioCurrentTime:0,isLastLessonPage:false,isReviewCourse:false,isLessonProgramPage:false,isAudioPlaying:false,isOpen:false,progressPercentage:completionPercentage,pagNumber:1,isLoading:false},() => {
       this.setLockPhases()
       this.getDefaultActivePhase();
       if(this.state.islastVideo){
        this.continueToLastLessonPage()
        this.setState({islastVideo:false})
       }
       if(this.state.lessonData.data.attributes.program_id){
        this.getProgramData(this.state.lessonData.data.attributes.program_id)
       }
      })
    }
  }
  getProgramData = async (programId:number) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProgramLibraryData = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_playlist/user_programs?id=${programId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),'get');
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  playAgainPhase = () => {
    if(this.state.lessonData.data.attributes.phases && this.state.lessonData.data.attributes.phases.length > 0){
      const existingLesson = this.state.lessonData.data.attributes.phases.find((phases:any)=> phases.videos.find((videos:any)=>Number(this.state.activeLesson) === videos.id))
      existingLesson && this.getLessonData(existingLesson.videos[0].id)
    }else{
      this.getLessonData(this.state.lessonData.data.attributes.videos[0].id)
    }
  }

  playUnCompleteLesson = () => {
    const allLessons = this.state.lessonData.data.attributes.phases && this.state.lessonData.data.attributes.phases.length > 0 ? this.state.lessonData.data.attributes.phases.flatMap((phase:any)=>phase.videos) : this.state.lessonData.data.attributes.videos
    const existingLesson = allLessons.find((phases:any)=> !phases.is_completed)
    this.getLessonData(existingLesson.id)
  }

  isLastphaseVideo = (responseJson:any) => {
    if(this.state.lessonData.data.attributes.phases){
      const existingPhase = this.state.lessonData.data.attributes.phases.find((phases:any)=> phases.phase_id === this.state.lessonData.data.attributes.phase_id)
      const existingLesson = existingPhase.videos.findIndex((phases:any)=> phases.id === responseJson.data.attributes.lesson_id)
      if(existingLesson + 1 === existingPhase.videos.length){
        this.setState({islastVideo:true})
      }
    }
  }

  setLockPhases = () => {
    if(this.state.lessonData.data && this.state.lessonData.data.attributes.phases && this.state.lessonData.data.attributes.phases.length > 0){
       const lockedData = this.state.lessonData.data.attributes.phases.filter((phase:any)=>phase.is_lock).map((phase:any)=>phase.phase_id)
       this.setState({lockedData:lockedData})
    }
  }

  activeAccordion = (activePhase:any) => {
    this.setState({activePhase:activePhase.phase_id === this.state.activePhase ? "" : activePhase.phase_id})
  }

  getDefaultActivePhase = () => {
    if(this.state.lessonData.data && this.state.lessonData.data.attributes.phases && this.state.lessonData.data.attributes.phases.length > 0){
      const activePhase = this.state.lessonData.data.attributes.phases.find((phase:any)=> phase.phase_id === this.state.lessonData.data.attributes.phase_id)
      this.setState({activePhase:activePhase.phase_id || '',activePhaseName:activePhase.phase || ''})
    }
  }

  handleSeekForward = () => {
    if(this.playerRef.current){
      const currentTime = this.playerRef.current.getCurrentTime();
      this.playerRef.current.seekTo(currentTime + 10);
    }
  };

  handleSeekForwardAudio = () => {
    if(this.audioPlayerRef.current){
      const currentTime = this.audioPlayerRef.current.getCurrentTime();
      this.audioPlayerRef.current.seekTo(currentTime + 10);
    }
  };

  handleSeekBackward = () => {
    if(this.playerRef.current){
       const currentTime = this.playerRef.current.getCurrentTime();
    this.playerRef.current.seekTo(currentTime - 10);
    }
  };

  handleSeekBackwardAudio = () => {
    if(this.audioPlayerRef.current){
       const currentTime = this.audioPlayerRef.current.getCurrentTime();
    this.audioPlayerRef.current.seekTo(currentTime - 10);
    }
  };

  handleToggleVideo = () => {
this.setState({playing:!this.state.playing})
  }

  handleProgress = (state: { playedSeconds: number }) => {
    this.setState({ played: state.playedSeconds });
  };

  handleDuration = (duration: number) => {
    this.setState({ duration });
  };

  handleAudioDuration = (duration: number) => {
    this.setState({audioLessonDuration: duration });
  };

  handleSeekChange = (event: React.ChangeEvent<{}>,value:number | number[]) => {
    const seekTo = value;
    this.playerRef.current?.seekTo(seekTo);
    this.setState({ played: seekTo as number});
  };

   handleMouseEnter = () => {
    this.setState({showPlayButton:true});
    setTimeout(() => {
      this.setState({showPlayButton:false});
    }, 5000);
  };

   handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({showPlayButton:false});
    }, 2000);
  };

  handleSpeedChange = (event:any) => {
    this.setState({ playbackRate: event.target.value });
  };

  handleFullScreenToggle = () => {
    const videoContainer = this.videoContainerRef.current;

    if (!this.state.isFullScreen && videoContainer) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if ((videoContainer).webkitRequestFullscreen) {
        (videoContainer).webkitRequestFullscreen();
      } else if ((videoContainer).mozRequestFullScreen) {
        (videoContainer).mozRequestFullScreen();
      } else if ((videoContainer).msRequestFullscreen) {
        (videoContainer).msRequestFullscreen();
      }

      this.setState({ isFullScreen: true });
    } else if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }

      this.setState({ isFullScreen: false });
    }
  };

  getLessonData = async (id?:any) => {
     this.setState({isOpen:true,isLoading:true})
  await setStorageData("lessonId",id)
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.lessonDataCallId = requestMessage.messageId;

    const lessonId = id
     this.setState({activeLesson:lessonId})
     const programId = await getStorageData("programId",true)
     let endpoint = `/bx_block_playlist/user_lessons/${lessonId}`;
     if(programId){
       endpoint = `/bx_block_playlist/user_lessons/${lessonId}?program_id=${programId}`;
     }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  onDocumentLoadSuccess = ({ numPages }:any) => {
      this.setState({numPages: numPages,pdfLoaded:true,pdfProgressLoaading:100});
  }

  
   formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
  
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    } else {
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
  };

  handleToggleMute = () => {
    this.setState((prevState) => ({ muted: !prevState.muted }));
  };

  completeLesson = async () => {
   const header = {
    ContentType: "multipart/form-data",
     token: localStorage.getItem("token")
   };
   const todayData = moment().format('YYYY-MM-DD');
   let formdata = new FormData();
   formdata.append("lesson_id", this.state.lessonData.data.id);
   if(this.state.lessonData.data.attributes.icon_type === "video" || this.state.lessonData.data.attributes.icon_type === "audio"){
     formdata.append("track_time", this.state.lessonData.data.attributes.icon_type === "video" ? Math.floor(this.state.played).toString() : Math.floor(this.state.audioCurrentTime).toString());
   }
   formdata.append("is_completed", "true")
   formdata.append("track_date", todayData)
   if(this.state.lessonData.data.attributes.program_id){
    formdata.append("is_program_track", "true")
    formdata.append("program_id", this.state.lessonData.data.attributes.program_id)
   }
   const httpBody = formdata
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   this.lessonCompleteCallId = requestMessage.messageId;

   const endpoint = `/bx_block_adhocreporting/course_video_track`;

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     endpoint
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     header
   );
   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Post'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    httpBody
  );
   runEngine.sendMessage(requestMessage.id, requestMessage);
 }

  activeLessons = (event:any,productCard:any) => {
    event.stopPropagation();
    if(!productCard.is_lock){
      this.getLessonData(productCard.id)
      this.getNoteData(productCard.id)
    }
    }

    toggleImageDialog = () => {
      this.setState({openImageDialog:!this.state.openImageDialog})
    }

    continueToNextLesson = () => {
      const videos = this.state.lessonData.data.attributes.videos.length > 0 ? this.state.lessonData.data.attributes.videos : this.state.lessonData.data.attributes.phases.map((phase:any)=> [...phase.videos]).flat()
      const existingLessonIndex = videos.findIndex((videos:any)=> Number(this.state.lessonData.data.id) === videos.id)
      const nextLesson = videos.length > existingLessonIndex + 1 && videos.find((videos:any,index:number)=> existingLessonIndex < index)
       if(nextLesson && !nextLesson.is_lock){
        this.getLessonData(nextLesson.id)
       }else if(!nextLesson && this.state.lessonData.data.attributes.total_video_count === this.state.lessonData.data.attributes.completed_video_count){
        this.continueToNextLessonForLastLesson()
       }else if(!nextLesson && this.state.lessonData.data.attributes.total_video_count !== this.state.lessonData.data.attributes.completed_video_count){
        this.setState({isLastLessonPage:false,isReviewCourse:false,isLessonProgramPage:false,showStartNewScreen:false,allLessonsCompleted:true})
       }else{
        this.setState({isLastLessonPage:true,isReviewCourse:false,isLessonProgramPage:false,showStartNewScreen:false,allLessonsCompleted:false})
       }
    }

    continueToNextLessonForLastLesson = () => {
      if(this.state.lessonData.data.attributes.is_program_enroll && this.state.programData.data.attributes.phases && this.state.programData.data.attributes.phases.length > 0){
       this.renderIfNextProgramPhaseIsLocked()
      }else if(this.state.lessonData.data.attributes.is_program_enroll && this.state.programData.data.attributes.program_include && this.state.programData.data.attributes.program_include.length > 0){
        const programInclude = this.state.programData.data.attributes.program_include || [];
        const courseIndex = programInclude.findIndex(
          (course: any) => course.id === this.state.lessonData.data.attributes.course_id
      );
        const isLastCourse = courseIndex + 1 === programInclude.length;
        if(!isLastCourse && programInclude[courseIndex + 1] && !programInclude[courseIndex + 1].is_lock){
          this.setState({isLastLessonPage:false,showStartNewScreen:true,isReviewCourse:false,isLessonProgramPage:false})
        }else{
          this.setState({isLastLessonPage:false,showStartNewScreen:false,allLessonsCompleted:false,isReviewCourse:true,isLessonProgramPage:false})
        }
      }else{
        this.setState({isLastLessonPage:false,showStartNewScreen:false,allLessonsCompleted:false,isReviewCourse:true,isLessonProgramPage:false})
      }
    }

    renderIfNextProgramPhaseIsLocked = () => {
      const programphase = this.state.programData.data.attributes.phases.find((phase:any)=>phase.program_include.map((programInc:any)=>programInc.id).includes(this.state.lessonData.data.attributes.course_id)) 
      const isLastCourseInProgramPhase = programphase.program_include.findIndex((ProgramInc:any)=>ProgramInc.id === this.state.lessonData.data.attributes.course_id) + 1 === programphase.program_include.length ? true : false
      const isLastProgramphase = programphase.phase_number < this.state.programData.data.attributes.phases.length;
      const isNextPhaseActive = this.state.programData.data.attributes.phases[programphase.phase_number] && this.state.programData.data.attributes.phases[programphase.phase_number]?.is_lock
      const programInclude = this.state.programData?.data.attributes.phases.flatMap((phase: any) => phase.program_include || []);
      const courseIndex = programInclude.findIndex(
        (course: any) => course.id === this.state.lessonData.data.attributes.course_id
    );
      const isLastCourse = courseIndex + 1 === programInclude.length;
      if(isLastCourseInProgramPhase && isLastProgramphase && isNextPhaseActive){
        this.setState({isLastLessonPage:false,isReviewCourse:false,isLessonProgramPage:true,showStartNewScreen:false,allLessonsCompleted:false})
      }else if(!isLastCourse && programInclude[courseIndex + 1] && !programInclude[courseIndex + 1].is_lock){
        this.setState({isLastLessonPage:false,showStartNewScreen:true,isReviewCourse:false,isLessonProgramPage:false,currentActiveProgramPhase:this.state.programData.data.attributes.phases[programphase.phase_number]})
      }else{
        this.setState({isLastLessonPage:false,showStartNewScreen:false,allLessonsCompleted:false,isReviewCourse:true,isLessonProgramPage:false})
      }
    }
    continueToLastLessonPage = () => {
      const videos = this.state.lessonData.data.attributes.videos.length > 0 ? this.state.lessonData.data.attributes.videos : this.state.lessonData.data.attributes.phases.map((phase:any)=> [...phase.videos]).flat()
      const existingLessonIndex = videos.findIndex((videos:any)=> Number(this.state.lessonData.data.id) === videos.id)
      const nextLesson = videos.length > existingLessonIndex + 1 && videos.find((videos:any,index:number)=> existingLessonIndex < index)
      if(this.state.lessonData.data.attributes.phases){
         if(nextLesson.is_lock){
          this.setState({isLastLessonPage:true})
          return true
         }else if(!nextLesson){
          this.continueToNextLesson()
         }
      }else if(!nextLesson){
        this.continueToNextLesson()
       }
    }

    lastLessonActivity = () => {
      if(this.state.lessonData.data.attributes.is_program_enroll && this.state.programData.data.attributes.phases){
        const programphase = this.state.programData.data.attributes.phases.find((phase:any)=>phase.program_include.map((programInc:any)=>programInc.id).includes(this.state.lessonData.data.attributes.course_id)) 
        const isLastCourseInProgramPhase = programphase.program_include.findIndex((ProgramInc:any)=>ProgramInc.id === this.state.lessonData.data.attributes.course_id) + 1 === programphase.program_include.length ? true : false
        const isLastProgramphase = programphase.phase_number < this.state.programData.data.attributes.phases.length;
        const isNextPhaseActive = this.state.programData.data.attributes.phases[programphase.phase_number] && this.state.programData.data.attributes.phases[programphase.phase_number]?.is_lock
         if(isLastCourseInProgramPhase && isLastProgramphase && isNextPhaseActive){
          this.setState({isLastLessonPage:false,isReviewCourse:false,isLessonProgramPage:true})
        }else{
          this.setState({isLastLessonPage:false,isReviewCourse:true,isLessonProgramPage:false})
        }
      }else{
        this.setState({isLastLessonPage:false,isReviewCourse:true,isLessonProgramPage:false})
      }
    }

    handleNavigateToNextCourse = () => {
      const programInclude = this.state.programData.data.attributes.phases.length > 0 ? this.state.programData.data.attributes.phases.flatMap((phase:any)=>phase.program_include) : this.state.programData.data.attributes.program_include;
        const courseIndex = programInclude.findIndex(
          (course: any) => course.id === this.state.lessonData.data.attributes.course_id
      );
        this.props.history.push(`/course-detail/Program/${programInclude[courseIndex+1].id}/${programInclude[courseIndex+1].title.replace(/\s/g, '-')}`,{program:this.state.lessonData.data.attributes.program_id})
    }
    previousLesson = () => {
      const videos = this.state.lessonData.data.attributes.videos.length > 0 ? this.state.lessonData.data.attributes.videos : this.state.lessonData.data.attributes.phases.map((phase:any)=> [...phase.videos]).flat()
      const existingLessonIndex = videos.findIndex((videos:any)=> Number(this.state.lessonData.data.id) === videos.id)
      const nextLesson = existingLessonIndex > 0 && videos.find((videos:any,index:number)=> existingLessonIndex - 1 === index)
       if(nextLesson.id){
        this.getLessonData(nextLesson.id)
       }
    }

    downloadFile = () => {
      const link = document.createElement('a');
      link.href = this.state.lessonData.data.attributes.video.url;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
      setTimeout(() => {
        !this.state.lessonData.data.attributes.is_completed && this.completeLesson();
      }, 3000);
    };

    handleNavigateToCourse = () => {
      this.setState({isReviewSubmitted: false})
      window.history.back()
      }

       handleLoadProgress = ({ loaded, total }:{loaded:number,total:number}) => {
        const percent = Math.round((loaded / total) * 100);
        this.setState({pdfProgressLoaading: percent > 100 ? 100 : percent});
      };

    handleReviewCourse = () => {
      this.setState({
        displayReviewPage: true
      })
    }

    reviewCourseProgram = (body: unknown) => {
      const header = {
        token: localStorage.getItem("token"),
        ContentType:"multipart/form-data"
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.reviewCourseProgramApiId = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.reviewCourseProgramAPi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }

    submitReviewCourse = (body: unknown) => {
      this.reviewCourseProgram(body)
    }

    handleReviewCourseResponse = (responseJson: ApiResponse) => {
      if(responseJson.data) {
        this.setState({isReviewSubmitted: true})
        if(responseJson.data.attributes?.reviewable_type === "BxBlockCoursecreation::Course"){
          toast.success("Course Reviewed Successfully.");
        }else{
          toast.success("Program Reviewed Successfully.");
        }
        setTimeout(() => {
          this.handleNavigateToCourse()
        }, 2000)
      } else {
       toast.error(responseJson?.error?.message ?? responseJson.errors[0].message);
      }
    }

    handleActiveTab = async(tab:number) => {
      this.setState({
        activeTabIndex:tab
      })
      if(tab === 1) {
        const lessonId = await getStorageData("lessonId",true)
        this.getNoteData(lessonId)
      }
    }
    getNoteData = async(chapterId:number) => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.noteDataApiId = requestMessage.messageId;
      const courseId = await getStorageData("courseId")
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.noteCourseApiEndpoint + courseId + '&chapter_id=' + chapterId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOpenNote = (id:string) => {
      this.setState({
        openNotePreview:!this.state.openNotePreview,
        notePreviewId:id
      })
    }
    handleAddNote = () => {
      this.setState({
        addNoteModal:true,
        editNoteModal:false,
        titleValue:'',
        descriptionValue:'',
        isAttachmentChange:true,
        attachedFile:null
      })
    }
    handleCloseAddNote = () => {
      this.setState({
        addNoteModal:false,
        titleValue:'',
        descriptionValue:'',
        isAttachmentChange:true,
        attachedFile:null,
        openNotePreview:false,
        validationError:false,
        titleError:false,
        descriptionError:false,
        titleErrorMessage:'',
        descriptionErrorMessage:''
      })
    }
    onSelectFile = (file: File) => {
      this.setState({ isAttachmentChange: true,FileType:"image", attachedFile: file, uploadFileProgress: 0, uploadFileStatus: 'uploaded', })
    }
    SelectedIconFileDeleted=()=>{
      this.setState({ isAttachmentChange: true, attachedFile: null,deleteImageModal:false})
    }
  
    handleChangeTitle = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        titleValue:event.target.value.replace(/^\s+/, ''),
      })
      if(event.target.value.length <= 20 && event.target.value.length >= 5){
        this.setState({
          titleError:false,
          titleErrorMessage:''
        })
        
      }else{
        this.setState({
          titleError:true,
          titleErrorMessage:'Title should be more than 5 character and less than 20 character',
          validationError:false
        })
      }
      
    }

    handleChangeDescription = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        descriptionValue:event.target.value.replace(/^\s+/, '')
      })
      if(event.target.value.length <= 500 && event.target.value.length >= 5){
        this.setState({
          descriptionError:false,
          descriptionErrorMessage:''
        })
        
      }else{
        this.setState({
          descriptionError:true,
          descriptionErrorMessage:'Description should be more than 5 character and less than 500 character',
          validationError:false
        })
      }
    }
    handleCreateNote = () => {
      if(this.state.titleValue === "" || this.state.descriptionValue === ""){
        this.setState({
          validationError:true,
          descriptionError:false,
          titleError:false,
          descriptionErrorMessage:'',
          titleErrorMessage:''
        })
      }else if(this.state.descriptionError || this.state.titleError){
        this.setState({
          validationError:false
        })
      }else{
        this.setState({
          validationError:false
        })
       
        this.handleAddNoteApiCall();
      }
    }
    handleAddNoteApiCall = async() => {
      const header = {
        token: localStorage.getItem("token")
      };
      const courseId = await getStorageData("courseId")
      const chapterId = await getStorageData("lessonId",true)
      const programId = await getStorageData("programId",true)
      const formData = new FormData()
      formData.append("note[title]",this.state.titleValue)
      formData.append("note[description]",this.state.descriptionValue)
      formData.append("note[course_id]",courseId)
      formData.append("note[chapter_id]",chapterId)
      if(this.state.attachedFile && !(this.state.attachedFile as { id: number; url: string; file_name: string; } )?.url){
        formData.append("note[image]",this.state.attachedFile as FileWithPath)
      }
      formData.append("note[note_type]",'course')
      formData.append("note[program_id]",programId)
      formData.append("note[course_timestamp]",this.formatTime(this.state.played))
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addNoteApiId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addNoteEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleNavigateToProgram = () => {
      this.props.history.push(`/program-detail/Program/${this.state.programData.data.id}/${this.state.programData.data.attributes.title.replace(/\s/g, '-')}`)
    }
    
    
    handleAddNoteApiResponse = async(responseJson: NoteAddResponse) => {
      if(responseJson.data){
        toast.success(`A note has been added to ${responseJson.data.attributes.chapter_title}  correctly`);
        this.handleCloseAddNote();
        const lessonId = await getStorageData("lessonId",true)
        this.getNoteData(lessonId)
      }else{
        toast.error(responseJson?.error?.message ?? responseJson.errors[0].message);
      }
    }
    handleEditNote = (noteId:string) => {
      this.setState({
        addNoteModal:true,
        editNoteModal:true,
        editNoteId:noteId
      })
      this.state.lessonNotedata.filter(data => data.id === noteId).forEach(item => {
        return(
          this.setState({
            titleValue:item.attributes.title,
            descriptionValue:item.attributes.description,
            attachedFile:item.attributes.image ? { ...item.attributes.image, name: item.attributes.image?.file_name, type: "image/png" }as { id: number; url: string; file_name: string; } : null
          })
        )
      })
     
    }
    deleteImagePopup = () => {
      this.setState({
        deleteImageModal:!this.state.deleteImageModal
      })
    }
    handleEdit = async() => {
      const header = {
        token: localStorage.getItem("token")
      };
      const courseId = await getStorageData("courseId")
      const chapterId = await getStorageData("lessonId",true)
      const programId = await getStorageData("programId",true)
      const formData = new FormData()
      formData.append("note[title]",this.state.titleValue)
      formData.append("note[description]",this.state.descriptionValue)
      formData.append("note[course_id]",courseId)
      formData.append("note[chapter_id]",chapterId)
      formData.append("note[note_type]",'course')
      formData.append("note[program_id]",programId)
      formData.append("note[course_timestamp]",this.formatTime(this.state.played))
      if (this.state.attachedFile instanceof File) {
        formData.append("note[image]", this.state.attachedFile);
      }
      if (this.state.attachedFile && 'url' in this.state.attachedFile) {
        const response = await fetch(this.state.attachedFile.url);
        const blob = await response.blob();
        const file = new File([blob], this.state.attachedFile.file_name || 'file', { type: blob.type });
        
        formData.append("note[image]", file);
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateNoteApiId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateNoteEndPoint + this.state.editNoteId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleEditApiResponse = async(responseJson: NoteAddResponse) => {
      if(responseJson.data){
        toast.success(`${responseJson.data.attributes.chapter_title} edit has been saved correctly`);
        this.setState({
          addNoteModal:false,
          editNoteModal:false
        })
        this.handleOpenNote(responseJson.data.id)
        const lessonId = await getStorageData("lessonId",true)
        this.getNoteData(lessonId)
      }else{
        toast.error(responseJson?.error?.message ?? responseJson.errors[0].message);
      }
    }
    deleteModal = () => {
      this.setState({
        deleteModalOpen:!this.state.deleteModalOpen
      })
    }
    handleDeleteNoteApiCall = (noteId:string) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        token: localStorage.getItem("token"),
        "Content-Type": configJSON.validationApiContentType
      };
      this.deleteNoteApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteNoteEndPoint + noteId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleDeleteNoteApiResponse = async(response:DeleteNoteResponse1) =>{
      if(response.message){
        toast.success(`${response.message}`);
        this.setState({
          deleteModalOpen:false,
          openNotePreview:false
        })
        const lessonId = await getStorageData("lessonId",true)
        this.getNoteData(lessonId)
      }else{
        toast.error(response?.error?.message ?? response.errors[0].message);
      }
    }

  // Customizable Area End
}