Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrganisationHierarchy";
exports.labelBodyText = "OrganisationHierarchy Body";
exports.loginBodyType = "email_account";

exports.postApiMethodType = "POST";
exports.loginURLEndPoint = "bx_block_login/logins";
exports.getAllTasks = "bx_block_tasks/tasks";
exports.getEmployeeTask = "bx_block_tasks/employee_tasks";
exports.getEmployees = "bx_block_employee/employees";
exports.getEmployeeHierarchy = "bx_block_employee/employees/get_hierarchy";
exports.email = "buraktest@gmail.com";
exports.securedText = "123456";
exports.StateListEndPoints="bx_block_organisationhierarchy/country_and_states"
exports.TimeZoneListEndPoints="bx_block_organisationhierarchy/country_and_states/timezone"
exports.getParentBranchEndPoints= "bx_block_organisationhierarchy/branch/parent_branch_list_level"
exports.CreateBranchEndPoints="bx_block_organisationhierarchy/branch"
exports.btnExampleTitle = "CLICK ME";
exports.getBranchDetailsEndPoint = "bx_block_organisationhierarchy/branch/1/subbranches";
exports.advanceSearchApiEndPoint = "bx_block_advanced_search/search/filter";
exports.teamListingApiEndPoint = "/account_block/standard_users/team_list";
exports.BranchesListingApiEndPoint = "/account_block/standard_users/branch_list";
// Customizable Area End
