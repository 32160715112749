import React from "react";
import {
  Box, Button, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DynamicTitle from "../../../components/src/DynamicTitle";
import NewlyAddedController from './NewlyAddedController.web';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import './videostyles.css';
import { SearchChild } from "./Search.web";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import Rating from '@material-ui/lab/Rating';
import { startRating,emptyStart } from "./assets";

// Customizable Area Start
const img4 = require("../assets/slider-1.png");
const img2 = require("../assets/courseimage4.png");
const play = require("../assets/play.png")
const cart = require("../assets/Cart_white.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const Short = require("../assets/Sort.png")
const shop = require("../assets/image_Bitmap Copy 4.png")

// Customizable Area End
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      width: '60%',
      marginTop: '15px',
      marginLeft: '17px',
    },
    colorPrimary: {
      // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
    },
  }))(LinearProgress);

export default class NewlyAdded extends NewlyAddedController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End

    _onFocus = () => {
      this.props.history.push('/Search')
    }
    gotoBackSide = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Library2WebPage");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    getCardTexts(element:any){
      return(
                 <Box style={{ display:"flex",gap: "16px",flexDirection:"column"}}>
                        <h1 
                         id="handle-title-click"
                         onClick={() => this.handleNavigate(element)}
                        title={element.attributes.collection.attributes.title.length>20 ? 
                        element.attributes.collection.attributes.title : ""} 
                        style={{fontFamily:
                         "Poppins",fontSize: "16px",
                         fontWeight: 700,
                         lineHeight: "22px",
                         textAlign: "left",
                         color:"#1C1B1B",
                         height:"20px"}}
                         >
                          {
                            element.attributes.collection.attributes.title.length>20 ? (
                              `${element.attributes.collection.attributes.title.slice(0,20)}...`
                            ) :(
                              element.attributes.collection.attributes.title
                            )
                          }
                        </h1>
                        <Typography style={{fontFamily: "Poppins",fontSize: "14px",fontWeight: 500, lineHeight: "22px",}}>{element.attributes.collection.attributes.created_by}</Typography>
                        <Typography
                          style={{
                            fontFamily: "Poppins",fontSize: "12px",fontWeight: 400,lineHeight: "18px",color:"#484646"
                          }}>{element.attributes.due_in_days === "Overdue" ? element.attributes.due_in_days : `Due in ${element.attributes.due_in_days} Days`}</Typography>
                      </Box> 
      )
    }
    getCardButtons(content: any) {
      return (
        <>
          {content.attributes.collection.attributes.is_paid ? (
            <Card
              style={{
                width: '87px',
                height: '45px',
                borderRadius: '30px',
                float: 'right',
                marginTop: '-2px',
                marginRight: '20px',
                background: '#1D1F4F',
              }}
            >
              <CardActionArea></CardActionArea>
            </Card>
          ) : (
            <ButtonWrapper>
              <Box>
                <Typography
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '18px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    textAlign: 'left',
                    height: '24px',
                    opacity: '0px',
                    marginRight: '24px',
                    textTransform:'capitalize'
                  }}
                >
                  {content.attributes.purchase_type === 'free'
                    ? content.attributes.purchase_type
                    : `$${content.attributes.price}`}
                </Typography>
              </Box>
    
              {content.attributes.button_action === 'play' ? (
                <Card
                  style={{
                    height: '48px',
                    borderRadius: '30px',
                    color: 'white',
                    float: 'right',
                    marginTop: '-2px',
                    background:
                      'linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)',
                    margin: '12px 16px 18px 6px',
                  }}
                >
                  <CardActionArea
                    id="post-newlyadded-data-play-btn"
                    onClick={() => this.postplayButton(content)}
                  >
                    <img
                      src={play}
                      style={{
                        margin: 'auto',
                        padding: '12px',
                        display: 'flex',
                        color: 'white',
                      }}
                    />
                  </CardActionArea>
                </Card>
              ) : (
                <Card
                  style={{
                    width: '106px',
                    height: '48px',
                    borderRadius: '30px',
                    float: 'right',
                    marginTop: '-2px',
                    background: 'black',
                    margin: '12px 16px 18px 6px',
                  }}
                >
                  <CardActionArea
                    id="post-newlyadded-data-cart-btn"
                    onClick={() => this.postplayButton(content)}
                  >
                    <img
                      src={cart}
                      style={{ margin: 'auto', padding: '12px', display: 'flex' }}
                    />
                  </CardActionArea>
                </Card>
              )}
            </ButtonWrapper>
          )}
        </>
      );
    }
    
    render() {
         return (
      <>
             <HeaderBox>
               <ArrowBackIcon
                 data-test-id="gotoBackBtn"
                 onClick={this.gotoBackSide}
               />
               <DynamicTitle title="Newly Added" />
             </HeaderBox>
            <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
                <GridWrapper container data-test-id="grid-wrapper">
                  {this.state.allInProgressData?.data?.length !== 0 ?
                  this.state.allInProgressData?.data?.map((conent: any, index: any) => {
                    return (
                  <Grid key={index} item lg={2} md={4} sm={6} xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Box className="gridItem"
                      style={{
                      height: "407px",position: "relative",
                      cursor:"pointer"}} key={index}>
                      <Box style={{
                          height: "181px", padding: "16px 0px 0px 0px", gap: "13px",borderRadius: "12px 0px 0px 0px",opacity: "0px",position: "relative"
                        }}>
                         <img width="100%" height="100%" style={{borderRadius:"12px"}} src={conent.attributes.collection.attributes.thumbnail_image.url} alt="kfjakhj" />
                         <Box style={{...styles.flexCenter,width:"100%",height:"100%",borderRadius: "12px",position:"absolute",top:"17px",background: "rgba(0, 0, 0, 0.3)"}}>
                          </Box> 
                          {conent.attributes.collection.attributes.expand_type === "Program" && <Typography style={{...styles.chipText,position:"absolute",top:"25px",right:"10px",textAlign:'center'}}>{conent.attributes.collection.attributes.expand_type}</Typography>} 
                      </Box>
                      <Box style={{width:"100%",height: "242px",padding: "24px",borderRadius: "12px ",position: "absolute",bottom:5,backgroundColor:"white",boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"}}>
                      {this.getCardTexts(conent)}
                      {this.getCardButtons(conent)}
                      {conent.attributes.review > 0 &&
                       <Box style={{display:'flex',alignItems:'center',gap:'3px',position:'absolute',bottom:'10px'}}>
                       <Typography style={{color: '#FF8B3F',
                          fontSize: '16px',
                          fontWeight:600}}>{conent.attributes.review % 1 === 0 ? conent.attributes.review : conent.attributes.review.toFixed(1)}</Typography>
                       <Rating
                          name='read-only'
                          value={conent.attributes.review.toFixed(2)}
                          precision={0.5}
                          readOnly
                          icon={<img src={startRating}/>}
                          emptyIcon={<img src={emptyStart}/>}
                      />
                       </Box>
                      }
                      </Box>  
                    </Box>
          </Grid>
                  )}) :
                  <><h2 style={{textAlign:'center',marginTop:'10%'}}>No Courses available</h2></>}
                </GridWrapper>

                </Grid>

             </Grid>
                {/* <Paper style={{ marginTop: '25px', height: '50px' }}>
                  <Pagination
                    count={this.state.allInProgressData?.meta?.pagination?.total_pages}
                    page={this.state.page}
                    color="primary"
                    onChange={this.pagination}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  />
                </Paper> */}
       </>



        )

    }

}

const GridWrapper = styled(Grid)({
    // marginTop: "50px",
    display: "flex", 
    // alignItems: "center", 
    justifyContent: "start",
    // flexWrap: "wrap"
  })

  const GridItem = styled(Grid)({
    display:"flex",justifyContent:"center",alignItems:"center",
    // maxWidth: "260px !important",
    // boxShadow:"0px 0px 16px #BFBEBE",
    // margin:"1%",
    backgroundColor:"white",
    // borderRadius: '20px',
  })

  const ButtonWrapper = styled(Box)({
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    gap:1
  })
  const styles = {
    chipTypography:{
      height:"28px",
      width: "117px",
      borderRadius: "16px",
      padding: "3px 0px",
      fontWeight:400,
      color:"white",
      background: "#E70E6B",
      fontSize:"14px",
    },
    paperStyleBefore: {
        overflow: 'hidden', flex: 1, height: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    paperStyleAfter: {
        overflow: 'hidden', flex: 1, minHeight: '525px', maxWidth: '90vw', margin: 'auto', padding: '30px', borderRadius: '24px', background: '#FFFFFF', marginTop: '25px'
    },
    mainhadding: {
        display: 'contents',
        fontSize: '48px',
        fontWeight: 600,
    },
    subHadding: {
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '5px'
    },
    mainContainer: {
        display: 'flex'
    },
    BoxContainer: {
        width: '100%',
        height: '45px',
        marginTop: '-5px'
    },
    filterButton: {
        color: '#6C328B',
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        fontWeight: 700,
        border: '1px solid #6C328B'
    },
    IconBox: {
        width: '100%',
        marginTop: '-5px',
        paddingLeft: '15px'
    },
    filterIcon: {
        width: '75px',
        height: '45px',
        borderRadius: '12px',
        border: '1px solid #6C328B',
        cursor: 'pointer'
    },
    paper_personal: {
        flex: 1,
        overflow: 'hidden',
        height: '490px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    typography_personal: {
        fontWeight: 700,
        fontSize: '24px'
    },
    typography_view_personal: {
        fontWeight: 700,
        fontSize: '17px',
        float: 'right' as 'right',
        marginTop: '-36px',
        cursor: 'pointer',
        color: 'rgba(97, 97, 97, 1)'
    },
    img_shape: {
        paddingLeft: '13px'
    },
    paper_corporate: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    paper_xpand: {
        overflow: 'hidden',
        flex: 1,
        height: '500px',
        maxWidth: '90vw',
        margin: 'auto',
        padding: '30px',
        borderRadius: '24px',
        background: '#FFFFFF',
        marginTop: '25px'
    },
    flexBetween:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    flexCenter:{
      display:"flex",
      justifyContent:"center",
    },
    chipText:{
      width: "117px",
      height:"28px",
      padding: "3px 0px",
      borderRadius: "16px",
      background: "#E70E6B",
      color:"white",
      fontWeight:400,
      fontSize:"14px",
    }
}

const HeaderBox = styled(Box)(({ theme }) =>({
  position: "absolute",
  zIndex: 9999,
  top: "22px",
  left: "60px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  cursor:"pointer",
  [theme.breakpoints.up("sm")]: {
      left: "125px",
      top: "8px"
  },
  [theme.breakpoints.up("md")]: {
      left: "125px",
      top: "8px"
  },
}));

const ArrowBackIcon = styled(ArrowBackIosIcon)({
  marginLeft: "8px",
  cursor: "pointer",
  color: "#92929D",
});
