import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { startOfToday } from 'date-fns';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
// import Dashboard from "../../web/public/Dashboard.svg"

class CustomDateFnsUtils extends DateFnsUtils {
  getWeekdays() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}

const useStyles = makeStyles({
  datePicker: {
    '& .MuiDialogActions-root button:last-child': {
        // Selecting the last button in the actions (which is the OK button)
        // and changing its text
        content: '"Select"',
      },
  },
 
  outlinedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'purple', // Border color
      borderStyle: 'dashed', // Border style
    },
    "& .PrivateNotchedOutline-root":{
        borderStyle: 'dashed',
        borderColor: 'purple',
    }
  },
  datePickerRoot: {
    border:"1px dashed ",
    borderRadius:"10px",
    height:"54px",
    '& .MuiPickersCalendarHeader-dayLabel-2288': {
      fontSize: "14px",
      color: "#1C1B1B",
      lineHeight: "16px",
      fontFamily: "'Poppins', sans-serif"
    },
    '& .Mui-error': {
      lineHeight: "1.2",
    }  
  },
  enableDatePicker:{
    background:"#FCF8F8",
    borderColor:"#929090",
  },
  disableDatePicker:{
    borderColor:"#ADAAAA",
    background:"#FFFFFF",
  }
});


function CustomDatePicker(props:any) {
  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: '310px !important',
          borderRadius:"16px",
          top:props.top,
          left:props.left,
        },
      },
      MuiToolbar: {
        root: {
          display: 'none !important',
        },
      },
      MuiBackdrop:{
        root:{
          backgroundColor: "none",
        },
      },
      MuiOutlinedInput:{
      notchedOutline:{
          border:"0px"
      }},
      MuiButton: {
          textPrimary: {
            color: 'white',
            backgroundColor: '#652786',
            width: '168px',
            height:"56px",
            '&:hover': {
              backgroundColor: '#652786',
            },
            textTransform:"capitalize",
            fontFamily: "'Poppins', sans-serif",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "16px",
            textAlign: "center",
            padding: "20px 90px 20px 90px",
            gap: "10px",
            borderRadius: "16px",
  
  
          },
        },
      MuiInputBase:{
           input:{
          fontFamily: "'Poppins', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          textAlign: "left",
      }
      },
      MuiDialogActions: {
          root: {
            justifyContent: 'center',
            borderTop:"1px solid #E5E2E1"
          },
        },
      MuiTypography:{
        caption:{
          fontSize: "14px",
          color: "#1C1B1B !important",
          lineHeight: "16px",
          fontFamily: "'Poppins', sans-serif"
      }
    }  
  },
  palette: {
      primary: {
        main: '#652786',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
      <DatePicker
        className={`${classes.datePickerRoot} ${!props.disable?classes.enableDatePicker:classes.disableDatePicker}`}
        value={props.selectedDate}
        format="MM/dd/yyyy"
        disabled={props.disable}
        onChange={props.handleChange}
        placeholder={props.placeHolder} 
        minDate={props.minDate}
        maxDate={props.maxDate}
        InputProps={{
            startAdornment: ( 
              <InputAdornment position="start">
                  {/* <DateRangeOutlinedIcon/> */}
                  {props.disable ? <img src="/Union.svg"></img>:<img src="/Dashboard.svg"></img>}
              </InputAdornment>
            ),
          }}
          ToolbarComponent={() => null}
          inputVariant="outlined"
          PopoverProps={{
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
          }}
          cancelLabel={null}
          okLabel="Select"
      />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default CustomDatePicker;


