export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.png");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.png");
export const courseimage5 = require("../assets/courseimage5.png")
export const courseimage4 = require("../assets/courseimage5.png")
export const group2image = require("../assets/group2a.png")
export const rectangle2 = require("../assets/Rectangle2.png")
export const playimage = require("../assets/play.png")
export const image1 = require("../assets/coursebottomicon3.png")
export const image2 = require("../assets/Notes.png")
export const image3 = require("../assets/Notes1.png")
export const image4 = require("../assets/courseimage10.png")
export const shapecopy = require("../assets/shapecopy.png")
export const play = require("../assets/play.png")
export const img3 = require("../assets/courseimage5.png")
export const group = require("../assets/Group 8.png")
export const shop = require("../assets/image_Bitmap Copy 4.png")
export const courseplayIcon = require("../assets/Bitmap.png")
export const objectIcon = require("../assets/Bitmap2.png")
export const playIconYellow = require("../assets/play_icon_yellow_1.5.png")
export const fileIcon = require("../assets/file_icon.png")
export const fileDownloadIcon = require("../assets/file_download_icon.png")
export const checkedIcon = require("../assets/checked.png")
export const unCheckedIcon = require("../assets/unchecked.png")
export const checkedIconLarge = require("../assets/checked_large.png")
export const unCheckedIconLarge = require("../assets/unchecked_large.png")
export const img4 = require("../assets/slider-1.png");
export const img2 = require("../assets/courseimage4.png");
export const play1 = require("../assets/Object.png");
export const Shape = require("../assets/Shape.jpg");
export const Short = require("../assets/Sort.png");
export const img =  require("../assets/courseimage4.png");
export const NotesIcon = require("../assets/Notes.png");
export const FullScreenOnIcon = require("../assets/fullscreen_on.png");
export const FullScreenOffIcon = require("../assets/fullscreen_off.png");
export const emoji1 = require("../assets/Asset10Copy.png")
export const emoji2 = require("../assets/Asset11Copy.png")
export const emoji3 = require("../assets/Asset12Copy.png")
export const emoji4 = require("../assets/Asset13Copy.svg")
export const rewardImg = require("../assets/reward-top.png")
export const achivedImg = require("../assets/achive-top.png")
export const normalImg = require("../assets/norml-top.png")
export const verysadselectedImg = require("../assets/verysadselected.svg")
export const sadselectedImg = require("../assets/sad.svg")
export const happyselectedImg = require("../assets/happy.svg")
export const iconImage = require("../assets/icon_image.png");
export const iconPage = require("../assets/icon_page.png");
export const frame = require("../assets/Frame.png");
export const iconQuiz = require("../assets/icon_quiz.png");
export const iconVideo = require("../assets/icon_video.png");
export const iconMusic = require("../assets/icon.png");
export const viewShape = require("../assets/view_shape.png");
export const courseImage = require("../assets/image_.png");
export const prevIcon = require("../assets/group_leftcontent.png");
export const nextIcon = require("../assets/button_right_.png");
export const veryHappyunselectedImg = require("../assets/veryHappyunselected.svg")
export const noContentImg = require("../assets/illustration_content assigned.png")
export const checkIcon = require("../assets/icon_check.png")
export const lessonCompleteIcon = require("../assets/Shape.png")
export const expandIcon = require("../assets/icon_expand.png")
export const smallCheckIcon = require("../assets/icon_check(1).png")
export const cartIcon = require("../assets/image_cart.png")
export const startIcon = require("../assets/image_start (1).png")
export const forwardIcon = require("../assets/corner-up-right.png")
export const backwardIcon = require("../assets/image_player.png")
export const settingIcon = require("../assets/icon_settings.png")
export const volumeIcon = require("../assets/icon_sound.png")
export const pauseIcon = require("../assets/icon_pause.png")
export const collapseIcon = require("../assets/icon_expand (2).png")
export const playIcon = require("../assets/image_video.png")
export const completedGreenIcon = require("../assets/icon_check (copy 1).png")
export const downloadBackgroundImage = require("../assets/Frame 2609289.png")
export const backButton = require("../assets/icon_player.png")
export const nextButton = require("../assets/icon_player(1).png")
export const lockIcon = require("../assets/group_vector.png")
export const finishedIcon = require("../assets/Frame 2609022.png")
export const activeLesson = require("../assets/Vector.png")
export const startRating = require("../assets/group_starrating.svg")
export const emptyStart = require("../assets/empty_starrating.svg")
export const iconCart = require("../assets/Icon_Cart.svg")
export const createNoteIcon = require("../assets/create_note.svg")

export const relatedData = [
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
	{
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
	{
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
    {
		imgPath: img,
	},
    
];

