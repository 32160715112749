import React, { useState } from 'react'
import { Box, Button, FormControl, styled, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as yup from "yup";
export const UserImage = require("./user.png");
export const StarIcon = require("./star.png");
export const StarIconActive = require("./starActive.png");
export const NoSmileIcon = require("./noSmile.png");
export const NoSmileIconActive = require("./noSmileActive.png");
export const SadIcon = require("./sad.png");
export const SadIconActive = require("./sadActive.png");
export const LoveIcon = require("./love.png");
export const LoveIconActive = require("./loveActive.png");
export const SmileIcon = require("./smile.png");
export const SmileIconActive = require("./smileActive.png");


interface ReviewCourseProps {
  courseName: string;
  submitReviewCourse: (data: unknown) => void;
  courseId?: number;
  programId: number;
  isSubmitted?:boolean;
  isProgram:boolean
}
type EmontionType = Array<{
  icon: string;
  type: string;
  iconActive: string
}>

type FormType = {
  feedback: string;
  learned: string
}

type Payload = {
  rating: number;
  comment: string;
  emoji: string;
  reviewable_id: number;
  reviewable_type: string;
}

const EMOTIONS: EmontionType = [
  {
    icon: SadIcon,
    iconActive: SadIconActive,
    type: "sad"
  },
  {
    icon: NoSmileIcon,
    type: "no_mood",
    iconActive: NoSmileIconActive,

  },
  {
    icon: SmileIcon,
    type: "happy",
    iconActive: SmileIconActive,

  },
  {
    icon: LoveIcon,
    type: "love",
    iconActive: LoveIconActive,

  }
]


const ReviewCourse = (props: ReviewCourseProps) => {
  const { courseName, submitReviewCourse, courseId, programId } = props
  const [comprehension, setComprehension] = useState("")
  const [rate, setRate] = useState(0)
  const handleClickEmotion = (type: string) => {
    if (type === comprehension) {
      setComprehension("")
    } else {
      setComprehension(type)
    }
  }

  const handleClickStar = (index: number) => {
    if (index === rate) {
      setRate(0)
    } else {
      setRate(index)
    }
  }

  const handleSubmitForm = (formValue: FormType) => {
    if (!comprehension || !rate || !formValue.feedback || !formValue.learned) {
      return
    }
    const data: Payload = {
      rating: rate,
      emoji: comprehension,
      comment: formValue.feedback,
      reviewable_id: courseId || programId,
      reviewable_type: courseId ? "BxBlockCoursecreation::Course" : "BxBlockCoursecreation::Program"
    }
    let formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value.toString());
    }
    submitReviewCourse(formData)
  }

  const schema = yup.object().shape({
    
    feedback: yup.string().required("Required Field").max(500, "Maximum 500 characters required").min(5,"Minimum 5 characters required"),
    learned: yup.string().required("Required Field").max(500, "Maximum 500 characters required").min(5,"Minimum 5 characters required"),
  })

  const initialValue: FormType = {
    feedback: "",
    learned: ""
  }

  const getBtnBgColor = (isValid: boolean) => {
    if (isValid) {
      return props?.isSubmitted ? "green" : "#E47004"
    }

    return "#E5E2E1"
  }

  return (
    <Wrapper>
      <Typography variant="h3" className="page-title">{courseId ? `Course` : `Program`} Completion</Typography>
      <Box className='container-box'>
      <Box className='header'>
        <Box className='text-part'>
          <Typography variant="h3" className="title">Congratulations!</Typography>
          <Box className='sub-title'>
            You have successfully <br /> completed the {props.isProgram ? "program" : "course"}
          </Box>
        </Box>
        <img src={UserImage} alt="user" />
      </Box>
      <Box className='header1'>
          <Box>
          <Typography style={{fontSize:"16px",fontWeight:700, lineHeight:"32px"}} variant="h3" className="title">Congratulations!</Typography>
            <Typography style={{fontSize:"12px",fontWeight:500, lineHeight:"18px"}}>You have successfully completed the {props.isProgram ? "program" : "course"}</Typography>
          </Box>
        <img src={UserImage} alt="user" />
      </Box>
      <Box className='form-box'>
        <Typography className="course-name">Review {courseName}</Typography>
        <Box className='comprehension'>
          <Box className="label">
            <Typography className='main-label'>Comprehension*</Typography>
            <Typography className='sub-label'>How well did you understand the concepts?</Typography>
          </Box>
          <Box className='emotion'>
            {
              EMOTIONS.map((emotion) => {
                return <div key={emotion.type} onClick={() => handleClickEmotion(emotion.type)}>
                  <img src={comprehension === emotion.type ? emotion.iconActive : emotion.icon} alt="emotion" />
                </div>
              })
            }
          </Box>
        </Box>
        <Box className='comprehension'>
          <Box className="label">
            <Typography className='main-label'>Rate your experience*</Typography>
            <Typography className='sub-label'>Hope you like the course</Typography>
          </Box>
          <Box className='emotion'>
            {
              Array(5).fill("").map((item, index) => {
                return <div key={index} onClick={() => handleClickStar(index + 1)}>
                  <img src={index < rate ? StarIconActive : StarIcon} className='starImg' alt="star" />
                </div>
              })
            }
          </Box>
        </Box>

        <Formik
          initialValues={initialValue}
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmitForm(values)
          }}
        >
          {({ handleChange, values, errors,touched, handleSubmit }) => {
            const areFieldsFilled = values.feedback.trim() !== '' && values.learned.trim() !== '' && comprehension && rate;
            return (
              <form className='form-css' onSubmit={handleSubmit}>
                <Box className='description-box'>

                  <FormControl variant="standard" className="customInput3"  >
                  <Typography className='main-label'>Any feedback?*</Typography>
                <TextField style={values.feedback.length > 0 ? descriptionTextarea1 :  descriptionTextarea}
                    data-test-id="selectTextAreaFieldID"
                    name={"feedback"}
                    multiline
                    maxRows={4}
                    placeholder="Please write your description"
                    id="bootstrap-input"
                    value={values.feedback}
                    onChange={handleChange}
                />
                {errors.feedback && touched.feedback &&
                <Typography style={{fontSize:"12px",color:"red"}}>*{errors.feedback}</Typography>
                }
            </FormControl>
                </Box>

                <Box className='description-box'>
                <FormControl variant="standard" className="customInput3"  >
                <Typography className='main-label'>What did you learn?*</Typography>
                <TextField style={values.learned.length > 0 ? descriptionTextarea1 :  descriptionTextarea}
                    data-test-id="selectTextAreaFieldID"
                    name={"learned"}
                    multiline
                    maxRows={10}
                    minRows={1}
                    placeholder="Please write your description"
                    id="bootstrap-input"
                    value={values.learned}
                    onChange={handleChange}
                    className='inputClass'
                />
                {errors.learned && touched.learned &&
                <Typography style={{fontSize:"12px",color:"red"}}>*{errors.learned}</Typography>
                }
            </FormControl>
                </Box>
                <Box className='submit-box'>
                  <Button
                    className={props?.isSubmitted ? 'btn-done' : 'btn-submit'}
                    type='submit'
                    disabled={!areFieldsFilled} 
                    style={{background:getBtnBgColor(Boolean(areFieldsFilled)),  color: areFieldsFilled ? "#FFFFFF" : "#787776",}}
                  >
                    {props?.isSubmitted ? 'Submitted' : 'Submit'}
                  </Button>
                </Box>
              </form>
            )
          }}
        </Formik>

      </Box>
      </Box>
    </Wrapper>
  )
}

export default ReviewCourse

const descriptionTextarea = {
  minHeight: "120px",
  border: "1px solid rgb(229, 226, 225)",
  backgroundColor: "#fcf6f6",
  fontSize:"16px",
  borderRadius:"8px",
  marginTop: "8px",
  padding: "2px 12px",
}
const descriptionTextarea1 = {
  minHeight: "120px",
  border: "1px solid rgb(229, 226, 225)",
  backgroundColor: "#fff",
  fontSize:"16px",
  borderRadius:"8px",
  marginTop: "8px",
  padding: "2px 12px",
}

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& .page-title": {
    fontWeight: 700,
    color:"#1C1B1B",
    "@media (max-width: 760px)": {
      fontSize:"20px"
    },
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"30px",
    "@media (max-width: 760px)": {
      display:"none"
    },
  },
  "& .header1": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(180deg, #F42074 0%, #DC1866 100%)",
    color:"white",
    padding:"3px 20px",
    borderRadius:"12px",
    "@media (min-width: 760px)": {
      display:"none"
    },
  },
  "& .header1 img": {
    width: "87px",
height: "92px",
  },
  "& .container-box":{
    marginTop:"15px",
    "@media (min-width: 760px)": {
      width:"80%",margin:"auto"
    },
  },
  "& .text-part": {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "& .title": {
      fontSize: "40px",
      fontWeight: 700,
      lineHeight: "60px",
      background: "-webkit-linear-gradient(#592A93, #BB1AB9)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent"
    },
    "& .sub-title": {
      backgroundImage: "-webkit-linear-gradient(#592A93, #BB1AB9)",
      color: "#FFFFFF",
      width: "356px",
      height: "100px",
      borderRadius: "14px",
      textAlign: "center",
      alignContent: "center",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
      display:"flex",
      flexWrap:"wrap",
      justifyContent:"center"
    }
  },
  "& .form-box": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    margin:"auto",
    maxWidth:"100%",
    "@media (min-width: 760px)": {
      background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
    padding: "30px",
    marginTop:"10px"
    },
  },
  "& .form-css": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  "& .course-name": {
    fontWeight: 700,
    fontSize: 32,
    color:"#1C1B1B",
  },
  "& .comprehension": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 760px)": {
      justifyContent: "space-between",
      flexDirection:"column",
    alignItems: "start",
    height:"135px"
    },
  },
  "& .emotion": {
    display: "flex",
    gap: "1rem",
  },
  "& .emotion img":{
    "@media (max-width: 760px)": {
      height:"29.88px",
      width:"31px"
    },
  },
  "& .starImg":{
      height:"42px",
      width:"42px",
    "@media (max-width: 760px)": {
      height:"29.88px",
      width:"31px"
    },
  },
  "& .label": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  },
  "& .main-label": {
    fontWeight: 600,
    fontSize: 24,
    lineHeight:'36px',
    color: "#1C1B1B"
  },
  "& .sub-label": {
    fontWeight: 400,
    fontSize: 16,
    color: "#484646"
  },
  "& .description-box": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  },
  "& .submit-box": {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 760px)": {
      justifyContent: "center",
    },
  },
  "& .btn-submit": {
    textTransform: "none",
    width: "300px",
    height: "48px",
    padding: "16px 120px 16px 120px",
    gap: "4px",
    borderRadius: "88px",
  },
  "& .MuiFormHelperText-root": {
    color: "red"
  },
  "& .btn-done":{
    textTransform: "none",
    background: "green",
    width: "300px",
    height: "48px",
    padding: "16px 120px 16px 120px",
    gap: "4px",
    borderRadius: "88px",
    color: "white"
  },
})
