import React, { useRef } from "react";
import {
    Box,
    Button, IconButton,
    Paper, Typography
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';

const play1 = require("./assets/Object.png")
const img3 = require("./assets/courseimage5.png")
const group = require("./assets/Group 8.png")
const shop = require("./assets/image_Bitmap Copy 4.png")

interface Props {
    style: any;
    addCartItem: (id: number, type: string) => void;
    postXpandLibraryData: (id: number, type: string) => void;
    handleNavigate: (type: string, id: any, pathname: string, state: any) => void;
    XpandData: any;
    props: any;
    postplayButton: (id: any, type: string) => void;
    handleSubscription: any
}

export default function XpandSlider({ style, XpandData, handleNavigate, props, postXpandLibraryData, postplayButton, addCartItem, handleSubscription }: Props) {
    let slider = React.createRef<any>();

    const HandleChange = (data: any) => {
        if (data?.attributes?.expand_type === "Course") {
            handleNavigate("Course", data.id, `/course-detail/${data?.attributes?.expand_type}/${data.id}/${data?.attributes?.title.replace(/\s/g, '-')}`, { name: data })
        } else if (data?.attributes?.expand_type === "Program") {
            handleNavigate("Program", data.id, `/program-detail/${data?.attributes?.expand_type}/${data.id}/${data?.attributes?.title.replace(/\s/g, '-')}`, { name: data })
        }
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    return (
        <>
            {XpandData?.collections?.data.length !== 0 ?
                <Slider {...settings}
                    ref={(c: any) => (
                        // @ts-ignore
                        slider = c
                    )}
                    className="slider1">
                    {XpandData?.collections?.data.map((data: any, index: any) => (
                        // <ImgMediaCard4 ele={data} key={index}/>
                        <Box key={index}>
                            <Box style={styles.IconButton} >
                                <CardMedia
                                    style={styles.NavigateNextOutlinedIcon}
                                    image={data?.attributes?.thumbnail_image?.url}
                                />
                                <Paper style={styles.course_box}>
                                    <Typography
                                        style={styles.course_h3}
                                        onClick={() => HandleChange(data)}>
                                        {data?.attributes?.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p"
                                        style={styles.img_play}>
                                        {data?.attributes?.created_by}
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p"
                                        style={styles.img_card}>
                                        Due in {data?.attributes?.course_timeline} Days
                                    </Typography>

                                    {data?.attributes?.is_paid ?
                                        <>
                                            <Card style={styles.group_img}>
                                                {data?.attributes?.subscription_period === "one_time_purchase" ?
                                                    <CardActionArea onClick={() => addCartItem(data?.id, data?.attributes?.expand_type)}>
                                                        <img src={shop} style={styles.group_cardImg} />
                                                    </CardActionArea> :
                                                    <CardActionArea onClick={() => addCartItem(data?.id, data?.attributes?.expand_type)}>
                                                        <Typography style={styles.shop_img}>Subscribe</Typography>
                                                    </CardActionArea>
                                                }
                                            </Card>
                                        </>
                                        :
                                        <> <Card style={styles.card_shop}>
                                            <CardActionArea onClick={() => postXpandLibraryData(data?.id, data?.attributes?.expand_type)}>
                                                <img src={group} style={styles.typo_due} />
                                            </CardActionArea>
                                        </Card>

                                            <Card style={styles.typo_create}>
                                                <CardActionArea onClick={() => HandleChange(data)}>
                                                    <img src={play1} style={styles.typo_title} />
                                                </CardActionArea>
                                            </Card>
                                        </>}
                                </Paper>
                            </Box>
                        </Box>
                    ))}
                </Slider>
                :
                <><h2 style={styles.paper_data}>No Courses available</h2></>}
        </>
    )
}

const styles = {
    IconButton: {
        padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' 
    },
    course_box: {
        width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' 
    },
    course_h3: {
        width: '100%', minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' 
    },
    NavigateNextOutlinedIcon: {
        height: "240px", width: '100%', borderRadius: '25px 25px 0 0' 
    },
    img_play: {
        margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' 
    },
    img_card: {
        margin: 'auto', fontSize: '12px', marginBottom: '10px', padding: '0 17px', color: '#44444F'
    },
    group_img: {
        width: '87px', height: '45px', borderRadius: '30px', float: 'right' as 'right',
        marginTop: '-2px', marginRight: '20px', background: '#1D1F4F'
    },
    group_cardImg: {
        margin: 'auto', padding: '27px', display: 'flex', marginTop: '-21px', width: '100%', height: 'auto' 
    },
    shop_img: {
        display: 'flex', width: '100%', lineHeight: '45px', justifyContent: 'center', color: '#fff', fontSize: '13px', fontWeight: 600 
    },
    card_shop: {
        width: '87px', height: '45px', borderRadius: '30px', float: 'left' as 'left',
        marginTop: '-2px', marginLeft: '25px', background: '#EF2B4B'
    },
    typo_due: {
        margin: 'auto', padding: '15px', display: 'flex' 
    },
    typo_create: {
        width: '87px', height: '45px', borderRadius: '30px', float: 'right' as 'right',
                                                marginTop: '-2px', marginRight: '25px', background: '#6C328B'
    },
    typo_title: {
        margin: 'auto', padding: '8px', display: 'flex' 
    },
    paper_data: {
        textAlign: 'center' as 'center', marginTop: '10%' 
    },
}