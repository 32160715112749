import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
// Customizable Area Start
import { BackImg, UserImage, Starts,Reward ,Arrow} from "../assets";
import Loader from "../../../../components/src/Loader";
// Customizable Area End

import AchievementListingController, {
  Props,
} from "./AchievementListingController.web";

export default class AchievementCongratulations extends AchievementListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const currentUrl = window?.location?.href;
    const url = new URL(currentUrl);
    const id = url.pathname.split("/").pop(); // Extract id from the URL
 
    if(id){
     await this.achievementDetails(id)
    }
    else{
      this.props.history.push("/AchievementListing")
    }
   }
  // Customizable Area End
 
  render() {
       return (
      // Customizable Area Start
      <>
       <Box style={{ display: 'flex', margin: '0px 40px 20px 10px' }}>
          <Box style={{ marginTop: '8px' }}>
            <img src={Arrow}></img>
          </Box>
          <Typography
            variant='h5'
            style={{ fontWeight: 700, fontSize: '48px' }}
          >
            {'Achievements'}
          </Typography>
        </Box>
      <Container maxWidth="lg" style={{ display: "flex" }}>
        <Box
          style={{  
            width: "100%",
            marginTop: "15px",
            background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              style={{margin: "auto", display: "block" }}
            >
            <img src={BackImg}></img>
            </Box>
            <Box
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "absolute",
                bottom: "0",
                left: "0",
                right: "0",
              }}
            >
              <Box
                style={{ margin: "auto", display: "block" }}
              >
                <img src={UserImage}></img>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img width={122} height={128}
               src={this.state?.achievementDetailsData?.data?.attributes?.achievement?.attributes?.thumbnail_image?.url || Starts}></img> 
                <Box style={{ textAlign: "center" }}>
                  <Typography
                    style={{
                      width: "100%",
                      display: "block",
                      color: "#1A1A1A",
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                   {
                  this.state?.achievementDetailsData?.data?.attributes
                    ?.achievement?.attributes?.name
                }
                  </Typography>
                  <Typography
                    style={{
                      width: "100%",
                      display: "block",
                      color: "#6C328B",
                      fontSize: "32px",
                      fontWeight: 700,
                    }}
                  >
                    Congratulations!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg" style={{ display: "flex", marginTop: "30px" }}>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Box>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: "10px",
                    fontWeight: 700,
                    fontSize: "24px",
                  }}
                >
                  Achievement description
                </Typography>
                <Typography
                  component="p"
                  /*  */style={{
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                    {this.state?.achievementDetailsData?.data?.attributes?.achievement?.attributes?.description}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: "10px",
                    fontWeight: 700,
                    fontSize: "24px",
                  }}
                >
                  To earn this achievement
                </Typography>
                {this.state?.achievementDetailsData?.data?.attributes?.statements?.map((val: any, index: any) => (
                      <Typography
                      key={index + 1}
                      component="p"
                      style={{ width: "100%", display: "block" }}
                    >
                      {index + 1 + ". " + val.text}
                    </Typography>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                style={{
                  background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
                  padding: "20px",
                  borderRadius: "16px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <Box>
                <img src={this.state?.achievementDetailsData?.data?.attributes?.rewards?.image || Reward}
                      width={'100px'}
                      height={"100px"}
                      alt="icon" />
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700, fontSize: "24px" }}
                  >
                   {this.state?.achievementDetailsData?.data?.attributes?.rewards?.title || "Reward"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      marginTop: "5px",
                    }}
                  >
                  {this.state?.achievementDetailsData?.data?.attributes?.rewards?.text}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      color: "#6C328B",
                      marginTop: "12px",
                    }}
                  >
                    Congratulations!
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {this.state.isloading ? <Loader loading={this.state.isloading} /> : null}
      </Container>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
