import React from "react";
import { Grid } from "@material-ui/core";
import CartItems from "./CartItems.web";
import Payments from "../../../stripepayments/src/Payments.web";
import SubscriptionCartItems from "./SubscriptionCartItems.web";
import { SelectedSubsCartItem } from "../ShoopingController.web";
import OrderSummary from "./OrderSummary.web";
interface ICart {
  cartType: string;
  cartItems: any;
  payableAmount: number;
  subscriptionCartItems: any;
  orderConfirmed: boolean;
  onRemoveItemFromCart: (id: any) => void;
  selectedSubsCartItem: SelectedSubsCartItem;
  onPaymentSuccess: (msg: string) => void;
  onPaymentFailure: (msg: string) => void;
  handleSubsCartItemsSelection: (item: SelectedSubsCartItem) => void;
  handleOrderConfirmation: () => void;
  checkoutProceed: ()=> Promise<void>;
}

export default function Cart({
  cartType,
  cartItems,
  subscriptionCartItems,
  orderConfirmed,
  onRemoveItemFromCart,
  payableAmount,
  onPaymentSuccess,
  onPaymentFailure,
  selectedSubsCartItem,
  handleSubsCartItemsSelection,
  handleOrderConfirmation,
  checkoutProceed
}: ICart) {
  return (
    <>
      <Grid container spacing={2} style={webStyle.flexCenter}>
        <Grid item lg={6} md={8} sm={12}>
            <CartItems
              cartItems={cartItems}
              onRemove={onRemoveItemFromCart}
              payableAmount={payableAmount}
              orderConfirmed={orderConfirmed}
              checkoutProceed={checkoutProceed}
              subscriptionCartItems={subscriptionCartItems}
              />
        </Grid>
      </Grid>
    </>
  );
}


const webStyle = {
  rootBox:{
    maxWidth:"843px"
  },
  flexCenter:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
}