import React from "react";
// Customizable Area Start
import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import { CheckRewards, RightArrow, Arrow }from "../assets";
// Customizable Area End
import AchievementListingController, {
  Props,
} from "./AchievementListingController.web";
import Loader from "../../../../components/src/Loader";

export default class AllRewardsListing extends AchievementListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <>
       <Box style={{ display: 'flex', margin: '0px 40px 20px 10px' }}>
          <Box style={{ marginTop: '8px' }}>
            <img src={Arrow}></img>
          </Box>
          <Typography
            variant='h5'
            style={{ fontWeight: 700, fontSize: '48px' }}
          >
            {'All Rewards'}
          </Typography>
        </Box>
      <Container
        maxWidth="lg"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          style={{
            width: "100%",
            marginTop: "15px",
            background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, fontSize: "24px" }}
              >
                Most recent reward
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: "100%", mt: "15px" }}>
            <Grid container spacing={3} style={{ alignItems: "center" }}>
              <Grid item xs={3}>
                <Box
                  style={{
                    background: "#EBE3EF",
                    borderRadius: "14px",
                    height: "238px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "18px",
                      color: "#6C328B",
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                 { this.state?.allRewardsListingData?.latest_reward?.reward?.attributes?.name}
                  </Typography>
                  <Box
                    sx={{ width: "116px" }}
                  >
                    <img width={110} height={110} src={this.state?.allRewardsListingData?.latest_reward?.reward?.attributes?.thumbnail_image?.url||CheckRewards}></img>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#6C328B",
                        fontWeight: 600,
                        marginTop:20,
                      }}
                    >
                      {this.state?.allRewardsListingData?.latest_reward?.reward?.attributes?.reward_status}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
             
              <Grid item xs={3}>
                <Box sx={{ height: "160px" }}></Box>
                <Box sx={{ display: "flex", justifyContent: "end" ,width:800}}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "32px",
                      width: "112px",
                      borderRadius: "30px",
                      boxShadow: "none",
                      background: "#6C328B",
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            marginTop: "30px",
            background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700, fontSize: "24px" }}
                >
                  All Rewards
                </Typography>
              </Box>
              {  this.state?.viewAll ? 
                <Box onClick={()=>{this.viewAllset()}}>
                <Typography
                  style={{
                    color: "#6C328B",
                    fontSize: "16px",
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  View All 
                  <Box
                    style={{
                      transition: "transform 0.3s ease",
                      transform: "rotate(0deg)"
                    }}
                  >
                    <img src={RightArrow}></img>
                  </Box>
                </Typography>
              </Box>
              :
              <Box onClick={()=>{this.viewLessSet()}}>
              <Typography
                style={{
                  color: "#6C328B",
                  fontSize: "16px",
                  fontWeight: 600,
                  display: "flex",
                }}
              >
                View All 
                <Box
                  style={{
                    transition: "transform 0.3s ease",
                    transform: "rotate(90deg)"
                  }}
                >
                  <img src={RightArrow}></img>
                </Box>
              </Typography>
            </Box>
              }
            </Box>
            <Box style={{ marginTop: "60px" }}>
              <Grid container spacing={8}>
                {
                  this.state.allRewardsListingData?.data?.slice(0, this.state?.arrayLen)?.map((val:any)=>(
                    <Grid item xs={6} key={val?.id}>
                    <Box
                    style={{
                      background: "#fff",
                      boxShadow: "0px 0px 16px 0px rgba(191, 190, 190, 0.35)",
                      borderRadius: "16px",
                      padding: "24px",
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                      position: "relative",
                    }}
                  >
                    <Box>
                    <Box
                    sx={{ width: "50px", height: "50px" }}
                        >
                <img src={val?.attributes?.reward.attributes?.thumbnail_image?.url} width={72} height={72}></img>
                        </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 600,
                          color: "#11142D",
                          fontSize: "18px",
                          marginBottom: "5px",
                        }}
                      >
                       {val?.attributes?.reward.attributes?.name}
                      </Typography>
                      <Typography
                        style={{
                          width: "100%",
                          display: "block",
                          color:val?.attributes?.reward?.attributes?.status === "progress"?"#FF8B3F" :val?.attributes?.reward?.attributes?.status === "earned"?"#6C328B":"#9F9F9F",
                          fontSize: "16px",
                        }}
                      >
                        {val?.attributes?.reward_status}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "24px",
                        right: "24px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: "32px",
                          borderRadius: "30px",
                          boxShadow: "none",
                          background: "#FF8B3F",
                        }}
                        onClick={()=> this.rewardsView(val?.id)}
                      >
                        View
                      </Button>
                    </Box>
                  </Box>
                  </Grid>
                  ))
                }
              </Grid>
            </Box>
          </Box>
        </Box>
        {this.state.isloading ? <Loader loading={this.state.isloading} /> : null}
      </Container>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
