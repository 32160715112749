import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import * as H from "history";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
const navigation = require("react-navigation");
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { ChangeEvent } from "react";

interface User {
name: string;
age: number;
email: string;
}

 export interface UserRewardListing {
    id: number;
    type: string;
    attributes: {
      id: number;
      user_name: string;
      branch_name: string | null;
      user_progress: string | string;
      redemption_status: boolean;
      profile_image: {
          id: number,
          url: string
      },
    };
  };
  
interface RewardListData {
    data: UserRewardListing[];
};

interface StatusSuccess {
    message: string
}

interface ErrorStatus {
    errors: Array<StatusSuccess>
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: typeof navigation;
    navigation: typeof navigation;
    // Customizable Area End
}

interface CheckboxSelectedListType { value: string, id: string }

interface UserBranchesListing {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    };
  }

  interface ExpertListing {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      email: string;
      profession: string;
      full_name: string;
      avatar: null;
      is_admin: boolean;
    };
  }
interface S {
    // Customizable Area Start
    orderDirection: string;
    orderBy: keyof User | string;
    rewardsData: RewardListData;
    rewardId: string;
    rewardName: string;
    isLeave: boolean;
    anchorFilterContainerEl: null | HTMLElement;
    placeholderSelectedItems:Array<CheckboxSelectedListType>;
    placeholderSearchText: string;
    anchorEl: null | HTMLElement;
    dropdownType: string;
    isCalendarOpen: boolean;
    placeholderParams: string;
    searchInputValue: string;
    showList: boolean;
    filteredList: Array<string>;
    placeholderSearchBranchText: string;
    placeholderSelectsBranchItems: Array<CheckboxSelectedListType>;
    userBranchListParams: string;
    userBranchList: Array<UserBranchesListing>;
    expertAdminList: Array<ExpertListing>;
    redeemptionStatus:string;
    userProgress:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateRewardController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    getRewardsDataCallId: string = "";
    putChangeStatusCallId: string = "";
    getAchievementDataCallId: string = "";
    GetBranchListApiCallIds: string = "";
    expertAdminListingAPICallId: string = "";
    userProgressOptions: {value:string; label:string;}[] = [];
    tableRowHeader:{label:string; key:string;}[];
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            orderDirection: "asc",
            orderBy: "name",
            rewardsData: {
                data: []
            },
            rewardId: "",
            rewardName: "",
            isLeave: false,
            anchorFilterContainerEl: null,
            placeholderSelectedItems: [],
            placeholderSearchText: '',
            anchorEl: null,
            dropdownType: "",
            isCalendarOpen: false,
            placeholderParams: "",
            searchInputValue: "",
            showList: false,
            filteredList: [],
            placeholderSearchBranchText: "",
            placeholderSelectsBranchItems: [],
            userBranchListParams: "",
            userBranchList:[],
            expertAdminList:[],
            userProgress:"",
            redeemptionStatus:"",
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.userProgressOptions = [
            {
                value: "redeemed",
                label:"Redeemed"
            },
            {
                value: "earned",
                label:"Earned"
            },
            {
                value: "progress",
                label:"In Progress"
            },
            {
                value: "pending",
                label:"UnStarted"
            },
        ];

        this.tableRowHeader = [
          {
            label:"Name",
            key:"user_name"
          },
          {
            label:"Branch name",
            key:"branch_name"
          },
          {
            label:"User progress",
            key:"user_progress"
          },
          {
            label:"Redemption status",
            key:"user_progress"
          },
        ];

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        const rowId = `${this.props.location.state.rewardId}`;
        const rowName = this.props.location.state.rewardName;
        const checkReward = this.props.location.state.isRewardsActive;

        this.setState({
            rewardId: rowId,
            rewardName: rowName
        }, () => {
            checkReward === "reward" ?
                this.getRewardsDataApiCall()
                :
                this.getAchievementDataApiCall();
        });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson) {
                if (responseJson.data || responseJson.message) {
                    this.apiSuccessFunctions(apiRequestCallId, responseJson);
                }
                if (responseJson.errors) {
                    this.apiFailureFunctions(apiRequestCallId, responseJson);
                }
            }
        }
    }

    apiSuccessFunctions = async (apiCallId: string, response: any) => {
      if(apiCallId === this.getRewardsDataCallId){
        this.getRewardsDataSuccess(response)
      }
      if(apiCallId === this.putChangeStatusCallId){
        this.putChangeStatusSuccess(response)
      }
      if(apiCallId === this.getAchievementDataCallId){
        this.getAchievementDataSuccess(response)
      }
      if (this.GetBranchListApiCallIds === apiCallId) {
        this.setState({ userBranchList: [...response.data] });
      }
      if (this.expertAdminListingAPICallId === apiCallId) {
        this.setState({
          expertAdminList: response.data,
        });
      }
    };
    

    apiFailureFunctions = async (apiCallId: string, response: ErrorStatus & object) => {
        if(apiCallId === this.putChangeStatusCallId){
            this.putChangeStatusFailure(response)
        }
    };

    rewardApiCallId = async (valueData: {
        body?: {};
        type?: string;
        contentType?: string;
        method?: string;
        endPoint?: string;
    }) => {
        let token = localStorage.getItem("token");
        let { contentType, method, endPoint, body, type } = valueData;
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        let header = {
            "Content-Type": contentType,
            token,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    handleSortAsc = (property: keyof UserRewardListing['attributes']) => {
        this.setState({
          orderDirection: "asc",
          orderBy: property,
        },()=>{
                const sortedUsers = this.state.rewardsData.data.sort((a, b) => {
                return `${a?.attributes[property]}`?.localeCompare(`${b?.attributes[property]}`);
        });
            this.setState({rewardsData:{data:sortedUsers}})
        });
    };
    
    handleSortDesc = (property: keyof UserRewardListing['attributes']) => {
        this.setState({
          orderDirection: "desc",
          orderBy: property,
        },()=>{
            const sortedUsers = this.state.rewardsData.data.sort((a, b) => {
                return `${b?.attributes[property]}`?.localeCompare(`${a?.attributes[property]}`);
            });
            this.setState({rewardsData:{data:sortedUsers}})
        });
    }; 
      
    getRewardsDataApiCall = async (params:string='') => {
        this.getRewardsDataCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            endPoint: params ? `${configJSON.manageRewardsEndPoint}?id=${this.state.rewardId}&${params}`:`${configJSON.manageRewardsEndPoint}?id=${this.state.rewardId}`
        });
    };

    getRewardsDataSuccess = (response: RewardListData) => {  
      if (this.state.showList) {
        const searchList = response.data.map(item => item.attributes.user_name);
        this.setState({ filteredList: searchList })
      } else {
        this.setState({ rewardsData: response });
      }
    };

    handleBackAchievement = () => {
        this.props.history.push("/RewardsAndAchievement")
    };

    putChangeStatusApiCall = async (id: number) => {
        this.putChangeStatusCallId = await this.rewardApiCallId({
            method: configJSON.examplePutMethod,
            contentType: configJSON.exampleApiContentType,
            endPoint: `${configJSON.changeRewardStatusEndPoint}?id=${id}`
        });
    };

    putChangeStatusSuccess = (response: StatusSuccess) => {
     if(response.message){
       toast.success("Status update successfully")
     }  
      this.getRewardsDataApiCall();
    };

    putChangeStatusFailure = (errorResponse: ErrorStatus) => {
        if (errorResponse.errors.length > 0) {
            errorResponse.errors.forEach((error) => {
                toast.error(error.message, { autoClose: 3000 })
            });
        }
    };

    getAchievementDataApiCall = async (params:string = '') => {
        this.getAchievementDataCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            endPoint: params ? `${configJSON.manageAchievementEndPoint}?id=${this.state.rewardId}&${params}` : `${configJSON.manageAchievementEndPoint}?id=${this.state.rewardId}`
        });
    };

    getAchievementDataSuccess = (response: RewardListData) => { 
     this.setState({rewardsData: response});
    };

    filterContainerDropdownCloseHandler = () => {
        this.setState({
            anchorFilterContainerEl: null,
        });
    };

    handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            anchorFilterContainerEl: event.currentTarget,
        });
    };

    clearFilterHandler = () => {
        this.setState({
            anchorFilterContainerEl: null,
            placeholderSelectedItems: [],
            placeholderSearchText: '',
            anchorEl: null,
            dropdownType: "",
            isCalendarOpen: false,
            placeholderParams: "",
            placeholderSearchBranchText: '',
            userBranchListParams:"",
            placeholderSelectsBranchItems: [],
            userProgress:"",
            redeemptionStatus:"",
        }, () => {
          const checkIsReward = this.props.location.state.isRewardsActive === 'reward';
          if (checkIsReward) {
            this.getRewardsDataApiCall(this.state.searchInputValue ? `search=${this.state.searchInputValue}` : '')
          } else {
            this.getAchievementDataApiCall(this.state.searchInputValue ? `search=${this.state.searchInputValue}` : '');
          }
        })
    }

    dropdownOpenHandler = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
        this.setState({
          anchorEl: event.currentTarget,
          dropdownType
        })
      }
    
      dropdownCloseHandler = () => {
        this.setState({
          anchorEl: null,
          dropdownType: "",
          isCalendarOpen: false
        })
      }

      clickFilterHandler = () => {
        const {userProgress, redeemptionStatus, searchInputValue} = this.state
        const checkIsReward = this.props.location.state.isRewardsActive === 'reward';
        const params = [];
      
        if(searchInputValue.length > 0){
          params.push(`search=${searchInputValue}`)
        }

        if(userProgress.length > 0){
          params.push(`q[status][]=${userProgress}`)
        }

          if (checkIsReward) {
            if(redeemptionStatus.length > 0){
              params.push(`q[status][]=${redeemptionStatus}`)
            }
            this.getRewardsDataApiCall(params.length > 0 ? params.join('&'):'')
          } else {
            this.getAchievementDataApiCall(params.length > 0 ? params.join('&'):'');
          }
        this.setState({
          anchorFilterContainerEl: null,
        });
      };

      placeholderCheckboxUserManageChangeBranchHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string
      ) => {
        const { checked, name } = event.target;
        if (checked) {
          this.setState(
            (prevState) => ({
              placeholderSelectsBranchItems: [
                ...prevState.placeholderSelectsBranchItems,
                { value: name, id: itemId },
              ],
            }),
            () => {
              const params = this.state.placeholderSelectsBranchItems
                .map(
                  (item: { value: string; id: string }) =>
                    `q[branch_ids][]=${item.id}`
                )
                .join("&");
              this.setState({
                userBranchListParams: params,
              });
            }
          );
        } else {
          this.setState(
            (prevState) => ({
              placeholderSelectsBranchItems:
                prevState.placeholderSelectsBranchItems.filter(
                  (item: { value: string; id: string }) => item.id !== itemId
                ),
            }),
            () => {
              const params = this.state.placeholderSelectsBranchItems
                .map(
                  (item: { value: string; id: string }) =>
                    `q[branch_ids][]=${item.id}`
                )
                .join("&");
              this.setState({
                userBranchListParams: params,
              });
            }
          );
        }
      };

      placeholderUserBranchSearchChangesHandler = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState(
          {
            placeholderSearchBranchText: event.target.value,
          },
          () => {
            this.placeholderDebouncedBranchHandler();
          }
        );
      };

      fetchBranchList(data: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
      }) {
        const { contentType, method, endPoint } = data;
        const header = {
          "Content-Type": contentType,
          token: localStorage.getItem("token"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        this.GetBranchListApiCallIds = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      placeholderDebouncedBranchHandler: () => void = debounce(
        () => this.fetchBranchList({
            contentType: configJSON.validationApiContentType,
            method: configJSON.apiMethodTypeGet,
            endPoint:
              configJSON.BranchesListingApiEndPoint +
              `?search=${this.state.placeholderSearchBranchText}`,
          }),
        700
      );

      handleplaceholderCheckboxAssignedExpertChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string
      ) => {
        const { checked, name } = event.target;
        if (checked) {
          this.setState(
            (prevState) => ({
              placeholderSelectedItems: [
                ...prevState.placeholderSelectedItems,
                { value: name, id: itemId },
              ],
            }),
            () => {
              const params = this.state.placeholderSelectedItems
                .map(
                  (item: { value: string; id: string }) =>
                    `q[expert_ids][]=${item.id}`
                )
                .join("&");
              this.setState({
                placeholderParams: params,
              });
            }
          );
        } else {
          this.setState(
            (prevState) => ({
              placeholderSelectedItems: prevState.placeholderSelectedItems.filter(
                (item: { value: string; id: string }) => item.id !== itemId
              ),
            }),
            () => {
              const params = this.state.placeholderSelectedItems
                .map(
                  (item: { value: string; id: string }) =>
                    `q[expert_ids][]=${item.id}`
                )
                .join("&");
              this.setState({
                placeholderParams: params,
              });
            }
          );
        }
      };

      fetchAdminExpertList(data: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
      }) {
        const { contentType, method, endPoint } = data;
        const header = {
          "Content-Type": contentType,
          token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        this.expertAdminListingAPICallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      placeholderDebouncedHandler: () => void = debounce(
        () => this.fetchAdminExpertList({
            contentType: configJSON.validationApiContentType,
            method: configJSON.apiMethodTypeGet,
            endPoint:
              configJSON.expertAdminListApiEndPoint +
              `?search=${this.state.placeholderSearchText}`,
          }),
        700
      );
    
      placeholderAssignedSearchChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState(
          {
            placeholderSearchText: event.target.value,
          },
          () => {
            this.placeholderDebouncedHandler();
          }
        );
      };

      inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const checkIsReward = this.props.location.state.isRewardsActive === 'reward';
        
        if (value === "") {
          if (checkIsReward) {
            this.getRewardsDataApiCall()
          } else {
            this.getAchievementDataApiCall();
          }
        }
        this.setState(
          {
            searchInputValue: value,
            showList: value !== "",
          },
          () => {
            if (value !== "") {
              if (checkIsReward) {
                this.getRewardsDataApiCall(`search=${value}`)
              } else {
                this.getAchievementDataApiCall(`search=${value}`);
              }
            }
          }
        );
      };

      searchListItemPressHandler = (selectedItem:string)=>{
        const checkIsReward = this.props.location.state.isRewardsActive === 'reward';

        this.setState({searchInputValue:selectedItem, showList:false})
        if(checkIsReward){
          this.getRewardsDataApiCall(`search=${selectedItem}`)
        }else {
          this.getAchievementDataApiCall(`search=${selectedItem}`);
        }
      }

      redeemptionStatusHandler=(status:string)=>{
        this.setState({redeemptionStatus:status});
      }

      userProgressHandler=(status:string)=>{
        this.setState({userProgress:status});
      }

      editNavigationHandler = () =>{
        const {rewardId} = this.state;
        this.props.navigation.navigate("EditReward",{rewardId})
      }

      editAchievementNavigationHandler = () =>{
        const {rewardId} = this.state;
        this.props.navigation.navigate("EditAchievement",{achievementId:rewardId})
      }

    // Customizable Area End
}