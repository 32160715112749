import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  root: {
    "&$checked": {
      color: "#6C328E",
    },
    "&$indeterminate": {
      color: "#6C328E",
    },
  },
  checked: {},
  indeterminate: {},
});

export default function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      {...props}
      color="default"
      classes={{
        root: classes.root,
        checked: classes.checked,
        indeterminate: classes.indeterminate,
      }}
    />
  );
}
