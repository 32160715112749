import React from 'react';
import {
    Button, Grid, InputAdornment,
    // Customizable Area Start
    Paper, Switch, Tooltip
    // Customizable Area End
    ,
    TextField, Typography, Box
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ArrowForwardIos";
import ManageContentFolderController from "./ManageContentFolderController.web";
import { Search } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import './style.css';
import './ContentManagement.css';

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: '12px',
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: "none"
        },
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: '12px',
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56
        }
    },
    content: {
        "&$expanded": {
            margin: "12px 0"
        }
    },
    expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '5px',
        display: 'block'
    }
}))(MuiAccordionDetails);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
        width: '250px',
        border: '15px',
        borderRadius: '15px',
        padding: '15px 0px',
        height: 'auto'
    },
}))(Tooltip);

// Customizable Area Start
const Vector = require("../assets/Vector.png");
const video = require("../assets/videoFolder.png");
const videoIcon = require("../assets/videoIcon.png");
const arrow = require("../assets/VectorLessthen.png");
const Dots = require("../assets/GroupDots.png");
const playButton = require("../assets/Group190.png")
const delet = require("../assets/delete.png");
const dwonload = require("../assets/dwonload.png")
const upload = require("../assets/upload.png")
const profile = require("../assets/share.png")
const copylink = require("../assets/copylink.png")
const Rename = require("../assets/Rename.png")
const lock = require("../assets/lockFolder.png")
const lock1 = require("../assets/ei_lock.png")
const trash = require("../assets/trash-can.png")
const trash1 = require("../assets/white_delete.png")
const close = require("../assets/imagenav_close.png")
const close1 = require("../assets/close-circle.png")
const folder = require("../assets/Vector2.png");
const Vector1 = require("../assets/3dot.png");
// Customizable Area End

export default class ManageContentFolder extends ManageContentFolderController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End


    render() {
        let last = this.state.allVideoData?.meta;
        let rename = last && last[last.length - 1];
        const { allSelected, selectedItems } = this.state;
        return (
            <div style={this.props.open === true ? { margin: 'auto auto -40px' } : { margin: 'auto' }}>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={Styles.mainTitle}>{this.props.open === true ? "Add Video" : "Manage Content Library"}</Typography>
                    </Grid>
                    <Grid container spacing={2} style={Styles.list} className="list">
                        <Grid item lg={6} md={6} sm={12} xs={12} className="search-outline">
                            <TextField
                                fullWidth
                                data-test-id="btn_change"
                                id="search"
                                name="search"
                                variant="outlined"
                                placeholder="Search"
                                onChange={this.getSearchData}
                                value={this.state.search}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {this.props.open === true ? "" :
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Button data-test-id="btn_tostSuccessfully" style={Styles.commanButton} variant="outlined" onClick={() => this.tostSuccessfully()}>View Quiz Bank</Button>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Button data-test-id="btn_addNewVideo" style={Styles.commanButton} variant="outlined" onClick={() => this.addNewVideo()} >+ New Video</Button>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Accordion
                                            square
                                            expanded={this.state.expanded === "panel2"}
                                            onChange={this.handleChangeExpanded("panel2")}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2d-content"
                                                id="panel2d-header"
                                            >
                                                <Typography>Bulk Actions</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Button data-test-id="btn_tostSuccessfully1" style={Styles.commanButton1} variant="outlined" onClick={() => this.tostSuccessfully()}><img style={Styles.BulkImg} src={upload} /> Bulk Upload</Button>
                                                {/* <Button style={Styles.commanButton1} variant="outlined" onClick={() => this.showLockButtons()}><img style={Styles.BulkImg} src={lock} /> Lock</Button> */}
                                                <Button data-test-id="btn_deleteData" style={Styles.commanButton1} variant="outlined" onClick={() => this.showDeleteButtons()}><img style={Styles.BulkImg} src={trash} /> Delete</Button>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid style={{ marginTop: '10px' }} container spacing={2}>
                        <Grid item lg={9}></Grid>
                        <Grid item lg={3}>
                            <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {this.state.showDeleteButtons && (
                                    <>
                                        <Button data-test-id="btn_handleOpenBulkdelete"
                                            style={Styles.commanButton12}
                                            variant="outlined"
                                            onClick={() => this.handleOpenBulkdelete()}
                                        >
                                            <img style={Styles.BulkImg} src={trash1} /> Delete
                                        </Button>
                                        <Button
                                            style={Styles.commanButton13}
                                            test-id="cancel"
                                            variant="outlined"
                                            onClick={() => this.cancelAction()}
                                        >
                                            <img style={Styles.BulkImg} src={close1} /> Cancel
                                        </Button>
                                    </>
                                )}
                                {this.state.showLockButtons && (
                                    <>
                                        <Button
                                            style={Styles.commanButton12}
                                            variant="outlined"
                                            test-id="success"
                                            onClick={() => this.tostSuccessfully()}
                                        >
                                            <img style={Styles.BulkImg} src={lock1} /> Lock
                                        </Button>
                                        <Button
                                            style={Styles.commanButton13}
                                            variant="outlined"
                                            test-id="cancel2"
                                            onClick={() => this.cancelAction()}
                                        >
                                            <img style={Styles.BulkImg} src={close1} /> Cancel
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Modal
                            open={this.state.open1}
                            // onClose={this.handleClosedelete}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <Paper style={Styles.deleteModal}>
                                <Box>
                                    <Typography style={Styles.DeleteForever}>Bulk Delete</Typography>
                                    <Typography style={Styles.permanently}>You are deleting multiple videos and their content at the same time...</Typography>
                                </Box>
                                <Box style={Styles.buttonMDelet1}>
                                    <Button
                                        style={Styles.Delete}
                                        variant="outlined"
                                        test-id="BulkDelete_folder"
                                        onClick={() => this.BulkDelete_folder()}
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        style={Styles.Cancel}
                                        variant="outlined"
                                        test-id="handleCloseBulkdelete"
                                        onClick={() => this.handleCloseBulkdelete()}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Paper>
                        </Modal>
                    </Grid>


                    <Grid container style={Styles.list}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box style={Styles.divDot}>
                                <Typography data-test-id="btn_navigetToMainPage" onClick={() => this.NavigatToMainPage()} style={Styles.folderList}>Folders</Typography>
                                {this.state.allVideoData?.parent_folder_data?.map((data: { id: number, name: string }, index: number) => {

                                    return (
                                        <>
                                            <img style={Styles.dotImg} src={arrow} />
                                            <Typography data-test-id="btn_backTofolder" onClick={() => this.backToCurrentFolder(data.id)} style={Styles.dotText}>{data.name}</Typography>
                                        </>
                                    )
                                })}
                                {/* <img onClick={() => this.ModalhandleOpen(rename)} style={Styles.Dots} src={Dots} /> */}
                                <LightTooltip enterDelay={500}
                                    // leaveDelay={1000000}   
                                    onClose={this.ModalhandleClose}
                                    interactive={true}
                                    title={<CardDetail tostSuccessfully={this.tostSuccessfully}
                                        RenameHandleOpen={this.RenameHandleOpen} DeleteFolderAPI={this.DeleteFolderAPI}
                                    // rowData={undefined} ref={undefined} onMouseEnter={undefined} openTool={this.state.Modalopen}                                  //    onMouseEnter={handleMouseEnter} ref={learnMoreButtonRef} rowData={row}
                                    />}
                                    // title="kkfkjkfhkh"
                                    placement="right-start">
                                    <img
                                        style={Styles.Dots}
                                        //@ts-ignore
                                        src={Dots}
                                        data-test-id="btn_modalOpen"
                                        onClick={() => this.ModalhandleOpen(rename)}
                                    />
                                </LightTooltip>

                            </Box>
                        </Grid>
                        {this.props.open === true ? "" :
                            <>
                                <Grid item lg={2} md={2} sm={4} xs={12}>
                                    <div style={Styles.vectorBox}>
                                        <div style={Styles.vectorBox2}>
                                            <img style={Styles.vectorImg} src={this.state.themeMode ? Vector : Vector1} />
                                            <Typography className='listText' style={this.state.themeMode ? Styles.listText : Styles.listText1}>List View</Typography>
                                        </div>
                                        <Switch data-test-id="btn_toggleNo" className="Switch" checked={this.state.themeMode} onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
                                    </div>
                                </Grid>
                                <Grid item lg={2} md={2} sm={4} xs={12}>
                                    <div style={Styles.vectorBox}>
                                        <div style={Styles.vectorBox2}>
                                            <img style={Styles.vectorImg} src={this.state.themeModeDelete ? Vector : Vector1} />
                                            <Typography className='listText1' style={this.state.themeModeDelete ? Styles.listText3 : Styles.listText2}>View Deleted</Typography>
                                        </div>
                                        <Switch data-test-id="btn_toggleNoDelete" className="Switch" checked={this.state.themeModeDelete} onChange={() => this.viewDelete()} />
                                    </div>
                                </Grid>
                                <Grid item lg={2} md={2} sm={4} xs={12}><Button disabled={
                                    this.state.createFolder.length > 15 || // Disable if the length is greater than 15
                                    (Array.isArray(this.state.allVideoData?.meta) && this.state.allVideoData?.meta.length !== 1 && this.state.allVideoData?.meta.length !== 2) // Disable if meta is not an array or its length is not 1 or 2
                                } data-test-id="btn_newFolder" onClick={() => this.dialogHandleOpen()} style={Styles.NewButton} variant="contained" color="primary" >Add New Folder</Button></Grid>
                            </>}
                    </Grid>


                    {(this.state.allXpandLibararyData?.videos?.data?.length <= 0) ?
                        ""
                        : <Box style={Styles.AllBox}>
                            <Checkbox
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                style={Styles.AllBoxCheck}
                                checked={allSelected}
                                onChange={() => this.toggleSelectAll()}
                            />
                            <Typography>Select All</Typography>
                        </Box>}
                    {this.state.themeMode ?
                        <></>
                        :
                        <Grid container spacing={2} style={Styles.GridData}>

                            {this.state.allXpandLibararyData?.data?.map((data: any, index: any) => (
                                <>
                                    <Grid key={index} item lg={3} md={4} sm={6} xs={12} ><Paper data-test-id={`btn_navigateNewScreen-${index}`} onClick={() => this.handleNavigate(data.id)} style={Styles.GridPaper}>
                                        <Grid container style={Styles.GridPed}>
                                            <Grid item lg={2} style={Styles.GridAuto}>
                                                <>
                                                    <img src={folder} />
                                                </>
                                            </Grid>
                                            <Grid item lg={8} style={Styles.GridAuto}>
                                                <Typography style={Styles.dataName}>{data.name}</Typography>
                                            </Grid>
                                            <Grid item lg={2} style={Styles.GridAuto}>
                                            </Grid>
                                        </Grid>
                                    </Paper></Grid>
                                </>
                            ))}

                        </Grid>
                    }
                    <Modal
                        open={this.state.dialogopen}
                        onClose={this.dialogHandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={Styles.modalDiv}>
                            <img src={close} onClick={this.dialogHandleClose} style={Styles.closeImg} />
                            <Typography style={Styles.closeText}>Create New Folder</Typography>
                            <Grid container spacing={4} style={Styles.Gridclose}>
                                <Grid item lg={9}>
                                    <TextField className='inputField' style={Styles.closeInput} error={this.state.createFolder.length > 15}
                                        helperText={this.state.createFolder.length > 15 ? '15 characters is required' : ''} value={this.state.createFolder} test-id="crateFolder" onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Button test-id="createNewFolder" onClick={() => this.createNewFolder()} style={Styles.closeButton} variant="contained" color="primary" >Create</Button>
                                </Grid>

                            </Grid>
                        </div>
                    </Modal>
                    <Grid container spacing={2} style={Styles.GridData}>
                        {(this.state.allXpandLibararyData?.videos?.data?.length <= 0 && this.state.allXpandLibararyData?.data?.length <= 0) && <Grid sm={12}><Typography style={{ textAlign: "center" }}>No data found</Typography></Grid>}
                    </Grid>
                    {/* <Dialog
                        open={this.state.Modalopen}
                        onClose={this.ModalhandleClose}
                        aria-labelledby="simple-dialog-title"
                        className='Edit_dialog'
                        hideBackdrop
                    >
                        <div style={Styles.dialogDiv}>
                            <div style={Styles.profileDiv} onClick={() => this.tostSuccessfully()}>
                                <img src={profile} />
                                <Typography style={Styles.proText}>Share</Typography>
                            </div>
                            <hr style={Styles.hr} />
                            <div onClick={() => this.tostSuccessfully()} style={Styles.linkDiv}>
                                <img src={copylink} />
                                <Typography style={Styles.proText}>Copy Link</Typography>
                            </div>
                            <hr style={Styles.hr} />
                            <div onClick={() => this.RenameHandleOpen()} style={Styles.linkDiv}>
                                <img src={Rename} />
                                <Typography style={Styles.proText}>Rename</Typography>
                            </div>
                            <hr style={Styles.hr} />
                            <div onClick={() => this.FolderLockAPI()} style={Styles.linkDiv}>
                                <img src={lock} />
                                <Typography style={Styles.proText}>Lock Folder</Typography>
                            </div>
                            <hr style={Styles.hr} />
                            <div onClick={() => this.DeleteFolderAPI()} style={Styles.linkDiv}>
                                <img src={trash} />
                                <Typography style={Styles.proText}>Delete Folder</Typography>
                            </div>
                        </div>
                    </Dialog> */}

                    <Modal
                        open={this.state.rename}
                        onClose={this.RenameHandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={Styles.EditModal}>
                            <img src={close} onClick={this.RenameHandleClose} style={Styles.EditImg} />
                            <Typography style={Styles.EditText}>Update Folder</Typography>
                            <Grid container spacing={4} style={Styles.EditGrid}>
                                <Grid item lg={9}>
                                    <TextField className='inputField' style={Styles.EditInput} value={this.state.editRename} test-id="editRename" onChange={(event: any) => this.setState({ editRename: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Button test-id="FolderRenameAPI" onClick={() => this.FolderRenameAPI()} style={Styles.EditButton} variant="contained" color="primary" >Update</Button>
                                </Grid>

                            </Grid>
                        </div>
                    </Modal>
                    {this.state.themeMode || this.state.themeModeDelete ?
                        <>
                            <Grid container style={Styles.headGrid}>
                                <Grid item lg={1}></Grid>
                                <Grid item lg={2}>
                                    <Typography style={Styles.headText}>Name</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={Styles.headText}>Created By</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={Styles.headText}>Total Time</Typography>
                                </Grid>
                                <Grid item lg={1}>
                                    <Typography style={Styles.headText}>Download</Typography>
                                </Grid>
                                <Grid item lg={4}>
                                    <Typography style={this.state.themeModeDelete ? Styles.headText1 : Styles.Right}>{this.state.themeModeDelete ? 'Delete Forever' : 'Delete'}</Typography>
                                    {this.state.themeModeDelete ? <Typography style={Styles.headText2}>Undo</Typography> : <></>}
                                </Grid>
                            </Grid>

                            {this.state.allXpandLibararyData?.videos?.data?.length > 0 ? (
                                this.state.allXpandLibararyData?.videos?.data?.map((data: any, index: any) => {
                                    /* istanbul ignore next */
                                    return (
                                        <>
                                            <Paper
                                                style={
                                                    selectedItems.includes(data.attributes.id)
                                                        ? Styles.selectedVideoCard1
                                                        : Styles.DataPaper
                                                }>
                                                <Grid container style={Styles.TableGrid}>
                                                    <Grid item lg={1} style={Styles.GridCenter}>
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            style={{ marginTop: '-8px', color: '#6C328B' }}
                                                            checked={selectedItems.includes(data.attributes.id)}
                                                            onChange={() => this.toggleItemSelect(data.attributes.id, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        <Typography style={Styles.GridText}> {data?.attributes?.title}</Typography>
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        <Typography style={Styles.GridText}>{data?.attributes?.created_by}</Typography>
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        <Typography style={Styles.GridText}>{data?.attributes?.video_size}</Typography>
                                                    </Grid>
                                                    <Grid item lg={1} style={{ textAlign: 'center' }}>
                                                        {
                                                            data?.attributes?.video_link ? <></> :
                                                                <a href={data?.attributes?.azure_video_url}>
                                                                    <img src={dwonload} style={Styles.GridTextImg} alt="Video Thumbnail" />
                                                                </a>
                                                        }


                                                    </Grid>
                                                    <Grid item lg={4}>
                                                        <Grid container>
                                                            <Grid item lg={6}></Grid>
                                                            <Grid item lg={4} style={{ marginTop: '-10px' }}>
                                                                {this.state.themeModeDelete ? <Button onClick={() => this.Undo_folder(data?.attributes.id)} style={Styles.undo}>Undo</Button> : <></>}
                                                            </Grid>
                                                            <Grid item lg={2}>
                                                                {this.state.themeModeDelete ? <img onClick={() => this.handleOpendelete()}
                                                                    src={delet} style={Styles.GridTextImg} /> :
                                                                    <img src={delet} onClick={() => this.delete_folder(data?.attributes.id)} style={Styles.GridTextImg} />}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                            <Modal
                                                open={this.state.open}
                                                // onClose={this.handleClosedelete}
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                            >
                                                <Paper style={Styles.deleteModal}>
                                                    <Box>
                                                        <Typography style={Styles.DeleteForever}>Delete Forever</Typography>
                                                        <Typography style={Styles.permanently}>You are about to permanently
                                                            delete the selected files. No recovery possible after deletion.</Typography>
                                                    </Box>
                                                    <Box style={Styles.buttonMDelet}>
                                                        <Button
                                                            style={Styles.Delete}
                                                            variant="outlined"
                                                            onClick={() => this.hardDeleteVideo(data?.attributes.id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            style={Styles.Cancel}
                                                            variant="outlined"
                                                            onClick={() => this.handleClosedelete()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Paper>
                                            </Modal>
                                        </>
                                    )
                                })) : (
                                <Typography style={Styles.NoData}>No data available here</Typography>)}
                        </>
                        :

                        <Grid container spacing={2} style={Styles.videoGrid}>
                            {
                                this.state.allXpandLibararyData?.videos?.data?.map((data: any, index: any) => {
                                    /* istanbul ignore next */
                                    return (
                                        <>
                                            <Grid item lg={3}>
                                                <Card style={
                                                    selectedItems.includes(data.attributes.id)
                                                        ? Styles.selectedVideoCard
                                                        : Styles.videoCard
                                                }>
                                                    <Checkbox
                                                        style={Styles.videoCheck}
                                                        checked={selectedItems.includes(data.attributes.id)}
                                                        onChange={() => this.toggleItemSelect(data.attributes.id, index)}
                                                    />
                                                    <CardMedia
                                                        component="img"
                                                        alt="Contemplative Reptile"
                                                        height="200"
                                                        image={data?.attributes?.video_thumb === null ? video : data?.attributes?.video_thumb?.url}
                                                        title="Contemplative Reptile"
                                                    />
                                                    <img
                                                        // onClick={() => this.props.history.push(`/Editvideo/${data?.attributes?.id}`)}
                                                        src={playButton} style={Styles.playButton} />
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item lg={2}>
                                                                <img src={videoIcon} />
                                                            </Grid>
                                                            <Grid item lg={10}>
                                                                <Typography style={Styles.playName}>
                                                                    {data?.attributes?.title.length > 20
                                                                        ? `${data?.attributes?.title.substring(0, 20)}...`
                                                                        : data?.attributes?.title
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}
                        </Grid>
                    }
                </Grid>
                {this.props.open === true ?
                    <Button onClick={() => this.selectData(this.state.selectedItems)}
                        style={Styles.NewButton1} variant="contained" color="primary" >Select</Button>
                    : ""}
                <Modal
                    open={this.state.popupOpen}
                    onClose={this.popupHandleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Paper style={Styles.modalPaper}>
                        <ManageContentFolder mainModalClose={this.props.mainModalClose} dataId={this.state.dataID} open={this.props.open} navigation={this.props.navigation} history={this.props.history} location={this.props.location} id={this.props.id} handleClose={this.props.handleClose} />
                    </Paper>
                </Modal>
            </div>
        )
    }
}

const Styles = {
    noDataMessage: {
        textAlign: 'center' as 'center',
        margin: 'auto',
        fontSize: '28px',
        fontWeight: 700,
    },
    NoData: {
        width: '100%',
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center' as 'center'
    },
    deleteModal: {
        width: '500px',
        minHeight: '210px',
        padding: '24px 30px',
        borderRadius: '20px',
        left: '50%',
        top: '50%',
        position: 'absolute' as 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    undo: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#6E3199',
        cursor: 'pointer'
    },
    DeleteForever: {
        fontSize: '22px',
        fontWeight: 700,
        color: '#11142D',
    },
    permanently: {
        marginTop: '10px',
        fontSize: '19px',
        fontWeight: 400,
        color: '#11142D',
        LineHeight: '24px',
    },
    modalPaper: {
        width: '1200px',
        display: 'flex',
        margin: '10px auto',
        maxHeight: '600px',
        overflowX: 'hidden' as 'hidden',
        padding: '45px',
        borderRadius: '15px !important'
    },
    listText: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#6C328B',
        paddingLeft: '15px'
    },
    listText3: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#6C328B',
        paddingLeft: '10px'
    },
    listText1: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#979797',
        paddingLeft: '15px'
    },
    listText2: {
        fontSize: '18px',
        fontWeight: 500,
        color: '#979797',
        paddingLeft: '10px'
    },
    vectorBox: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '10px'
    },
    vectorBox2: {
        display: 'flex',
        marginTop: '10px'
    },
    mainTitle: {
        fontSize: '48px', fontWeight: 700,
    },
    list: {
        marginTop: '25px',
    },
    commanButton: {
        borderRadius: '12px', width: '100%', height: '56px', textTransform: 'capitalize' as 'capitalize', fontSize: '17px'
    },
    commanButton1: {
        justifyContent: 'flex-start', border: 'none', borderRadius: '12px', width: '100%', height: '56px', textTransform: 'capitalize' as 'capitalize', fontSize: '14px'
    },
    commanButton12: {
        color: '#fff', fontWeight: 700, background: '#653889', justifyContent: 'flex-start', border: 'none', borderRadius: '12px', width: '45%', height: '48px', textTransform: 'capitalize' as 'capitalize', fontSize: '14px'
    },
    Delete: {
        color: '#fff', fontWeight: 700, background: '#653889', border: 'none', borderRadius: '12px', width: '150px', height: '45px', textTransform: 'capitalize' as 'capitalize', fontSize: '14px'
    },
    buttonMDelet: {
        display: 'flex' as 'flex',
        flexDirection: 'row-reverse' as 'row-reverse',
    },
    buttonMDelet1: {
        display: 'flex' as 'flex',
        flexDirection: 'row-reverse' as 'row-reverse',
        marginTop: '15px',
    },
    commanButton13: {
        width: '45%',
        borderRadius: '12px',
        border: '1px solid #653889',
        color: '#653889',
        fontWeight: 700
    },
    Cancel: {
        width: '150px',
        borderRadius: '12px',
        border: '1px solid #653889',
        color: '#653889',
        textTransform: 'capitalize' as 'capitalize',
        height: '45px',
        fontWeight: 700,
        marginRight: '15px'
    },
    BulkImg: {
        paddingRight: '8px',
    },
    divDot: {
        display: 'flex'
    },
    folderList: {
        fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px', cursor: 'pointer'
    },
    dotImg: {
        width: '10px', height: '15px', marginTop: '28px', marginLeft: '15px', marginRight: '15px'
    },
    dotText: {
        fontSize: '20px', fontWeight: 400, color: '#979797', cursor: 'pointer', marginTop: '22px', textTransform: 'capitalize' as 'capitalize'
    },
    Dots: {
        width: '4px', height: '13px', marginTop: '30px', marginLeft: '15px', cursor: 'pointer'
    },
    listDiv: {
        display: 'flex', justifyContent: 'space-evenly', marginTop: '10px'
    },
    listDiv1: {
        display: 'flex', marginTop: '10px'
    },
    vectorImg: {
        width: '20px', height: '12px', marginTop: '7px'
    },
    vectorText: {
        fontSize: '18px', fontWeight: 500, color: '#6C328B', paddingLeft: '15px'
    },
    NewButton: {
        borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' as 'capitalize'
    },
    NewButton1: {
        borderRadius: '12px', width: '180px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' as 'capitalize', display: 'flex', margin: '25px auto',
        fontSize: '18px', fontWeight: 700
    },
    AllBox: {
        display: 'flex', marginTop: '20px'
    },
    AllBoxCheck: {
        marginTop: '-8px', color: '#6C328B'
    },
    GridData: {
        marginTop: '20px', marginLeft: '15px'
    },
    GridPaper: {
        width: '100%', height: '68px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px', borderRadius: '10px'
    },
    GridAuto: {
        margin: 'auto'
    },
    GridPed: {
        padding: '15px'
    },
    dataName: {
        cursor: 'Pointer', fontSize: '14px', fontWeight: 600, color: '#222836', marginLeft: '0px', marginTop: '0px', textTransform: 'capitalize' as 'capitalize'
    },
    modalDiv: {
        position: 'absolute' as 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
        margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'
    },
    closeImg: {
        float: 'right' as 'right', marginTop: '20px', marginRight: '20px'
    },
    closeText: {
        fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px'
    },
    Gridclose: {
        marginLeft: '30px', marginTop: '25px'
    },
    closeInput: {
        borderRadius: '16px', width: '665px'
    },
    closeButton: {
        borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889'
    },
    dialogDiv: {
        width: '225px', maxHeight: '200px', backgroundColor: '#fff', padding: '15px 0px',
    },
    profileDiv: {
        display: 'flex' as 'flex', marginTop: '13px', marginLeft: '25px', cursor: 'pointer'
    },
    proText: {
        fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px'
    },
    hr: {
        width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)'
    },
    linkDiv: {
        cursor: 'pointer', display: 'flex', marginTop: '0px', marginLeft: '25px'
    },
    EditModal: {
        position: 'absolute' as 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
        margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'
    },
    EditImg: {
        float: 'right' as 'right', marginTop: '20px', marginRight: '20px'
    },
    EditText: {
        fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px'
    },
    EditGrid: {
        marginLeft: '30px', marginTop: '25px'
    },
    EditInput: {
        borderRadius: '16px', width: '665px'
    },
    EditButton: {
        borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889'
    },
    headGrid: {
        marginTop: '40px', marginBottom: '25px'
    },
    headText: {
        fontSize: '16px', fontWeight: 600, textAlign: 'center' as 'center'
    },
    headText1: {
        fontSize: '16px', fontWeight: 600, float: 'right' as 'right', marginRight: '2%'
    },
    Right: {
        fontSize: '16px', fontWeight: 600, float: 'right' as 'right', marginRight: '10%'
    },
    headText2: {
        fontSize: '16px', fontWeight: 600, float: 'right' as 'right', marginRight: '6%'
    },
    DataPaper: {
        boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#fcfcfd',
        height: '70px',
        borderRadius: '20px',
        marginBottom: '15px',
    },
    selectedVideoCard1: {
        boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#fcfcfd',
        height: '70px',
        borderRadius: '20px',
        marginBottom: '15px',
        background: '#6C328B40'
    },
    TableGrid: {
        marginTop: '20px'
    },
    GridCenter: {
        textAlign: 'center' as 'center'
    },
    GridText: {
        fontSize: '14px', fontWeight: 500,
        textAlign: 'center' as 'center',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: 'hidden' as 'hidden',
        textOverflow: 'ellipsis' as 'ellipsis',
    },
    GridTextImg: {
        cursor: 'pointer'
    },
    videoGrid: {
        marginTop: '40px'
    },
    videoCard: {
        maxWidth: '100%', borderRadius: '15px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', cursor: 'pointer', position: 'relative' as 'relative',
    },
    videoCard1: {
        maxWidth: '100%', borderRadius: '15px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', cursor: 'pointer', position: 'relative' as 'relative',
        border: '2px solid rgb(108, 50, 139)'
    },
    videoCheck: {
        position: 'absolute' as 'absolute', top: '8px', left: '8px', zIndex: 1, color: '#6C328B', borderRadius: '5px', background: '#fff', width: '35px', height: '35px'
    },
    playButton: {
        marginTop: '-10%', position: 'absolute' as 'absolute', display: 'flex', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
    },
    playName: {
        fontSize: '16px', fontWeight: 400, textAlign: 'center' as 'center'
    },
    selectedVideoCard: {
        maxWidth: '100%',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        position: 'relative' as 'relative',
        border: '2px solid rgb(108, 50, 139)',
        borderRadius: '10px'
    },
    '@media (max-width: 600px)': {
        deleteModal: {
            width: '90%',
            left: '5%',
            top: '20%',
        },
        modalPaper: {
            width: '90%',
            padding: '30px',
        },
        listText: {
            fontSize: '16px'
        },
        listText1: {
            fontSize: '16px'
        }
        // Add more responsive styles as needed
    },

    '@media (max-width: 768px)': {
        // Example: Adjust font size for smaller screens
        noDataMessage: {
            fontSize: '24px',
        },
        // Add more responsive styles as needed
    },
}

export function CardDetail({ tostSuccessfully, RenameHandleOpen, DeleteFolderAPI }: { tostSuccessfully: () => void, DeleteFolderAPI: () => void, RenameHandleOpen: () => void }) {

    return (
        <>
            <div data-test-id="btn_tostSuccessfully2" style={Styles.profileDiv} onClick={() => tostSuccessfully()}>
                <img src={profile} />
                <Typography style={Styles.proText}>Share</Typography>
            </div>
            <hr style={Styles.hr} />
            <div data-test-id="btn_tostSuccessfully3" onClick={() => tostSuccessfully()} style={Styles.linkDiv}>
                <img src={copylink} />
                <Typography style={Styles.proText}>Copy Link</Typography>
            </div>
            <hr style={Styles.hr} />
            <div data-test-id="btn_renameFolder" onClick={() => RenameHandleOpen()} style={Styles.linkDiv}>
                <img src={Rename} />
                <Typography style={Styles.proText}>Rename</Typography>
            </div>
            <hr style={Styles.hr} />
            <div data-test-id="btn_DeleteFolderAPI" onClick={() => DeleteFolderAPI()} style={Styles.linkDiv}>
                <img src={trash} />
                <Typography style={Styles.proText}>Delete Folder</Typography>
            </div>
        </>
    );
}