import React from "react";
import { CourseIdComponent, EditComponent, EditInnerComponent, HrComponent, IconButtonComponent, ImageComponent, LightTooltip, RenameComponent } from "./Table";
import { ClickAwayListener, FormControl, FormHelperText, Input } from "@material-ui/core";
import { ContentRolesType } from "../../blocks/catalogue/src/CatalogueController";
import { circleCheck, correctlyCheck, editIcon, exitCircle } from "./Table/asset";

interface ContentIdPoverProps {
    uniqId: string;
    editIconClick: Function;
    handleChangeContentId: Function;
    updateEditContentId: Function;
    isEditContentIdOpen: boolean;
    row: ContentRolesType;
    editContentdata: ContentRolesType;
    updateContentIDErrorMessage: string;
}

export const ContentIdToolTip = (props: ContentIdPoverProps) => {

    return (
    <>
        <div onClick={(event) => event.stopPropagation()}>
        <LightTooltip
            data-test-id="tooltip-editBtn"
            enterDelay={200}
            leaveDelay={100}
            enterTouchDelay={0}
            leaveTouchDelay={100000}
            interactive={true}
            title={
                <>
                    <EditComponent>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100px", alignItems: "center" }}>
                            <EditInnerComponent>
                                Edit ID
                            </EditInnerComponent>
                            <ImageComponent data-test-id="tooltip-edit" src={editIcon} onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                                props.editIconClick()
                            }} />
                        </div>
                        <HrComponent />
                    </EditComponent>
                </>
            }
            placement="top"
            arrow
        >
            <span data-test-id="uniq_id">{props.uniqId}</span>
        </LightTooltip>
        </div>
    {
        props.isEditContentIdOpen && props.row?.id === props.editContentdata.id && (
            <ClickAwayListener data-test-id="clickAwayListner1" onClickAway={() => props.editIconClick()}>
            <div onClick={(event) => event.stopPropagation()} data-test-id="clickAwayListner">
            <LightTooltip
                enterDelay={200}
                leaveDelay={100}
                interactive={true}
                open={props.isEditContentIdOpen}
                title={
                    <>
                        <FormControl
                        >
                            <RenameComponent>
                                Rename Content ID
                            </RenameComponent>
                            <CourseIdComponent>
                                <Input
                                    autoFocus
                                    fullWidth
                                    value={props.editContentdata?.attributes.uniq_id}
                                    onChange={(event) => props.handleChangeContentId(event.target.value)}
                                    disabled={props.updateContentIDErrorMessage === "Id correctly edited."}
                                    style={(props.updateContentIDErrorMessage === "Content Role ID has already exist. Please try a different one." || props.updateContentIDErrorMessage === "Content Role ID must start with 'CN' followed by 1 alphanumeric character")
                                        ? webStyle.errorInput : props.updateContentIDErrorMessage === "Automatic ID Assigned" ? webStyle.automaticText : undefined}
                                />
                                <>
                                    <IconButtonComponent onClick={() => props.updateEditContentId(props.editContentdata?.attributes.uniq_id, Number(props.editContentdata?.id))}>
                                        Save
                                    </IconButtonComponent>
                                </>
                            </CourseIdComponent>
                            {
                                (props.updateContentIDErrorMessage === "Content Role ID has already exist. Please try a different one." || props.updateContentIDErrorMessage === "Content Role ID must start with 'CN' followed by 1 alphanumeric character") && (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img style={{ width: "18px", height: "18px" }} src={exitCircle} />
                                            <FormHelperText style={{ color: 'red', marginLeft: " 5px", fontSize: "10px" }}>
                                                {props.updateContentIDErrorMessage}
                                            </FormHelperText>
                                        </div>
                                    </>
                                )
                            }
                            {props.updateContentIDErrorMessage === "Id correctly edited." && (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <img src={correctlyCheck} />
                                        <FormHelperText style={{ color: '#9A5E89', marginLeft: " 5px" }}>
                                            {props.updateContentIDErrorMessage}
                                        </FormHelperText>
                                    </div>
                                </>
                            )}
                            {props.updateContentIDErrorMessage === "Automatic ID Assigned" && (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <img src={circleCheck} />
                                        <FormHelperText style={{ borderColor: "#652786", marginLeft: " 5px" }}>
                                            {props.updateContentIDErrorMessage}
                                        </FormHelperText>
                                    </div>
                                </>
                            )}
                        </FormControl>
                    </>
                }
                placement="top"
                arrow
            >
                <span>
                </span>
            </LightTooltip>
            </div>
            </ClickAwayListener>
        )
    }
    </>
)}
;

const webStyle = {
    errorInput: {
      borderColor: 'red',
      backgroundColor: "#FFF5F5"
    },
    errorText: {
      color: 'red',
      marginLeft: " 5px",
      fontSize: "10px"
    },
    successText: {
      color: '#9A5E89',
      marginLeft: " 5px"
    },
    automaticText: {
      borderColor: "#652786",
      marginLeft: " 5px"
    },
  }
// Customizable Area End
