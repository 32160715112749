import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { 
  EARNED_REWARD, 
  EARNED_ACHIEVEMENT, 
  COURSE_COMPLETE, 
  EARNED_REWARD_ADMIN, 
  EARNED_ACHIEVEMENT_ADMIN,
} from "../notificationTypes.web"

interface IContent {
  type: string;
  notification: any;
}

export default function Content({ type, notification }: IContent) {
  const classes = useStyles();
  const videoCountExists = notification?.attributes?.video_count;
  const notificationType = notification?.attributes?.notification_type;

  return (
    <Box className={clsx(classes.itemContent, type !== "normal" && classes.itemContentCheckbox)}>
      <Typography className={classes.content}>
        {notification?.attributes?.contents}
        {notification?.attributes?.notification_type === COURSE_COMPLETE && (
          <p className={classes.subContent}>let's go</p>
        )}
      </Typography>
      {videoCountExists && (
        <Typography>Total Videos: {notification?.attributes?.video_count}</Typography>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContent: {
      width: "70%",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    itemContentCheckbox: {
      marginTop: 0,
    },
    content: {
      fontWeight: 700,
    },
    link: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      textDecorationLine: "underline !important",
      color: "#6C328B"
    },
    subContent: {
      fontWeight: 500,
      fontSize: "14.9434px",
      lineHeight: "21px",
      color: "#7D8592",
      textTransform: "capitalize",
      margin: "10px 0",
    }
  })
);