import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Button,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AnswerOptions from "./AnswerOptions.web";
import { OutlinedButton } from "../../../components/src/Buttons";
import { useCommonStyles } from "./Styles.web";

interface Props {
  questions: any;
  handleQuestionTypeChange: any;
  handleAddMcqOption: any;
  handleChange: any;
  isSubmitting: any;
  values: any;
  touched: any;
  errors: any;
  resetForm: any;
  setValues: any;
  handleCancelSavingQuestion: any;
  handleRemoveOptionsForMcqQuestions: any;
}

export default function QuestionControl({
  questions,
  handleQuestionTypeChange,
  handleAddMcqOption,
  handleChange,
  isSubmitting,
  values,
  touched,
  errors,
  resetForm,
  setValues,
  handleCancelSavingQuestion,
  handleRemoveOptionsForMcqQuestions,
}: Props) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    questions &&
    questions.length > 0 &&
    questions.map(
      (question: any, index: number) =>
        !question.isSaved && (
          <Fragment key={index}>
            <Grid container style={{ marginTop: 30 }}>
              {/* Question type starts */}
              <Grid item xs={12}>
                <Typography className={commonClasses.label}>
                  Question Type
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="question"
                    name={`questions.${index}.questionType`}
                    value={question.questionType}
                    onChange={(e: any) => {
                      handleChange(`questions.${index}.questionType`)(e);
                      handleQuestionTypeChange(e, values, index, setValues);
                    }}
                  >
                    <FormControlLabel
                      value="MCQ"
                      control={<Radio />}
                      label="Multiple Choice (MCQs)"
                      className={commonClasses.radio}
                    />
                    <FormControlLabel
                      value="TnF"
                      control={<Radio />}
                      label="True or False (TnF)"
                      className={commonClasses.radio}
                    />
                    <FormControlLabel
                      value="YnN"
                      control={<Radio />}
                      label="Yes or No (YnN)"
                      className={commonClasses.radio}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Question type ends */}
              {/* Active/deactive question starts */}
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography className={commonClasses.label2}>Active</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="is active"
                    name={`questions.${index}.isActive`}
                    value={question.isActive}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                      className={commonClasses.radio}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      className={commonClasses.radio}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Active/deactive question ends */}
              {/* Question id, text starts */}
              <Grid item xs={12}>
                <label className={commonClasses.label}>question ID</label>
                <br />
                <TextField
                  name={`questions.${index}.id`}
                  variant="outlined"
                  value={question.id}
                  className={commonClasses.textFieldDisabled}
                  placeholder="ID"
                  disabled
                />
                <TextField
                  name={`questions.${index}.text`}
                  fullWidth
                  placeholder="Type your question here..."
                  variant="outlined"
                  value={question.text}
                  onChange={handleChange}
                  className={commonClasses.transparentTextField}
                  error={
                    touched?.questions?.[index]?.text &&
                    Boolean(errors?.questions?.[index]?.text)
                  }
                  helperText={
                    touched?.questions?.[index]?.text &&
                    errors?.questions?.[index]?.text
                  }
                  style={{ marginTop: 15 }}
                />
              </Grid>
              {/* Question id, text ends */}
            </Grid>
            <AnswerOptions
              index={index}
              errors={errors}
              values={values}
              touched={touched}
              question={question}
              setValues={setValues}
              questions={questions}
              handleChange={handleChange}
              handleAddMcqOption={handleAddMcqOption}
              handleRemoveOptionsForMcqQuestions={
                handleRemoveOptionsForMcqQuestions
              }
            />
            <Box style={{ display: "flex" }}>
              <OutlinedButton 
                onClick={() => 
                  handleCancelSavingQuestion(index, values, setValues)}
                >
                  cancel
              </OutlinedButton>
              <Button
                variant="contained"
                // color="primary"
                disabled={isSubmitting}
                type="submit"
                className={classes.containedBtn}
                style={{marginLeft: 10}}
                >
                save question
              </Button>
            </Box>
          </Fragment>
        )
    )
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  outlinedBtn: {
    color: "#202020",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "capitalize",
    border: "1px solid #202020",
    borderRadius: "16px",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      padding: "14px 26px",
      fontSize: "18px",
    }
  },
  containedBtn: {
    backgroundColor: "#6C328B",
    color: "#FFFFFF",
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "capitalize",
    borderRadius: "16px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#6C328B"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "14px 26px",
      fontSize: "18px",
    },
  }
}))