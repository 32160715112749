Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.b6662536892a73acf765efdf133aaead0cf7b054 = "assets/b6662536892a73acf765efdf133aaead0cf7b054.png";
exports.backgroundImage = "assets/backgroundImage.png";
exports.Oval = "assets/Oval.png";
exports.loginAPiEndPoints = "bx_block_login/login";
exports.registerHere="Register here";
exports.newXpand = "New to XPAND?";
exports.xpandLogo = "XPAND";
exports.forgotpassword = "Forgot Password";
exports.labelHeader = "Please enter your login details";
exports.HiThere = "Hi There!";
exports.titleWelcome = "Welcome to XPAND";
exports.btnTxtLogin = "LOGIN";
exports.errorPasswordNotValid = "Please enter  password";
exports.errorPasswordSpace = "Space should not be there in password"
exports.loginAPiEndPoint = "bx_block_login/login";
exports.addDeviceIdEndPoint = "bx_block_customisableuserprofiles/profiles/add_device_id";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End