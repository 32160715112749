import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import clsx from "clsx";
import {
  NEW_COURSE_ASSIGNED,
  NEW_COURSE_ENROLL,
  NEW_COURSES,
  DUE_REMINDER,
  EARNED_REWARD,
  EARNED_ACHIEVEMENT,
  EARNED_REWARD_ADMIN,
  EARNED_ACHIEVEMENT_ADMIN,
  COURSE_COMPLETED,
} from "./reminderTypes";
import DateEST from "../../../../components/src/DateEst/index.web";

interface IDescription {
  type: string;
  due_in: number;
  earned_date?: string;
  courseOrProgramName: string;
  rewardOrAchieventName?: string;
  is_multiple_course?: boolean;
  courseOrProgramUrl: string;
}

function RewardAndAchieventDate({
  dateStr,
  title,
}: {
  dateStr: string | undefined;
  title: string;
}) {
  const classes = useStyles();
  return (
    <>
      <p className={classes.rewardEarnedTimeLabel}>{title}</p>
      <DateEST dateStr={dateStr} />
    </>
  );
}

export default function Description({
  type,
  due_in,
  earned_date,
  courseOrProgramName,
  rewardOrAchieventName,
  courseOrProgramUrl,
  is_multiple_course,
}: IDescription) {
  const classes = useStyles();
  switch (type) {
    case NEW_COURSE_ASSIGNED:
      return (
        <p className={classes.cardDescription}>
          A{" "}
          <span className={classes.emphasis}>new course has been assigned</span>{" "}
          to you. So, what are you waiting for?
        </p>
      );
    case NEW_COURSE_ENROLL:
      return (
        <>
          <p className={classes.cardDescription}>
            {" "}
            We love to see you Xpanding one course at a time.
          </p>
          <p className={`${classes.cardDescription} ${classes.emphasis}`}>
            Keep killin' it!
          </p>
        </>
      );
    case NEW_COURSES:
      const path = is_multiple_course ? "library" : courseOrProgramUrl;
      return (
        <>
          <p className={classes.cardDescription}>
            {" "}
            There are{" "}
            <span className={classes.emphasis}>
              new courses available to you!
            </span>{" "}
            Click the link to see all of the new courses that we thought you
            might like!
          </p>
          <Link
            className={`${classes.cardDescription} ${classes.emphasis}`}
            to={path}
          >
            link
          </Link>
        </>
      );
    case DUE_REMINDER:
      if (due_in === 0 || due_in > 0) {
        return (
          <p className={classes.cardDescription}>
            Xpand your knowledge and go finish up {courseOrProgramName} now!
          </p>
        );
      }
      return (
        <p className={classes.cardDescription}>
          What are you waiting for? Keep Xpanding your life one course at a
          time.
        </p>
      );
    case EARNED_REWARD:
      return (
        <>
          <p className={classes.cardDescription}>
            You have achieved
            <br />
            <Link to="#" className={classes.link}>
              {rewardOrAchieventName}
            </Link>
          </p>
          <RewardAndAchieventDate
            dateStr={earned_date}
            title="Reward earned on"
          />
        </>
      );
    case EARNED_ACHIEVEMENT:
      return (
        <p className={classes.cardDescription}>
          Doesn't it feel good to be rewarded for all your hard work! As you are
          putting in the hours to sharpen your skills, just remember that “the
          journey is the reward”. Happy learning!
        </p>
      );
    case EARNED_REWARD_ADMIN:
      return (
        <>
          <Link to="#" className={classes.link}>
            link
          </Link>
          <RewardAndAchieventDate
            dateStr={earned_date}
            title="Reward earned on"
          />
        </>
      );
    case EARNED_ACHIEVEMENT_ADMIN:
      return (
        <>
          <Link to="#" className={classes.link}>
            link
          </Link>
          <RewardAndAchieventDate
            dateStr={earned_date}
            title="Achievement earned on"
          />
        </>
      );
    case COURSE_COMPLETED:
      const { cardDescription, black, emphasisBlack } = classes;
      return (
        <>
          <p className={clsx(cardDescription, black)}>
            {" "}
            Make sure to give them a friendly nudge for getting after it!
          </p>
          <p className={clsx(cardDescription, emphasisBlack)}>Let's go!</p>
        </>
      );

    default:
      return null;
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cardDescription: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#7d8592",
    },
    black: {
      color: "#000000",
    },
    emphasis: {
      color: "#6C328B",
      fontWeight: 700,
    },
    emphasisBlack: {
      color: "#000000",
      fontWeight: 700,
    },
    link: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      textDecorationLine: "underline",
      color: "#6C328B",
    },
    rewardEarnedTimeLabel: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#7D8592",
      margin: "5px 0",
    },
    dateContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dateTexts: {
      marginLeft: 10,
    },
    dateText: {
      margin: "5px 0",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "129%",
      textAlign: "center",
      color: "#5C5F62",
    },
  })
);