Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiMethodType = "GET";
exports.validationApiContentType = "application/json";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gamification";
exports.labelBodyText = "Gamification Body";
exports.getOwnerListEndpoint = "bx_block_library2/experts";
exports.getBranchNameEndpoint = "account_block/standard_users/branch_list";
exports.examplePutMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.courseAPiEndPoint = "bx_block_catalogue/collection_lists";
//Get Rewards List
exports.rewardsAPiEndPoint = "bx_block_educational_gamification/rewards";
//Get Achievements List
exports.achievementsAPiEndPoint = "bx_block_educational_gamification/achievements";
exports.rewardAPiEndPoint = "bx_block_educational_gamification/rewards";
exports.EnrolledUserAPiEndPoint = "bx_block_catalogue/enrolled_users_lists";
exports.putTableCourseIdEndPoint = "bx_block_coursecreation/courses/";
exports.putTableProgramIdEndPoint = "bx_block_coursecreation/programs/";
exports.putCourseIdApiEndPoint  = "bx_block_coursecreation/courses_ids/";
exports.putProgramIdApiEndPoint  = "bx_block_coursecreation/programs_ids/";
exports.expertAdminListApiEndPoint = "bx_block_library2/experts";
exports.catagoryListingApiEndPoint ="bx_block_categories/categories";
exports.contentRoleListingApiEndPoint ="bx_block_coursecreation/content_roles";
exports.courseTypeContentRole="bx_block_catalogue/collection_lists?course_type=content_role"
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";
exports.duplicateCourseApi = "/bx_block_coursecreation/duplicate_courses";
exports.duplicateText="Duplicate"
exports.cancelTxt="Cancel";
exports.duplicateSubText = "Only the content is copied. Assigned users/branches and rewards and achievements will not be copied.";
exports.duplicateHeadingTxt="Are you sure you want to duplicate this Course?";
exports.duplicateSuccessTxt="Duplicate course has been added";
exports.coursePreview ="bx_block_coursecreation/courses/";
exports.BranchesListingApiEndPoint="account_block/standard_users/branch_list"
exports.getCourseListEndpoint ='bx_block_account_groups/user_contents/course_contents';
exports.getProgramListEndPoint = 'bx_block_account_groups/user_contents/program_contents';
exports.postCreateAchievementEndPoint = 'bx_block_educational_gamification/achievements';
exports.manageRewardsEndPoint = 'bx_block_educational_gamification/rewards/manage_user_rewards';
exports.changeRewardStatusEndPoint = 'bx_block_customisableuserprofiles/user_rewards/change_redeemed_status';
exports.manageAchievementEndPoint = 'bx_block_educational_gamification/achievements/manage_user_achievements';
exports.iconListEndPoint = "bx_block_image_management/icons";
// Customizable Area End