import React, { useState, useEffect } from "react";
import { Typography, FormControlLabel, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCommonStyles } from "./Styles.web";
import SelectDropdown from "./SelectDropdown.web";
import StyledCheckbox from "../../../components/src/StyledCheckbox";
import { OutlinedButton } from "../../../components/src/Buttons";
import { ShuffleIcon } from "./assets";

interface Props {
  values: any;
  courseId: any;
  setValues: any;
  handleChange: any;
  handleBulkActions: any;
  handleAddQuestion: any;
}

const OPTIONS = [
  { id: 1, label: "Bulk Action", value: "" },
  { id: 2, label: "Delete", value: "delete" },
  { id: 3, label: "Make Active", value: "active" },
  { id: 4, label: "Make Inactive", value: "deactive" },
];

export default function QuizDetailsHeader({
  values,
  courseId,
  setValues,
  handleChange,
  handleBulkActions,
  handleAddQuestion,
}: Props) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [disableAddBtn, setDisableAddBtn] = useState(true);
  const disableBulkActionBtn = values.selectedQuestionIds.length === 0;

  // Disable add question button if course ID is missing
  useEffect(() => {
    if (!courseId) {
      setDisableAddBtn(true);
    }
  }, [courseId]);

  useEffect(() => {
    /**
     * Enable add question button if
     * there are no questions
     * or all questions are saved
     */
    if (!courseId) {
      return;
    }
    const { questions } = values;
    if (questions.length === 0) {
      setDisableAddBtn(false);
    } else {
      const savedAllQuestions = questions.every(
        (question: any) => question.isSaved
      );
      savedAllQuestions ? setDisableAddBtn(false) : setDisableAddBtn(true);
    }
  }, [courseId, values.questions]);

  useEffect(() => {
    if (values.bulkAction) {
      const { selectedQuestionIds, bulkAction } = values;
      handleBulkActions(selectedQuestionIds, bulkAction, values, setValues);
    }
  }, [values.bulkAction]);

  return (
    <Box className={classes.questionDetailsHeader}>
      <Typography className={commonClasses.label} data-test-id="question-details">question details</Typography>
      <Box className={classes.addQuestionContainer}>
        <SelectDropdown
          name="bulkAction"
          options={OPTIONS}
          value={values.bulkAction}
          disabled={disableBulkActionBtn}
          handleChange={handleChange}
        />
        <FormControlLabel
          className={classes.shuffleControlLabel}
          control={
            <StyledCheckbox
              name="shuffleQuestions"
              checked={values.shuffleQuestions}
              onChange={handleChange}
            />
          }
          label={
            <Box className={classes.shuffleQsContainer}>
              <img
                src={ShuffleIcon}
                alt="shuffle questions"
                style={{ width: "22px" }}
              />
              <Typography className={classes.shuffleLabel}>
                Shuffle Questions
              </Typography>
            </Box>
          }
        />
        <OutlinedButton
          data-test-id="add-question-btn"
          disabled={disableAddBtn}
          onClick={() => handleAddQuestion(values, setValues)}
        >
          Add a question
        </OutlinedButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionDetailsHeader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    addQuestionContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: 10,
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 0,
      },
    },
    shuffleQsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    shuffleLabel: {
      fontSize: "15px",
      lineHeight: "15px",
      fontWeight: 600,
      color: "#202020",
      marginLeft: 10,
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    outlinedBtn: {
      color: "#202020",
      fontSize: "15px",
      fontWeight: 600,
      textTransform: "capitalize",
      border: "1px solid #202020",
      borderRadius: "16px",
      padding: "10px",
      [theme.breakpoints.up("sm")]: {
        padding: "14px 26px",
        fontSize: "18px",
      },
    },
    shuffleControlLabel: {
      marginLeft: 0,
      [theme.breakpoints.up("md")]: {
        marginLeft: 10,
      },
    },
  })
);
