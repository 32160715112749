import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  IconButton,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import StyledCheckbox from "../../../../components/src/StyledCheckbox";
import EnhancedTableHead from "./EnahancedTableHead";
import { truncateText, getUnSavedQuestionIndex } from "../utils.web";
import { TrashIcon, DragIcon } from "../assets";

const draggingOverBackground = (isDraggingOver: boolean) =>
  isDraggingOver ? "lightgray" : "white";

export default function QuestionTable({
  values,
  setValues,
  handleEditQuestion,
  deleteQuestion,
}: any) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<number[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [unsavedQuestionIndex, setUnsavedQuestionIndex] = useState(-1);

  const setSelectedQuestionIds = (selectedIds: number[]) => {
    const newValues = {
      ...values,
      selectedQuestionIds: selectedIds,
    };
    setValues(newValues);
  };

  useEffect(() => {
    const savedQuestionFound = values.questions.some(
      (question: any) => question.isSaved === true
    );
    savedQuestionFound ? setShowTable(true) : setShowTable(false);
  }, [values.questions]);

  useEffect(() => {
    const index = getUnSavedQuestionIndex(values.questions);
    setUnsavedQuestionIndex(index);
  }, [values.questions]);

  // Reset selected after succesfful bulk action
  useEffect(() => {
    if (values.selectedQuestionIds.length === 0) {
      setSelected([]);
    }
  }, [values.selectedQuestionIds]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = values.questions.map((question: any) => question.id);
      setSelected(newSelecteds);
      setSelectedQuestionIds(newSelecteds);
      return;
    }
    setSelectedQuestionIds([]);
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelectedQuestionIds(newSelected);
    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    // Handle drop outside of droppable
    if (!destination) {
      return;
    }
    // Handle drop to where it was before
    if (destination.index === source.index) {
      return;
    }

    // Handle row re-ordering
    const newValues = { ...values };
    const newQuestions = [...newValues.questions];
    const draggedQuestion = newQuestions.find(
      (question) => question.id === draggableId
    );
    newQuestions.splice(source.index, 1);
    newQuestions.splice(destination.index, 0, draggedQuestion);
    newValues.questions = newQuestions;
    setValues(newValues);
    // Update questions IDs in local storage
    const newQuestionsIds = newQuestions.map((qs) => qs.id);
    localStorage.setItem("question_ids", JSON.stringify(newQuestionsIds));
  };

  if (!showTable) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.root}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="questions table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={values.questions.length}
            />
            <Droppable droppableId="questions-table">
              {(provided, droppableSnapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {values.questions.map((question: any, qIndex: number) => {
                    const isItemSelected = isSelected(question.id);
                    const labelId = `question-table-checkbox-${qIndex}`;

                    if (question.isSaved) {
                      return (
                        <Draggable
                          draggableId={question.id.toString()}
                          index={qIndex}
                          key={question.id}
                        >
                          {(provided, draggableSnapshot) => {
                            const style = {
                              ...provided.draggableProps.style,
                              display: draggableSnapshot.isDragging && "table",
                              backgroundColor: draggableSnapshot.isDragging
                                ? "#f3eded"
                                : draggingOverBackground(
                                    droppableSnapshot.isDraggingOver
                                  ),
                            };
                            return (
                              <TableRow
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                // onClick={(event: any) =>
                                //   handleClick(event, question.id)
                                // }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={question.id}
                                className={classes.tableRow}
                                // @ts-ignore
                                style={style}
                              >
                                <TableCell
                                  {...provided.dragHandleProps}
                                  padding="normal"
                                  className={classes.tableCell}
                                >
                                  <img
                                    src={DragIcon}
                                    alt="Drag icon"
                                    style={{ width: "25px" }}
                                  />
                                </TableCell>
                                <TableCell
                                  padding="checkbox"
                                  className={classes.tableCell}
                                >
                                  <StyledCheckbox
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                    onClick={(event: any) =>
                                      handleClick(event, question.id)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  padding="normal"
                                  scope="row"
                                  id={labelId}
                                  className={classes.tableCell}
                                >
                                  {question.id}
                                </TableCell>
                                <TableCell
                                  padding="normal"
                                  className={classes.tableCell}
                                >
                                  {question.questionType}
                                </TableCell>
                                <TableCell
                                  padding="normal"
                                  className={classes.tableCell}
                                >
                                  {truncateText(question.text)}
                                </TableCell>
                                <TableCell
                                  padding="normal"
                                  className={`${classes.tableCell} ${
                                    classes.actionCell
                                  }`}
                                >
                                  <Button
                                    aria-label="edit question"
                                    className={classes.editButton}
                                    onClick={() =>
                                      handleEditQuestion(
                                        values,
                                        qIndex,
                                        setValues
                                      )
                                    }
                                    // Every edit btn is disabled: if all questions are saved
                                    // Disable every other edit btn except the not saved one
                                    disabled={
                                      unsavedQuestionIndex === -1
                                        ? false
                                        : qIndex !== unsavedQuestionIndex
                                    }
                                  >
                                    edit
                                  </Button>
                                  <IconButton
                                    aria-label="delete question"
                                    onClick={() =>
                                      deleteQuestion(values, qIndex, setValues)
                                    }
                                  >
                                    <img
                                      src={TrashIcon}
                                      alt="Delete question"
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </div>
    </DragDropContext>
  );
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: 15,
    },
    table: {
      minWidth: 750,
      tableLayout: "auto",
    },
    tableRow: {
      transition: "background-color .3s ease",
    },
    tableHeadCell: {
      borderBottom: "0 !important",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "30px",
      color: "#000000",
      [theme.breakpoints.up("sm")]: {
        fontSize: "20px",
      },
    },
    tableCell: {
      borderBottom: "0 !important",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "24px",
      color: "#222836",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
      },
    },
    actionCell: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    editButton: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "24px",
      textAlign: "center",
      color: "#653889",
      textDecoration: "none",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "22px",
        lineHeight: "33px",
      },
    },
    checkbox: {
      "&$checked": {
        color: "#6C328E",
      },
    },
  })
);
