Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.title1="Sell or Buy products";
exports.title2="Sell / Buy products";
exports.title3=" Buy products";
exports.title4="Sell products";
exports.formDataContentType = 'application/json';
exports.apiMethodTypeGET = 'GET';
exports.apiMethodTypePOST = 'POST';
exports.categoryFetchAPiEndPoint = 'bx_block_categories/categories';
exports.createUserCategoryEndPoint = "bx_block_categories/categories/create_user_categories";
exports.skipUserCategoryEndPoint = "bx_block_categories/categories/skip_category";
exports.getPorfileDataEndPoint = "bx_block_customisableuserprofiles/profiles";

exports.subtitle1="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
// Customizable Area End