import React from "react";
import clsx from "clsx";
import { Box, Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import DateEST from "../../../../components/src/DateEst/index.web";
import { playIcon } from "../assets";
import {
  NOTE,
  DUEDATE,
  EARNED_REWARD,
  EARNED_ACHIEVEMENT,
  COURSE_COMPLETE,
  EARNED_REWARD_ADMIN,
  EARNED_ACHIEVEMENT_ADMIN,
} from "../notificationTypes.web";

interface IActionButtons extends RouteComponentProps {
  type: string;
  title: string;
  history: any;
  rewardAchievementEarnedDate: string;
  onLearningClick: (notification: any) => void;
  onReminderClick: (notification: any) => void;
  onDismissClick: () => void;
}

function ActionButtons({
  type,
  title,
  history,
  onLearningClick,
  onReminderClick,
  onDismissClick,
  rewardAchievementEarnedDate,
}: IActionButtons) {
  const classes = useStyles();
  let content;

  switch (type) {
    case NOTE:
      content = (
        <>
          {title && (
            <Box className={classes.courseTitleContainer}>
              <img
                src={playIcon}
                alt="play icon"
                className={classes.playIcon}
              />
              <Typography>{title}</Typography>
            </Box>
          )}
          <Button
            data-test-id = "containedBtn"
            className={classes.containedBtn}
            onClick={() => history.push("/CourseNotes")}
          >
            View Note
          </Button>
        </>
      );
      break;
    case DUEDATE:
      content =
        localStorage.getItem("user_role") === "user" ? (
          <>
            <Button className={classes.containedBtn} onClick={onLearningClick}>
              Continue Learning
            </Button>
            <Button className={classes.outlinedBtn} onClick={onReminderClick}>
              Remind Me
            </Button>
          </>
        ) : null;
      break;
    case EARNED_REWARD:
    case EARNED_ACHIEVEMENT:
      content = (
        <>
          <div className={classes.dateEstContainer}>
            <DateEST dateStr={rewardAchievementEarnedDate} />
          </div>
          <div className={classes.dateEstContainer}>
            <Button className={classes.outlinedBtn} onClick={onDismissClick}>
              dismiss
            </Button>
          </div>
        </>
      );
      break;
    case EARNED_REWARD_ADMIN:
    case EARNED_ACHIEVEMENT_ADMIN:
      content = (
        <>
          <div className={classes.dateEstContainer}>
            <DateEST dateStr={rewardAchievementEarnedDate} />
          </div>
          <div className={classes.dateEstContainer}>
            <Button
              className={clsx(classes.outlinedBtn, classes.outlinedBtnAdmin)}
              onClick={onDismissClick}
            >
              dismiss
            </Button>
          </div>
        </>
      );
      break;
    case COURSE_COMPLETE:
      content = (
        <>
          <Button
            className={clsx(classes.containedBtn, classes.containedBtnAdmin)}
            // onClick={onLearningClick}
          >
            congratulate
          </Button>
          <Button
            className={clsx(classes.outlinedBtn, classes.outlinedBtnAdmin)}
            onClick={onDismissClick}
          >
            dismiss
          </Button>
        </>
      );
      break;
    default:
      content = null;
  }

  return <Box className={classes.actionButtons}>{content}</Box>;
}

export default withRouter(ActionButtons);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      display: "flex",
      flexDirection: "column",
      marginTop: 10,
      [theme?.breakpoints?.up("sm")]: {
        flexDirection: "row",
        alignItems:"center"
      },
    },
    courseTitleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginBottom: 10,
      [theme?.breakpoints?.up("sm")]: {
        justifyContent: "flex-start",
        marginBottom: 0,
      }
    },
    playIcon: {
      marginRight: "15px",
    },
    outlinedBtn: {
      width: "100%",
      height: "55px",
      borderRadius: "16px",
      backgroundColor: "#fff",
      color: "#fd8c3e",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      marginLeft: 0,
      marginTop: 10,
      border: "1px solid #fd8c3e",
      textTransform: "capitalize" as "capitalize",
      [theme?.breakpoints?.up("sm")]: {
        marginLeft: "5px",
        marginTop: 0,
      },
    },
    outlinedBtnAdmin: {
      color: "#6C328B",
      border: "1.5px solid #6C328B",
    },
    containedBtn: {
      width: "100%",
      height: "55px",
      borderRadius: "16px",
      backgroundColor: "#fd8c3e",
      color: "#fff",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "24px",
      marginRight: 0,
      textTransform: "capitalize" as "capitalize",
      "&:hover": {
        backgroundColor: "#fd8c3e",
      },
      [theme?.breakpoints?.up("sm")]: {
        marginRight: "5px",
      },
    },
    containedBtnAdmin: {
      backgroundColor: "#6C328B",
      "&:hover": {
        backgroundColor: "#6C328B",
      },
    },
    dateEstContainer: {
      width: "100%",
      [theme?.breakpoints?.up("sm")]: {
        width: "50%",
      },
    },
  })
);
