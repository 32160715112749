import React from 'react'
// Customizable Area Start
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Avatar,
  Card,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Caret, uploadIcon } from "./assets"
import { Formik } from "formik";
import * as yup from "yup";
import './style.css';
import AddvideoController from "./AddvideoController.web";
class Addvideo extends AddvideoController {
  render() {
    return (
        <Formik
          initialValues={{
            title: "",
            description: "",
            phase: this.state.isFreeTrial ? 0 : 1,
            video_text_url: "",
            video: this.state.filename,
            link: "",
          }}
          onSubmit={async (values, {resetForm}) => {
            this.handleSubmit(values);
          }}
          validationSchema={yup.object().shape({
            title: yup.string().required("Please enter title").min(5, "min 5 characters required").max(150, "max 150 characters required"),
            description: yup.string().required("Please enter description").min(160, "min 160 characters required").max(1500, "max 1500 characters required"),
            phase: yup.number().required('Please enter phase'),
            link: yup.string().required('Enter valid url!'),
            video_text_url: yup
              .string()
              .matches(
                /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?<=\/|v\/|u\/|embed\/|shorts\/|watch\?v=)(?<!\/user\/)(?<id>[\w\-]{11})(?=\?|&|$)/,
                'Enter valid url!'
              )
            ,
          })}

        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <div style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <form onSubmit={handleSubmit}>
                  <Grid container className='video' spacing={4}>
                    <Grid container item className='file-container' md={12} >
                      {!this.state.image && <> <Grid className="file-content" item md={1}>
                        <Button variant="contained" className="upload-btn" component="label"  disabled={!!values.video_text_url }>
                          <input hidden data-test-id="file-input" accept="video/*" className='video-input' name="file"  disabled={!!values.video_text_url } type="file" onChange={(e) => this.handleImageChange(e)} />
                          <Avatar src={uploadIcon} />
                        </Button>

                      </Grid>
                        <Grid item md={12}>
                          <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>
                        </Grid>
                        <Grid item md={12}>
                          <p className="drag-subtext">Upload upto 15 MB and file dimension should be 768*1024</p>
                        </Grid>
                      </>}
                      {this.state.image && <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
                        <div style={{ minWidth: "200px", maxWidth: "200px", margin: "-16px", textAlign: "center" }} >
                          <Card >
                            <div
                              className='card-header'
                            >
                              <IconButton aria-label="close" test-id="remove-image" onClick={() => this.setState({ image: "", file: "" })}>
                                <Close />
                              </IconButton>
                            </div>
                            <img className="select-img-preview" src={
                              "https://images.unsplash.com/photo-1634157703702-3c124b455499?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80"
                            } />
                          </Card>
                          <p className='file-name'>{this.state.filename}</p>
                        </div>
                      </div>}
                    </Grid>
                    <Grid container spacing={4} >
                      <Grid item md={12} xs={12}>
                        <div className='file-container'>
                          <Grid item md={12} className="linkheading-content">
                            <p className="link-heading">Paste link here</p>
                          </Grid>
                          <Grid item md={12} className="subheading-content">
                            <p className="link-subheading">You can paste your youtube/vimeo video link here</p>
                          </Grid>
                          <Grid item xs={12} className="inputlink-content">
                            <TextField variant='standard' className="input-link" label="Enter your link here"
                              name='video_text_url'
                              value={values.video_text_url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.video_text_url && Boolean(errors.video_text_url)}
                              helperText={touched.video_text_url && errors.video_text_url}
                              disabled={this.state.file}
                            />
                          </Grid>
                        </div>
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                      <div className='file-container'>
                        <Grid item md={12} className="library-content" style={this.state.setImage ? { textAlign: 'center' } : {textAlign: 'center', marginTop: '45px'}}>
                          <Button variant="contained" className={this.state.setImage ? "upload-btn1" :"upload-btn"} component="label">
                            <img src={libraryIcon} width="36" height="36" />
                            <input hidden type="file" onChange={this.onImageChange} className="filetype" />
                            {this.state.setImage ? <img alt="preview image" width="100%" height="208px" src={this.state.setImage} /> :
                             <Avatar style={{ borderRadius: '0px' }} src={uploadIcon}/>}
                            <Avatar style={{ borderRadius: '0px' }} src={uploadIcon} />
                          </Button>
                        </Grid>
                        <Grid item md={12} style={{ textAlign: "center" }}>
                        {this.state.setImage ? <></> : <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>} 
                        </Grid>
                      </div>
                    </Grid> */}
                    </Grid>
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <p className="basic">Video Details</p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={3}>
                        <TextField
                          fullWidth
                          id="title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                          variant="outlined"
                          className="vtitle-field"
                          placeholder="Enter title"
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </Grid>
                      <Grid item lg={3}>
                        <TextField
                          fullWidth
                          id="link"
                          name="link"
                          onChange={handleChange}
                          value={values.link}
                          onBlur={handleBlur}
                          error={touched.link && Boolean(errors.link)}
                          helperText={touched.link && errors.link}
                          variant="outlined"
                          className="vtitle-field"
                          placeholder="Enter link"
                          InputLabelProps={{
                            shrink: true
                          }} />
                      </Grid>
                      <Grid item lg={3}>
                        <FormControl className="select-phases1">
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name='phase'
                            value={values.phase}
                            error={touched.phase && Boolean(errors.phase)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            IconComponent={() => null}
                            endAdornment={
                              <InputAdornment position="end">
                                <img src={Caret} />
                              </InputAdornment>
                            }
                            fullWidth
                          >
                            <MenuItem value={1}>
                              Select Phase
                            </MenuItem>
                            <MenuItem value={0}>Free Trial</MenuItem>
                            <MenuItem value={1}>Tag 1</MenuItem>
                            <MenuItem value={2}>Tag 2</MenuItem>
                            <MenuItem value={3}>Tag 3</MenuItem>
                            <MenuItem value={4}>Tag 4</MenuItem>
                            <MenuItem value={5}>Tag 5</MenuItem>
                            <MenuItem value={6}>Tag 6</MenuItem>
                            <MenuItem value={7}>Tag 7</MenuItem>
                            <MenuItem value={8}>Tag 8</MenuItem>
                            <MenuItem value={9}>Tag 9</MenuItem>
                            <MenuItem value={10}>Tag 10</MenuItem>
                          </Select>
                          <p className='invalid-feedback'>{touched.phase && errors.phase}</p>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <p className="basic">Object Description</p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          className="object-desc"
                          id="object_desc"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.description && Boolean(errors.description)}
                          helperText={touched.description && errors.description}
                          variant="outlined"
                          placeholder="Please write your description here"
                          multiline
                          rows={6}
                          maxRows={12}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ justifyContent: "end" }}>
                      <Grid item md={2}>
                        <Button style={{ width: '200px !important', marginLeft: '-60%' }} disabled={this.state.loading} className="btn-thumbnail" type='submit'>
                          {this.state.loading ? <><CircularProgress size={20} color='inherit'/>&nbsp; Uploading...</> : "Add Video"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </div>
            );
          }}
        </Formik>
    )
  }
}
export default Addvideo;
// Customizable Area End