import moment from "moment";
const { baseURL } = require("../../../framework/src/config");

/**
 * Generate course or program url using
 * course_type
 * course or program id
 * & course or program name
 */

export function genCourseOrProgramUrl(
  course_type: string,
  id: number,
  name = "title"
) {
  if (course_type === "BxBlockCoursecreation::Program") {
    return `/program-detail/Program/${id}/${name.replace(/\s/g, "-")}`;
  } else if (course_type === "BxBlockCoursecreation::Course") {
    return `/course-detail/Course/${id}/${name.replace(/\s/g, "-")}`;
  }
  return "";
}

/**
 * Capitalize the first letter of a sentence
 */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Format durations in seconds i.e. 4000 into "1 hr 6 min 40 sec"
 */
export function formatDurationToString(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  let durationString = "";

  if (hours > 0) {
    durationString += hours + " hr ";
  }

  if (minutes > 0) {
    durationString += minutes + " min ";
  }

  if (remainingSeconds > 0) {
    durationString += remainingSeconds + " sec";
  }

  return durationString.trim();
}

function getDifferenceInMinutes(date1: any, date2: any) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

export async function handleTrackerTime(time: any = "", date: any = "") {
  const TRACKING_TIME_URL = `${baseURL}/bx_block_adhocreporting/training_track`;
  let token = localStorage.getItem("token");

  try {
    // @ts-ignore
    const response = await fetch(TRACKING_TIME_URL, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ track_time: time, track_date: date }),
    });
    if (response) {
      console.log("tracking time", response);
    }
  } catch (error) {
    console.log(error, "tracking time not update");
  }
}

export function compareTime() {
  let start_time = Date.parse(
    window.localStorage.getItem("tracker_time") as any
  );
  let present_time = new Date();

  console.log(getDifferenceInMinutes(start_time, present_time), "time2");
  let minutes = getDifferenceInMinutes(start_time, present_time);
  let date = moment().format("L");
  console.log(minutes, date, "time");
  handleTrackerTime(minutes, date);
}

/**
 * Format input into MM/YYYY format
 */
export function formatExpirationDate(value: string) {
  const clearValue = value.replace(/\D+/g, "");

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}