// App.js - WEB
import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import montserrat from 'typeface-montserrat';
import { ToastContainer } from 'react-toastify';
import { Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.css";

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading.web';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import SocialMediaAccountLogin from '../../blocks/social-media-account/src/SocialMediaAccountLogin';
import SocialMediaAccountRegistration from '../../blocks/social-media-account/src/SocialMediaAccountRegistration';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Sorting from '../../blocks/sorting/src/Sorting';
import Videos from '../../blocks/videos/src/Videos';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Splitpayments2 from '../../blocks/splitpayments2/src/Splitpayments2';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import Coursecreation from '../../blocks/coursecreation/src/Coursecreation';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Splashscreen from '../../blocks/splashscreen/src/TermPage.web';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import FormApprovalWorkflow from '../../blocks/formapprovalworkflow/src/FormApprovalWorkflow';
import Stripegatewayapifrontend2 from '../../blocks/stripegatewayapifrontend2/src/Stripegatewayapifrontend2';
import VideoLibrary from '../../blocks/videolibrary/src/VideoLibrary';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Automaticreminders from '../../blocks/automaticreminders/src/Automaticreminders';
import QuestionBank from '../../blocks/questionbank/src/QuestionBank';
import Notifications from '../../blocks/notifications/src/Notifications';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import AutomaticCheckoutCalculation from '../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import VideoEmbeddingYoutube from '../../blocks/videoembeddingyoutube/src/VideoEmbeddingYoutube';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders2.web';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import Analytics from '../../blocks/analytics/src/Analytics';
import CallRecording from '../../blocks/call-recording/src/CallRecording';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Shopifyintegration11 from '../../blocks/shopifyintegration11/src/Shopifyintegration11';
import DragDropInterface from '../../blocks/dragdropinterface/src/DragDropInterface';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import Rolesandpermissions2 from '../../blocks/rolesandpermissions2/src/Rolesandpermissions2';
import PricingEngine from '../../blocks/pricingengine/src/PricingEngine';
import ThemeBlock from '../../blocks/themeblock/src/ThemeBlock';
import AutomaticRenewals from '../../blocks/automaticrenewals/src/AutomaticRenewals';
import Playlist2 from '../../blocks/playlist2/src/Playlist2';
import ViewChat from '../../blocks/chat/src/ViewChat';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import OrderManagement from '../../blocks/ordermanagement/src/OrderManagement';
import Cfcheckoutlink from '../../blocks/cfcheckoutlink/src/Cfcheckoutlink';
import InventoryManagement from '../../blocks/inventorymanagement/src/InventoryManagement';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Conversationthreading2 from '../../blocks/conversationthreading2/src/Conversationthreading2';
import ProgramContent from '../../blocks/coursecreation/src/ProgramContent.web.tsx'

/** Web components from RN repo starts */
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import AdminDashboard from '../../blocks/dashboard/src/AdminDashboard.web';
import CreateQuiz from '../../blocks/questionbank/src/CreateQuiz.web';
import DummyQuizListing from '../../blocks/questionbank/src/DummyQuizListing';
import Subscription from '../../blocks/shoppingcart/src/Subscription.web';
import UserManagementWeb from '../../blocks/accountgroups/src/UserManagement.web';
import CreateUser from '../../blocks/accountgroups/src/CreateUser.web';
import EditUser from '../../blocks/accountgroups/src/EditUser.web';
import UserManage from '../../blocks/accountgroups/src/UserManage.web';
import UserAssignContent from '../../blocks/accountgroups/src/UserAssignContent.web.tsx';
import ContentManagement from '../../blocks/catalogue/src/Catalogue.web';
import ManageAssign from '../../blocks/servicespecificsettingsadmin2/src/ManageAssign.web.tsx';
import ManageContentLibrarylist from '../../blocks/videolibrary/src/VideoLibrary.web.tsx';
import ManageContentFolder from '../../blocks/contentmanagement/src/ManageContentFolder.web';
import ManageThumbnailLibrary from '../../blocks/contentmanagement/src/ManageThumbnailLibrary.web';
import ManageThumbnailUser from '../../blocks/contentmanagement/src/ManageThumbnailUser.web';
import ManageVideoCourse from '../../blocks/contentmanagement/src/ManageVideoCourse.web';
import Addvideo from '../../blocks/contentmanagement/src/Addvideo.web';
import Editvideo from '../../blocks/contentmanagement/src/Editvideo.web';
import CourseCreation from '../../blocks/coursecreation/src/Coursecreation.web';
import BuildCourse from '../../blocks/coursecreation/src/BuldCourse.web';
import AddPreview from '../../blocks/coursecreation/src/AddPreview.web';
import AddReward from '../../blocks/coursecreation/src/AddReward.web';
import AddAchievement from '../../blocks/coursecreation/src/AddAchievement.web';
import IconLibraryWeb from '../../blocks/coursecreation/src/IconLibrary.web';
import AddFolderWeb from '../../blocks/coursecreation/src/AddFolder.web';
import AddIconWeb from '../../blocks/coursecreation/src/AddIcon.web';
import RewardListWeb from '../../blocks/coursecreation/src/RewardList.web';
import AchievementsListWeb from '../../blocks/coursecreation/src/AchievementsList.web';
import ProgramCreationWeb from '../../blocks/coursecreation/src/ProgramCreation.web';
import ContentRoleCreationWeb from '../../blocks/coursecreation/src/ContentRolePage.web.tsx';
import BuildProgramWeb from '../../blocks/coursecreation/src/BuildProgram.web';
import AddObjectWeb from '../../blocks/coursecreation/src/AddObject.web';
import ContentRoleList from '../../blocks/coursecreation/src/ManageContentRole.web';
import ManageContentRoleList from '../../blocks/coursecreation/src/ManageContentRoleList.web';
import ExistingCourseList from '../../blocks/coursecreation/src/ExistingCourseList.web';
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles';
import AllNoteList from '../../blocks/customisableuserprofiles/src/AllNoteList.web';
import Customform from '../../blocks/customform/src/CustomForm1.web'
import AddNoteModal from '../../blocks/customisableuserprofiles/src/AddNoteModal.web';
import CourseNotes from '../../blocks/customisableuserprofiles/src/CourseNotes.web';
import CourseReports from '../../blocks/customisableuserprofiles/src/CourseReports.web';
import AdminCustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/AdminCustomisableUserProfiles.web';
import InsightOverview from '../../blocks/customisableuserprofiles/src/InsightOverview.web';
import Reports from '../../blocks/customisableuserprofiles/src/Reports.web';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import AssignContent from '../../blocks/email-account-login/src/AssignContent.web';
import UserSendInviteWeb from '../../blocks/email-account-login/src/UserSendInvite.web';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import AccountSetup from '../../blocks/email-account-registration/src/AccountSetup.web.tsx';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPassword.web';
import Search from '../../blocks/Library2/src/Search.web';
// web library
import Library2Web from '../../blocks/Library2/src/Library2.web';
import MyContentWeb from '../../blocks/Library2/src/MyContent.web.tsx';
// course
import CoursePage from '../../blocks/Library2/src/CoursePage.web';
//program page
import ProgramPage from '../../blocks/Library2/src/ProgramPage.web';
//lesson page
import LessonPage from '../../blocks/Library2/src/LessonPage.web.tsx';
//phase program
import PhaseProgram from '../../blocks/Library2/src/PhaseProgram.web';
//title page
import TitlePage from '../../blocks/Library2/src/TiltePage.web';
//personal library
import PersonalLibrary from '../../blocks/Library2/src/PersonalLibrary.web';
//xpand library
import XpandLibrary from '../../blocks/Library2/src/XpandLibrary.web';
//CourseCompletion
import CourseCompletion from '../../blocks/Library2/src/CourseCompletion.web';
import InProgress from '../../blocks/Library2/src/InProgress.web';
import NewlyAdded from '../../blocks/Library2/src/NewlyAdded.web';
import Completed from '../../blocks/Library2/src/Completed.web';
import ObjectDetails from '../../blocks/Library2/src/ObjectDetails.web';
import OnboardingguideWeb from '../../blocks/onboardingguide/src/OnboardingCategory.web';
import OnboardingCategoryWeb from '../../blocks/onboardingguide/src/OnboardingCategory.web';
import VideoWeb from '../../blocks/videos/src/video.web';
import VideoAddWeb from '../../blocks/videos/src/videoAdd.web';
import NotFoundPage from '../../components/src/NotFoundPage';
//Achievements
import AchievementListing from '../../blocks/certificationtracking/src/RewardAndAchievement/AchievementListing.web';
import AchievementDetails from '../../blocks/certificationtracking/src/RewardAndAchievement/AchievementDetails.web';
import AllRewardsListing from '../../blocks/certificationtracking/src/RewardAndAchievement/AllRewardsListing.web';
import AllRewardsDetails from '../../blocks/certificationtracking/src/RewardAndAchievement/AllRewardsDetails.web';
import AchievementCongratulations from '../../blocks/certificationtracking/src/RewardAndAchievement/AchievementCongratulations.web';
import CategoryWeb from '../../blocks/categoriessubcategories/src/Category.web.tsx';
import CourseCreationformTwo from "../../blocks/coursecreation/src/CourseCreationformTwo.web"
import AddContentPage from '../../blocks/coursecreation/src/AddContentPage.web';
import Thumbnailmanagement from "../../blocks/imagemanagement2/src/Thumbnailmanagement.web.tsx"
import Addthumbnail from "../../blocks/imagemanagement2/src/Addthumbnail.web.tsx"
import Iconmanagement from "../../blocks/imagemanagement2/src/Iconmanagement.web.tsx"
import AddIcon from "../../blocks/imagemanagement2/src/AddIcon.web.tsx"
import TeamBuilder from "../../blocks/teambuilder/src/TeamBuilder.web.tsx"
import CreateTeam from "../../blocks/teambuilder/src/CreateTeam.web.tsx"
import ShowBranch from "../../blocks/organisationhierarchy/src/ShowBranch.web.tsx"
import CreateBranch from "../../blocks/organisationhierarchy/src/CreateBranch.web.tsx"
import CreateAchievement from "../../blocks/educationalgamification/src/CreateAchievement.web.tsx"
import CreateReward from "../../blocks/educationalgamification/src/CreateReward.web.tsx";
import ManageReward from "../../blocks/educationalgamification/src/ManageReward.web.tsx";
import ManageAchievement from "../../blocks/educationalgamification/src/ManageAchievement.web.tsx";
//Gamification
import Gamification from "../../blocks/educationalgamification/src/Gamification.web"

import BranchAssignContent from '../../blocks/servicespecificsettingsadmin2/src/BranchAssignContent.web.tsx';
import EditBranchAssignContent from '../../blocks/servicespecificsettingsadmin2/src/EditBranchAssignContent.web.tsx';
import BranchDetails from "../../blocks/servicespecificsettingsadmin2/src/BranchDetails.web.tsx"
import BranchDetailsEdit from "../../blocks/servicespecificsettingsadmin2/src/BranchDetailsEdit.web.tsx"
import SelectContent from "../../blocks/educationalgamification/src/SelectContent.web.tsx"
import AdminReport from '../../blocks/analytics/src/AdminReport.web.tsx';
/** Web components from RN repo ends */

/** Default web routes start */
// const routeMap = {
// AccountGroups:{
//  component:AccountGroups,
// path:"/AccountGroups"},
// BulkUploading:{
//  component:BulkUploading,
// path:"/BulkUploading"},
// StripePayments:{
//  component:StripePayments,
// path:"/StripePayments"},
// SocialMediaAccountLogin:{
//  component:SocialMediaAccountLogin,
// path:"/SocialMediaAccountLogin"},
// SocialMediaAccountRegistration:{
//  component:SocialMediaAccountRegistration,
// path:"/SocialMediaAccountRegistration"},
// Filteritems:{
//  component:Filteritems,
// path:"/Filteritems"},
// Filteroptions:{
//  component:Filteroptions,
// path:"/Filteroptions"},
// ImportExportData:{
//  component:ImportExportData,
// path:"/ImportExportData"},
// DocumentOpener:{
//  component:DocumentOpener,
// path:"/DocumentOpener"},
// SocialMediaAccountLoginScreen:{
//  component:SocialMediaAccountLoginScreen,
// path:"/SocialMediaAccountLoginScreen"},
// ForgotPassword:{
//  component:ForgotPassword,
// path:"/ForgotPassword"},
// ForgotPasswordOTP:{
//  component:ForgotPasswordOTP,
// path:"/ForgotPasswordOTP"},
// NewPassword:{
//  component:NewPassword,
// path:"/NewPassword"},
// VisualAnalytics:{
//  component:VisualAnalytics,
// path:"/VisualAnalytics"},
// Customisableusersubscriptions:{
//  component:Customisableusersubscriptions,
// path:"/Customisableusersubscriptions"},
// SubscriptionDetails:{
//  component:SubscriptionDetails,
// path:"/SubscriptionDetails"},
// Sorting:{
//  component:Sorting,
// path:"/Sorting"},
// Videos:{
//  component:Videos,
// path:"/Videos"},
// Catalogue:{
//  component:Catalogue,
// path:"/Catalogue"},
// SocialMediaAccountRegistrationScreen:{
//  component:SocialMediaAccountRegistrationScreen,
// path:"/SocialMediaAccountRegistrationScreen"},
// Emailnotifications2:{
//  component:Emailnotifications2,
// path:"/Emailnotifications2"},
// Splitpayments2:{
//  component:Splitpayments2,
// path:"/Splitpayments2"},
// Onboardingguide:{
//  component:Onboardingguide,
// path:"/Onboardingguide"},
// Coursecreation:{
//  component:Coursecreation,
// path:"/Coursecreation"},
// CountryCodeSelector:{
//  component:CountryCodeSelector,
// path:"/CountryCodeSelector"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
// PhoneNumberInput:{
//  component:PhoneNumberInput,
// path:"/PhoneNumberInput"},
// AdditionalDetailForm:{
//  component:AdditionalDetailForm,
// path:"/AdditionalDetailForm"},
// Settings2:{
//  component:Settings2,
// path:"/Settings2"},
// Notificationsettings:{
//  component:Notificationsettings,
// path:"/Notificationsettings"},
// FormApprovalWorkflow:{
//  component:FormApprovalWorkflow,
// path:"/FormApprovalWorkflow"},
// Stripegatewayapifrontend2:{
//  component:Stripegatewayapifrontend2,
// path:"/Stripegatewayapifrontend2"},
// VideoLibrary:{
//  component:VideoLibrary,
// path:"/VideoLibrary"},
// Pushnotifications:{
//  component:Pushnotifications,
// path:"/Pushnotifications"},
// Automaticreminders:{
//  component:Automaticreminders,
// path:"/Automaticreminders"},
// // QuestionBank:{
// //  component:QuestionBank,
// // path:"/QuestionBank"},
// Notifications:{
//  component:Notifications,
// path:"/Notifications"},
// MobileAccountLoginBlock:{
//  component:MobileAccountLoginBlock,
// path:"/MobileAccountLoginBlock"},
// AutomaticCheckoutCalculation:{
//  component:AutomaticCheckoutCalculation,
// path:"/AutomaticCheckoutCalculation"},
// EmailAccountRegistration:{
//  component:EmailAccountRegistration,
// path:"/EmailAccountRegistration"},
// Categoriessubcategories:{
//  component:Categoriessubcategories,
// path:"/Categoriessubcategories"},
// VideoEmbeddingYoutube:{
//  component:VideoEmbeddingYoutube,
// path:"/VideoEmbeddingYoutube"},
// Adminconsole2:{
//  component:Adminconsole2,
// path:"/Adminconsole2"},
// ShoppingCartOrders:{
//  component:ShoppingCartOrders,
// path:"/ShoppingCartOrders"},
// AddShoppingCartOrderItem:{
//  component:AddShoppingCartOrderItem,
// path:"/AddShoppingCartOrderItem"},
// Analytics:{
//  component:Analytics,
// path:"/Analytics"},
// CallRecording:{
//  component:CallRecording,
// path:"/CallRecording"},
// EmailAccountLoginBlock:{
//  component:EmailAccountLoginBlock,
// path:"/EmailAccountLoginBlock"},
// Shopifyintegration11:{
//  component:Shopifyintegration11,
// path:"/Shopifyintegration11"},
// DragDropInterface:{
//  component:DragDropInterface,
// path:"/DragDropInterface"},
// Tasks:{
//  component:Tasks,
// path:"/Tasks"},
// TaskList:{
//  component:TaskList,
// path:"/TaskList"},
// Task:{
//  component:Task,
// path:"/Task"},
// CarouselDisplay:{
//  component:CarouselDisplay,
// path:"/CarouselDisplay"},
// Reviews:{
//  component:Reviews,
// path:"/Reviews"},
// AddReview:{
//  component:AddReview,
// path:"/AddReview"},
// Rolesandpermissions2:{
//  component:Rolesandpermissions2,
// path:"/Rolesandpermissions2"},
// PricingEngine:{
//  component:PricingEngine,
// path:"/PricingEngine"},
// ThemeBlock:{
//  component:ThemeBlock,
// path:"/ThemeBlock"},
// AutomaticRenewals:{
//  component:AutomaticRenewals,
// path:"/AutomaticRenewals"},
// Playlist2:{
//  component:Playlist2,
// path:"/Playlist2"},
// // GroupVideoCall:{
// //  component:GroupVideoCall,
// // path:"/GroupVideoCall"},
// // Chat:{
// //  component:Chat,
// // path:"/Chat"},
// ViewChat:{
//  component:ViewChat,
// path:"/ViewChat"},
// Scheduling:{
//  component:Scheduling,
// path:"/Scheduling"},
// OrderManagement:{
//  component:OrderManagement,
// path:"/OrderManagement"},
// Cfcheckoutlink:{
//  component:Cfcheckoutlink,
// path:"/Cfcheckoutlink"},
// InventoryManagement:{
//  component:InventoryManagement,
// path:"/InventoryManagement"},
// OTPInputAuth:{
//  component:OTPInputAuth,
// path:"/OTPInputAuth"},
// Conversationthreading2:{
//  component:Conversationthreading2,
// path:"/Conversationthreading2"},

//   Home: {
//     component: HomeScreen,
//     path: '/',
//     exact: true
//   },
//   InfoPage: {
//     component: InfoPage,
//     path: '/InfoPage'
//   },

//   AlertWeb: {
//     component: AlertBlock,
//     path: "*/AlertWeb",
//     modal: true
//   }

// };
/** Default web routes ends */

/** Web routes from the RN repo starts */

const routeMap = {
  // UserGroups2: {
  //   path: '/UserGroups2',
  // },
  // VisualAnalytics: {
  //   component: VisualAnalytics,
  //   path: '/VisualAnalytics',
  // },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles',
    roles: ['user'],
  },
  AllNoteList: {
    component: AllNoteList,
    path: '/AllNoteList',
    roles: ['user'],
  },
  AddNoteModal: {
    component: AddNoteModal,
    path: '/AddNoteModal',
    roles: ['user'],
  },
  CourseNotes: {
    component: CourseNotes,
    path: '/CourseNotes',
    roles: ['user'],
  },
  InsightOverview: {
    component: InsightOverview,
    path: '/InsightOverview',
    roles: ['user'],
  },
  Reports: {
    component: Reports,
    path: '/Reports',
    roles: ['user'],
  },
  CourseReports: {
    component: CourseReports,
    path: '/CourseReports',
    roles: ['user'],
  },
  Subscription: {
    component: Subscription,
    path: '/Subscription',
    roles: ['user'],
  },
  AssignContent: {
    component: AssignContent,
    path: '/AssignContent',
    roles: ['user'],
  },
  // PaidContent: {
  //   component: PaidContent,
  //   path: '/PaidContent',
  // },
  // AutomaticCheckoutCalculation2: {
  //   component: AutomaticCheckoutCalculation2,
  //   path: '/AutomaticCheckoutCalculation2',
  // },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary',
  },
  CreateTeam: {
    component: CreateTeam,
    path: '/CreateTeam/:type/:id?/:redirectToBranch?',
    roles: ['expert', 'admin', 'super_admin'],
  },
  
  // ShopifyIntegration11: {
  //   component: Shopifyintegration11,
  //   path: '/ShopifyIntegration11',
  // },
  // Ordermanagement: {
  //   component: OrderManagement,
  //   path: '/Ordermanagement',
  // },
  // OrderDetails: {
  //   component: OrderDetails,
  //   path: '/OrderDetails',
  // },
  // TaskAllocator: {
  //   component: TaskAllocator,
  //   path: '/TaskAllocator',
  // },
  // CarouselDisplay: {
  //   component: CarouselDisplay,
  //   path: '/CarouselDisplay',
  // },
  // SocialMediaAccountLoginScreen: {
  //   component: SocialMediaAccountLoginScreen,
  //   path: '/SocialMediaAccountLoginScreen',
  // },
  // SplitPayments: {
  //   component: Splitpayments2,
  //   path: '/SplitPayments',
  // },
  // QuestionBank: {
  //   component: QuestionBank,
  //   path: '/QuestionBank',
  // },
  // OrganisationHierarchy: {
  //   component: OrganisationHierarchy,
  //   path: '/OrganisationHierarchy',
  // },
  // TicketspiceIntegration2: {
  //   component: TicketspiceIntegration2,
  //   path: '/TicketspiceIntegration2',
  // },
  // BreadcrumbNavigation: {
  //   component: BreadcrumbNavigation,
  //   path: '/BreadcrumbNavigation',
  // },
  // PricingEngine2: {
  //   component: PricingEngine2,
  //   path: '/PricingEngine2',
  // },
  // Documentation: {
  //   component: Documentation,
  //   path: '/Documentation',
  // },
  // DataImportexportcsv: {
  //   component: DataImportexportcsv,
  //   path: '/DataImportexportcsv',
  // },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/cart',
    roles: ['user'],
  },
  ShoppingCartOrdersFailed: {
    component: ShoppingCartOrders,
    path: '/order-failed',
    roles: ['user'],
  },
  ShoppingCartOrdersSuccess: {
    component: ShoppingCartOrders,
    path: '/order-success',
    roles: ['user'],
  },
  // AddShoppingCartOrderItem: {
  //   component: AddShoppingCartOrderItem,
  //   path: '/AddShoppingCartOrderItem',
  // },
  // ProjectTemplates: {
  //   component: ProjectTemplates,
  //   path: '/ProjectTemplates',
  // },
  // ProjecttaskTracking2: {
  //   component: ProjecttaskTracking2,
  //   path: '/ProjecttaskTracking2',
  // },
  // OTPInputAuth: {
  //   component: OTPInputAuth,
  //   path: '/OTPInputAuth',
  // },
  // UserGroups: {
  //   component: UserGroups,
  //   path: '/UserGroups',
  // },
  // RolesPermissions2: {
  //   component: RolesPermissions2,
  //   path: '/RolesPermissions2',
  // },
  // Payments: {
  //   component: Payments,
  //   path: '/Payments',
  // },
  // Reviews: {
  //   component: Reviews,
  //   path: '/Reviews',
  // },
  AddReview: {
    component: AddReview,
    path: '/AddReview',
    roles: ['expert', 'admin', 'super_admin'],
  },
  Videos: {
    component: VideoWeb,
    path: '/videos',
    roles: ['expert', 'admin', 'super_admin'],
  },
  IconLibrary: {
    component: IconLibraryWeb,
    path: '/iconLibrary',
    roles: ['expert', 'admin', 'super_admin'],
  },
  UserManagement: {
    component: UserManagementWeb,
    path: '/usermanagement',
    exact: true,
    roles: ['expert', 'admin', 'super_admin'],
  },
  CreateUser: {
    component: CreateUser,
    path: '/usermanagement/create-user',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditUser: {
    component: EditUser,
    path: '/usermanagement/edit-user/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  UserManage: {
    component: UserManage,
    path: '/user-manage/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  UserAssignContent: {
    component: UserAssignContent,
    path: '/UserAssignContent/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  BranchAssignContent: {
    component: BranchAssignContent,
    path: '/BranchAssignContent/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditBranchAssignContent: {
    component: EditBranchAssignContent,
    path: '/EditBranchAssignContent/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddFolder: {
    component: AddFolderWeb,
    path: '/addFolder',
    roles: ['expert', 'admin', 'super_admin'],
  },
  VideoAdd: {
    component: VideoAddWeb,
    path: '/VideoAdd/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // StripeGatewayApifrontend: {
  //   component: StripeGatewayApifrontend,
  //   path: '/StripeGatewayApifrontend',
  // },
  // Notes: {
  //   component: Notes,
  //   path: '/Notes',
  // },
  // PromoteContent: {
  //   component: PromoteContent,
  //   path: '/PromoteContent',
  // },
  Customform: {
    component: Customform,
     path:'/Customform/:type/:id?/:courseID/:contentType/:parentType/:previousLessonId/:lessonEditId',
    roles: ['expert', 'admin', 'super_admin'],
  },

  // GroupChat: {
  //   component: GroupChat,
  //   path: '/GroupChat',
  // },
  // Pushnotifications: {
  //   component: Pushnotifications,
  //   path: '/Pushnotifications',
  // },
  // TwilioIntegration2: {
  //   component: TwilioIntegration2,
  //   path: '/TwilioIntegration2',
  // },
  // VideoAds: {
  //   component: VideoAds,
  //   path: '/VideoAds',
  // },
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: '/forgotpassword',
  },
  // ForgotPasswordOTP: {
  //   component: ForgotPasswordOTP,
  //   path: '/ForgotPasswordOTP',
  // },
  NewPassword: {
    component: ResetPasswordWeb,
    path: '/reset_password/:token',
  },
  // DeepLinking: {
  //   component: DeepLinking,
  //   path: '/DeepLinking',
  // },
  // Notifications: {
  //   component: Notifications,
  //   path: '/Notifications',
  // },
  // DocumentOpener: {
  //   component: DocumentOpener,
  //   path: '/DocumentOpener',
  // },
  // LiveStreaming: {
  //   component: LiveStreaming,
  //   path: '/LiveStreaming',
  // },
  // Playlist4: {
  //   component: Playlist4,
  //   path: '/Playlist4',
  // },
  // DynamicContent: {
  //   component: DynamicContent,
  //   path: '/DynamicContent',
  // },
  // Analytics: {
  //   component: Analytics,
  //   path: '/Analytics',
  // },
  // Customisableusersubscriptions: {
  //   component: Customisableusersubscriptions,
  //   path: '/Customisableusersubscriptions',
  // },
  // SubscriptionDetails: {
  //   component: SubscriptionDetails,
  //   path: '/SubscriptionDetails',
  // },
  // Filteritems: {
  //   component: Filteritems,
  //   path: '/Filteritems',
  // },
  // Filteroptions: {
  //   component: Filteroptions,
  //   path: '/Filteroptions',
  // },
  // FormApprovalWorkflow: {
  //   component: FormApprovalWorkflow,
  //   path: '/FormApprovalWorkflow',
  // },
  // Referrals: {
  //   component: Referrals,
  //   path: '/Referrals',
  // },
  // AdminConsole3: {
  //   component: AdminConsole3,
  //   path: '/AdminConsole3',
  // },
  // Settings5: {
  //   component: Settings5,
  //   path: '/Settings5',
  // },
  // Library2: {
  //   component: Library2,
  //   path: '/Library2',
  // },
  // CustomAdvertisements: {
  //   component: CustomAdvertisements,
  //   path: '/CustomAdvertisements',
  // },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditBulkUploading: {
    component: BulkUploading,
    path: '/Edit-BulkUploading',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // AutomaticRenewals: {
  //   component: AutomaticRenewals,
  //   path: '/AutomaticRenewals',
  // },
  // ThemeBlock: {
  //   component: ThemeBlock,
  //   path: '/ThemeBlock',
  // },
  // Scheduling: {
  //   component: Scheduling,
  //   path: '/Scheduling',
  // },
  Categoriessubcategories: {
    component: CategoryWeb,
    path: '/Categoriessubcategories',
    roles: ['user'],
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ExistingCourseList: {
    component: ExistingCourseList,
    path: '/ExistingCourseList',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // CertificationTracking: {
  //   component: CertificationTracking,
  //   path: '/CertificationTracking',
  // },
  // CountryCodeSelector: {
  //   component: CountryCodeSelector,
  //   path: '/CountryCodeSelector',
  // },
  // LeadManagement: {
  //   component: LeadManagement,
  //   path: '/LeadManagement',
  // },
  // PhoneNumberInput: {
  //   component: PhoneNumberInput,
  //   path: '/PhoneNumberInput',
  // },
  // AdditionalDetailForm: {
  //   component: AdditionalDetailForm,
  //   path: '/AdditionalDetailForm',
  // },
  // SocialMediaAccountRegistrationScreen: {
  //   component: SocialMediaAccountRegistrationScreen,
  //   path: '/SocialMediaAccountRegistrationScreen',
  // },
  // AutomaticReminders: {
  //   component: AutomaticReminders,
  //   path: '/AutomaticReminders',
  // },
  // Sorting: {
  //   component: Sorting,
  //   path: '/Sorting',
  // },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ShowBranch: {
    component: ShowBranch,
    path: '/ShowBranch',
    roles: ['expert', 'admin', 'super_admin'],
  },
  CreateBranch: {
    component: CreateBranch,
    path: '/CreateBranch/:type/:id?',
    roles: ['expert', 'admin', 'super_admin'],
  },
  CreateAchievement: {
    component: CreateAchievement,
    path: '/CreateAchievement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditAchievement: {
    component: CreateAchievement,
    path: '/EditAchievement/:achievementId',
    roles: ['expert', 'admin', 'super_admin'],
  },
  CreateReward: {
    component: CreateReward,
    path: '/CreateReward',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditReward: {
    component: CreateReward,
    path: '/EditReward/:rewardId',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageReward: {
    component: ManageReward,
    path: '/ManageReward',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageAchievement: {
    component: ManageAchievement,
    path: '/ManageAchievement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  BranchDetails: {
    component: BranchDetails,
    path: '/BranchDetails/:id/:type?',
    roles: ['expert', 'admin', 'super_admin'],
  },
  BranchDetailsEdit: {
    component: BranchDetailsEdit,
    path: '/BranchDetailsEdit/:id/:type?',
    roles: ['expert', 'admin', 'super_admin'],
  },
  SelectContent: {
    component: SelectContent,
    path: '/SelectContent',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // Catalogue: {
  //   component: Catalogue,
  //   path: '/Catalogue',
  // },
  // Notificationsettings: {
  //   component: Notificationsettings,
  //   path: '/Notificationsettings',
  // },
  // PremiumPlanComparison: {
  //   component: PremiumPlanComparison,
  //   path: '/PremiumPlanComparison',
  // },
  // HamburgerMenu: {
  //   component: HamburgerMenu,
  //   path: '/HamburgerMenu',
  // },

  ProgramContent : {
    component: ProgramContent,
    path: '/ProgramContent/:id/:type',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistrationWeb,
    path: '/signup',
  },
  AccountSetup: {
    component: AccountSetup,
    path: '/signupsetup',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // VideoEmbeddingYoutube: {
  //   component: VideoEmbeddingYoutube,
  //   path: '/VideoEmbeddingYoutube',
  // },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    roles: ['user'],
  },
  // SwitchAccounts: {
  //   component: SwitchAccounts,
  //   path: '/SwitchAccounts',
  // },
  Splashscreen: {
    component: Splashscreen,
    path: '/terms',
    roles: ['user'],
  },
  // TargetedFeed: {
  //   component: TargetedFeed,
  //   path: '/TargetedFeed',
  // },
  Onboardingguide: {
    component: OnboardingguideWeb,
    path: '/Onboardingguide',
    roles: ['user'],
  },
  // ElasticSearch: {
  //   component: ElasticSearch,
  //   path: '/ElasticSearch',
  // },
  // AdManager: {
  //   component: AdManager,
  //   path: '/AdManager',
  // },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlockWeb,
    path: '/login',
  },
  // WelcomeSplashscreen: {
  //   component: WelcomeSplashscreen,
  //   path: '/WelcomeSplashscreen',
  //   roles: ['user'],
  // },
  // LiveChat2: {
  //   component: LiveChat2,
  //   path: '/LiveChat2',
  // },
  CourseCreation: {
    component: CourseCreation,
    path: '/CourseCreation/:id/:type',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ProgramCreation: {
    component: ProgramCreationWeb,
    path: '/ProgramCreation/:id/:type',
    roles: ['expert', 'admin', 'super_admin'],
  },
  contentRoleEdit: {
    component: ContentRoleCreationWeb,
    path: '/ContentRoleCreationWeb/edit/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  contentRoleCreation: {
    component: ContentRoleCreationWeb,
    path: '/ContentRoleCreationWeb',
    roles: ['expert', 'admin', 'super_admin'],
  },
  BuildCourse: {
    component: BuildCourse,
    path: '/BuildCourse/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  BuildProgram: {
    component: BuildProgramWeb,
    path: '/BuildProgram/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddPreview: {
    component: AddPreview,
    path: '/AddPreview',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddReward: {
    component: AddReward,
    path: '/AddReward',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddAchievement: {
    component: AddAchievement,
    path: '/AddAchievement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddIcon: {
    component: AddIconWeb,
    path: '/AddIcon',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddObject: {
    component: AddObjectWeb,
    path: '/AddObject/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  RewardList: {
    component: RewardListWeb,
    path: '/RewardList',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AchievementsList: {
    component: AchievementsListWeb,
    path: '/AchievementsList',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ContentRoleList: {
    component: ContentRoleList,
    path: '/ContentRoleList',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageContentRoleList: {
    component: ManageContentRoleList,
    path: '/ManageContentRoleList/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  UserSendInvite: {
    component: UserSendInviteWeb,
    path: '/UserSendInvite',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddContentPage: {
    component: AddContentPage,
    path: '/AddContentPage/:type/:id/:lesson',
    roles: ['expert', 'admin', 'super_admin'],
  },
  // DragDropInterface: {
  //   component: DragDropInterface,
  //   path: '/DragDropInterface',
  // },
  // InappPurchasing: {
  //   component: InappPurchasing,
  //   path: '/InappPurchasing',
  // },
  // EmailNotifications: {
  //   component: EmailNotifications,
  //   path: '/EmailNotifications',
  // },

  Home: {
    component: Dashboard,
    path: '/',
    exact: true,
    roles: ['user'],
  },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage',
  // },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true,
  // },
  MyContent: {
    component: MyContentWeb,
    path: '/MyContentWeb',
    roles: ['user'],
  },
  InProgress: {
    component: InProgress,
    path: '/InProgress',
    roles: ['user'],
  },
  Library2WebPage: {
    component: Library2Web,
    path: '/library',
    roles: ['user'],
  },
  SearchWebPage: {
    component: Search,
    path: '/Search',
    roles: ['user'],
  },
  CourseWebPage: {
    component: CoursePage,
    path: '/course-detail/:type/:id/:title',
    roles: ['user'],
  },

  ProgramWebPage: {
    component: ProgramPage,
    path: '/program-detail/:type/:id/:title',
    exact: true,
    roles: ['user'],
  },

  LessonPage: {
    component: LessonPage,
    path: '/lesson-detail',
    exact: true,
    roles: ['user'],
  },

  ObjectDetailsPage: {
    component: ObjectDetails,
    path: '/program-detail/:type/:id/:title/objects/:objectId',
    roles: ['user'],
  },

  XpandPageWeb: {
    component: XpandLibrary,
    path: '/Xpand',
    roles: ['user'],
  },

  // PhaseProgramWebPage: {
  //   component: PhaseProgram,
  //   path: '/phase',
  // },

  TitlePageWeb: {
    component: TitlePage,
    path: '/title/:id',
    roles: ['user'],
  },

  CourseCompletionWebPage: {
    component: CourseCompletion,
    path: '/CourseCompletion',
    roles: ['user'],
  },

  PersonalPageWeb: {
    component: PersonalLibrary,
    path: '/personal',
    roles: ['user'],
  },
  InProgressPageWeb: {
    component: InProgress,
    path: '/course-inProgess',
    roles: ['user'],
  },

  NewlyAddedPageWeb: {
    component: NewlyAdded,
    path: '/course-newlyAdded',
    roles: ['user'],
  },

  CompletedPageWeb: {
    component: Completed,
    path: '/course-completed',
    roles: ['user'],
  },

  CreateQuiz: {
    component: CreateQuiz,
    path: '/create-quiz',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditQuiz: {
    component: CreateQuiz,
    path: '/edit-quiz/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  QuizListing: {
    component: DummyQuizListing,
    path: '/quiz-list',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageContentLibrarylist: {
    component: ManageContentLibrarylist,
    path: '/ManageContentLibrary',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageContentFolder: {
    component: ManageContentFolder,
    path: '/ManageContentFolder/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageThumbnailLibrary: {
    component: ManageThumbnailLibrary,
    path: '/ManageThumbnailLibrary',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageAssign: {
    component: ManageAssign,
    path: '/ManageAssign',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageThumbnailUser: {
    component: ManageThumbnailUser,
    path: '/ManageThumbnailUser/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  ManageVideoCourse: {
    component: ManageVideoCourse,
    path: '/ManageVideoCourse',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AdminDashboard: {
    component: AdminDashboard,
    path: '/Home',
    roles: ['expert', 'admin', 'super_admin'],
  },
  Addvideo: {
    component: Addvideo,
    path: '/Addvideo/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
 
  Editvideo: {
    component: Editvideo,
    path: '/Editvideo/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },

  AdminCustomisableUserProfiles: {
    component: AdminCustomisableUserProfiles,
    path: '/AdminCustomisableUserProfiles/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AchievementListing: {
    component: AchievementListing,
    path: '/AchievementListing',
    roles: ['user'],
  },
  AchievementDetails: {
    component: AchievementDetails,
    path: '/AchievementDetails/:id',
    roles: ['user'],
  },
  AchievementCongratulations: {
    component: AchievementCongratulations,
    path: '/AchievementCongratulations/:id',
    roles: ['user'],
  },
  AllRewardsListing: {
    component: AllRewardsListing,
    path: '/AllRewardsListing',
    roles: ['user'],
  },
  AllRewardsDetails: {
    component: AllRewardsDetails,
    path: '/AllRewardsDetails/:id',
    roles: ['user'],
  },
  Thumbnailmanagement: {
    component: Thumbnailmanagement,
    path: '/Thumbnailmanagement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  Editthumbnail: {
    component: Addthumbnail,
    path: '/addthumbnail/edit/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  Addthumbnail: {
    component: Addthumbnail,
    path: '/Addthumbnail',
    roles: ['expert', 'admin', 'super_admin'],
  },
  Iconmanagement: {
    component: Iconmanagement,
    path: '/Iconmanagement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AddIcon: {
    component: AddIcon,
    path: '/AddIcon',
    roles: ['expert', 'admin', 'super_admin'],
  },
  EditIcon: {
    component: AddIcon,
    path: '/addIcon/edit/:id',
    roles: ['expert', 'admin', 'super_admin'],
  },
  RewardsAndAchievement: {
    component: Gamification,
    path: '/RewardsAndAchievement',
    roles: ['expert', 'admin', 'super_admin'],
  },
  AdminReport: {
    component: AdminReport,
    path: '/AdminReport',
    roles: ['expert', 'admin', 'super_admin'],
  },
  NotFoundPage: {
    component: NotFoundPage,
    path: '*',
  }
};

/** Web routes from the RN repo ends */

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const theme = createTheme({
      typography: {
        fontFamily: 'Montserrat'
      },
      palette: {
        background: {
          default: '#FFFFFF',
          paper: '#FFFFFF'
        }
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [montserrat]
          }
        }
      }
    });

    return (
      <>
        <View>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              {WebRoutesGenerator({ routeMap })}
            </Switch>
            <ModalContainer />
          </ThemeProvider>
        </View>
        <ToastContainer />
      </>
    );
  }
}

export default App;
