import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";

export interface Faq {
  id: number;
  question: string;
  answer: string;
}

interface NotificationSetting {
  label: string;
  status: boolean;
}

export interface NotificationSettings {
  new_course_available: NotificationSetting;
  rewards_and_achievements: NotificationSetting;
  goal_course_assigment_assign: NotificationSetting;
  due_date_reminder: NotificationSetting;
}

const initialNotificationSettings = {
  new_course_available: {
    label: "New course/content available",
    status: false,
  },
  rewards_and_achievements: {
    label: "Rewards/Achievements",
    status: false,
  },
  goal_course_assigment_assign: {
    label: "When Goal/Course/Assignment is assigned to you",
    status: false,
  },
  due_date_reminder: {
    label: "Due date reminder",
    status: false,
  },
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleSettingsClose: () => void;
  handleLogout: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  faqs: Faq[];
  notificationSettings: NotificationSettings;
  backupNotificationSettings: NotificationSettings;
  receivedNotificationSettings: boolean;
  accountDeactivated: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFaqsApiCallId: string = "";
  getNotificationSettingsCallId: string = "";
  updateNotificationSettingsCallId: string = "";
  deactivateAccountApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      faqs: [],
      notificationSettings: initialNotificationSettings,
      backupNotificationSettings: initialNotificationSettings,
      receivedNotificationSettings: false,
      accountDeactivated: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getFaqsApiCallId === apiRequestCallId) {
        // Get FAQs
        if (!responseJson?.length) {
          // Show error is response is not an array
          this.setState({
            isLoading: false
          })
          toast.error("Something went wrong")
          return;
        }
        this.setState({
          isLoading: false,
          faqs: responseJson
        })
      } else if (this.getNotificationSettingsCallId === apiRequestCallId) {
        // Get notification settings
        if (!responseJson?.data) {
          // Show error is response doesn't contain data
          this.setState({
            isLoading: false
          })
          toast.error("Something went wrong")
          return;
        }
        // Update state for notification settings
        this.updateStateForNS(responseJson.data);
      } else if (this.updateNotificationSettingsCallId === apiRequestCallId) {
        // Update notification settings on the server
        this.handleUpdateNotificationSettingsResponse(responseJson);
      } else if (this.deactivateAccountApiCallId === apiRequestCallId) {
        this.handleDeactivateAccountResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, payload } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getFAQs = async () => {
    this.setState({
      isLoading: true
    });

    const { apiContentTypeApplicationJson, httpGetMethod, faqUrl } = configJSON;

    this.getFaqsApiCallId = await this.apiCall({
      contentType: apiContentTypeApplicationJson,
      method: httpGetMethod,
      endPoint: faqUrl,
    });
  };

  resetState = () => {
    this.setState({
      faqs: [],
      notificationSettings: initialNotificationSettings,
      receivedNotificationSettings: false
    });
  }

  getNotificationSettings = async () => {
    this.setState({
      isLoading: true,
    });

    const { apiContentTypeApplicationJson, httpGetMethod, notificationSettingsUrl } = configJSON;

    this.getNotificationSettingsCallId = await this.apiCall({
      contentType: apiContentTypeApplicationJson,
      method: httpGetMethod,
      endPoint: notificationSettingsUrl,
    });
  };

  // Update state from response for notification settings
  updateStateForNS = (data: any) => {
    const { account_id, ...settings } = data.attributes;
    const {
      new_course_available,
      rewards_and_achievements,
      goal_course_assigment_assign,
      due_date_reminder,
    } = this.state.notificationSettings;
    const newNotificationSettings: NotificationSettings = {
      new_course_available: { ... new_course_available },
      rewards_and_achievements: { ... rewards_and_achievements },
      goal_course_assigment_assign: { ... goal_course_assigment_assign },
      due_date_reminder: { ... due_date_reminder },
    };
    Object.keys(settings).forEach((key) => {
      // @ts-ignore
      if (key in newNotificationSettings) {
        const typedKey = key as keyof NotificationSettings;
        newNotificationSettings[typedKey].status = settings[typedKey]!;
      }
    });
    this.setState({
      isLoading: false,
      notificationSettings: newNotificationSettings,
      receivedNotificationSettings: true
    })
  }

  // Update individual notification settings
  updateIndividualNotificationSettings = (key: string) => {
    // Take backup
    this.setState({
      backupNotificationSettings: this.state.notificationSettings
    });

    // Update individual settings in state
    this.setState((curState: S) => {
      return {
        ...curState,
        notificationSettings: {
          ...curState.notificationSettings,
          [key]: {
            // @ts-ignore
            ...curState.notificationSettings[key],
            // @ts-ignore
            status: !curState.notificationSettings[key].status
          }
        }
      }
    }, () => {
      // Update changes to the server
      this.updateNotificationSettings();
    })
  }

  // Update notification settings
  updateNotificationSettings = async () => {

    const {
      due_date_reminder,
      goal_course_assigment_assign,
      new_course_available,
      rewards_and_achievements,
    } = this.state.notificationSettings;

    const httpBody = { 
      due_date_reminder: due_date_reminder.status,
      goal_course_assigment_assign: goal_course_assigment_assign.status,
      new_course_available: new_course_available.status,
      rewards_and_achievements: rewards_and_achievements.status,
    };

    const { apiContentTypeApplicationJson, httpPostMethod, notificationSettingsUrl } = configJSON;

    this.updateNotificationSettingsCallId = await this.apiCall({
      contentType: apiContentTypeApplicationJson,
      method: httpPostMethod,
      endPoint: notificationSettingsUrl,
      payload: JSON.stringify(httpBody),
    });

  };

  // Deactivate Account
  deactivateAccount = async () => {
    
    const { apiContentTypeApplicationJson, httpGetMethod, deactivateAccountUrl } = configJSON;

    this.deactivateAccountApiCallId = await this.apiCall({
      contentType: apiContentTypeApplicationJson,
      method: httpGetMethod,
      endPoint: deactivateAccountUrl,
    });

  }

  handleUpdateNotificationSettingsResponse = (responseJson: any) => {
    if (!responseJson?.data) {
      // Switch UI to the backup state if request fails
      this.setState({
        notificationSettings: this.state.backupNotificationSettings
      });
      toast.error("Something went wrong");
      return;
    }
    // Show success
    this.setState({
      backupNotificationSettings: this.state.notificationSettings
    })
    toast.success("Settings updated");
  }

  handleDeactivateAccountResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      responseJson.errors.forEach((msg: string) => {
        toast.error(msg);
      })
      return;
    }
    toast.success("Your account has been deactivated");
    this.setState({
      accountDeactivated: true
    });
  }

  // Customizable Area End
}
