import React from "react";
import { Link } from "react-router-dom";

export default function DummyQuizListing() {
  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        {/* <Link to="/create-quiz">Create Quiz</Link> */}
        <Link to={{ pathname: "create-quiz", state: { courseId: 55 } }}>
          Create quiz with course Id
        </Link>
      </li>
      <li>
        <Link to="/edit-quiz/50">Edit Quiz</Link>
      </li>
    </ul>
  );
}
