import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { BlobServiceClient } from '@azure/storage-blob';
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any
  image: any
  EditImage: any
  filename: any
  filenameEdit: any
  Editfile: any
  imgUrl: any
  EditimgUrl: any
  img: any
  file: any
  link: string
  id: any,
  EditId: any,
  isFreeTrial: boolean,
  loading: boolean,
  setImage: string,
  ApiImage: string,
  editVideoData: any,
  themeMode: boolean,
  videoDetails: any,
  imageURL: string | ArrayBuffer | null | undefined,
  thumbnailBlob: Blob,
  duration: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddvideoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAddVideoApiCallId: string |Message = ""
  EditVideo: string
  getSASTokenApi: Message | string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      image: "",
      EditImage: "",
      filename: "",
      filenameEdit: "",
      imgUrl: "",
      EditimgUrl: "",
      link: "",
      img: "",
      file: "",
      Editfile: "",
      id: "",
      EditId: "",
      setImage: '',
      ApiImage: '',
      isFreeTrial: false,
      loading: false,
      editVideoData: {},
      themeMode: false,
      videoDetails: {},
      imageURL: '',
      thumbnailBlob: new Blob(),
      duration:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.EditVideo === apiRequestCallId) {
        this.setState({ editVideoData: responseJson })
      }

      if (this.getAddVideoApiCallId === apiRequestCallId) {
        if (!responseJson.errors) {
          this.setState({ loading: false, file: null, filename: "", image: "" });
          toast.success("Video Upload successfully");
          this.props.history.push(`/ManageContentFolder/${responseJson.data.attributes.folder_id}`, { id: responseJson.data.attributes.folder_id })
        } else if (responseJson.errors) {
          let value = responseJson.errors.map((item: any) => Object.keys(item))
          toast.error(responseJson.errors[0][value[0]], { delay: 1000 });
          this.setState({ loading: false, file: null, filename: "", image: "" });
        }
      }
      if (this.getSASTokenApi === apiRequestCallId) {
        if (!this.state.file) {
          this.setState({ loading: false })
          toast.error("Please add video to continue");
          return;
        }
        const sasUrl = responseJson.data;
        const blobServiceClient = new BlobServiceClient(sasUrl);
        const containerClient = blobServiceClient.getContainerClient(responseJson.container_name);
        let fileName = `video${this.state.filename}`
        const blobClient = containerClient.getBlockBlobClient(fileName);

        try {
          const blobUploadResponse = await blobClient.uploadBrowserData(this.state.file);
          const fd = new FormData();
          fd.append('title', this.state.videoDetails.title);
          fd.append('description', this.state.videoDetails.description);
          fd.append('folder_id', this.props.history.location.state.id);
          fd.append('thumbnail', this.state.thumbnailBlob);
          fd.append('video_key', `${responseJson.container_name}/${responseJson.container_name}/${fileName}`);
          fd.append('video_size ', this.state.duration)
          this.addVideo(fd)
        } catch (error) {
          this.setState({ loading: false, file: null, filename: "" });
          toast.error("Error while uploading video");
          console.error("Error uploading file:", error);
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleSubmit = (data: any) => {
    this.setState({ loading: true, videoDetails: { ...data } })
    if(data.video_text_url !== "") {
      const fd = new FormData();
      fd.append('title', this.state.videoDetails.title);
      fd.append('description', this.state.videoDetails.description);
      fd.append('folder_id', this.props.history.location.state.id);
      fd.append('link', this.state.videoDetails.link);
      fd.append('video_link', data.video_text_url);
      this.addVideo(fd);
      return;
    }
    
    this.getSASToken();
  }

  addVideo = (data: any) => {
    this.doPostAddVideo({
      contentType: undefined,
      method: 'POST',
      endPoint: `bx_block_attachment/library_video`,
      body: data
    });
  }
  doPostAddVideo = (data: any) => {
    const { method, endPoint, body } = data;

    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAddVideoApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getEditVideoData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.EditVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/${this.props.navigation.getParam('id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSASToken = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSASTokenApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_coursecreation/course_videos/get_sas_token"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  processFileName = (fileName: string): string => {
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '').replace(/[^\w.]/g, '').replace(/_/g, '');
    const truncatedFileName = fileNameWithoutExtension.slice(0, 8);
    const finalFileName = truncatedFileName + '.' + fileExtension;

    return finalFileName;
  }
  handleImageChange = async(e: any) => {
    const files: File = e.target.files[0];
    if (files) {
      this.setState({ filename: this.processFileName(files.name), image: URL.createObjectURL(files), imgUrl: "", file: files, id: "" })
    }
    try {
      const { thumbnailBlob, videoDuration } = await this.generateThumbnail(files);
      this.setState({
        thumbnailBlob: thumbnailBlob,
        duration: videoDuration
      });
    } catch (error) {
    }
  }

  generateThumbnail = async (
    videoFile: File
  ): Promise<{
    thumbnailBlob: Blob;
    videoDuration: number;
  }> => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.preload = "metadata";

    const { thumbnailBlob, videoDuration } = await this.loadedMetadata(video);
    URL.revokeObjectURL(video.src);

    return { thumbnailBlob, videoDuration };
  };

  loadedMetadata = (video: HTMLVideoElement): Promise<{
    thumbnailBlob: Blob;
    videoDuration: number;
  }> => {
    return new Promise((resolve) => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");

      video.onloadedmetadata = () => {
        if (canvasContext) {
          canvas.width = 320;
          canvas.height = 240;
          canvasContext.drawImage(
            video,
            0,
            0,
            canvas.width,
            canvas.height
          );

          const thumbnailDataURL = canvas.toDataURL("image/jpeg");

          resolve({
            thumbnailBlob: this.dataURLtoBlob(thumbnailDataURL),
            videoDuration: video.duration,
          });
        }
      };
    });
  };


  dataURLtoBlob = (dataURL: string): Blob => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  // Customizable Area End
}
