import React from "react";

import {
  Box,
  Typography,
  Button,
  // Customizable Area Start
  Grid,
  Badge,
  styled,
  Popover,
  DialogTitle,
  DialogContent,
  DialogActions
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { thumbnailImg, Filter } from "./assets";
import { NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import { createTheme } from "@material-ui/core/styles";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal"
import ContentLibraryTable from "../../../components/src/ContentLibraryTable";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import FilterItems from "../../filteritems/src/FilterItems.web"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import IconmanagementController, {
  configJSON,
  Props
} from "./IconmanagementController.web"
import { DialogWrapper } from "../../../../packages/blocks/teambuilder/src/TeamBuilder.web";
import { closeIcon } from "../../../../packages/components/src/DeleteDialog/assets";
import moment from "moment";

export default class Iconmanagement extends IconmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rendeAddIconrInputRow = () => {
    const { anchorFilterContentsEl } = this.state;
    const openFilterContainer = Boolean(anchorFilterContentsEl)

    return (
      <>
        <InputSearchWrap>
        <StyledContentWrappers>
                <AdvancedSearch
              data-test-id="advanced_btn"
              searchInputValue={this.state.searchAdvanceValues}
              handleInputChange={this.handleInputChangeSearch}
              handleListItemClick={this.handleThumbnailListClick}
              filterList={this.state.filteredContentsLists}
              inputWidth={window.innerWidth<500?"280":"480"}
              isFilterDropActive={openFilterContainer}
              showList={this.state.showContentData} navigation={undefined} id={""} />
               <div style={{ marginLeft: "0px" }} className={this.state.anchorFilterContentsEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
              {this.state.creationIconsDate.length > 0 || (this.state.creationDateParamsdatas.hasOwnProperty('start_date') && this.state.creationDateParamsdatas.start_date.length > 0) || this.state.placeholderSelectsItems.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
              {this.state.anchorFilterContentsEl ? (
                <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover 
              data-test-id="filterPropsID"
              className="filterContainerPopover"
              open={openFilterContainer}
              id="myPopover"
              anchorEl={this.state.anchorFilterContentsEl}
              onClose={this.filterContainerDropdownCloseHandlers}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            
            >
              <FilterItems
                isThumbnail
                navigation={this.props.navigation}
                data-test-id="FilterItemsTestID"
                handleClick={this.handleClicksFilters}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandlers}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChanges}
                dropdownOpenHandler={this.dropdownHandlerOpens}
                creationDateChangeHandler={this.creationChangeHandlerDates}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendors}
                anchorEl={this.state.anchorsEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectsItems}
                placeholderSearchText={this.state.placeholderSearchsTexts}
                creationDate={this.state.creationIconsDate}
                expertAdminList={this.state.expertAdminData}
                isCalendarOpen={this.state.isCalendarshow}
                handleClear={this.handleClearFilters}
                handleCalendarChange={this.handleChangeCalendors} />
            </Popover>
              </StyledContentWrappers>
          <CreateGrid >
          <Box sx={webStyles.Accordions} className="btnBox">
            <Button
            onClick={()=>this.handleAddIcon()}
              style={webStyles.AccordionsSummary}
              data-test-id='thumbnail_id'
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <CreateButtonComponent>
                <span style={{ fontSize: "16px", fontWeight: 600, paddingRight: "10px", fontFamily: "Poppins, sans-serif" }}>
                  +
                </span>
                Add Icon
              </CreateButtonComponent>
            </Button>
          </Box>

          <DeleteModal
            data-test-id='delete-content'
            openDialog={this.state.deleteIconModal}
            headingText={`Are you sure you want to delete this ?`}
            btnCancelText="Cancel"
            btnOkText="Delete"
            handleCancel={this.cancelContentDeleteDialog}
            handleOk={() => this.handleDeleteContent(this.state.latestId)}
          />
          <DeleteModal
            data-test-id='delete-all-content'
            openDialog={this.state.deleteBulkModal}
            headingText={`Are you sure you want to delete this ?`}
            btnCancelText="Cancel"
            btnOkText="Delete"
            handleCancel={this.cancelIconAllDeleteDialog}
            handleOk={this.handleDeleteAllContent}
          />
       
          <AlertSuccess
            data={{
              show: this.state.deleteToast.isOpen,
              msg: this.state.showMessage  ? configJSON.deletionSuccessfull : this.state.deleteToast.msg,
              type: this.state.deleteToast.type,
              horizontal: "right",
              vertical: "top",
              showThumb:false
            }}
          />
          {this.state.showMessage && <AlertSuccess
            data={{
              show: this.state.deleteToast.isOpen,
              msg: "Some icons could not be deleted since they are being used.",
              type: this.state.deleteToast.type,
              vertical: "top",
              horizontal: "right",
              showThumb:true
            }}
          />}
            
        </CreateGrid>
        </InputSearchWrap>
      

      </>

    )
  }

  renderPreviewIconModal = () => {
    const { isPreviewIconModalVisible, selectedIconDetails } = this.state;

    return (
      <DialogWrapper
        data-test-id="preview_icon"
        open={isPreviewIconModalVisible}
        onClose={() => {
          this.setState({ isPreviewIconModalVisible: false })
        }}
        aria-labelledby={configJSON.ariaLabelledByText}
        aria-describedby={configJSON.ariaDscribedByText}
      >

        <DialogTitle id="alert-dialog-title">
          <div className="dialogTitle">
            <div>
              <span
                className="headingStyle"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                }}
                title={selectedIconDetails?.attributes?.title}
              >
                {selectedIconDetails?.attributes?.title}
              </span>
            </div>

          </div>
        </DialogTitle>
        <DialogContent>
          <div className="subHeading" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={selectedIconDetails?.attributes?.image?.url} width="200px" height="200px" style={{ borderRadius: "12px" }} />
          </div>

          <Grid container justifyContent={"space-between"} style={{ marginTop: "16px" }}>
            <Grid item style={modalStyle.modalText}>
              {selectedIconDetails?.attributes?.created_by}
            </Grid>
            <Grid item style={modalStyle.modalText}>
              Created on: {moment(selectedIconDetails?.attributes?.created_at).format("DD/MM/YYYY")}
            </Grid>
          </Grid>

          <Grid container style={modalStyle.modalThumbnailWrapper}>
            <Typography style={modalStyle.heading} >
              Rewards that use this Icon
            </Typography>

            {
              selectedIconDetails?.attributes?.rewards?.length ?
                selectedIconDetails.attributes.rewards.map((item) => {
                  return (
                    <>
                      <Typography variant="body1" style={modalStyle.modalDescriptionText}>
                        <span>• Reward Name {`>`} {item.name}</span>
                      </Typography> <br></br>
                    </>
                  )
                }
                )
                :
                <Typography variant="body1" style={modalStyle.modalDescriptionText}>
                  No Rewards found that use this Icon
                </Typography>
            }

            <Typography style={modalStyle.heading} >
              Achievements that use this Icon
            </Typography>

            <br></br>

            {
              selectedIconDetails?.attributes?.achievements?.length ?
                selectedIconDetails.attributes.achievements.map((contentItem) => {
                  return (
                    <>
                      <Typography variant="body1" style={modalStyle.modalDescriptionText}>
                        <span>• Achievement Name {`>`} {contentItem.name}</span>
                      </Typography> <br></br>
                    </>
                  )
                }
                )
                :
                <Typography variant="body1" style={modalStyle.modalDescriptionText}>
                  No Achievements found that use this Icon
                </Typography>
            }
          </Grid>

        </DialogContent>

        <DialogActions className="btnWrapper">
          <Button variant="outlined" className="cancelBtn"
            data-test-id="delete_thumbnail"
            onClick={()=>{
              if(selectedIconDetails?.id){
                this.setState({ isPreviewIconModalVisible: false });
                this.deleteSingleIcon(selectedIconDetails.id);
              }
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            data-test-id="editpopupn_btn"
            className="primaryBtnStyle"
            onClick={() => {
              if (selectedIconDetails?.id) {
                this.setState({ isPreviewIconModalVisible: false });
                this.navigateToEditIcon(selectedIconDetails.id);
              }
            }}
          >
            Edit
          </Button>

        </DialogActions>
        <span className="close_icon">
          <img src={closeIcon} alt="cross_icon" data-test-id="close_icon" onClick={() => this.setState({ isPreviewIconModalVisible: false })} />
        </span>
      </DialogWrapper>
    );
  }

  AddIconrenderTableOrNotFound = () => {
    const TABLEHEADERS = [
      { id: 1, label: "", sort: false },
      { id: 2, label: "Name", sort: true, dataType: "string", key: "title" },
      { id: 4, label: "Created by", sort: true, dataType: "string", key: "created_by" },
      { id: 5, label: "Created on", sort: true, dataType: "date", key: "created_at" },
      { id: 6, label: "", sort: false },
    ];
    return (
      <TableWrapper>
        {this.state.IconData && this.state.IconData.length > 0 ?
          <ContentLibraryTable
            data-test-id='contentTableTestId'
            thumbnailList={this.state.IconData}
            isThumbnail
            onSortIconClick={this.sortUsers}
            TableHeader={TABLEHEADERS}
            getSelectedChecboxID={this.getSelectedIDChecbox}
            deleteItem={this.deleteSingleIcon}
            handleDeleteAll={this.handleDeleteBulkIcon}
            closeDeleteButton={this.state.RemoveDeleteButton}
            updateChildState={this.updateChildState}
            handleOpenObjectModal={this.handleIconPreview}
          /> :

          <AddIconNotFoundBox>
            <AddIconInnerBox>
              <Box>
                <AddImageComponent src={thumbnailImg} />
              </Box>
              <AddIconNotFoundText>No results found</AddIconNotFoundText>
            </AddIconInnerBox>
          </AddIconNotFoundBox>
        }
      </TableWrapper>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container style={{ marginTop: '15px', justifyContent: "space-between" }}>
          {this.rendeAddIconrInputRow()}
          {this.AddIconrenderTableOrNotFound()}
        </Grid>
        {this.renderPreviewIconModal()}
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const TableWrapper = styled(Box)({
  width: "100%",
  "& .table thead .tr .th:nth-child(2)": {
    "& p": {
      marginLeft: "92px !important",
      justifyContent: "flex-start !important",
    }
  },
  "& .table tbody .tr .td:nth-child(2)": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px"
  },
  "& .table tbody .rowBg": {
    backgroundColor: "#80008012"
  }
})

const CreateGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop:"10px"
    },
  }
});

const AddImageComponent = styled("img")({
  macWidth:"100%",
  height:"auto",
});

const AddIconNotFoundBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const AddIconInnerBox = styled(Box)({
  marginTop: "40px"
});

const AddIconNotFoundText = styled(Typography)({
  marginTop: "26px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-1px"
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
  }
});

const StyledContentWrappers = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      cursor: "pointer",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
  },
  "& .filterIconBox:hover": {
      borderColor: '#652786'
  },
  "& .allBorder": {
      borderColor: '#652786',
      backgroundColor: "#EEE8F2",
  }
});
const InputSearchWrap = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  width:"100%",
  [theme.breakpoints.down('sm')]: {
   flexDirection:"column"
  },
  '& .MuiOutlinedInput-root': {
      border: '1px solid #E5E2E1',
    borderRadius: '10px',
    borderRight: "none",
    height: '54px',
    borderTopRightRadius: "0px !important",
    transition: 'border-color 0.3s ease',
    borderBottomRightRadius: "0px !important",
    '&:hover': {
        borderRadius: '10px !important',
      borderColor: '#652786 !important',
      color: '#652786 !important',
      border: '1px solid #652786',
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#652786',
    border: '1px solid #652786',
    borderTopRightRadius: "0px !important",
    borderRadius: '10px',
    borderBottomRightRadius: "0px !important",
  }
});

const modalStyle = {
  modalText: {
    fontSize: "16px",
    fontWeight: 400
  },
  heading: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#1C1B1B",
    marginTop: "15px"
  },
  modalThumbnailWrapper: {
    marginTop: "10px",
  },
  modalDescriptionText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#484646",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "36px",
    gap: "10px"
  }
}


const CreateButtonComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: ' flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize'
})

const webStyles = {
  MainWrapper:{
    display:"flex",
    justifyContent:"space between"
  },
  Accordions: {
    height: '56px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  },
  AccordionsSummary: {
    backgroundColor: '#6C328B',
    borderRadius: '15px',
    width: '192px',
    height: '56px',
  },
};
// Customizable Area End
