import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { CoursePreviewRoles } from "./CatalogueController";


// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End



export interface Props {
    // Customizable Area Start
    handlePreviewClose:()=>void;
    isPreview:boolean;
    dialogContent: CoursePreviewRoles | null;
    gotoEditCourse:(idValue:number | undefined)=>void
    gotoEditContent:(isPhases:boolean | undefined,idValue:number | undefined)=>void
    onProgramPreview?:(idValues:string, expandType: string | null)=>void
    // Customizable Area End
}

interface S {

    // Customizable Area Start
    toggleDropDown:boolean;
    onArrclick : string[];
    hoveredIndex: number | null;
    // Customizable Area End
}

interface SS {
    id: string;

}

export default class CoursePreviewController extends BlockComponent<Props, S, SS> {

    getProductApiCallId: any;
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [

            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            toggleDropDown:false,
            onArrclick: [],
            hoveredIndex: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    rotateArrowIcon =(arrowId: string)=> {
    
        const match = this.state.onArrclick.find(item => item === arrowId);
        if(match){
          this.setState({onArrclick:this.state.onArrclick.filter(item => item !== arrowId)})
        }else {
          this.setState({onArrclick : [...this.state.onArrclick, arrowId]})
        }
        
      }
    // Customizable Area End
}
