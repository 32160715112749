import React from 'react'
import { achivedImg, normalImg, rewardImg } from '../assets'

const GetCompletionImage = (data: any) => {
    if (data.achievements?.data) {
        return (<img src={rewardImg} style={{}} />)
    } else if (data.rewards?.data) {
        return (<img src={achivedImg} style={{}} />)
    } else {
        return (<img src={normalImg} style={{}} />)
    }
}

export default GetCompletionImage