import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
export default class CfDigitalMallAdapter {
  send: (message: Message) => void;
  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationMessageSendData)
    ]);
  }
  convert = (from: Message): Message => {
    const goto = new Message(getName(MessageEnum.NavigationMessage));
    const screenName =  from.getData(getName(MessageEnum.NavigationScreenNameMessage));
    goto.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    goto.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.ContentManagementData),
      from.getData(getName(MessageEnum.ContentManagementData))
    );
    goto.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    return goto;
  };
receive(from: string, message: Message): void {
  this.send(this.convert(message));
}
}