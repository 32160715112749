import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { trashIcon } from "../assets";
import { Box } from "@material-ui/core";

interface ICartItem {
  thumbnail: string;
  title: string;
  author: string;
  price?: string;
  itemType: string;
  onRemoveClick?: () => void;
  danger?: boolean;
  subscriptionPeriod:string;
}

export default function CartItem({
  thumbnail,
  title,
  author,
  price,
  itemType,
  onRemoveClick,
  danger,
  subscriptionPeriod
}: ICartItem) {
  const classes = useStyles();
  const courseOrProgramPrice = price ? parseFloat(price).toFixed(2) : null;
  const getPeriod = (value:string) => {
    switch(value){
     case "monthly_subscription":
       return "Monthly"
     case "semi_anually":
       return "Semi anually"
     case "anually":
       return "Anually"
     default:
     return ""
    }
 }
  return (
      <div className={classes.itemContainer}>
      <div className={classes.itemSubContainer}>
            <div className={classes.thumbnailContainer}>
              <img
                className={classes.thumbnail}
                alt="complex"
                src={thumbnail}
              />
            </div>
            <Box>
                <Typography
                  className={clsx(classes.cardH1, danger && classes.danger)}
                >
                  {itemType}
                </Typography>
                <Typography className={classes.courseName}>{title}</Typography>
                <Typography className={classes.authorName}>
                  {author && `By ${author}`}
                </Typography>
                </Box>
      </div>
                <Box className={classes.flexCenter}>
                  <Box>
                 <Typography className={classes.coursePrice}>
                  {courseOrProgramPrice && `$${courseOrProgramPrice}`}
                </Typography>
               {subscriptionPeriod &&  <Typography className={classes.coursePricePeriod}>
                  {getPeriod(subscriptionPeriod)}
                </Typography>}
                </Box>
            {onRemoveClick && (
                <Button
                  startIcon={
                    <img
                      src={trashIcon}
                      className={classes.trashIcon}
                      alt="remove from cart"
                    />
                  }
                  className={classes.removeBtn}
                  onClick={onRemoveClick}
                >
                </Button>
            )}
            </Box>
      </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    itemContainer: {
      padding: theme.spacing(2),
      borderBottom: "1px solid rgba(225, 225, 225, 0.577997);",
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
    },
    itemSubContainer: {
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
      gap:"15px"
    },
    thumbnailContainer: {
      width: 116,
      height: 116,
    },
    thumbnail: {
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 10,
    },
    cardH1: {
      color: "#652786",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "20px",
      padding: "10px 0",
      fontFamily:"poppins",
      textTransform: "capitalize",
    },
    danger: {
      color: "#EF2B4B",
    },
    courseName: {
      color: "#1C1B1B",
      padding: "2px 0",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      fontFamily:"poppins",
      textTransform: "capitalize",
    },
    coursePrice: {
      color: "#787776",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      fontFamily:"poppins",
      textAlign:"right"
    },
    coursePricePeriod: {
      color: "#787776",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily:"poppins",
      textAlign:"end"
    },
    flexCenter: {
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    },
    authorName: {
      color: "#ADAAAA",
      fontSize: "16px",
      fontWeight: 400,
      margin: "10px 0",
      textTransform: "capitalize",
    },
    removeBtn: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "16px",
      color: "#1A1A1A",
      "&.MuiButton-root": {
        textTransform: "capitalize",
      },
    },
    trashIcon: {
      width: "22px",
      height: "22px",
    },
  })
);
