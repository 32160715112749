import React, { useRef } from "react";
import {
    Box,
    Button, IconButton,
    Paper, Typography, LinearProgress
} from "@material-ui/core";
import Slider from "react-slick";
import { createTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const play = require("./assets/play.png")

interface Props {
    style: any;
    props: any;
    last_trackVideo: (data: any) => void;
    handleNavigate: (type: string, id: any, pathname: string, state: any) => void;
    personalData: any;
}

const BorderLinearProgress = withStyles((theme: any) => ({
    root: {
        height: 10,
        borderRadius: 5,
        width: '60%',
        marginTop: '15px',
        marginLeft: '17px',
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
    },
}))(LinearProgress);

export default function PersonalSlider({ personalData, handleNavigate, props, last_trackVideo, style }: Props) {


    const HandleChange = (data: any) => {
        if (data?.attributes?.collection?.attributes?.expand_type === "Course") {
            handleNavigate("Course", data.id, `/course-detail/${data?.attributes?.collection?.attributes?.expand_type}/${data?.attributes?.collection?.id}/${data?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: data?.attributes?.collection })
        } else if (data?.attributes?.collection?.attributes?.expand_type === "Program") {
            handleNavigate("Program", data.id, `/program-detail/${data?.attributes?.collection?.attributes?.expand_type}/${data?.attributes?.collection?.id}/${data?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: data?.attributes?.collection })
        }
    }

    let slider = React.createRef<any>();

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <>
            <Box id={"slick-slider-1212"}>
                {personalData?.data?.length !== 0 ?
                    <Slider  {...settings} ref={(c: any) => (
                        // @ts-ignore
                        slider = c
                    )}
                        className='slider1'
                    >
                        {personalData?.data.map((data: any, index: any) => (
                            <Box key={index}>
                                <Box style={styles.IconButton} >
                                    <CardMedia className="cardmedia"
                                        style={styles.course_box}
                                        image={`${data?.attributes?.collection?.attributes?.thumbnail_image?.url}`}
                                    />
                                    <Paper className="imagepaper" style={styles.course_h3}>
                                        <Typography style={styles.NavigateNextOutlinedIcon}
                                            onClick={() => HandleChange(data)}>
                                            {data?.attributes?.collection?.attributes?.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p"
                                            style={styles.img_play}>
                                            {data?.attributes?.collection?.attributes?.created_by}
                                        </Typography>
                                        <Box>
                                            <BorderLinearProgress variant="determinate" value={(data?.attributes?.completed_video_count / data?.attributes?.total_video_count) * 100} />
                                            <Typography variant="body2" color="textSecondary" component="p"
                                                style={styles.img_card}>
                                                {data?.attributes?.completed_video_count}/{data?.attributes?.total_video_count} Videos Completed
                                            </Typography>
                                            <Card style={styles.group_img}>
                                                <CardActionArea>
                                                    <img src={play} onClick={() => last_trackVideo(data)}
                                                        style={styles.group_cardImg} />
                                                </CardActionArea>
                                            </Card>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                        ))
                        }
                    </Slider>
                    :
                    <><h2 style={styles.shop_img}>No Courses available</h2></>}
            </Box>
        </>
    )
}

const styles = {
    IconButton: {
        padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px'
    },
    course_box: {
        height: "240px", width: '100%', borderRadius: '25px 25px 0 0'
    },
    course_h3: {
        width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px'
    },
    NavigateNextOutlinedIcon: {
        minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer'
    },
    img_play: {
        margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black'
    },
    img_card: {
        margin: 'auto', fontSize: '12px', marginTop: '10px', padding: '0 17px', color: 'rgba(254,132,64,1)'
    },
    group_img: {
        width: '40px', height: '40px', borderRadius: '10px', float: 'right' as 'right',
        marginTop: '-50px', marginRight: '25px'
    },
    group_cardImg: {
        margin: 'auto', padding: '8px'
    },
    shop_img: {
        textAlign: 'center' as 'center', marginTop: '10%'
    },
}