import React from "react";
// Customizable Area Start
import { Button, Grid, Box, styled, Popover, Modal } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { Filter, NotFoundImage, NewFilter, achievement, rewards } from "./assets";
import FilterItems from "../../filteritems/src/FilterItems.web";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import EnhancedTable from "../../../../packages/components/src/Gamification";
export const configJSON = require("./config");
// Customizable Area End

import CatalogueController, { Props } from "./GamificationController";

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderModal = () => {
    return (
      <>
        <Modal
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{display: "flex", alignItems: "center", justifyContent: "center"}}
        > 
          <ImageBox>
            <GridBox onClick={this.handleAchievement}>
              <ImageWrapper src={achievement} />
            </GridBox> 
            <LeftGridBox onClick={this.handleRewardScreen}>
              <ImageWrapper src={rewards} />
            </LeftGridBox>
          </ImageBox>
        </Modal>
      </>
    )
  };

  generateToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "52px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#1C1B1B" : "",
      display: "flex",
      alignItems: "center",
      fontWeight: isActive ? 600 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      fontSize: "18px",
      width: "50%"
    };
  }

  generateNewToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "48px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#000" : "",
      display: "flex",
      alignItems: "center",
      minWidth: "170px",
      maxWidth: "200px",
      fontWeight: isActive ? 700 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      gap: "10px",
      padding: "14px 24px",
    };
  }

  renderToggleBox() {
    const { isRewardsActive, filterItems, anchorFilterContainerEl } =
      this.state;

    const rewardsToggleStyle = this.generateToggleBoxStyle(
      isRewardsActive === "reward"
    );
    const achievementsToggleStyle = this.generateToggleBoxStyle(
      isRewardsActive === "achievement"
    );
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl);

    return (
      <Grid
        item
        container
        md={12}
        style={{ display: "flex", alignItems: "center" }}
      >
        <TabGrid item md={6} xs={12} lg={4}>
          <ToggleBox>
            <ToggleInnerbox
              data-test-id="courseToggleId"
              style={rewardsToggleStyle}
              onClick={this.handleToggleCourses}
            >
              Rewards
            </ToggleInnerbox>
            <ToggleInnerbox
              style={achievementsToggleStyle}
              onClick={this.handleTogglePrograms}
              data-test-id="programToggleId"
            >
              Achievements
            </ToggleInnerbox>
          </ToggleBox>
        </TabGrid>
        <InputMain
          item
          md={6}
          xs={12}
          lg={6}
          style={{
            display: "flex",
            alignItems: "center",
            borderRight: "none",
            justifyContent: "center",
          }}
        >
          <StyledContentWrapper>
            <AdvancedSearch
              data-test-id="btn_change"
              isFilterDropActive={openFilterContainer}
              handleListItemClick={this.handleListClickSuggestion}
              searchInputValue={this.state.searchInputValue}
              handleInputChange={this.handleInputChangeSearch}
              filterList={this.state.filteredList}
              showList={this.state.showList}
              navigation={undefined}
              id={""}
            />
            <div
              style={{ marginLeft: "0px" }}
              className={
                anchorFilterContainerEl
                  ? "filterIconBox allBorder"
                  : "filterIconBox"
              }
              onClick={this.handleFilterDrop}
              aria-describedby={"myPopover"}
            >
              {this.state.creationDate.length > 0 ||
              (this.state.creationDateParams.hasOwnProperty("start_date") &&
                this.state.creationDateParams.start_date.length > 0) ||
              this.state.categorySelectedItems.length > 0 ||
              this.state.placeholderSelectedItems.length > 0 ||
              this.state.contentRoleSelectedItems.length > 0 ||
              this.state.selectedStatus.length > 0 ? (
                <BadgeComponents
                  color="secondary"
                  variant="dot"
                ></BadgeComponents>
              ) : (
                ""
              )}
              {anchorFilterContainerEl ? (
                <FiterImage
                  src={Filter}
                  data-test-id="filterOpenTestID"
                  alt="Filter"
                />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <FilterItems
                placeholderCheckboxUserManageChangeHandlerBranch={
                  this.placeholderCheckboxUserManageChangeHandlersBranch
                }
                placeholderSearchBranchText={
                  this.state.placeholderSearchBranchText
                }
                handlePlaceholderUserSearchChangeBranch={
                  this.handlePlaceholderUserBranchSearchChanges
                }
                placeholderSelectedBranchItems={
                  this.state.placeholderSelectsBranchItems
                }
                isContentRole={this.state.contentRoleTrue}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleClick={this.handleClickFilters}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={
                  this.placeholderCheckboxAssignedExpertChangeHandler
                }
                handlePlaceholderSearchChange={
                  this.handlePlaceholderAssignedSearchChange
                }
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                userBranchList={this.state.userBranchList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor}
              />
            </Popover>
          </StyledContentWrapper>
        </InputMain>
        <CreateNewGrid item md={6} xs={12} lg={2}>
          <Box style={Styles.Accordion} className="btnBox">
            <Button
              style={Styles.AccordionSummary}
              data-test-id="panel1a-content"
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={this.handleModalOpen}
            >
              <CreateNewVideoComponent>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    paddingRight: "10px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  +
                </span>
                Create New
              </CreateNewVideoComponent>
            </Button>
          </Box>
        </CreateNewGrid>
      </Grid>
    );
  }

  enhancedTableData() {
    return (
      <EnhancedTable
        data-test-id="tableTestId"
        prevPageExists={!!this.state.pagination.prevPage}
        nextPageExists={!!this.state.pagination.nextPage}
        handleNextPageNavigation1={this.handlePageNavigationNext}
        handlePrevPageNavigation1={this.handlePageNavigationPrev}
        meta={this.state.meta}
        achievementsList={this.state.achievementsList}
        getAchievements={this.getAchievements}
        rewardsList={this.state.rewardsList}
        getRewards={this.getRewards}
        isRewardsActive={this.state.isRewardsActive}
      />
    );
  }
  renderTableOrNotFound() {
    let contentToRender;
    if (
      (this.state.isRewardsActive === "reward" ||
        this.state.isRewardsActive === "achievement") &&
      this.state.data.length > 0
    ) {
      contentToRender = this.enhancedTableData();
    } else if (this.state.data.length === 0) {
      contentToRender = (
        <MainNotFoundBox>
          <MainInnerBox>
            <Box>
              <ImageComponent src={NotFoundImage} />
            </Box>
            <NotFoundText>No results founds</NotFoundText>
          </MainInnerBox>
        </MainNotFoundBox>
      );
    }

    return contentToRender;
  }

  statusOptionData = (row: any) => {
    if (row.attributes.status === "active") {
      return webStyle.statusNew;
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Grid container style={{ marginTop: "7px" }}>
        {this.renderToggleBox()}
        {this.renderTableOrNotFound()}
      </Grid>
      {this.renderModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CreateNewVideoComponent = styled(Typography)({
  margin: "auto",
  fontStyle: "normal",
  display: " flex",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "16px",
  textAlign: "center",
  color: "#FFFFFF",
  textTransform: "capitalize",
});
const webStyle = {
  table: {
    minWidth: 750,
    borderCollapse: "separate" as "separate",
    borderSpacing: "0 15px",
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 15px",
    },
  },

  statusNew: {
    display: "flex",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#EEE8F2",
    color: "#652786",
    textTransform: "capitalize" as "capitalize",
  },

  textalignleft: {
    paddingLeft: "0",
    textAlign: "left",
    border: "1px solid red",
  },
};

const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  maxWidth: "520px",
  width: "100%",

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%",
  },
  "& .filterIconBox:hover": {
    borderColor: "#652786",
  },
  "& .allBorder": {
    borderColor: "#652786",
    backgroundColor: "#EEE8F2",
  },
});

const InputMain = styled(Grid)({
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "0px",
  },
});

const CreateNewGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    marginLeft: "auto",
  },
});

const TabGrid = styled(Grid)({
  paddingRight: "0 !important",
});

const ToggleBox = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  backgroundColor: "#fcf6f6",
  height: "52px",
  alignItems: "center",
  borderRadius: "12px ",
  cursor: "pointer",
  width: "90%",
});

const FiterImage = styled("img")({});

const ToggleInnerbox = styled(Box)({
  color: "#1c1818",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "173px",
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px",
  },
});

const ImageComponent = styled("img")({
  width: "100%",
  height: "100%",
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const MainInnerBox = styled(Box)({
  width: "727px",
  height: "540px",
  marginTop: "40px",
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-1px",
});

const Styles = {
  Accordion: {
    height: "56px",
    borderRadius: "15px",
    boxShadow: "none",
    position: "relative",
  } as React.CSSProperties,
  accordionContent: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  },
  AccordionDetails: {
    backgroundColor: "#fff",
    position: "absolute",
    borderRadius: "10px",
    zIndex: 999,
  },
  Accordion_Typography: {
    margin: "auto",
    fontStyle: "normal",
    display: " flex",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize",
  } as React.CSSProperties,
  AccordionSummary: {
    height: "56px",
    backgroundColor: "#6C328B",
    borderRadius: "15px",
    width: "192px",
  },
  course1_btns: {
    background: "#6C328B",
    height: "56px",
    borderRadius: "16px",
    width: "196px",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  course2_btns: {
    background: "#6C328B",
    borderRadius: "16px",
    width: "196px",
    height: "56px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
};

const GridBox = styled(Box)({
  width: "47%",
  height: "218px",
  backgroundColor: "#652786",
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
  cursor: "pointer",
  borderRight: "1px solid #986FAE",
  "@media (max-width: 520px)" : {
    borderRight: "1px solid transparent",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px"
   }
});

const ImageBox = styled(Box)({
  display: "flex",
  alignItems: "center", 
  justifyContent: "center",
   width: "482px", 
   height: "221px", 
   backgroundColor: "#652786", 
   borderRadius: "20px",
   "@media (max-width: 520px)" : {
    backgroundColor: "transparent !important", 
    flexDirection: "column"
   }
});

const LeftGridBox = styled(Box)({
  width: "47%",
  height: "218px",
  backgroundColor: "#652786",
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
  cursor: "pointer",
  borderLeft: "1px solid #986FAE",
  "@media (max-width: 520px)" : {
    borderRight: "1px solid transparent",
    borderTop: "1px solid #986FAE",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px"
   }
});

const ImageWrapper = styled("img")({
  "@media (max-width: 520px)" : {
     padding: "20px"
   }
});


// Customizable Area End
