// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,    
    Typography,
    TextField,
} from '@material-ui/core';
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import { Folder } from './assets';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from '@material-ui/core/Modal';
import Fade from "@material-ui/core/Fade";
const close = require("../assets/imagenav_close.png")

class AddFolder extends CourseCreationController{
    constructor(props:any){
        super(props);
    }
    async componentDidMount() {
        this.getFolderData()
      }
    render(){
        return (<>
        <div className="add-folder">
            <Grid container>
                <Grid item md={12} xs={12} style={{textAlign:"end"}}>
                    <Button className="add-folder-btn" onClick={this.handleCreateFolderOpen}>Add Folder</Button>
                </Grid>
            </Grid>
            <p className="folder-text">folder</p>
            <Grid container>
                {
                    this.state.folderData.length > 0 && this.state.folderData.map((item:any, index:any)=>{
                        return(
                            <Grid item md={2} xs={12} key={index}>
                            <Button className="select-folder-btn"><img src={Folder} width={30} height={30} /><Typography>{item.name}</Typography></Button>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
            <Modal
                        open={this.state.folderModalOpen}
                        onClose={this.handleCreateFolderClose}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        // @ts-ignore
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{ backgroundColor:"rgba(0, 0, 0, -0.5)", display:'flex', alignItems:'center', justifyContent:'center'}}
                    >
            <Fade in={this.state.folderModalOpen} >

                        <div style={{
                            position: 'absolute', width:'890px',height:'225px', backgroundColor: '#fff',
                            margin:'12%',marginLeft:'21%',borderRadius:'15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'
                            
                        }}>
                          <img src={close} onClick={this.handleCreateFolderClose} style={{float:'right',marginTop:'20px',marginRight:'20px'}}/>
                           <Typography style={{fontSize:'20px', fontWeight:600,marginTop:'20px',marginLeft:'25px'}}>Create New Folder</Typography>
                           <Grid container spacing={4} style={{marginLeft:'30px',marginTop:'25px'}}>
                              <Grid item lg={9}>
                                <TextField data-test-id = "inputFolder" style={{ borderRadius: '16px', width: '665px' }} value={this.state.createFolder} onChange={(event:any) => this.setState({createFolder:event.target.value})} placeholder= "Type the name of the folder" variant="outlined" />
                              </Grid>
                              <Grid item lg={3}>
                              <Button data-test-id = "createFolder" style={{ borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" onClick={()=>this.createFolder()} >Create</Button>
                              </Grid>
                            
                           </Grid>
                        </div>
            </Fade>
                    </Modal>
                    </Grid>
        </div>
        </>);
    }
}
export default AddFolder;
// Customizable Area End