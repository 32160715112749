import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme ,withStyles  } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
const searchIcon = require("../assets/searchIcon.png");
import './style.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssignContentController, {
  Props,
} from "./AssignContentController.web";

export default class AssignList extends AssignContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {



    return (
      // Customizable Area Start
      
      <Grid container spacing={5}>
          <Grid item xs={12} lg={5}>
            <Box className="first_box">
          <Typography variant="h6">Course/Program</Typography>

                <Box className="search_icon">
                    <input type="text" placeholder="Search"/>
                    <img src={searchIcon} alt="" />
                </Box>

             <Box className="list">
                <Box className="list_heading">
                 <input type="checkbox" />
                 <Typography>Course/Program Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>Course/Program Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>Course/Program Name</Typography>
                </Box>
             </Box>
                  </Box>
          </Grid>
         
          <Grid item xs={12} lg={7}>
            <Box className="second_box">
          <Typography variant="h6">Course/Program</Typography>

                <Box className="search_icon">
                    <input type="text" placeholder="Search"/>
                    <img src={searchIcon} alt="" />
                </Box>

             <Box className="list">
                <Box className="list_heading">
                 <input type="checkbox" />
                 <Typography>Id</Typography>
                 <Typography>Name</Typography>
                 <Typography>Branch</Typography>
                
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>u397989</Typography>
                 <Typography>User Name</Typography>
                 <Typography>Branch Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>u397989</Typography>
                 <Typography>User Name</Typography>
                 <Typography>Branch Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>u397989</Typography>
                 <Typography>User Name</Typography>
                 <Typography>Branch Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>u397989</Typography>
                 <Typography>User Name</Typography>
                 <Typography>Branch Name</Typography>
                </Box>
                <Box className="list_row">
                 <input type="checkbox" />
                 <Typography>u397989</Typography>
                 <Typography>User Name</Typography>
                 <Typography>Branch Name</Typography>
                </Box>
             </Box>
                  </Box>
          </Grid>

          <Grid item xs={12} style={{textAlign:'end'}}>
          <ColorButton size="small" onClick={this.props.handleOpen}>Assign</ColorButton>
          </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
    mainWrapper: {
    padding: '30px',
      background: "#f2f2f2",
      borderRadius: '15px'
    },
    searchBar: {
        padding: '30px',
          background: "#f2f2f2",
          borderRadius: '15px'
        },
}

const ColorButton = withStyles((theme) => ({
  root: {
      backgroundColor: purple[500],
      color:"white",
      borderRadius:"10px",
      borderColor: purple[500],
      textTransform:'capitalize',
      padding:'10px 50px',
      '&:hover': {
          borderColor: purple[700],
          color:"white",
          backgroundColor: purple[800],
      },
  },
}))(Button);


// Customizable Area End
