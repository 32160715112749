import React from "react";


import ContentRoleController, {
    Library,
    Props,
} from "./ContentRoleController.web";
import Badge from "@material-ui/core/Badge";
import { Box,
     Button,
    Checkbox, 
    FormControl, 
    Grid, 
    Popover, 
    TextField, 
    TextareaAutosize, 
    Typography, 
    styled } from "@material-ui/core";
import AdvancedSearch from "../../../../packages/blocks/advancedsearch/src/AdvancedSearch.web";
import { Filter, NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import { addContentImg, imageHtmlCourse2,NotFoundImage, rightArrow } from "./assets";
import FilterItems from "../../../../packages/blocks/filteritems/src/FilterItems.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";

class ContentRoleCreationWeb extends ContentRoleController {
    wrapperRefLaunchDate: any;
    wrapperRefDueDate: any;
    constructor(props: Props) {
        super(props);
    }

    generateToggleBoxStyle(isActive: boolean) {
        return {
            backgroundColor: isActive ? "#FFF" : "#fcf6f6",
            height: "48px",
            boxShadow: isActive ? "1px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
            color: isActive ? "#000" : "",
            display: "flex",
            alignItems: "center",
            width:"170px",
            minWidth: "170px",
            fontWeight: isActive ? 700 : 400,
            justifyContent: "center",
            borderRadius: "10px",
            cursor: "pointer",
            maxWidth: "200px",
            padding: "14px 24px"
        };
    }

    renderTextField = (label: string, name: string, placeholder: string,) => {
        return (
            <FormControl variant="standard" className={this.state.contentRoleName.length > 0 ? "customInput2"  : "customInput1"}>
                <label className="labelStyle" style={labelText}>{label}</label>
                <TextField
                className="customInputTextName"
                    data-test-id="selectTextFieldID"
                    name={name}
                    placeholder={placeholder}
                    id="bootstrap-input"
                    value={this.state.contentRoleName}
                    onChange={this.handleNameChange}
                />

                   
            </FormControl>
        );
    }

    renderDescriptionField = (label: string, name: string, placeholder: string,) => {
        return (
            <FormControl variant="standard" className="customInput3"  >
                <label className="labelStyle" style={labelText}>{label}</label>
                <TextField style={this.state.description.length > 0 ? descriptionTextarea1 :  descriptionTextarea}
                    data-test-id="selectTextAreaFieldID"
                    name={name}
                    multiline
                    maxRows={4}
                    placeholder={placeholder}
                    id="bootstrap-input"
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    
                />
                <Typography style={{fontSize:"12px"}}>*A description will increase sales and improve the user experience.</Typography>
               
            </FormControl>
        );
    }

    renderToggleBox() {
        const { isCourseActive, filterItems ,anchorFilterContainerEl} = this.state;
        const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course");
        const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program");
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)
        return (
            <Grid item container style={{margin:"20px",gap:"clamp(5px, 45px, 3%)"}} >
                <TabGrid >
                    <ToggleBox >
                        <ToggleInnerbox data-test-id="courseToggleId" 
                        style={courseToggleStyle} onClick={this.handleToggleCourses} >
                            Course
                        </ToggleInnerbox>
                        <ToggleInnerbox data-test-id="programToggleId" 
                        style={programToggleStyle} onClick={this.handleTogglePrograms}>
                            Program
                        </ToggleInnerbox>
                    </ToggleBox>
                </TabGrid>

                <InputMainBox  style={{ display: 'flex', alignItems: 'center',
                 borderRight: "none", justifyContent: "center", width:"clamp(300px, 518px, 45%)"}}>
                    <StyledContentWrapper className={!filterItems ? "removeRightBorder" : ""}>
                        <AdvancedSearch
                            data-test-id="btn_chanLibraryBoxge"
                            searchInputValue={this.state.searchInputValue}
                            handleListItemClick={this.handleListItemClickSuggestion}
                            filterList={this.state.filteredList}
                            handleInputChange={this.handleInputCatalogueChangeSearch}
                            isFilterDropActive={openFilterContainer}
                            inputWidth="100%"
                            showList={this.state.showList} navigation={undefined} id={""} />
                         <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDrop}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) || this.state.placeholderSelectedItems.length > 0 || this.state.categorySelectedItems.length > 0 || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
                        <Popover 
              data-test-id="filterPropsID"
              className="filterContainerPopover"
              open={openFilterContainer}
              id="myPopover"
              anchorEl={this.state.anchorFilterContainerEl}
              onClose={this.filterContainerDropdownCloseHandler}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            
            >
              <FilterItems
                data-test-id="FilterItemsTestID"
                handleChangeStatus={this.handleCatalogueChangeStatus}
                selectedStatus={this.state.selectedStatus}
                handleClick={this.handleClickFilter}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandlerChange}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                categorySelectedItems={this.state.categorySelectedItems}               
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminContentRolesList={this.state.adminContentRolesList}
                adminCatagoryList={this.state.adminCatagoryList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>
                        </StyledContentWrapper>
                    </InputMainBox>
                </Grid>
            )
        }

        statusOptionData = (data:any) => {
            if(data.attributes?.status ==="active"){
              return webStyle.statusNew
            }else if(data.attributes?.status === "draft"){
              return webStyle.statusDraft
            }else {
              return webStyle.statusArchived
            }
          }

        renderTableData=(libraryStaticData:Library[],handleChange:(selectedData:Library)=>void,
                    tableName:string,checked:Library[])=>{
            return(
                <Box  style={{overflowY:"scroll", width:"100%",height:"588px" , padding:"0px 25px"}} >
                {libraryStaticData.map((data, index) => (
                    
                    <StyledBox 
                    style={{ backgroundColor:this.isCheckedValue(checked,data) ? "rgba(238, 232, 242, 1)": "#fff"}}>
                    <div style={{display: "flex" ,  alignItems: "center"}}>
                        <Checkbox
                        className="checkBox"
                            style={{ color: this.isCheckedValue(checked,data)?"#652786":"grey", textTransform: "capitalize" }}
                            checked={this.isCheckedValue(checked,data)}
                            data-testid ={`selectedCheckBox${index}`}
                            onChange={() => handleChange(data)}
                        />
                        <div style={{display: "flex" ,  alignItems: "center"  , gap:"14px", }}>
                            <div>
                            <img style={thumb_img} src={data.attributes?.thumbnail_image?.url ? data.attributes.thumbnail_image.url : imageHtmlCourse2} />
                            </div>

                            <TitleData>{data.attributes?.title}</TitleData>
                        </div>
                    </div >

                    <Statuses style={this.statusOptionData(data)} >{data.attributes?.status}</Statuses>
                    </StyledBox>
                    ))}
            </Box>
            )
        }


renderProgramCLickedData=()=>{
    return(
        <>
         {this.state.removedAllData.length > 0 ?
        (this.renderTableData(this.state.removedAllData,this.handleRemoveCheckboxChangeProgram,"",
        this.state.removedProgramData)              
        ):( <Box style={{display:"flex",
        justifyContent:"center",
        flexDirection:"column",
         alignItems:"center",
         gap:"10px"}}>
        <Addedimg src={addContentImg} data-test-id="filterOpenTestID"/>
            <Typography style={webStyle.staticDataStyle}
            >Select the content you want to add</Typography> 
        </Box>)}

        <Box style={webStyle.removeButtonBoxstyle}>
         <Button style={this.state.removedProgramData?.length? webStyle.removeButtonStyleAfter:webStyle.removeButtonStyleBefor}
         data-test-id="removeButtonId"
            disabled={!this.state.removedProgramData?.length}
            onClick={this.handleRemoveButtonClickProgram}>
            <KeyboardBackspaceIcon/>
            &nbsp;&nbsp;&nbsp;
            <Typography style={typo1}>Remove</Typography> 
             </Button>
             </Box>
        </>
    )
   
}

libraryDataCheck = (data:any, value:any)=>{
    if(data.length>0){
        return  this.renderTableData(value,this.handleCheckboxChange,
            "data",this.state.selectedContent)
    }
    else{
        return <Box style={{display:"flex",
        justifyContent:"center",
        flexDirection:"column",
         alignItems:"center",
         gap:"10px",
         height:"695px"}}>
        <NoDataImg src={NotFoundImage} data-test-id="filterOpenTestID"/>
            <Typography style={webStyle.staticDataStyle}
            >No results founds</Typography> 
        </Box>
    }
}

    renderDataBox() {
        const libraryData = this.state.libraryData.filter((library)=> !this.state.removedAllData.includes(library))
        const programData = this.state.programData.filter((library)=> !this.state.removedAllData.includes(library))
        return (

            <LibraryBox >
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6} lg={6}  >
                    <Box style={{ display:"flex" ,flexDirection:"column" ,gap:"24px"  ,
                            boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)"  ,
                            height:"695px",padding:"20px",borderRadius:"16px"
                           }}>
                            <Box>
                                <Typography style={headStyle}>Library</Typography>          
                            </Box>
                            {this.state.isCourseActive === "course" ? this.libraryDataCheck(this.state.libraryData, libraryData)
                                :
                                this.libraryDataCheck(this.state.programData, programData)
                               }
                                         
                 
                            <Box style={webStyle.addButtonBoxstyle}>
                            <Button 
                            style={this.state.selectedContent?.length ? webStyle.addButtonStyleHighlighted : webStyle.addButtonStyle} 
                            onClick={this.handleAddButtonClick}
                            data-testid ="testing">
                            <Typography 
                            style={typo1}>Add</Typography> 
                            &nbsp;&nbsp;&nbsp;
                            <img src={rightArrow} 
                            />
                            </Button>
                        </Box>
                    </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Box style={{ display: "flex", 
                            alignItems: "center",
                            justifyContent:"space-between",
                            flexDirection:"column",
                            boxShadow:" 0px 2px 8px 0px rgba(0, 0, 0, 0.07)",
                            height:"695px",padding:"20px",gap:"16px"
                            ,top:"616px",left:"134px",borderRadius:"16px"
                            }} >
                                 <Box style={{ width:"100%"}}>
                                    <Typography style={headStyle}>Added</Typography>          
                                </Box>
                                {this.renderProgramCLickedData()}
                        </Box>
                    </Grid>
             </Grid>
            </LibraryBox>
        )
    }


    
    render() {
      
        return (
            <>
                <Box sx={gridStyle}>
                    {this.renderTextField('Name*', 'title', 'Enter title')}


                    {this.renderDescriptionField('Description', 'title', 'Please write your description here')}
                </Box>
                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderToggleBox()}
                </Grid>

                <Grid container style={{ marginTop: '20px' }}>
                    {this.renderDataBox()}
                </Grid>

                <MainButton>
                    {!this.state.isContentRoleAlreadyActive &&
                    <SaveAndCreate 
                    style={this.state.removedAllData?.length  && this.state.contentRoleName? webStyle.draftButtonStyle2: webStyle.draftButtonStyle1}
                    disabled={!this.state.contentRoleName || (!this.state.removedAllData?.length)}
                    onClick={() => {this.saveAsDraft("draft") }}
                    data-testid = "saveAsDraft"
                    >
                        <Typography style={typo}> Save as Draft </Typography>
                    </SaveAndCreate>
                    }
                    {this.state.editContentId && !this.state.isDupliactingContentRole ?
                    <>
                    <SaveAndCreate 
                    style={this.state.removedAllData?.length && this.state.contentRoleName? webStyle.roleButtonStyle2:webStyle.roleButtonStyle1}
                    disabled={!this.state.contentRoleName || (!this.state.removedAllData?.length)}
                    onClick={this.openSavePromptDialogue}
                    data-testid = "saveContentRole"
                    > 
                    <Typography style={typo}> Save Content Role </Typography>
                    </SaveAndCreate>
                    
                      </>
                    :
                    <SaveAndCreate 
                    style={this.state.removedAllData?.length && this.state.contentRoleName? webStyle.roleButtonStyle2:webStyle.roleButtonStyle1}
                    disabled={!this.state.contentRoleName || (!this.state.removedAllData?.length)}
                    onClick={() => {this.saveAsDraft("active")}}
                    
                    > 
                    <Typography style={typo}>Create Content Role</Typography>
                    </SaveAndCreate>
                    }
                </MainButton>
                <DeleteModal
                    data-test-id="save-add-content-modal"
                    openDialog={this.state.saveContentModal}
                    headingText={"Save changes?"}
                    subHeading={"Are you sure you want to make these changes? This might confuse users"}
                    btnCancelText={"Cancel"}
                    btnOkText={"Save"}
                    handleCancel={this.closeSavePromptDialogue}
                    handleOk={this.handleSaveContent} 
                />
            </>
        )
    }
}

const thumb_img={
width:"40px",
height:"40px", 
borderRadius:"4px",
border:"1px solid #929090",
boxShadow: "0px 2px 14px 0px #8C8C8CB6"
}
const labelText = { 
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left" as const, 
    color: "#1C1B1B", 
    fontFamily: "'Poppins', sans-serif" }

    const nameTextFiled = {
    height: "56px",
    width: "495px",
};

const headStyle ={

    fontFamily: "Poppins",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
};

 const typo = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform:"none" as 'none'
 }
 const typo1 = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform:"capitalize" as 'capitalize'

 }


const descriptionTextarea = {
    minHeight: "120px",
    backgroundColor: "#fcf6f6",
    border: "1px solid rgb(229, 226, 225)",
    paddingLeft: "24px",
    paddingTop: "15px",
    borderRadius:"8px",
    fontSize:"16px",
}
const descriptionTextarea1 = {
    minHeight: "120px",
    backgroundColor: "#fff",
    border: "1px solid rgb(229, 226, 225)",
    paddingLeft: "24px",
    paddingTop: "15px",
    borderRadius:"8px",
    fontSize:"16px",
}
const gridStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    padding: "24px",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: "18px",

};
const webStyle={

    addButtonBoxstyle:{
        display: "flex",
        padding:"16px 16px 20px",
        justifyContent:"flex-end",
        alignItems: "end",
        gap: "24px"
    },

    removeButtonBoxstyle:{
        display: "flex",
        width: "100%",
        height: "74px",
        padding:"16px 16px 20px",
        justifyContent:"flex-start",
        alignItems: "center",
        gap: "24px",
    },
  

    addButtonStyle:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        gap: "10px",
        flexShrink:0 ,
        borderRadius:"12px",
        backgroundColor: "#E5E2E1",
        color: "#929090",
    },
    addButtonStyleHighlighted:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        gap: "10px",
        flexShrink:0 ,
        borderRadius:"12px",
        backgroundColor:"#652786",
        color:"#ffff"
    },
    removeButtonStyleBefor:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        gap: "10px",
        flexShrink:0 ,
        borderRadius:"12px",
        border:"1px solid #E5E2E1",
        color:"#929090",
    },
    removeButtonStyleAfter:{
        display: "flex",
        width: "165px",
        height: "42px",
        padding: "12px 24px 12px 16px",
        justifyContent:"center",
        alignItems:"center",
        gap: "10px",
        flexShrink:0 ,
        borderRadius:"12px",
        border:"1px solid #E47004",
        color:"#E47004",
    },

    staticDataStyle :{
        fontFamily:"Poppins",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px", 
        color:"#1C1B1B",  
    } ,
    draftButtonStyle1:{
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        border:"1px solid #E5E2E1" ,
        borderRadius:"16px",
        color:"#929090"
    },
    draftButtonStyle2:{
        display: "flex",
        padding: "20px 32px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        border:"1px solid #E47004" ,
        borderRadius:"16px",
        color:"#E47004"
    },
    roleButtonStyle1:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        backgroundColor:"#E5E2E1",
        borderRadius:"16px",
        color:"#929090"
    },
    roleButtonStyle2:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px", 
        backgroundColor:"#652786",
        borderRadius:"16px",
        color:"#fff"
    },

    statusNew: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "88px",
        background: "#E0D4E7",
        color: "#652786",
        textTransform:"capitalize" as 'capitalize'
        
      },
      statusArchived: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#FFD9DF",
        borderRadius: "88px",
        color: "#E70E6B",
        textTransform:"capitalize" as 'capitalize'
      },
      statusDraft: {
        display: 'flex',
        width: "96px",
        height: "40px",
        padding: "6px 9px",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
        background: "#FFEDE4",
        borderRadius: "88px",
        color: "#E47004",
        textTransform:"capitalize" as 'capitalize'
    
      },
   
}
const MainButton =styled(Box)({
    display: "flex",
    padding: "20px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexDirection:"column",

  
});

const SaveAndCreate =styled(Button)({
    width: "411px",
    height: "56px",
    padding: "20px 32px",
    "@media (max-width:960px)": {
        width:"240px",
        height: "56px",
        padding: "10px 21px",
      },
      "@media (min-width:720px)": {
        
      },
})


 const TitleData =styled("div")({
    color:"#484646",
    fontSize:"16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform:"capitalize" as 'capitalize',

    "@media (max-width:540px)": {
        wordBreak: "break-all",
        width:"56px",
    }

 });

 const StyledBox = styled(Box)({
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"  ,
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", 
    padding:"16px 24px",
    borderRadius:"12px",
    maxWidth:"588px", 
    height:"72px",
    marginBottom:"10px",
    "@media (max-width:960px)": {
        height: "auto"
    }
})

const Statuses =styled("div")({
    backgroundColor:"#E0D4E7",
    textAlign:"center",
    padding:"6px 9px" , 
    color: "#542070", 
    fontSize:"14px", 
    borderRadius:"88px",
    gap:"10px",
    textTransform:"capitalize",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    width:"125px",
    height:"40px",

    "@media (max-width:960px)": {
    fontSize:"10px", 
    padding:"6px 9px" , 
      },
      "@media (min-width:720px)": {
        
      },
});

const TabGrid = styled(Box)({
    display:"flex",
    flexWrap:"wrap"
})

const LibraryBox = styled(Box)({
    
    gap: "10px",
    width: "100%",  

})



const ToggleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: "#fcf6f6",
    alignItems: "center",
    borderRadius: "12px ",
    cursor: "pointer",
    flexWrap:"wrap"


});

const FiterImage = styled("img")({
});

const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    width: "100%",

    "& .filterIconBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        width: "63px",
        borderRadius: "8px",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        cursor: "pointer",
        border: "1px solid #C9C6C5",
        position: "relative",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        borderColor: '#652786',
        backgroundColor: "#EEE8F2",
    }
});

const ToggleInnerbox = styled(Box)({
    color: "#1c1818",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const BadgeComponent = styled(Badge)({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
        top: "-14px",
        left: "20px",
        right: " 0px",
        backgroundColor: " #FF883F",
        fontSize: "81px",
        width: "10px",
        height: "10px",
        borderRadius: "20px"
    }
});

const Addedimg=styled("img")({
        
    "@media (max-width:960px)": {
       width:"250px"
       
      },
      "@media (min-width:720px)": {
      
      },
})

const NoDataImg=styled("img")({
        height:"80%",
        width:"80%",

    "@media (max-width:960px)": {
       width:"250px"
       
      },
      "@media (min-width:720px)": {
      
      },
})

const InputMainBox = styled(Grid)({
    '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px',
}
  
});

export default ContentRoleCreationWeb;