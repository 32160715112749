export const dotsIcon = require("./assets/iconChocolate.png");
export const videoIcon = require("./assets/groupIconVideo.png");
export const deleteIcon = require("./assets/deleteIcon.png");
export const editIcon = require("./assets/EditIcon.png");
export const audioIcon = require("./assets/groupIconAudio.png");
export const imageIcon = require("./assets/groupIconImage.png");
export const documentImage = require("./assets/groupIconDocument.png");
export const quizIcon = require("./assets/groupIconDocument2.png");
export const addIconHover = require("./assets/addIconHover.png");
export const iconClose = require("./assets/iconClose.png");
export const NotFoundImage = require("./assets/Frame.png");
export const iconQuiz = require("./assets/iconQuiz.png");
export const iconPage = require("./assets/iconPage.png");
export const lessonImage = require("./assets/lessonImage.png");
export const dropDownArrow = require("./assets/dropDownArrow.png");
export const lessonDelete = require("./assets/lessonDelete.png");
export const collapseIcon = require("./assets/collapseIcon.png");
