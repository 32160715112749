export const NotificationIcon = require("./assets/images/notification.png");
export const EmptyNotificationIcon = require("./assets/images/empty_notifications.png");
export const MessageIcon = require("./assets/images/message.png");
export const EditIcon = require("./assets/images/edit.png");
export const AvatarImage = require("./assets/images/avatar.jpg");
export const arrowBack = require("./assets/images/ArrowBack.png");
export const iconClose = require("./assets/images/icon_close.png");
export const previewIcon = require("./assets/previewIcon.svg");
export const iconCloseSvg = require("./assets/images/icon_close.svg");
export const previewImg = require("./assets/images/programPreview.png");
export const editIconPreview = require("./assets/icon_edit.svg");
export const lessonpic = require("./assets/lesson.png");
export const docIcon = require("./assets/icon_doc.svg");
export const courseIcon =require("./assets/course_icon.svg");
export const crossIcon =require("./assets/crossIcon.png")
export const starRating =require("./assets/group_starrating.svg")
export const emptyStar =require("./assets/empty_starrating.svg")