import React from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCommonStyles } from "./Styles.web"
import StyledCheckbox from "../../../components/src/StyledCheckbox";
import { TrashIcon } from "./assets";

export default function Option({
  questionInd,
  errors,
  option,
  touched,
  question,
  optionInd,
  questions,
  handleChange,
  values,
  setValues,
  handleRemoveOptionsForMcqQuestions,
}: any) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  
  if (!option._destroy) {
    return (
      <Grid item xs={12} md={6}>
        <Box className={classes.answerOption}>
          <Box className={classes.removeIconContaier}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  name={`questions.${questionInd}.options.${optionInd}.isSelected`}
                  checked={option?.isSelected}
                  onChange={handleChange}
                  color="primary"
                  disabled={
                    questions?.[questionInd]?.questionType !== "MCQ"
                  }
                />
              }
              label={
                <Typography className={classes.optionSelectLabel}>
                  {option.label}
                </Typography>
              }
            />
             <IconButton
                aria-label="delete option"
                disabled={
                  questions?.[questionInd]?.questionType !== "MCQ"
                }
                onClick={() => handleRemoveOptionsForMcqQuestions(
                  questionInd, optionInd, values, setValues
                )}
              >
                <img src={TrashIcon} alt="Delete question" />
              </IconButton>
          </Box>
          <label 
            className={commonClasses.label2}
            htmlFor={`questions.${questionInd}.options.${optionInd}`}
          >
            Answer Text
          </label>
          <br />
          <TextField
            fullWidth
            variant="outlined"
            placeholder={option.placeholder}
            id={`questions.${questionInd}.options.${optionInd}`}
            name={`questions.${questionInd}.options.${optionInd}.text`}
            value={option.text}
            onChange={handleChange}
            error={
              question?.questionType === "MCQ" &&
              touched?.questions?.[questionInd]?.options?.[optionInd]
                ?.text &&
              Boolean(
                errors?.questions?.[questionInd]?.options?.[optionInd]
                  ?.text
              )
            }
            className={classes.textField2}
            helperText={
              question?.questionType === "MCQ" &&
              touched?.questions?.[questionInd]?.options?.[optionInd]
                ?.text &&
              errors?.questions?.[questionInd]?.options?.[optionInd]
                ?.text
            }
            disabled={
              questions?.[questionInd]?.questionType !== "MCQ"
            }
          />
          <FormControlLabel
            control={
              <StyledCheckbox
                name={`questions.${questionInd}.options.${optionInd}.displayAnsGraphic`}
                checked={option?.displayAnsGraphic}
                onChange={handleChange}
              />
            }
            label={
              <Typography className={classes.ansGraphicLabel}>
                Display correct or incorrect answer graphic
              </Typography>
            }
          />
          <Typography className={commonClasses.label2}>
            Response Action
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={
              touched?.questions?.[questionInd]?.options?.[optionInd]
                ?.isCorrect &&
              Boolean(
                errors?.questions?.[questionInd]?.options?.[optionInd]
                  ?.isCorrect
              )
            }
          >
            <Select
              displayEmpty
              name={`questions.${questionInd}.options.${optionInd}.isCorrect`}
              value={option?.isCorrect}
              onChange={handleChange}
              className={classes.actionSelect}
            >
              <MenuItem value="">Select response</MenuItem>
              <MenuItem value="yes">Correct Answer</MenuItem>
              <MenuItem value="no">Incorrect Answer</MenuItem>
            </Select>
            <FormHelperText>
              {
                touched?.questions?.[questionInd]?.options?.[optionInd]
                ?.isCorrect &&
                errors?.questions?.[questionInd]?.options?.[optionInd]
                  ?.isCorrect
              }
            </FormHelperText>
          </FormControl>
        </Box>
      </Grid>
    )
  }
  return null;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    width: "100%",
  },
  answerOption: {
    background: "#F6F6F6",
    border: "1px solid #D6D6D6",
    borderRadius: "8px",
    padding: 5,
    [theme.breakpoints.up("sm")]: {
      padding: 20,
    },
  },
  removeIconContaier: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  checkbox: {
    "& .Mui-checked": {
      color: "#6C328E",
    },
    marginTop: 10,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  },
  optionSelectLabel: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: 600,
    color: "#000000",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      lineHeight: "32px",
    },
  },
  textField2: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D6D6D6",
    },
    "& .MuiOutlinedInput-root": {
      background: "#FFFFFF",
      borderRadius: "8px",
    },
  },
  ansGraphicLabel: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#000000",
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      lineHeight: "15px",
    }
  },
  actionSelect: {
    background: "#FFFFFF",
    borderRadius: 8,
    fontSize: 15,
    lineHeight: "24px",
    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
      lineHeight: "27px",
    },
  },
}))