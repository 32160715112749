import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import './index.css';

interface Data {
  id: number,
  content_role_id: any,
  content_role: any;
  content_role_desc: string;
  status: string;
  date_added: string;
  url: string;
}

function createData(
  id: number,
  content_role_id: any,
  content_role: any,
  content_role_desc: string,
  status: string,
  date_added: string,
  url: string
): Data {
  return { id, content_role_id, content_role, content_role_desc, status, date_added, url };
}
//@ts-ignore
let rows: { id: string | number; content_role_id: string | number; content_role: string | number; content_role_desc: string; status: string | number; date_added: string | number; url: string | number; }[] = [

];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'content_role_id', numeric: true, disablePadding: false, label: 'Content Role ID' },
  { id: 'content_role', numeric: false, disablePadding: false, label: 'Content Role' },
  { id: 'content_role_desc', numeric: false, disablePadding: false, label: 'Content Role Description' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'date_added', numeric: false, disablePadding: false, label: 'Date Added' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        <TableCell padding="checkbox">
        </TableCell>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: 'none',
    },
    table: {
      minWidth: 750,
      width: '100%',
      display: 'table',
      background: '#f1f1f1',
      borderRadius: '20px 20px 0 0',
      borderSpacing: '0 25px',
      borderCollapse: 'inherit'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableRowRoot: {
      "&$tableRowSelected, &$tableRowSelected:hover": {
        backgroundColor: "#ddb9f0",
        padding: '20px'
      },
      borderRadius: '20px',
      background: '#fff'
    },
    tableRowSelected: {
      backgroundColor: "#fff",
      borderRadius: '20px',
    },
    tableBody: {
      "& > :not(:last-child)": {
        borderBottom: "25px solid #FAFAFA"
      }
    },
    paginationRoot: {
      padding: 0,
      background: '#f1f1f1',
      borderRadius: '0 0 20px 20px',
    },

  }),
);

export default function EnhancedTable(props: any) {
  //   console.log(props.data,"<===data")
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('content_role_id');
  const [selected, setSelected] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const MAX_LENGTH = 10; `  `
  const [expanded, setExpanded] = React.useState(false);
  rows = []
  rows = props.contentRoleList.map((item: any) => createData(item.attributes.id, item.attributes.uniq_id, item.attributes.name, item.attributes.description, item.attributes.status, new Date(item.attributes.created_at).toLocaleDateString("en-US"), item.attributes.thumbnail_image == null ? "" : item.attributes.thumbnail_image.url))
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      //@ts-ignore
      const newSelecteds = rows.map((n) => n.id);
      props.selectedContentRole(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row: any, name: string, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    const selectedItem = newSelected.map((item, index) => checkData(item))
    props.selectedContentRole(selectedItem)
    console.log(newSelected, selectedItem, "newSelect")
    setSelected(newSelected);
    // props.navigateTo(id)
  };

  const checkData = (item: number) => {
    const filtered = rows.filter(row => row.id === item)
    return filtered[0]
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    props.getContentRole({ course_id: "", search: props.search, per: rowsPerPage, page: page })
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  



  return (
    <div className={classes.root} id="content-role-table" style={{ marginTop: "2%" }}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            id="manage-table"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  //@ts-ignore
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //@ts-ignore
                      onClick={(event) => handleClick(event, row.name, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      classes={{
                        root: classes.tableRowRoot,
                        selected: classes.tableRowSelected,
                      }}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <span style={{ position: "relative" }}>
                          <input id={`id-${index}`} type="checkbox" checked={isItemSelected} />
                          <label htmlFor={`id-${index}`} className="block" />
                        </span>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <img
                          //@ts-ignore
                          src={"https://images.unsplash.com/photo-1520085601670-ee14aa5fa3e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"}
                          style={{
                            height: "40px",
                            width: "40px",
                            border: "1px solid #979797",
                            filter:
                              "drop-shadow(0px 2px 14px rgba(140, 140, 140, 0.715335))"
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">{row.content_role_id}</TableCell>
                      <TableCell component="th" align='center' id={labelId} scope="row" padding="none">
                        {row.content_role}
                      </TableCell>
                      <TableCell align="right">{row.content_role_desc?.length > 50 ? `${row.content_role_desc.substring(0, 50)}...` : row.content_role_desc }</TableCell>
                      {/* <TableCell align="right"> {row.content_role_desc}</TableCell> */}
                      {/* <TableCell align="right">
                        <span style={{ color: expanded ? 'blue' : 'inherit' }}>{expanded ? row.content_role_desc : row.content_role_desc?.substring(0, 10)}</span>
                        {row.content_role_desc?.length > 10 && (
                          <button className="btn" onClick={()=> setExpanded(!expanded)}>
                            {expanded ? 'Show less' : 'Show more'}
                          </button>
                        )}
                      </TableCell> */}
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.date_added}</TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.meta === "" ? rows.length : props.meta.pagination.total_count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            root: classes.paginationRoot,
          }}
        />
      </Paper>
    </div>
  );
}
