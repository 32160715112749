export const NEW_COURSE_ASSIGNED = "new_course_assigned";
export const NEW_COURSE_ENROLL = "new_course_enroll";
export const NEW_COURSES = "new_courses";
export const DUE_REMINDER = "due_reminder";
export const EARNED_REWARD = "earned_reward";
export const NEW_REWARD = "new_reward";
export const EARNED_REWARD_ADMIN = "earned_reward_admin";
export const EARNED_ACHIEVEMENT = "earned_achivement";
export const NEW_ACHIEVEMENT = "new_achivement";
export const EARNED_ACHIEVEMENT_ADMIN = "earned_achivement_admin";
export const COURSE_COMPLETED = "Course_complete";
