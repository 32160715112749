import React from "react";

const CustomCarousel = ({ datavalue,children,getTransformValue }:any) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [itemsToShow, setItemsToShow] = React.useState(1);
  
    React.useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth >= 1024) {
          setItemsToShow(3);
        } else if (window.innerWidth >= 768) {
          setItemsToShow(2);
        } else {
          setItemsToShow(1);
        }
      };
  
      window.addEventListener("resize", handleResize);
      handleResize(); 
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex:number) =>
        prevIndex === datavalue.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex:number) =>
        prevIndex === 0 ? datavalue.length - 1 : prevIndex - 1
      );
    };
  
    const goToSlide = (index:number) => {
      setCurrentIndex(index);
    };
    const transformValueByWidth = (windowWidth:number,currentIndex:number,itemsToShow:number) => {
      if(windowWidth>900){
        return -(currentIndex * 50) / itemsToShow
      } else if(windowWidth<900 && windowWidth > 500){
        return -(currentIndex * 70) / itemsToShow
      } else if(windowWidth < 500){
        return -(currentIndex * 20) / itemsToShow
      }
    }

    const getTransformValueFormat = (currentIndex:number,itemsToShow:number) => {
      return transformValueByWidth(window.innerWidth,currentIndex,itemsToShow)
     }

    return (
      <div className="carousel">
      <div
        className="carousel-inner"
        style={{
          transform: `translateX(${getTransformValue ? getTransformValue(currentIndex,itemsToShow) : getTransformValueFormat(currentIndex,itemsToShow)}%)`,
          width: `${(datavalue.length / itemsToShow) * 100}%`,
        }}
      >
        {children(itemsToShow)}
      </div>
      <button className="carousel-button prev" onClick={prevSlide}>
        ❮
      </button>
      <button className="carousel-button next" onClick={nextSlide}>
        ❯
      </button>
      <div className="carousel-dots">
        {datavalue.map((_:any, index:number) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
    );
  };

  export default CustomCarousel