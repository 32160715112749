import React from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { BoxImg, Reward, Arrow } from "../assets";
// Customizable Area Start
import { Line } from 'react-chartjs-2';
import Loader from "../../../../components/src/Loader"
import moment from 'moment';
import './Style.css';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "32px",
    borderRadius: "8px",
  },
  colorPrimary: {
    backgroundColor: "#66c9e840",
  },
  bar: {
    borderRadius: "8px",
    background: "linear-gradient(321deg, #592A93 0%, #BB1AB9 100%)",
  },
}))(LinearProgress);
// Customizable Area End

import AchievementListingController, {
  Props,
} from "./AchievementListingController.web";

export default class AchievementDetails extends AchievementListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   const currentUrl = window?.location?.href;
   const url = new URL(currentUrl);
   const id = url.pathname.split("/").pop(); // Extract id from the URL

    if (id) {
      await this.achievementDetails(id)
    }
    else {
      this.props.history.push("/AchievementListing")
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ display: 'flex', margin: '0px 40px 20px 10px' }}>
          <Box style={{ marginTop: '8px' }}>
            <img src={Arrow}></img>
          </Box>
          <Typography
            variant='h5'
            style={{ fontWeight: 700, fontSize: '48px' }}
          >
            {'Achievements'}
          </Typography>
        </Box>
        <Container maxWidth="lg" style={{ display: "flex" }}>

          <Box
            style={{
              width: "100%",
              marginTop: "15px",
              background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
              padding: "20px",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.08)",
            }}>
            <Box>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, fontSize: "24px" }}>
                {
                  this.state?.achievementDetailsData?.data?.attributes
                    ?.achievement?.attributes?.name
                }

              </Typography>

            </Box>

            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}>
                  <Box style={{ marginTop: 70, paddingRight: '12px' }}>
                    <img width={126} height={132}
                      src={this.state?.achievementDetailsData?.data?.attributes?.achievement?.attributes?.thumbnail_image?.url || BoxImg}></img>
                  </Box>
                  <div className="canvas-details">
                    <LineChart2 state={this?.state} />
                  </div>


                  <div className="right">

                    <div style={styles.BarDropDiv}>

                      <Typography style={styles.BarDropTypography}>

                        <FormControl style={styles.formControl}>

                          <Select

                            style={styles.FormControlSlect}

                            value={this.state?.selected}

                            disableUnderline

                            label={"Weekly"}

                            inputProps={{

                              name: "agent",

                              id: "age-simple",

                            }}

                            onChange={(e) => {

                              e.target.value != " " && this.handleChange(e);

                            }}>

                            <MenuItem disabled value=" ">

                              Weekly

                            </MenuItem>




                            <MenuItem

                              onClick={() => {

                                this.setState({ selected: "This Week" });

                              }}

                              value={"This Week"}>

                              This Week

                            </MenuItem>

                            <MenuItem

                              onClick={() => {

                                this.setState({ selected: "Last Week" });

                              }}

                              value={"Last Week"}>

                              Last Week

                            </MenuItem>

                          </Select>

                        </FormControl>

                      </Typography>

                    </div>

                    <Typography

                      style={{

                        fontWeight: 700,

                        fontSize: "18px",

                        marginTop: "35px",

                        marginBottom: "-10px",

                        marginLeft: "40px",

                      }}>

                      Videos Watched

                    </Typography>

                    <Grid container style={{ paddingTop: "0px", display: "flex", flexDirection: "column" }}>

                      <Grid

                        item

                        lg={12}

                        md={12}

                        sm={12}

                        xs={12}

                        style={{}}>

                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "30px", alignItems: "center" }}>
                          <div

                            style={{

                              width: "16px",

                              height: "16px",

                              backgroundColor: "#5542F6",

                              borderRadius: "10px",

                            }}></div>

                          <Typography

                            style={{
                              fontWeight: 400,
                              fontSize: "16px",
                            }}>

                            This Week

                          </Typography>

                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: "18px",
                            }}>
                          
                            {this.state.chartData?.graph_data?.week_count}

                          </Typography>
                        </div>



                      </Grid>

                      <Grid

                        item

                        lg={12}

                        md={12}

                        sm={12}

                        xs={12}

                        style={{}}>

                        <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "30px", alignItems: "center" }}>

                          <div

                            style={{

                              width: "16px",

                              height: "16px",

                              backgroundColor: "#FF8B3F",

                              borderRadius: "10px",

                            }}></div>

                          <Typography

                            style={{

                              fontWeight: 400,

                              fontSize: "16px",

                            }}>

                            Last Week

                          </Typography>
                          <Typography

                            style={{

                              fontWeight: 700,

                              fontSize: "18px",

                            }}>
                         
                            {this.state.chartData?.graph_data?.last_count}

                          </Typography>
                        </div>



                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>

                        <div

                          style={{
                            display: "flex",
                            marginTop: "30px",
                            justifyContent: "space-evenly",
                            paddingTop: "30px",
                            alignItems: "center"
                          }}>
                          <Typography

                            style={{

                              fontWeight: 700,

                              fontSize: "16px"

                            }}>

                            Total Videos

                          </Typography>

                          <Typography

                            style={{

                              fontWeight: 700,

                              fontSize: "18px",

                              marginTop: "5px",

                            }}>
                            {this.state.chartData?.total_video_count}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "100%", mt: "15px", }}>
              <Grid container spacing={3}>
                <Grid item xs={10} style={{ position: "relative", left: "140px" }}>
                  <Box sx={{ position: "relative", marginTop: "45px" }}>
                    <Typography
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        left: "0",
                        right: "0",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}>
                      {
                        this.state?.achievementDetailsData?.meta
                          ?.watched_video_count
                      }
                      /
                      {
                        this.state?.achievementDetailsData?.meta

                          ?.total_video_count

                      }

                    </Typography>

                    <BorderLinearProgress

                      variant="determinate"

                      value={

                        (this.state?.achievementDetailsData?.meta

                          ?.watched_video_count /

                          this.state?.achievementDetailsData?.meta

                            ?.total_video_count) *

                        100

                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>

        <Container maxWidth="lg" style={{ display: "flex", marginTop: "30px" }}>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Box>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: "10px",
                      fontWeight: 700,
                      fontSize: "24px",
                    }}
                  >
                    Achievement description

                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {this.state?.achievementDetailsData?.data?.attributes?.achievement?.attributes?.description}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "30px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: "10px",
                      fontWeight: 700,
                      fontSize: "24px",
                      display: 'flex',
                      alignItems: 'center',
                      gap: '60px'
                    }}
                  >
                    To earn this achievement
                    <span style={{ fontSize: '12px' }}>{"Due to " + moment(this.state?.achievementDetailsData?.data?.attributes?.due_date, 'DD-MM-YYYY').format('DD MMM YYYY')}</span>
                  </Typography>
                  {this.state?.achievementDetailsData?.data?.attributes?.statements?.map((val: any, index: any) => (
                    <Typography
                      key={index + 1}
                      component="p"
                      style={{ width: "100%", display: "block" }}
                    >
                      {index + 1 + ". " + val.text}
                    </Typography>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  style={{
                    background: "linear-gradient(185deg, #FFF 0%, #F0F3F6 100%)",
                    padding: "20px",
                    borderRadius: "16px",
                    border: "1px solid rgba(0, 0, 0, 0.08)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  <Box>
                    <img src={this.state?.achievementDetailsData?.data?.attributes?.rewards?.image || Reward}
                      width={'100px'}
                      height={"100px"}
                      alt="icon" />
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 700, fontSize: "24px" }}
                    >
                      {this.state?.achievementDetailsData?.data?.attributes?.rewards?.title || "Reward"}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        marginTop: "5px",
                      }}
                    >
                      {this.state?.achievementDetailsData?.data?.attributes?.rewards?.text}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {this.state.isloading ? <Loader loading={this.state.isloading} /> : null}
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const LineChart2 = ({ state }: any) => {

  const data = {
    responsive: true,
    labels: state?.selected === "This Week" ? state?.thisDateWeek : state?.lastDateWeek,
    datasets: [
      {
        id: 1,
        label: '',
        data: state?.selected === "This Week" ? state?.thisWeekCount : state?.lastWeekCount,
        backgroundColor: 'transparent',
        borderColor: state?.selected === "This Week" ? '#5542F6' : '#FF8B3F',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 14,
        ticks: {
          stepSize: 2,
          callback: (value: any) => value
        },
      }
    },
  };

  return (
    <>
      <div style={{ width: '100%', height: '250px', marginTop: '20px' }}>
        <Line height="250px" data={data} options={options}></Line>
      </div>
    </>
  )
}

const styles = {
  mainhadding: {
    display: 'contents'
  },
  mainContainer: {
    display: 'flex'
  },
  divContainer: {
    width: '130px',
    height: '46px',
    marginTop: '3px'
  },
  filterButton: {
    color: '#6C328B',
    height: '100%',
    width: '100%',
    border: '1px solid #6C328B'
  },
  IconDiv: {
    width: '100%',
    marginTop: '3px',
    paddingLeft: '15px'
  },
  filterIcon: {
    width: '40px',
    height: '46px',
    borderRadius: '10px',
    border: '1px solid #6C328B',
    cursor: 'pointer'
  },
  mainOfferPaper: {
    flex: 1,
    marginTop: '25px',
    padding: '30px',
    borderRadius: '24px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    overflow: 'hidden',
    maxWidth: '90vw',
    height: '384px',
    margin: '25px auto auto auto'
  },
  offerTypography: {
    fontWeight: 700,
    marginBottom: '20px',
    fontSize: '24px'
  },
  filterPaper: {
    overflow: 'hidden',
    padding: '30px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    borderRadius: '24px',
    flex: 1,
    maxWidth: '90vw',
    height: '384px',
    margin: 'auto',
    marginTop: '25px'
  },
  learningHadding: {
    fontWeight: 700,
    fontSize: '24px'
  },
  BarDropDiv: {
    display: 'inline-grid',
    marginTop: '8px',
    marginLeft: '25px',
    justifyContent: 'space-around'
  },
  BarDropTypography: {
    fontWeight: 700,
    fontSize: '17px',
    marginTop: '-30px',
    cursor: 'pointer'
  },
  formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  FormControlSlect: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  barCart2Maindiv: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  barCart2Typography: {
    fontWeight: 700,
    fontSize: '17px',
    marginTop: '0px',
    cursor: 'pointer'
  },
  barCart2formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  select: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  }
}
// Customizable Area End
