import React from "react";
import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FieldArray } from "formik";
import Option from "./Option.web";
import { useCommonStyles } from "./Styles.web"

export default function AnswerOptions({
  index,
  errors,
  values,
  touched,
  question,
  questions,
  handleChange,
  setValues,
  handleAddMcqOption,
  handleRemoveOptionsForMcqQuestions,
}: any) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <Paper className={commonClasses.paper} style={{ marginTop: 15 }}>
      <Typography 
        className={commonClasses.label} 
        style={{ marginBottom: 15 }}
      >
        answer options
      </Typography>
      <Grid container spacing={2}>
        <FieldArray
          name={`questions.${index}.options`}
          render={() =>
            question?.options &&
            question.options.length > 0 &&
            question.options.map((option: any, optionInd: number) => (
              <Option 
                key={optionInd}
                questionInd={index}
                errors={errors}
                option={option}
                touched={touched}
                question={question}
                questions={questions}
                optionInd={optionInd}
                handleChange={handleChange}
                handleRemoveOptionsForMcqQuestions={
                  handleRemoveOptionsForMcqQuestions
                }
                values={values}
                setValues={setValues}
              />
            ))
          }
        />
        {question.questionType === "MCQ" && (
          <Grid item xs={12}>
            <Typography
              className={classes.answerChoiceText}
              onClick={() => handleAddMcqOption(index, values, setValues)}
            >
              + Add answer choice
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    marginBottom: "20px",
    padding: 10,
    boxShadow:
      "-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 15px",
      borderRadius: "20px",
    },
  },
  label: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "36px",
    color: "#1A1A1A",
    textTransform: "capitalize",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
  },
  answerChoiceText: {
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "15px",
    textAlign: "left",
    textDecorationLine: "underline",
    color: "#202020",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: "27px",
      textAlign: "right",
    },
  }
}))
