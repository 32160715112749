import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React, { MouseEvent, useState, useEffect } from "react";
import { runEngine } from "framework/src/RunEngine";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { EditIcon, MessageIcon, NotificationIcon, EmptyNotificationIcon, AvatarImage, arrowBack, iconClose, previewIcon, previewImg, docIcon, editIconPreview, lessonpic, courseIcon, crossIcon, starRating, emptyStar } from "./assets";
import Settings5 from "../../../blocks/settings2/src/Settings5.web";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import CancelIcon from '@material-ui/icons/Cancel';
import Fade from "@material-ui/core/Fade";
// import { draftUI } from "../../../blocks/coursecreation/src/AddContentPage.web";
import Backdrop from "@material-ui/core/Backdrop";
import ProfileModal from "../../../blocks/customisableuserprofiles/src/ProfileModal.web";
import MyIntrest from "../../../blocks/customisableuserprofiles/src/MyInterest.web";
import Notifications2 from "../../../blocks/notifications/src/Notifications2.web";
import { compareTime, capitalizeFirstLetter } from "../../../blocks/utilities/src/CommonHelpers";
import Congratulations from "../../../blocks/dashboard/src/Congratulations.web";
import EditEmail from "../../../blocks/customisableuserprofiles/src/EditEmail.web";
import DynamicTitle from "../DynamicTitle";
import { ArrowBack } from "@material-ui/icons";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import GoBackConfirmationModal from "../ConfirmationModal/GoBackConfirmationModal";
import DeleteModal from "../DeleteDialog/DeleteModal";
const { baseURL } = require("../../../framework/src/config");
import CoursePreviewWeb from "../../../blocks/catalogue/src/CoursePreview.web";
import { checkURLPathStartWith, getCurrentPathName } from "../Utilities";
import CloseIcon from '@material-ui/icons/Close';
import { ProgramCourse, ProgramPreviewType } from "../../../blocks/catalogue/src/CatalogueController";
import { LightTooltip } from "../../../../packages/components/src/Table";
import { Rating } from "@material-ui/lab";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from "moment";
import Title from "../DynamicTitle";

interface Props extends RouteComponentProps {
  handleDrawerToggle: any;
  drawerWidth: number;
  roles: string[];
  navigation: any;
  cartItemsCount?: number;
  routeNotFound: boolean;
  locationSet:any
}

interface LeaveModalProps {
  isLeave: boolean;
  handleClose: () => void;
}

const USER_DATA_URL =
  `${baseURL}/bx_block_roles_permissions/profiles`;
const NOTIFICATIONS_DATA_URL = `${baseURL}/notifications/notifications`;
const ACTIVATION_CONFIRMATION = `${baseURL}/account_block/account/close_email_confirmation_screen`;
const TimeSpentTrainingTrack = `${baseURL}/bx_block_adhocreporting/training_track`;
const DISCARD_CHANGES_URL = `${baseURL}/bx_block_coursecreation/discard_changes/`
const COURSE_PREVIEW = `${baseURL}/bx_block_coursecreation/courses/`
const PROGRAM_DISCARD_CHANGES_URL = `${baseURL}/bx_block_coursecreation/discard_program_changes/`
const PROGRAM_PREVIEW = `${baseURL}/bx_block_coursecreation/programs/`;

function Topbar({
  handleDrawerToggle,
  drawerWidth,
  history,
  roles,
  navigation,
  cartItemsCount,
  routeNotFound,
  locationSet
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditEmail, setIsOpenEditEmail] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [notiOpen, setNotiOpen] = useState(false);
  const [cartItem, setCartItem] = useState(0);
  const [role, setRole] = useState('');
  const [userRole, setUserRole] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationExists, setNotificationExists] = useState(false);
  const fullScreen = useMediaQuery("(max-width:600px)");
  const [modalOpen, setModalOpen] = useState(false)
  const [backModal, setBackModal] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [previewId,setPreviewId] = useState("");
  const [isPreview, setISPreview] = useState(false)
  const [coursePreviewData, setCoursePreviewData] = useState(null)
  const [isLeave, setIsLeave] = useState(false); 
  const [isProgramPreview, setIsProgramPreview] = useState(false);;
  const [programPreviewData, setProgramPreviewData] = useState<ProgramPreviewType | null>(null);
  const [arrowClick, setArrowClick] = useState<string[]>([]);
  const [toggleDropDown, setToggleDown] = useState(false);

  const [goBackConfirmationOpen, setGoBackConfirmationOpen] = useState<boolean>(false)
  const [goBackHeadingMessage, setGoBackHeadingMessage] = useState<string>("")
  const [goBackBodyMessage, setGoBackBodyMessage] = useState<string>("")
  const [goBackButtonText, setGoBackButtonText] = useState<string>('Go Back')
  const [goBackHandleLeaveButton, setGoBackHandleLeaveButton] = useState<string>('Leave')
  const [goBackHandleCancel, setGoBackHandleCancel] = useState<() => void>(() => { })
  const [goBackHandleClose, setGoBackHandleClose] = useState<() => void>(() => { })
  const [goBackHandleLeave, setGoBackHandleLeave] = useState<() => void>(() => { })

  const programGoBackCancel = () => {
    setGoBackConfirmationOpen(false)
    setGoBackHeadingMessage("")
    setGoBackBodyMessage("")
    setGoBackButtonText("")
    setGoBackHandleLeaveButton("")
    setGoBackHandleCancel(() => { })
    setGoBackHandleClose(() => { })
    setGoBackHandleLeave(() => { })
  }

  const programGoBackLeave = () => {
    navigation.navigate('ContentManagement')
    programGoBackCancel();
  }
  const programID = getStorageData("currentProgramID")

  const programCreationGoBack = async () => {
    const programID = await getStorageData("currentProgramID")
    navigation.navigate("ProgramCreation", {type: "addProgram",id: programID});
    programGoBackCancel();
  }

  const userManagementGoBack = () => {
    history.push("/usermanagement");
    programGoBackCancel();
  }

  const userManageGoBack = async () => {
    const userID = await getStorageData("currentUserID")
      history.push(`/user-manage/${userID}`) 
    programGoBackCancel();
  }

  const branchAssignContentGoBack = async () => {
    const userID = await getStorageData("currentBranchID")
      history.push(`/BranchDetails/${userID}`) 
    programGoBackCancel();
  }

  const branchAssignContentEditGoBack = async () => {
    const userID = await getStorageData("currentBranchID")
      history.push(`/BranchDetailsEdit/${userID}`) 
    programGoBackCancel();
  }

  const showBranchGoBack = () => {
    history.push("/ShowBranch");
    programGoBackCancel();
  }

  const branchManagementGoBack = () => {
    history.push("/ShowBranch");
    programGoBackCancel();
  }

  const handleProgramBack = () => {
    setGoBackHeadingMessage("Save to Draft to avoid losing changes")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Keep Creating')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => programGoBackLeave)
    setGoBackConfirmationOpen(true)
  }

  const handleProgramContentBack = () => {
    setGoBackHeadingMessage("Save to Draft to avoid losing changes")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Go Back')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => programCreationGoBack)
    setGoBackConfirmationOpen(true)
  }
  const handleUserManagementBack = () => {
    setGoBackHeadingMessage("Are you sure?")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Go Back')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => userManagementGoBack)
    setGoBackConfirmationOpen(true)
  }
  const handleManageAssignBack = () => {
    setGoBackHeadingMessage("Save changes to avoid losing them.")
    setGoBackBodyMessage("If you change assignee before saving changes,  you lose your edits.")
    setGoBackButtonText('Continue')
    setGoBackHandleLeaveButton("Cancel")
  }
  const handleBranchManagementBack = () => {
    setGoBackHeadingMessage("Are you sure you want to leave?")
    setGoBackBodyMessage("All your changes will be lost.")
    setGoBackButtonText('Cancel')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => branchManagementGoBack)
    setGoBackConfirmationOpen(true)
  }
  const handleBranchAssignContentBack = () => {
    setGoBackHeadingMessage("Are you sure you want to leave?")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Cancel')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => branchAssignContentGoBack)
    setGoBackConfirmationOpen(true)
  }
  const handleBranchAssignContentEditBack = () => {
    setGoBackHeadingMessage("Are you sure you want to leave?")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Cancel')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => branchAssignContentEditGoBack)
    setGoBackConfirmationOpen(true)
  }

  const handleUserManageBack = () => {
    setGoBackHeadingMessage("Are you sure?")
    setGoBackBodyMessage("If you leave without saving, all your changes will be lost.")
    setGoBackButtonText('Go Back')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => userManageGoBack)
    setGoBackConfirmationOpen(true)
  }

  const handleCreateBranchBack = () => {
    setGoBackHeadingMessage("Are you sure you want to leave?")
    setGoBackBodyMessage("All your changes will be lost.")
    setGoBackButtonText('Cancel')
    setGoBackHandleCancel(() => programGoBackCancel)
    setGoBackHandleClose(() => programGoBackCancel)
    setGoBackHandleLeave(() => showBranchGoBack)
    setGoBackConfirmationOpen(true)
  }

  const rotateArrowIcon = (arrowId: string) => {
    setArrowClick(prevState => {
      const isMatch = prevState.includes(arrowId);
      if (isMatch) {
        return prevState.filter(item => item !== arrowId);
      } else {
        return [...prevState, arrowId];
      }
    });
  };
  
  const classes = useStyles({
    drawerWidth,
  });

  const token = localStorage.getItem("token");
  const headers = { token };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const editCourseContent:any =  localStorage.getItem("EditCourseContent")
  const editProgramContent:any =  localStorage.getItem("EditProgramContent")


  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    compareTime();
    setAnchorEl(null);
    handleMenuClose();
    handleMobileMenuClose();
    localStorage.removeItem("token");
    localStorage.removeItem("first_name");
    localStorage.removeItem("tracker_time");
    history.push("/login");
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSettingsOpen = () => {
    setOpen(true);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleSettingsClose = () => {
    setOpen(false);
  };

  const handelEditEmailModelClose = () => {
    setIsOpenEditEmail(false)
  }
  const handelEditEmailModel = () => {
    setIsOpenEditEmail(true)
    handelEditModelClose();
  }

  const removeTokenAndRedirectTo = (path: string) => {
    localStorage.removeItem("token");
    localStorage.removeItem("first_name");
    history.push(path);
  }

  let callCount = 0;

  useEffect(() => {
    // Function to start the timer
    const startTimer: any = () => {
      const intervalId = setInterval(() => {
        callCount++;
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    };
    const pathSegments = window.location.pathname.split('/');
     setPreviewId(pathSegments[2])
    startTimer();

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      timeSpentOnTraining();
      localStorage.setItem('elapsedTime', callCount.toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // clearInterval(startTimer);
    };
  }, []);

  const timeSpentOnTraining = async () => {
    const formdata = new FormData();
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const storedCallCount: any = localStorage.getItem('elapsedTime');
    formdata.append("track_time", storedCallCount);
    formdata.append("track_date", formattedDate.toString());

    const requestOptions = {
      method: 'POST',
      headers: {
        token,
        // 'Content-Type': 'multipart/form-data',
      },
      body: formdata,
      redirect: 'follow'
    };

    try {
      // @ts-ignore
      const response = await fetch(TimeSpentTrainingTrack, requestOptions);
      if (!response.ok) {
        console.error("Something is wrong in time spent on training api response");
      }
    } catch (error) {
      console.error("Something went wrong in time spent on training api");
    }
  }

  const getUserData = async () => {
    try {
      // @ts-ignore
      const response = await fetch(USER_DATA_URL, { headers });
      if (!response.ok) {
        removeTokenAndRedirectTo("/login");
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        const {
          data: {
            attributes: { first_name, last_name, avatar, user_role, activation_confirmation },
          },
        } = userData;
        await setStorageData("userData", JSON.stringify(userData.data))
        setUserRole(user_role);
        if (activation_confirmation === false) {
          handelEditDailogOpen();
        }
        // Set first name & user role in the local storage
        localStorage.setItem("first_name", first_name);
        localStorage.setItem("user_role", user_role);
        localStorage.setItem("expert_id", userData.data.id);
        if (roles?.includes(user_role) || routeNotFound) {
          setUsername(`${first_name} ${last_name}`);
          setAvatarUrl(avatar?.url);
          setRole(user_role);
        } else {
          // Redirect users to login if their role doesn't permit to view
          // the page to be rendered
          toast.error("You are not authorized to view this page");
          removeTokenAndRedirectTo("/login");
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      removeTokenAndRedirectTo("/login");
    } finally {
      setIsLoading(false);
    }
  };

  const getNotifications = async () => {
    const allNotification = await getStorageData("totalNotifications",true)
    try {
      // @ts-ignore
      const response = await fetch(NOTIFICATIONS_DATA_URL, { headers });
      if (!response.ok) {
        toast.error("Something went wrong");
        return;
      }
      // Handle data
      const notifications = await response.json();
        if (notifications.data.length > 0) {
          setNotificationExists(true);
          await setStorageData("totalNotifications",notifications?.meta?.pagination?.total_count)
      }else if(allNotification === notifications?.meta?.pagination?.total_count){
        setNotificationExists(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const discardChanges = async (id: any) => {

    try {
      // @ts-ignore
      const response = await fetch(DISCARD_CHANGES_URL + id, { headers });
      if (!response.ok) {
        toast.error("Something went wrong");
        return;
      }
      // Handle data
      const discardChanges = await response.json();
      const courseID = await getStorageData("currentCourseID")

      if (discardChanges.message) {

        toast.success(discardChanges.message, { autoClose: 2000 });
     if(editCourseContent){
          history.push(`/CourseCreation/${courseID}/editCourse`)
        }else if(!!courseID) {
          history.push(`/CourseCreation/${courseID}/addCourse`)
        } else {
          history.push(`/CourseCreation/new/addCourse`)
        }
        setModalOpen(false);
      }
    
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const discardProgramChanges = async (id: any) => {

    try {
      // @ts-ignore
      const response = await fetch(PROGRAM_DISCARD_CHANGES_URL + id, { headers });
      if (!response.ok) {
        toast.error("Something went wrong");
        return;
      }
      // Handle data
      const discardChanges = await response.json();
      const programId = await getStorageData("currentProgramID")

      if (discardChanges.message) {

        toast.success(discardChanges.message, { autoClose: 2000 });

        if(editProgramContent){
          history.push(`/ProgramCreation/${programId}/editProgram`)
        }else if(!!programId) {
          history.push(`/ProgramCreation/${programId}/addProgram`)
        } else {
          history.push(`/ProgramCreation/new/addProgram`)
        }
        setModalOpen(false);
      }
    
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const handelEditDailogOpen = () => {
    setIsModalOpen(true);
  }

  const getCoursePreviewListing = async (idData:string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        token,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(COURSE_PREVIEW + idData, requestOptions as RequestInit);
      if (!response.ok) {
        removeTokenAndRedirectTo("/login");
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        setCoursePreviewData(userData?.data)
      }
    } catch (error) {
      toast.error("Something went wrong");
    } 
}  
const handlePreview =(idValues:string)=>{
    setISPreview(true)
    getCoursePreviewListing(idValues)
  }

const fetchProgramPreview = async (idData:string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      token,
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await fetch(PROGRAM_PREVIEW + idData, requestOptions as RequestInit);
    if (!response.ok) {
      removeTokenAndRedirectTo("/login");
      const { status } = response;
      // Handle expired token
      if (status === 401) {
        toast.error("Your session has expired. Please log in again");
      } else if (status === 422) {
        toast.error("Your account is not valid");
      } else {
        toast.error("Something went wrong");
      }
    } else {
      // Handle data
      const userData = await response.json();
      setProgramPreviewData(userData?.data)
    }
  } catch (error) {
    toast.error("Something went wrong");
  } 
}
const handleProgramPreview =(idValues:string)=>{
  setIsProgramPreview(true)
  fetchProgramPreview(idValues)
}

  const handelEditDailogClose = async () => {
    try {
      // @ts-ignore
      const response = await fetch(ACTIVATION_CONFIRMATION, { headers });
      if (!response.ok) {
        removeTokenAndRedirectTo("/login");
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        const {
          success
        } = userData;
        if (success === true) {
          setIsModalOpen(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      removeTokenAndRedirectTo("/login");
    } finally {
      setIsLoading(false);
    }
  }

  const handelEditModel = () => {
    setIsOpen(true);
  }

  const handelEditModel2 = () => {
    setIsModal(false);
  }

  const handelEditModelClose = () => {
    setIsOpen(false);
  }

  const handelEditModelOpen = () => {
    setIsModal(true);
  }

  const handleOpenNotification = () => {
    setNotiOpen(true);
    setNotificationExists(false)
    handleMobileMenuClose();
  }
  const handleArrowBack = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }


  const handleCloseNotification = () => {
    setNotiOpen(false);
  }

  const handleMessages = () => {
    toast.success("Coming soon");
    handleMobileMenuClose();
  }

  const handleBackAddPhases = async() => {
    const courseCreationDirtyVal = await getStorageData("courseCreationDirtyVal")
    const isProgramFormDirty = await getStorageData("isProgramFormDirty")
    if(courseCreationDirtyVal==="true"){
      setBackModal(true)
    } else if (isProgramFormDirty) {
      setBackModal(true)
    }
    else if (ProgramContent){
      setBackModal(true)
    }
      else{
      history.push('/ContentManagement')
    }
}

  const handleLooseModalClose = () => {
    setBackModal(false)
  }

  const handleBackCreate = () => {
    setIsLeave(true); 
  };

  const handleLeaveClose = () => {
    setIsLeave(false);
  };

  const handleBackNew = async () => {
   
  if(editCourses){
    const isModalOpen = await getStorageData("ispreviewModalOpne",true)
    isModalOpen && setStorageData("coursePreview",previewId)
    setStorageData("ispreviewModalOpne","false")
    history.push(`/ContentManagement`) 
  } else if (editProgram) {
    // const isModalOpen = await getStorageData("ispreviewModalOpne",true)
    // isModalOpen && setStorageData("coursePreview",previewId)
    // setStorageData("ispreviewModalOpne","false")
    history.push(`/ContentManagement`) 
  }
 
  else{
    setBackModal(false)

  }
    }

  const handleClose = () => {
    setBackModal(false)
    { addCourses && draftBackModal(backModal) }
    { addPhases && draftBackModal(backModal) }
  }

  const handleLooseLeave = async () => {
    const courseID = await getStorageData("currentCourseID")
    if(editCourses || editProgram){
      setBackModal(false);
    }
    else if(ProgramContent){
      const programID = await getStorageData("currentProgramID")
      history.push(`/ProgramCreation/${programID}/addProgram`) 
    }
    else if(lessonBack&&(editLesson||addLesson)){
      history.push(Phases ? `/AddContentPage/Phases/${courseID}/lessonAdded` : `/AddContentPage/AddPhases/${courseID}/lessonAdded`);
         }  else if(addPhases && editCourseContent){
       addPhases && history.push(`/CourseCreation/${courseID}/editCourse`) 
     }else if(addPhases){
     history.push(`/CourseCreation/${courseID}/addCourse`) 
     }
      { addCourses && history.push('/ContentManagement') }
      { contentRole && history.push('/ContentManagement') }
      setBackModal(false);
  }

  // Fetch user details
  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getUserData();
    }

  }, [token]);

  useEffect(() => {
    getNotifications();
    { localStorage.getItem("user_role") === "user" ? timeSpentOnTraining() : "" }
  }, [])

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{top:'60px', borderRadius:  '12px'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {
        userRole === "user" &&
      <MenuItem onClick={handleSettingsOpen}>Settings</MenuItem>
      }
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Avatar
          alt={capitalizeFirstLetter(username)}
          src={avatarUrl ? avatarUrl : AvatarImage}
          className={classes.iconMobile}
        />
        <p>{username}</p>
      </MenuItem>
      <MenuItem >
        <img
          src={EditIcon}
          alt="Edit profile"
          className={`${classes.icon} ${classes.iconMobile}`}
          onClick={handelEditModel}
        />
        <p>Edit profile</p>
      </MenuItem>
      <MenuItem onClick={handleMessages}>
        <img
          src={MessageIcon}
          alt="Send Message"
          className={`${classes.icon} ${classes.iconMobile}`}
        />
        <p>Messages</p>
      </MenuItem>
      <MenuItem
        onClick={handleOpenNotification}
      >
        <img
          src={notificationExists ? NotificationIcon : EmptyNotificationIcon}
          alt="Notifications"
          className={`${classes.icon} ${classes.iconMobile}`}
        />
        <p>Notifications</p>
      </MenuItem>
      {
        role === "user" &&
        <MenuItem onClick={() => history.push('/cart')}>
          <Box className={`${classes.shoopingCart} ${classes.iconMobile}`}>
            <ShoppingCartOutlinedIcon
              className={classes.shoopingCartIcon}
            />
            <Typography className={classes.shoopingCartNum}>
              {cartItemsCount && cartItemsCount > 0 ? cartItemsCount : null}
            </Typography>
          </Box>
          <p>Cart</p>
        </MenuItem>
      }
      <MenuItem onClick={handleSettingsOpen}>
        <SettingsIcon
          className={classes.iconMobile}
          style={{ color: "#6C328B" }}
        />
        <p>Settings</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ExitToAppIcon
          className={classes.iconMobile}
          style={{ color: "#6C328B" }}
        />
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const draftUI = (modalOpened: any, id: any, fromProgram: boolean) => {
    return (
      <Box>
        <ModalContainer
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={modalOpened}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalStyle}
        >
          <div>
            <Box className={classes.modalMainContainer}>
              <Typography className={classes.modalMainHeading}>
                Save to Draft to avoid losing changes
              </Typography>
              <Typography id="server-modal-description" className={classes.modalSubHeading}>
                If you leave without saving, all your changes will be lost.
              </Typography>
              <Box className={classes.buttons}>
                <Button className={classes.modalLeftButton} onClick={() => fromProgram ? discardProgramChanges(id) :  discardChanges(id)}>
                  <Typography style={{ textTransform: "none" }}>Leave</Typography>
                </Button>
                <Button className={classes.modalRightButton} onClick={() => handleModalClose()} >
                  <Typography style={{ textTransform: "none" }}>Go Back</Typography>
                </Button>
              </Box>
              <Button className={classes.modalCloseButton} data-testId="modalClose" onClick={() => handleModalClose()}>
                <img src={iconClose} alt="Close" className={classes.dropDownArrow} data-testid="closeModal"></img>
              </Button>
            </Box>
          </div>
        </ModalContainer>
      </Box>
    )
  }


  const draftBackModal = (modalOpened: boolean) => {
    return (
      <Box>
        <ModalContainer
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={modalOpened}
          onClose={handleClose}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
        >
          <div>
            <ModalMainContainer className={classes.gobackWrapper}>
              <ModalMainHeading>
                Save to Draft to avoid losing changes
              </ModalMainHeading>
              <ModalSubHeading id="server-modal-description">
                If you leave without saving, all your changes will be lost.
              </ModalSubHeading>
              <ButtonBox className={classes.gobackBtn}>
                <ModalLeftButton onClick={handleLooseLeave}>
                  <Typography data-test-id="openModalBackModalID" style={{ textTransform: "none" }} >{editCourses || editProgram?"Cancel":"Leave"}</Typography>
                </ModalLeftButton>
                <ModalRightButton onClick={handleBackNew}>
                  <Typography data-test-id="modalBackModalID" style={{ textTransform: "none" }}>{editCourses || editProgram?"Discard":addCourses ? "Keep Creating" : "Go Back"}</Typography>
                </ModalRightButton>
              </ButtonBox>
              <ModalCloseButton data-testId="modalClose">
                <ImageComponent
                  onClick={handleLooseModalClose}
                  src={iconClose}
                  alt="close"
                  data-test-id="closeModal"></ImageComponent>
              </ModalCloseButton>
            </ModalMainContainer>
          </div>
        </ModalContainer>
      </Box>
    )
  }
  const GoBackModal = () => {
    return (
      <>
        <DeleteModal
          data-test-id='delete-all-content'
          openDialog={goBack}
          headingText="Save to avoid losing changes"
          btnCancelText="Leave"
          btnOkText="Keep Editing"
          handleCancel={handleLeaveScreen}
          handleOk={handleSaveScreen}
          library={true}
        />

      </>
    )
  }

  const handleBackAchievement = () => {
    reward || create ? history.push("/RewardsAndAchievement")
    :
    history.push("/CreateAchievement")
  };

  const LeaveModal: React.FC<LeaveModalProps> = ({ isLeave, handleClose }) => {
    return (
      <Modal
        open={isLeave}
        onClose={handleLeaveClose}
      >
        <Box style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height:"100%"}}>
          <div style={{backgroundColor:"white", height:"150px", borderRadius: "12px", padding: "30px 30px" ,position: 'relative', }}>
          <IconButton 
          onClick={handleClose} 
          style={{ position: 'absolute', right: -7,
            top: -10,
            background: "#652786",
            width: "10px",
            height: "10px"
          }}
        >
          <CloseIcon style={{color: "white", fontSize: "14px"}}/>
        </IconButton>
            <Typography variant="h6" style={{fontSize: "22px" , paddingBottom: "20px"}}>
              Save to avoid losing changes
            </Typography>
            <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", flexFlow: "wrap"}}>
              <ButtonModal
                onClick={handleBackAchievement}
                variant="outlined"
              >
                Leave
              </ButtonModal>
              <ButtonDarkModal
                onClick={handleClose}
                variant="contained"
              >
                Keep Editing
              </ButtonDarkModal>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const statusOptionData = (row:any) => {
    if(row?.attributes?.status ==="active"){
      return webStyle.statusNew
    }else if(row?.attributes?.status === "draft"){
      return webStyle.statusDraft
    }else {
      return webStyle.statusArchived
    }
  }

  const renderProgramCourses = (courses: ProgramCourse[]) => {
    return courses.map((data, index) => (
    <AccordionDetails key={index} style={{height: "128px"}}> 
        <Box 
          key={index} 
          style={{
          height: "100%",
          display: "flex",
          gap: "16px",
          }}
        >
           <img style={{width: "80px", height: "80px", borderRadius: "10px"}} data-test-id="thumbnailImg" src={data.thumbnail_image?.url}/>

          <div style={{ minHeight: "43px", display: "flex", flexDirection: "column" }}>
              <TitleTypography>
                {data.title.length > 16 ? data.title.substring(0, 16) + "..." : data.title}
              </TitleTypography>
              <LightTooltip
                  data-test-id="tooltip-editBtn"
                  enterDelay={200}
                  leaveDelay={100}
                  enterTouchDelay={0}
                  leaveTouchDelay={100000}
                  interactive={true}
                  title={
                    <Typography style={{
                      lineHeight: "22px",
                      color: "#484646",
                      fontWeight: 500,
                      fontSize: "14px",
                      fontFamily: "Poppins"
                    }}>
                      {data.description}
                    </Typography>
                  }
                  placement="top"
                  arrow
              >
              <Typography style={{
                lineHeight: "22px",
                color: "#484646",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}>
                {data.description.length > 40 
                ? data.description.substring(0, 40) + "..." 
                : data.description}
              </Typography>
              </LightTooltip>
              <Box style={{display: "flex"}}>
                <Typography style={{
                  lineHeight: "16px",
                  color: "#FF8B3F",
                  fontWeight: 600,
                  fontSize: "16px",
                  fontFamily: "Poppins"
                }}>
                  {data.average_rating}&nbsp;
                </Typography>     
                <Rating
                    style={{height: "12px", alignSelf: "center", fontSize: "12px", width: "12px", gap: "3px"}}
                    name='read-only'
                    value={data.average_rating}
                    precision={0.5}
                    readOnly
                    icon={<img src={starRating}/>}
                    emptyIcon={<img src={emptyStar}/>}
                />
              </Box>
              <Typography style={{
                lineHeight: "18px",
                color: "#1C1B1B",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "Poppins"
              }}>
                {data.expert_name}
              </Typography>
          </div>
        </Box>
      </AccordionDetails>)
    )
  }

  const renderPreviewImage = () => {
    return (
      <ImageBox >
        <img style={{ width: "100%", height: "344px", borderRadius: "20px 20px 0px 0px" }}
          src={programPreviewData?.attributes?.thumbnail_image?.url ?
            programPreviewData.attributes?.thumbnail_image?.url : previewImg} 
            />
      </ImageBox>
    )
  }

  const renderArrowIcon = (index: number) => {
    return (
      <KeyboardArrowRightIcon style={{ rotate: `${arrowClick.includes(String(index)) ? "90deg" : "0deg"}`, alignSelf: "center" }}/>
    )
  }

  const renderHeaderArrowIcon = () => {
    return (
      <KeyboardArrowRightIcon 
        style={toggleDropDown ? { rotate: "90deg", cursor: "pointer" } : { rotate: "0deg", cursor: "pointer" }} 
      />
    )
  }

  const renderIfPhasesAreReleased = () => {
    if (programPreviewData?.attributes.content_release_schedule) {
      return (
        <Box style={{
          display: "flex"
        }}>
          <Typography style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            fontFamily: "Poppins",
            alignSelf: "center"
          }}>
            Phases are released</Typography>
          <Button style={{
            padding: "6px 9px 6px 9px", borderRadius: "88px", backgroundColor: "#E0D4E7",
            textTransform: "capitalize", gap: "10px", color: "#542070", textAlign: "center",
            cursor: "pointer", marginLeft: "auto", fontSize: "14px", minWidth: "86px", minHeight: "40px",
            fontWeight: 400
          }}>
            {programPreviewData?.attributes.content_release_schedule_value}
          </Button>

        </Box>
      
      )
    }
  }

  const renderStatus = () => {
    if (programPreviewData?.attributes?.status) {
      return (
        <Box>
          <Statuses 
          style={statusOptionData(programPreviewData)}
          >
            {programPreviewData?.attributes?.status}
          </Statuses>
        </Box>
      )
    }
  }

  const renderProgramPreview = () => {
    const dueDateAtString = programPreviewData?.attributes?.due_date;
    const dueDateAtMoment = moment(dueDateAtString);
    const formattedDueDate = dueDateAtMoment.format('MM-DD-YYYY');

    return (
      <DialogBox
          data-test-id="dialogPreview"
          open={isProgramPreview}
          onClose={handlePreviewClose}
        >

          <SecondDialogBox>
            <Box style={{
                position: 'absolute' as 'absolute',
                right: "1px",
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                zIndex: 10
              }}
              data-test-id="testcase1"
              onClick={() => { handlePreviewClose(); setArrowClick([]) }}
              >
              <img src={crossIcon} />
            </Box>
            <ModalBox>
              <Box style={{ padding: "10px" }}>
                {<Title
                  showBackButton title1="Program preview" 
                  onBackClick={handlePreviewClose} 
                  />}
              </Box>


              <Grid container style={{ display: "flex", flexWrap: "wrap", }} spacing={2} >
                <Grid item xs={12} md={7} lg={8}>
                  <Box style={{
                    display: "flex", flexDirection: "column", gap: "24px", maxWidth: "771px",
                    height: "fit-content"
                  }}>
                    {renderPreviewImage()}

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "12px" }}>
                        <Typography style={{
                          fontWeight: 700,
                          fontSize: "24px",
                          fontFamily: "Poppins",
                          lineHeight: "36px",
                          color: "#1C1B1B"
                        }}>
                          {programPreviewData?.attributes?.title} 
                          </Typography>

                        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>

                          <Typography style={{
                            borderRight: "1px solid rgba(173, 170, 170, 1)",
                            color: "#787776",
                            paddingRight: "12px",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",

                          }}>
                            {programPreviewData?.attributes.expert_name}
                          </Typography>

                          <Typography style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",
                            color: "#ADAAAA"
                          }}>
                            {(programPreviewData?.attributes && programPreviewData.attributes.categories.length > 1) ? `${programPreviewData?.attributes.categories[0].attributes.name} & ${programPreviewData.attributes && programPreviewData?.attributes.categories.length - 1} more` : programPreviewData?.attributes.categories[0].attributes.name}
                          </Typography>
                          <Box style={{display: "flex"}}>
                            <Typography style={{
                              lineHeight: "16px",
                              color: "#FF8B3F",
                              fontWeight: 600,
                              fontSize: "16px",
                              fontFamily: "Poppins",
                              marginTop: "auto"
                            }}>
                              {programPreviewData?.attributes.average_rating || 0}&nbsp; 
                            </Typography>     
                            <Rating
                                style={{height: "12px", alignSelf: "center", fontSize: "12px", width: "12px", gap: "3px"}}
                                name='read-only'
                                value={programPreviewData?.attributes.average_rating} 
                                precision={0.5}
                                readOnly
                                icon={<img src={starRating}/>}
                                emptyIcon={<img src={emptyStar}/>}
                            />
                          </Box>
                        </Box>

                      </Box>

                      {renderStatus()}
                    </Box>

                    <Box >
                      <Box style={{
                        display: "flex", justifyContent: "space-between", gap: "12px", alignItems: "center",
                        flexWrap: "wrap"
                      }}>
                        <Typography style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          lineHeight: "16px",
                          color: "#652786"
                        }}>
                          Last Modified:  &nbsp; <span style={{
                            color: "#484646",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",
                          }}>
                            {moment.utc(programPreviewData?.attributes.updated_at).format('MM/DD/YYYY')}
                            </span> 
                            </Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "12px", flexWrap: "wrap" }}>

                          <Button data-test-id="editCourseBtn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "16px"
                          }} 
                          onClick={() => handleEditProgramDetails(Number(programPreviewData?.id))}
                          >
                            <img src={editIconPreview} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                fontFamily: "Poppins",
                                lineHeight: "16px",
                                color: "#E47004"
                              }}
                            >Edit Program</Typography>
                          </Button>
                          <Button data-test-id="editContentBtn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "8px"
                          }}
                          onClick={() => handleEditProgramContent(programPreviewData?.attributes.content_release_schedule,Number(programPreviewData?.id))}
                          >
                            <img src={docIcon} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontSize: "16px",
                                lineHeight: "16px",
                                fontWeight: 600,
                                fontFamily: "Poppins",
                                color: "#E47004"
                              }}>
                              Edit Content
                            </Typography>
                          </Button>
                        </Box>

                      </Box>
                    </Box>


                    <DescriptionBox >
                      <Typography style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        lineHeight: "22px",
                        color: "#787776"
                      }}>
                        {programPreviewData?.attributes?.description}
                      </Typography>
                    </DescriptionBox>

                  </Box>
                </Grid>

                <Grid item xs={12} md={5} lg={4}>

                  <GridBox>

                    <ContentBox>
                      <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "24px",
                            fontFamily: "Poppins",
                            lineHeight: "36px",
                            color: "#1C1B1B" 
                          }}>Program includes</Typography>
                        <div style={{alignSelf: "center"}} data-test-id="toggleDropDown" 
                        onClick={() => setToggleDown((prev) => !prev)}
                        >
                          {renderHeaderArrowIcon()}
                        </div>
                      </div>

                      <Box style={{
                        display: "flex",
                      }}>

                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          padding:"8px 0"
                        }}>

                          <div>
                            <img src={courseIcon} />
                          </div>

                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1A1A1A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > 
                            {programPreviewData?.attributes.courses_count} 
                            </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              color: "#484646"
                            }}> Courses </Typography>
                          </div>

                        </Box >

                        <Divider style={{margin: "0 24px"}} orientation="vertical" flexItem />

                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          padding:"8px 0"
                        }}>

                          <div>
                            <img src={lessonpic} />
                          </div>

                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1A1A1A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > 
                            {programPreviewData?.attributes.lessons_count} 
                            </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              color: "#484646"
                            }}> Lessons </Typography>
                          </div>

                        </Box >
                      </Box>

                      {renderIfPhasesAreReleased()}

                    </ContentBox>


                    {toggleDropDown && programPreviewData?.attributes?.content_release_schedule &&
                    <Box style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                      {programPreviewData?.attributes?.contents?.map((item, index) => {
                        return (
                          <>
                            <WithOutPhase key={index}>

                              <Box key={index} style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "304px"
                              }}>

                                <Accordion key={index}>
                                  <AccordionSummary
                                    onClick={() => rotateArrowIcon(String(index))}
                                    style={{ display: "flex", justifyContent: "space=between", alignItems: "center" }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Box>
                                      <Typography
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          lineHeight: "22px",
                                          color: "#1C1B1B"
                                        }}>Phase &nbsp;{item.attributes.phase_number} &nbsp;
                                        {item.attributes.name && <span>{"- " + item.attributes.name}</span>}
                                      </Typography>
                                      {programPreviewData?.attributes?.add_due_date && 
                                      <Typography
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "12px",
                                          fontFamily: "Poppins",
                                          lineHeight: "22px",
                                          color: "#E47004"
                                        }}>Due: &nbsp;{<span style={{color: "#787776", fontWeight: 
                                          400}}>{formattedDueDate}</span>}
                                      </Typography>}
                                    </Box>
                                    {renderArrowIcon(index)}
                                  </AccordionSummary>
                                  {renderProgramCourses(item.attributes.courses)}
                                </Accordion>
                              </Box>
                            </WithOutPhase>

                          </>)

                      })}
                      </Box>}
                    {toggleDropDown && !programPreviewData?.attributes?.content_release_schedule &&
                      programPreviewData?.attributes?.contents?.map((item, index) => {
                        return (
                          <>

                            <WithOutPhase key={index}>
                              {renderProgramCourses(item.attributes.courses)}
                            </WithOutPhase>

                          </>)

                      })}

                  </GridBox>
                </Grid>
              </Grid>
            </ModalBox>
          </SecondDialogBox>
        </DialogBox>
    )
  }

  const handlePreviewClose =()=>{
    setISPreview(false)
    setIsProgramPreview(false)
  }

  const handleEditCourseData = (id: number | undefined) =>{
    navigation.navigate("CourseCreation", {type: "editCourse",id: id});
    handlePreviewClose()
  }

  const handleEditCourseContent = (isPhases:boolean | undefined,id: number | undefined) =>{
    handlePreviewClose()
    navigation.navigate(isPhases ? `AddContentPage/Phases/${id}/lessonAdded` : `AddContentPage/AddPhases/${id}/lessonAdded`);
  }

  const handleEditProgramDetails = (id: number | undefined) =>{
    navigation.navigate("ProgramCreation", {type: "editProgram",id: id});
    handlePreviewClose()
  }

  const handleEditProgramContent = (isPhases:boolean | undefined,id: number | undefined) =>{
    const type = isPhases ? "Phases" : "AddPhases"
    navigation.navigate("ProgramContent", {type: type, id: id });
    handlePreviewClose()
  }

  const handleLeaveScreen = async () => {
     if(BulkUploadID){
      history.push("/ManageContentLibrary");
    }else if(lessonBack){
      handleLooseLeave()
    }else if(redirectToBranchDetailFromManageTeam){
      history.goBack();
    }else if(CreateTeam){
      history.push("/TeamBuilder");
    }else if(CreateBranch){
      history.push("/ShowBranch");
    }else{
      let formData;
      if (locationSet.state) {
        formData = locationSet.state?.formData
      }
      const originScreen = await getStorageData("originScreen");
  if (originScreen) {
    history.push({
      pathname: originScreen,
      state: {
        thumbnail: null,
        formData: formData
      }
    });
    removeStorageData("originScreen");
  }else{
      history.goBack();
  }
    }
  }
  const handleSaveScreen = () => {
    setGoBack(false)
  }

  const pathSegments = window.location.pathname.split('/');
  const hasPhases = pathSegments.includes('Phases');
  const id = hasPhases ? pathSegments[pathSegments.indexOf('Phases') + 1] : null;
  const addPhases = pathSegments.includes('AddPhases');
  const addCourses = pathSegments.includes('addCourse');
  const addLesson = pathSegments.includes('addLesson');
  const editLesson = pathSegments.includes('editLesson');
  const editCourses = pathSegments.includes('editCourse');
  const ProgramContent = pathSegments.includes('ProgramContent')
  const editContent = pathSegments.includes('editContent');
  const editId=localStorage.getItem("editTeam")
  const create = pathSegments.includes('CreateAchievement');
  const select = pathSegments.includes('SelectContent');  
  const editProgram = pathSegments.includes('editProgram');
  const reward = pathSegments.includes('CreateReward'); 
  const lessonBack = pathSegments.includes('Customform');
  const Phases = pathSegments.includes('Phases');
  const contentRole = pathSegments.includes('ContentRoleCreationWeb');
  const editcontentRole = pathSegments.includes('ContentRoleCreationWeb') && pathSegments.includes('edit');
  const editThumbnaiScreen = pathSegments.includes('addthumbnail') && pathSegments.includes('edit');
  const ThumbnailID = pathSegments.includes('Addthumbnail');
  const BulkUploadID = pathSegments.includes('BulkUploading') || pathSegments.includes('Edit-BulkUploading');
  const IconID = pathSegments.includes('AddIcon')
  const CreateTeam=pathSegments.includes("CreateTeam")
  const redirectToBranchDetailFromManageTeam=pathSegments.includes("CreateTeam") && pathSegments.includes("redirectToBranch")
  const CreateBranch=pathSegments.includes("CreateBranch")
  const editIconScreen = pathSegments.includes('addIcon') && pathSegments.includes('edit');
  const manageBranch = pathSegments.includes('BranchDetails');

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon color="primary" />
          </IconButton>
          {window.location.pathname === "/Iconmanagement" && <DynamicTitle title="Icon Library" />}
          {window.location.pathname === "/Thumbnailmanagement" && <DynamicTitle title="Thumbnail Library" />}
          {window.location.pathname === "/ContentManagement" && <DynamicTitle title="Manage Products" />}
          {window.location.pathname === "/ManageContentLibrary" && <DynamicTitle title="Content Library" />}
          {window.location.pathname === "/usermanagement" && <DynamicTitle title="User Management" />}
          {window.location.pathname === "/RewardsAndAchievement" && <DynamicTitle title="Rewards & Achievements" />}
          {window.location.pathname.startsWith("/usermanagement/create-user") && (
        <div style={{ display: "flex", alignItems: "center", color: "#1C1B1B" }}>
          <IconButton onClick={handleUserManagementBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Create User" />
        </div>
      )}
         {window.location.pathname.startsWith("/user-manage") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleUserManagementBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Manage User" />
        </div>
      )}
      {window.location.pathname.startsWith("/BranchDetails") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleBranchManagementBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title={manageBranch ? "Create Branch" : "Manage Branch"} />
        </div>
      )}
      {window.location.pathname.startsWith("/BranchAssignContent") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleBranchAssignContentBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Assign Content" />
        </div>
      )}
      {window.location.pathname.startsWith("/EditBranchAssignContent") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleBranchAssignContentEditBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Assign Content" />
        </div>
      )}
          {window.location.pathname.startsWith("/usermanagement/edit-user") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleUserManagementBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Edit User" />
        </div>
      )}
         {window.location.pathname.startsWith("/UserAssignContent") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleUserManageBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Assign" />
        </div>
      )}
      {window.location.pathname.startsWith("/ManageAssign") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DynamicTitle title="Assign Content" />
        </div>
      )}
          {window.location.pathname.startsWith("/ProgramContent") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={editProgramContent ? handleArrowBack : handleProgramContentBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title={editProgramContent ? "Edit program content" : "Add program content"} />
          {editProgramContent && draftUI(modalOpen, pathSegments[2], true)}
        </div>
      )}

          {(addPhases && !lessonBack) && !ProgramContent && <><ArrowBackIosIcon
            onClick={handleBackAddPhases}
            style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title={editCourseContent  ? "Edit course content":"Add course content"} />
            {draftBackModal(backModal)}
          </>}

          {(hasPhases && !lessonBack&& !ProgramContent) &&<> <ArrowBackIosIcon  data-testid="dropDownOpen" onClick={handleArrowBack}
           style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
            <DynamicTitle title={editCourseContent ? "Edit course content":"Add course content"} />
            {draftUI(modalOpen, id, false)}
          </>}
          {window.location.pathname === "/CreateTeam/Create" && <DynamicTitle title="Create New Team" showBackButton  onBackClick={() => { setGoBack(true) }}/>}
          {checkURLPathStartWith(`/CreateTeam/Edit`) && <DynamicTitle title="Manage Team" showBackButton  onBackClick={() => { setGoBack(true) }}/>}
          {window.location.pathname === `/ShowBranch` && <DynamicTitle title="Manage Branches"/>}
          {getCurrentPathName() === "/AdminReport" && <DynamicTitle title="Usage Reports"/>}
          {window.location.pathname.startsWith("/CreateBranch/Create") && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleCreateBranchBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <DynamicTitle title="Create Branch" />
        </div>
      )}
          {addCourses &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowBackIosIcon
                onClick={handleBackAddPhases}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Course Creation" />
              {draftBackModal(backModal)}
            </div>
          }
          {create &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowBackIosIcon
                onClick={handleBackCreate}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Create Achievement" />
              <LeaveModal isLeave={isLeave} handleClose={handleLeaveClose} />
            </div>
          }
          {select &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <DynamicTitle title3="Select content"/>
              <LeaveModal isLeave={isLeave} handleClose={handleLeaveClose} />
            </div>
          }
          {reward &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowBackIosIcon
                onClick={handleBackCreate}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Create Reward"/>
              <LeaveModal isLeave={isLeave} handleClose={handleLeaveClose} />
            </div>
          }
          {contentRole &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <ArrowBackIosIcon
            onClick={handleBackAddPhases}
            style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
            <DynamicTitle onBackClick={handleProgramBack} title={editcontentRole ? "Edit Content Role" :"Create Content Role"} />
            {draftBackModal(backModal)}
          </div>
}         


          {editContent &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ArrowBackIosIcon
                onClick={handleBackAddPhases}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Edit course content" />
              {draftBackModal(backModal)}
            </div>
          }

          {editCourses &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowBackIosIcon
                onClick={handleBackAddPhases}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Edit course" />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", paddingLeft:"42px"}}>
              <div style={{
                width: "2px",
                height: "40px",
                backgroundColor: "#d3d3d3",
                marginRight: "10px",
              }}></div>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                <Button style={{color:"#663399",textTransform:"capitalize",fontSize:"16px",fontWeight:600}}
               onClick={()=>handlePreview(previewId)}>Preview</Button> <img src={previewIcon} alt="" onClick={()=>handlePreview(previewId)}/>
              </div>
               </div>
              {draftBackModal(backModal)}
            </div>
          }

          {editProgram &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ArrowBackIosIcon
                onClick={handleBackAddPhases}
                style={{ marginLeft: "8px", cursor: "pointer", color: "#92929D" }} />
              <DynamicTitle title="Edit program" />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", paddingLeft:"42px"}}>
                  <div style={{
                  width: "2px",
                  height: "40px",
                  backgroundColor: "#d3d3d3",
                  marginRight: "10px",
                }}></div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                  <Button style={{color:"#663399",textTransform:"capitalize",fontSize:"16px",fontWeight:600}}
                onClick={()=>handleProgramPreview(previewId)}>Preview</Button> <img src={previewIcon} alt="" onClick={()=>handleProgramPreview(previewId)}/>
                </div>
               </div>
              {draftBackModal(backModal)}
            </div>
          }


              <CoursePreviewWeb  
                isPreview={isPreview} 
                dialogContent={coursePreviewData}
                 handlePreviewClose={handlePreviewClose}
                gotoEditCourse={handleEditCourseData}
                gotoEditContent={handleEditCourseContent}
                onProgramPreview={handleProgramPreview}
                />

              {isProgramPreview && renderProgramPreview()}


          {history.location.pathname === "/TeamBuilder" && <DynamicTitle title="Manage Teams"/>}
  
          {history.location.pathname.endsWith('addProgram') && <DynamicTitle title="Program Creation" showBackButton onBackClick={handleProgramBack} />}
          {history.location.pathname === "/BulkUploading" && <DynamicTitle title="Create Content" showBackButton onBackClick={() => { setGoBack(true) }} />}
          {history.location.pathname.toLowerCase() === "/Edit-BulkUploading".toLowerCase() && <DynamicTitle title="Edit Content" showBackButton onBackClick={() => { setGoBack(true) }} />}
          {lessonBack &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <DynamicTitle title={addLesson?"Create Lesson" :"Edit Lesson"}showBackButton onBackClick={() => { setGoBack(true) }}  />
              {GoBackModal()}
            </div>}
          {editThumbnaiScreen && <DynamicTitle showBackButton onBackClick={() => { setGoBack(true) }} title="Edit Thumbnail" />}
          {window.location.pathname === "/Addthumbnail" && <DynamicTitle showBackButton onBackClick={() => { setGoBack(true) }} title="Add Thumbnail" />}
          {window.location.pathname === "/AddIcon" && <DynamicTitle showBackButton onBackClick={() => { setGoBack(true) }} title="Add Icon" />}
          {window.location.pathname === "/CourseCreation/edit" && <DynamicTitle title="Edit Course" />}
          {editIconScreen && <DynamicTitle title="Edit Icon" showBackButton onBackClick={() => { setGoBack(true) }} />}

          {GoBackModal()}
          <Box style={{ flexGrow: 1 }} />
          <Box className={classes.sectionDesktop}>
            {history.location.pathname === "/CustomisableUserProfiles" ?
              <>
                {
                  role == "user" ?
                    <IconButton
                      size="medium"
                      aria-label="Edit profile"
                      color="primary"
                      onClick={handelEditModel}
                    >
                      <img
                        src={EditIcon}
                        alt="Edit profile"
                        className={classes.icon}

                      />
                    </IconButton> :
                    <></>
                }</> : <></>}
            <IconButton
              size="medium"
              aria-label="Messages"
              color="primary"
              onClick={handleMessages}
            >
              <img
                src={MessageIcon}
                alt="Send Message"
                className={classes.icon}
              />
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Notifications"
              color="primary"
              onClick={handleOpenNotification}
            >
              <img
                src={notificationExists ? NotificationIcon : EmptyNotificationIcon}
                alt="Notifications"
                className={classes.icon}
              />
            </IconButton>
            {
              role == "user" ?
                <IconButton
                  size="medium"
                  aria-label="ShoppingCart"
                  color="primary"
                  onClick={() => history.push('/cart')}
                >
                  <Box className={classes.shoopingCart}>
                    <ShoppingCartOutlinedIcon
                      className={classes.shoopingCartIcon}
                    />
                    <Typography className={classes.shoopingCartNum}>
                      {cartItemsCount && cartItemsCount > 0 ? cartItemsCount : null}
                    </Typography>
                  </Box>
                </IconButton> :
                <></>
            }

            <Avatar
              alt={capitalizeFirstLetter(username)}
              src={avatarUrl ? avatarUrl : AvatarImage}
              className={classes.avatar}
            />
            <Typography
              className={classes.username}
              component={Link}
              to={userRole === "user" ? "/CustomisableUserProfiles" : "#"}
            >
              {username}
            </Typography>
            <IconButton
              size="medium"
              aria-label="Notifications"
              color="primary"
              onClick={handleMenuOpen}
            >
              <ArrowDropDownIcon className={classes.downArrow} />
            </IconButton>
          </Box>
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon style={{ color: "#6C328B" }} />
            </IconButton>
          </Box>
        </Toolbar>

        <GoBackConfirmationModal
          modalOpened={goBackConfirmationOpen}
          heading={goBackHeadingMessage}
          goBackButtonText={goBackButtonText}
          goBackHandleLeaveButton={goBackHandleLeaveButton}
          bodyMessage={goBackBodyMessage}
          handleCancel={goBackHandleCancel}
          handleLeave={goBackHandleLeave}
          handleClose={goBackHandleClose}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isOpen}
          onClose={handelEditModel}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isOpen}>
            <ProfileModal
              handelEditEmailModel={handelEditEmailModel}
              handelClose={handelEditModelClose}
              handleOpen={handelEditModelOpen} handelEditEmailModelClose={undefined} />
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isModal}
          // onClose={handelEditModel2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isModal}>
            <MyIntrest
              handelClose={handelEditModelClose}
              handleOpen={handelEditModel2} handelEditEmailModel={undefined} handelEditEmailModelClose={undefined} />
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isOpenEditEmail}
          // onClose={handelEditModel2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isModal}>
            <EditEmail
              handelClose={handelEditModelClose}
              handleOpen={handelEditModel2} handelEditEmailModel={undefined} handelEditEmailModelClose={handelEditEmailModelClose} />
          </Fade>
        </Modal>
        <Congratulations
          handelEditDailogClose={handelEditDailogClose}
          isModalOpen={isModalOpen}
          history={history}
        />
        <Notifications2
          history={history}
          onClose={handleCloseNotification}
          navigation={undefined}
          id={""}
          open={notiOpen}
          fullScreen={fullScreen}
        />
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {/* @ts-ignore */}
      <Settings5
        open={open}
        handleSettingsClose={handleSettingsClose}
        handleLogout={handleLogout}
      />
    </>
  );
}


const ModalContainer = styled(Modal)({
  backgroundColor: "rgba(0, 0, 0, 0.55)",
  display: 'flex',
  padding: 1,
  alignItems: 'center',
  justifyContent: 'center'
});

const 
ModalMainContainer = styled(Box)({
  position: 'relative',
  maxWidth: "525px",
  width:"100%",
  minHeight: "177px",
  bgcolor: 'background.paper',
  borderRadius: "12px",
  border: '2px solid #000',
  padding: "24px 32px 24px 32px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  backgroundColor: "#FFF",

});

const ModalMainHeading = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "22px",
  lineHeight: "33px",
  color: "#000"
  
});

const ModalSubHeading = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  color: "#000",
  paddingBottom:"15px"
});

const ButtonBox = styled(Box)({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap"
});

const ModalLeftButton = styled(Button)({
  width: "214.5px",
  height: "42px",
  borderRadius: "12px",
  padding: "16px 24px 16px 24px",
  border: "1px solid rgba(228, 112, 4, 1)",
  color: "#E47004"
});


const ModalRightButton = styled(Button)({
  width: "214.5px",
  height: "42px",
  borderRadius: "12px",
  padding: "16px 24px 16px 24px",
  border: "1px solid rgba(101, 39, 134, 1)",
  backgroundColor: "rgba(101, 39, 134, 1)",
  color: "#FFF",
  '&:hover': {
    backgroundColor: "rgba(101, 39, 134, 1)",
    borderColor: "1px solid rgba(101, 39, 134, 1)",
  },
});

const ModalCloseButton = styled(Button)({
  position: "absolute" as "absolute",
  top: "-15px",
  right:"-30px",
  cursor: "pointer" as "pointer"
});

const ImageComponent = styled("img")({
  width: "24.66px",
  height: "24px",
  cursor: "pointer"
});

const ButtonModal = styled(Button) ({
  width: "214px",
  height: "42px",
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: "12px",
  border : "1px solid #E47004",
  color: "#E47004",
  textTransform: "capitalize",
  "@media(max-width: 520px)": {
    width: "120px",
  }
});

const ButtonDarkModal = styled(Button) ({
  width: "214px",
  height: "42px",
  fontSize: "16px",
  fontWeight: 600,
  borderRadius: "12px",
  color: "white",
  background: "#652786",
  textTransform: "capitalize",
  marginLeft: "20px",
  "&.MuiButton-contained:hover": {
   backgroundColor: "#652786 !important",
   color: "white !important"
  },
  "@media(max-width: 520px)": {
    width: "135px",
  }
});

const TitleTypography = styled(Typography)({
  fontFamily: "Poppins",
  lineHeight: "22px",
  fontSize: "16px",
  color: "#1C1B1B",
  fontWeight: 700,
  wordWrap: "break-word"
})

const ImageBox = styled(Box)({
  maxWidth: "771px",
  radius: "88px",
  height: "344px"
});

const DialogBox = styled(Modal)({
  display: 'flex',
  justifyContent: "center",

  "& .MuiDialog-paperWidthSm": {
    maxWidth: "700px !important",

    "@media (max-width:960px)": {
      minWidth: "100px !important",
      height: "152px !important",
      padding: "16px",
    },
    "@media (min-width:720px)": {

    },
  },

})

const SecondDialogBox = styled(Box)({
  position: "relative",
  "@media (min-width:720px)": {
    maxWidth: "1211px",
    width:"85%",
    marginLeft: "clamp(15px, 135px, 10%)",
},
})

const ModalBox = styled(Box)({
  margin: "1rem",
  backgroundColor: "white",
  padding: "0px 32px 32px 32px",
  overflowY: "scroll",
  borderRadius: "16px",
  height: "97vh",

})

const Statuses = styled("div")({
  textAlign: "center",
  padding: "6px 9px",
  fontSize: "14px",
  borderRadius: "88px",
  gap: "10px",
  textTransform: "capitalize",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  width: "125px",
  height: "40px",
});

const DescriptionBox = styled(Box)({
  height: "fit-content",

  "@media (max-width:960px)": {
    height: "110px", overflowY: "scroll"
  },
  "@media (min-width:720px)": {

  },
});

const GridBox = styled(Box)({
  display: "flex",
  maxWidth: "352px", height: "fit-content", borderRadius: "16px", flexDirection: "column",
   boxShadow: "1px 2px 10px 2px rgba(0, 0, 0, 0.07)",
  gap: "24px",
  padding:"32px 24px"
})

const ContentBox = styled(Box)({
  display: "flex", flexDirection: "column", maxWidth: "304px", gap: "24px" ,
  "@media (max-width:960px)": {
   maxWidth: "360px",
 },
  "@media (min-width:720px)": {
   maxWidth: "1211px",
},
})

const WithOutPhase = styled(Box)({
  maxWidth: "304px",
  gap: "24px",
})

const useStyles:any = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      background: "#fff",
      boxShadow: "none",
      marginLeft: (props: any) => props.drawerWidth,
      [theme.breakpoints.up("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: (props: any) => `calc(100% - ${props.drawerWidth}px)`,
      },
    },
    dropDownArrow: {
      width: "24.66px",
      height: "24px",
      cursor: "pointer"
    },
    sectionDesktop: {
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid rgba(225,225,225,0.58)",
      margin: "10px 0",
      padding: "5px 10px",
      borderRadius: "16px",
      boxShadow: "0px 0px 16px rgba(213,213,213,0.35)",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      padding: "5px 10px",
      margin: "10px 0",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    modalCloseButton: {
      position: "absolute" as "absolute",
      top: "-15px",
     right:"-30px",
      cursor: "pointer" as "pointer"
    },
    modalMainHeading: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#000"
    },
    modalSubHeading: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      display: "flex",
      gap: "5px",
      alignItems: "center",
      color: "#000",
      paddingBottom:"15px"
    },
    modalLeftButton: {
      width: "214.5px",
      height: "42px",
      borderRadius: "12px",
      padding: "16px 24px 16px 24px",
      border: "1px solid rgba(228, 112, 4, 1)",
      color: "#E47004"
    },
    buttons: {
      display: "flex",
      gap: "10px",
      flexWrap: "wrap",
      "& .MuiTypography-body1":{
        fontSize:"16px",
        fontWeight:600,
      },
      [theme.breakpoints.down('sm')]: {
        "& .MuiButton-root":{
         width:"100%"
        },
        [theme.breakpoints.down('xs')]: {
          "& .MuiButton-root":{
           width:"100%"
          }
        }
    }
  },
    modalRightButton: {
      width: "214.5px",
      height: "42px",
      borderRadius: "12px",
      padding: "16px 24px 16px 24px",
      border: "1px solid rgba(101, 39, 134, 1)",
      backgroundColor: "rgba(101, 39, 134, 1)",
      color: "#FFF",
      '&:hover': {
        backgroundColor: "rgba(101, 39, 134, 1)",
        borderColor: "1px solid rgba(101, 39, 134, 1)",
      },
    },
    modalMainContainer: {
      position: 'relative',
      maxWidth: "525px",
      width:"100%",
      minHeight: "177px",
      bgcolor: 'background.paper',
      borderRadius: "12px",
      border: '2px solid #000',
      padding: "24px 32px 24px 32px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      backgroundColor: "#FFF",
      [theme.breakpoints.down('sm')]: {
        padding:"20px"
      },
      [theme.breakpoints.down('xs')]: {
        padding:"20px",
        maxWidth:"385px"
      }
    },
    modalStyle: {
      display: 'flex',
      padding: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    username: {
      textTransform: "capitalize",
      color: "#000",
      marginLeft: "12px",
      textDecoration: "none",
    },
    downArrow: { color: "#92929D" },
    icon: {
      height: "20px",
      width: "20px",
    },
    shoopingCart: {
      position: "relative",
      width: "20px",
    },
    shoopingCartNum: {
      width: '20px',
      position: 'absolute',
      color: '#fff',
      background: '#ff8b3f',
      borderRadius: '50px',
      fontSize: '13px',
      top: '-10px',
      right: '-13px',
      textAlign: "center",
    },
    shoopingCartIcon: {
      height: "25px",
      width: "25px",
      color: "#6c328b",
    },
    iconMobile: {
      marginRight: "10px",
    },
    menuButton: {
      marginLeft: "5px",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    avatar: {
      marginLeft: 10,
    },
    gobackWrapper:{
      [theme.breakpoints.down('sm')]: {
        padding:"20px"
      },
      [theme.breakpoints.down('xs')]: {
        padding:"20px",
        maxWidth:"385px"
      }
    },
    gobackBtn:{
      "& .MuiTypography-body1":{
        fontSize:"16px",
        fontWeight:600,
      },
      [theme.breakpoints.down('sm')]: {
        "& .MuiButton-root":{
         width:"100%"
        },
        [theme.breakpoints.down('xs')]: {
          "& .MuiButton-root":{
           width:"100%"
          }
      },
    }
  }
    
  })
);

const webStyle = {
  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    backgroundColor: "#E0D4E7",
    color: "#542070",
    textTransform:"capitalize" as 'capitalize'
  },

  statusDraft: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFEDE4",
    color: "#E47004",
    textTransform:"capitalize" as 'capitalize'
  },
  statusArchived: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFD9DF",
    color: "#E70E6B",
    textTransform:"capitalize" as 'capitalize'
  },
}

// @ts-ignore
export default withRouter(Topbar);

