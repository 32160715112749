Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.courseAPiEndPoint = "bx_block_coursecreation/collection_lists";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully';
exports.btnExampleTitle = "CLICK ME";
exports.listCourseDataEndPoint ='bx_block_account_groups/user_contents/library_courses';
exports.listProgramDataEndPoint = 'bx_block_account_groups/user_contents/library_programs';
exports.listContentRoleDataEndPoint="bx_block_account_groups/user_contents/library_content_roles?";
exports.userListEndPoint = "account_block/standard_users";
exports.branchListEndPoint = "account_block/standard_users/branch_list";
exports.createUserAssignContentEndPoint= 'bx_block_account_groups/user_assign_contents';
exports.catagoryAdvanceSearch ="bx_block_advanced_search/search/filter";
exports.courseAndProgramAPIEndPoint = "/bx_block_catalogue/collection_lists";
// Customizable Area End
