// Customizable Area Start
import React, { ReactChild } from "react";

import {
  Box,
  Popover,
  Badge,
  Typography,
} from "@material-ui/core";

import { createTheme,styled } from "@material-ui/core/styles";
import {AddIcon,folder,edit, thumbnailImg} from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web"
import { Filter } from "../../../../packages/blocks/catalogue/src/assets";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';
import FilterItems from "../../filteritems/src/FilterItems.web";

interface SubBranch {
  attributes: {
  is_primary_branch: boolean;
  teams_counts: number;
  manager: string;
  active_users: number;
  total_users: number;
  id: number;
  name: string;
  branch_id: string;
  branch_status: string;
  payment_status: string;
  sub_branches: SubBranch[];
}
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ShowBranchController, {
  Props,
} from "./ShowBranchController.web";

export default class ShowBranch extends ShowBranchController {
  constructor(props: Props) {
    super(props);
  }

  advancedSearchFun=()=>{
    const {anchorFilterContainerEl}=this.state
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)
    return(<StyledContentWrappers>
      <AdvancedSearch
    data-test-id="btn_change_one"
    searchInputValue={this.state.AdvanceSearchValue}
    handleListItemClick={this.handleThumbnailListClick}
    handleInputChange={this.handleTeamInputChangeSearch}
    inputWidth={window.innerWidth<800?"300":"480"}
    filterList={this.state.filteredContentData}
    isFilterDropActive={openFilterContainer}
    showList={this.state.showContentLists} navigation={undefined} id={""} />
                <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={Filter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <FilterItems
                isThumbnail
                navigation={this.props.navigation}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleClick={this.handleClicksFilters}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandles}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChanges}
                dropdownOpenHandler={this.dropdownHandlerOpens}
                creationDateChangeHandler={this.creationChangeHandlerDates}
                dropdownCloseHandler={this.dropdownHandlerClose}
                anchorEl={this.state.anchorsEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedTeamsItems={this.state.placeholderSelectsTeamsItems}
                creationDate={this.state.creationIconsDate}
                expertAdminList={this.state.expertAdminsContent}
                placeholderSearchText={this.state.placeholderSearchsTexts}
                isCalendarOpen={this.state.isCalendarshow}
                handleClear={this.handleClearFilters}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchBranchText={this.state.placeholderSearchBranchText} 
                handleCalendarOpen={function (): void {}} 
                handleCalendarChange={function (): void {}} />
            </Popover>
    </StyledContentWrappers>)
    
  }
  
  renderTable = () => {
    const { branchData } = this.state;
    return(
      <Wrapper>
        <div className="dashboard-container">
          {branchData && branchData.length > 0 ? (
            <table className="dashboard-table">
              <thead>
                <tr>
                  <th>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{fontSize: "20px", fontWeight: 500, color: "#1C1B1B"}}>Branches</div>
                      <div>
                      <span onClick={this.toggleExpandAll} style={{ cursor: 'pointer', color: '#787776' ,fontSize: "16px",fontWeight: 500, display: "flex" ,alignItems: "flex-start"}} data-test-id="togglebuttonid">
                        <span data-test-id="textid">{this.state.isExpanded ? 'Collapse all' : 'Expand all'}</span> <span>{this.state.isExpanded ? <MdKeyboardArrowDown style={{fontSize: "24px", color: "#787776"}}/> : <MdKeyboardArrowRight style={{fontSize: "24px", color: "#787776"}}/>}</span>
                        </span>
                      </div>
                    </div>
                  </th>
                  <th className="divider-header"></th>
                <th style={{fontSize: "20px", fontWeight: 500,color: "#1C1B1B"}}>Teams</th>
                <th style={{fontSize: "20px", fontWeight: 500,color: "#1C1B1B"}}>Manager</th>
                <th style={{fontSize: "20px", fontWeight: 500,color: "#1C1B1B"}}>Active Users</th>
                <th style={{fontSize: "20px", fontWeight: 500,color: "#1C1B1B"}}>Total Users</th>
                <th style={{fontSize: "20px", fontWeight: 500,color: "#1C1B1B"}}>Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {branchData.map((branch: SubBranch, index: number) => this.renderBranchRow(branch, `${index}`))}
              </tbody>
            </table>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '18px', color: '#787776' }}>
              <  AddIconNotFoundBoxs>
        <AddIconInnerBoxs>
          <Box>
            <AddImageComponents src={thumbnailImg} />
          </Box>
          <AddIconNotFoundTexts>No results found</AddIconNotFoundTexts>
        </AddIconInnerBoxs>
      </  AddIconNotFoundBoxs>
            </div>
          )}
        </div>
      </Wrapper>
    );
  };
  
  renderBranchRow : ((branch: SubBranch, index: string, level?: number ) => ReactChild) = (branch: SubBranch, index: string, level = 0) => {
    const { expandedRows } = this.state;
    const isExpanded = expandedRows[index];
    return (
      <>
        <tr key={branch.attributes.name}>
          <td style={{ paddingLeft: `${level * 20}px` }} className="ellipsis"> 
            {branch.attributes.sub_branches && (
              <span className="expand-toggle" onClick={() => this.toggleRow(index)} data-test-id="subtogglebuttonid">
                {isExpanded ? <MdKeyboardArrowDown style={{fontSize: "24px", color: "#1C1B1B"}}/> : <MdKeyboardArrowRight style={{fontSize: "24px", color: "#1C1B1B"}}/>}
              </span>
            )}
            <img src={folder} className="folder-icon"/><CustomTooltip title={branch.attributes.name} arrow placement="top" ><span className="branchName">{branch.attributes.name}</span></CustomTooltip>
          </td>
          <td className="divider"></td>
          <td>{branch.attributes.teams_counts}</td>
          <td style={{fontSize: "16px", fontWeight: 400, whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", maxWidth: "160px" }}><CustomTooltip title={branch.attributes.manager} arrow placement="top" ><span>{branch.attributes.manager}</span></CustomTooltip></td>
          <td>{branch.attributes.active_users}</td>
          <td>{branch.attributes.total_users}</td>
          <td style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <div style={{fontFamily:"Poppins",fontSize:"14px",fontWeight:400,borderRadius:"12px",textTransform:"capitalize",width:"103px",
            padding:"3px 25px", 
            backgroundColor:
              branch.attributes.payment_status === "pending"
                ? "#FFD9DF"
                : branch.attributes.payment_status === "paid"
                ? "#D7FFE7"
                : branch.attributes.payment_status === "free"
                ? "#FFEDE4"
                : "transparent",
                color:
              branch.attributes.payment_status === "pending"
                ? "#E70E6B"
                : branch.attributes.payment_status === "paid"
                ? "#005B1D"
                : branch.attributes.payment_status === "free"
                ? "#E47004"
                : "transparent"  
          }}>
              {branch.attributes.payment_status}
            </div>
          </td>
          <td data-test-id="edit_pencil_btn" onClick={() => this.navigateToBranchDetails(branch.attributes.id)}>
            <img src={edit} className="edit-icon"/>
          </td>
        </tr>
        {isExpanded && branch.attributes.sub_branches && branch.attributes.sub_branches.map((subBranch, subIndex) =>
          this.renderBranchRow(subBranch, `${index}-${subIndex}`, level + 1)
        )}
      </>
    );
  };
  
  render() {
    return (
      <>
      <HeadingMainWrapper>
        <HeadingInnerWrapper>
        <p className="main_head">Primary Branch</p>
        <p className="sub_head">D2DU</p>
        </HeadingInnerWrapper> 
        <Box className="advance_main">
          {this.advancedSearchFun()}
          </Box>
          <ButtonWrappers >
          <button className="btns" data-test-id="create_Branch" onClick={this.handleCreateBranch}><span><img src={AddIcon} alt ="icon"/></span>Create Branch</button>
          </ButtonWrappers>
      </HeadingMainWrapper>
      {this.renderTable()}
      </>
    
    );
  }
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',  
    color: 'black',    
    fontSize: '14px',       
    borderRadius: "10px", 
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    padding: "8px"
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#fff',
  },
}));

const StyledContentWrappers = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FAFAFA",
      alignItems: "center",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      cursor: "pointer",
      borderBottomLeftRadius: "8px !important",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
  },
  "& .filterIconBox:hover": {
      borderColor: '#652786'
  },
  "& .allBorder": {
    backgroundColor: "#EEE8F2",
      borderColor: '#652786',
  }
});

const ButtonWrappers = styled(Box)({
 display:"flex",
 justifyContent:"center",
 alignItems:"center",
 "& .btns": {
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width:"228px",
  gap:"10px",
  height:"56px",
  backgroundColor: "#652786 !important",
  cursor:"pointer",
  color:"#fff",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 600 as const,
  textTransform: "capitalize" as const,
},

});

const FiterImage = styled("img")({
});

const BadgeComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      right: " 0px",
      left: "20px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      height: "10px",
      width: "10px",
      borderRadius: "20px"
  }
});
const  HeadingInnerWrapper =styled(Box)({
  display:"flex",
  flexDirection:"column",
  paddingLeft:"20px",
  justifyContent:"center",
  gap:"5px",
  width:"304px",
  height:"72px",
  backgroundColor: "#fff !important",
  cursor:"pointer",
  color:"#2B2E3CCC",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 700 as const,
  textTransform: "capitalize" as const,
  boxShadow:  "6px 6px 25px 0px #E5E5E580", 
"& .main_head":{
  margin:0
},"& .sub_head":{
  margin:0,
  color:"#2B2E3C99",
  fontWeight:500
}
})
  const HeadingMainWrapper=styled(Box)({
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    '@media (max-width: 1100px)': {
      alignItems:"flex-start",
       flexDirection:"column",
       gap:"10px"
    },
    "& .advance_main":{
      '@media (max-width: 800px)': {
        paddingLeft:"20px"
     },
    }
  })

  const Wrapper = styled(Box)({
    "& .dashboard-container" :{
      padding: "20px"
    },
  
    "& .dashboard-table": {
      width: "100%",
      borderCollapse: "collapse"
    },
  
    "& .dashboard-table th, .dashboard-table td": {
      padding: "12px",
      textAlign: "center",
      borderBottom: "none",
    },
  
    "& .dashboard-table th": {
      backgroundColor: "#fff"
    },
  
    "& .folder-icon": {
      marginRight: "8px",
      fontSize: "1.2rem",
    },
  
    "& .edit-icon": {
      cursor: "pointer",
      color: "#9c27b0",
      fontSize: "1.2rem",
    },
  
    "& .expand-toggle": {
      cursor: "pointer",
      marginRight: "8px",
      display: "inline-flex",
      alignItems: "center"
    },
  
    "& .payment-status": {
      display: "inline-block",
      padding: "4px 8px",
      borderRadius: "12px",
      fontSize: "0.85rem",
      fontWeight: "bold",
      color: "white",
    },
  
    "& .payment-status.paid": {
      backgroundColor: "#D7FFE7",
      color: "#005B1D",
      fontSize: "14px",
      width: "103px",
      borderRadius: "20px",
      fontWeight: 400,
    },
  
    "& .payment-status.pending": {
      backgroundColor: "#FFD9DF",
      color: "#E70E6B",
      fontWeight: 400,
      fontSize: "14px",
      width: "103px",
      borderRadius: "20px"
    },
  
    "& .payment-status.free": {
      fontWeight: 400,
      backgroundColor: "#FFEDE4",
      color: "#E47004",
      fontSize: "14px",
      width: "103px",
      borderRadius: "20px"
    },      
  
    "& .divider-header, .divider": {
      width: "0.5px",
      backgroundColor: "#201e1ea3",
      padding: "1px !important",
    },
  
    "& .ellipsis": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "210px;",
      display: "flex",
      fontSize: "16px",
      color: "#1C1B1B",
      alignItems: "center"
    },
    "& .branchName":{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  });

  const AddIconNotFoundBoxs = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  });
  
  const AddIconInnerBoxs = styled(Box)({
    marginTop: "40px"
  });

  const AddImageComponents = styled("img")({
    macWidth:"100%",
    height:"auto",
  });

  const AddIconNotFoundTexts = styled(Typography)({
    marginTop: "26px",
    color: "#1C1B1B !important",
    textAlign: "center",
    fontFamily: "Poppins Arial, Helvetica, sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-1px"
  });
  
// Customizable Area End
