// Customizable Area Start
import React from "react";
import { Box, Typography, TextField, Button, Modal, InputAdornment, Select, IconButton, MenuItem } from "@material-ui/core"
import { withStyles, styled } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ProfileModalController, {
  Props
} from "./ProfileModalController.web";
import Backdrop from "@material-ui/core/Backdrop";
const Avatar = require("../assets/avatar.jpg");
const Edit = require("../assets/edit.png");
const editImage = require("../assets/EditEmail.png")
import { Formik } from "formik";
import * as yup from "yup";
import './style.css'
import { downIcon } from "./assets";

export default class ExampleBlockUI extends ProfileModalController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getUserProfileData();
    this.getUserProfileDataModal();
    this.CountryCodeApi();
  }

  render() {

    const { imageDemo, userProfileDataModal } = this.state
    /* istanbul ignore next */
    const onImageChange = (event: any) => {
      this.setState({
        imageDemo: URL.createObjectURL(event.target.files[0]),
        image: event.target.files[0]
      })

    }

    const renderResetYourPassword = () => {
      if (this.state.showPopup) {
      return (
        <div style={{...styles.showPopUpDiv, position: "fixed"}}>
          <div style={{...styles.showPopUpInnerDiv, textAlign: "center",}}>
            <h2 style={{ fontSize: "20px", color: "#333" }}>
              Reset Your Password
            </h2>
            <p style={{ fontSize: "14px", color: "#666", marginBottom: "20px"}}>
              A verification code will be sent to your device to start your
              change of password.
            </p>
            <div style={{ marginBottom: "20px" }}>
              <button
                style={{...styles.viaEmailButton, textAlign: "center",}}>
                <div style={{fontSize: "16px", color: "#111"}}>Via email</div>
                {this.state.email}
              </button>
            </div>
            <button
              style={styles.sendVarificationButton}
              data-test-id="password-email-test"
              onClick={() => {this.postResetPasswordViaEmail()}}
            >
              SEND VERIFICATION CODE
            </button>
            <button
            data-test-id="CancelTest"
              onClick={this.togglePopup}
              style={styles.cancelVarificationButton}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      return null;                                          
    }

    const renderVarifyPassword = () => {
      if (this.state.showPopupVarification) {
        const pin = this.state.verificationCode.join('');
        return (
          <div style={{...styles.varifyPasswordDiv, position: "fixed"}}>
            <div style={styles.varifyPasswordInnerDiv}>
              <h2 style={{...styles.varifyCodeText, fontWeight: "bold", textAlign: "center",}}>
                Verification code
              </h2>
              <p style={{...styles.varifyCodeText2,textAlign: "center"}}>
                A verification code was sent to your email, enter the 4-digit code
                received
              </p>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                {this.state.verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    type="number"
                    maxLength={1}
                    value={digit}
                    data-test-id="handleinputchangetest"
                    onChange={(e) => this.handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => this.handleKeyDown(index, e)}
                    style={{...styles.varifyCodeInputs, textAlign: "center"}}
                  />
                ))}
              </div>
              <p
                style={{...styles.resendCodeBtn,textAlign: "center"}}
                data-test-id="resend-code-test"
                onClick={() => {this.postResetPasswordViaEmail()}}
              >
                RESEND CODE
              </p>
              <button
                style={styles.confirmCodeBtn}
                data-test-id="verify-password-test"
                onClick={() => {
                  this.updateVarifyPassword(pin);
                }}
              >
                CONFIRM
              </button>
            </div>
          </div>
        );
      }
      return null;
    };
    
    /* istanbul ignore next */
    return (
      <Box className="scrollbar" style={{ width: "40vw", height: '95vh', overflowX: 'hidden', marginTop: '15px', backgroundColor: "white", padding: '30px', borderRadius: "20px" }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Edit Profile</Typography>
          <Box style={styles.closeButton} onClick={this.props.handelClose}><CloseIcon /></Box>
        </Box>
        <Box>
          <Formik
            initialValues={{
              first_name: userProfileDataModal?.first_name,
              last_name: userProfileDataModal?.last_name,
              image: userProfileDataModal?.avatar?.url || null,
              user_name: userProfileDataModal?.full_name,
              email_id: userProfileDataModal?.email,
              Phone_Number: userProfileDataModal?.phone_number,
              CountryCode: "US",
            }}
            onSubmit={(values) => {

              console.log(values, 'form data test');
              this.updateProfileData(values);
            }}
            validationSchema={yup.object().shape({
              first_name: yup.string().required('FirstName is required'),
              last_name: yup.string().required('LastName is required'),
              user_name: yup.string().required('UserName is required'),
              // Country_Code: yup.string().required('Country is required')
            })}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit
              } = props;

              return (
                <form className="regform" onSubmit={handleSubmit}>
                  <Box>
                    {
                      !this.state.isPasswordUpdate &&
                      <>
                        <Box style={{ display: "flex", alignItems: 'center', height: "150px", justifyContent: "center" }}>
                          <Box style={{ width: "100px", height: "100px", position: "relative" }}>
                            {imageDemo ?
                              <img src={imageDemo} className="shadowStyle" height="100px" width="100px" style={{ borderRadius: "50px" }} />
                              :
                              <img src={values.image ? values.image : Avatar} className="shadowStyle" height="100px" width="100px" style={{ borderRadius: "50px" }} />}

                            <label htmlFor="file">
                              <Box className="shadowStyle" style={{ backgroundColor: "white", borderRadius: "30px", width: "30px", height: "35px", position: "absolute", top: "0px", right: "-5px", display: 'flex', alignItems: "center", justifyContent: "center", cursor: 'pointer' }}>
                                <img src={Edit} height="20px" width="20px" />
                              </Box>
                            </label>
                            <input test-id="change1" id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "10px" }}>Personal Details</Typography>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Name</Typography>
                            <TextField fullWidth placeholder="First Name" variant="outlined" size="small"
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              error={touched.first_name && Boolean(errors.first_name)}
                              helperText={touched.first_name && errors.first_name} />
                          </Box>

                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Last Name</Typography>
                            <TextField fullWidth placeholder="Last Name" variant="outlined" size="small"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              error={touched.last_name && Boolean(errors.last_name)}
                              helperText={touched.last_name && errors.last_name} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>User Name</Typography>
                            <TextField fullWidth disabled placeholder="User Name" variant="outlined" size="small"
                              name="user_name"
                              value={values.user_name}
                              error={touched.user_name && Boolean(errors.user_name)}
                              helperText={touched.user_name && errors.user_name} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Email Id</Typography>
                            <TextField fullWidth placeholder="Email Id" variant="outlined" size="small"
                              name="email_id"
                              value={values.email_id}
                              onChange={handleChange}
                              inputProps={{ type: 'email' }}
                              error={touched.email_id && Boolean(errors.email_id)}
                              helperText={touched.email_id && errors.email_id}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img onClick={this.props.handelEditEmailModel} style={{ cursor: 'pointer' }} src={editImage} alt="Edit" />
                                  </InputAdornment>
                                ),
                              }} />
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Phone Number</Typography>
                            <Box style={{ display: 'flex' }}>
                              <CustomSelect
                                    id="CountryCode"
                                    name="CountryCode"
                                    displayEmpty
                                    value={values.CountryCode}
                                    onChange={handleChange}
                                    onOpen={this.handleSelectOpen}
                                    onClose={this.handleSelectClose}
                                    MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                                    className={`select_flag`}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => (
                                      <IconButton style={{top: 3}} {...props} className="icon_img MuiSelect-icon">
                                        <img  src={downIcon} />
                                      </IconButton>
                                    )}
                                  >
                                    {this.state.CountryFlagCode && this.state.CountryFlagCode
                                       .filter(this.isCountryMatch)
                                      .map(name => (
                                        <MenuItem
                                          key={name.id}
                                          value={name.id}
                                          className="menu_box"
                                        >
                                          <span className="menu_arrow"></span>
                                          <span className={`flag_img fi fi-${name.id.toLowerCase()}`}></span>
                                          <span className="flag_Text">{`+${name.attributes.country_code}`}</span>
                                        </MenuItem>
                                      ))
                                    }
                                  </CustomSelect>
                              <TextField fullWidth placeholder="Phone Number" variant="outlined" size="small"
                                name="Phone_Number"
                                value={values.Phone_Number}
                                onChange={handleChange}
                                inputProps={{ type: 'number' }}
                                error={touched.Phone_Number && Boolean(errors.Phone_Number)}
                                helperText={touched.Phone_Number && errors.Phone_Number} />
                            </Box>
                          </Box>

                          <Box onClick={this.togglePopup} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                            <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                              <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>Passwords</Typography>
                              <ArrowForwardIosIcon />
                            </Box>
                          </Box>
                          <Box onClick={() => this.handleOpen()} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                            <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                              <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>My Interests</Typography>
                              <ArrowForwardIosIcon />
                            </Box>
                          </Box>
                          <Box style={{ marginTop: "25px" }}>
                            <ColorButton variant="contained" fullWidth style={{ fontSize: "14px", fontWeight: "bold" }} type="submit">
                              Save
                            </ColorButton>
                          </Box>
                        </Box></>
                    }
                  </Box>
                </form>
              )
            }}
          </Formik>
          <Formik
            initialValues={
              {
                password: '',
                old_password: '',
                password_confirmation: ''
              }
            }
            onSubmit={(values) => {
              console.log(values, 'form data test');
              this.updateProfilePassword(values)

            }}/*  */
            validationSchema={yup.object().shape({
              password: yup
              .string()
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              )
              .required("Password is required")
              .notOneOf([yup.ref('old_password')], "New password cannot be the same as the old password"),
              old_password: yup.string().required('old password is required'),
              password_confirmation: yup
              .string()
              .required("Confirm Password is required")
              .oneOf(
                [yup.ref("password"), null],
                "Passwords do not match"
              ),
            })}
            enableReinitialize={true}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,

              } = props;
              return (
                <form className="regform" onSubmit={handleSubmit}>
                  {
                    this.state.isPasswordUpdate &&
                    <Box>
                      <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "10px" }}>Update Password</Typography>
                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Current Password</Typography>
                        <TextField fullWidth placeholder="Current Password" variant="outlined" size="small"
                          name="old_password"
                          value={values.old_password}
                          onChange={handleChange}
                          error={touched.old_password && Boolean(errors.old_password)}
                          helperText={touched.old_password && errors.old_password}
                        />
                      </Box>
                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>New Password</Typography>
                        <TextField fullWidth placeholder="Password" variant="outlined" size="small"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />

                      </Box>

                      <Box style={{ marginTop: "25px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Confirm Password</Typography>
                        <TextField fullWidth placeholder="Confirmation Password" variant="outlined" size="small"
                          name="password_confirmation"
                          value={values.password_confirmation}
                          onChange={handleChange}
                          error={touched.password_confirmation && Boolean(errors.password_confirmation)}
                          helperText={touched.password_confirmation && errors.password_confirmation}
                        />
                      </Box>
                      <Box onClick={this.disablePasswordUI} style={{ marginTop: "25px", backgroundColor: "#f3f3f3", height: "50px", cursor: "pointer" }}>
                        <Box style={{ padding: "10px 15px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "14px" }}>Back to profile</Typography>
                          <ArrowForwardIosIcon />
                        </Box>
                      </Box>
                      <Box style={{ marginTop: "25px" }}>
                        <ColorButton variant="contained" fullWidth style={{ fontSize: "14px", fontWeight: "bold" }} type="submit">Update Password </ColorButton>
                      </Box>
                    </Box>
                  }
                </form>
              )
            }}
          </Formik>
          {renderResetYourPassword()}
          {renderVarifyPassword()}
        </Box>
      </Box>
    );
  }
}

const styles = {
  closeButton: {
    borderRadius: "50px",
    width: "50px",
    height: "50px",
    '&:hover': {
      backgroundColor: 'none'
    },
    '&:active': {
      backgroundColor: 'none',
    },
  },
  mainBox: {
    width: "40vw",
    height: '95vh',
    overflowX: 'hidden' as 'hidden',
    marginTop: '15px', backgroundColor: "white",
    padding: '30px',
    borderRadius: "20px"
  },
  showPopUpDiv: {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  showPopUpInnerDiv: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
    padding: "20px",
    width: "400px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  viaEmailButton: {
    width: "100%",
    padding: "10px",
    margin: "5px 0",
    backgroundColor: "#F7F7F7",
    border: "1px solid #DDD",
    borderRadius: "16px",
    fontSize: "14px",
    color: "#333",
  },
  sendVarificationButton: {
    padding: "10px 20px",
    backgroundColor: "#FF7E36",
    color: "#FFF",
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
    fontSize: "16px",
    marginRight: "15px"
  },
  cancelVarificationButton: {
    marginTop: "15px",
    backgroundColor: "transparent",
    border: "none",
    color: "#FF7E36",
    cursor: "pointer",
    fontSize: "14px",
  },
  varifyPasswordDiv: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  varifyPasswordInnerDiv: {
    background: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "320px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
  varifyCodeText: {
    fontSize: "18px",
    marginBottom: "10px",
  },
  varifyCodeText2: {
    fontSize: "14px",
    marginBottom: "20px",
    color: "#555",
  },
  varifyCodeInputs: {
    width: "50px",
    height: "50px",
    fontSize: "18px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    outline: "none",
  },
  resendCodeBtn: {
    fontSize: "14px",
    color: "purple",
    marginBottom: "15px",
    cursor: "pointer",
  },
  confirmCodeBtn: {
    background: "linear-gradient(to right, red, purple)",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "16px",
    borderRadius: "25px",
    cursor: "pointer",
    width: "100%",
  },
}

const CustomSelect = styled(Select)({
  marginRight: "10px",
  width: "110px",
  '& .MuiSelect-root': {
    position: 'relative',
  },
  '& .MuiPopover-paper': {
    position: 'relative',
    zIndex: 999,
  },
  "& .MuiList-padding": {
    paddingTop: "0px"
  },
  "& .MuiListItem-gutters": {
    paddingLeft: "2px !important",
    paddingRight: "8px !important",
    gap: "10px !important",
    color: "#6C328B !important",
    fontSize: "14px !important",
    fontWeight: 500
  },
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "5px 20px 5px 5px",
  }
})

const CustomMenuProps = {
  PaperProps: {
    style: {
      height: "auto" as const,
      marginTop: '10px' as const,
      marginLeft: "6px" as const,
      width: "86px" as const,
      minWidth: "86px",
      left: "10px"
    },
  },
  getContentAnchorEl: null
};

const ColorButton :any = withStyles((theme) => ({
  root: {
    backgroundColor: purple[800],
    color: "white",
    borderRadius: "10px",
    height: "50px",
    borderColor: purple[700],
    '&:hover': {
      borderColor: purple[500],
      color: "white",
      backgroundColor: purple[600],
    },
  },
}))(Button);
// Customizable Area End
