export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const FORWARD_ICON = require("../assets/forward.png");
export const goToNext = require("../assets/top.png");
export const dropDown = require("../assets/dropDown.png");
export const RightArrow = require("../assets/right_arrow.png");
export const LeftArrow = require("../assets/left_arrow.png");
export const UpgradeBanner = require("../assets/upgrade.png");
export const ReferBanner = require("../assets/refer_banner.png");
export const CopyIcon = require("../assets/copy.png");
