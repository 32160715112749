import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sortTableData } from "../../utilities/src/TableHelpers";
import debounce from "lodash.debounce";
interface ThumbnailType{
 id:string,
 type:string,
 attributes:{
    title:string,
    created_at:string,
    image:{
        id:string,
        url:string,
        filename:string
    },
    created_by:string
 }   
}
export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name:string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}
export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}
export interface CheckboxSelectedListTypes { value: string, id: string }
interface ThumbnailResponse{
    data:[ThumbnailType]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:boolean;
  onClose:any;
  CloseModal:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchAdvanceValue:string;
  filteredContentLists: Array<string>,
  showContentLists:boolean,
  anchorFilterContentEl: null | HTMLElement;
  ThumbnailDataList:Array<ThumbnailType>
  selectRadio:string;
  filterItemsData:boolean;
  placeholderParams: string;
  placeholderSelectItems:Array<CheckboxSelectedListTypes>
  creationThumbnailDate:string;
  creationDateParamsdata: {
    start_date: string;
    end_date: string;
  };
  isCalendarOpens:boolean;
  placeholderSearchTexts:string;
  anchorEl: null | HTMLElement;
  dropdownType: string;
  expertAdminLists: Array<ExpertListing>;
  searchList:boolean;
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommonmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllThumbnailDataCallID:string=""
  getPreviewApiCallId:string=""
  getAdvanceSearchApiCallId:string=""
  GetAdminExpertListCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchAdvanceValue:"",
      filteredContentLists:[],
      showContentLists:false,
      anchorFilterContentEl: null,
      ThumbnailDataList:[],
      selectRadio:"",
      filterItemsData:false,
      placeholderSelectItems:[],
      placeholderParams:"",
      creationThumbnailDate:"",
      creationDateParamsdata: {
        start_date: "",
        end_date: ""
      },
      isCalendarOpens:false,
      placeholderSearchTexts:"",
      anchorEl: null,
      dropdownType: "",
      expertAdminLists:[],
      searchList:false,
      isLoading:false
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValue + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

if (apiRequestCallId === this.getAllThumbnailDataCallID) {
  this.getThumbailAllData(responseJson)
 
  this.parseApiCatchErrorResponse(errorResponse);
  this.setState({isLoading: false})
}
if(apiRequestCallId=== this.getPreviewApiCallId){
  this.handleThumbnailPreview(responseJson)
}else if(apiRequestCallId===this.getAdvanceSearchApiCallId){
      this.setState({filteredContentLists:responseJson.data})
}
  if (this.GetAdminExpertListCallId === apiRequestCallId) {  
    this.setState({
      expertAdminLists: responseJson.data
    })
}
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {
    this.setState({isLoading: true})
    super.componentDidMount();
    this.getAllThumbnailData();
    this.getAdminExpertListings();
  }

  getThumbailAllData=(responseJson:ThumbnailResponse)=>{
  this.setState({ThumbnailDataList:responseJson.data})
  }
 
  ConstructEndPoint = () => {
    let params = [];
  
    if (this.state.searchAdvanceValue && this.state.searchList) {
      params.push(this.searchValueParams());
    }
    if (this.state.creationDateParamsdata) {
      params.push(this.addCreationDateRangeParam());
    }
    if (this.state.creationThumbnailDate) {
      params.push(this.addCreationDateParam());
    }
    if(this.state.placeholderParams){
      params.push(this.addPlaceholderParams())
    }
    params = params.filter(param => param !== '');
    return params.length > 0 ? params.join('&') : '';
  };
  private searchValueParams(): string{
    return `search=${this.state.searchAdvanceValue ? encodeURIComponent(this.state.searchAdvanceValue):""}`;
  }
  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `q[created_at]=${dateParam}` : ''
  }

  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParamsdata;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `q[custom_range]=${queryString}`;
    }
    return '';
  }
  private addPlaceholderParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
  }
  private getDateParam(): string {
    switch (this.state.creationThumbnailDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }
  RowClick=(userId:any)=>{
    this.setState({selectRadio:userId})
  }
getAllThumbnailData=async()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getAllThumbnailDataCallID = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getAllThumbailEndPoint}?${this.ConstructEndPoint()}`
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}
formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
};

handleRadioChange=(event:any)=>{
  this.setState({selectRadio:event?.target.value})
}
sortUsers = (dataType: string, keyType: string, sortType: string) => {
  const sortedUsers = sortTableData(
    this.state.ThumbnailDataList,
    dataType,
    keyType,
    sortType,
  );
  this.setState({ ThumbnailDataList: sortedUsers });
};
handleClickFilters = () => {
  this.setState({
    anchorFilterContentEl: null
  }, () => {
    this.getAllThumbnailData()
  })

}
placeholderDebounced: () => void = debounce(
  () => this.getAdminExpertListings(),
  700
)
getAdminExpertListings=()=>{
  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.GetAdminExpertListCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.expertAdminListApiEndPoint}?search=${this.state.placeholderSearchTexts}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}
handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
  this.setState({
    filterItemsData: !this.state.filterItemsData,
    anchorFilterContentEl: event.currentTarget,
  })
};
dropdownHandlerOpens = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
  this.setState({
    anchorEl: event.currentTarget,
    dropdownType
  })
}
handleClearFilters=()=>{
  this.setState({
    anchorFilterContentEl: null,
  placeholderParams: "",
  placeholderSelectItems:[],
  creationThumbnailDate:"",
  creationDateParamsdata: {
    start_date: "",
    end_date: ""
  },
  isCalendarOpens:false,
  placeholderSearchTexts:"",
  anchorEl: null ,
  dropdownType: "",
    }, () => {
      this.getAllThumbnailData()
    })
}
handleOpenCalendors = () => {
  this.setState({
    isCalendarOpens: true
  })
}
handleChangesCalendors = (value: CalendarValue) => {
  const startDate = new Date(value[0]);
  const endDate = new Date(value[1]);
  const formattedStartDates = startDate.getDate();
  const formattedStartDatesNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
  const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
  const formattedStartYears = startDate.getFullYear();
  const formattedEndDate = endDate.getDate();
  const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
  const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
  const formattedEndYears = endDate.getFullYear();
  const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
  const dateRange = `${formattedStartDates}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYears}`;
  const start_date = `${formattedStartYears}-${formattedStartMonthNum}-${formattedStartDatesNum}`;
  const end_date = `${formattedEndYears}-${formattedEndMonthNum}-${formattedEndDateNum}`;
  this.setState({
    creationThumbnailDate: dateRange,
    creationDateParamsdata: {
      start_date,
      end_date
    }
  });
}

handlePlaceholderAssignedSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    placeholderSearchTexts: event.target.value
  }, () => {
    this.placeholderDebounced();
  })
}
dropdownHandlerClose = () => {
  this.setState({
    anchorEl: null,
    isCalendarOpens: false,
    dropdownType: "",
    // categoryId: [],
  })
}
creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    creationThumbnailDate: event.target.value
  })
}
filterContainerDropdownCloseHandlers = () => {
  this.setState({
    anchorFilterContentEl: null
  })
}

placeholderCheckboxAssignedExpertChangeHandlers = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
  const { checked, name } = event.target;
  if (checked) {
    this.setState(prevState => ({
      placeholderSelectItems: [...prevState.placeholderSelectItems, { value: name, id: itemId }]
    }), () => {
      const params = this.state.placeholderSelectItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
      this.setState({
        placeholderParams: params
      });
    });
  } else {
    this.setState(prevState => ({
      placeholderSelectItems: prevState.placeholderSelectItems.filter((item: { value: string, id: string }) => item.id !== itemId)
    }), () => {
      const params = this.state.placeholderSelectItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
      this.setState({
        placeholderParams: params
      });
    });
  }
}
handleInputChangeSearch = (event:any) => {
  this.setState({searchAdvanceValue:event.target.value},() => {
    this.getAllThumbnailData()
  })
    this.setState({searchList:false,showContentLists:event.target.value !== ""},()=>{this.getAdvancedSearchList()}) 
   }
   handleThumbnailListClick=(item:string)=>{
    this.setState({
      searchList:true,
      searchAdvanceValue: item,
      showContentLists: false
    },()=>{
       this.getAllThumbnailData()
    })
   }
handleAlertSuccessClose=()=>{
  setTimeout(() => {
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: false,
      },
    }));
  }, 3000);
}
handleSelectThumbnail=()=>{
  this.getThumbnailPreview()
}
getAdvancedSearchList=()=>{
  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getAdvanceSearchApiCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.AdvancedSearchEndPoint}?type=thumbnail&search=${this.state.searchAdvanceValue}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;

}
getThumbnailPreview=()=>{
  const token = localStorage.getItem('token')
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token
  };
  const requestMessageUpdate = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessageUpdate.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getPreviewApiCallId = requestMessageUpdate.messageId
  requestMessageUpdate.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getAllThumbailEndPoint}/${this.state.selectRadio}`
  );
  requestMessageUpdate.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessageUpdate.id, requestMessageUpdate);
  return requestMessageUpdate.messageId;

}
handleThumbnailPreview=(responseJson:any)=>{
  this.props.onClose(responseJson.data)
}

  // Customizable Area End
}
