Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.validationApiMethodType = "GET";
exports.termAPiEndPoint = "bx_block_settings/pages/terms-of-use";
exports.termAPiMethod = "POST";
exports.termApiContentType = "application/json";
// Customizable Area End