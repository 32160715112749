import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  libraryData: any;
  createFolder: any;
  ThumbnailData:any;
  setImage:any;
  newId:string;
  ApiImage:string;
  themeMode: boolean;
  dialogopen: boolean;
  dialogopen1:boolean;
  ThumbnailDownload:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageThumbnailUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PostCeateNewFolder: any;
  PostCeateNewImage:any;
  GetFolderData: any;
  GetThumbnailData:any;
  GetThumbnailDownload:any;
  DeleteThumbnailData:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: "",
      createFolder: "",
      themeMode: false,
      dialogopen: false,
      dialogopen1:false,
      setImage:'',
      ApiImage:'',
      newId:'',
      libraryData: {},
      ThumbnailData:{},
      ThumbnailDownload:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
    this.getFolderData();
    this.getThumbnailData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert("Change Value", `From: ${this.state.txtSavedValue} To: ${value}`);
      this.setState({ txtSavedValue: value });
    }
  
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.PostCeateNewFolder:
            this.getFolderData();
            break;
          case this.PostCeateNewImage:
            this.getThumbnailData();
            this.dialog1HandleClose();
            break;
          case this.DeleteThumbnailData:
            this.getThumbnailData();
            break;
          case this.GetThumbnailData:
            this.setState({ ThumbnailData: responseJson.data });
            break;
          case this.GetThumbnailDownload:
            this.setState({ ThumbnailDownload: responseJson?.link });
            const link = document.createElement("a");
            link.download = `${responseJson.link}`;
            link.href = `${responseJson.link}`;
            link.target = "_blank";
            link.click();
            break;
          case this.GetFolderData:
            this.setState({ libraryData: responseJson });
            break;
        }
      }
    }
    // Customizable Area End
  }
  

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onImageChange = (event:any) => {
    this.setState({
      setImage: URL.createObjectURL(event.target.files[0]),
      ApiImage: event.target.files[0]
    },()=>{

    })
  }

  UploadNewImage = () => {
    let formdata = new FormData();
    formdata.append("folder_id", this.props.history.location.state.id);
    formdata.append("image", this.state.ApiImage);

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    const httpBody = formdata;

    console.log("httpBody", httpBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCeateNewImage = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/thumbnail_libraries`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dialogHandleClose = () => {
    this.setState({ dialogopen: false });
  };

  dialogHandleOpen = () => {
    this.setState({ dialogopen: true });
  };

  dialog1HandleClose = () => {
    this.setState({ dialogopen1: false });
  };

  image_cancel = () => {
    this.setState({ dialogopen1: false });
    this.setState({setImage:''})
  }

  dialog1HandleOpen = () => {
    this.setState({ dialogopen1: true });
  };

  handleNavigate = (data: any) => {
    this.props.history.push(`/ManageThumbnailUser/${data?.id}`, { id: data?.id });
    this.setState({ newId: data?.id }, () => {
      this.getFolderData()
    })
    setTimeout(function () {
      window.location.reload();
    }, 100);
  };

  Delete_thumbnail = (id:number) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteThumbnailData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/thumbnail_libraries/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "delete"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  Downloadimage = (id:number) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetThumbnailDownload = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/thumbnail_libraries/download_image?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getThumbnailData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetThumbnailData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/thumbnail_libraries?folder_id=${this.props.history.location.state.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFolderData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetFolderData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder?folder_id=${this.props.history.location.state.id}&folder_type=thumbnail_library`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewFolder = () => {
    let formdata = new FormData();
    formdata.append("name", this.state.createFolder);
    formdata.append("folder_type", "thumbnail_library");
    formdata.append("parent_folder_id", this.props.history.location.state.id);

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token"),
    };
    const httpBody = formdata;

    console.log("httpBody", httpBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCeateNewFolder = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
