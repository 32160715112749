// Customizable Area Start
import React from 'react';
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search } from "@material-ui/icons";
import DynamicTitle from "../../../components/src/DynamicTitle";
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import '../../videos/src/video.css';
import { Caret1 } from './assets';
import AchievementListTable from '../../../components/src/AchievementList';
const heading = [
  { label: "", sort: false },
  { label: "", sort: false },
  { label: "Achievement  Name", sort: true },
  { label: "Assigned", sort: false },
  { label: "Earned", sort: true },
  { label: "Branch", sort: false },
  { label: "Requirements", sort: true },
  { label: "Status", sort: true },
  { label: "", sort: false }
];
class AchievementList extends CourseCreationController {
  constructor(props: any) {
    super(props);
  }
  selected = (index: any) => {
    let items = [...this.state.reward_list];
    let item = { ...items[index] }
    item.is_selected = !item.is_selected
    items[index] = item
    this.setState({ reward_list: items })
  }

  // sort(key:string,type:string){
  //   if(type === "asc"){
  //     this.state.achievement_list.sort(function(a:any, b:any) {
  //       const nameA = a?.attributes?.name.toLowerCase();
  //       const nameB = b?.attributes.name.toLowerCase(); 
  //       if (nameA < nameB) {
  //         return -1;
  //       }
  //       if (nameA > nameB) {
  //         return 1;
  //       }
  //         return 0;
  //     });
  //   }
  //   else {
  //     this.state.achievement_list.sort(function(a:any, b:any) {
  //       const nameA = a?.attributes?.name.toLowerCase();
  //       const nameB = b?.attributes?.name.toLowerCase(); 
  //       if (nameA > nameB) {
  //         return -1;
  //       }
  //       if (nameA < nameB) {
  //         return 1;
  //       }
  //         return 0;
  //     });

  //   }

  // }

  render() {
    return (<>
      <div className="existing_course reward-list">
        <DynamicTitle title="Achievements" />
        <Grid container style={{ paddingBottom: "30px" }}>
          <Grid item md={4} xs={10}>

            <TextField
              fullWidth
              id="search"
              variant="outlined"
              name="search"
              data-test-id="handleChange"
              placeholder="Search"
              InputLabelProps={{
                shrink: true
              }}
              className="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={2} xs={12}>
            <FormControl className="bulk-action">
              <Select
                labelId="bulk action"
                id="bulk action"
                name='branch_name'
                variant="outlined"
                data-test-id = "IconComponent"
                IconComponent={() => null}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={Caret1} />
                  </InputAdornment>

                }
                fullWidth>
                <MenuItem value="">
                  <em>Bulk Action</em>
                </MenuItem>
                <MenuItem value={1}>Phase 1</MenuItem>
                <MenuItem value={2}>Phase 2</MenuItem>
                <MenuItem value={3}>Phase 3</MenuItem>
                <MenuItem value={4}>Phase 4</MenuItem>
                <MenuItem value={5}>Phase 5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} style={{ textAlign: "end" }}>
            <Button className='create-reward-btn'>Create Achievements</Button>
          </Grid>
        </Grid>
        <AchievementListTable
          data-test-id = "AchievementListTable"
          tableHeading={heading}
          //@ts-ignore
          columnData={this.state.achievement_list}
          // getRewardList={(obj: any) => this.getAchievementList(obj)}
          meta={this.state.meta}
          tableRows={this.state.achievement_list}
          selected={(index: any) => this.selected(index)}
        />

      </div>
    </>);
  }
}
export default AchievementList;
// Customizable Area End