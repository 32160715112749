import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import PdfViewer, { IPdfViewer } from "./PdfViewer.web";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement }, 
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" timeout={10} ref={ref} {...props} />;
})

export default function PdfViewerDialog({ 
  url, 
  fullScreenPdf, 
  onFullScreenBtnClick,
  onNoteBtnClick,
}: IPdfViewer) {
  const classes = useStyles();

  const handleClose = () => {
    onFullScreenBtnClick();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={fullScreenPdf}
        onClose={handleClose}
        className={classes.dialog}
        TransitionComponent={Transition}
      >
        <Button
          className={classes.backBtn}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleClose}
        >
          Back
        </Button>
        <PdfViewer 
          url={url}
          fullScreenPdf={fullScreenPdf}
          onNoteBtnClick={onNoteBtnClick}
          onFullScreenBtnClick={onFullScreenBtnClick}
        />
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  dialog: {
    position: "relative"
  },
  backBtn: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    color: "#000",
    fontSize: 16,
    textTransform: "capitalize",
    [theme.breakpoints.up("sm")]: {
      color: "#fff",
    }
  },
  toolbar: {
    background: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // position: "relative",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    "& > *": {
      marginRight: 15
    }
  },
  nestedIcons: {
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      marginLeft: 15,
      padding: 0
    }
  }
}));
