// Customizable Area Start
import React from "react";
import { Formik } from "formik";
import QuizController from "./QuizController.web";
import QuizForm from "./QuizForm.web";
import {
  handleCreateQuiz,
  handleSaveQuestion,
  handleDeleteQuestion,
  handleBulkActions,
} from "./utils.web";

class CreateQuiz extends QuizController {
  render() {
    const { quiz, validationSchema } = this.state;
    const { history, navigation, location } = this.props;

    return (
      <Formik
        initialValues={quiz}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSaveQuestion}
      >
        {(formikProps: any) => (
          <QuizForm
            {...formikProps}
            quiz={quiz}
            editQuiz={quiz.id}
            history={history}
            courseId={quiz.courseId}
            location={location}
            navigation={navigation}
            handleCreateQuiz={handleCreateQuiz}
            handleBulkActions={handleBulkActions}
            handleDeleteQuestion={handleDeleteQuestion}
            handleAddQuestion={this.handleAddQuestion}
            handleAddMcqOption={this.handleAddMcqOption}
            handleEditQuestion={this.handleEditQuestion}
            handleQuestionTypeChange={this.handleQuestionTypeChange}
            handleCancelSavingQuestion={this.handleCancelSavingQuestion}
            handleRemoveOptionsForMcqQuestions={
              this.handleRemoveOptionsForMcqQuestions
            }
          />
        )}
      </Formik>
    );
  }
}

export default CreateQuiz;

// Customizable Area End
