import React, { useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const VerticalBarChart = ({ data }: { data: any }) => {
  /* istanbul ignore next */
  let label = data?.map((data: any) => data[0].split("-").slice(1).join("/"))
  /* istanbul ignore next */
  let newData = data?.map((data: any) => data[1])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,

      },

    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 20 //this change the font size
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 20 //this change the font size
          }
        }
      }
    }

  };

  const labels = label;


  let chartRef = useRef<any>({});

  type Data = {
    labels: string[];
    datasets: Dataset[];
  };

  type Dataset = {
    id: number;
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string;
    maxBarThickness: number;
    pointBorderColor: string;
    responsive: boolean;
  };

  const chartData: Data = {
    labels: labels,
    datasets: [
      {
        id: 1,
        label: '',
        data: newData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: [],
        maxBarThickness: 15,
        pointBorderColor: 'transparent',
        responsive: true,
      },

    ]

  }
  /* istanbul ignore next */
  chartData?.datasets[0]?.data?.forEach((value: number) => {
    if (value < 2) {
      chartData?.datasets[0]?.backgroundColor.push('#F42074')
    } else {
      chartData?.datasets[0]?.backgroundColor.push('#592A93')
    }
  });

  return (
    <>
      <div className='barChart' >
        <Bar ref={chartRef} options={options} data={chartData} />
      </div>
    </>
  )
}

export default VerticalBarChart