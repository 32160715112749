import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalBarChart = ({ data1 }: { data1: any }) => {
  if (!data1 || !data1.data || data1.data.length === 0) {
    return <div>No data available for the chart. Please change the value from Dropdwon</div>;
  }

  const labels = data1.data.map((item: any) => item[0].split('-').slice(1).join('/'));
  const newData = data1.data.map((item: any) => item[1]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,

      },

    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 20 //this change the font size
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 20 //this change the font size
          }
        }
      }
    }

  };

  type Data = {
    labels: string[];
    datasets: Dataset[];
  };

  type Dataset = {
    id: number;
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string;
    maxBarThickness: number;
    pointBorderColor: string;
    responsive: boolean;
  };

  const chartData:Data = {
    labels: labels,
    datasets: [
      {
        id: 1,
        label: '',
        data: newData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: [], // This will be updated in useEffect
        maxBarThickness: 15,
        pointBorderColor: 'transparent',
        responsive: true,
      },
    ],
  };

  chartData?.datasets[0]?.data.forEach((value: number) => {
    if (value < 15) {
      chartData?.datasets[0]?.backgroundColor.push('#F42074')
    } else {
      chartData?.datasets[0]?.backgroundColor.push('#592A93')
    }
  });

  return (
    <div className='barChart'>
      <Bar  options={options} data={chartData} />
    </div>
  );
};

export default VerticalBarChart;
