Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalText =
  "By checking this option you agreed to our";
exports.labelHeader = "Please enter your personal details";
exports.labelFirstName = "Please type first name";
exports.btnTextSignUp = "REGISTER";
exports.lastName = "Please type last name";
exports.labelEmail = "Please type email address";
exports.labelPassword = "Please enter password";
exports.labelRePassword = "Confirm your password";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.accountsAPiEndPoint =
  "account_block/account";
exports.lastName = "Last name";
exports.xpandLogo = "XPAND";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.putApiMethod = "PUT";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.labelReferalText = "Do you have Referral Code"
exports.HiThere = "Hi There!";
exports.titleWelcome = "Welcome to XPAND";
exports.backgroundImage = "assets/backgroundImage.png";
exports.Oval = "assets/Oval.png";
exports.perfectImage = "assets/perfectImage.png";
exports.formDataContentType = "application/json";
exports.CountryFlagApiEndPoint = "account_block/accounts/country_code_and_flags";
exports.setupAccountDetailsApiEndPoint = "account_block/setup_accounts/user_detail";
exports.setupAccountEditDetailsApiEndPoint = "account_block/setup_accounts";
// Customizable Area End
