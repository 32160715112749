import React, { useEffect } from "react";
import { Container, Grid, Button, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CartItem from "./CartItem.web";
import { crossIcon } from "../assets";

interface IOrderSuccess {
  orderItems: any;
  message: string;
  onGoToHomeClick: () => void;
  onTryAgainClick: () => void;
  cartOrderDetails: () => Promise<void>;
}

export default function OrderFailure({ 
  message,
  orderItems, 
  onGoToHomeClick, 
  onTryAgainClick,
  cartOrderDetails
}: IOrderSuccess) {
  const classes = useStyles();

  let orderItemsContent;
  if (orderItems && orderItems?.length > 0) {
    orderItemsContent = orderItems.map((data: any) => {
      const isCourse = data.attributes.item_details.type === "Program" ? false : true
      const author = data?.attributes?.item_details?.created_by;
      const thumbnail = data?.attributes?.item_details?.image_url?.url;
      const title = data?.attributes?.item_details?.title;
      return (
        <CartItem
          key={data.id}
          itemType={isCourse ? "Course" : "Program"}
          thumbnail={thumbnail}
          title={title}
          author={author}
          danger={false}
        subscriptionPeriod={orderItems[0]?.attributes?.subscription_period}
        />
      );
    });
  } else {
    orderItemsContent = null;
  }

  useEffect(()=>{
    cartOrderDetails()
  },[])
  return (
    <Container maxWidth="md" className={classes.mainContainer}>
      <Box className={classes.topGridContainer}>
        <Box className={classes.centeredContainer}>
          <img 
            src={crossIcon} 
            alt="order success" 
            className={classes.img}
          />
        </Box>
        <Box className={classes.centeredContainer}>
          <h1 className={classes.headingText}>Ooops!</h1>
          <p className={classes.descriptionText}>{message}</p>
        </Box>
      </Box>
      <p className={classes.secondaryText}>Order Summary</p>
      {orderItemsContent}
      <Grid container style={{gap:"12px"}}>
        <Grid item xs={12}>
          <Button 
            fullWidth 
            className={classes.btn}
            onClick={onGoToHomeClick}
          >
            Go to Home Page
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.flexCenter}>
          <Button
            variant="contained"
            fullWidth
            className={`${classes.btn} ${classes.containedBtn}`}
            onClick={onTryAgainClick}
          >
            Try Again
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      borderRadius: 16,
      marginTop: 50,
    },
    topGridContainer: {
      paddingTop: 25,
      display:"flex",
      alignItems:"center",
      gap:"24px"
    },
    centeredContainer: {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    infoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headingText: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#BF0032",
    },
    descriptionText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#1A1A1A",
    },
    img: {
      width: 100,
      height: 100,
    },
    secondaryText: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "24px",
      color: "#1C1B1B",
    },
    emphasis: {
      fontWeight: 700,
    },
    btnContainer: {
      justifyContent: "center",
      padding: "25px 0",
    },
    btn: {
      width: "100%",
      height:"46px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      textTransform: "none",
      color: "#652786",
      padding: "0 40px",
    },
    containedBtn: {
      background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
      color: "#fff",
      maxWidth:"300px",
      height:"48px",
      borderRadius:"88px"
    },
    flexCenter:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }
  })
);