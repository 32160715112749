export const Logo = require("./assets/images/Logo.svg");
export const AssignmentIcon = require("./assets/images/assignment.png");
export const CalenderIcon = require("./assets/images/calender.png");
export const ChannelsIcon = require("./assets/images/channels.png");
export const CoursesIcon = require("./assets/images/courses.png");
export const GoalsIcon = require("./assets/images/goals.png");
export const HomeIcon = require("./assets/images/home.png");
export const TopBanner = require("./assets/images/rectangle.png");
export const TopBannerMobile = require("./assets/images/rectangle_mobile.png");
export const Crown = require("./assets/images/crown.png");
export const GoalsIllustrator = require("./assets/images/goals_illustrator.png");
export const CalendarIllustrator = require("./assets/images/calendar_illustrator.png");
export const AssignmentIllustrator = require("./assets/images/assignments_illustrator.png");