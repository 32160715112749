import React, { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  styled,
  Paper,
  Grid,
} from "@material-ui/core";
import { dummy_img } from "./asset";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withRouter } from 'react-router-dom';

//@ts-ignore

interface Reward {
   id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}

interface Achievement {
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    created_by: string;
    icon: {
      id: number;
      url: string;
    };
    users: number;
    unstarted: number;
    progress: number;
    earned: number;
    redeemed: number;
  };
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Reward["attributes"];
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "created_by",
    numeric: true,
    disablePadding: false,
    label: "Created by",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "users",
    numeric: true,
    disablePadding: false,
    label: "Users",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Stages",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Reward,
    sortType: string
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  getRewards: (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number
  ) => void;
  isRewardsActive: string;
  getAchievements: (
    params: {
      search: string;
      filter: string;
      page: string;
      per: string;
      property: string;
      sortType: string;
    },
    pagination: number
  ) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof Reward["attributes"], sortType: string) =>
    (event: React.MouseEvent<unknown>) => {
      console.log(property, sortType, "kkkkkkkkkkk");
      const params = {
        search: "",
        filter: "",
        page: "1",
        per: "10",
        property,
        sortType,
      };
      const pagination = 1;
      if (props.isRewardsActive === "reward") {
        props.getRewards(params, pagination);
      } else if (props.isRewardsActive === "achievement") {
        props.getAchievements(params, pagination);
      }
      onRequestSort(event, property as keyof Reward, sortType);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ verticalAlign: "middle" }}
            className={
              headCell.label === "Name"
                ? "table-header-name" 
                : headCell.label === "Stages"
                ? "table-header-stages"
                : "table-header-cell" 
            }
            colSpan={
              headCell.label === "Course" || headCell.label === "Program"
                ? 2
                : 1
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span
                style={{
                  marginLeft: "17px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#1E253C",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "20px",
                  marginBottom: "16px"
                }}
              >
                {headCell.label}
              </span>
              {headCell.label !== "Stages" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4px",
                    alignItems: "center",
                    height: "45px"
                  }}
                >
                  <ExpandLessIcon style={{  cursor: "pointer", color: "black", fontSize: "16px", fontWeight: 700 }} onClick={createSortHandler(headCell.id, "ASC")}/>
                  <ExpandMoreIcon style={{ cursor: "pointer", color: "black", fontSize: "16px",fontWeight: 700 }} onClick={createSortHandler(headCell.id, "DESC")}/>
                </div>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      borderCollapse: "separate",
      borderSpacing: "0 15px",
      "& .MuiTable-root": {
        borderCollapse: "separate",
        borderSpacing: "0 15px",
      },
      "& .MuiTableRow-root": {
        boxShadow: "unset !important",
        borderRadius: "12px !important",
        borderSpacing: "0 15px !important",
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22) !important",
        },
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    statusNew: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#EEE8F2",
      color: "#652786",
    },
    statusDraft: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFEDE4",
      color: "#E47004",
    },
    statusArchived: {
      display: "flex",
      width: "96px",
      height: "40px",
      padding: "6px 9px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "88px",
      background: "#FFD9DF",
      color: "#E70E6B",
    },

    errorInput: {
      borderColor: "red",
      backgroundColor: "#FFF5F5",
    },
    errorText: {
      color: "red",
      marginLeft: " 5px",
      fontSize: "10px",
    },
    successText: {
      color: "#9A5E89",
      marginLeft: " 5px",
    },
    automaticText: {
      borderColor: "#652786",
      marginLeft: " 5px",
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: "30px",
      borderBottom: "1px solid lightgray",
    },
    alignRight: {
      "&:last-child": {
        textAlign: "right",
      },
    },
    paper2: {
      padding: "8px 0px",
      whiteSpace: "nowrap",
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  })
);

 function EnhancedTable(props: any) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Reward>("id");

  const [rows, setRows] = useState<Reward[]>([]);

  const [achievements, setAchievements] = useState<Achievement[]>([]);

  const isRewardsActive = props.isRewardsActive;
  const classes = useStyles();

  console.log(props.params);

  useEffect(() => {
    const fetchRewards = async () => {
      console.log("Fetching rewards data...");
      try {
        console.log("Rewards data fetched:", props.rewardsList);

        setRows(props.rewardsList);
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    };

    const fetchAchievements = async () => {
      console.log("Fetching Achievements data...");
      try {
        console.log("isRewardsActive:", isRewardsActive);
        console.log("Achievements data fetched:", props.achievementsList);

        setAchievements(props.achievementsList);
      } catch (error) {
        console.error("Error fetching Achievements:", error);
      }
    };
    console.log("isRewardsActive changed:", isRewardsActive);
    console.log("Props changed:", props);

    if (isRewardsActive === "reward") {
      fetchRewards();
    } else if (isRewardsActive === "achievement") {
      fetchAchievements();
    }
  }, 
  [ isRewardsActive, props.rewardsList, props.achievementsList]
);
  
  const displayedRows = isRewardsActive === 'achievement' ? achievements : rows;

  const handleRequestSort = (
    event: React.MouseEvent<unknown, MouseEvent>,
    property: keyof Reward,
    sortType: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  
    const sortedRows = [...displayedRows].sort((a, b) => {
      const aValue = (a.attributes as unknown as Reward)[property];
      const bValue = (b.attributes as unknown as Reward)[property];
  
      if (typeof aValue === "undefined" || typeof bValue === "undefined") return 0;
  
      if (sortType === "asc") {
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return aValue < bValue ? -1 : 1;
        }
      } else {
        if (typeof aValue === "string" && typeof bValue === "string") {
          return bValue.localeCompare(aValue);
        } else {
          return aValue > bValue ? -1 : 1;
        }
      }
    });
  
    // if (isRewardsActive === "reward") {
    //   setRows(sortedRows);
    // } else if (isRewardsActive === "achievement") {
    //   setAchievements(sortedRows as Achievement[]);
    // }
  };
  
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    console.log("page hande scroll:");
    const container = event.currentTarget as HTMLDivElement;
    const isAtBottom =
      container.scrollTop + container.offsetHeight >= container.scrollHeight;
    if (isAtBottom) {
      props.handleNextPageNavigation1();
    }
  };

  const handleRewardDetails = (row: number, rowName: string) => {
    isRewardsActive === "reward" ?
      props.history.push({ pathname: "/ManageReward", state: { rewardId: row, rewardName: rowName ,isRewardsActive: "reward" } })
      :
      props.history.push({ pathname: "/ManageAchievement", state: { rewardId: row, rewardName: rowName ,isRewardsActive: "achievement"} })
  };

  return (
    <MainContainer
      className={classes.root}
      style={{ marginTop: "2%" }}
      id="course-program-table"
    >
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          style={{
            borderSpacing: "10px 16px",
            borderCollapse: "separate"
          }}
        >
          <EnhancedTableHead
            getAchievements={props.getAchievements}
            getRewards={props.getRewards}
            isRewardsActive={props.isRewardsActive}
            classes={classes}
            numSelected={0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={() => {}}
            onRequestSort={handleRequestSort}
            rowCount={displayedRows.length}
          />
          <TableBody>
            {displayedRows.map((row, index) => (
              <tr key={index} style={{ boxShadow: '0px -2px 10px 0px #00000011, 0px 2px 10px 0px #00000011', borderRadius: "8px", cursor: 'pointer'}} onClick={() => handleRewardDetails(row.id,row.attributes.name)}>
                <TableCell 
                  style={{
                    boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9',
                    border: "none",
                    padding: "20px",
                  }}
                  padding="checkbox"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        row.attributes.icon && row.attributes.icon.url
                          ? row.attributes.icon.url
                          : dummy_img
                      }
                      style={{
                        borderRadius: "4px",
                        border: " 1px solid #929090",
                        background: " lightgray 50% / cover no-repeat",
                        boxShadow: "0px 2px 8px 0px rgba(6, 1, 1, 0.22)",
                        height: "40px",
                        width: "40px",
                        marginRight: "20px",
                      }}
                    />
                    {row.attributes.name}
                  </div>
                </TableCell>
                <TableCell style={{ border: "none" , boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9',}} align="right">
                  {row.attributes.created_by}
                </TableCell>
                <TableCell style={{ border: "none", boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9' }} align="right">
                  {new Date(row.attributes.created_at)
                    .toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    .replace(/\//g, "-")}
                </TableCell>
                <TableCell style={{ border: "none",  boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9' }} align="right">
                  {row.attributes.users}
                </TableCell>
                <TableCell
                  style={{ border: "none", padding: "20px",  boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9' }}
                  align="right"
                >
                  <Grid container spacing={1} wrap="wrap">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <Paper className="unstartedBox">
                        <div
                        className="stagesData unstarted"
                        >
                          {row.attributes.unstarted}
                        </div>
                        <div
                            className="unstarted stages"
                        >
                          Unstarted
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <Paper className="inProgressBox">
                        <div
                          className="inProgress stagesData"
                        >
                          {row.attributes.progress}
                        </div>
                        <div
                          className="inProgress stages"
                        >
                          Progress
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <Paper className="earnedBox">
                        <div className="earned stagesData">
                          {row.attributes.earned}
                        </div>
                        <div className="earned stages">
                          Earned
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      className={row.type === "achievement_listing"? "rewards":"achievemnt"}
                    >
                      <Paper
                      className={row.type === "achievement_listing"?"redeemedBoxWhite":"redeemedBox"}
                      >
                        <div
                          className={row.type === "achievement_listing"?"colorWhite stagesData":"redeemed stagesData"}
                        >
                          {row.attributes.redeemed}
                        </div>
                        <div
                          className={row.type === "achievement_listing"?"colorWhite stages":"redeemed stages"}
                        >
                          Redeemed
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell
                  style={{ border: "none", textTransform: "capitalize",
                    boxShadow: '0px 0px 0px 0px #00000011, 0px 2px 10px 0px #F9F9F9'
                  }}
                  align="right"
                >
                  <div
                    className={
                      row.attributes.status === "active"
                        ? classes.statusNew
                        : row.attributes.status === "draft"
                        ? classes.statusDraft
                        : classes.statusArchived
                    }
                  >
                    {row.attributes.status}
                  </div>
                </TableCell>
              </tr>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainContainer>
  );
}

const MainContainer = styled("div")({
  position: "relative",
  "& .MuiTableCell-root":{
    fontSize:"16px"
  },
  "& .stages":{
    fontWeight:500,
    fontSize:"10px"
  },
  "& .stagesData": {
    fontWeight: 700,
    fontSize: "18px"
  },
  "& .unstartedBox":{
    padding: "10px",
    backgroundColor: "#FFC5C5",
    textAlign: "center",
    borderRadius: "10px",
    border: "1px solid #FF3737",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    width: "72px !important",
  },
  "& .unstarted":{
    color: "#FF3737",
  },
  "& .inProgressBox":{
    padding: "10px",
    backgroundColor: "#FFD7BE",
    textAlign: "center",
    borderRadius: "10px",
    border: "1px solid #FF9900",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    width: "72px !important",
  },
  "& .inProgress":{
    color: "#FF9900",
  },
  "& .earnedBox":{
    padding: "10px",
    backgroundColor: "#EEE8F2",
    textAlign: "center",
    borderRadius: "10px",
    border: "1px solid #7A288A",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    width: "72px !important",
  },
  "& .earned":{
    color: "#7A288A",
  },
  "& .redeemedBox":{
    padding: "10px",
    backgroundColor:"#C6F4D6",
    textAlign: "center",
    borderRadius: "10px",
    border:"1px solid #8BC34A",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    width: "72px !important",
  },
  "& .redeemedBoxWhite":{
    padding: "10px",
    backgroundColor:"#FFFFFF",
    textAlign: "center",
    borderRadius: "10px",
    border:"1px solid #FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    width: "72px !important",
  },
  "& .redeemed":{
    color: "#333"
  },
  "& .colorWhite": {
    color: "#fff"
  },
  "& .achievemnt": {
  display: "block !important"
  },
  "& .rewards": {
   display: "none !important"
  },
  "& .MuiGrid-container": {
    alignItems: "center",
    justifyContent: "space-around"
  }
});

export default withRouter(EnhancedTable);