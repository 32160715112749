import React from "react";
// Customizable Area Start

import {
    Typography,
    Paper,
    Box,
    FormControlLabel, 
    RadioGroup,
    FormControl,
    Radio,
    TextField,
    InputAdornment,
    MenuItem,
    ListItemText,
    Popper,
    ClickAwayListener
} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withStyles, styled } from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import "./style.css";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import AdminDashboardController, { Props } from "./AdminDashboardController.web";
const img4 = require("../assets/award2.png")
const object = require("../assets/Object.png")
const SignIn = require("../assets/SignIn.png")
const Open = require("../assets/Open.png")
import RemindersWeb from "./Reminders.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialUIBox from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 55,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: 'rgba(138, 80, 183, 0.6)',
    },
    bar: {
        borderRadius: 5,
        background: "linear-gradient(270deg, #6C328B 0%, #41328B 100%)",
    },
}))(LinearProgress);
// Customizable Area End
let id: any;
export default class AdminDashboard extends AdminDashboardController {
    constructor(props: Props) {

        super(props);
        // Customizable Area Start

        // Customizable Area End
    }


    // Customizable Area Start
    renderGroupDropdown() {
        const borderColorStyle = this.state.selectedGroup ? "#929090" : "";
        const borderColor = this.state.dropdownAnchor ? "#652786" : borderColorStyle;

        return (
            <>
            <StyledDropdownClickBoxs
                tabIndex={0}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => this.hanldeDropdown(event, 1)}
                data-test-id="select_group"
                style={{borderColor: borderColor, background: this.handleBackgroundColor(this.state.selectedGroup)}}
            >
                <MaterialUIBox className="contentContainer content_role">
                <MaterialUIBox className="innerContentBox"
                    style={{
                    color: this.handleTextColor(this.state.selectedGroup),
                    background: this.handleBackgroundColor(this.state.selectedGroup), fontFamily: "Poppins", fontWeight: 400, fontSize: "16px"
                    }}>
                    {this.state.selectedGroup ? this.state.selectedGroup.title : "Select from list"}
                </MaterialUIBox>
                </MaterialUIBox>
                {this.state.dropdownAnchor ? <ExpandLess style={{color: "#1C1B1B"}} /> : <ExpandMore style={{color: "#1C1B1B"}} />}
            </StyledDropdownClickBoxs>
            {this.state.dropdownAnchor && (
                <div ref={this.dropdownRef}>
                <ClickAwayListener data-test-id="click_away" onClickAway={() => this.setState({dropdownAnchor: false})}>
                <DropdownGroupContent>
                    <MaterialUIBox className="checkboxList">
                    <RadioGroup
                        unselectable="on"
                        data-test-id="radioGroup"
                        aria-labelledby="group-selection"
                        name="group"
                        value={this.state.selectedGroup?.toFilter}
                    >
                        {this.state.dropdownGroups.map((group: {title: string, toFilter: string}, index: number) => (
                            <FormControlLabel data-test-id={`radio_${index}`} style={{color: this.state.selectedGroup?.title === group.title ? "#652786" : "#1C1B1B", backgroundColor: (this.state.selectedGroup?.title === group.title ? "#EEE8F2" : ""), borderRadius: "4px"}} key={index} name={group.title} className={"checkboxContent"} value={group.toFilter} control={<RadioBtn onClick={(event: any) => this.handleDropdownGroupChange(event)} checked={this.state.selectedGroup?.title === group.title} />} label={group.title} />
                        ))}
                    </RadioGroup>
                    </MaterialUIBox>
                </DropdownGroupContent>
                </ClickAwayListener>
                </div>
            )}
            </>
        )
    }
    
    renderAutoCompleteDropdown() {
        const borderStyle = this.state.selectedOption ? "#929090" : "#E5E2E1";
        const border = `1px solid ${this.state.dropdownTwoAnchor ? "#652786" : borderStyle}`;

        return (
            <FormControl variant="outlined" style={{ width: '100%' }}>
                <DashboardAutocomplete
                    open={this.state.dropdownTwoAnchor}
                    onOpen={() => this.setState({dropdownTwoAnchor: true})}
                    onClose={() => this.setState({dropdownTwoAnchor: false})}
                    disabled={!this.state.selectedGroup}
                    data-test-id="select_option"
                    style={{border: border, background: this.handleBackgroundColor(this.state.selectedOption), color: this.handleTextColor(this.state.selectedGroup)}}
                    onChange={(event, value) => this.handleDropdownTwoChange(value)}
                    onClick={() => 
                        this.setState((prevState) => ({
                          dropdownTwoAnchor: !prevState.dropdownTwoAnchor,
                        }))
                      }
                    PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
                    renderInput={(params) => (
                        <TextField                     
                            style={{width: "100%"}}
                            placeholder={this.state.selectedGroup ? "Select from list" : "Select group first"}
                            {...params}
                            variant="outlined"
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment onClick={() =>
                                        !params.disabled && this.setState((prevState) => ({
                                          dropdownTwoAnchor: !prevState.dropdownTwoAnchor,
                                        }))
                                      }position="end">
                                        {this.state.dropdownTwoAnchor ? <ExpandLess style={{color: "#1C1B1B"}} /> : <ExpandMore style={{color: "#1C1B1B"}} />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    options={this.state.dropdownTwoOptions}
                    getOptionLabel={(option: any) =>  option.name}
                    value={this.state.selectedOption}
                    PaperComponent={(props) => <AutocompletePaper style={{padding: "8px 16px", borderRadius: "16px", boxShadow: "0px 2px 8px 0px #00000026", maxHeight: "166px"}} {...props} elevation={3} />}
                    renderOption={(option: any, { selected }) => (
                        <AssigneeMenuItem
                            selected={selected}
                            key={option.id}
                            value={option.id}
                        >
                            <RadioBtn checked={selected} />
                            <ListItemText style={{background: "none", border: "none", color: selected ? "#652786" : "#1C1B1B"}} primary={option.name} />
                        </AssigneeMenuItem>
                    )}
                    getOptionSelected={(option: any, value: any) => option.id === value?.id}
                />
            </FormControl>
        )
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ fontFamily: 'Montserrat', fontSize: '48px', marginTop: '-24px', lineHeight: '72px', fontWeight: 600, color: '#11142D' }}>Xpand The Lives You Lead</Typography>

                        <Box style={{marginTop: "36px", boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.0677)", padding: "20px", borderRadius: "16px"}}>

                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                        <FormControl variant="standard" className="customInput" style={{width: "100%"}}>
                                            <Typography className="labelStyle" >Group:</Typography>
                                            {this.renderGroupDropdown()}
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box>
                                    <DropdownLabel className="labelStyle">{this.handleLabel()}:</DropdownLabel>
                                        {this.renderAutoCompleteDropdown()}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={3} style={{ marginTop: '40px' }}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: '225px', borderRadius: '16px', background: 'linear-gradient(153.43deg, #6C328B 16.67%, #6C328B 16.67%, #5C328B 44.34%, #41328B 100%)' }}>
                                    <div style={{ paddingTop: '25px', paddingLeft: '25px', display: 'flex', }}>
                                        <img src={object} style={{ cursor: 'pointer' }} />
                                        <Typography style={{ fontFamily: 'Montserrat', color: '#fff', fontSize: '16px', fontWeight: 600, marginTop: '10px', marginLeft: '15px', cursor: 'pointer' }}>Courses Completed</Typography>
                                    </div>
                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#fff', fontSize: '54px', display: 'flex', fontWeight: 700, marginLeft: '40%', marginTop: '6px' }}>{this.state.homeScreenData.complete_count}<Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 400, marginTop: '30px' }}>/{this.state.homeScreenData.total_count}</Typography></Typography>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: '225px', borderRadius: '16px', background: 'linear-gradient(153.43deg, #6C328B 16.67%, #6C328B 16.67%, #5C328B 44.34%, #41328B 100%)' }}>
                                    <div style={{ paddingTop: '25px', paddingLeft: '25px', display: 'flex', }}>
                                        <img src={SignIn} style={{ cursor: 'pointer' }} />
                                        <Typography style={{ fontFamily: 'Montserrat', color: '#fff', fontSize: '16px', fontWeight: 600, marginTop: '10px', marginLeft: '15px', cursor: 'pointer' }}>Sign ins</Typography>
                                    </div>
                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#fff', fontSize: '54px', display: 'flex', fontWeight: 700, marginLeft: '45%', marginTop: '6px' }}>{this.state.homeScreenData.total_signin}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '50px' }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: 'auto', background: '#FFFFFF', boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '50px' }}>Users</Typography></Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Learn</Typography></Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '0px' }}>Reward</Typography></Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '30%' }}>Achievement</Typography></Grid>
                                    </Grid>
                                    <div>
                                        {this.state.homeScreenData?.res?.map((data: any, index: any) => {
                                            return (
                                                <Paper key={data.id} style={{ width: '95%', height: '70px', background: '#FFFFFF', boxShadow: '0px 0px 7px #d6d6d6', borderRadius: '10px', margin: '35px auto' }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                                            <div style={{ display: 'flex' }}>
                                                                <img src={data.image === null ? 'https://picsum.photos/536/354' : data.image} style={{ padding: '7px', marginLeft: '55px', width: '50px', height: '50px', borderRadius: '25px', marginTop: '5px' }} />
                                                                <Typography 
                                                                // onClick={() => this.props.history.push(`/AdminCustomisableUserProfiles/${data.account_id}`)} 
                                                                style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 500, marginTop: '18px', paddingLeft: '20px', textTransform: "capitalize" }}>{data.full_name}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <div style={{ marginTop: '3px' }}>
                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    value={(data.completedcourse_count / data.totalcourse_count) * 100}
                                                                />
                                                                <Typography style={{ fontFamily: 'Montserrat', marginTop: '-40px', marginLeft: '5%', position: 'absolute', color: '#fff' }}>{data.completedcourse_count}/{data.totalcourse_count}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                                            <Grid container spacing={1}>
                                                                <Grid item lg={1} md={1}></Grid>
                                                                <Grid item lg={4} md={4} sm={5} xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}><div style={{ width: '68px', height: '55', background: '#FF8B3F', borderRadius: '8px', marginTop: '3px' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', marginTop: '10px' }}>{data.reward_count}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Started</Typography>
                                                                </div></Grid>
                                                                <Grid item lg={3} md={3} sm={2} xs={2}><div style={{ width: '68px', height: '55', background: '#F42074', borderRadius: '8px', margin: '3px auto' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', paddingTop: '10px' }}>{data.earned_reward}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Earned</Typography>
                                                                </div></Grid>
                                                                <Grid item lg={2} md={2} sm={5} xs={5}><div style={{ width: '68px', height: '55', background: '#6C328B', borderRadius: '8px', marginTop: '3px' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', paddingTop: '10px' }}>{data.approved_reward}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Approved</Typography>
                                                                </div></Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '12px' }}>
                                                                <img src={img4} />
                                                            </div>
                                                            <div style={{ width: '13px', height: '13px', background: 'rgba(108, 50, 139, 1)', display: 'flex', margin: 'auto', borderRadius: '10px', marginTop: '-30px' }}>
                                                                <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '9px', color: '#fff', fontWeight: 600, margin: 'auto' }}>{data.achiv_count}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </Paper>
                                            )
                                        })}
                                    </div>
                                    <Box style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '15px' }}>
                                        <Pagination page={this.state.page} onChange={this.pagination} count={this.state.homeScreenData?.total_pages} shape="rounded" color="primary" />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
  const RadioBtn = styled(Radio)({
    color: "#787776",
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
    '&.Mui-checked': {
      color: "#652786",
    },
  });

  const DropdownLabel = styled(Typography)({
    fontSize: "16px", 
    fontWeight: 400, 
    lineHeight: "24px", 
    color: "#1C1B1B", 
    fontFamily: "'Poppins', sans-serif" 
  });

  const AssigneeMenuItem = styled(MenuItem)({
    width: "100%",
    padding: "0",
    "& .MuiTypography-body1": {
        fontSize: "16px",
        fontWeight: 400,
    },
    "&:hover": {
        background: "none"
    },
    "&.Mui-selected": {
        background: "none !important"
    },
    "& .MuiTouchRipple-root": {
        display: "none"
    }
});

const DashboardAutocomplete = styled(Autocomplete)({
    height: "53px",
    marginTop: "6px",
    borderRadius: "8px",
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
        fontSize: "16px",
        fontWeight: 400,
        padding: "16px 20px !important",
        borderRadius: "8px",
        zIndex: 999,
    },
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot .MuiInputBase-input": {
        padding: "0 !important",
        fontWeight: 400
    },
    "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline": {
        border: `none`,
    },
    "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
});

const StyledDropdownClickBoxs = styled('div')({
    height: "53px",
    marginTop: "6px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px 20px !important",
    border: "1px solid #E5E2E1",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
    "@media (max-width:1000px)": {
        width: "100%",
        height: "52px",
    },
    "&.selectCommonStyle .contentContainer": {
        display: "flex",
        gap: "3px",
        width: "100%"
    },
    "&.selectActive": {
        backgroundColor: "#EEE8F2",
    },
    "&.selectActive .title": {
        color: "#652786"
    },
    "&.creationDate": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .content_role ": {
        width: "100% !important",
        display: "flex !important",
        justifyContent: "space-between !important"
    },
    "& .innerContentBox": {
        display: "flex",
        gap: "3px",
        fontSize: "16px",
        fontWeight: 500
    },
    "& .title": {
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 400,
        color: "#484646",
        fontFamily: "Poppins, sans-serif"
    },
    "& .arrowIconStyle": {
        color: "#652786"
    }
  });

  const DropdownGroupContent = styled(MaterialUIBox)({
    maxHeight: "165px",
    position: "absolute",
    marginTop: "4px",
    zIndex: 9999,
    background: "#fff",
    width: "100%",
    padding: "8px 16px",
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: "12px",
    "& .checkboxList": {
        flexDirection: "column",
        marginTop: "5px",
        gap: "8px",
        display: "flex",
        marginBottom: "5px"
    },
    "& .checkboxContent": {
        display: "flex",
        fontFamily: "Poppins , sans-serif",
        alignItems: "center",
        textTransform: "capitalize",
        borderBottom: "1px solid #E5E2E1 !important",
    }, "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: "16px",
    },
    "& .checkboxContent:hover": {
        borderBottom: "1px solid transparent",
        borderRadius: "4px",
        // backgroundColor: "#F4F0EF",
    },
    "& .selectedStyle": {
        borderBottom: "1px solid transparent !important",
        borderRadius: "8px",
        backgroundColor: "#EEE8F2",
    },
    "& .selectedStyle .MuiTypography-body1": {
        color: "#542070 !important"
    },
    "& .applyBtn": {
        margin: "auto",
        display: "block",
        textTransform: "capitalize",
        fontSize: "16px",
        color: "#542070",
    },
    "& .MuiFormControlLabel-root": {
        borderBottom: "1px solid transparent",
        margin: 0,
        fontFamily: "Poppins, sans-serif"
    },
  });

  const AutocompletePaper = styled(Paper)({
    "& .MuiAutocomplete-option": {
        padding: "0",
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
        background: "#EEE8F2 !important",
        borderRadius: "4px",
        borderBottom: "1px solid #E5E2E1"
    },
    "& .MuiAutocomplete-option[data-focus='true']": {
        background: "none",
    },
    "& .MuiAutocomplete-listbox": {
        maxHeight: "150px",
    }
  });

  const webStyle = {
    tableWrapper: {
      height: '100%', 
      borderRadius: "16px" 
    },
    titleName: {
      fontSize: "22px",
      fontWeight: 600,
    },
    columnName: {
      fontSize: "18px",
      fontWeight: 600,
    },
    textCenter: {
      textAlign: "center"
    },
    noDataFound: {
      fontSize: "20px",
      fontWeight: 600,
    },
    dropDown: {
      color: '#1C1B1B', 
      background: '#fff'
    },
    startEndDateLable: {
      fontSize: "14px", 
      marginBottom: "6px"
    }
  };

// Customizable Area End