// Customizable Area Start
import * as yup from "yup";
import { toast } from "react-toastify";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
const MAX_QUESTIONS_COUNT = 20;

const initialSchema = {
  phase: yup.number().required("Phase is required"),
  quizName: yup.string().required("Quiz name is required"),
  numOfQuestions: yup
    .number()
    .min(1, "You must create at least one question")
    .max(
      MAX_QUESTIONS_COUNT,
      `You can create a maximum of ${MAX_QUESTIONS_COUNT} questions`
    )
    .typeError("Number of questions must be a number")
    .required("Number of questions are required"),
  quizDuration: yup
    .number()
    .min(1, "You must run the quiz for at least one minute")
    .typeError("Quiz duration must be a number"),
  passPercentage: yup
    .number()
    .min(1, "Pass percentage must be greater than 0")
    .max(100, "Pass percentage must be less than or equal to 100")
    .typeError("Pass percentage must be a number")
    .required("Pass percentage is required"),
  questions: yup.array().of(
    yup.object().shape({
      text: yup.string().required("Question is required"),
      questionType: yup.string().required("Question type is required"),
      options: yup.array().of(
        yup.object().shape({
          isCorrect: yup.string().required("Response is required"),
        })
      ),
    })
  ),
};

const mcqSchema = {
  phase: yup.number().required("Phase is required"),
  quizName: yup.string().required("Quiz name is required"),
  numOfQuestions: yup
    .number()
    .min(1, "You must create at least one question")
    .max(
      MAX_QUESTIONS_COUNT,
      `You can create a maximum of ${MAX_QUESTIONS_COUNT} questions`
    )
    .typeError("Number of questions must be a number")
    .required("Number of questions are required"),
  quizDuration: yup
    .number()
    .min(1, "You must run the quiz for at least one minute")
    .typeError("Quiz duration must be a number"),
  passPercentage: yup
    .number()
    .min(1, "Pass percentage must be greater than 0")
    .max(100, "Pass percentage must be less than or equal to 100")
    .typeError("Pass percentage must be a number")
    .required("Pass percentage is required"),
  questions: yup.array().of(
    yup.object().shape({
      text: yup.string().required("Question is required"),
      questionType: yup.string().required("Question type is required"),
      options: yup.array().of(
        yup.object().shape({
          text: yup.string().required("Option is required"),
          isCorrect: yup.string().required("Response is required"),
        })
      ),
    })
  ),
};

export interface Props {
  id: string;
  history: any;
  classes: any;
  location: any;
  navigation: any;
}

interface S {
  quiz: {
    id: string;
    courseId: string;
    quizName: string;
    phase: number;
    quizDuration: string;
    passPercentage: number;
    questions: any;
    shuffleQuestions: boolean;
    editQuiz: boolean;
    deletedBulkQuestions: boolean;
  };
  validationSchema: any;
}

interface SS {
  id: any;
}

interface Question {
  type: "mcq" | "tnf" | "ynn";
  id: number;
  isActive: "string";
  text: string;
  options: any;
}

interface Error {
  message: string;
}

const initialQuiz = {
  quizName: "",
  phase: 1,
  quizDuration: "",
  passPercentage: 1,
  numOfQuestions: 0,
  questions: [],
  shuffleQuestions: false,
  editQuiz: false,
  deletedBulkQuestions: false,
  failTest: "no",
  showStats: "yes",
  bulkAction: "",
  selectedQuestionIds: [],
};

export default class QuizController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      quiz: {
        ...initialQuiz,
        id: props.navigation.getParam("id"),
        courseId: props.location.state?.courseId,
      },
      validationSchema: yup.object(mcqSchema),
    };
  }

  // Api call ids
  createQuizId: string = "";
  updateQuizId: string = "";
  getQuizDetailsId: string = "";
  bulkDeleteQuestionsId: string = "";

  async componentDidMount() {
    const { quiz } = this.state;
    // Show error if course ID is missing
    if (!quiz.courseId) {
      toast.error("Something went wrong. Course ID not found");
      return;
    }
    // Get quiz details for editing quiz
    if (quiz.id) {
      this.getQuizDetails(quiz.id);
    }
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, payload } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Get quiz details
  getQuizDetails = async (id: string) => {
    const quizId = parseInt(id);
    if (isNaN(quizId) || quizId < 1) {
      toast.error("Something went wrong");
      return;
    }
    const { applicationJson, httpGetMethod, quizUrl } = configJSON;

    this.getQuizDetailsId = await this.apiCall({
      contentType: applicationJson,
      method: httpGetMethod,
      endPoint: `${quizUrl}/${id}`,
    });
  };

  // Handle quiz details response
  handleGetQuizDetails = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.errors) {
      const {
        data: {
          attributes: {
            duration,
            pass_percentage,
            phase,
            questions,
            shuffle_questions,
            title,
            quize_questions_count,
            passing_score,
            stats,
          },
        },
      } = responseJson;
      // Modify response accroding to our state
      const curQuestions = questions.map(
        ({ id, attributes }: any, qsIndex: number) => ({
          id,
          questionType: attributes.question_type,
          isActive: attributes.is_active ? "yes" : "no",
          text: attributes.question,
          isSaved: true,
          options: attributes.options.map(
            (option: any, opIndex: number) => ({
              id: option.id,
              label: `Answer ${String.fromCharCode(opIndex + 65)}`,
              placeholder:
                attributes.question_type === "MCQ"
                  ? `Type your option ${String.fromCharCode(
                      opIndex + 65
                    )} for the answer here...`
                  : "",
              text: option.title,
              isSelected: option.is_selected,
              displayAnsGraphic: option.display_ans_graphic,
              isCorrect: option.is_correct ? "yes" : "no",
            })
          ),
        })
      );
      // Save question ids to localstorage
      const questionIds = curQuestions.map((question: any) => question.id);
      localStorage.setItem("question_ids", JSON.stringify(questionIds));

      // Update form with quiz data
      this.setState((curState: any) => ({
        ...curState,
        quiz: {
          ...curState.quiz,
          quizName: title,
          phase,
          quizDuration: duration,
          passPercentage: pass_percentage,
          questions: curQuestions,
          shuffleQuestions: shuffle_questions,
          numOfQuestions: quize_questions_count,
          failTest: passing_score ? "yes" : "no",
          showStats: stats ? "yes" : "no",
        },
      }));
    } else if (responseJson && responseJson.errors) {
      toast.error("Something went wrong");
    } else if (errorResponse) {
      toast.error(errorResponse);
    }
  }

  // Update form validation schema based on question type
  updateValidationSchemaForMcq = (questionType: string) => {
    if (questionType === "MCQ") {
      this.setState({
        validationSchema: yup.object(mcqSchema),
      });
    } else {
      this.setState({
        validationSchema: yup.object(initialSchema),
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.getQuizDetailsId === apiRequestCallId) {
        // Get quiz details
        this.handleGetQuizDetails(responseJson, errorResponse);
      } else if (this.bulkDeleteQuestionsId === apiRequestCallId) {
        // Bulk delete questions
        this.handleBulkDeleteQuestions(responseJson, errorResponse); 
      }
    }
  }

  // Generate options according to question type
  generateOptions = (questionType: string) => {
    if (questionType === "MCQ") {
      return Array.from(new Array(4)).map((_, i) => ({
        id: "",
        label: `Answer ${String.fromCharCode(i + 65)}`,
        placeholder: `Type your option ${String.fromCharCode(i + 65)} here...`,
        text: "",
        isSelected: false,
        displayAnsGraphic: false,
        isCorrect: "",
      }));
    } else if (questionType === "TnF") {
      return Array.from(new Array(2)).map((_, i) => ({
        id: "",
        label: `Answer ${String.fromCharCode(i + 65)}`,
        placeholder: "",
        text: i % 2 === 0 ? "True" : "False",
        isSelected: true,
        displayAnsGraphic: false,
        isCorrect: "",
      }));
    } else {
      return Array.from(new Array(2)).map((_, i) => ({
        id: "",
        label: `Answer ${String.fromCharCode(i + 65)}`,
        placeholder: "",
        text: i % 2 === 0 ? "Yes" : "No",
        isSelected: true,
        displayAnsGraphic: false,
        isCorrect: "",
      }));
    }
  };

  handleAddQuestion = (curValues: any, setValues: any) => {
    // Check for max No. of questions
    if (curValues.questions.length === MAX_QUESTIONS_COUNT) {
      toast.error(
        `You can create a maximum of ${MAX_QUESTIONS_COUNT} questions`
      );
      return;
    }
    // When user created exactly n no. of questions stated in the num of questions field
    if (curValues.questions.length === parseInt(curValues.numOfQuestions)) {
      toast.error("Please increase the number of questions");
      return;
    }
    // Update validation schema for MCQ question
    this.updateValidationSchemaForMcq("MCQ");
    // Add a new MCQ type question to the form values
    const question = {
      id: "",
      questionType: "MCQ",
      isActive: "yes",
      text: "",
      isSaved: false,
      options: this.generateOptions("MCQ"),
    };
    const updatedValues = {
      ...curValues,
      questions: [...curValues.questions, question],
    };
    setValues(updatedValues);
  };

  handleEditQuestion = (curValues: any, index: number, setValues: any) => {
    const questions = [...curValues.questions];
    questions[index].isSaved = false;
    setValues({
      ...curValues,
      questions,
    });
  };

  handleQuestionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    curValues: any,
    questionIndex: number,
    setValues: any
  ) => {
    // Update validation schema for new question type
    const newQuestionType = (event.target as HTMLInputElement).value;
    this.updateValidationSchemaForMcq(newQuestionType);
    // Generate appropriate options & update state
    const questions = [...curValues.questions];
    questions[questionIndex].questionType = newQuestionType;
    questions[questionIndex].options = this.generateOptions(newQuestionType);
    setValues({
      ...curValues,
      questions,
    });
  };

  handleAddMcqOption = (
    questionIndex: number,
    curValues: any,
    setValues: any
  ) => {
    // Calculate current number of options
    const curQuestion: Question = curValues.questions[questionIndex];
    const curNumOfOptions = curQuestion.options.length;
    if (curNumOfOptions === 10) {
      toast.error("Maximum 10 options can be added.");
    } else {
      // Create a new option
      const newOption = {
        id: "",
        displayAnsGraphic: false,
        isCorrect: "",
        isSelected: false,
        label: `Answer ${String.fromCharCode(curNumOfOptions + 65)}`,
        placeholder: `Type your option ${String.fromCharCode(
          curNumOfOptions + 65
        )} for the answer here...`,
        text: "",
      };
      // Append the new option to the existing ones & update values
      const updatedQuestions = curValues.questions;
      const curOptions = updatedQuestions[questionIndex].options;
      const updatedOptions = [...curOptions, newOption];
      updatedQuestions[questionIndex].options = updatedOptions;
      setValues({
        ...curValues,
        questions: updatedQuestions,
      });
    }
  };

  handleCancelSavingQuestion = (
    index: number,
    curValues: any,
    setValues: any
  ) => {
    if (curValues.questions[index].id) {
      // If id exists, the questions is saved, we are editing it
      const questions = [...curValues.questions];
      questions[index].isSaved = true;
      setValues({
        ...curValues,
        questions,
      });
      return;
    }
    // Id doens't exists, this question is not saved yet
    const questions = [...curValues.questions];
    questions.pop();
    setValues({
      ...curValues,
      questions,
    });
  };

  handleRemoveOptionsForMcqQuestions = (
    questionInd: number,
    optionInd: number,
    curValues: any,
    setValues: any
  ) => {
    const questions = [...curValues.questions];
    const question = questions[questionInd];
    const options = [...question.options];
    // Notify users the minimum options requirement
    if (options.length === 4) {
      toast.error("MCQ questions must have minimum 4 options");
      return;
    }
    // If id of a question exists, this question is saved
    if (question.id && options.length > 4) {
      if (questions[questionInd].options[optionInd].id) {
        // If option's id exists, the option is saved
        questions[questionInd].options[optionInd]._destroy = true;
        setValues({
          ...curValues,
          questions,
        });
      } else {
        // This options is not saved yet, just remove it
        const filteredOptions = options.filter(
          (_: any, index: number) => index !== optionInd
        );
        questions[questionInd].options = filteredOptions;
        setValues({
          ...curValues,
          questions,
        });
      }
      return;
    }
    // If id of a question doesn't exist, this question is not saved
    if (!question.id && options.length > 4) {
      // Remove the option
      const filteredOptions = options.filter(
        (_: any, index: number) => index !== optionInd
      );
      questions[questionInd].options = filteredOptions;
      setValues({
        ...curValues,
        questions,
      });
    }
  };

  // Handle bulk delete questions
  handleBulkDeleteQuestions = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState((curState: any) => ({
        ...curState,
        quiz: {
          ...curState.quiz,
          deletedBulkQuestions: true,
        },
      }));
    } else if (responseJson && responseJson.errors) {
      toast.error("Something went wrong");
    } else if (errorResponse) {
      toast.error(errorResponse);
    }
  }
}

// Customizable Area End
