export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Caret = require("../assets/caret.svg");
export const ArrowDown = require("../assets/arrowdown.svg");
export const uploadIcon = require("../assets/Shape.svg");
export const libraryIcon = require("../assets/libraryIcon.svg");
export const course = require("../assets/Teacher.png");
export const program = require("../assets/system-uicons_document.png");
export const Filter = require("../assets/filter.svg");
export const NotFoundImage = require("../assets/NotFoundImage.jpeg")
export const addContentImg = require("../assets/image_select.png");
export const imageHtmlCourse2 = require("../assets/image_html_course2.png")
export const rightArrow = require("../assets/right_arrow.png")
