// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";
import { NEW_COURSES, NEW_REWARD, NEW_ACHIEVEMENT } from "./components/reminderTypes";

interface Reminder {
  attributes: {
    app_url: number;
    courseable_id: number;
    courseable_type: string;
    due_date: string;
    due_in: number;
    full_date: string;
    popup_type: string;
    title: string;
    video_count: string;
  }
}

const configJSON = require("./config.js");
const toastOptions: any = {
  position: "top-left"
}

export interface Props {
  navigation: any;
  fullScreen: boolean;
  classes?: any;
  userRole: string;
}

interface S {
  isReminderOpen: boolean;
  reminders: Reminder[];
  reminderIndex: number;
  isDismissing: boolean;
}

interface SS {
  id: any;
}

export default class RemindersController extends BlockComponent<Props, S, SS> {
  apiGetRemindersCallId: string | Message = "";
  apiGetAdminRemindersCallId: string |Message = "";
  apiPostDismissRemindersCallId: string | Message = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isReminderOpen: true,
      reminders: [],
      reminderIndex: 0,
      isDismissing: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (this.props.userRole === "super_admin") {
      this.getAdminRemindersData();
    } else if (this.props.userRole === "user") {
      this.getRemindersData();
    }
    this.handleOpenReminders();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.apiGetRemindersCallId:
          this.handleGetRemindersResponse(responseJson);
          break;
        case this.apiPostDismissRemindersCallId:
          this.handleDismissRemindersResponse(responseJson);
          break;
        case this.apiGetAdminRemindersCallId:
          this.handleGetAdminRemindersResponse(responseJson);
          break;
        default:
          break;
      }
    }
  }

  // Common method for api calls
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, payload } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Get reminders
  getRemindersData = async () => {
    this.apiGetRemindersCallId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.remindersGetUrl,
    });
  }

  // Handle get reminders response
  handleGetRemindersResponse = (response: any) => {
    if (!response?.popup) {
      toast.error("Something went wrong", toastOptions);
      return;
    }
    // Save reminders to state
    const newReminders = response.popup.filter(
      (r: any) =>
        r.attributes.popup_type !== NEW_REWARD &&
        r.attributes.popup_type !== NEW_ACHIEVEMENT
    );
    this.setState({
      reminders: newReminders
    });
  }

  // Get admin reminders
  getAdminRemindersData = async () => {
    this.apiGetAdminRemindersCallId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.adminRemindersGetUrl,
    });
  }

  // Handle get reminders response
  handleGetAdminRemindersResponse = (response: any) => {
    if (!Array.isArray(response)) {
      toast.error("Something went wrong", toastOptions);
      return;
    }
    const newReminders = response.map((reminder: any) => reminder.attributes);
    this.setState({
      reminders: newReminders
    });
  }

  // Open reminders
  handleOpenReminders = () => {
    if (this.state.reminders.length > 0) {
      this.setState({ isReminderOpen: true });
    }
  }

  // Close reminders
  handleCloseReminders = () => {
    this.setState({ isReminderOpen: false });
  }

  // Dismiss reminders
  handleDismissReminders = (
    curReminder: Pick<
      Reminder["attributes"],
      "courseable_id" | "courseable_type" | "popup_type"
    >
  ) => {
    // Start the loader for dismiss button
    this.setState({ isDismissing: true });
    // Call api for dismiss
    const { courseable_id, courseable_type } = curReminder;
    const payload = {
      courseable_id,
      courseable_type,
      reminder: false,
      is_new_courses: curReminder.popup_type === NEW_COURSES ? true : false,
    };
    this.postDismissReminders(payload);
  };

  // Dismiss reminders
  postDismissReminders = async (
    payload: Pick<Reminder["attributes"], "courseable_id" | "courseable_type">
  ) => {
    this.apiPostDismissRemindersCallId = await this.apiCall({
      contentType: configJSON.dashboardContentType,
      method: configJSON.postApiMethodType,
      endPoint: configJSON.dismissRemindersPostUrl,
      payload: JSON.stringify(payload),
    });
  };

  // Handle dismiss reminders response
  handleDismissRemindersResponse = (response: any) => {
    if (!response?.success) {
      this.setState({ isDismissing: false });
      toast.error("Something went wrong", toastOptions);
      return;
    }
    toast.success("Dismissed successfully", toastOptions);
    // Go to next reminder
    this.setState(
      {
        reminderIndex: this.state.reminderIndex + 1,
        isDismissing: false,
      }
    );
  };
}
// Customizable Area End