import React from "react";
// Customizable Area Start
import UserSettingsDialogWeb from "./UserSettingsDialog.web";

// Customizable Area End

import Settings5Controller, { Props } from "./Settings5Controller.web";

export default class Settings5 extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      localStorage.getItem("user_role") === "user" && (
        <UserSettingsDialogWeb
          open={this.props.open}
          handleSettingsClose={this.props.handleSettingsClose}
          faqs={this.state.faqs}
          getFaqs={this.getFAQs}
          getNotificationSettings={this.getNotificationSettings}
          isLoading={this.state.isLoading}
          resetState={this.resetState}
          receivedNotificationSettings={this.state.receivedNotificationSettings}
          notificationSettings={this.state.notificationSettings}
          updateNotificationSettings={this.updateIndividualNotificationSettings}
          deactivateAccount={this.deactivateAccount}
          handleLogout={this.props.handleLogout}
          accountDeactivated={this.state.accountDeactivated}
        />
      )
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
