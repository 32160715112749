import React from "react";
import clsx from "clsx";
import { Button, Container, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { NotFoundImg } from "./assets";

function NotFoundPage({ history }: RouteComponentProps) {
  const classes = useStyles();

  const handleHomeClick = () => {
    history.push(localStorage.getItem("user_role") === "user" ? "/" : "/Home");
  }

  return (
    <Container maxWidth="sm">
      <h1 className={classes.title}>Page not found</h1>
      <img src={NotFoundImg} className={classes.img} alt="not found" />
      <Grid container spacing={2}>
        <Grid item sm xs={12}>
          <Button
            fullWidth
            variant="outlined"
            className={clsx(classes.btn, classes.outlinedBtn)}
            onClick={handleHomeClick}
          >
            Back to Home
          </Button>
        </Grid>
        <Grid item sm xs={12}>
          <Button
            fullWidth
            variant="contained"
            className={clsx(classes.btn, classes.containedBtn)}
            onClick={() => window.location.reload()}
          >
            Try Again
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withRouter(NotFoundPage);

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: "#000",
      fontSize: 40,
      fontWeight: 700,
      textAlign: "center"
    },
    img: {
      width: "100%",
      marginBottom: 80
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    btn: {
      borderRadius: "16px",
      padding: "15px",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center"
    },
    containedBtn: {
      background:
        "linear-gradient(223deg, #6C328B 0%, #EF2B4B 100%), linear-gradient(223deg, #6C328B 0%, #EF2B4B 100%)",
      boxShadow: "0px 3px 12px 0px rgba(108, 50, 139, 0.30)",
      color: "#FAFAFA"
    },
    outlinedBtn: {
      border: "2px solid #6C328B",
      boxShadow: "0px -10px 12px 0px rgba(0, 0, 0, 0.16)",
      color: "#6C328B"
    }
  })
);
