import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { notesIcon, NotifyIcon, RewardIcon, AchieventIcon } from "../assets";
import {
  NOTE,
  EARNED_REWARD,
  EARNED_ACHIEVEMENT,
  EARNED_REWARD_ADMIN,
  EARNED_ACHIEVEMENT_ADMIN,
} from "../notificationTypes.web";

interface IIcon {
  type: string;
}

export default function Icon({ type }: IIcon) {
  const classes = useStyles();
  let icon;

  switch (type) {
    case NOTE:
      icon = (
        <img src={notesIcon} className={classes.iconStyle} alt="notes icon" />
      );
      break;
    case EARNED_REWARD:
    case EARNED_REWARD_ADMIN:
      icon = (
        <img src={RewardIcon} className={classes.iconStyle} alt="reward icon" />
      );
      break;
    case EARNED_ACHIEVEMENT:
    case EARNED_ACHIEVEMENT_ADMIN:
      icon = (
        <img
          src={AchieventIcon}
          className={classes.iconStyle}
          alt="achievement icon"
        />
      );
      break;
    default:
      icon = (
        <img
          src={NotifyIcon}
          className={classes.iconStyle}
          alt="notification icon"
        />
      );
      break;
  }

  return <div className={classes.iconContainer}>{icon}</div>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      width: "20%",
    },
    iconStyle: {
      width: "80px",
      height: "80px",
      minWidth: "80px",
      borderRadius: "15px",
    },
  })
);
