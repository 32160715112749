// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

// Customizable Area Start


interface PaginationResponse {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

export interface Library {
  id: number;
  type: string;
  attributes: {
    id: number;
    status: string;
    title: string;
    thumbnail_image:{
      id:string;
      url:string;
    }
    expand_type: string;
  }
}

export interface CheckboxSelectedListType { value: string, id: string }

export interface AdminContentRoles {
  id: string;
  type: string;
  attributes:
  {
    id: number;
    uniq_id: string;
    name: string;
    title: string;
    description: string;
    status: string;
    is_sellable: boolean;
    price: string;
    expert_id: number;
    expert_type: string;
    created_at: string;
    subscription_period: string;
    is_selected: boolean;
    thumbnail_image: null;
  };
}
export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name:string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}

export interface AttributesType {
  id: number;
  name: string
  title: string
  uniq_id: string;
  lessons_count: number;
  status: string;
  updated_at: string;
  is_selected: boolean;
}
export interface ContentRolesType {
  id: string,
  type: string,
  attributes: AttributesType;
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface AdminCatagoryListing {
  id: string,
  type: string,
  attributes: {
    name: string,
  }
}

interface ContentDetailResponse {
  id: string;
  type: string;
  attributes: Attributes2;
}

interface Attributes2 {
  id: number;
  uniq_id: string;
  name: string;
  description: string;
  status: string;
  expert_id: number;
  expert_type: string;
  created_at: string;
  updated_at: string;
  is_selected: boolean;
  lessons_count: number;
  course_content_roles: Coursecontentrole[];
}

interface Coursecontentrole {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  courseable_id: number;
  courseable_type: string;
  content_role_id: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}
interface S {
  isCourseActive: string;
  filterItems: boolean;
  searchInputValue: string;
  showList: boolean;
  filteredList: Array<string>;
  anchorFilterContainerEl: null | HTMLElement;
  creationDate: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  libraryData: Library[];
  programData: Library[];
  selectedContent: Library[];
  text: string;
  selectedContentProgramData: Library[],
  categoryId: any[];
  checkedDataProgram: boolean
  pagination: Pagination;
  contentRoleName: string;
  description: string;
  contentRoleError: boolean;
  contentRoleErrorText: string;
  descriptionError: boolean;
  descriptionErrorText: string;
  removedData: Library[];
  removedProgramData: Library[];
  contentType: string;
  removedAllData: Library[];
  removedAllDataProgram: Library[];
  contentRoleResponse: any;
  placeholderSelectedItems: CheckboxSelectedListType[];
  categorySelectedItems: CheckboxSelectedListType[];
  contentRoleSelectedItems: CheckboxSelectedListType[];
  selectedStatus: string;
  anchorEl: null | HTMLElement;
  contentRolesParams: string;
  contentRoleSearchText: string;
  categorySearchText: string;
  dropdownType: string;
  placeholderSearchText: string;
contentRolesData:Array<ContentRolesType>
  expertAdminList: Array<ExpertListing>;
  adminCatagoryList: Array<AdminCatagoryListing>;
  adminContentRolesList: Array<AdminContentRoles>;
  isCalendarOpen: boolean;
  placeholderParams: string;
  catagoryParams: string;
  changeBorder:boolean;
  editContentId: number;
  initialAssignedProgramIds: number[];
  initialAssignedCourseIds: number[];
  isEditCourseIdsSet: boolean;
  isEditProgramIdsSet: boolean;
  intitailAssignedCourseProgramList: Coursecontentrole[]
  isContentRoleAlreadyActive: boolean;
  isDupliactingContentRole:boolean;
  saveContentModal: boolean;
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCourseListDataCallId: string = "";
  getProgramListDataCallId: string = "";
  postCreateRoleDraftAndCreateCallId: string = "";
  adminsCatagoryListAPICallId: string = "";
  getCatalogueAdvanceSearchApiCallId: string = "";
  expertAdminListingAPICallId: string = "";
contentRolesApiCallId:string = "";
  getContentRoleDataCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationCustomFormMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCourseActive: "course",
      showList: false,
      filterItems: true,
      searchInputValue: '',
      contentType: "course",
      filteredList: [],
      removedAllData: [],
      removedAllDataProgram: [],
      anchorFilterContainerEl: null,
      creationDate: '',
      contentRoleName: "",
      description: "",
      contentRoleError: false,
      contentRoleErrorText: "",
      descriptionError: false,
      descriptionErrorText: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      libraryData: [],
      programData: [],
      removedData: [],
      removedProgramData: [],
      selectedContent: [],
      selectedContentProgramData: [],
      contentRolesParams: "",
      contentRoleSearchText: "",
      checkedDataProgram: false,
      contentRoleResponse: {},
      placeholderSelectedItems: [],
      contentRolesData:[],
      categorySelectedItems: [],
      contentRoleSelectedItems: [],
      selectedStatus: "",
      anchorEl: null,
      categorySearchText: "",
      dropdownType: "",
      placeholderSearchText: "",
      expertAdminList: [],
      adminCatagoryList: [],
      adminContentRolesList: [],
      categoryId: [],
      isCalendarOpen: false,
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      placeholderParams: "",
      catagoryParams: "",
      text: "",
      changeBorder:false,
      editContentId: this.props.navigation?.getParam('id'),
      initialAssignedProgramIds: [],
      initialAssignedCourseIds: [],
      isEditCourseIdsSet: true,
      isEditProgramIdsSet: true,
      intitailAssignedCourseProgramList: [],
      isContentRoleAlreadyActive: false,
      isDupliactingContentRole:false,
      saveContentModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    if(this.state.editContentId) {
      this.getContentRoleDetail()
    }
    
    this.getCourseList({ search: "", filter: "course", page: "", per: "", }, this.state.pagination.curPage);
    this.getProgramList({ search: "", filter: "program", page: "", per: "", }, this.state.pagination.curPage);
    this.getAdminCategoryListing()
    this.getCatalogueAdvanceSearch("", 'course')
    this.getAdminExpertListing();
    this.getAdminContentRolesListing();


  }

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      

      if (this.getProgramListDataCallId === apiRequestCallId) {
        this.setState({ programData: responseJson?.data })
      }

      if (this.getCatalogueAdvanceSearchApiCallId === apiRequestCallId) {

        this.setState({
          filteredList: responseJson?.data
        })
       
      }

      if (this.expertAdminListingAPICallId === apiRequestCallId) {
      
        this.setState({
          expertAdminList: responseJson.data
        })
      }

      if (this.postCreateRoleDraftAndCreateCallId === apiRequestCallId) {
        this.setState({ contentRoleResponse: responseJson?.data })
         this.draftCreateApiResponse(responseJson)
      }
      this.contentRoleApi(from, message)

      this.getContentRoleDetailResponse(from , message)
    }

    if (getName(MessageEnum.NavigationCustomFormMessage) === message.id) {
      const navigationProps = message.getData(
        getName(MessageEnum.NavigationCustomFormMessage)
      );

      if (navigationProps.isDuplicateConentRole) {
        this.setState({ isDupliactingContentRole: navigationProps.isDuplicateConentRole }, () => {
          this.duplicateContentRole(navigationProps.id)
        })
      }
    }
    // Customizable Area End
  }

  getContentRoleDetailResponse = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (this.getContentRoleDataCallId === apiRequestCallId && responseJson) {
        let courseIds = [];
        let programIds = [];
        const courseType ="BxBlockCoursecreation::Course";
        const programType = "BxBlockCoursecreation::Program";

        courseIds = responseJson.data?.attributes?.course_content_roles.filter((role:Coursecontentrole)=> role.attributes.courseable_type === courseType).map((role:Coursecontentrole) => role.attributes.courseable_id)
        programIds = responseJson.data?.attributes?.course_content_roles.filter((role:Coursecontentrole)=> role.attributes.courseable_type === programType).map((role:Coursecontentrole) => role.attributes.courseable_id)
        const isContentRoleActive = responseJson.data?.attributes?.status === "active";
        this.setState({ 
          contentRoleName: responseJson.data?.attributes.name, 
          description: responseJson.data?.attributes?.description, 
          initialAssignedCourseIds: courseIds, 
          initialAssignedProgramIds: programIds,
          intitailAssignedCourseProgramList:responseJson.data?.attributes?.course_content_roles,
          isContentRoleAlreadyActive: isContentRoleActive 
        })

        if(this.state.isDupliactingContentRole){
          this.setState({editContentId:responseJson.data?.attributes.id})
        }
      }
    }
  }
  
  async duplicateContentRole (id:string){
    const token = await localStorage.getItem("token")
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.duplicateConentRoleApiEndPoint}${id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  draftCreateApiResponse = (responseJson:{data?:ContentRolesType,errors?:{ message: string }[]}) => {
    if (responseJson?.data?.attributes?.status === "draft") {
      toast.success(`${this.state.contentRoleName} has been saved to draft`);
      const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
      toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
      toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(toNavigate);
    } else if (responseJson?.data?.attributes?.status === "active") {
      toast.success(`${this.state.contentRoleName} ${this.state.editContentId && !this.state.isDupliactingContentRole ? "has been updated" : "has been created"}`);
      const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
      toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "ContentManagement");
      toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(toNavigate);
    } else if (responseJson?.errors) {
      responseJson.errors.map((errors: { message: string }) => {
        return (
          toast.error(errors.message)
        )

      })
    }
  }

  setCourseDataInAddedList = () => {
    const selectedCourseList = this.state.libraryData.filter((courseData) => this.state.initialAssignedCourseIds.includes(Number((courseData.id))));
    this.setState({ removedAllData: [...this.state.removedAllData, ...selectedCourseList] || [], isEditCourseIdsSet: false });
  }

  setProgramDataInAddedList = () => {
    const selectedProgramList = this.state.programData.filter(programData => this.state.initialAssignedProgramIds.includes(Number((programData.id))));
    this.setState({ removedAllData: [...this.state.removedAllData, ...selectedProgramList] || [] , isEditProgramIdsSet: false});
  }

  contentRoleApi = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
    if (this.adminsCatagoryListAPICallId === apiRequestCallId) {
      this.setState({ adminCatagoryList: responseJson?.data })
    }
    if (this.getCourseListDataCallId === apiRequestCallId) {
        this.setState({ libraryData: responseJson?.data || [] }, () => {
          this.setCourseListForEditContentRole()
        })
    }

    if (this.getProgramListDataCallId === apiRequestCallId) {
        this.setState({ programData: responseJson?.data || [] }, () => {
          this.setProgramListForEditContentRole()
        })
     }

    if (this.contentRolesApiCallId === apiRequestCallId) {
      this.setState({
        contentRolesData: responseJson.data,
        adminContentRolesList: responseJson.data
      })
      
    }
    
  }
  }

  setCourseListForEditContentRole = () => {
    if(this.state.isEditCourseIdsSet) {
      this.setCourseDataInAddedList();
    }
  }

  setProgramListForEditContentRole = () => {
    if(this.state.isEditProgramIdsSet) {
      this.setProgramDataInAddedList();
    }
  }

  handleToggleCourses = () => {
    this.setState({
      isCourseActive: "course",
      searchInputValue:'',
    },()=>{
      this.getCourseList({
        search: this.state.searchInputValue,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1);
    })
  };

  handleTogglePrograms = () => {
    this.setState({
      isCourseActive: "program",
      searchInputValue:'',
    },()=>{
      this.getProgramList({
        search: this.state.searchInputValue,
        filter: this.state.isCourseActive,
        page: "",
        per: "",
      }, 1)
      
    })
  };
  
  handleListItemClickSuggestion = (item: string) => {
    this.setState({
      searchInputValue: item,
      showList: false
    }, () => {
      this.getCourseList({search: this.state.searchInputValue,filter: this.state.isCourseActive,page: "",per: "",}, 1)
      this.getProgramList({search: this.state.searchInputValue,filter: this.state.isCourseActive,page: "",per: "",}, 1)
    })
  }

  saveAsDraft = (status: string) => {
    if (!this.state.contentRoleName) {
      this.setState({ contentRoleError: true, contentRoleErrorText: "Name is Required!!" })

    } else if (this.state.contentRoleName.length > 150) {
      this.setState({ contentRoleError: true, contentRoleErrorText: "name length should be less than 150" })

    } else if (this.state.description.length > 1500) {
      this.setState({ descriptionError: true, descriptionErrorText: "description length should be less than 1500" })
    }
    this.contentRoleDraftOrCreateApi(status)
  }

  openSavePromptDialogue = () => {
    this.setState({ saveContentModal: true })
  }

  closeSavePromptDialogue = () => {
    this.setState({ saveContentModal: false })
  }

  handleSaveContent = () => {
    this.closeSavePromptDialogue();
    this.saveAsDraft("active")
  }


  contentRoleCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        contentRoleSelectedItems: [...prevState.contentRoleSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        contentRoleSelectedItems: prevState.contentRoleSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.contentRoleSelectedItems.map((item: { value: string, id: string }) => `q[content_role_ids][]=${item.id}`).join("&");
        this.setState({
          contentRolesParams: params
        });
      });
    }
  }

  handleContentRoleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contentRoleSearchText: event.target.value
    })
  }

  placeholderCheckboxAssignedExpertChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        placeholderSelectedItems: [...prevState.placeholderSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    } else {
      this.setState(prevState => ({
        placeholderSelectedItems: prevState.placeholderSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.placeholderSelectedItems.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
        this.setState({
          placeholderParams: params
        });
      });
    }
  }

 
  handleClearFilter = () => {
    this.setState({
      anchorFilterContainerEl: null,
      selectedStatus: "",
      contentRolesParams: "",
      catagoryParams: "",
      contentRoleSelectedItems: [],
      contentRoleSearchText: "",
      categorySelectedItems: [],
      categorySearchText: "",
      placeholderSelectedItems: [],
      placeholderSearchText: '',
      creationDate: "",
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      placeholderParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      }
    }, () => {
      this.getCourseList({search: this.state.text,filter: this.state.isCourseActive,page: "",per: "",}, 1);
      this.getProgramList({search: this.state.text,filter: this.state.isCourseActive,page: "",per: "",}, 1);
    })
  }


  handleChangeCalendor = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationDate: dateRange,
      creationDateParams: {
        start_date,
        end_date
      }
    });
  }



  handlePlaceholderAssignedSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchText: event.target.value
    }, () => {
      this.placeholderDebounced();
    })
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value
    })
  }

  placeholderDebounced: () => void = debounce(
    () => this.getAdminExpertListing(),
    700
  )

  getCourse(params: { search: string, filter: string, page: string, per: string }, pagination: number) {
    let customEndPoint = this.constructEndPoint({...params,filter:"course"}, pagination);
    let customEndPoint2 = this.constructEndPoint({...params,filter:"program"}, pagination);


    this.doGetCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.courseAPiEndPoint + customEndPoint,
    });

    this.doGetProgram({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.courseAPiEndPoint + customEndPoint2,
    });

    return customEndPoint;

  }

  constructEndPoint(params: { search: string, filter: string, page: string, per: string }, pagination: number): string {
    let endPoint = `course_type=${params.filter}&per=${params.per}&page=${pagination}`;
    if (params.search) {
      endPoint += `&search=${params.search}`;
    }
    endPoint += this.addStatusParam();
    endPoint += this.addPlaceholderParams();
    endPoint += this.addContentRolesParams();
    endPoint += this.addCategoryParams();
    endPoint += this.addCreationDateParam();
    endPoint += this.addCreationDateRangeParam();

    return endPoint;

  }

  private addStatusParam(): string {
    return this.state.selectedStatus ? `&q[status]=${this.state.selectedStatus}` : '';
  }

  private addPlaceholderParams(): string {
    return this.state.placeholderParams ? `&${this.state.placeholderParams}` : '';
  }

  private addContentRolesParams(): string {
    return this.state.contentRolesParams ? `&${this.state.contentRolesParams}` : '';
  }

  private addCategoryParams(): string {
    return this.state.catagoryParams ? `&${this.state.catagoryParams}` : '';
  }

  private addCreationDateParam(): string {
    const dateParam = this.getDateParam();
    return dateParam ? `&q[created_at]=${dateParam}` : ''
  }

  private addCreationDateRangeParam(): string {
    const { start_date, end_date } = this.state.creationDateParams;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `&q[custom_range]=${queryString}`;
    }
    return '';
  }

  private getDateParam(): string {
    switch (this.state.creationDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }



  categoryCheckboxHandlerChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    let updatedItems;
    if (checked) {
      updatedItems = [...this.state.categorySelectedItems, { value: name, id: itemId }]
    } else {
      updatedItems = this.state.categorySelectedItems.filter(item => item.id !== itemId);
    }
    const params = this.buildCategoryParams(updatedItems);
    this.setState({ categorySelectedItems: updatedItems, categoryId: [itemId], catagoryParams: params })

    return { categorySelectedItems: updatedItems, catagoryParams: params };
  }

  buildCategoryParams = (items: { value: string, id: string }[]) => {
    const categoryIdsKey = this.state.isCourseActive === "course" ? 'category_ids_c' : 'category_ids_p';
    return items.map(item => `q[${categoryIdsKey}][]=${item.id}`).join('&');
  }

  handleCategoryChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categorySearchText: event.target.value
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorEl: null,
      dropdownType: ""
    })
  }

  handleClickFilter = () => {
    this.setState({
      anchorFilterContainerEl: null
    }, () => {
      this.getCourseList({ search: "", filter: this.state.isCourseActive, page: "", per: "", }, 1);
      this.getProgramList({ search: "", filter: this.state.isCourseActive, page: "", per: "", }, 1);
    })

  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    if (this.state.pagination.curPage !== prevState.pagination.curPage) {
      this.getCourseList({ search: "", filter: this.state.contentType, page: "", per: "", }, this.state.pagination.curPage);
      this.getProgramList({ search: "", filter: this.state.contentType, page: "", per: "", }, this.state.pagination.curPage);
    }
  }

  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType
    })
  }/*  */

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      categoryId: [],
    })
  }

  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true
    })
  }

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ contentRoleName: event.target.value })
    if (event.target.value.length > 0) {
      this.setState({ contentRoleError: false, contentRoleErrorText: "" })

    }
  };

  handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value })

  };

  handleInputCatalogueChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.setState({
      searchInputValue: value,
      showList: value !== "",
    }, () => {
      this.getCatalogueAdvanceSearch(value, this.state.isCourseActive)
    });
  }

  getCatalogueAdvanceSearch = (value: string,type:string) => {
    this.doGetCatalogueAdvanceSearch({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.catagoryAdvanceSearch + `?search=${value}&type=${type}`,
    });

  };

  doGetCatalogueAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCatalogueAdvanceSearchApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  handleFilterDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      filterItems: !this.state.filterItems,
      anchorFilterContainerEl: event.currentTarget,
    })
  };

  handleCheckboxChange = (data: Library) => {
    let selectedData;
    if (this.state.selectedContent.includes(data)) {
      selectedData = this.state.selectedContent.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.selectedContent, data];
    }
    this.setState({ selectedContent: selectedData });
  };

  handleRemoveCheckboxChangeProgram = (data: Library) => {
    let selectedData;
    if (this.state.removedProgramData.includes(data)) {
      selectedData = this.state.removedProgramData.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.removedProgramData, data];
    }
    this.setState({ removedProgramData: selectedData });
  };

  handleAddButtonClick = () => {
    if (this.state.selectedContent.length > 0) {
      const data = [...this.state.removedAllData ,...this.state.selectedContent]
      const uniqueResult = [...new Set(data)]
      this.setState({ removedAllData: uniqueResult, selectedContent: [] })
    }

  }

  handleRemoveButtonClickProgram = () => {
    if (this.state.removedAllData.length > 0) {
      const filteredData = this.state.removedAllData.filter((item: Library) =>
        !this.state.removedProgramData.includes(item)
      )
      const courseData = this.state.removedProgramData.filter((filter)=> this.state.libraryData.includes(filter))
      const programData = this.state.removedProgramData.filter((filter)=> this.state.programData.includes(filter))
      this.setState({
        programData: [...this.state.programData, ...programData],
        libraryData: [...this.state.libraryData, ...courseData],
        removedAllData: filteredData, selectedContentProgramData: filteredData,
        removedProgramData: []
      });
    }

  };

  isCheckedValue = (arrayData: Library[], value: Library) => {
    return arrayData.includes(value)
  }

  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContainerEl: null
    })
  }

  getCourseList = async (params: { search: string, filter: string, page: string, per: string }, pagination: number) => {
    let customEndPoint = this.constructEndPoint({...params,filter:"course"}, pagination);
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listCourseDataEndPoint + customEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getProgramList = async (params: { search: string, filter: string, page: string, per: string }, pagination: number) => {
    let customEndPoint2 = this.constructEndPoint({...params,filter:"program"}, pagination);
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProgramListDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listProgramDataEndPoint + customEndPoint2
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  contentRoleDraftOrCreateApi = async (status: string) => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token
    };

    let tempData: ({ courseable_id: number; courseable_type: string; } | { id: number; _destroy: string; }  | undefined)[] = 
    this.state.removedAllData.map((data) => {
      const type = data.attributes.expand_type; // Dynamically determine the type based on the property
      // if program/course already added while create form then don't pass again
      const courseProgarmAlreadyPresnetOnCreate = this.state.intitailAssignedCourseProgramList.find((result) => result.attributes.courseable_type === type  && Number(result.attributes.courseable_id) === Number(data.id))
      if(courseProgarmAlreadyPresnetOnCreate) { return }
      return {
        courseable_id: data.id,
        courseable_type: `${type}`
      };
    }).filter(i => i);
    
    // if course/program remove from selecetd list then pass detroy with id
    const removeItems = this.state.intitailAssignedCourseProgramList.map((data) => {
      const type = data.attributes.courseable_type; // Dynamically determine the type based on the property
      // if program/course already added while create form then don't pass again
      const courseProgarmAlreadyPresnetOnCreate = this.state.removedAllData.find((result) => result.attributes?.expand_type === type  && Number(result.attributes.id) === Number(data.attributes.courseable_id))
      if(courseProgarmAlreadyPresnetOnCreate) { return }
      return {
        id: data.attributes.id,
        _destroy: `${type}`
      };
    }).filter(i => i);
    tempData = [...tempData , ...removeItems]

    const httpBody = {
      content_role: {
        name: this.state.contentRoleName,
        description: this.state.description,
        status: status,
        course_content_roles_attributes: tempData
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreateRoleDraftAndCreateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    const apiURL = `${configJSON.contentRoleDraftAndCreate}${this.state.editContentId ? `/${Number(this.state.editContentId)}` : ""}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${this.state.editContentId ? "PUT" : "POST"}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAdminCategoryListing = async () => {
    const token = await localStorage.getItem("token")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.adminsCatagoryListAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryFetchAPiEndPoint + `?search=${this.state.categorySearchText}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetCourse(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseListDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetProgram(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramListDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  
  doAdminExpertListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.expertAdminListingAPICallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getAdminExpertListing() {
    this.doAdminExpertListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.expertAdminListApiEndPoint + `?search=${this.state.placeholderSearchText}`,
    });
  }

  doContentRolesListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.contentRolesApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getAdminContentRolesListing() {
    this.doContentRolesListing({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getContentRoleListApiEndPoint,
    });
  }

  getContentRoleDetail = async () => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleDataCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentRoleListingApiEndPoint + `/${this.state.editContentId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

}
// Customizable Area End
