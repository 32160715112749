import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { BlobServiceClient } from '@azure/storage-blob';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any
  image: any
  filename: any
  imgUrl: any
  img: any
  file: any
  id: any,
  isFreeTrial: boolean,
  loading: boolean,
  duration: any,
  course: any,
  open: boolean,
  open1: boolean,
  videoDetails: any,
  thumbnailBlob: Blob,
  contentVideoId: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStockImagesApiCallId: string = "";
  getAddVideoApiCallId: string = "";
  getFreeTrialApiCallId: string = "";
  getCourseDetailApiCallId: string = "";
  getSASTokenApi: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      image: "",
      filename: "",
      imgUrl: "",
      img: "",
      file: "",
      id: "",
      isFreeTrial: false,
      loading: false,
      duration: 0,
      course: undefined,
      open: false,
      open1: false,
      videoDetails: {},
      thumbnailBlob: new Blob(),
      contentVideoId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetCourseDetailResponse = this.handleGetCourseDetailResponse.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getStockImagesApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          const images = responseJson.data && responseJson.data.attributes && responseJson.data.attributes && responseJson.data.attributes.images.length > 0 && responseJson.data.attributes.images.map((item: any) => {
            return {
              id: item.id,
              selected: false, url: item.url,
              featured: true
            }
          });
          this.setState({ data: images });

        }

      }
      else if (this.getFreeTrialApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.is_free_trial) {
          this.setState({ isFreeTrial: responseJson.is_free_trial })
        }
      }
      else if (this.getAddVideoApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          this.setState({ loading: false })
          toast.success("Video Add successfuly", { delay: 2000 });
          this.props.navigation.navigate("BuildCourse", {
            id: this.props.navigation.getParam('id')
          })
        }
        else if (responseJson?.errors) {
          this.setState({ loading: false })
          let value = responseJson.errors.map((item: any) => Object.keys(item))
          toast.error(responseJson.errors[0][value[0]]);
        }
      }
      else if (this.getCourseDetailApiCallId === apiRequestCallId) {
        this.handleGetCourseDetailResponse(responseJson);
      } else if (this.getSASTokenApi === apiRequestCallId) {
        if (!this.state.file) {
          this.setState({ loading: false })
          toast.error("Please add video to continue");
          return;
        }
        try {
          const sasUrl = responseJson.data;
          const blobServiceClient = new BlobServiceClient(sasUrl);
          const containerClient = blobServiceClient.getContainerClient(responseJson.container_name);
          let fileName = `video${this.state.filename}`
          const blobClient = containerClient.getBlockBlobClient(fileName);

          await blobClient.uploadData(this.state.file);
          const fd = new FormData();
          fd.append('course_video[video_key]', `${responseJson.container_name}/${responseJson.container_name}/${fileName}`);
          fd.append('course_video[title]', this.state.videoDetails.title);
          fd.append('course_video[description]', this.state.videoDetails.description);
          fd.append('course_video[phase]', this.state.videoDetails.phase);
          fd.append('course_video[course_id]', this.props.navigation.getParam('id'));
          fd.append('course_video[video_size]', this.state.duration);
          fd.append('course_video[content_video_id]', this.state.contentVideoId);
          fd.append('course_video[thumbnail_image]', this.state.thumbnailBlob);
          this.addVideo(fd)
        } catch (error) {
          this.setState({ loading: false, file: null, filename: "" });
          toast.error("Error while uploading video");
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.props?.navigation?.getParam('id') === "login") {
      this.props?.navigation?.navigate("EmailAccountLoginBlock")
    }
    this.getStockImage()
    this.getFreeTrial()
    this.getCourseDetail(this.props?.navigation?.getParam('id'))
  }
  handleGetCourseDetailResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.setState({ course: responseJson.data });
    }
  }
  getStockImage() {
    this.doGetStockImages({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.stockImageApiEndPoint,
    });
  }
  getFreeTrial() {
    this.doGetFreeTrial({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getFreeTrialApiEndPoint + `?course_id=${this.props.navigation.getParam('id')}`,
    });
  }

  handleOpen = () => {
    this.setState({ open: true })
  };

  handleClose = (data: any) => {
    this.setState({ open: false, contentVideoId: data[0] })
  };

  handleOpen1 = () => {
    this.setState({ open1: true })
  };

  handleClose1 = () => {
    this.setState({ open1: false })
  };

  doGetFreeTrial(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getFreeTrialApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  addVideo(data: any) {
    this.doPostAddVideo({
      contentType: configJSON.validationApiContentType,
      method: configJSON.reviewAPiMethod,
      endPoint: configJSON.addVideoApiEndPoint,
      body: data
    });
  }
  doPostAddVideo(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAddVideoApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  doGetStockImages(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getStockImagesApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getCourseDetail(id: any) {
    this.doGetCourseDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCourseDetailApiEndPoint + `/${id}`,
    });
  }
  doGetCourseDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  processFileName = (fileName: string): string => {
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '').replace(/[^\w.]/g, '').replace(/_/g, '');
    const truncatedFileName = fileNameWithoutExtension.slice(0, 8);
    const finalFileName = truncatedFileName + '.' + fileExtension;

    return finalFileName;
  }
  handleImageChange1 = async (e: any): Promise<void> => {
    const files = e.target.files[0];
    if (files === undefined) {
      return;
    }
    let filenames = "";
    filenames += files.name + "\n";
    this.getStockImage();
    this.setState({ filename: filenames, image: URL.createObjectURL(files), imgUrl: "", file: files, id: "" });
  }
  handleImageChange = async (e: any) => {
    const files = e?.target?.files[0];
    this.getStockImage();

    if (files) {
      this.setState({
        filename: this.processFileName(files.name),
        image: URL.createObjectURL(files),
        imgUrl: "",
        file: files,
        id: ""
      });
    }

    try {
      const { thumbnailBlob, videoDuration } = await this.generateThumbnail(files);
      this.setState({
        thumbnailBlob: thumbnailBlob,
        duration: videoDuration
      });
    } catch (error) {
    }
  }


  loadedMetadata = (video: HTMLVideoElement): Promise<{
    thumbnailBlob: Blob;
    videoDuration: number;
  }> => {
    return new Promise((resolve) => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");

      video.onloadedmetadata = () => {
        if (canvasContext) {
          canvas.width = 320;
          canvas.height = 240;
          canvasContext.drawImage(
            video,
            0,
            0,
            canvas.width,
            canvas.height
          );

          const thumbnailDataURL = canvas.toDataURL("image/jpeg");

          resolve({
            thumbnailBlob: this.dataURLtoBlob(thumbnailDataURL),
            videoDuration: video.duration,
          });
        }
      };
    });
  };

  generateThumbnail = async (
    videoFile: File
  ): Promise<{
    thumbnailBlob: Blob;
    videoDuration: number;
  }> => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.preload = "metadata";

    const { thumbnailBlob, videoDuration } = await this.loadedMetadata(video);
    URL.revokeObjectURL(video.src);

    return { thumbnailBlob, videoDuration };
  };


  dataURLtoBlob = (dataURL: string): Blob => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };


  handleSubmitVideo = (data: any) => {
    this.setState({ loading: true, videoDetails: { ...data } });
    const fd = new FormData();
    fd.append('course_video[title]', this.state.videoDetails.title);
    fd.append('course_video[description]', this.state.videoDetails.description);
    fd.append('course_video[phase]', this.state.videoDetails.phase);
    fd.append('course_video[course_id]', this.props.navigation.getParam('id'));
    if (data.video_text_url !== "") {
      fd.append('course_video[video_text_url]', data.video_text_url);
      this.addVideo(fd);
    } else if (this.state.contentVideoId !== "") {
      fd.append('course_video[content_video_id]', this.state.image);
      this.addVideo(fd);
    } else {
      this.getSASToken();
    }
    
  }
  getSASToken = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSASTokenApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_coursecreation/course_videos/get_sas_token"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}