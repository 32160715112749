import React from "react";
import {
  Button, Grid, InputAdornment,
  // Customizable Area Start
  Paper, Switch
  // Customizable Area End
  , TextField, Typography
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import { Search } from "@material-ui/icons";
import ManageContentLibrarylistController, {
  Props
} from "./ManageContentLibrarylistController.web";
import './style.css';
import './ContentManagement.css';
import ManageContentFolder from "./ManageContentFolder.web";
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const Vector1 = require("../assets/3dot.png");
const folder = require("../assets/Vector2.png");
const lock = require("../assets/lock2.png");
const close = require("../assets/imagenav_close.png")

// Customizable Area End


export default class ManageContentLibrarylist extends ManageContentLibrarylistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End


  render() {
    return (
      <div>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={Styles.mainHeading}>{this.props.open1 === false ? "Add Video" : "Manage Content Library"}</Typography>
          </Grid>
          <Grid container style={Styles.mainGrid} className="list">
            <Grid item lg={5} className="search-outline">
              <TextField
                fullWidth
                data-test-id="btn_change"
                id="search"
                name="search"
                variant="outlined"
                placeholder="Search"
                onChange={this.getSearchData}

                value={this.state.search}
                InputLabelProps={{
                  shrink: true,
                }}
                className="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          </Grid>

          <Grid container style={Styles.folderContainer}>
            <Grid item lg={5} md={3} sm={12} xs={12}>
              <Typography style={Styles.folder}>Folders</Typography>
            </Grid>
            {this.props.open1 === false ? "" : <>
              <Grid item lg={2} md={3} sm={4} xs={6}>
                {/* <div style={Styles.vectorBox}>
                  <div style={Styles.vectorBox2}>
                    <img style={Styles.vectorImg} src={this.state.themeMode ? Vector : Vector1} />
                    <Typography style={this.state.themeMode ? Styles.listText : Styles.listText1}>List View</Typography>
                  </div>
                  <Switch className="Switch" checked={this.state.themeMode} onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
                </div> */}
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={6}>
                {/* <div style={Styles.vectorBox}>
                  <div style={Styles.vectorBox2}>
                    <img style={Styles.vectorImg} src={this.state.themeModeDelete ? Vector : Vector1} />
                    <Typography style={this.state.themeModeDelete ? Styles.listText3 : Styles.listText2}>View Deleted</Typography>
                  </div>
                  <Switch className="Switch" checked={this.state.themeModeDelete} onChange={() => this.setState({ themeModeDelete: !this.state.themeModeDelete })} />
                </div> */}
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12}><Button onClick={this.dialogHandleOpen} style={Styles.dailogButton} variant="contained" color="primary" >Add New Folder</Button></Grid>
            </>}
          </Grid>
          <Modal
            open={this.state.dialogopen}
            onClose={this.dialogHandleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={Styles.modal}
          >
            <div style={Styles.modalBox}>
              <img src={close} onClick={this.dialogHandleClose} style={Styles.closeImg} />
              <Typography style={Styles.createText}>Create New Folder</Typography>
              <Grid container spacing={4} style={Styles.dailogGrid}>
                <Grid item lg={9}>
                  <TextField className="inputField" style={Styles.textgrid} data-test-id = "create_folder"
                     value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                </Grid>
                <Grid item lg={3}>
                  <Button onClick={() => this.createNewFolder()} style={Styles.createbutton} variant="contained" color="primary" >Create</Button>
                </Grid>

              </Grid>
            </div>
          </Modal>


          <Grid container spacing={2} style={Styles.foldergrid}>
            {this.state.allXpandLibararyData?.data?.map((data: any, index: any) => (
                <Grid key={index} item lg={3} md={4} sm={6} xs={12} style={Styles.itemgrid}>
                  <Paper style={Styles.paper}>
                    <Grid container style={Styles.paperGrid}>
                      <Grid item lg={2} style={Styles.imgGrid}>
                        <>
                          <img src={folder} />
                        </>
                      </Grid>
                      <Grid item lg={8} style={Styles.imgGrid}>
                        <Typography onClick={() => this.handleNavigate(data.id)} style={Styles.dataname}>{data.name}</Typography>
                      </Grid>
                      <Grid item lg={2} style={Styles.imgGrid}>
                        <>
                          {data.is_lock ? <img src={lock} /> : <></>}
                        </>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            }

          </Grid>
        </Grid>
        <Modal
          open={this.props.open1}
          onClose={this.props.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper style={Styles.modalPaper}>
            <ManageContentFolder mainModalClose={this.props.mainModalClose} handleClose={this.props.handleClose} dataId={this.state.dataID} open={this.props.open1} navigation={this.props.navigation} history={this.props.history} location={this.props.location} id={this.props.id} />
          </Paper>
        </Modal>
      </div>
    )
  }
}

const Styles = {
  noDataMessage :{
    textAlign: 'center' as 'center',
    margin: 'auto',
    fontSize: '28px',
    fontWeight: 700,
  },
  mainHeading: {
    fontSize: '48px',
    fontWeight: 700,
  },
  mainGrid: {
    marginTop: '25px'
  },
  folderContainer: {
    marginTop: '25px'
  },
  folder: {
    fontSize: '20px',
    fontWeight: 400,
    color: '#979797',
    marginTop: '22px'
  },
  vectorBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '10px'
  },
  vectorBox2: {
    display: 'flex',
    marginTop: '10px'
  },
  vectorImg: {
    width: '20px',
    height: '12px',
    marginTop: '7px'
  },
  listText: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#6C328B',
    paddingLeft: '15px'
  },
  listText3: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#6C328B',
    paddingLeft: '10px'
  },
  listText1: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#979797',
    paddingLeft: '15px'
  },
  listText2: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#979797',
    paddingLeft: '10px'
  },
  dailogButton: {
    borderRadius: '12px',
    width: '212px',
    height: '56px',
    backgroundColor: '#653889',
    textTransform: "capitalize" as "capitalize"
  },
  modal: {
    backgroundColor: "rgba(0, 0, 0, -0.5)"
  },
  modalBox: {
    position: 'absolute' as 'absolute',
    width: '890px',
    height: '225px',
    backgroundColor: '#fff',
    margin: '12%',
    marginLeft: '21%',
    borderRadius: '15px',
    boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'
  },
  closeImg: {
    float: 'right' as 'right',
    marginTop: '20px',
    marginRight: '20px',
    cursor: 'pointer'
  },
  createText: {
    fontSize: '20px',
    fontWeight: 600,
    marginTop: '20px',
    marginLeft: '25px'
  },
  dailogGrid: {
    marginLeft: '30px',
    marginTop: '25px'
  },
  textgrid: {
    borderRadius: '16px',
    width: '665px'
  },
  createbutton: {
    textTransform: 'capitalize' as 'capitalize',
    borderRadius: '18px',
    width: '130px',
    height: '56px',
    backgroundColor: '#653889'
  },
  foldergrid: {
    marginTop: '40px'
  },
  itemgrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    width: '100%',
    height: '68px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px',
    borderRadius: '10px'
  },
  paperGrid: {
    padding: '15px'
  },
  imgGrid: {
    margin: 'auto'
  },
  dataname: {
    cursor: 'Pointer',
    fontSize: '14px',
    fontWeight: 600,
    color: '#222836',
    marginLeft: '0px',
    marginTop: '0px',
    textTransform: 'capitalize' as 'capitalize'
  },
  modalPaper: {
    width: '1200px',
    display: 'flex',
    margin: '10px auto',
    maxHeight: '600px',
    overflowX: 'hidden' as 'hidden',
    padding: '45px',
    borderRadius: '15px !important'
  }
}