import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,withStyles  } from "@material-ui/core/styles";
const edit = require("../assets/edit.png");
import ArrowBackIosIcon  from '@material-ui/icons/ArrowBackIos';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import './style.css'

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssignContentController, {
  Props,
} from "./AssignContentController.web";

export default class AssignModal extends AssignContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const {assignContent, notify, calendar} = this.state


    return (
      // Customizable Area Start
      <Box style={{width:"60vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
      <Box style={{width:"325px", display:'flex',justifyContent:'space-between',alignItems:"center"}}>
        <ArrowBackIosIcon style={{fontSize:'30px', cursor:'pointer'}} onClick={this.props.handleClose} />
        <Typography variant="h5" style={{fontWeight:"bold"}}>Assign Content Details</Typography>
      </Box>
      <Box className="react_calendar">
      <Calendar onChange={this.handleCalendar} value={calendar}/>
      </Box>

      <Box className="optional_box">
        <Box>
      <Typography>Notify User When Completed <span>(Optional)</span> </Typography>
      <FormControl component="fieldset">
      <RadioGroup aria-label="notify" name="notify" row  value={notify} onChange={this.handleNotifyUser} >
        <FormControlLabel value='true' control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value='false' control={<Radio color="primary" />} label="No" />
      </RadioGroup>
    </FormControl>
    </Box>
  { notify !== 'false' ? <Box style={{marginLeft:'80px'}}>
    <Typography>Who Gets Notified?</Typography>
    <FormControl className="content_select">
        <NativeSelect
          value={assignContent}
          onChange={this.handleAssignContent}
        >
          <option value="">Assign Content</option>
          <option value='Course/Program'>Course/Program</option>
          <option value='ContentRole'>Content role</option>
          <option value='Bundle'>Bundle</option>
          <option value='Folder'>Folder</option>
        </NativeSelect>
        </FormControl>
    </Box>  : <></>}
      </Box>
      <Box className="optional_box">
        <Box>
      <Typography>Course Required <span>(Optional)</span> </Typography>
      <FormControl component="fieldset">
      <RadioGroup aria-label="required" name="required" row >
        <FormControlLabel value="True" control={<Radio color="primary" />} label="Yes" />
        <FormControlLabel value="False" control={<Radio color="primary" />} label="No" />
      </RadioGroup>
    </FormControl>
    </Box>

      </Box>
      { notify !== 'false' ?
      <Box className="notify_users">
        
      <Typography>Notify Users</Typography>
      <Box className="notify_button">
      <ColorButton size="small" >Email</ColorButton>
      <ColorButton size="small" >Text Message</ColorButton>
      <ColorButton size="small" >In App</ColorButton>
    </Box>

      </Box>
       : <></>}
        { notify !== 'false' ?
      <Box className="notify_input">
        <Box className="notify_field">
       <input type="text" placeholder="Edit your message here"/>
       <Typography>
        Edit
        <img src={edit} alt="" />
       </Typography>
      </Box>
     
        </Box>
        : <></>}
     <Box className="send">
        <OrangeButton size="small" onClick={this.props.handleOpen}>Send</OrangeButton>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
    mainWrapper: {
    padding: '30px',
      background: "#f2f2f2",
      borderRadius: '15px'
    },
    searchBar: {
        padding: '30px',
          background: "#f2f2f2",
          borderRadius: '15px'
        },
}

const ColorButton = withStyles((theme) => ({
  root: {
      backgroundColor: '#fff',
      color:"#6C328B",
      borderRadius:"15px",
      borderColor: '#6C328B',
      textTransform:'capitalize',
      padding:'15px 50px',
      border: '1px solid',
      fontSize:'15px',
      fontWeight:600,
      marginRight:'15px',
      '&:hover': {
          backgroundColor:'#E7E0FB',
      },
  },
}))(Button);

const OrangeButton = withStyles((theme) => ({
  root: {
      backgroundColor: '#FF8B3F',
      color:"#fff",
      borderRadius:"15px",
      textTransform:'capitalize',
      padding:'12px 60px',
      fontSize:'15px',
      fontWeight:600,
      marginRight:'15px',
      '&:hover': {
          backgroundColor:'#FF8B3F',
      },
  },
}))(Button);


// Customizable Area End
