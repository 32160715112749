import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: any
  alert:{isOpen:Boolean,msg:string,type:string};
  is_category_selected:any;
  is_expert_selected:any;
  is_expert_skipped:any;
  is_category_skipped:any;
  isLoading: boolean;
  open:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.NavigationPayLoadMessage),
    getName(MessageEnum.RestAPIResponceSuccessMessage)];

    this.state = {
      category: [],
      alert:{isOpen:false,msg:'',type:'error'},
      is_category_selected:false,
      is_expert_selected:false,
      is_category_skipped:false,
      is_expert_skipped:false,
      isLoading:true,
      open:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     


      if (this.getCategoryApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          const category = responseJson.data && responseJson.data.length > 0 && responseJson.data.map((item: any) => {
            return {
              id: item.id, type: item.type, attributes: item.attributes,
              selected: false, img: item.attributes?.image?.url,
              featured: true
            }
          });
          this.setState({ category: category });

        }
        else {
          if (responseJson.errors) {
            this.setState({ category: [] })
          }

        }

     }
     else if(apiRequestCallId === this.profileUserApiCallId){
     
    this.setState({is_category_selected:responseJson.is_category_selected,is_expert_selected:responseJson.is_expert_selected,
      is_category_skipped:responseJson.is_category_skipped,
      is_expert_skipped: responseJson.is_expert_skipped,
      isLoading:false
    });

    }
     else if (this.createUserCategoryApiCallId === apiRequestCallId){
      if(responseJson && !responseJson.errors){
        setTimeout(() => {
          this.props.navigation.navigate('Categoriessubcategories')
        }, 3000)
      }
     else {
      let value = responseJson.errors.map((item:any) => Object.keys(item))
    }

    }
     else if (this.skipCategoryApiCallId === apiRequestCallId){
      if(responseJson && responseJson.suceess){
        setTimeout(() => {
          this.props.navigation.navigate('Categoriessubcategories')
        }, 3000)
      }
     else {
      let value = responseJson.errors.map((item:any) => Object.keys(item))
    }

    }
      else if (responseJson && responseJson.errors) {
        this.setState({ category: [] })
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
}

  // Customizable Area Start
  skipOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerSkipOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  exitOnboarding() {
    const msg: Message = new Message(getName(MessageEnum.OnboardingGuideControllerDoneOnboarding));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    this.getCategory()
    this.getPorfileData();
    this.stopBackButton();
    this.handleClickOpen();
  }

  getCategoryApiCallId:string;
  createUserCategoryApiCallId:string;
  skipCategoryApiCallId:string;
  profileUserApiCallId:string;

  stopBackButton(){
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }

  getCategory () {    
      this.doGetCategoryUser({
        contentType: configJSON.formDataContentType,
        method: configJSON.apiMethodTypeGET,
        endPoint: configJSON.categoryFetchAPiEndPoint,
    });
  }

  createCategory (data:any) {
     this.doCreateUserCategory({
      contentType: configJSON.formDataContentType,
      method: configJSON.apiMethodTypePOST,
      endPoint: configJSON.createUserCategoryEndPoint,
      body: JSON.stringify(data)
     })
  }

  handleClickOpen = () => {
    this.setState({open:true});
  };

  handleClose = () => {
    this.setState({open:false});
  };

  skipCategory () {
     this.doSkipCategory({
      contentType: configJSON.formDataContentType,
      method: configJSON.apiMethodTypeGET,
      endPoint: configJSON.skipUserCategoryEndPoint,
     })
  }
  
  getPorfileData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token : localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.profileUserApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPorfileDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGET
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleData({msg,type}:any) {
    this.setState({alert:{isOpen:true,msg,type}}) 
  }

    // API Call API Call 
    doGetCategoryUser(data: any) {
      const { contentType, method, endPoint, body } = data;
      const header = {
        "Content-Type": contentType,
        token: localStorage.getItem("token"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
    }

    doCreateUserCategory(data: any) {
      const { contentType, method, endPoint, body } = data;
      const header = {
        "Content-Type": contentType,
        token: localStorage.getItem("token"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    this.createUserCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
    }

    doSkipCategory(data:any) {
      const { contentType, method, endPoint, body } = data;
      const header = {
        "Content-Type": contentType,
        token: localStorage.getItem("token"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    this.skipCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
    }
  // Customizable Area End
}