import React from 'react'
// Customizable Area Start
import {
    Grid,
    TextField,
    InputLabel,
    InputAdornment,
    Button,
    IconButton,
    Avatar,
    Card,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { Caret, uploadIcon } from "./assets"
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify';
import './CourseCreation.css';
import CourseCreationController, {
} from "./CourseCreationController.web";
import PageTitle from '../../../components/src/DynamicTitle';

class AddObject extends CourseCreationController {
    constructor(props: any) {
        super(props);
    }

    // Validate files
    validateFiles(formData: any) {
        if (!this.state.file && !formData.video_text_url) {
            toast.info("Please select a file");
            return false;
        }

        const { file } = this.state;
        if (file) {
            const fileTypeVideoOrImage = file.type.includes("video") || file.type.includes("image");
            const fileTypePdf = file.type.includes("pdf");
            // Support only pdf files for object type 'po_documents'
            if (formData.object_type === "po_documents" && !fileTypePdf) {
                toast.info("Please select a pdf file for documents object type");
                return false;
            }
            // Support videos & images for object type 'po_references'
            if (formData.object_type === "po_references" && !fileTypeVideoOrImage) {
                toast.info("Please select a video or image for references object type");
                return false;
            }
            return true;
        }
        return true;
    }

    handleSubmit(data: any) {
        // Validate selected file
        const fileValidationOk = this.validateFiles(data);
        if (!fileValidationOk) {
            return;
        }
        const fd = new FormData();
        const { phase, image, file, id, imgUrl } = this.props.location.state;
        if (!image && !imgUrl) {
            toast.info("Please add thumbnail");
            return;
        }
        fd.append('program_object[name]', data.title);
        fd.append('program_object[description]', data.description);
        fd.append('program_object[custom_event]', data.object_type);
        fd.append('program_id', this.props.navigation.getParam('id'))
        fd.append('phase', phase);
        this.state.tags.length > 0 && this.state.tags.forEach((item: any) => {
            if(item.isSelected === true){
            fd.append("custom_tag_id[]", item.id);
            }
        });
        image === "" ? 
            fd.append('program_object[stock_image_id]', id) : 
            fd.append('program_object[thumbnail_image]', file);
        this.state.file && fd.append('program_object[attachment]', this.state.file);
        data.video_text_url && fd.append('program_object[video_text_url]', data.video_text_url);
        
        // Call api to create objects
        this.getCreateObject(fd);
    }

    onTagChange = (e: any) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            e.preventDefault();
            this.setState({ tags: [...this.state.tags, { attributes: { name: e.target.value } }] })
            e.target.value = ""
        }
    }
    removeTag(index: number) {
        this.state.tags.splice(index, 1)
        this.setState({ tags: this.state.tags })
    }
    selectTags = (index:number) => {
        let items  = [...this.state.tags];
        let item = {...items[index]};
        item.isSelected = !item.isSelected;
        items[index] = item;
        this.setState({tags:items})
    }

    render() {
        const getImageSource = () => {
            if (this.props.navigation.getParam('id')) {
                if (this.props?.location?.state?.imgUrl) {
                    return this.props.location.state.imgUrl;
                } else if (this.props?.location?.state?.image) {
                    return this.props.location.state.image;
                } else {
                    return "https://images.unsplash.com/photo-1520085601670-ee14aa5fa3e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
                }
            }
        }

        const { state } = this.props.location;
        let initialValues;
        if (state?.formObject) {
            initialValues = {
                title: state.formObject.title,
                description: state.formObject.description,
                object_type: state.formObject.object_type,
                video_text_url: state.formObject.video_text_url,
            }
        } else {
            initialValues = {
                title: "",
                description: "",
                object_type: "",
                video_text_url: "",
            }
        }

        return (
            <>
                <PageTitle 
                    showBackButton 
                    title="Add Object" 
                    onBackClick={this.props.navigation.goBack} 
                />
                <Formik
                    data-test-id="formik_data"
                    initialValues={initialValues}
                    onSubmit={async (values: any) => {
                        this.handleSubmit(values);
                    }}
                    validationSchema={yup.object().shape({
                        title: yup.string().min(5).max(150).required('Please enter title'),
                        description: yup.string().min(150).max(1500).required('Please enter description'),
                        object_type: yup.string().required('Please enter object type'),
                        video_text_url: yup
                            .string()
                            .matches(
                                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                'Enter valid url!'
                            )
                        ,
                    })}

                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleSubmit,
                            handleBlur
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container className='video' spacing={4}>
                                    <Grid container spacing={4} md={12} style={{ marginTop: "20px" }} className="thumb-content">
                                        <Grid item md={2} xs={12}>
                                            <Link to={{ pathname: '/videos', state: { values: {...values, file: this.state.file}, redirectPath: window.location.pathname, categories: [], phase: this.props?.location?.state?.phase } }} style={{ textDecoration: "none !important" }}><Button className="changethumb-btn"

                                            > {this.props.location?.state?.image || this.props?.location?.state?.imgUrl ? "Update Thumbnail" : "Add Thumbnail"}</Button>
                                            </Link>
                                        </Grid>
                                        <Grid item md={3} className="thumbpreview">
                                            <img src={getImageSource()}
                                                style={{ filter: "drop-shadow(0px 2px 14px rgba(140, 140, 140, 0.715335))", borderRadius: "8px", height: "56px", width: "56px" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container md={12} xs={12} spacing={4} style={{ marginTop: "20px" }}>
                                        <Grid item md={6} xs={12}>
                                            <InputLabel className='basic'>Basic Detail</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="title"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.title && Boolean(errors.title)}
                                                helperText={touched.title && errors.title}
                                                variant="outlined"
                                                className="vtitle-field"
                                                placeholder="Enter Title"
                                                InputLabelProps={{
                                                    shrink: true
                                                }} />
                                        </Grid>
                                        <Grid item md={6} xs={12} className="course-section">
                                            <InputLabel className='basic'>Object Type</InputLabel>
                                            <FormControl className="object-type">
                                                <Select
                                                    displayEmpty
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    name='object_type'
                                                    value={values.object_type}
                                                    error={touched.object_type && Boolean(errors.object_type)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    IconComponent={() => null}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <img src={Caret} />
                                                        </InputAdornment>

                                                    }
                                                    fullWidth>
                                                    <MenuItem value="" selected >
                                                        <em>Object Type</em>
                                                    </MenuItem>
                                                    <MenuItem value={"po_documents"}>Documents</MenuItem>
                                                    <MenuItem value={"po_references"}>References</MenuItem>
                                                </Select>
                                                <p className='invalid-feedback'>{touched.object_type && errors.object_type}</p>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container md={12} xs={12}>
                                        <Grid item md={12} xs={12}>
                                            <InputLabel className='basic'>Object Description</InputLabel>
                                            <TextField
                                                fullWidth
                                                className="object-desc"
                                                id="object_desc"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.description && Boolean(errors.description)}
                                                helperText={touched.description && errors.description}
                                                variant="outlined"
                                                placeholder="Please write your description here"
                                                multiline
                                                rows={10}
                                                maxRows={12}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                id="tags"
                                                name="tag"
                                                onKeyDown={this.onTagChange}
                                                disabled={true}
                                                variant="outlined"
                                                placeholder="Enter tag"

                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />

                                        </Grid>
                                    </Grid>
                                    <div className='course' style={{ width: "100%" }}>
                                            <Grid container spacing={4} md={12} xs={12} style={{ marginTop: "10px" }} className="contentRole">
                                                {this.state?.tags && this.state?.tags?.length === 0 ? "" : this.state?.tags?.map((item: any, index: any) => {
                                                    return <Grid md={2} xs={12} item className="custom-md-2" key={item.id}>
                                                        <Button className={item?.isSelected ? "contentRoleContent-btn btn-background" : "contentRoleContent-btn"} variant="outlined" onClick={() => this.selectTags(index)}>{item?.attributes?.name}</Button>
                                                    </Grid>
                                            })}
                                            </Grid>
                                    </div>

                                    <Grid container spacing={4} >
                                        <Grid item md={6} xs={12}>
                                            <div className='file-container'>
                                                <Grid item md={12} className="linkheading-content">
                                                    <p className="link-heading">Paste link here</p>
                                                </Grid>
                                                <Grid item md={12} className="subheading-content">
                                                    <p className="link-subheading">You can paste your youtube/vimeo video link here</p>
                                                </Grid>
                                                <Grid item xs={12} className="inputlink-content">
                                                    <TextField variant='standard' className="input-link" label="Enter your link here"
                                                        name='video_text_url'
                                                        value={values.video_text_url}
                                                        onChange={handleChange}
                                                        error={touched.video_text_url && Boolean(errors.video_text_url)}
                                                        helperText={touched.video_text_url && errors.video_text_url}
                                                        disabled={this.state.file === "" ? false : true}
                                                    />
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <div className='file-container'>
                                                {this.state.image === "" ? <>

                                                    <Grid className="file-content" item md={1} style={{ margin: "auto" }}>
                                                        <Button variant="contained" className="upload-btn" component="label" style={{ marginTop: "10px" }}>
                                                            <input hidden disabled={values.video_text_url === "" ? false : true} type="file" onChange={(e) => this.handleImageChange(e)} />
                                                            <Avatar src={uploadIcon} />
                                                        </Button>

                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <p className="drag-fsubtext" style={{ textAlign: "center" }}>Upload upto 15 MB and file dimension should be 768*1024</p>
                                                    </Grid>
                                                </> : <>
                                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
                                                        <div style={{ minWidth: "200px", maxWidth: "200px", margin: "0px", textAlign: "center" }} >
                                                            <Card >
                                                                <div
                                                                    className='card-header'
                                                                >
                                                                    <IconButton aria-label="close" onClick={() => this.setState({ image: "", file: "", filename: "" })}>
                                                                        <Close />
                                                                    </IconButton>
                                                                </div>
                                                                <img className="select-img-preview" src={values.object_type === "po_references" || values.object_type === "po_documents" ? "https://media.istockphoto.com/id/157591513/photo/file-folder-made-of-100-percent-recycled-fiber-with-document.jpg?s=2048x2048&w=is&k=20&c=O_Ddxg6YBfFPxcwKeioLt1c7U7LGQ0GR5XHAe0ICTm0=" :
                                                                    "https://images.unsplash.com/photo-1634157703702-3c124b455499?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80"
                                                                } />
                                                            </Card>
                                                            <p className='file-name'>{this.state.filename}</p>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                        </Grid>
                                    </Grid>


                                    <Grid container style={{ justifyContent: "center" }}>
                                        <Grid item md={2}>
                                            <Button className="btn-thumbnail" fullWidth type="submit">
                                                {this.state.isLoading ? (
                                                    <>
                                                        <CircularProgress size={20} color="inherit" />
                                                        &nbsp; Uploading...
                                                    </>
                                                ) : (
                                                    "Add Object"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </>
        )
    }
}
export default AddObject;
// Customizable Area End