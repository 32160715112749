import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");  

export interface Props {
  navigation: any;
  history:any;
  location:any;
  id: string;
  // Customizable Area Start
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl:any;
  libraryData:any;
  allXpandLibararyData:any;
  grapValues:any;
  postXpandLibararyData:any;
  search:any;
  cartItem:any;
  page:number;
  cartItemsCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class XpandLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData:any;
  PostXpandLibraryData:any;
  AddCartItemID:any;
  GetSearchData:any;
  getCartItemsApiCallId: string | Message = "";;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl:'',
      grapValues:'Week',
      allXpandLibararyData: [],
      postXpandLibararyData:{},
      search:"",
      cartItem: true,
      page:1,
      libraryData:[
        {
          id:1,
          image_url:slider1,
          value:true,
        },
        {
          id:2,
          image_url:slider2,
          value:true,
        },
        {
          id:3,
          image_url:slider3,
          value:false,
        },
        {
          id:4,
          image_url:slider2,
          value:true,
        },
        {
          id:5,
          image_url:slider1,
          value:false,
        },
        {
          id:6,
          image_url:slider2,
          value:true,
        },
        {
          id:7,
          image_url:slider3,
          value:false,
        },
        {
          id:8,
          image_url:slider1,
          value:true,
        },
        {
          id:9,
          image_url:slider2,
          value:false,
        }
      ],
      cartItemsCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(){
    this.getXpandLibraryData();
    this.getCartItems();
  }
  XPAND_DATA:any = {
    data: [
        {
            id: "33",
            type: "collection_list",
            attributes: {
                title: "JQuery",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r",
                average_rating: 0,
                course_timeline: 25,
                subscription_period: "one_time_purchase",
                price: "0.0",
                thumbnail_image: {
                    id: 179,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYk09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e63deb3cef9c477c75e4473f85c5e3356dce8c92/download%20(2).jpeg"
                },
                expand_type: "Course",
                is_paid: false,
                created_by: "StreetSmarts Admin",
                button: "enroll",
                is_enrolled: false,
                course_video_track: {
                    last_video_id: 47,
                    seen_time: "0",
                    status: "partial"
                }
            }
        },
        {
            id: "32",
            type: "collection_list",
            attributes: {
                title: "CSS",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r",
                average_rating: 0,
                course_timeline: 25,
                subscription_period: "one_time_purchase",
                price: "0.0",
                thumbnail_image: {
                    id: 177,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1e2eec54e19dca7fd61aa0556f793c7fc1a18d56/download%20(1).jpeg"
                },
                expand_type: "Course",
                is_paid: false,
                created_by: "StreetSmarts Admin",
                button: "enroll",
                is_enrolled: false,
                course_video_track: {
                    last_video_id: 44,
                    seen_time: "0",
                    status: "partial"
                }
            }
        },
        {
            id: "31",
            type: "collection_list",
            attributes: {
                title: "HTML",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r",
                average_rating: 0,
                course_timeline: 25,
                subscription_period: "one_time_purchase",
                price: "0.0",
                thumbnail_image: {
                    id: 175,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYTg9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--46b427c6313e9af503b9d59b2dac694909c8ac95/download.jpeg"
                },
                expand_type: "Course",
                is_paid: false,
                created_by: "StreetSmarts Admin",
                button: "enroll",
                is_enrolled: false,
                course_video_track: {
                    last_video_id: 41,
                    seen_time: "0",
                    status: "partial"
                }
            }
        },
        {
            id: "6",
            type: "collection_list",
            attributes: {
                title: "Course WITH resolution created through api",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r",
                average_rating: 0,
                course_timeline: 40,
                subscription_period: "one_time_purchase",
                price: "0.0",
                thumbnail_image: {
                    id: 95,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBaQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--12bb9f181b306e7ec55dd901b1f2e76bfd21e0cf/103.png"
                },
                expand_type: "Course",
                is_paid: false,
                created_by: "StreetSmarts Admin",
                button: "enroll",
                is_enrolled: false,
                course_video_track: {
                    last_video_id: 23,
                    seen_time: "0",
                    status: "partial"
                }
            }
        },
        {
            id: "99",
            type: "collection_list",
            attributes: {
                title: "Paid Course one",
                description: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
                average_rating: 0,
                course_timeline: 10,
                subscription_period: "one_time_purchase",
                price: "100.0",
                thumbnail_image: {
                    id: 547,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaU1DIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--734e9f79399deafb7bb15c24d360817a2f81c1b0/0_fbvHe6LGGyb-rkvn.jpeg"
                },
                expand_type: "Course",
                is_paid: true,
                created_by: "StreetSmarts Admin",
                button: "cart",
                is_enrolled: false,
                course_video_track: null
            }
        },
        {
            id: "100",
            type: "collection_list",
            attributes: {
                title: "Paid Course two",
                description: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
                average_rating: 0,
                course_timeline: 10,
                subscription_period: "subscription",
                price: "100.0",
                thumbnail_image: {
                    id: 547,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaU1DIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--734e9f79399deafb7bb15c24d360817a2f81c1b0/0_fbvHe6LGGyb-rkvn.jpeg"
                },
                expand_type: "Course",
                is_paid: true,
                created_by: "StreetSmarts Admin",
                button: "cart",
                is_enrolled: false,
                course_video_track: null
            }
        }, {
            id: "10111",
            type: "collection_list",
            attributes: {
                title: "JQuery",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \r",
                average_rating: 0,
                course_timeline: 25,
                subscription_period: "one_time_purchase",
                price: "0.0",
                thumbnail_image: {
                    id: 179,
                    url: "https://streetsmarts-112516-ruby.b112516.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYk09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e63deb3cef9c477c75e4473f85c5e3356dce8c92/download%20(2).jpeg"
                },
                expand_type: "Course",
                is_paid: false,
                created_by: "StreetSmarts Admin",
                button: "enroll",
                is_enrolled: false,
                course_video_track: {
                    last_video_id: 47,
                    seen_time: "0",
                    status: "partial"
                }
            }
        },

    ],
    meta: {
        pagination: {
            per_page: 20,
            current_page: 1,
            next_page: null,
            prev_page: null,
            total_pages: 1,
            total_count: 4
        }
    }
}
  async receive(from: string, message: Message) {
    const messageType = message.id;
    const dataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const successMessage = getName(MessageEnum.RestAPIResponceSuccessMessage);

  // Customizable Area Start
    switch (messageType) {  
      case getName(MessageEnum.RestAPIResponceMessage):
        const apiRequestCallId = message.getData(dataMessage);
        const responseJson = message.getData(successMessage);
  
        if (apiRequestCallId && responseJson) {
          switch (apiRequestCallId) {
            case this.GetXpandLibraryData:
              this.setState({ allXpandLibararyData: responseJson });
              break;
  
            case this.PostXpandLibraryData:
              if (responseJson) {
                this.getXpandLibraryData();
                toast.success('Course Add in Personal');
              }
              break;
  
            case this.GetSearchData:
              this.handleSearchDataResponse(responseJson);
              break;
  
            case this.AddCartItemID:
              this.handleAddCartItemResponse(responseJson);
              break;
  
            case this.getCartItemsApiCallId:
              this.handleGetCartItemsApiResponse(responseJson);
              break;
  
            default:
              // Handle unknown apiRequestCallId if necessary
              break;
          }
        }
        break;
  
      default:
        // Handle unknown messageType if necessary
        break;
    }
    // Customizable Area End
  }
  
  
  handleSearchDataResponse(responseJson: any) {
    this.setState({ allXpandLibararyData: responseJson.collection });
    if (this.state.search === "") {
      this.getXpandLibraryData();
    }
  }
  



  // Customizable Area Start
 
  pagination = (evaent:any,value:any) => {
      this.setState({page:value}, ()=> 
        this.getXpandLibraryData()
      ) 
     
  }
  
 

  handleNavigate = (data:any) => {
    if(data.attributes.expand_type === 'Course'){
        this.props.history.push(`/course-detail/${data.attributes.expand_type}/${data.id}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data})    
    }else if(data.attributes.expand_type === 'Program'){
      this.props.history.push(`/program-detail/${data.attributes.expand_type}/${data.id}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data}) 
    }
}

handleSubscription = (data:any) => {
  this.props.history.push('/Subscription',{name: data})    
}

  getSearchData = async (event:any) => {
    this.setState({search:event.target.value})
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_playlist/libraries/search?search=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coursecreation/collections?page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

postplayButton = async (data:any) => {
  this.props.history.push(`/course-detail/${data.id}/${data.attributes.expand_type}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data.id})
}

  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const httpBody =
      type == "Program"
        ? {
            program_id: id,
          }
        : {
            course_id: id,
          };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AddCartItemID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart.')
      this.setState({
        cartItemsCount: this.state.cartItemsCount + 1
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } 
  }



  postXpandLibraryData = async (id:any, type:string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": `BxBlockCoursecreation::${type}` // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_playlist/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   // Get current cart items
   getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.log("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };

  // Customizable Area End
}