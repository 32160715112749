import React from "react";
 // Customizable Area Start
    

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    
} from "@material-ui/core";


import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slider from "react-slick";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Visibility from "@material-ui/icons/Visibility";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';





import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));



const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


import Library2Controller, {
    Props,
    configJSON,
} from "./Library2Controller.web";
import Card from '@material-ui/core/Card';
import { borderRadius } from "react-select/src/theme";
import Sidebar from "../../../components/src/Sidebar";
// Customizable Area End

export default class ProgramPage extends Library2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
        <Grid container>
          <Typography variant="h3" className="profile_header">Phase</Typography>
            <ThemeProvider theme={theme}>
                <Container>
                    <Grid container spacing={4} style={{ width: '100%' }}>

                        <Grid item xs={12} sm={8}>
                            {/* <h2
                                style={{
                                    fontSize: "normal",
                                    fontStyle: "roman",
                                    fontWeight: "normal",

                                }}
                            >Program</h2> */}
                            <Box
                                style={{
                                    // border:"1px solid red",
                                    width: "100%",
                                    height: "300px",
                                    borderTopRightRadius: "10px",
                                    borderTopLeftRadius: "10px",

                                }}>
                                <img src="https://picsum.photos/seed/picsum/200/300" style={{ width: '100%', height: '300px', borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }} alt="img" />
                            </Box>
                            <p
                                style={{
                                    fontSize: "20px",
                                    fontStyle: "roman",
                                    fontWeight: "bold",
                                }}>
                                Program Name(6 Courses)</p>

                            <>

                            </>
                            <p>This content related to chapter1.And it consists of HTML and CSS.
                                Each video will be covered in 2hrs.You need to follow the videos everyday.
                                And end of the day you should check with the documentation.After documentation you should write the teat exams.
                                After writing the test exams you should practice the coding part.
                                At the end of the week you will have assignment.
                                Based on the assignment marks grade will be assigned</p>

                            <p
                                style={{
                                    fontSize: "20px",
                                    fontStyle: "roman",
                                    fontWeight: "bold",
                                }}>
                                What you will learn</p>

                            <p>This content related to chapter1.And it consists of HTML and CSS.
                                Each video will be covered in 2hrs.You need to follow the videos everyday.
                                And end of the day you should check with the documentation.After documentation you should write the teat exams.
                                After writing the test exams you should practice the coding part.
                                At the end of the week you will have assignment.
                                Based on the assignment marks grade will be assigned</p>

                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <h2
                                style={{
                                    fontSize: "normal",
                                    fontStyle: "roman",
                                    fontWeight: "normal",

                                }}
                            >Currirculum</h2>

                            <Accordion style={{ boxShadow: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>8 Videos |0h 56m total length</Typography>
                                </AccordionSummary>

                                <AccordionDetails style={{ flexDirection: 'column', padding: 0, }}>
                                    {[...Array(8)].map((e) => {
                                        return (
                                            <MediaControlCard />
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>

                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}><h3>Recommended courses for you!</h3> <Box><ChevronRightIcon /></Box></Grid>

                            <Card style={{ height: '180px' }}>
                                <SliderComponent data={this.state.libraryData} type='buttons' />
                            </Card>

                        </Grid>

                    </Grid>
                </Container>

            </ThemeProvider>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};

export const SliderComponent = (props: any) => {
    var settings = {
        // beforeChange: (current, next) => setactiveSlide(next),
        // afterChange: current => setactiveSlide2(current),
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: false,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    };
    return (
        <Slider {...settings}>
            {props?.type === 'buttons' ?
                ([...Array(10)].map((e: any) => {
                    return <ImgMediaCard props={props} />
                })) : (
                    props?.data?.map((c: any, i: any) => {
                        return <Box key={i}>
                            <Box style={{ width: '200px', position: 'relative', borderRadius: '12px', overflow: 'hidden' }}>
                                <Box>
                                    <img src={c.image_url} style={{ width: '100px', height: '100px' }} alt="img" />
                                </Box>
                                <Box style={{ position: 'absolute', bottom: '8px', left: '20px', color: '#fff' }}>
                                    <h3>
                                        heading
                                    </h3>
                                    <h3 >
                                        paragra data
                                    </h3>
                                </Box>
                            </Box>
                        </Box>
                    })
                )}
        </Slider>
    )
}

export function ImgMediaCard(props: any) {
    return (
        <Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: "relative",
                    height: "180px",
                    width: "150px",
                    borderRadius: "10px",
                    overflow: 'hidden',
                    boxShadow: 'rgb(237 237 237) -15px 16px 24px, rgb(237 237 237) 5px 4px 6px'
                }}
            >
                <Box>
                    <img
                        src="https://picsum.photos/seed/picsum/200/300"
                        alt=""
                        style={{ width: "100%", height: "280px" }}
                    />
                </Box>
                <Box
                    style={{
                        height: "100px",
                        marginTop: "-20px",
                        borderRadius: "10px",
                        background: '#fff',
                    }}
                >
                    <Box style={{
                        position: 'absolute',
                        bottom: 35,
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        {props?.props?.cardButtons ? (
                            <React.Fragment>
                                <Box style={{ width: '50px', height: '40px', background: 'red', borderRadius: '10px' }}></Box>
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '40px', background: 'purple', borderRadius: '10px' }}>
                                    <PlayCircleOutlineIcon style={{ color: '#fff' }} />
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box style={{ width: '50px', height: '25px', background: 'red', borderRadius: '10px' }}></Box>
                                <Box><PlayCircleOutlineIcon /></Box>
                            </React.Fragment>
                        )}

                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export function MediaControlCard() {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Card className={classes.root} style={{ height: "70px", width: "300px", marginBottom: "10px" }}>
            <Box style={{ position: 'relative', }} >

                <img
                    src="https://picsum.photos/seed/picsum/200/300"
                    style={{ height: '70px', width: '70px' }}
                />
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <PlayCircleOutlineIcon />
                </Box>

            </Box>

            <Box>
                <Box style={{ marginLeft: '10px' }}>
                    <h5 style={{ margin: '5px 0', }}>
                        Chapter 1
                    </h5>
                    <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', }}>
                        This is about chapter 1
                    </Typography>
                </Box>


            </Box>

        </Card>

    )
}



// Customizable Area End
