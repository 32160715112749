// Customizable Area Start

import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";

interface Props {
  data: any;
  classes?:any
}
class AlertSuccess extends Component<Props, {}> {
  constructor(props: any) {
    super(props);
  }
  render() {  
    const { classes, data } = this.props;
    const snackbarClass = `${classes.snackbarBlock} ${data.showThumb ? classes.snackbarTop : classes.snackbarBelowTop}`;

    return (
        <Snackbar
        className={snackbarClass}
        open={this.props?.data?.show}
        message=  {this.props?.data?.msg}
         anchorOrigin={{
         vertical: this.props?.data?.vertical,
         horizontal: this.props?.data?.horizontal,
  }}
/>
     
    );
  }
}
const styles = (theme: Theme) =>
  createStyles({
    snackbarBlock: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: "white !important",
        color: "#000 !important",
        borderLeft:"8px solid #00882C",
        padding:"24px 32px",
        fontSize:"16px",
        fontWeight:400,
        fontFamily:"Poppins",
        borderRadius:"0px",
        minWidth:"auto",
        "& .MuiSnackbarContent-message":{
          padding:"0px"
        }
      },
    },
    snackbarTop: {
        top: "100px"
    },
  });
export default withStyles(styles)(AlertSuccess);

// Customizable Area End
