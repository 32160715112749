// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleClose?: any;
  message: any;
  handleBackHome: any;
  handleBackPersonal: any;
  history: any;
  handleStripeSuccess: any;
  handleStripeFailure: any;
  handleCartItemsCountInTopbar?: (count: number) => void;
}

export interface SelectedSubsCartItem {
  id: string;
  price: string;
  purchasableId: number;
  purchasableType: string;
  subscriptionPeriod: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  //isModalOpen:boolean;
  orderConfirmationOpen: any;
  recommendedCourseData: any;
  recommendedLoader: any;
  proceedModal: any;
  cartType: string;
  cartData: any;
  subscriptionCartData: any;
  cartDataLoader: any;
  selectedOrder: any;
  saveOrder: any;
  payableAmount: number;
  paymentMethod: any;
  message: any;
  transitionError: any;
  stripeKey: any;
  removedCartItemId: string;
  orderSuccessMsg: string;
  orderFailureMsg: string;
  orderItems: any;
  selectedSubsCartItem: SelectedSubsCartItem;
  lastSubscriptionOrder: any;
  orderConfirmed: boolean;
  // settype:any;
}

interface SS {
  id: any;
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  getRecommendedCourseID: string = "";
  getCartDataID: string = "";
  removeCartDataID: string = "";
  addCardPaymentID: string = "";
  getLastOrderDataStripeSubscriptionID: string = "";
  postAddToCartId: string = "";
  orderSummaryApiCallId: string = "";
  postCourseEnrollmentId: string = "";
  checkoutApiCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      orderConfirmationOpen: true,
      recommendedCourseData: [],
      recommendedLoader: true,
      proceedModal: false,
      cartType: "purchase",
      cartData: [],
      subscriptionCartData: [],
      cartDataLoader: true,
      selectedOrder: [],
      saveOrder: false,
      payableAmount: 0,
      paymentMethod: "debit",
      message: "",
      transitionError: "",
      stripeKey: "",
      removedCartItemId: "",
      orderSuccessMsg: "",
      orderFailureMsg: "",
      orderItems: [],
      selectedSubsCartItem: {
        id: "",
        price: "",
        purchasableId: 0,
        purchasableType: "",
        subscriptionPeriod: "",
      },
      lastSubscriptionOrder: {},
      orderConfirmed: false,
      //isModalOpen:false,
      // settype:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCartData();
    const orderSuccessPage = window.location.pathname.includes("order-success");
    const orderFailedPage = window.location.pathname.includes("order-failed");
    if (orderSuccessPage) {
      this.handleSubscriptionOrderSuccess("congrats");
    } 
     if (orderFailedPage) {
      this.handleOrderFaillure("Your order has failed. Please enter correct card details or check with your bank");
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getCartDataID:
          this.handleGetCartDataResponse(responseJson);
          break;
        case this.removeCartDataID:
          this.handleRemoveCartItemResponse(responseJson);
          break;
        case this.orderSummaryApiCallId:
          this.handleGetLastOrderDataResponse(responseJson);
          break;
        case this.getLastOrderDataStripeSubscriptionID:
          this.handleGetLastOrderDataStripeSubscription(responseJson);
          break;
        case this.postAddToCartId:
          this.handleAddToCartResponse(responseJson);
          break;
        case this.checkoutApiCallId:
          this.handleCheckoutApiResponse(responseJson);
          break;
        default:
          break;
      }
    }
  }

  // Get cart data
  getCartData = async () => {
    this.getCartDataID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCartDataApiEndPoint + `?full_details=${true}`,
    });
  }


  handleCheckoutApiResponse = (responseJson:any) => {
    if(responseJson.success && responseJson.data){
      window.location.href = responseJson.data.url
    }
  }

  // Handle cart data response
  handleGetCartDataResponse = (response: any) => {
    // Handle error
    if (response?.error) {
      this.setState({
        cartDataLoader: false,
      });
      toast.error("Something went wrong");
      return;
    }

    // Handle empty cart
    if (response?.message) {
      this.setState({
        cartData: [],
        subscriptionCartData: [],
        cartDataLoader: false,
        recommendedCourseData: [],
      });
      return;
    }

    // Handle cart data
    if (response?.data) {
      const { cart_item, subscription_cart_item } = response.data.attributes;
      this.setState({
        cartData: cart_item,
        subscriptionCartData: subscription_cart_item,
        cartDataLoader: false,
        recommendedCourseData: response?.meta?.recommended_course.data ?? [],
        payableAmount: response.data.attributes.total
      });
    }
  }

  // Remove item from cart
  removeCartData = async (save: any = "", itemID: any = "") => {
    this.removeCartDataID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.removeCartDataApiEndPoint +
      `?order_item_id=${itemID}`,
    });
  }

  // Handle Remove cart item api response
  handleRemoveCartItemResponse = (response: any) => {
    if (response && !response.error) {
      if(response.message === "Item removed successfully"){
        this.getCartData()
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  handleConfirmation = () => {
    this.setState({
      orderConfirmationOpen: false,
    });
    console.log("open");
  };

  handleConfirmationClose = () => {
    this.setState({
      orderConfirmationOpen: true,
    });
    console.log("open");
  };

  handleProceed = () => {
    this.setState({
      proceedModal: true,
    });
  }

  handleProceedModalClose = () => {
    this.setState({
      proceedModal: false,
    });
  };

  handleStripeSuccess = () => {
    this.setState({
      message: "Congratulations",
      transitionError: "",
      orderConfirmationOpen: true,
    });

    this.handleProceed();
    this.getCartData();
  };

  handleStripeFailure = () => {
    this.setState({
      message: "OOPS!",
    });
  };

  handleRemoveItemFromCart = (id: any) => {
    this.removeCartData(this.state.saveOrder, id);
    this.setState({
      cartDataLoader: true,
      removedCartItemId: id,
    });
  };

  handleBackHome = () => {
    this.props.navigation.navigate("Dashboard");
  };

  handleBackPersonal = () => {
    this.props.history.push("/library");
  };

  handleBackToCart = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
    this.setState({
      orderSuccessMsg: "",
      orderFailureMsg: "",
    });
  }

  handlePaymentMethod = (e: any) => {
    this.setState({
      paymentMethod: e.target.value,
    });
  };

  // Handle go back
  handleGoBack = () => {
    this.props.navigation.goBack();
  }

  // Handle order success
  handleOrderSuccess = (msg: string, lastSubsOrder?: any) => {
    if (this.state.cartType === "otp") {
      this.setState({
        orderSuccessMsg: msg,
        cartData: [],
      });
      return;
    }
    // Handle order success for subscription cart
    this.handleSubscriptionOrderSuccess(msg, lastSubsOrder);
  }

  // Handle order success for subscription cart
  handleSubscriptionOrderSuccess = (msg: string, lastSubsOrder?: any) => {
    const updatedSubsCartData = this.state.subscriptionCartData.filter(
      (item: any) => item.id !== this.state.selectedSubsCartItem.id
    );
    this.setState({
      orderSuccessMsg: msg,
      subscriptionCartData: updatedSubsCartData,
      lastSubscriptionOrder: lastSubsOrder ?? {},
    });
  }
  
  // Handle order failure
  handleOrderFaillure = (msg: string) => {
    this.setState({
      orderFailureMsg: msg,
    });
  }

  // Handle response of last order
  handleGetLastOrderDataResponse = (response: any) => {
    if (!response.data) {
      toast.error("Something went wrong");
      this.setState({
        cartDataLoader: false,
      });
      return;
    }
    this.setState({
      cartDataLoader: false,
      orderItems: response.data.attributes.order_items,
      cartType:response.data.attributes.order_type
    });
  }

  // Get last order info for stripe subscription
  getLastOrderDataStripeSubscription = async (sessionId: string) => {
    this.setState({ cartDataLoader: true });
    this.getLastOrderDataStripeSubscriptionID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.getLastOrderStripeSubscriptionApiEndPoint}?session_id=${sessionId}`,
    });
  }

  // Handle reponse of last order stripe subscription
  handleGetLastOrderDataStripeSubscription = (response: any) => {
    if (!response?.data) {
      this.setState({ cartDataLoader: true });
      toast.error("Something went wrong");
      return;
    }
    this.getCartData();
    const { purchasable_type, course_detail, program_detail } = response.data.attributes.subscription_cart_item[0].attributes; 
    const isCourse = purchasable_type === "BxBlockCoursecreation::Course"; 
    const lastOrder = {
      expand_type: purchasable_type,
      image: isCourse ? course_detail.thumbnail_image : program_detail.thumbnail_image,
      title: isCourse ? course_detail.title : program_detail.title,
      created_by_name: isCourse ? course_detail.created_by : program_detail.created_by,
    }
    this.setState({
      cartDataLoader: false,
      lastSubscriptionOrder: lastOrder,
    })
  }

  // Handle selection of subs cart items
  handleSubsCartItemsSelection = (item: SelectedSubsCartItem) => {
    this.setState({ selectedSubsCartItem: item });
  }

  // Handle cart type change
  handleCartTypeChange = (type: string) => {
    this.setState({ cartType: type });
  }

  // Handle order cofirmaiton
  handleOrderConfirmation = () => {
    this.setState({ orderConfirmed: true });
  }

  /**
   * Methods for recommended courses
   */

  // Add item to cart
  addToCart = async (id: any, type: string) => {
    const payload =
      type == "course_cart"
        ? {
          course_id: id,
        }
        : {
          program_id: id,
        };
    this.postAddToCartId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.getCartDataApiEndPoint,
      payload: JSON.stringify(payload)
    });
  };


  cartCheckout = async () => {
    this.checkoutApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.cartCheckoutEndPoint,
    });
  };

  // Handle add to cart response
  handleAddToCartResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart');
      this.getCartData();
    }
  }

  enrollToCourses = async (id: any) => {
    const payload = {
      "courseable_id": id,
      "courseable_type": "BxBlockCoursecreation::Course"
    }
    this.postCourseEnrollmentId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.courseEnrollAPiEndPoint,
      payload: JSON.stringify(payload)
    });
  }

  cartOrderDetails = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const orderId = queryParams.get("order_id");
    this.orderSummaryApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.orderSummaryOrder + orderId,
    });
  };
}
// Customizable Area End