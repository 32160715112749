import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import {
  ArrowBackIos,
  CheckCircleOutline,
  KeyboardArrowDown,
  Search,
} from "@material-ui/icons";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController.web";
import { Redirect } from "react-router-dom";
import AlertBlock from "../../alert/src/ActionAlert.web";
import "./Category.css";

export default class Category extends CategoriessubcategoriesController {
  handleSubmit() {
    const ids = this.state.experts.filter(
      (item: any) => item.selected === true
    );
    const expertId = ids.map((item: any) => item.id);
    const payload = { admin_ids: expertId };
    this.createExpert(payload);
  }
  handleChange(e: any) {
    this.getExpertSearchList(e.target.value);
    this.setState({ query: e.target.value });
  }
  render() {
    const selectCategory = (index: any) => {
      this.state.experts[index].selected = !this.state.experts[index].selected;
      this.setState({ experts: this.state.experts });
    };
    if (!this.state.isLoading) {
      if (
        this.state.is_category_selected === false &&
        this.state.is_expert_selected === false &&
        this.state.is_category_skipped === false
      ) {
        return <Redirect to="Onboardingguide" />;
      } else {
        if (
          this.state.is_category_skipped === true &&
          this.state.is_expert_skipped === true
        ) {
          return <Redirect to="Dashboard" />;
        }
        if (
          this.state.is_category_skipped === false &&
          this.state.is_expert_skipped === false
        ) {
          if (
            this.state.is_category_selected === true &&
            this.state.is_expert_selected === true
          ) {
            return <Redirect to="Dashboard" />;
          } else if (
            this.state.is_category_selected === false &&
            this.state.is_expert_selected === true
          ) {
            return <Redirect to="Onboardingguide" />;
          } else if (
            this.state.is_category_selected === true &&
            this.state.is_expert_selected === false
          ) {
            console.info("")
          }
        }
        if (
          this.state.is_category_skipped === true &&
          this.state.is_expert_skipped === false
        ) {
          if (this.state.is_expert_selected === true) {
            return <Redirect to="Dashboard" />;
          } else if (this.state.is_expert_selected === false) {
            console.info("")
          }
        }
        if (
          this.state.is_category_skipped === false &&
          this.state.is_expert_skipped === true
        ) {
          if (this.state.is_category_selected === true) {
            return <Redirect to="Dashboard" />;
          } else if (this.state.is_category_selected === false) {
            return <Redirect to="Onboardingguide" />;
          }
        }
      }
    }

    return (
      <>
        {this.state.isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={100} color="inherit" />
          </Box>
        ) : (
          <>
            <AlertBlock
              data={{
                show: this.state.alert.isOpen,
                close: () => {
                  this.setState({
                    alert: { isOpen: false, msg: "", type: "" },
                  });
                },
                msg: this.state.alert.msg,
                type: this.state.alert.type,
                vertical: "top",
                horizontal: "right",
              }}
            />
            <Grid
              container
              className="expert"
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 1,
                  mx: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AppBar position="static">
                      <Toolbar>
                        <IconButton
                          onClick={() => this.props.navigation.goBack(null)}
                          disabled={this.state.is_category_selected}
                        >
                          <ArrowBackIos className="arrow-icon" />
                        </IconButton>
                        <Typography
                          variant="h6"
                          component="div"
                          className="my-headings"
                          style={{
                            flexGrow: 1,
                          }}
                        >
                          Find An Xpert
                        </Typography>
                        <Button
                          className="skip-btn"
                          onClick={() => this.skipExpert()}
                        >
                          Skip
                        </Button>
                      </Toolbar>
                    </AppBar>
                  </Grid>
                </Grid>

                <Grid
                  style={{ flexGrow: 1, marginTop: "5px" }}
                  className="pl-45"
                  container
                  spacing={2}
                >
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder="Search"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.query}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            Advanced Search
                            <KeyboardArrowDown />
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  style={{
                    flexGrow: 1,
                    marginTop: "20px",
                    padding: "5px 35px 35px 35px",
                  }}
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Grid className="tiles-container" container spacing={2}>
                      {this.state.isText ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                            fontStyle: "normal !important",
                            fontWeight: 600,
                            fontSize: 48,
                            lineHeight: "72px",
                            letterSpacing: "-1px",
                            color: "#11142D !important",
                          }}
                        >
                          No Xperts Found
                        </div>
                      ) : (
                        this.state?.experts?.length > 0 &&
                        this.state.experts.map((value: any, index: any) => (
                          //@ts-ignore
                          <Grid
                            item
                            xs={12}
                            lg={3}
                            sm={4}
                            md={this.state.width < 1045 ? 4 : 3}
                            key={index}
                          >
                            <div
                              className={
                                value.selected
                                  ? "selected-image"
                                  : "no-selected-image"
                              }
                            >
                              <div
                                className={
                                  value.selected
                                    ? "selected-image2"
                                    : "no-selected-image2"
                                }
                              >
                                <Card className="custom-card card-outline">
                                  <CardActionArea
                                    onClick={() => selectCategory(index)}
                                  >
                                    <CardMedia
                                      className="card-media"
                                      component="img"
                                      image={
                                        "" +
                                        (value.attributes.avatar !== null
                                          ? value.attributes.avatar.url
                                          : value.img)
                                      }
                                      width="200"
                                      height="240"
                                      style={{ objectFit: "contain" }}
                                      title="hello"
                                    />
                                    <CardContent>
                                      <Typography
                                        className="title"
                                        variant="h6"
                                        gutterBottom
                                      >
                                        {value.attributes.first_name +
                                          value.attributes.last_name}
                                      </Typography>
                                      <Typography
                                        className="sub-title"
                                        variant="h6"
                                        gutterBottom
                                      >
                                        Philosopher
                                      </Typography>
                                      <Typography
                                        className="sub-text"
                                        variant="h6"
                                        gutterBottom
                                      >
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Habitant morbi tristique
                                        senectus et. Augue eget arcu dictum
                                        varius duis at consectetur lorem donec.
                                      </Typography>
                                      <div className="wrapper">
                                        <div
                                          className={
                                            value.selected
                                              ? "check"
                                              : "check-hide"
                                          }
                                        >
                                          <CheckCircleOutline
                                            className="check-circle-icon"
                                            style={{
                                              fontSize: "40px",
                                              color: "#fff",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </div>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid style={{ flexGrow: 1, marginTop: "20px" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className="proceed-content"
                    spacing={2}
                  >
                    {this.state.experts.length > 0 && (
                      <Grid container style={{ paddingBottom: "15px" }}>
                        <Button
                          fullWidth
                          disabled={
                            this.state.experts.some(
                              (item: any) => item.selected === true
                            )
                              ? false
                              : true
                          }
                          className={
                            this.state.experts.some(
                              (item: any) => item.selected === true
                            )
                              ? "proceed-btn"
                              : "proceed-btn-disabled"
                          }
                          onClick={() => this.handleSubmit()}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </>
    );
  }
}
// Customizable Area End
