export const defaultAvatar = require("../assets/defaultAvatar.png");
export const BoxImg = require("../assets/Starts.svg")
export const Reward = require("../assets/Reward.svg")
export const RightArrow = require("../assets/viewpassword.svg")
export const WhiteLogo = require("../assets/Whitelogo.svg")
export const Award = require("../assets/Awards.png")
export const CheckRewards = require("../assets/CheckRewards.png")
export const BackImg = require("../assets/Product_696-copia-1.png")
export const UserImage = require("../assets/Frame.png")
export const Starts = require("../assets/StarsNew.svg")
export const RewardRes= require("../assets/RewardRece.png")

export const Arrow = require("../assets/Arrow.svg")
export const Star = require("../assets/Star.svg")
export const Recieved = require("../assets//Recieved.svg")
export const InDesign = require("../assets/InDesign.svg")
export const Understand = require("../assets/Understand.svg")
export const habit = require("../assets/icon_goal_habit.svg")
export const achievement = require("../assets/icon_goal_achievement.svg")

export const Inprogress = require("../assets/Progress.svg")
export const Recived_yellow = require("../assets/Recived_yellow.svg")
export const Redeemed = require("../assets/Redeemed.svg")
export const Staged = require("../assets/staged.svg")
export const Gift = require("../assets/Gift.svg")
export const Spare = require("../assets/spare.svg")