
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { toast } from "react-toastify";
import { genCourseOrProgramUrl } from "../../utilities/src/CommonHelpers";
import React from "react";
const toastOptions: any = {
  position: "top-left"
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose: () => void;
  history: any;
  classes: any;
  open: boolean;
  fullScreen: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationData: any;
  listType: string;
  isLoading: boolean;
  selectedNotificationIds: number[];
  silentNotifications: any;
  notificationType: string;
  shouldSilence: boolean;
  notificationpageData:number;
  isLastPage:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationApiCallId: string = "";
  getSilanceNotificationAPI: string = "";
  getRemindMeApiCallId: string = "";
  deleteNotificationsApiCallId: string = "";
  silentNotificationsApiCallId: string = "";  
  divScrollRef: React.RefObject<HTMLDivElement>;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notificationData: [],
      listType: 'normal',
      isLoading: false,
      selectedNotificationIds: [],
      silentNotifications: [],
      notificationType: "",
      shouldSilence: false,
      notificationpageData:1,
      isLastPage:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.divScrollRef = React.createRef();
// Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotificationData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  preExistedMethod = (message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }
  }

  async receive(from: string, message: Message) {
    // this.preExistedMethod();
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // user notification api response
      switch (apiRequestCallId) {
        case this.getNotificationApiCallId:
          this.handleGetNotificationResponse(responseJson);
          break;
        case this.getRemindMeApiCallId:
          this.hanldeRemindeMeApiResponse(responseJson);
          break;
        case this.deleteNotificationsApiCallId:
          this.handleDeleteNotificationsResponse(responseJson);
          break;
        case this.silentNotificationsApiCallId:
          this.handleSilentNotificationsResponse(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End_______----____
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  // Common method for api calls
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, payload } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    payload &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        payload
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem('token')
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  handleNavigation = (data: any) => {
    this.handleNotificationClose();
    const { course_type, app_url: id, name = "title" } = data.attributes;
    const url = genCourseOrProgramUrl(course_type, id, name);
    this.props.history.push(url);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  // Reminde me
  callRemindMeAPI = async (data: any) => {
    this.setState({
      isLoading: true
    });

    const payload = {
      courseable_id: data.attributes.app_url,
      courseable_type:
        data.attributes.course_type === "BxBlockCoursecreation::Program"
          ? "BxBlockCoursecreation::Program"
          : "BxBlockCoursecreation::Course",
      reminder: true,
    };
  
    this.getRemindMeApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postDataMethod,
      endPoint: configJSON.remindMeEndPoint,
      payload: JSON.stringify(payload),
    });
  };

  // Handle reminde me api
  hanldeRemindeMeApiResponse = (response: any) => {
    if (response.success && !response.error) {
      this.setState({
        isLoading: false
      });
      toast.success("Reminder added", toastOptions);
      this.getNotifications();
    }
  }

  // Delete notifications 
  deleteNotifications = async () => {
    this.setState({
      isLoading: true
    });

    this.deleteNotificationsApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postDataMethod,
      endPoint: configJSON.deleteNotificationsEndpoint,
      payload: JSON.stringify({
        ids: this.state.selectedNotificationIds
      })
    });
  }

  // Silent notifications 
  silentNotifications = async (shouldSilence: boolean) => {
    // REMOVE THIS AFTER ADMIN SILENT API HAS BEEN DEVELOPED
    if (localStorage.getItem("user_role") === "super_admin") {
      toast.success("Coming soon", toastOptions);
      return;
    }

    this.setState({
      isLoading: true,
      shouldSilence,
    });

    this.silentNotificationsApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postDataMethod,
      endPoint: configJSON.silentNotificationsEndpoint,
      payload: JSON.stringify({
        data: this.state.silentNotifications,
        is_silence: shouldSilence
      })
    });
  }

  timeSince(date: string) {
    const seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }
  
  // get notification data
  getNotificationData = async () => {
    
    this.setState({
      isLoading: true
    });

    this.getNotificationApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getDataMethod,
      endPoint: configJSON.getNotificationsEndpoint + `?page=${this.state.notificationpageData}`,
    });
  }

  // Handle get notifications api response
  handleGetNotificationResponse = (response: any) => {
    if (response && !response.error) {
      let newList = response.data;
      newList.forEach((x: any) => {
        x.isSilent = false;
        x.isDelete = false;
        x.selectAll = false;
      });
      this.setState({
        notificationData: [...this.state.notificationData,...newList],
        isLoading: false,
        notificationpageData:response.meta.pagination.next_page,
        isLastPage: response.meta.pagination.next_page && response.meta.pagination.next_page <= response.meta.pagination.total_pages
      });
     
    }
  }

  // Close notification
  handleNotificationClose = () => {
    this.setState({
      listType: "normal",
      selectedNotificationIds: [],
      silentNotifications: []
    });
    this.props?.onClose && this.props.onClose();
  }

  // Select notifications to delete
  handleNotificationSelectionToDelete = (notificationId: number) => {
    const idExists = this.state.selectedNotificationIds.includes(notificationId);
    let updatedIds = [];
    if (idExists) {
      updatedIds = [...this.state.selectedNotificationIds].filter((id: number) => id !== notificationId);
    } else {
      updatedIds = [...this.state.selectedNotificationIds, notificationId];
    }
    this.setState({
      selectedNotificationIds: updatedIds
    });
  }

  // Select notifications to silent
  handleNotificationSelectionToSilent = (notification: any) => {
    let newSilentNotifications = [];
    const notificationExists = this.state.silentNotifications.find(
      (item: any) => item.id === notification.id
    );
    if (notificationExists) {
      newSilentNotifications = [...this.state.silentNotifications].filter(
        (item: any) => item.id !== notification.id
      );
    } else {
      newSilentNotifications = [
        ...this.state.silentNotifications,
        {
          id: notification.id,
          coursable_id: notification.app_url,
          coursable_type: notification.course_type,
          notificationType: notification.notification_type,
        },
      ];
    }
    this.setState({
      silentNotifications: newSilentNotifications,
    });
  };

  // Select all notifications to delete
  handleAllNotificationSelectionToDelete = () => {
    const { selectedNotificationIds, notificationData } = this.state;
    if (selectedNotificationIds.length === 0 || selectedNotificationIds.length < notificationData.length) {
      const allIds = notificationData.map((notification: any) => notification.attributes.id);
      this.setState({
        selectedNotificationIds: allIds
      });
    } else {
      this.setState({
        selectedNotificationIds: []
      });
    }
  }

  // Select all notifications to silent
  handleAllNotificationSelectionToSilent = () => {
    const { silentNotifications, notificationData } = this.state;
    if (silentNotifications.length === 0 || silentNotifications.length < notificationData.length) {
      const allNotifications = notificationData.map((notification: any) => {
        return {
          id: notification.attributes.id,
          coursable_id: notification.attributes.app_url,
          notificationType: notification.attributes.notification_type,
          coursable_type: notification.attributes.course_type,
        }
      });
      this.setState({
        silentNotifications: allNotifications
      });
    } else {
      this.setState({
        silentNotifications: []
      });
    }
  }

  // Handle delete notifications response
  handleDeleteNotificationsResponse = (response: any) => {
    if (!response?.message) {
      toast.error("Something went wrong", toastOptions);
      this.setState({ isLoading: false });
      return;
    }
    // Remove deleted notification from UI
    const { notificationData, selectedNotificationIds } = this.state;
    const newNotificationData = notificationData.filter(
      (notification: any) =>
        !selectedNotificationIds.includes(notification.attributes.id)
    );
    toast.success(
      `${
        selectedNotificationIds.length > 1 ? "Notifications" : "Notification"
      } deleted`, 
      toastOptions
    );
    this.setState({
      notificationData: newNotificationData,
      selectedNotificationIds: [],
      isLoading: false,
    });
  };
  
  // Handle silent notifications response
  handleSilentNotificationsResponse = (response: any) => {
    if (!response?.success) {
      toast.error("Something went wrong", toastOptions);
      this.setState({ 
        isLoading: false, 
        notificationType: "", 
        shouldSilence: false,
      });
      return;
    }

    // Show toast
    const { silentNotifications, shouldSilence } = this.state;
    const message = `${
      silentNotifications.length > 1 ? "Notifications" : "Notification"
    } ${shouldSilence ? "silenced" : "unsilenced"}`;
    toast.success(message, toastOptions);
    
    // Alter is_silence in state for all this.state.notificationType
    const { notificationData } = this.state;
    const updatedNotificationData = notificationData.map(
      (notification: any) => {
        if (
          silentNotifications.some(
            (n: any) =>
              n.notificationType === notification.attributes.notification_type
          )
        ) {
          return {
            ...notification,
            attributes: {
              ...notification.attributes,
              is_silence: this.state.shouldSilence,
            },
          };
        }
        return notification;
      }
    );

    // Reset state
    this.setState({
      silentNotifications: [],
      isLoading: false,
      notificationType: "",
      shouldSilence: false,
      notificationData: updatedNotificationData,
    });
  };

  // Dismiss notifications
  dismissNotification = async (id: number) => {
    this.setState({
      selectedNotificationIds: [...this.state.selectedNotificationIds, id]
    }, () => {
      this.deleteNotifications();
    });
  }

  // Silent individual notification
  silentSingleNotification = (notification: any, shouldSilence: boolean) => {
    // REMOVE THIS AFTER ADMIN SILENT API HAS BEEN DEVELOPED
    if (localStorage.getItem("user_role") === "super_admin") {
      toast.success("Coming soon", toastOptions);
      return;
    }
    const newSilentNotifications = [
      ...this.state.silentNotifications,
      {
        id: notification.id,
        coursable_id: notification.app_url,
        coursable_type: notification.course_type,
        notificationType: notification.notification_type,
      },
    ];
    this.setState({
      silentNotifications: newSilentNotifications,
      notificationType: notification.notification_type,
    }, () => {
      this.silentNotifications(shouldSilence);
    });
  }


   handleScroll = () => {
    const container = this.divScrollRef.current;
    if (this.state.isLastPage && container && container.scrollTop + container.clientHeight >= container.scrollHeight - 5 && !this.state.isLoading) {
      this.getNotificationData();
    }
};

  handleNotificationsDivScroll = () => {
    const container = this.divScrollRef.current;
    if (container) {
      container.addEventListener("scroll", this.handleScroll);
    }
  }

  handleNotificationsDivScrollRemove = () => {
    const container = this.divScrollRef.current;
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  }

  async componentWillUnmount() {
    this.handleNotificationsDivScrollRemove()
  }
  // Customizable Area End
}

