import { CalendarValue, ContentData } from "../../../blocks/videolibrary/src/VideoLibraryController";
import { CheckboxSelectedListType } from "./CustomFormController.web";

export interface FilterParamsProps {
    contentCreationDate: Array<CheckboxSelectedListType>;
    creationDateParams: { start_date: string; end_date: string };
    creationNameParams: string;
    tagParams: string
}

export interface RequestResponse {
    error: unknown;
    errors: unknown;
    data: unknown
}

export interface ApiResponse {
    data: any;
    error: { message: string };
    errors: Array<ApiResponse["error"]>
}


export const getSearchParams = (search: string) => {
    let result = ""

    if (search) {
        result += `&search=${search}`
    }
    return result
}

export const getContentData = (contentData: Array<ContentData>, selectedId: number) => {
    const nonSelected = contentData.find(dataa => dataa.id !== 0)
    const selectedInfo = contentData.find(dataa => dataa.id === selectedId)
    const audioFileName = contentData.find(data => data.id === selectedId)?.attributes.title
    const expertName = contentData.find(data => data.id === selectedId)?.attributes.expert_name
    const createdAt = contentData.find(data => data.id === selectedId)?.attributes.created_at

    return {
        nonSelected,
        selectedInfo,
        audioFileName,
        expertName,
        createdAt
    }
}

export function areAllTruthy(...args: unknown[]): boolean {
    return args.every(arg => Boolean(arg));
}

export const getFilterParams = (props: FilterParamsProps) => {
    const { contentCreationDate, creationDateParams, creationNameParams, tagParams } = props
    let params = ""
    let date = ""
    let dateRange = ""
    const nameParam = creationNameParams ? `&${creationNameParams}` : ''
    const tagParam = tagParams ? `&${tagParams}` : ''

    switch (contentCreationDate?.[0]?.value) {
        case "Last Week":
            date = "last_week";
            break;
        case "Last Month":
            date = "last_month";
            break;
        case "Last Year":
            date = "last_year";
            break;
        default:
            break
    }

    const { start_date, end_date } = creationDateParams;

    if (start_date && end_date) {
        const rangeObj = {
            start_date: start_date,
            end_date: end_date
        };
        const queryString = encodeURIComponent(JSON.stringify(rangeObj));
        dateRange = `&q[custom_range]=${queryString}`;
    }
    const dateParams = date ? `&q[created_at]=${date}` : dateRange

    params = params + dateParams + nameParam + tagParam

    return params
}

export const getDateTransform = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNums = startDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartYear = startDate.getFullYear();
    const formattedStartMonthNumber = startDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedEndDateNumber = endDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndDate = endDate.getDate();
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedEndYear = endDate.getFullYear();
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNumber}-${formattedStartDateNums}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNumber}`;

    return {
        dateRange,
        start_date,
        end_date
    }
}

export const isRequestSuccess = (response: RequestResponse) => {
    if (response.error || response.errors) {
        return false
    }
    return true
}

export const transformContentData = (content: Array<{ id: number; name: string; title: string; audioName: string; created_at: string }>) => {
    const selectedId = -1
    const nonSelected1 = content.filter(data => data.id !== 0)[0].id
    const selectedInfo1 = content.filter(data => data.id === selectedId)[0].audioName
    const audioFileName1 = content.filter(data => data.id === selectedId)[0].audioName
    const expertName1 = content.filter(data => data.id === selectedId)[0].name
    const createdAt1 = content.filter(data => data.id === selectedId)[0].created_at

    const contentCreatedAt = content.filter(data => data.id !== 0)[0].created_at
    const contentAudioName = content.filter(data => data.id === selectedId)[0].audioName
    const contentAudioTitle = content.filter(data => data.id === selectedId)[0].title
    const contentExertName = content.filter(data => data.id === selectedId)[0].name
    const contentAudioCreated = content.filter(data => data.id === selectedId)[0].created_at

    const timeStamp = content.filter(data => data.id !== 0)[0].created_at
    const fileName = content.filter(data => data.id === selectedId)[0].audioName
    const fileSubTiTle = content.filter(data => data.id === selectedId)[0].title
    const fileUploadedTime = content.filter(data => data.id === selectedId)[0].created_at
    const selectedFileTime = content.filter(data => data.id === selectedId)[0].created_at

    const finalName = expertName1 + contentExertName + fileName
    const finalUpatedTime = timeStamp + contentCreatedAt

    return {
        nonSelected1, selectedInfo1, audioFileName1, expertName1, createdAt1,
        contentCreatedAt, contentAudioName, contentAudioTitle, contentExertName, contentAudioCreated,
        timeStamp, fileName, fileSubTiTle, fileUploadedTime, selectedFileTime,
        finalName, finalUpatedTime
    }
}

export const getDateRangeValueTransform = (value: CalendarValue) => {
    const begin = new Date(value[0]);
    const endDate = new Date(value[1]);
    const startY = begin.getFullYear();
    const startD = begin.getDate();
    const endDateFormated = endDate.getDate();
    const startMonth = begin.toLocaleDateString("en-US", { month: "2-digit" });
    const endMonth = endDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedEndYear = endDate.getFullYear();
    const range = `${startD}-${endDateFormated} ${endMonth} ${formattedEndYear}`;
    const start = `${startY}-${startMonth}`;
    const end = `${formattedEndYear}-${formattedEndMonth}`;
    return {
        range,
        start,
        end
    }
}