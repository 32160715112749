import React from "react";
import { Button, Dialog, Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TopBanner, TopBannerMobile, Crown } from "./assets";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  image: string;
}

export default function ComingSoonDialog({
  open,
  image,
  title,
  description,
  onClose,
}: Props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.dialog}
        PaperProps={{
          className: classes.paper,
        }}
        fullWidth
        maxWidth="md"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent className={classes.content}>
          <div className={classes.crownContainer}>
            <img src={Crown} alt="crown" />
          </div>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>coming soon</Typography>
          </div>
          <Typography className={classes.tagline}>
            Xpand Your Life like never before
          </Typography>
          <div className={classes.descriptionContainer}>
            <img src={image} alt={title} className={classes.descriptionImg} />
            <Typography className={classes.descriptionTitle}>
              {title}
            </Typography>
            <Typography className={classes.descriptionContent}>
              {description}
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              className={classes.btn}
              onClick={onClose}
            >
              okay
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiPaper-rounded": {
        borderRadius: "20px",
        [theme.breakpoints.up("sm")]: {
          borderRadius: "15px",
        },
      },
    },
    paper: {
      overflow: "visible",
      maxWidth: "724px",
    },
    content: {
      backgroundImage: `url(${TopBannerMobile})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      position: "relative",
      overflow: "visible",
      textAlign: "center",
      top: "-10px",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${TopBanner})`,
        top: "-10px"
      },
    },
    crownContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: "-45px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    titleContainer: {
      maxWidth: "200px",
      margin: "0 auto",
    },
    title: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      color: "#FFFFFF",
      borderBottom: "1px solid white",
      textTransform: "uppercase",
      marginTop: 15,
      paddingBottom: 5,
    },
    tagline: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#FFFFFF",
      marginTop: 10,
    },
    descriptionContainer: {
      // marginTop: 60
    },
    descriptionImg: {
      margin: "60px 0 30px 0",
    },
    descriptionTitle: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#6C328B",
      textTransform: "uppercase",
    },
    descriptionContent: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      textAlign: "center",
      color: "#1A1A1A",
      marginTop: 5,
    },
    btn: {
      maxWidth: "200px",
      background: "#FF8B3F",
      borderRadius: "24px",
      color: "white",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "16px",
      margin: "25px 0",
      padding: "15px",
      border: "1px solid #FF8B3F",
      "&:hover": {
        background: "#FF8B3F",
      },
    },
  })
);
