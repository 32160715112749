import React from "react";
import { Box, Card, Grid, Typography, IconButton, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import { formatDurationToString } from "../../../utilities/src/CommonHelpers";
import { 
  courseplayIcon, 
  objectIcon, 
  playIconYellow, 
  fileIcon, 
  fileDownloadIcon, 
  checkedIcon, 
  unCheckedIcon,
  checkedIconLarge,
  unCheckedIconLarge, 
} from "../assets";

interface Attachment {
  attachment: string;
  file_name: string;
  page_count: number;
}

interface CCard {
  resourceType: string;
  courseContent: any;
  thumbnailImg: string;
  title: string;
  description: string;
  rating: number;
  author: string;
  attachment?: Attachment;
  isLocked: boolean;
  isCompleted: boolean;
  isEnrolled: boolean;
  handleNavigate: () => void;
  handleObjectNavigation: () => void;
  hightlight: boolean;
}

export default function CourseCard({ 
  resourceType,
  courseContent,
  thumbnailImg,
  title,
  description,
  rating,
  author,
  attachment,
  isLocked,
  isCompleted,
  isEnrolled,
  handleNavigate,
  handleObjectNavigation,
  hightlight,
}: CCard) {
  const classes = useStyles();
  let actionIcon = courseplayIcon;
  if (resourceType === "object") actionIcon = objectIcon;

  let courseContentUI = null;
  if (courseContent) {
    courseContentUI = courseContent.map((content: any, index: number) => {
      const durationStr = formatDurationToString(
        content.type === "videos" ? content.size : content.duration * 60,
      );
      return (
        <Box key={content.title} className={classes.videoInfo}>
          <Box className={`${classes.flex} ${classes.justifyStart}`}>
            {
              isEnrolled && (
                <Checkbox 
                  size="small"
                  disabled
                  checked={!!content?.is_completed} 
                  icon={<img src={unCheckedIcon} alt="uncheck-icon" />}
                  checkedIcon={<img src={checkedIcon} alt="check-icon" />}
                />
              )
            }
            <Box>
              <Typography className={`${classes.textL} ${classes.textLBold}`}>
                {`${index + 1}. ${content.title}`}
              </Typography>
              <Box className={classes.ratingContainer}>
                <img
                  src={playIconYellow}
                  alt="play icon"
                  className={classes.playIconYellow}
                />
                <Typography
                  className={`${classes.textL} ${classes.textLGrey}`}
                >
                  {content.type === "quiz"
                    ? `${content.number_of_questions} questions`
                    : "1 video"}{" "}
                  | {durationStr}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    })
  }

  const attachmentExtension = attachment?.file_name
    .split(".")
    .pop()
    ?.toUpperCase();
  let pageNumber;
  if (attachmentExtension !== "PDF") pageNumber = null;
  else {
    pageNumber =
      attachment?.page_count === 1
        ? `| 1 page`
        : `| ${attachment?.page_count} pages`;
  }

  let attachmentDownloadContent;
  if (isEnrolled) {
    attachmentDownloadContent = (
      <a 
          role="link"
          href={attachment?.attachment} 
          target="_blank"
        >
          <img src={fileDownloadIcon} alt="file download icon" />
        </a>
    );
  } else {
    attachmentDownloadContent = <img src={fileDownloadIcon} alt="file download icon" />
  }

  let attachmentContent = (
    <Box className={classes.videoInfo}>
      <Box className={`${classes.flex} ${classes.attachmentContainer}`}>
        {
          isEnrolled && (
            <Checkbox 
              size="small" 
              disabled
              checked={isCompleted} 
              icon={<img src={unCheckedIcon} alt="uncheck-icon" />}
              checkedIcon={<img src={checkedIcon} alt="check-icon" />}
            />
          )
        }
        <Box className={`${classes.textL} ${classes.textLBold} ${classes.longText}`}>
            1. {attachment?.file_name} 
          <Box className={classes.ratingContainer}>
            <img
              src={fileIcon}
              alt="file icon"
              className={classes.playIconYellow}
            />
            <Typography
              className={`${classes.textL} ${classes.textLGrey}`}
            >
              {attachmentExtension} file {pageNumber}
            </Typography>
          </Box>
        </Box>
        {attachmentDownloadContent}
      </Box>
    </Box>
  )

  const shortDescription = `${description.slice(0, 50)}...`;
  return (
    <Card className={clsx(classes.root, hightlight && classes.rootChecked)}>
      <Grid container spacing={2}>
        <Grid item className={classes.flex}>
          {
            isEnrolled && (
              <Checkbox
                disabled 
                checked={isCompleted} 
                icon={<img src={unCheckedIconLarge} alt="uncheck-icon" />}
                checkedIcon={<img src={checkedIconLarge} alt="check-icon" />}
              />
            )
          }
          <div className={classes.thumbnailContainer}>
            <img
              className={classes.thumbnail}
              alt="complex"
              src={thumbnailImg}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm container alignItems="flex-end" spacing={2}>
          <Grid item xs={12} lg={10} container direction="column" spacing={2}>
            <Grid item xs>
              <Typography className={classes.textXL}>{title}</Typography>
              <Typography className={classes.textMD}>{shortDescription}</Typography>
              <Box className={classes.ratingContainer}>
                <span className={classes.textSM}>{rating}</span>
                <Rating
                  name="rating"
                  size="small"
                  defaultValue={rating}
                  precision={0.5}
                  readOnly
                />
              </Box>
              <Typography className={classes.textXS}>
                {author}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={2}
            justifyContent="center"
            className={classes.actionIconGrid}
          >
            <IconButton 
              disabled={isLocked} 
              onClick={
                resourceType === "object" 
                  ? handleObjectNavigation 
                  : handleNavigate
              }
            >
              <img
                src={actionIcon}
                alt="action icon"
                className={classes.actionIcon}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.videosContainer}>
        {attachment ? attachmentContent : courseContentUI}
      </Box>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid rgba(225, 225, 225, 0.58)",
    background: "#FFF",
    boxShadow: "0px 0px 16px 0px rgba(213, 213, 213, 0.35)",
  },
  rootChecked: {
    border: "1px solid #6C328B",
    boxShadow: "0px 0px 16px 0px rgba(108, 50, 139, 0.33)",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  textXL: {
    color: "#1A1A1A",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "28px",
    textTransform: "capitalize",
    marginBottom: "5px !important",
  },
  textL: {
    color: "#1A1A1A",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "capitalize",
    margin: "5px 0 !important",
  },
  textLBold: {
    fontWeight: 700,
  },
  textLGrey: {
    color: "#627078",
    textTransform: "none",
  },
  textMD: {
    color: "#1A1A1A",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    marginBottom: "5px !important",
  },
  textSM: {
    color: "#1A1A1A",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "16px",
    marginRight: 5,
  },
  textXS: {
    color: "#1A1A1A",
    fontSize: "9px",
    fontWeight: 600,
    lineHeight: "normal",
    marginTop: "10px !important",
  },
  longText: {
    // width: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // [theme.breakpoints.up("sm")]: {
    //   width: "100%"
    // }
  },
  ratingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  star: {
    fontSize: "15px",
    color: "#f9b651",
  },
  thumbnailContainer: {
    width: 84,
    height: 84,
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    objectIconFit: "cover",
    borderRadius: 8,
  },
  actionIconGrid: {
    justifyContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },
  actionIcon: {
    height: "32px",
    width: "32px",
    cursor: "pointer",
  },
  videosContainer: {
    padding: 15,
  },
  videoInfo: {
    paddingTop: 20,
    paddingLeft: 0,
    "&:nth-child(1)": {
      borderTop: "1px solid #E6E8EC",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 20,
    }
  },
  playIconYellow: {
    marginRight: 10,
  },
  attachmentContainer: {
    "& > *": {
      marginRight: "5px !important"
    },
    [theme.breakpoints.up("sm")]: {
      "& > *": {
        marginRight: "20px !important"
      },
    }
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  justifyStart: {
    justifyContent: "flex-start",
  }
}));