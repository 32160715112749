import React from "react";

import {
  Box,
  // Customizable Area Start
  Dialog, DialogTitle, DialogContent,Popover,Typography, DialogActions,Radio,RadioGroup,FormControlLabel,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled} from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { NewFilter } from "../../../../packages/blocks/catalogue/src/assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import { Filter,BackArrow ,UpArrow,DownArrow,thumbnailImg} from "./assets";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../../components/src/CustomTableV3";
const headershow=[
  { id: 1, label: "Name", sort: true, dataType: "string", key: "title" },
  { id: 2, label: "Created by", sort: true, dataType: "string", key: "created_by" },
  { id: 3, label: "Created on", sort: true, dataType: "date", key: "created_at" },
 ]
 import FilterItems from "../../filteritems/src/FilterItems.web"
 import Loader from "../../../components/src/Loader"
// Customizable Area End

import CommonSelectIconFromLibraryController, {
  Props
} from "./CommonSelectIconFromLibraryController.web"

export default class CommonSelectIconFromLibrary extends CommonSelectIconFromLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
renderTableBody=()=>{
    return(
      <>
        {this.state.iconList?.map((user:any) => { 
           const BgColors=this.state.selectRadio===user.id?true:false
              return (
                <Tr key={user.id} rowBg={BgColors} RowClick={()=>this.RowClick(user.id)}>
                  <Td rowBg={BgColors}>
                  <RadioGroup
                   aria-labelledby="demo-error-radios"
                   name="quiz"
                   value={this.state.selectRadio}
                   onChange={this.handleRadioChange}
                   data-test-id={`radio_select${user.id}`}
                   >
                  <FormControlLabel  value={user.id} control={<Radio />} label="" />
                  </RadioGroup>
                  <span>
                  <img
                      src={user.attributes?.image?.url}
                      style={{
                        borderRadius: '4px',
                        border: '1px solid #929090',
                        background: 'lightgray 50% / cover no-repeat',
                        boxShadow: '0px 1px 3px 0px rgba(140, 140, 140, 0.72)',
                        height: '56px',
                        width: '56px',
                        marginRight:"20px"
                      }}
                    />
                  </span>
                  </Td>
                  <Td rowBg={BgColors}>
                    {user.attributes.title}
                    </Td> 
                    <Td rowBg={BgColors}>
                    {user.attributes.created_by}
                    </Td>  
                    <Td rowBg={BgColors}>
                    {user.attributes.created_at
                      ? this.formatDate(user.attributes.created_at)
                      : "-"}
                    </Td>     
                </Tr>)})}
      </>
    )
  }
  advancedSearch=()=>{
    const openFilterContainer = Boolean(this.state.anchorFilterContentEl)
    return(<StyledContentWrapper>
      <AdvancedSearch
    data-test-id="btn_change_one"
    searchInputValue={this.state.searchAdvanceValue}
    handleInputChange={this.handleInputChangeSearch}
    handleListItemClick={this.handleThumbnailListClick}
    filterList={this.state.filteredContentLists}
    inputWidth={window.innerWidth<800?"240":"480"}
    isFilterDropActive={openFilterContainer}
    showList={this.state.showContentLists} navigation={undefined} id={""} />
     <div style={{ marginLeft: "0px" }} className={this.state.anchorFilterContentEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
    {this.state.creationThumbnailDate.length > 0 || (this.state.creationDateParamsdata.hasOwnProperty('start_date') && this.state.creationDateParamsdata.start_date.length > 0) || this.state.placeholderSelectItems.length > 0 ? (
      <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
      ""
    )
    }
    {this.state.anchorFilterContentEl ? (
      <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
    ) : (
      <img src={NewFilter} data-test-id="filterOpenTestID" />
    )}
  </div>
  <Popover 
    data-test-id="filterPropsID"
    className="filterContainerPopover"
    open={openFilterContainer}
    id="myPopover"
    anchorEl={this.state.anchorFilterContentEl}
    onClose={this.filterContainerDropdownCloseHandlers}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  
  >
    <FilterItems
      isThumbnail
      navigation={this.props.navigation}
      data-test-id="FilterItemsTestID"
      handleClick={this.handleClickFilters}
      placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandlers}
      handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChanges}
      dropdownOpenHandler={this.dropdownHandlerOpens}
      creationDateChangeHandler={this.creationChangeHandlerDates}
      dropdownCloseHandler={this.dropdownHandlerClose}
      handleCalendarOpen={this.handleOpenCalendors}
      anchorEl={this.state.anchorEl}
      dropdownType={this.state.dropdownType}
      placeholderSelectedItems={this.state.placeholderSelectItems}
      placeholderSearchText={this.state.placeholderSearchTexts}
      creationDate={this.state.creationThumbnailDate}
      expertAdminList={this.state.expertAdminLists}
      isCalendarOpen={this.state.isCalendarOpens}
      handleClear={this.handleClearFilters}
      handleCalendarChange={this.handleChangesCalendors} />
  </Popover>
    </StyledContentWrapper>)
    
  }

  renderContent = () => {
    if (this.state.isLoading) {
      // Loader that will be shown until the thumbnails are fetched
      return <Loader loading={this.state.isLoading} />
    } else if (!this.state.isLoading && this.state.iconList && this.state.iconList.length > 0) {
      return (
        <>  
        <DialogContent>
          <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                  <Th>
                  </Th>
              {headershow?.map((header: any) => {
                  return(
                      <Th>
                      <p className="headingLabel">
                        {header.label}{" "}
                        <span className="arrowIconsContainer">
                          {header.sort && (
                            <>
                              <img
                                data-test-id={`sort_asc${header.label}`}
                                src={DownArrow}
                                width="12px"
                                onClick={() =>
                                  this.sortUsers(
                                    header?.dataType ?? "",
                                    header?.key ?? "",
                                    "asc"
                                  )
                                }
                              />
  
                              <img
                              data-test-id={`sort_desc${header.label}`}
                                src={UpArrow}
                                width="12px"
                                onClick={() =>
                                  this.sortUsers(
                                    header?.dataType ?? "",
                                    header?.key ?? "",
                                    "desc"
                                  )
                                }
                              />
                            </>
                          )}
                        </span>
                      </p>
                    </Th>
                  )})}
            
              </Tr>
            </Thead>
            <Tbody>
              {this.renderTableBody()}
            </Tbody>
          </Table>
          </TableWrapper>
          </DialogContent>
              <DialogActions className="btnWrapper">
                    <button 
                      className={this.state.selectRadio?"enablebtn btn":"btn"}
                          onClick={() => this.handleSelectIcon()}
                          disabled={this.state.selectRadio?false:true}
                          data-test-id="select_btn"
                      >
                        Select Icon
                      </button>
          </DialogActions> 
        </>
      )
    } else {
      return (
      <  AddIconNotFoundBoxs>
        <AddIconInnerBoxs>
          <Box>
            <AddImageComponents src={thumbnailImg} />
          </Box>
          <AddIconNotFoundTexts>No results found</AddIconNotFoundTexts>
        </AddIconInnerBoxs>
      </  AddIconNotFoundBoxs>
      )
    }
  }
  // Customizable Area End

  render() {
       // Customizable Area Start
   
      // Customizable Area End
    return (
      // Customizable Area Start
      <>
          <Box>
          <DialogWrapper
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"0"}}>
           <Box className="heading_wrapper">
                <Box className="heading_inner">
                    <span style={{cursor:"pointer"}}>
                     <img src={BackArrow} style={{cursor:"pointer"}} alt="back_ing" onClick={() => this.props.CloseModal()} />
                    </span>
                    <p className="heading_text">
                    Icon Library
                    </p>
                </Box>
                <Box>
                {this.advancedSearch()}
                </Box>
           </Box>
        </DialogTitle>
        {this.renderContent()}
      </DialogWrapper> 
          </Box>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AddImageComponents = styled("img")({
  macWidth:"100%",
  height:"auto",
});

const   AddIconNotFoundBoxs = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const AddIconInnerBoxs = styled(Box)({
  marginTop: "40px"
});

const AddIconNotFoundTexts = styled(Typography)({
  marginTop: "26px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-1px"
});
const BadgeComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
  }
});
const DialogWrapper=styled(Dialog)({
    "& .MuiDialog-paper":{
      maxWidth:"1275px",
      width:"100% !important",
     height:"100%",
     borderRadius:"16px",
     padding:"30px",
     marginLeft:"96px",
     marginTop:"35px",
     "@media(max-width:600px)":{
      margin:"10px",
      marginTop:"80px"
     }, 
    },
    "& .heading_wrapper":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"20px",
        "@media(max-width:1120px)":{
         flexDirection:"column",
         alignItems:"flex-start",
         gap:"20px"
        }, 
        "@media(max-width:600px)":{
          alignItems:"center",
      
         }, 
    },
    "& .heading_inner":{
        display:"flex",
        alignItems:"center",
        gap:"5px"
    },
    "& .heading_text":{
      fontSize:"48px",
      fontWeight:700,
      fontFamily:"Poppins",
      margin:0,
      padding:0,
    
      "@media(max-width:1250px)":{
        fontSize: "35px",
       }, 
      "@media(max-width:600px)":{
        fontSize: "22px",
      }, 
    },
    "& .btnWrapper":{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center", 
        "@media(max-width:600px)":{
          justifyContent:"center",
        }, 
    },
    "& .btn": {
        marginTop:"30px",
        width:"228px",
        height:"56px",
        backgroundColor: "#E5E2E1",
        border: "0px",
        borderRadius: "16px",
        color: "#787776",
        fontFamily: "'Poppins', sans-serif" as const,
        fontSize: "16px",
        fontWeight: 600 as const,
        textTransform: "capitalize" as const,
      },
      "& .enablebtn":{
        backgroundColor: "#652786 !important",
        cursor:"pointer",
        color:"#fff"
      },
      "& .headingLabel":{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
       textAlign:"start",
        "@media(max-width:600px)":{
          fontSize: "18px",
          minWidth:"140px"
        }, 
      },
      "& .arrowIconsContainer":{
        display: "flex",
        flexDirection: "column",
        margin: "3px 0px 0px 10px",
        "& > img": {
          cursor: "pointer",
        },
        "& > img:first-child": {
          marginBottom: 5,
        },
      },
      "& .MuiRadio-root":{
         color:"#787776",
         padding:"18px 20px"
      },
      "& .MuiFormControlLabel-root":{
      margin:"0px"
      },
      "& .MuiRadio-colorSecondary.Mui-checked":{
        color:"#652786",
        background:"transparent"
      },
      "& .MuiDialogContent-root":{
        padding:"0px"
      }
  }) 
  const TableWrapper = styled(Box)({
    width: "100%",
    "& .tr":{
        borderRadius:"12px",
    },
    "& .td":{
        textAlign:"start"
    },
    "& .table tbody .tr":{
      cursor:"pointer"
  },
    "& .td:first-child":{
      display:"flex",
      minWidth:"120px",
      borderBottomLeftRadius: "12px",
     borderTopLeftRadius: "12px"
    },
    "& .td:last-child":{
        borderBottomRightRadius: "12px",
       borderTopRightRadius: "12px"
      },
    "& .table tbody .tr .td:nth-child(3) ,.td:nth-child(4)":{
      width:"320px",
      minWidth:"200px",
      paddingLeft:"20px"
      },
    "& .table thead .tr .th:nth-child(1)":{
       width:"120px"
      },
      "& .table tbody .rowBg":{
      backgroundColor:"#EEE8F2 !important",
      },
    "& .table thead .tr .th:nth-child(2)":{
      "& p":{
        marginLeft:"20px",
        minWidth:"200px",
        justifyContent: "flex-start !important",
      }
    },
    "& .table tbody .tr .td:nth-child(2)":{
      textAlign:"start",
      paddingLeft:"20px"
    },
   "& .BackgroundCss":{
        background:"#EEE8F2"
    }
  }) 
  const StyledContentWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    width: "100%",

    "& .filterIconBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        width: "63px",
        borderRadius: "8px",
        borderTopLeftRadius: "8px !important",
        borderBottomLeftRadius: "8px !important",
        cursor: "pointer",
        border: "1px solid #C9C6C5",
        position: "relative",
        zIndex: 999,
        left: "-1%"
    },
    "& .filterIconBox:hover": {
        borderColor: '#652786'
    },
    "& .allBorder": {
        borderColor: '#652786',
        backgroundColor: "#EEE8F2",
    }
});
// Customizable Area End
