// Customizable Area Start
import React from "react";
import "./EmailAccountRegistration.css";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  Checkbox,
  InputAdornment,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import "../../../../node_modules/flag-icons/css/flag-icons.min.css";
import { withStyles, styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as yup from "yup";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Dialog from '@material-ui/core/Dialog';
import { LoginImage, SkipIcon } from "./assets";
import AccountSetupController, {
  Props,
} from "./AccountSetupController.web";
import TermPageWeb from "../../splashscreen/src/TermPage.web";

const CheckboxWithCustomCheck = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #802F88",
        borderRadius: 3,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 3,
        top: 2,
        height: 8,
        width: 8,
        position: "absolute",
        backgroundColor: "#802F88",
        zIndex: -1,
        borderColor: "#802F88",
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "1px solid #802F88",
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 5,
      width: 5,
      position: "absolute",
      backgroundColor: "#fff",
      zIndex: -1,
      borderColor: "#802F88",
    },
  },
  checked: {},
})(Checkbox);
const MainSelect = styled(Box)({
  position: "relative",
  width: "100%",
  "& .select_main": {
    position: "relative",
    width: "100%",
    "& .MuiListItem-gutters": {
      paddingLeft: "2px !important",
      paddingRight: "8px !important",
      gap: "10px !important",
      color: "#6C328B !important",
      fontSize: "14px !important",
      fontWeight: 500
    }
  },
})

const CustomSelect = styled(Select)({
  '& .MuiSelect-root': {
    position: 'relative',
  },
  '& .MuiPopover-paper': {
    position: 'relative',
    zIndex: 999,
  },
  "& .MuiList-padding": {
    paddingTop: "0px"
  },
  "& .MuiListItem-gutters": {
    paddingLeft: "2px !important",
    paddingRight: "8px !important",
    gap: "10px !important",
    color: "#6C328B !important",
    fontSize: "14px !important",
    fontWeight: 500
  }
})
const CustomMenuProps = {
  PaperProps: {
    style: {
      height: "auto" as const,
      marginTop: '22px' as const,
      marginLeft: "40px" as const,
      width: "86px" as const,
      minWidth: "86px",
      left: "10px"
    },
  },
  getContentAnchorEl: null
};

class AccountSetup extends AccountSetupController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    const { firstName, lastName, email, countryCode, phone } = this.state;
    const initialValues = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      mobileNumber: phone,
      showPassword: false,
      password: "",
      confirmPassword: "",
      showConPassword: false,
      CountryCode: countryCode,
      acceptTerms: false,
    };
    return (
      <>
        <Grid
          container
          style={{ height: "100vh", width: "100vw" }}
          className="register"
        >
          <Grid
            xs={"auto"}
            sm={4}
            lg={3}
            style={{
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            lg={9}
            component={Paper}
            elevation={6}
            square
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values) => {
                  this.handleSubmit(values);
                }}
                validationSchema={yup.object().shape({
                  firstName: yup
                    .string()
                    .required("First name is required")
                    .matches(/^[a-zA-Z\s'-]+$/, 'First name can only include alphabetic characters, spaces, hyphens, and apostrophes')
                    .min(2, 'First name must be between 2 and 50 characters long')
                    .max(50, 'First name must be between 2 and 50 characters long'),
                  lastName: yup.string()
                    .required("Last name is required")
                    .matches(/^[a-zA-Z\s'-]+$/, 'Last name can only include alphabetic characters, spaces, hyphens, and apostrophes')
                    .min(2, 'Last name must be between 2 and 50 characters long')
                    .max(50, 'Last name must be between 2 and 50 characters long'),
                  mobileNumber: yup
                    .string()
                    .matches(
                      /^\(\d{3}\)\s\d{3}\s\d{4}$/,
                      "Mobile number must be in the format (xxx) xxx xxxx"
                    )
                    .required("Mobile number is required"),
                  password: yup
                    .string()
                    .matches(
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    )
                    .required("Password is required"),
                  confirmPassword: yup
                    .string()
                    .required("Confirm Password is required")
                    .oneOf(
                      [yup.ref("password"), null],
                      "Passwords do not match"
                    ),
                  acceptTerms: yup
                    .bool()
                    .oneOf([true], "Please accept the Terms & Conditions"),
                })}
              >
                {(formikProps) => {
                  const {
                    values,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = formikProps;
                  return (
                    <form className="regform" onSubmit={handleSubmit}>
                      <Grid item xs={8} className="typo">
                        <Typography
                          component="h6"
                          variant="h5"
                          className="typo_title"
                        >
                          Sign up
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          className="typo_sub_title"
                        >
                          Please enter your personal details
                        </Typography>
                      </Grid>
                      <Grid className="form" container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel>Email</InputLabel>
                          <TextField
                            disabled
                            id="email"
                            name="email"
                            className="email-input"
                            autoComplete="on"
                            value={values.email}
                            style={{ background: "#E7E9EB", borderRadius: "12px" }}
                            variant="outlined"
                            placeholder="Please type email address"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="pr" spacing={3}>
                        <Grid item md={6} sm={12}  >
                          <InputLabel htmlFor="firstName">
                            First Name
                          </InputLabel>
                          <TextField
                            id="firstName"
                            name="firstName"
                            className="fname-input"
                            value={values.firstName}
                            onChange={handleChange}
                            autoComplete="on"
                            onBlur={handleBlur}
                            variant="outlined"
                            placeholder="Please type first name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            />
                            {this.renderFirstNameError(formikProps)}
                        </Grid>
                        <Grid item md={6} sm={12} >
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <TextField
                            id="lastName"
                            name="lastName"
                            className="lname-input"
                            value={values.lastName}
                            onChange={handleChange}
                            autoComplete="on"
                            onBlur={handleBlur}
                            variant="outlined"
                            placeholder="Please type last name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {this.renderEditLastNameError(formikProps)}
                        </Grid>
                      </Grid>
                      <MainSelect className="select_main">
                        <Grid container spacing={5}>
                          <Grid item md={12} xs={12}>
                            <InputLabel htmlFor="mobileNumber">
                              Mobile number
                            </InputLabel>
                            <Grid container spacing={1}>
                              <Grid item md={3} className="grid_3">
                                <FormControl>
                                  <CustomSelect
                                    id="CountryCode"
                                    name="CountryCode"
                                    displayEmpty
                                    value={values.CountryCode}
                                    onChange={handleChange}
                                    onOpen={this.handleSelectOpen}
                                    onClose={this.handleSelectClose}
                                    MenuProps={{ ...CustomMenuProps, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }}
                                    className={`select_flag`}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => (
                                      <IconButton {...props} className="icon_img MuiSelect-icon">
                                        <img src={SkipIcon} />
                                      </IconButton>
                                    )}
                                  >
                                    {this.state.CountryFlagCode && this.state.CountryFlagCode
                                      .filter((name) => name.id === 'US' || name.attributes.name === 'Canada')
                                      .map(name => (
                                        <MenuItem
                                          key={name.id}
                                          value={name.id}
                                          className="menu_box"
                                        >
                                          <span className="menu_arrow"></span>
                                          <span className={`flag_img fi fi-${name.id.toLowerCase()}`}></span>
                                          <span className="flag_Text">{`+${name.attributes.country_code}`}</span>
                                        </MenuItem>
                                      ))
                                    }
                                  </CustomSelect>
                                </FormControl>
                                {this.state.selectOpen && <div className="triangle"></div>}
                              </Grid>
                              <Grid item md={9}>
                                <TextField
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  className="mobile-input"
                                  value={this.state.phone || ""}
                                  onChange={(event: any) => this.handleEditPhoneChange(event)}
                                  autoComplete="on"
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  placeholder="Please enter mobile number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                />
                                {this.renderEditPhoneError(formikProps)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MainSelect>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel htmlFor="filled-adornment-password">
                            Enter your password
                          </InputLabel>
                          <TextField
                            id="filled-adornment-password"
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            className="password-input"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            placeholder="Please enter password"
                            variant="outlined"
                            value={values.password}
                            autoComplete="on"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    data-test-id="toggleTextfieldPassword"
                                    onClick={() => {
                                      setFieldValue(
                                        "showPassword",
                                        !values.showPassword
                                      );
                                    }}
                                    edge="end"
                                  >
                                    {values.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.renderPasswordError(formikProps)}
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel htmlFor="filled-adornment-cpassword">
                            Confirm your password
                          </InputLabel>
                          <TextField
                            id="filled-adornment-cpassword"
                            type={values.showConPassword ? "text" : "password"}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            name="confirmPassword"
                            className="conpass-input"
                            autoComplete="on"
                            placeholder="Confirm your password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      setFieldValue(
                                        "showConPassword",
                                        !values.showConPassword
                                      );
                                    }}
                                    edge="end"
                                  >
                                    {values.showConPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.renderConfirmPasswordError(formikProps)}
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <div>
                            <CheckboxWithCustomCheck
                              checked={values.acceptTerms}
                              onChange={handleChange}
                              name="acceptTerms"
                              onBlur={handleBlur}
                            />
                            <span>I accept the Xpand </span>
                            <span data-test-id="termsAndConditions" onClick={() => this.handleClickOpen()} className="terms"> Terms and Conditions</span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <Button type="submit" fullWidth className="submit-btn">
                            Sign up
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog-paper"
          PaperProps={{
            style: {
              marginLeft: '22%',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0.1)',
            }
          }}
        >
          <TermPageWeb data-test-id="handleCloseTermsAndCondition" handleClose={this.handleClose} id={""} navigation={undefined} />
        </Dialog>
      </>
    );
  }
}

export default AccountSetup;
// Customizable Area End