import React from "react";
import {
    Button, Grid, Switch, Avatar,
    // Customizable Area Start
    Paper, TextField, Typography
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import { uploadIcon } from "./assets"
import ManageThumbnailUserController, {
    Props
} from "./ManageThumbnailUserController.web";
import './style.css';
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const folder = require("../assets/Vector2.png");
const lock = require("../assets/lock2.png");
const close = require("../assets/imagenav_close.png")
const arrow = require("../assets/VectorLessthen.png");
const video = require("../assets/videoFolder.png");
const videoIcon = require("../assets/videoIcon.png");
const Dots = require("../assets/GroupDots.png");
const playButton = require("../assets/Group190.png")
const delet = require("../assets/delete.png");
const dwonload = require("../assets/dwonload.png")
const profile = require("../assets/share.png")
const copylink = require("../assets/copylink.png")
const Rename = require("../assets/Rename.png")
const trash = require("../assets/trash-can.png")

// Customizable Area End


export default class ManageThumbnailUser extends ManageThumbnailUserController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <div>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '-23px' }}>
                        <Typography style={{ fontSize: '48px', fontWeight: 700, }}>Manage Thumbnail Library</Typography>
                    </Grid>


                    <Grid container spacing={2} style={{ marginTop: '0px' }}>
                        <Grid item lg={6}>
                            <div style={{ display: 'flex' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Folders</Typography>
                                <img style={{ width: '10px', height: '15px', marginTop: '28px', marginLeft: '15px', marginRight: '15px' }} src={arrow} />
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>User Uploads</Typography>
                            </div>
                        </Grid>
                        <Grid item lg={2}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <img style={{ width: '20px', height: '12px', marginTop: '7px' }} src={Vector} />
                                    <Typography style={{ fontSize: '18px', fontWeight: 500, color: '#6C328B', paddingLeft: '15px' }}>List View</Typography>
                                </div>
                                <Switch className="Switch" checked={this.state.themeMode} test-id="change20" onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
                            </div>
                        </Grid>
                        <Grid item lg={2}><Button onClick={this.dialog1HandleOpen} style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' }} variant="contained" color="primary" >Add Thumbnails</Button></Grid>
                        <Grid item lg={2}><Button onClick={this.dialogHandleOpen} style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' }} variant="contained" color="primary" >Add New Folder</Button></Grid>
                    </Grid>



                    <Modal
                        open={this.state.dialogopen}
                        onClose={this.dialogHandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={{
                            position: 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
                            margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                        }}>
                            <img src={close} onClick={this.dialogHandleClose} style={{ float: 'right', marginTop: '20px', marginRight: '20px' }} />
                            <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Create New Folder</Typography>
                            <Grid container spacing={4} style={{ marginLeft: '30px', marginTop: '25px' }}>
                                <Grid item lg={9}>
                                    <TextField style={{ borderRadius: '16px', width: '665px' }} test-id="change21" value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Button test-id="click1" onClick={() => this.createNewFolder()} style={{ borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Create</Button>
                                </Grid>

                            </Grid>
                        </div>
                    </Modal>

                    <Modal
                        open={this.state.dialogopen1}
                        onClose={this.dialog1HandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={{
                            position: 'absolute', width: '890px', height: '350px', backgroundColor: '#fff',
                            margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                        }}>
                            <img src={close} onClick={this.dialog1HandleClose} style={{cursor:'pointer', float: 'right', marginTop: '20px', marginRight: '20px' }} />
                            <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Upload Thumbnail</Typography>
                            <Grid container spacing={4} style={{ marginTop: '25px' }}>
                                <Button variant="contained" style={{ boxShadow: 'none', background: '#EAEAEA', width: '85px', height: '75px', borderRadius: '15px', display: 'flex', margin: 'auto', textAlign: 'center' }} component="label">
                                    {/* <img src={libraryIcon} width="36" height="36" /> */}
                                    <input hidden id="file" type="file" accept="image/*" onChange={this.onImageChange} className="filetype" />
                                    {this.state.setImage ? 
                                        <img alt="preview image" src={this.state.setImage} /> : <Avatar style={{ borderRadius: '0px' }} src={uploadIcon} />}
                                </Button>
                                
                                {/* <input type="file" onChange={this.onImageChange} accept="image/png, image/jpeg" className="filetype" /> */}
                                {/* <TextField style={{ borderRadius: '16px', width: '665px' }} value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" /> */}
                            </Grid>
                            <Typography style={{ textAlign: 'center', marginTop: '35px', fontSize: '28px', fontWeight: 500 }}>Drag and Drop or <span style={{ color: '#653889', fontWeight: 700 }}>Browse</span> to Upload</Typography>
                            <Typography style={{ textAlign: 'center', marginTop: '5px', fontSize: '18px', color: '#979797' }}>Upload file up to 15 MB and file dimensions</Typography>
                            <Grid container style={{ marginTop: '30px' }}>
                                <Grid item lg={9}></Grid>
                                <Grid item lg={3}>
                                    <Grid container spacing={2} style={{ marginLeft: '-30px' }}>
                                        <Grid item lg={6}>
                                            <Button test-id="click02" onClick={() => this.image_cancel()} style={{ border: '1px solid #653889', color: "#653889", width: '100%', height: '50px', borderRadius: '10px', textTransform: 'capitalize' }}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Button test-id="click03" onClick={() => this.UploadNewImage()} style={{ color: "#fff", width: '100%', height: '50px', borderRadius: '10px', background: '#653889', textTransform: 'capitalize' }}>
                                                Upload
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>

                    {this.state.themeMode ? <></> :
                        <Grid container spacing={2} style={{ marginTop: '10px' }}>
                            {this.state.libraryData?.data?.map((data: any, index: any) => (
                                <>
                                    <Grid key={index.id} item lg={3}><Paper style={{ width: '295px', height: '68px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px', borderRadius: '10px' }}>
                                        <Grid container style={{ padding: '15px' }}>
                                            <Grid item lg={2} style={{ margin: 'auto' }}>
                                                <>
                                                    <img src={folder} />
                                                </>
                                            </Grid>
                                            <Grid item lg={8} style={{ margin: 'auto' }}>
                                                <Typography test-id="handleNavigate" onClick={() => this.handleNavigate(data)} style={{ cursor: 'Pointer', fontSize: '14px', fontWeight: 600, color: '#222836', marginLeft: '0px', marginTop: '0px', textTransform: 'capitalize' }}>{data.name}</Typography>
                                            </Grid>
                                            <Grid item lg={2} style={{ margin: 'auto' }}>
                                                {data.is_lock ? <img style={{ marginLeft: '8px' }} src={lock} /> : <></>}
                                                {/*  <img style={{ marginLeft: '8px' }} src={lock} /> */}
                                            </Grid>
                                        </Grid>
                                    </Paper></Grid>
                                </>
                            ))}

                        </Grid>}

                    {this.state.themeMode ?
                        <>
                            <Grid container style={{ marginTop: '40px', marginBottom: '25px' }}>
                                <Grid item lg={3}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Name</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Created By</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                </Grid>
                                <Grid item lg={5}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, float: 'right', marginRight: '20%' }}>Download</Typography>
                                </Grid>
                            </Grid>
                            {this.state.ThumbnailData?.data?.map((data: any, index: any) => (
                                <Paper key={index} style={{
                                    boxShadow: '-6px -6px 25px #e0e0e0, 6px 6px 25px #e0e0e0',
                                    width: '100%',
                                    margin: 'auto',
                                    backgroundColor: '#fcfcfd',
                                    height: '70px',
                                    borderRadius: '10px',
                                    marginBottom: '15px',
                                }}>
                                    <Grid container style={{ marginTop: '20px' }}>
                                        <Grid item lg={1} style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                style={{ marginTop: '-8px' }}
                                            />
                                        </Grid>
                                        <Grid item lg={2}>
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, marginLeft: '25px' }}>{data?.attributes?.folder?.name}</Typography>
                                        </Grid>
                                        <Grid item lg={2}>
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>{data?.attributes?.created_by}</Typography>
                                        </Grid>
                                        <Grid item lg={2}>
                                        </Grid>
                                        <Grid item lg={5}>
                                            <Grid container>
                                                <Grid item lg={8}></Grid>
                                                <Grid item lg={2}>
                                                    {data?.attributes?.image?.url ? <> <img onClick={() => this.Downloadimage(data.id)} src={dwonload} style={{ cursor: 'pointer' }} /> </> : <></>}
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <img onClick={() => this.Delete_thumbnail(data.id)} src={delet} style={{ cursor: 'pointer' }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            ))}
                        </>
                        :
                        <Grid container spacing={2} style={{ marginTop: '40px' }}>
                            {this.state.ThumbnailData?.data?.map((data: any, index: any) => (
                                <>
                                    <Grid key={index} item lg={3}>
                                        <Card style={{ maxWidth: 285, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>

                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                height="200"
                                                image={data?.attributes?.image?.url ? data?.attributes?.image?.url : video}
                                                title="Contemplative Reptile"
                                            />
                                            <img src={playButton} style={{ marginTop: '-16%', padding: '7%', position: 'absolute', display: 'flex' }} />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item lg={3}>
                                                        <img src={videoIcon} />
                                                    </Grid>
                                                    <Grid item lg={9}>
                                                        <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                                                            {data?.attributes?.folder?.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>

                                        </Card>
                                    </Grid>
                                </>
                            ))}

                        </Grid>
                    }

                </Grid>
            </div>
        )

    }

}
