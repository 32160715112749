import React from "react";
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import CourseCard from "./CourseCard.web"

interface PIncludes {
  totalCourses: number;
  totalObjects: number;
  phases: any;
  programIncludes: any;
  phaseReleaseSchedule: string;
  isEnrolled: boolean;
  handleNavigate: (courseObj: any) => void;
  handleObjectNavigation: (objectId: number) => void;
  objectId?: string;
}

function genProgramStatistics(totalCourses: number, totalObjects: number) {
    let totalCoursesText = null;
    if (totalCourses === 0) totalCoursesText = "No courses";
    else if (totalCourses === 1) totalCoursesText = "1 course";
    else if (totalCourses > 1) totalCoursesText = `${totalCourses} courses`;

    let totalObjectsText = "";
    if (totalObjects === 1) totalObjectsText = "/ 1 object";
    else if (totalObjects > 1) totalObjectsText = `/ ${totalObjects} objects`;

    return `${totalCoursesText} ${totalObjectsText}`;
}

export default function ProgramIncludes({
  totalCourses,
  totalObjects,
  phases,
  isEnrolled,
  programIncludes,
  phaseReleaseSchedule,
  handleNavigate,
  handleObjectNavigation,
  objectId,
}: PIncludes) {
  const classes = useStyles();

  const onNavigate = (isLocked: boolean, id: string, title: string) => {
    !isLocked && handleNavigate({ id, title });
  }

  const programIncludesExists = programIncludes && programIncludes.length > 0;
  const isPhaseEmpty = phases && Object.keys(phases).length === 0 && phases.constructor === Object;
  let phaseReleaseScheduleContent = null;
  if (!isPhaseEmpty) {
    phaseReleaseScheduleContent = (
      <Box className={classes.contentRelease}>
        <p className={classes.textL}>Phases are released</p>
        <p className={`${classes.textL} ${classes.textLBold}`}>
          {phaseReleaseSchedule}
        </p>
      </Box>
    );
  }

  let phaseContent = null;
  if (!isPhaseEmpty && !programIncludesExists) {
    phaseContent = phases && Object.entries(phases).map(([phaseName, value]: any) => {
      return (
        <Accordion key={phaseName} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p className={classes.textXL}>{phaseName}</p>
          </AccordionSummary>
          <AccordionDetails className={classes.accDetails}>
            {value?.package?.map((item: any) => {
              const hightlight = item.id.toString() === objectId;
              return (
                <Box key={item.id}>
                  <CourseCard
                    title={item.title}
                    description={item.description}
                    rating={item.rating}
                    resourceType={item.type}
                    author={item.author_name}
                    courseContent={item.videos}
                    thumbnailImg={item.thumbnail_image}
                    attachment={item.attachment_data}
                    isLocked={item.is_lock}
                    isEnrolled={isEnrolled}
                    isCompleted={item.is_completed}
                    handleNavigate={() => onNavigate(item.is_lock, item.id, item.title)}
                    handleObjectNavigation={() => handleObjectNavigation(item.id)}
                    hightlight={hightlight}
                  />
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    })
  }

  let programIncludesContent = null;
  if (isPhaseEmpty && programIncludesExists) {
    programIncludesContent = programIncludes.map((item: any) => {
      const hightlight = item.id.toString() === objectId;
      return (
        <CourseCard
          key={item.id}
          title={item.title}
          description={item.description}
          rating={item.rating}
          resourceType={item.type}
          author={item.author_name}
          courseContent={item.videos}
          thumbnailImg={item.thumbnail_image}
          attachment={item.attachment_data}
          isLocked={item.is_lock}
          isEnrolled={isEnrolled}
          isCompleted={item.is_completed}
          handleObjectNavigation={() => handleObjectNavigation(item.id)}
          handleNavigate={() => onNavigate(item.is_lock, item.id, item.title)}
          hightlight={hightlight}
        />
      );
    });
  }

  const programStatistics = genProgramStatistics(totalCourses, totalObjects);

  return (
    <Card className={classes.card}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="program-includes-content"
          id="program-includes-header"
        >
          <Box>
            <p className={classes.textXXL}>
              Program Includes{" "}
            </p>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ display: "inherit" }}>
          <p className={classes.textMD}>{programStatistics}</p>
          {phaseReleaseScheduleContent}
          <Box>
            {phaseContent}
            {programIncludesContent}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    borderRadius: "20px",
    border: "1px solid rgba(225, 225, 225, 0.58)",
    background: "#FFF",
    boxShadow: "0px 0px 16px 0px rgba(213, 213, 213, 0.35)",
    "& p": {
      margin: 0
    }
  },
  accordion: {
    boxShadow: "none"
  },
  textXXL: {
    color: "#171725",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "36px",
    letterSpacing: "0.1px",
  },
  textXL: {
    color: "#1A1A1A",
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "28px",
    textTransform: "capitalize",
  },
  textL: {
    color: "#1A1A1A",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    marginBottom: "15px !important",
  },
  textLBold: {
    fontWeight: 600, 
    lineHeight: "22px",
    borderBottom:  "1px solid #23262F",
    textTransform: "capitalize",
  },
  textMD: {
    color: "#1A1A1A",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    marginBottom: "15px !important",
  },
  contentRelease: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  accDetails: {
    padding: 0,
    display: "block",
  }
}));