// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { Search } from "@material-ui/icons";
import debouce from "lodash.debounce";
import CourseCreationController from './CourseCreationController.web';
import '../../email-account-login/src/UserManagement.css'
import '../../videos/src/video.css';
import { Caret2 } from './assets';
import ExistingCourseTable from '../../../components/src/ExistingCourseTable';
import { toast } from 'react-toastify';
const heading = [
    { label: "", sort: false },
    { label: "", sort: false },
    { label: "Name", sort: false },
    { label: "Course ID", sort: false },
    { label: "Xpert Name", sort: false },
    { label: "Content Role(s)", sort: false },
    { label: "Total Chapters", sort: false },
    { label: "User Enrolled", sort: false },
    { label: "", sort: false },
    // { label: "Summary", sort: false },
    { label: "Status", sort: false },
];

export interface Props {

}
export interface State {
    columnData: any
}
class ExistingCourseList extends CourseCreationController {
    constructor(props: any) {
        super(props);
        this.debouncedResults = this.debouncedResults.bind(this)
    }
    selected = (index: any) => {
        let items = [...this.state.existing_course];
        let item = { ...items[index] }
        item.is_selected = !item.is_selected
        items[index] = item
        this.setState({ existing_course: items })
    }

    debouncedResults: any = debouce(
        (text) => this.getExistingCourseList({ search: text, filter: "", page: 1, per: 10 }, this.state.pagination.curPage),
        700
    );
    handleChange(e: any) {
        const { value } = e.target;
        if (e.target.name === "search") {
            this.debouncedResults(value);
        }
        else if (e.target.name === "sort") {
            this.getExistingCourseList({ search: "", filter: value, page: 1, per: 10 }, this.state.pagination.curPage)
        }
    }
    handleSubmit() {
        const filter_existing_course = this.state?.existing_course?.filter((item: any) => item.is_selected === true);
        if (filter_existing_course.length === 0) {
            toast.error("Please select course", { delay: 1000 });
        }
        else if (!this.props?.location?.state?.part_of_program) {
            toast.error("It's not part of program", { delay: 1000 });
            this.props?.navigation?.navigate("ExistingCourseList");
        }
        const fd = new FormData();
        filter_existing_course.forEach((ele: any) => {
            fd.append("courses_id[]", ele.id);
        });
        fd.append("phase", this.props?.location?.state?.phase || "1");
        fd.append("program[title]", this.props?.location?.state?.title);
        this.updateProgram(fd, this.props?.location?.state?.programId);

    }
    render() {
        return (<>

            <div className="existing_course">
                <Grid container spacing={4} style={{ justifyContent: "space-between", marginBottom: "10px" }}>
                    <Grid item md={3}>
                        <TextField
                            data-test-id="handleChange"
                            fullWidth
                            id="search"
                            name="search"
                            onChange={(e: any) => { this.handleChange(e) }}
                            variant="outlined"
                            placeholder="Search"
                            InputLabelProps={{
                                shrink: true
                            }}
                            className="search"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                    <Grid item md={2} xs={12} style={{ textAlign: "end" }}>
                        <FormControl style={{ marginLeft: '-20px' }} className="bulk-action">
                            <Select
                                data-test-id="handleChange1"
                                labelId="bulk action"
                                id="bulk action"
                                name='sort'
                                variant="outlined"
                                onChange={(e: any) => { this.handleChange(e) }}
                                value={"expert"}
                                IconComponent={() => null}
                                startAdornment={
                                    <InputAdornment position="start" className="filter-heading">Filter By:</InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={Caret2} />
                                    </InputAdornment>
                                }
                                fullWidth>
                                <MenuItem value="expert" selected>
                                    <strong>Xpert</strong>
                                </MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>


                <ExistingCourseTable
                    prevPageExists={!!this.state.pagination.prevPage}
                    nextPageExists={!!this.state.pagination.nextPage}
                    handleNextPageNavigation={this.handleNextPageNavigation}
                    handlePrevPageNavigation={this.handlePrevPageNavigation}
                    tableHeading={heading}
                    //@ts-ignore
                    columnData={this.state.existing_course}
                    getExistingCourseList={(obj: any) => this.getExistingCourseList(obj, this.state.pagination)}
                    meta={this.state.meta}
                    tableRows={this.state.existing_course}
                    selected={(index: any) => this.selected(index)}
                    data-test-id="selectData"
                />
                <Grid container style={{ justifyContent: "end", marginTop: "10px" }}>
                    <Grid item md={3} style={{ textAlign: "end" }}>
                        <Button data-test-id="handleSubmit" type='button' className='confirm-btn' onClick={() => this.handleSubmit()}>Confirm Selection</Button>
                    </Grid>
                </Grid>

            </div>
        </>);
    }
}
export default ExistingCourseList;
// Customizable Area End