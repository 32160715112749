function sortStringsAsc(data: any, key: string) {
  console.log("sort Data", data)
  return data.sort((a: any, b: any) => {
    const upperCasedA = a["attributes"][key]?.toUpperCase();
    const upperCasedB = b["attributes"][key]?.toUpperCase();
    console.log("up", upperCasedA, upperCasedB)

    if (upperCasedA < upperCasedB) return -1;
    if (upperCasedA > upperCasedB) return 1;
    return 0;
  })
}

function sortStringsDesc(data: any, key: string) {
  return data.sort((a: any, b: any) => {
    const upperCasedA = a["attributes"][key]?.toUpperCase();
    const upperCasedB = b["attributes"][key]?.toUpperCase();

    if (upperCasedA > upperCasedB) return -1;
    if (upperCasedA < upperCasedB) return 1;
    return 0;
  })
}

export function sortTableData(
  data: any,
  dataType: string,
  key: string,
  sortType: string,
) {
  let sortedData;
  if (dataType === "number" && sortType === "asc") {
    sortedData = data.sort((a: any, b: any) => a[key] - b[key]);
    return sortedData;
  } else if (dataType === "number" && sortType === "desc") {
    sortedData = data.toSorted((a: any, b: any) => b[key] - a[key]);
    return sortedData;
  } else if (dataType === "string" && sortType === "asc") {
    sortedData = sortStringsAsc(data, key);
    return sortedData;
  } else if (dataType === "string" && sortType === "desc") {
    sortedData = sortStringsDesc(data, key);
    return sortedData;
  } else if (dataType === "date" && sortType === "asc") {
    sortedData = data.sort(
      (a: any, b: any) =>
        new Date(a["attributes"][key]).valueOf() - new Date(b["attributes"][key]).valueOf(),
    );
    return sortedData;
  } else if (dataType === "date" && sortType === "desc") {
    sortedData = data.sort(
      (a: any, b: any) =>
        new Date(b["attributes"][key]).valueOf() - new Date(a["attributes"][key]).valueOf(),
    );
    return sortedData;
  }
}