import React from "react";
import {
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
interface CurrentData {
    cdate: string;
    count: string | number
}

interface DoubleLineChartProps {
    chartTitle: string;
    discription: string;
    currentData: CurrentData[];
    previousData: CurrentData[];
    currentTotal: string | number;
    previousTotal: string | number;
    displayPreviousData: boolean;
    pastPreviousLable: string;
}
const ITEMS_PER_PAGE = 12;

const DoubleLineChart = (props: DoubleLineChartProps) => {
    const [currentPage, setCurrentPage] = React.useState(0);

    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrev = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    // Slice the data based on the current page
    const paginatedData = props.currentData?.length ? props.currentData.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
    ): [];


    const previousPaginatedData = props.previousData?.length ? props.previousData.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
    ): [];

    const seriesList = paginatedData?.length ? paginatedData?.map((seriesValue, index) => {
        const modal = {
            cdate: seriesValue.cdate, 
            count: seriesValue.count, 
            previousCount: previousPaginatedData[index]?.count
        }
        return modal
    }): [];

    return (
        <DoubleLineChartWrapper>
            <Box sx={{ padding: 3, borderRadius: 2, color: '#fff', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
                <Box display="flex" justifyContent="space-between" flexDirection="column" mb={2}>
                    {props.displayPreviousData && 
                    <Typography variant="body2" style={{ color: '#ff7f00' }}>
                        <span style={{ marginRight: 8 }}>🟠</span>Previous {props.pastPreviousLable}: <strong>{props.previousTotal} {props.discription}</strong>
                    </Typography>
                    }
                    <Typography variant="body2" style={{ color: '#9b59b6' }}>
                        <span style={{ marginRight: 8 }}>🟣</span>Past {props.pastPreviousLable}: <strong>{props.currentTotal} {props.discription}</strong>
                    </Typography>
                </Box>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={seriesList}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="cdate" stroke="#ff7f00" tick={{ fill: '#ccc' }} />
                        <YAxis tick={{ fill: '#ccc' }} width={80}/>
                        <Tooltip />
                        <Legend verticalAlign="top" align="right" iconType="circle" wrapperStyle={{ color: '#ccc' }} />
                        {props.displayPreviousData &&
                            <Line type="monotone" dataKey="previousCount" stroke="#ff7f00" strokeWidth={3} dot={{ r:4, fill: '#ff7f00' }} /> 
                        }
                        <Line type="monotone" dataKey="count" stroke="#9b59b6" strokeWidth={3}  dot={{ r:4, fill: '#fff' }} />
                    </LineChart>
                </ResponsiveContainer>
                <Typography align="center" variant="h6" style={{color: "#484646", fontSize: "18px", fontWeight: 600}}>
                    {props.chartTitle}
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button onClick={handlePrev} disabled={currentPage === 0} variant="contained" style={{ marginRight: 10 }}>
                        Previous
                    </Button>
                    <Button onClick={handleNext} disabled={(currentPage + 1) * ITEMS_PER_PAGE >= props.currentData?.length} variant="contained">
                        Next
                    </Button>
                </Box>
            </Box>
        </DoubleLineChartWrapper>
    );
};


const DoubleLineChartWrapper = styled("div")({
    "& .MuiBox-root": {
        backgroundColor: "white",
        borderRadius: "18px",
        padding: "20px 8px"
    },
    "& .recharts-legend-item": {
        display: "none !important"
    },
    "& .recharts-legend-item-text": {
        display: "none !important"
    },
});

export default DoubleLineChart;