import React, { Component } from 'react';
import { Box, Modal, Typography, Button, withStyles, Theme, createStyles, styled } from '@material-ui/core';
import { iconCloseSvg } from './Topbar/assets';

const styles = (theme: Theme) => createStyles({
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalMainContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },
  modalMainHeading: {
    marginBottom: theme.spacing(2),
  },
  modalSubHeading: {
    marginBottom: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalLeftButton: {
    marginRight: theme.spacing(2),
  },
  modalRightButton: {
    marginLeft: theme.spacing(2),
  },
  modalCloseButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  dropDownArrow: {
    width: '24px',
    height: '24px',
  },
});

interface SaveAndDiscardChangesModalProps {
  open: boolean;
  heading: string;
  description: string;
  leftButtonText: string;
  rightButtonText: string;
  onClose: () => void;
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
  classes: {
    modalStyle: string;
    modalMainContainer: string;
    modalMainHeading: string;
    modalSubHeading: string;
    buttons: string;
    modalLeftButton: string;
    modalRightButton: string;
    modalCloseButton: string;
    dropDownArrow: string;
  };
}

class SaveAndDiscardChangesModal extends Component<SaveAndDiscardChangesModalProps> {
  render() {
    const {
      classes,
      open,
      heading,
      description,
      leftButtonText,
      rightButtonText,
      onClose,
      onLeftButtonClick,
      onRightButtonClick,
    } = this.props;

    return (
      <Box>
        <ModalContainer
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={open}
          onClose={onClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className={classes.modalStyle}
        >
          <div>
            <ModalMainContainer>
              <ModalMainHeading id="modal-title">
                {heading}
              </ModalMainHeading>
              <ModalSubHeading id="modal-description">
                {description}
              </ModalSubHeading>
              <ButtonBox >
                <ModalLeftButton  onClick={onLeftButtonClick}>
                  <Typography style={{ textTransform: 'none', fontWeight:600 }}>{leftButtonText}</Typography>
                </ModalLeftButton>
                <ModalRightButton  onClick={onRightButtonClick}>
                  <Typography style={{ textTransform: 'none', fontWeight:600 }}>{rightButtonText}</Typography>
                </ModalRightButton>
              </ButtonBox>
              <ModalCloseButton>
                <ImageComponent
                  onClick={onClose}
                  src={iconCloseSvg}
                  alt="close"
                  ></ImageComponent>
              </ModalCloseButton>
            </ModalMainContainer>
          </div>
        </ModalContainer>
      </Box>
    );
  }
}

const ModalContainer = styled(Modal)({
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    display: 'flex',
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center'
  });
  
  const ModalMainContainer = styled(Box)({
    position: 'relative',
    width: "525px",
    // height: "177px",
    minHeight: "177px",
    bgcolor: 'background.paper',
    borderRadius: "12px",
    border: '2px solid #000',
    padding: "24px 32px 24px 32px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "#FFF",
    "@media (max-width: 700px)": {
      padding:"20px",
      maxWidth:"385px"
    },
  });
  
  const ModalMainHeading = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",
    color: "#000"
  });
  
  const ModalSubHeading = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    gap: "5px",
    alignItems: "center",
    color: "#000"
  });
  
  const ButtonBox = styled(Box)({
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  });
  
  const ModalLeftButton = styled(Button)({
    width: "214.5px",
    height: "42px",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid rgba(228, 112, 4, 1)",
    color: "#E47004",
    "@media (max-width: 700px)": {
      width:"100%"
    },     
  });
  
  
  const ModalRightButton = styled(Button)({
    width: "214.5px",
    height: "42px",
    borderRadius: "12px",
    padding: "16px 24px 16px 24px",
    border: "1px solid rgba(101, 39, 134, 1)",
    backgroundColor: "rgba(101, 39, 134, 1)",
    color: "#FFF",
    '&:hover': {
      backgroundColor: "rgba(101, 39, 134, 1)", 
      borderColor: "1px solid rgba(101, 39, 134, 1)",
    },
    "@media (max-width: 700px)": {
      width:"100%"
    },
  });
  
  const ModalCloseButton = styled(Button)({
    position: "absolute" as "absolute",
    top: "-16px",
    left: "94%",
    cursor: "pointer" as "pointer",
    "@media (max-width: 700px)": {
      top: "-18px",
      left: "92%",
    },
  });
  
  const ImageComponent = styled("img")({
    width: "24.66px",
    height: "24px",
    cursor: "pointer"
  });

export default withStyles(styles)(SaveAndDiscardChangesModal);
