import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useState, Fragment } from "react";
import {
  Logo,
  ManageIcon,
  HomeIcon,
  ReportIcon,
  RewardIcon,
  UserManagementIcon,
} from "./assets";
import Topbar from "../Topbar";
import RouteProtection from "../RouteProtection";
import { Link, RouteComponentProps } from "react-router-dom";
interface Props extends RouteComponentProps {
  render: any;
  roles: string[];
  navigation: any;
  routeNotFound: boolean;
  location: any;
}

const DRAWER_WIDTH = 96;

function AdminSidebar(props: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElUserManagement, setAnchorElUserManagement] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [active, setActive] = useState("Home");
  const token = localStorage.getItem("token");
  const fullScreen = useMediaQuery("(max-width:600px)");
  const { history, roles, render } = props;
  const {
    location: { pathname },
  } = history;

  const handleUserManagementClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUserManagement(event.currentTarget);
    setActive("User Management");
  };

  const handleUserManagementClose = () => {
    setAnchorElUserManagement(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleManageClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActive("Manage");
  };

  const handleManageOpen1 = () => {
    history.push("/ContentManagement");
    setAnchorEl(null);
  };
  const handleBranchClick =()=>{
  history.push("/ShowBranch")
  }
  const handleManageOpen2 = () => {
    props.history.push("/ManageContentLibrary");
    setAnchorEl(null);
  };
  const handleTeamClick= () => {
  props.history.push("/TeamBuilder");
    setAnchorEl(null);
  };
  const handleManageOpen3 = () => {
    props.history.push("/Home");
    setAnchorEl(null);
  };
  
  const handleManageClose = () => {
    setAnchorEl(null);
  };
  const handleContentClose = () => {
    props.history.push("/ManageContentLibrary");
    setAnchorEl(null);
  };

  const handleRewardsAndAchievementClick=()=>{
    history.push("/RewardsAndAchievement");
  };
 
  const handleClick = (route: string) => history.push(route);

  const isActive = (route: string | string[]) => Array.isArray(route) ? route.includes(pathname) : pathname.startsWith(route);

  const getIconStyle = (name: string) => {
    if (name === "Manage") {
      return { width: "27px", height: "27px" };
    } else if (name === "Rewards & Achievement") {
      return { width: "20px", height: "26px" };
    } else if (name === "Reports") {
      return { width: "21px", height: "24px" };
    } else {
      return {};
    }
  };

  const navItems = [
    {
      name: "Home",
      icon: HomeIcon,
      route: "/Home",
      clickHandler: () => history.push("/Home"),
    },
    {
      name: "Manage",
      icon: ManageIcon,
      route: ["/ContentManagement","/ManageAssign","/ManageContentLibrary","/Thumbnailmanagement","/Iconmanagement"],
      clickHandler: handleManageClick,
    },
    {
      name: "User Management",
      icon: UserManagementIcon,
      route: "/usermanagement",
      clickHandler: handleUserManagementClick,
    },
    //Gamification
    {
      name: "Rewards & Achievement",
      icon: RewardIcon,
      route: "/RewardsAndAchievement",
      // clickHandler: () => history.push("/Home"),
      clickHandler: handleRewardsAndAchievementClick,
    },
    {
      name: "Reports",
      icon: ReportIcon,
      route: "/AdminReport",
      clickHandler: () => history.push("/AdminReport"),
    },
  ];

  const drawer = (
    <>
      <div className={classes.logoContainer}>
        <img src={Logo} alt="Logo" />
      </div>
      <List>
        {navItems.map((nav: any, index: number) => (
          <Fragment key={nav.name}>
            <ListItem
              button
              onClick={nav.clickHandler}
              className={classes.listItem}
              style={{
                background: clsx(isActive(nav.route) && "rgba(0,0,0,0.41)"),
                borderLeft: isActive(nav.route)
                  ? "5px solid #fff"
                  : "5px solid rgba(0, 0, 0, 0.04)",
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  src={nav.icon}
                  alt={nav.name}
                  className={`${classes.icon} ${classes.homeIcon}`}
                  style={{ ...getIconStyle(nav.name) }}
                />
              </ListItemIcon>
              <ListItemText
                className={`${classes.listItemText} ${
                  isActive(nav.route) ? classes.active : ""
                }`}
                primary={nav.name}
              />
            </ListItem>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleManageClose}
              className={classes.manageMenu}
              elevation={0}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                component={Link}
                to="#"
                onClick={handleManageOpen1}
                className={classes.manageMenuItem}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Products
              </MenuItem>
              <MenuItem
                component={Link}
                to="/ManageAssign"
                onClick={handleManageClose}
                className={classes.manageMenuItem}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Assign
              </MenuItem>
              <MenuItem
                component={Link}
                to="/ManageContentLibrary"
                onClick={handleContentClose}
                className={classes.manageMenuItem}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Content
              </MenuItem>
              <MenuItem
                component={Link}
                to="/Thumbnailmanagement"
                onClick={handleManageOpen3}
                className={`${classes.manageMenuItem} ${
                  classes.lastManageMenuItem
                }`}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Thumbnails 
              </MenuItem>
              <MenuItem
                component={Link}
                to="/Iconmanagement"
                onClick={handleManageOpen3}
                className={`${classes.manageMenuItem} ${
                  classes.lastManageMenuItem
                }`}
                style={{ borderBottom: "none" }}
              >
                 Icons
              </MenuItem>
            </Menu>
            <Menu
              id="simple-menu"
              anchorEl={anchorElUserManagement}
              keepMounted
              open={Boolean(anchorElUserManagement)}
              onClose={handleUserManagementClose}
              className={classes.userManageMenu}
              elevation={0}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                component={Link}
                to="/ShowBranch"
                onClick={handleBranchClick}
                className={classes.userManageMenuItem}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Branches
              </MenuItem>
              <MenuItem
                component={Link}
                to="/TeamBuilder"
                onClick={handleTeamClick}
                className={classes.manageMenuItem}
                style={{ borderBottom: "1px solid #EEE8F2" }}
              >
                Teams
              </MenuItem>
              <MenuItem
                component={Link}
                to="/usermanagement"
                onClick={handleUserManagementClose}
                className={classes.manageMenuItem}
                style={{ borderBottom: "none" }}
              >
                Users
              </MenuItem>
            </Menu>
          </Fragment>
        ))}
      </List>
    </>
  );

  return (
    <>
      <RouteProtection />
      <div className={classes.root}>
        {token ? (
          <>
            <Topbar
              drawerWidth={DRAWER_WIDTH}
              handleDrawerToggle={handleDrawerToggle}
              roles={roles}
              navigation={props.navigation}
              routeNotFound={props.routeNotFound}
              locationSet={props.location}
            />
            <nav className={classes.drawer} aria-label="navigation">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor="left"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {render({ ...props, fullScreen })}
            </main>
          </>
        ) : (
          render({ ...props, fullScreen })
        )}
      </div>
    </>
  );
}

export default AdminSidebar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      background: "linear-gradient(180deg, #6C328B 0%, #41328B 100%)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: DRAWER_WIDTH,
    },
    toolbar: theme.mixins.toolbar,
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "30px 0",
    },
    content: {
      padding: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
    },
    listItem: {
      flexDirection: "column",
      borderRadius: "2px",
      padding: "10px 0",
    },
    listItemIcon: {
      justifyContent: "center",
    },
    listItemText: {
      color: "#fff",
      textAlign: "center",
      "& .MuiTypography-body1": {
        fontSize: "12px",
      },
    },
    active: {
      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    homeIcon: {
      width: "24px",
      height: "24px",
    },
    manageIcon: {
      width: "40px",
      height: "36px",
    },
    rewardsIcon: {
      width: "20px",
      height: "26px",
    },
    reportsIcon: {
      width: "20px",
      height: "24px",
    },
    manageMenu: {
      "& .MuiList-root": {
        backgroundColor: "#6C328B",
        padding: "18px 29px",
        gap: "10px",
        width: "285px",
        height: "218px",
        "@media (max-width: 600px)": {
          height: "auto !important",
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: "0 16px 16px 0",
        backgroundColor: "#6C328B",
      },
    },
    manageMenuItem: {
      borderBottom: "1px solid #EEE8F2",
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    userManageMenu: {
      "& .MuiList-root": {
        backgroundColor: "#6C328B",
        padding: "18px 29px",
        gap: "10px",
        width: "285px",
        height: "142px",
        "@media (max-width: 600px)": {
          height: "auto !important",
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: "0 16px 16px 0",
        backgroundColor: "#6C328B",
      },
    },
    userManageMenuItem: {
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    lastManageMenuItem: {
      borderBottom: '1px solid #fff',
    },
    manageQuizBank:{
      borderBottom: "0pindex",
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "-0.25px",
      color: "#FAFAFA",
      cursor: "pointer",
    }
  })
);