import React from "react";

// Customizable Area Start
import './ForgotPassword.css'
import {
    Box,
    Typography,
    Grid,
    Paper,
    TextField,
    InputLabel,
    InputAdornment,
    Button,
    IconButton


} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik } from 'formik';
import * as yup from 'yup';
import {Link} from 'react-router-dom';
import { LoginImage } from "./assets";
import AlertBlock from "../../alert/src/ActionAlert.web";
import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'end',
    color: theme.palette.text.secondary,
}));

export default class ResetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }
    handleSubmit(user: any) {
        let payload = {
                    new_password: JSON.parse(user).password,
                    confirm_new_password: JSON.parse(user).confirmPassword,
                    token: this.props.navigation.getParam('token')
        }
        this.getUpdatePassword(payload)
    }

    render() {
        return (
            // Required for all blocks
            <>
                <AlertBlock data={{ show: this.state.alert.isOpen, close: () => { this.setState({ alert: { isOpen: false, msg: '', type: '' } }) }, msg: this.state.alert.msg, type: this.state.alert.type, vertical: "top", horizontal: "right" }} />
                <Grid container style={{ height: "100vh", width: "100vw" }} className="forgotPassword">
                    <Grid
                        xs={'auto'}
                        // spacing={0}
                        sm={4}
                        md={4}
                        lg={3}
                        item
                        style={{
                            backgroundImage: `url(${LoginImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid item xs={12} sm={8} md={8} lg={9} className="form-container" component={Paper} elevation={6} square style={{ overflowY: 'scroll', height: '100vh' }}>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >

                            <Grid item  className="login-route">
                                <Item ><Link to="/login" className="text">Already have an account ?<span style={{paddingLeft:'20px'}}> Login</span></Link></Item>
                            </Grid>


                            <Grid container className="login-route mt-11" style={{marginTop:'5%'}}>
                                <Grid item lg={3}></Grid>
                                <Grid item xs={12} sm={12} lg={6} style={{padding:'0 60px'}} >

                                <Formik
                                data-test-id="formik"
                                    initialValues={{
                                        showPassword: false, password: "",
                                        confirmPassword: "", showConPassword: false,
                                    }}
                                    onSubmit={async values => {
                                        this.handleSubmit(JSON.stringify(values))

                                    }}
                                    validationSchema={yup.object().shape({
                                        password: yup.string()
                                            .required('New Password is required').matches(
                                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/,
                                                "Must Contain 5 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                                            ),
                                        confirmPassword: yup.string()
                                            .required('Confirm Password is required')
                                            .oneOf([yup.ref('password'), null], 'Passwords do not match'),
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            setFieldValue,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        } = props;
                                        return (
                                            <form className="regform" onSubmit={handleSubmit}>
                                                <Grid item  md={12} xs={12} sm={12} className="typo" >
                                                    <Typography component='h5' variant="h4" style={{fontSize:'40px',textAlign:'center'}} className="forgot-heading">New password</Typography>
                                                </Grid>
                                                <Grid className="form" container spacing={5}>
                                                    <Grid item md={12} xs={12} sm={12}>

                                                        <InputLabel htmlFor="filled-adornment-password">Enter The Password</InputLabel>
                                                        <TextField
                                                            id="filled-adornment-password"
                                                            type={this.getInputType(values.showPassword)}
                                                            name="password"
                                                            data-test-id="passwordTextField"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            placeholder="Password"
                                                            variant="outlined"
                                                            value={values.password}
                                                            error={touched.password && Boolean(errors.password)}
                                                            helperText={touched.password && errors.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            data-test-id="showPasswordTest"
                                                                            aria-label="toggle password visibility"
                                                                            onClick={() => { setFieldValue("showPassword", !values.showPassword) }}
                                                                            edge="end"
                                                                        >
                                                                            {this.getPasswordVisibilityIcon(values.showPassword)}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid className="form" container spacing={5}>
                                                    <Grid item md={12} xs={12} sm={12}>
                                                        <InputLabel htmlFor="filled-adornment-cpassword">Confirm The Password</InputLabel>
                                                        <TextField
                                                            id="filled-adornment-cpassword"
                                                            type={this.getInputType(values.showConPassword)}
                                                            data-test-id="confirmPasswordTextField"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            variant="outlined"
                                                            fullWidth
                                                            name="confirmPassword"
                                                            placeholder="Confirm password"
                                                            value={values.confirmPassword}
                                                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={() => { setFieldValue("showConPassword", !values.showConPassword) }}
                                                                            edge="end"
                                                                        >
                                                                            {this.getPasswordVisibilityIcon(values.showConPassword)}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={5} className="form">
                                                    <Grid item md={12} xs={12} sm={12}>
                                                        <Button type="submit" className={values.password && values.confirmPassword ? "enable" : "disable"}
                                                            fullWidth
                                                        >Submit</Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Grid>
                                <Grid item lg={3}></Grid>
                            </Grid>
                            





                        </Box>
                    </Grid>

                </Grid>
            </>
        );
    }
}
// Customizable Area End
