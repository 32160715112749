// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { Search } from "@material-ui/icons";
import debouce from "lodash.debounce";
import {Link} from 'react-router-dom';
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import '../../videos/src/video.css';
import ManageContentRoleTable from '../../../components/src/ManageContentRole';
class ManageContentRoleList extends CourseCreationController {
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.debouncedResults = this.debouncedResults.bind(this)
    }
    handleChange = (e:any) => {
        const {value} = e.target;
        if (e.target.name === "search") {
            this.setState({ text: value })
            this.debouncedResults(e.target.value)
          }
    }
    debouncedResults: any = debouce((text) => this.getContentRole({ course_id:"",search:text,per:"",page:"" }), 700);
    selectedContentRole(selected:any){
      if(selected.length>0){
        this.setState({selectedContentRole:selected})
      }
    }
    removeSelectedContentRole(index:number){
        this.state?.selectedContentRole?.splice(index,1);
        this.setState({selectedContentRole:this.state?.selectedContentRole})
    }

    render() {
        return (<>
            <div className="reward-list">

                <Grid container spacing={4}>
                    <Grid item md={4}>
                        <TextField
                            fullWidth
                            id="search"
                            name="search"
                            variant="outlined"
                            placeholder="Search"
                            data-test-id = "reward_list"
                            InputLabelProps={{
                                shrink: true
                            }}
                            className="search"
                            onChange={(e) => this.handleChange(e) }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>

                    <Grid item md={8} style={{
                        textAlign: "end"
                    }}>
                        <Button className='contentRole-btn'>+ Add New Content Role</Button>
                    </Grid>
                </Grid>

            </div>
            <Grid container md={12} xs={12}>
                <Grid item md={12} xs={12}>
                    <ManageContentRoleTable data-test-id = "ManageContentRoleTable" contentRoleList={this.state.contentRoleList} meta={this.state.meta} getContentRole={(obj:any) => this.getContentRole(obj)} 
                    selectedContentRole={(selected:any) => this.selectedContentRole(selected)} search={this.state.text}
                    />
                </Grid>
            </Grid>
            <Grid container style={{textAlign:"center",margin:"20px"}}>
                <Grid item md={12}>
                <Link data-test-id = "removeSelectedContentRole" to={{pathname:this.props?.location?.state?.pathname,
                    state:{selectedContentRole:this.state?.selectedContentRole,image:this.props?.location?.state?.image,imgUrl:this.props?.location?.state?.imgUrl
                    },
                    //@ts-ignore
                    data:{removeSelectedContentRole:(index:number) => this.removeSelectedContentRole(index)
                    }}}><Button className="proceed-content-btn" type="button" fullWidth disabled={this.state.selectedContentRole.length === 0 ? true : false}>Proceed To Add Content Role</Button></Link>
                </Grid>
            </Grid>
        </>);
    }
}
export default ManageContentRoleList;
// Customizable Area End