import { toast } from "react-toastify";
const { baseURL } = require("../../../framework/src/config");

export const getUnSavedQuestionIndex = (questions: any) => {
  const index = questions.findIndex((question: any) => !question.isSaved);
  return index;
};

export const truncateText = (text: string) =>
  text.length >= 70 ? `${text.slice(0, 70)}....` : text;

export const apiCall = async ({
  contentType,
  method,
  endPoint = "",
  payload,
}: any) => {
  const response = await fetch(`${baseURL}/${endPoint}`, {
    method,
    headers: {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    },
    ...(payload && { body: payload }),
  });
  return response.json();
};

const configJSON = require("./config");

export const handleSaveQuestion = async (
  data: any,
  { setValues, setSubmitting, setTouched }: any
) => {
  const { questions } = data;
  const index = getUnSavedQuestionIndex(questions);
  const curQuestion = questions[index];
  const { id, text, options, isActive, questionType } = curQuestion;

  const isEditingQuestion = curQuestion.id;

  const payload = {
    question: text,
    question_type: questionType,
    is_active: isActive === "yes" ? true : false,
    question_options_attributes: options.map((option: any) => ({
      display_ans_graphic: option.displayAnsGraphic,
      is_correct: option.isCorrect === "yes" ? true : false,
      is_selected: option.isSelected,
      option: option.text.toLowerCase(),
      ...(isEditingQuestion && { id: option.id }),
      ...(option._destroy && { _destroy: true }),
    })),
  };

  const {
    applicationJson,
    httpPostMethod,
    httpPutMethod,
    createQuestionUrl,
  } = configJSON;

  if (isEditingQuestion) {
    // Update question
    try {
      const responseJson = await apiCall({
        contentType: applicationJson,
        method: httpPutMethod,
        endPoint: `${createQuestionUrl}/${id}`,
        payload: JSON.stringify(payload),
      });
      if (responseJson && !responseJson.errors && responseJson.data) {
        const {
          data: {
            attributes: { options },
          },
        } = responseJson;
        // Update updated question's isSaved status
        const questions = [...data.questions];
        const updatedQuestionIndex = getUnSavedQuestionIndex(questions);
        questions[updatedQuestionIndex].isSaved = true;
        // Filter out the option that has _destroy key
        const filteredOptions = questions[updatedQuestionIndex].options.filter(
          (option: any) => !option._destroy
        );
        questions[updatedQuestionIndex].options = filteredOptions;
        // Update option ids
        options.forEach((option: any, index: number) => {
          questions[updatedQuestionIndex].options[index].id = option.id;
          questions[updatedQuestionIndex].options[
            index
          ].label = `Answer ${String.fromCharCode(index + 65)}`;
        });
        // Update form values
        setValues({
          ...data,
          questions,
        });
        setSubmitting(false);
        setTouched({});
        toast.success("Question updated");
      } else if (responseJson && responseJson.errors) {
        const { errors } = responseJson;
        errors.forEach((error: Error) => {
          toast.error(error.message);
        });
      }
    } catch (error) {
      toast.error(error);
    }
  } else {
    // Create question
    try {
      const responseJson = await apiCall({
        contentType: applicationJson,
        method: httpPostMethod,
        endPoint: createQuestionUrl,
        payload: JSON.stringify(payload),
      });
      if (responseJson && !responseJson.errors && responseJson.data) {
        const {
          data: {
            id: questionId,
            attributes: { options },
          },
        } = responseJson;
        // Save question id to local storage
        const questionIdsString = localStorage.getItem("question_ids");
        const questionIds = questionIdsString
          ? [...JSON.parse(questionIdsString), questionId]
          : [questionId];
        localStorage.setItem("question_ids", JSON.stringify(questionIds));
        // Update question id & options ids to form values
        const questions = [...data.questions];
        const lastIndex = questions.length - 1;
        questions[lastIndex].id = questionId;
        questions[lastIndex].isSaved = true;
        options.forEach((option: any, index: number) => {
          questions[lastIndex].options[index].id = option.id;
        });
        setValues({
          ...data,
          questions,
        });
        setSubmitting(false);
        setTouched({});
        toast.success("Question saved");
      } else if (responseJson && responseJson.errors) {
        const { errors } = responseJson;
        errors.forEach((error: Error) => {
          toast.error(error.message);
        });
      }
    } catch (error) {
      toast.error(error);
    }
  }
};

export const handleDeleteQuestion = async (
  curValues: any,
  questionInd: number,
  setValues: any
) => {
  const questions = [...curValues.questions];
  const questionId = parseInt(questions[questionInd].id);
  const { applicationJson, httpDelMethod, createQuestionUrl } = configJSON;

  try {
    const responseJson = await apiCall({
      contentType: applicationJson,
      method: httpDelMethod,
      endPoint: `${createQuestionUrl}/${questionId}`,
    });
    if (responseJson && !responseJson.errors) {
      // Remove deleted question from the UI
      const { id: deletedQuestionId } = responseJson;
      const questions = [...curValues.questions].filter(
        (question: any) => question.id !== deletedQuestionId
      );
      setValues({
        ...curValues,
        questions,
      });
      // Remove deleted question id from local storage
      const questionIdsString = localStorage.getItem("question_ids");
      let questionIds = questionIdsString ? JSON.parse(questionIdsString) : [];
      questionIds = questionIds.filter(
        (id: string) => id !== deletedQuestionId
      );
      localStorage.setItem("question_ids", JSON.stringify(questionIds));
      toast.success("Question Deleted");
    } else if (responseJson && responseJson.errors) {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error(error);
  }
};

export const handleCreateQuiz = async (curValues: any, resetForm: any) => {
  // Abort creating quiz if No. of questions doesn't match
  if (curValues.questions.length !== parseInt(curValues.numOfQuestions)) {
    toast.error(
      `Please create ${curValues.numOfQuestions} questions to save quiz`
    );
    return;
  }
  // Create quiz
  const {
    id: quizId,
    courseId,
    quizName,
    passPercentage,
    phase,
    quizDuration,
    shuffleQuestions,
    numOfQuestions,
    failTest,
    showStats,
  } = curValues;
  const duration = quizDuration ? parseInt(quizDuration) : 0;
  const percentage = passPercentage ? parseInt(passPercentage) : 0;
  const questionIds = localStorage.getItem("question_ids");

  const payload = {
    course_id: courseId,
    title: quizName,
    phase,
    duration: isNaN(duration) ? 0 : duration,
    pass_percentage: isNaN(percentage) ? 0 : percentage,
    shuffle_questions: shuffleQuestions,
    question_ids: questionIds ? JSON.parse(questionIds) : [],
    quize_questions_count: numOfQuestions,
    stats: showStats === "yes" ? true : false,
    passing_score: failTest === "yes" ? true : false,
  };

  const {
    applicationJson,
    httpPostMethod,
    httpPutMethod,
    quizUrl,
  } = configJSON;

  // If quiz id is there edit the quiz otherwise create quiz
  if (quizId) {
    try {
      const responseJson = await apiCall({
        contentType: applicationJson,
        method: httpPutMethod,
        endPoint: `${quizUrl}/${quizId}`,
        payload: JSON.stringify(payload),
      });
      if (responseJson && !responseJson.errors) {
        toast.success("Quiz updated");
      } else if (responseJson && responseJson.errors) {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error);
    }
  } else {
    try {
      const responseJson = await apiCall({
        contentType: applicationJson,
        method: httpPostMethod,
        endPoint: quizUrl,
        payload: JSON.stringify(payload),
      });
      // Create quiz
      if (responseJson && !responseJson.errors && responseJson.data) {
        toast.success("Quiz created");
        // Remove question ids from local storage
        localStorage.removeItem("question_ids");
        // Reset the form
        resetForm();
        return true;
      } else if (responseJson && responseJson.errors) {
        const { errors } = responseJson;
        errors.forEach((error: Error) => {
          toast.error(error.message);
        });
      }
    } catch (error) {
      toast.error(error);
    }
  }
};

export const handleBulkActions = async (
  selectedQuestionIds: string[],
  action: string,
  values?: any,
  setValues?: any
) => {
  if (selectedQuestionIds.length > 0 && action) {
    const payload = {
      ids: selectedQuestionIds,
      bulk_action: action,
    };

    const { applicationJson, httpPostMethod, bulkActionUrl } = configJSON;

    try {
      const responseJson = await apiCall({
        contentType: applicationJson,
        method: httpPostMethod,
        endPoint: bulkActionUrl,
        payload: JSON.stringify(payload),
      });
      if (responseJson && !responseJson.errors) {
        if (!values && !setValues) {
          // Remove question ids form local storage
          // while navigating to another router w/o saving quiz
          localStorage.removeItem("question_ids");
          return;
        }
        const newValues = {
          ...values,
          bulkAction: "",
          selectedQuestionIds: [],
        };
        switch (action) {
          case "delete":
            let newQuestions = [...newValues.questions];
            newQuestions = newQuestions.filter(
              (question: any) => !selectedQuestionIds.includes(question.id)
            );
            newValues.questions = newQuestions;
            setValues(newValues);
            // Update question IDs in local storage
            const questionIdsString = localStorage.getItem("question_ids");
            let questionIds = questionIdsString
              ? JSON.parse(questionIdsString)
              : [];
            questionIds = questionIds.filter(
              (id: string) => !selectedQuestionIds.includes(id)
            );
            localStorage.setItem("question_ids", JSON.stringify(questionIds));
            toast.success("Questions deleted");
            break;
          case "deactive":
            const deactivatedQuestions = [...newValues.questions];
            deactivatedQuestions.forEach((question: any) => {
              if (selectedQuestionIds.includes(question.id)) {
                question.isActive = "no";
              }
            });
            newValues.questions = deactivatedQuestions;
            setValues(newValues);
            toast.success("Questions deactivated");
            break;
          case "active":
            const activatedQuestions = [...newValues.questions];
            activatedQuestions.forEach((question: any) => {
              if (selectedQuestionIds.includes(question.id)) {
                question.isActive = "yes";
              }
            });
            newValues.questions = activatedQuestions;
            setValues(newValues);
            toast.success("Questions activated");
            break;
        }
      } else if (responseJson && responseJson.errors) {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error);
    }
  }
};
