// Customizable Area Start
import React from "react";
import { Box, Typography, Button, TextField, Grid } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import ProfileModalController, {
    Props
} from "./ProfileModalController.web";
import './style.css'
import OtpInput from 'react-otp-input';
import { event } from "react-native-reanimated";
const back = require("../assets/Fill1.png")


export default class EditEmail extends ProfileModalController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.getUserProfileData();
        this.getUserProfileDataModal();
    }
    render() {
        const { userProfileDataModal } = this.state
        return (
            <Box className="scrollbar" style={styles.mainBox}>

                <Box style={styles.closeBox}>
                    <Typography variant="h5" style={styles.backTypo}><img style={styles.backImg} src={back} />{this.state.showEditEmailMsg ? "Change your email" : "Verification Code"}</Typography>
                    <Box style={styles.closeButton} onClick={this.props.handelEditEmailModelClose}><CloseIcon /></Box>
                </Box>
                {this.state.showEditEmailMsg ?
                    <>
                        <Box style={styles.mainBoxDelete}>
                            <Box style={{ marginTop: "25px" }}>
                                <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Email</Typography>
                                <TextField className="EditEmail" fullWidth placeholder="Email" variant="outlined" size="small"
                                    name="email_id"
                                    value={userProfileDataModal?.email}
                                    inputProps={{ type: 'email' }}
                                    disabled
                                    multiline
                                    rows={1.5}
                                />
                            </Box>

                            <Box style={{ marginTop: "25px" }}>
                                <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>New email</Typography>
                                <TextField className="EditEmail1" fullWidth placeholder="New email" variant="outlined" size="small"
                                    name="email_id"
                                    value={this.state.NewEmail}
                                    test-id="change1"
                                    onChange={(event) => this.setState({ NewEmail: event.target.value })}
                                    inputProps={{ type: 'email' }}
                                    multiline
                                    rows={1.5}
                                />
                            </Box>

                            <Box style={{ marginTop: "25px" }}>
                                <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Confirm new email</Typography>
                                <TextField className="EditEmail1" fullWidth placeholder="Confirm new email" variant="outlined" size="small"
                                    name="email_id"
                                    value={this.state.ConfirmNewEmail}
                                    test-id="change2"
                                    onChange={(event) => this.setState({ ConfirmNewEmail: event.target.value })}
                                    inputProps={{ type: 'email' }}
                                    multiline
                                    rows={1.5}
                                />
                            </Box>

                        </Box></> :
                    <>
                        <Box style={styles.mainBoxDelete}>
                            <Box style={{ marginTop: "-5px" }}>
                                <Typography variant="subtitle2" style={{ fontSize: '18px', fontWeight: 700 }}>Verification code</Typography>
                                <Typography style={{ fontSize: '16px', marginTop: '20px' }}>A verification code was sent to your phone, <span style={{ fontSize: '16px', fontWeight: 700 }}>enter the 4 digit code</span> received</Typography>
                            </Box>
                        </Box>

                        <OtpInput
                            value={this.state.otp}
                            test-id="change3"
                            onChange={(OtpInput) => this.setState({ otp: OtpInput })}
                            numInputs={4}
                            renderSeparator={<span style={{ display: "none" }}>-</span>}
                            renderInput={(props: any) => <input {...props} className={`otp-input ${this.state.otp ? "" : "otp-input-disabled"}`}
                            />}
                            inputStyle={{
                                width: '100px',
                                textAlign: 'center',
                                height: '100px',
                                borderRadius: '15px',
                                border: '1px solid #E6E8EC',
                                fontSize: '50px',
                            }}
                            containerStyle="otp-container"
                        />
                    </>
                }
                <Box style={styles.buttonBox}>
                    {this.state.showEditEmailMsg ?
                        <Button onClick={() => this.ChangeyourEmail()} style={styles.button}>Change your email</Button>
                        :
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Typography onClick={() => this.ResendOTP()} style={styles.codeType}>Resend code</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button onClick={() => this.OtpSubmit()} style={styles.confirm}>Confirm</Button>
                            </Grid>
                        </Grid>}
                </Box>
            </Box>
        );  
    }
}     

const styles = {
    button: {
        width: '100%',
        backgroundColor: "#FF8B3F",
        borderRadius: '20px',
        color: '#fff',
        fontWeight: 700,
        height: '48px',
    },
    mainBox: {
        width: "40vw",
        height: 'auto',
        overflowX: 'hidden' as 'hidden',
        marginTop: '15px', backgroundColor: "white",
        padding: '30px',
        borderRadius: "20px"
    },
    closeBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeButton: {
        borderRadius: "50px",
        width: "50px", height: "50px",
        cursor: 'pointer'
    },
    backTypo: {
        fontWeight: "bold" as "bold",
        fontSize: '32px',
    },
    backImg: {
        width: '12px', height: '20px', marginRight: '20px'
    },
    mainBoxDelete: {
        marginTop: '7%', paddingLeft: '15px'
    },

    buttonBox: {
        textAlign: 'center' as 'center',
        cursor: 'pointer',
        marginTop: '8%'
    },
    codeType: {
        textAlign: 'start' as 'start',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 700,
        color: 'var(--primary-purple, #6C328B)',
        marginTop: '12px',
    },
    confirm: {
        display: 'flex',
        width: '100%',
        height: '48px',
        fontSize: '16px',
        fontWeight: 700,
        justifyContent: 'center' as 'center',
        alignItems: 'center' as 'center',
        borderRadius: '28px',
        backgroundColor: '#8636A3',//'linear-gradient(90deg, #6C328B 0 %, #F42074 72.63 %, #EF2B4B 100 %)',
        color: '#fff'
    }

}

// Customizable Area End
