// Customizable Area Start
import React from "react";
import { Box, Typography, Button, Grid } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import ProfileModalController, {
    Props
} from "./ProfileModalController.web";
import './style.css'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
const close_myInterest = require("../assets/myInterest_icon.png");
const back = require("../assets/Fill1.png")


export default class MyIntrest extends ProfileModalController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.getMyInterestData()
    }

    render() {
        return (
            <Box className="scrollbar" style={styles.mainBox}>    
                <Box style={styles.closeBox}>
                    <Typography variant="h5" style={styles.backTypo}><img onClick={this.props.handleOpen} style={styles.backImg} src={back} />Your Interest</Typography>
                    <Box style={styles.closeButton} onClick={() => this.SaveCategories()}><CloseIcon /></Box>
                </Box>
                <Box style={styles.mainBoxDelete}>
                    <Typography style={styles.deleText}>My Current Interests</Typography>
                    <Grid container spacing={2}>
                        {this.state.myInterestData?.selected_category?.data?.map((data: any, index: number) => {
                            /* istanbul ignore next */
                            return (
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Box style={styles.GridMap}>
                                        <Box test-id="click2" onClick={() => this.Delete_Categories(data?.attributes?.id)} style={styles.closeImage}>
                                            <img style={styles.ImgClose} src={close_myInterest} />
                                        </Box>
                                        <Card style={styles.root}>
                                            <CardActionArea>
                                                <CardMedia
                                                    style={styles.media}
                                                    image={data?.attributes?.image === null ? 'https://picsum.photos/536/354' : data?.attributes?.image?.url}
                                                    title="Contemplative Reptile"
                                                />
                                                <Box style={styles.overlay}>{data?.attributes?.name}</Box>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>

                <Box style={styles.mainBoxAdd}>
                    {this.state.myInterestData?.category_list?.data === undefined ? <></> : <Typography style={styles.selectTypo}>Select New Interests</Typography>}
                    <Grid container spacing={2}>
                        {this.state.myInterestData?.category_list?.data?.map((data: any, index: number) => {
                            /* istanbul ignore next */
                            return (
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Box style={styles.GridMap}>
                                        <Card style={styles.root1} test-id="click3" onClick={() => this.Add_Categories(data?.attributes?.id)}>
                                            <Box style={styles.BoxOverlay}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        style={styles.media1}
                                                        image={data?.attributes?.image === null ? 'https://picsum.photos/536/354' : data?.attributes?.image?.url}
                                                        title="Contemplative Reptile"
                                                    />
                                                    <Box style={styles.overlay}>{data?.attributes?.name}</Box>
                                                </CardActionArea>
                                            </Box>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                <Box style={styles.buttonBox}>
                    <Button test-id="click4" onClick={this.props?.handleOpen} style={styles.button}>Save</Button>
                </Box>
            </Box>
        );
    }
}

const styles = {
    root: {
        width: '100%',
        marginTop: '10%',
        borderRadius: '13px',
        padding: '3px',
        border: '1px solid #EF2B4B',
        boxShadow: 'none',
        // position: "relative"
    },
    media: {
        height: 140,
        borderRadius: '13px',
    },
    root1: {
        width: '100%',
        marginTop: '10%',
        borderRadius: '13px',
        padding: '3px',
        boxShadow: 'none',
        // border: '1px solid #EF2B4B',
    },
    media1: {
        height: 140,
        borderRadius: '13px',
    },
    button: {
        width: '60%',
        backgroundColor: "#FF8B3F",
        borderRadius: '20px',
        color: '#fff',
        fontWeight: 700,
        height: '48px',
    },
    mainBox: {
        width: "40vw",
        height: '95vh',
        overflowX: 'hidden' as 'hidden',
        marginTop: '15px', backgroundColor: "white",
        padding: '30px',
        borderRadius: "20px"
    },
    closeBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeButton: {
        borderRadius: "50px",
        width: "50px", height: "50px"
    },
    backTypo: {
        fontWeight: "bold" as "bold"
    },
    backImg: {
        width: '12px', height: '20px', marginRight: '20px', cursor: 'pointer'
    },
    mainBoxDelete: {
        marginTop: '7%', paddingLeft: '15px'
    },
    deleText: {
        fontSize: '18px', fontWeight: 600
    },
    GridMap: {
        position: 'relative' as 'relative'
    },
    closeImage: {
        float: 'right' as 'right',
        marginRight: '18px',
        marginTop: '-12px',
        cursor: 'pointer'
    },
    ImgClose: {
        position: 'absolute' as 'absolute',
        zIndex: 999
    },
    mainBoxAdd: {
        marginTop: '10%', paddingLeft: '15px'
    },
    selectTypo: {
        fontSize: '18px', fontWeight: 600
    },
    buttonBox: {
        textAlign: 'center' as 'center',
        cursor: 'pointer',
        marginTop: '8%'
    },
    BoxOverlay: {
        position: 'relative' as 'relative',
    },
    overlay: {
        position: 'absolute' as 'absolute',
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)', /* Black see-through */
        color: '#f1f1f1',
        width: '100%',
        transition: '.5s ease',
        fontSize: '16px',
        padding: '90px 7px 0px',
        textAlign: 'center' as 'center',
        opacity: 1,
        borderRadius: '15px',
        fontWeight: 700,
        height: '-webkit-fill-available'
    }
}

// Customizable Area End
