// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Typography,
  Tooltip,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  IconButton,
  Grid,
  Radio,
  Switch,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  withStyles,
  styled
} from "@material-ui/core/styles";
import "react-calendar/dist/Calendar.css";
import { EditIcon3, deleteIcon, plusIcon, iconInfo, searchIcon, closeIcon, Document, AddIcon, addIcon, deleteIcon2, dollarIcon } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import BranchDetailsEditController, { AddContractResponse, PAYMENT_STATUS } from "./BranchDetailsEditController.web";
import MaterialUIBox from '@material-ui/core/Box';
import Loader from "../../../components/src/Loader";
import { Download, Delete } from "../../coursecreation/src/assets";
import moment from "moment";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const configJSON = require("./config.js");
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";

export interface Props {
  classes: any;
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '6px 6px 28px 0px #85858540, -6px -6px 28px 0px #85858540',
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#484646",
    maxWidth: '550px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#FFFFFF',
    width: '32px',
    height: '20px',
    position: "absolute",
    top: "39px",
  },
});

const CustomTooltip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '6px 6px 28px 0px #85858540, -6px -6px 28px 0px #85858540',
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#484646",
    maxWidth: '600px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#FFFFFF',
    width: '32px',
    height: '20px',
    position: "absolute",
    top: "39px",
  },
});

const CustomTooltip3 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '6px 6px 28px 0px #85858540, -6px -6px 28px 0px #85858540',
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#484646",
    maxWidth: '550px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#FFFFFF',
    width: '32px',
    height: '20px',
    position: "absolute",
    top: "39px",
  },
});

const CustomTooltip4 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '6px 6px 28px 0px #85858540, -6px -6px 28px 0px #85858540',
    position: "relative",
    left: "235px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "14px",
    color: "#484646",
    maxWidth: '800px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#FFFFFF',
    width: '32px',
    height: '20px',
    position: "absolute",
    top: "39px",
    left: "75px !important",
  },
});

class BranchDetailsEdit extends BranchDetailsEditController {

  downloadFile = async (fileName: string ,fileURL: string) => {
    const responseUrl = await fetch(fileURL);
    const blobURL= await responseUrl.blob();
    const fileDownloadURL = URL.createObjectURL(blobURL);
    const a = document.createElement('a');
    a.href = fileDownloadURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderBilling() {
    const { selectedOption, saveChangesDisable } = this.state;
    const isAddTierDisabled = this.state.tierSectionValues?.length >= 5;
    const estimatedTotal = this.calculateEstimatedTotal();
    const isDisable = this.disablePaymentField();
    return (
      <div className={this.props.classes.root4}>
         <div className={this.props.classes.billingDiv} >
          <div className={this.props.classes.billingNameStatusWrapper} >
        <Typography className={this.props.classes.billingHeading}>
         {configJSON.billingTitle}
          { this.state.isEditBranchScreen &&
            <img src={EditIcon3}
            className={this.props.classes.editIconImg}
            data-test-id="editBillingDetails"
            onClick={this.editBranchBilling}
          />
          }
        </Typography>
        {this.state.branchBillingDetails?.attributes?.payment_status && this.state.selectedOption &&  ! (this.state.selectedOption === "user_driven" || 
              this.state.selectedOption === "admin_driven" && this.state.isFreePlanEnabled)
            &&
        <div
          className={
            this.state.branchBillingDetails?.attributes?.payment_status === PAYMENT_STATUS.PAID
              ? this.props.classes.paymentPaidStatus
              : this.props.classes.paymentPendingStatus
          } >
          {this.displayPaymentStatus(this.state.branchBillingDetails?.attributes?.payment_status)}
        </div>
        }
        </div>
        <p style={{marginBottom:"0px"}} className={this.props.classes.billingSubHeading}>
        {configJSON.billingSubtitle}
        </p>
         </div>
         <div style={{padding: "0px 0px 20px 0px",marginLeft: "24px"}} >
        <FormControlLabel
          control={
            <Radio
              value="user_driven"
              checked={selectedOption === "user_driven"}
              disabled={this.state.isEditBranchScreen}
              onChange={this.handleRadioChange}
              size="small"
              style={{
                color: selectedOption === "user_driven" ? "#652786" : undefined,
                height: "24px", width: "24px", marginLeft: "5px"
              }}
            />
          }
          label={<span style={{ marginLeft: "6px", borderRight: "1px solid #929090", paddingRight: "15px", fontWeight: 600, fontSize: "18px", color: "#484646" }} className={this.props.classes.toggleBtnText} >User Driven
            <CustomTooltip4 enterTouchDelay={0} title="Users can buy products independent of the branch and are charged the price set on the Course/Program." arrow placement="top">
              <img className={this.props.classes.InfoIcon1} src={iconInfo} />
            </CustomTooltip4>
          </span>}
        />
        <FormControlLabel
          control={
            <Radio
              value="admin_driven"
              checked={selectedOption === "admin_driven"}
              disabled={this.state.isEditBranchScreen}
              onChange={this.handleRadioChange}
              size="small"
              style={{
                color: selectedOption === "admin_driven" ? "#652786" : undefined,
                height: "24px", width: "24px", marginLeft: "12px"
              }}
            />
          }
          label={<span style={{ marginLeft: "6px", fontWeight: 600, fontSize: "18px", color: "#484646"  }} className={this.props.classes.toggleBtnText} >{configJSON.adminSide}</span>}
        />
         </div>

        <div className={this.props.classes.billingSection} >
          <div className={this.props.classes.adminSideRightSideContainer}>
            {selectedOption === "admin_driven" && (
              <>
                <div className={this.props.classes.toggleSectionsDiv} >
                  <p className={this.props.classes.toggleBtnText}>{configJSON.freePlan}</p>
                  <Switch
                    checked={this.state.isFreePlanEnabled}
                    onChange={this.handleSwitchChange}
                    disabled={this.state.isEditBranchScreen}
                    className="Switch"
                    classes={{
                      switchBase: this.props.classes.switchBase,
                      track: this.props.classes.track,
                    }}
                  />
                </div>

                {!this.state.isFreePlanEnabled && (
                  <div className={this.props.classes.flatFeeToggle}>
                    <div style={{ display: "flex" }}>
                      <div className={this.props.classes.toggleSectionsDiv} >
                        <p className={this.props.classes.toggleBtnText}>{configJSON.flatFee}</p>
                        <Switch
                          checked={this.state.isFlatFeeEnabled}
                          onChange={this.handleisFlatFeeEnabledChange}
                          disabled={this.state.isEditBranchScreen}
                          className="Switch"
                          classes={{
                            switchBase: this.props.classes.switchBase,
                            track: this.props.classes.track,
                          }}
                        />
                      </div>

                    </div>
                    <div className={this.props.classes.flatFeeToggle}>
                      <div className={this.props.classes.toggleSectionsDiv} >
                        <p className={this.props.classes.toggleBtnText}>{configJSON.payPerUser}</p>
                        <Switch
                          checked={this.state.isPayPerUserEnabled}
                          onChange={this.handlePayPerUserChange}
                          disabled={this.state.isEditBranchScreen}
                          className="Switch"
                          classes={{
                            switchBase: this.props.classes.switchBase,
                            track: this.props.classes.track,
                          }}
                        />
                      </div>
                      {this.state.isPayPerUserEnabled && (
                        <div>
                          <div style={{ display: "flex", alignItems: "center" }} >
                            <div className={this.props.classes.perActiveUserDiv} >
                              <p className={this.props.classes.toggleBtnText2}>{configJSON.perActiveUser}
                                <CustomTooltip3 enterTouchDelay={0} title="Users who logged in and viewed any lesson within the last 30 days." arrow placement="top">
                                  <img className={this.props.classes.InfoIcon2} src={iconInfo} />
                                </CustomTooltip3></p>
                              <Switch
                                checked={this.state.isPerActiveUserEnabled}
                                onChange={this.handlePerActiveUserChange}
                                disabled={this.state.isEditBranchScreen}
                                className="Switch"
                                classes={{
                                  switchBase: this.props.classes.switchBase,
                                  track: this.props.classes.track,
                                }}
                              />
                            </div>
                          </div>
                          <div className={this.props.classes.TieredSectionDiv} >
                            <p className={this.props.classes.toggleBtnText2}>{configJSON.tierTitle}</p>
                            <Switch
                              checked={this.state.isTieredEnabled}
                              onChange={this.handleTieredChange}
                              disabled={this.state.isEditBranchScreen}
                              className="Switch"
                              classes={{
                                switchBase: this.props.classes.switchBase,
                                track: this.props.classes.track,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

          </div>
          {(this.state.isFlatFeeEnabled || this.state.isPayPerUserEnabled) && (
            <div className={this.props.classes.adminSideLeftSideContainer}>
              <div  className={this.props.classes.adminsideLeftSideBorderContainer}>
                <div style={{marginBottom:"13px"}} className={this.props.classes.paymentPeriod} >
                  <p className={this.props.classes.toggleBtnTextOption}>{configJSON.paymentPeriod}</p>
                  <FormControl variant="outlined" style={{ minWidth: 200 }}>
                    <Select
                      displayEmpty
                      className={this.props.classes.monthlyPaymentInputs}
                      value={this.state.paymentPeriodOption}
                      onChange={this.handlePaymentPeriodOptionChange}
                      IconComponent={KeyboardArrowDownIcon}
                      style={{
                        color: this.state.paymentPeriodOption === "" ? "#ADAAAA" : "inherit",
                        border: this.state.paymentPeriodOption ? "1px solid #929090": "1px solid #E5E2E1",
                      }}
                      disabled={this.state.isEditBranchScreen}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem style={{ color: "#ADAAAA", fontSize: "16px", fontWeight: 400 }} value="" disabled>
                        Select period
                      </MenuItem>
                      {[
                        { value: "monthly", label: "Monthly" },
                        { value: "semi_anually", label: "Semi-Anually" },
                        { value: "anually", label: "Anually" },
                      ].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {this.state.isFlatFeeEnabled && (
                  <div style={{marginBottom:"18px"}} className={this.props.classes.flatFeeSectionFiled}>
                    <p className={this.props.classes.toggleBtnTextOption}>{configJSON.flatFee}</p>
                    <TextField
                      placeholder="00.00"
                      variant="outlined"
                      type="text"
                      className={this.props.classes.BillingSectionInputs}
                      value={this.state.amount}
                      onChange={this.handleAmountChange}
                      disabled={this.disablePaymentField()}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton style={{ padding: '0px' }}>
                            <img src={dollarIcon} className={this.props.classes.dollarIconStyle} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <IconButton style={{ padding: '0px' }} disabled={this.disablePaymentField()} onClick={this.handleIncrement}>
                                  <KeyboardArrowUpIcon fontSize="small" />
                                </IconButton>
                                <IconButton style={{ padding: '0px' }} disabled={this.disablePaymentField()} onClick={this.handleDecrement}>
                                  <KeyboardArrowDownIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </div>
                          </InputAdornment>
                        ),
                        inputProps: {
                          style: { textAlign: 'right' },
                        },
                        style: {
                          borderRadius: '8px',
                          border: this.state.amount ? "1px solid #929090": "1px solid #E5E2E1",
                        },
                      }}
                    />
                  </div>
                )}

                {this.state.isPayPerUserEnabled && (
                  <div>

                    {this.state.isTieredEnabled ? (
                      <>
                     <div  style={{ display: "flex"}}>
                      <div  className={this.props.classes.userRange}>User Range</div>
                      <div className={this.props.classes.payPerPrice}> Price Per User</div>
                      
                     </div>
                       {this.state.tierSectionValues.map((val, index) => (
                      <div className={this.props.classes.minMaxRangeDiv}>
                        <div>
                          <div className={this.props.classes.minMaxContainer} >
                            <div>
                              <p  className={this.props.classes.minText}>
                                Min.
                              </p>
                              <TextField
                                data-test-id="minTest"
                                variant="outlined"
                                name="min"
                                className={this.props.classes.mininumInput}
                                value={val.min}
                                onChange={(event:any) => this.handleNameChange(event, index)}
                                InputProps={{
                                  style: { width: "140px", height: "40px", color: "#787776", 
                                  border: "1px solid #929090", borderRadius: '8px', background: "#E5E2E1" },
                                }}
                                disabled 
                              />
                            </div>
                            <div className={this.props.classes.maxRangeDiv}>
                              <p className={this.props.classes.setPriceTitle}>
                                Max.
                              </p>
                              <TextField
                                name="max"
                                data-test-id="maxTest"
                                value={val.max}
                                type="text"
                                placeholder="Max"
                                onChange={(event:any) => this.handleNameChange(event, index)}
                                className={this.props.classes.maximumInput}    
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <IconButton data-test-id="maxTestUparrow" style={{ padding: "0px" }} disabled={this.state.isEditBranchScreen} onClick={() => this.handleTierIncrement(index, 'max')}>
                                          <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                                        </IconButton>
                                        <IconButton disabled={this.state.isEditBranchScreen} onClick={() => this.handleTierDecrement(index, 'max')} style={{ padding: "0px" }}>
                                          <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                                        </IconButton>
                                      </div>
                                    </InputAdornment>
                                  ),
                                  style: { width: "140px",
                                           height: "40px",
                                           padding:"0px 5px 0px 10px",
                                           border:  val.max ? " 1px solid #929090" : "1px solid #E5E2E1",
                                           borderRadius: "8px"
                                          },
                                }}
                                disabled={this.state.isEditBranchScreen}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex" }}>
                            <div>
                              <p className={this.props.classes.setPriceTitle}>
                                {configJSON.setPriceTitle}
                              </p>
                              <TextField
                                data-test-id="setPriceTest"
                                variant="outlined"
                                name="tierPrice"
                                value={val.tierPrice || ""}
                                type="text"
                                className={this.props.classes.setPriceInput}
                                onChange={(event:any) => this.handleNameChange(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={dollarIcon} className={this.props.classes.dollarIconStyle} />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <IconButton data-test-id="setPriceUpArrowCountTest" style={{ padding: "0px" }} disabled={this.disablePaymentField()} onClick={() => this.handleTierIncrement(index, 'tierPrice')} >
                                          <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                                        </IconButton>
                                        <IconButton disabled={this.disablePaymentField()} onClick={() => this.handleTierDecrement(index, 'tierPrice')} style={{ padding: "0px" }}>
                                          <KeyboardArrowDownIcon style={{ fontSize: "15px" }}  />
                                        </IconButton>
                                      </div>
                                    </InputAdornment>
                                  ),
                                  style: {
                                    height: "40px", border:  val.tierPrice ? " 1px solid #929090" : "1px solid #E5E2E1", borderRadius: "8px"
                                  },
                                  inputProps: {
                                    style: { textAlign: 'right' },
                                  },
                                }}
                                disabled={this.disablePaymentField()}
                              />
                            </div>
                          </div>
                          {!this.state.isEditBranchScreen && index > 0 && (
                          <img className={this.props.classes.tierDeleteIcon} data-test-id="deleteTier" onClick={() => this.handleDeleteButton(index)} src={deleteIcon2} />
                          )}
                        </div>
                       
                      </div>
                      ))}

                      {this.state.errorMessage && (
                        <div style={{ color: "red", marginBottom: "10px",fontSize: "0.75rem",fontFamily: "Poppins"}}>
                          {this.state.errorMessage}
                        </div>
                      )}
                      {!this.state.isEditBranchScreen &&
                      <div
                      style={{opacity: isAddTierDisabled ? 0.5 : 1}}
                       className={`${this.props.classes.addTierDiv} ${isAddTierDisabled ? this.props.classes.disabledButton : ""}`}
                       onClick={this.handleClickAdd}
                       data-test-id="addTier">
                        <img src={addIcon} style={{width:"24px",height:"24px"}} /> 
                        <span className={this.props.classes.addTierTitle}> 
                          {configJSON.addTierTitle} 
                        </span>
                      </div>
                      }

                      </>
                    ) : (
                      <div style={{marginBottom:"22px" }} className={this.props.classes.payPerUserSectionFiled}>
                        <div className={this.props.classes.pricePerUser} >
                          <p className={this.props.classes.toggleBtnTextOption} style={{ margin: "4px 0" }}>
                            Price per user
                          </p>
                          {this.state.isPerActiveUserEnabled ? (
                            <p className={this.props.classes.activeUsers}>(Active users = {this.state.activeUsers})</p>
                          ) : (
                            <p className={this.props.classes.activeUsers}>(Total users = {this.state.totalUsers})</p>
                          )}
                        </div>
                        <TextField
                          placeholder="00.00"
                          variant="outlined"
                          type="text"
                          className={this.props.classes.BillingSectionInputs}
                          value={this.state.payPerUserAmount}
                          onChange={this.handlePayPerUserAmountChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton style={{ padding: '0px' }}>
                                  <img src={dollarIcon} className={this.props.classes.dollarIconStyle} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <IconButton style={{ padding: "0px" }} disabled={this.disablePaymentField()} onClick={this.handlePayPerUserAmountIncrement}>
                                    <KeyboardArrowUpIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton style={{ padding: "0px" }} disabled={this.disablePaymentField()} onClick={this.handlePayPerUserAmountDecrement}>
                                    <KeyboardArrowDownIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </InputAdornment>
                            ),
                            inputProps: {
                              style: { textAlign: 'right' },
                            },
                            style: {
                              borderRadius: '8px',
                              border: this.state.payPerUserAmount ? "1px solid #929090": "1px solid #E5E2E1",
                            },
                          }}
                          disabled={this.disablePaymentField()}
                        />
                      </div>
                    )}
                    <div className={this.props.classes.flatFeeSection} style={{ borderTop: "1px solid #E5E2E1", padding: "12px 0px", marginTop: "20px" }}>
                      <span className={this.props.classes.EstimatedTotalText} >Estimated Total
                        <CustomTooltip2 enterTouchDelay={0} title="The amount is calculated at moment of billing, depending on current users." arrow placement="top">
                          <img className={this.props.classes.InfoIcon3} src={iconInfo} />
                        </CustomTooltip2></span>
                      <span className={this.props.classes.EstimatedTotalText}>US${estimatedTotal}</span>
                    </div>
                  </div>
                )}

              </div>
             
            </div>
          )}
        </div>
       
        <div className={this.props.classes.addPaymentButtonDiv}>
          <button
            disabled={isDisable || saveChangesDisable}
            data-test-id="addsavechangesData"
            className={isDisable || saveChangesDisable ? this.props.classes.addPaymentButtonDisable : this.props.classes.addPaymentButton}
            style={{background:isDisable || saveChangesDisable ? "gray":"#652786"}}
            onClick={(event) => this.postBilling(event)}>
            Save Changes
          </button>
        </div>
        {(this.state.isFlatFeeEnabled || this.state.isPayPerUserEnabled) && !(this.state.branchBillingDetails?.attributes?.payment_status === PAYMENT_STATUS.PAID) && (
        <div className={this.props.classes.addPaymentButtonDiv}>
          <button 
          data-test-id="addPaymentData" 
          disabled={!this.state.addPaymentEnable}
          onClick={() => this.handleAddPayment()}
          className={!this.state.addPaymentEnable ? this.props.classes.addPaymentButtonDisable : this.props.classes.addPaymentButton}
          style={{background:!this.state.addPaymentEnable ? "gray":"#652786"}}>
            Add Payment
          </button>
        </div>
        )}

      </div>
    );
  }

  render() {
    return (
      <>
      {this.state.loading ? (
            <Loader loading={this.state.loading} />
          ) : (
        <div className={this.props.classes.container} >
          <div className={this.props.classes.root2}>
            <div className={this.props.classes.userInfoDiv}>
              <div className={this.props.classes.userInfoInnerDiv} >
                <div className={this.props.classes.userInfoInnerDiv2} >
                  <Typography className={this.props.classes.userInformationHeading2}>
                    Branch Information
                  </Typography>
                  <img src={EditIcon3}
                    className={this.props.classes.editIconImg}
                    data-test-id="userEditUser"
                  />
                </div>
                <div className={this.props.classes.userInfoInnerDiv4}>
                  <div className={this.props.classes.userInfoDiv10} >
                  <div className={this.props.classes.userInfoInnerDiv5}>
                      <p className={this.props.classes.userInformationName}>Parent Branch</p>
                      <p className={this.props.classes.userInformationName}>Branch Manager</p>
                  </div>
                  <div className={this.props.classes.userInfoInnerDiv6} >
                    <span className={this.props.classes.userInformationsubName}>{this.state.parentBranch}</span>
                    <span className={this.props.classes.userInformationsubName}>{this.state.branchManager}</span>

                  </div>
                  </div>
                  <div className={this.props.classes.userInfoDiv11}>
                  <div className={this.props.classes.branchInfoInnerDiv5}>
                    <p className={this.props.classes.userInformationName}>Branch Name</p>
                    <div>
                    <p className={this.props.classes.userInformationActiveUser}>Total active users
                    <CustomTooltip enterTouchDelay={0} title="Users who logged in and viewed any lesson within the last 30 days." arrow placement="top">
                      <img style={{ cursor: "pointer", height: "16px", width: "16px",marginLeft:"5px" }} src={iconInfo} />
                    </CustomTooltip>
                    </p>
                   
                    </div>
                  </div>
                  <div className={this.props.classes.userInfoInnerDiv11} >
                    <span className={this.props.classes.userInformationBranchName}>{this.state.branchName}</span>
                    <span className={this.props.classes.userInformationsubName2}>{this.state.activeUsers}</span>
                  </div>
                  </div>
                </div>
                
              </div>
              <div className={this.props.classes.userInfoInnerDiv3} >
              <div className={this.props.classes.userInfoInnerDivStatus}>
              <div
                  className={
                    this.state.branchStatus === "active"
                      ? this.props.classes.statusStyle
                      : this.props.classes.statusInactiveStyle
                  } >
                  {this.state.branchStatus}
                </div>
                </div>
                <div className={this.props.classes.userInfoDeleteUserDiv} >
                  <div className={this.props.classes.userInfoDeleteUserInnerDiv} >
                    <div onClick={this.cancelLessonDeleteDialog} className={this.props.classes.userInformationDeleteDiv} >
                      <img src={deleteIcon} />
                      <span className={this.props.classes.userInformationDeleteText}>
                        Delete branch
                      </span>
                    </div>
                    { 
                    this.state.branchStatus === "active" &&
                    <div data-test-id="make-inactive" onClick={this.toggleMakeInactiveDialog} className={this.props.classes.makeInactiveDiv} >
                      <span className={this.props.classes.makeInactiveText}>
                        Make Inactive
                      </span>
                    </div>
                    }
                  </div>
                </div>
                {
                  <DeleteModal
                  data-test-id="make-inactive-branch-dialogue"
                  openDialog={this.state.makeInactiveDialogue}
                  headingText={"Are you sure you want to make this branch inactive?"}
                  subHeading={"Users will no longer have access to content assigned to them via this branch, and billing will stop."}
                  btnCancelText={"Cancel"}
                  btnOkText={"Continue"}
                  handleCancel={this.toggleMakeInactiveDialog}
                  handleOk={() => this.makeInActiveBranchAPI()} 
                />
                }
                {<ModalMainBody
                  open={this.state.openUserDeleteDialog}
                >
                  <ModalContent>
                    <IconAlign onClick={this.cancelLessonDeleteDialog}><CloseIcon /></IconAlign>
                    <ModalText>{`Delete branch  ?`}</ModalText>
                    <ModalText1>{"Users will no longer have access to content assigned to them via this branch, and billing will stop. Usage data will be kept in reporting."}</ModalText1>
                    <div style={{ width: "85%" }}>
                      <div className={this.props.classes.buttonContainer}>
                        <button data-test-id="cancelTeamData" className={this.props.classes.cancelButton} onClick={this.cancelLessonDeleteDialog}>Cancel</button>
                        <button data-test-id="deleteBranchData" className={this.props.classes.deleteButton} onClick={() => this.handleDeleteUserData()}>Delete</button>
                      </div>
                    </div>
                  </ModalContent>
                </ModalMainBody>}
              </div>
            </div>
            <div className={this.props.classes.assignContentDiv} >
              <div className={this.props.classes.assignContentInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading3}>
                  Assign Content
                </Typography>
              </div>
              <div className={this.props.classes.assignContentInnerDiv2} >
                <span className={this.props.classes.userInformationName}>Select the content this branch will be allowed to view</span>
                <button data-test-id="userAssign" onClick={() => this.handleAssignContent(this.state.branchId)} className={this.props.classes.userInformationAssignText}>
                  Assign Content
                </button>
              </div>
            </div>
          </div>
          <div className={this.props.classes.root3}>
            <div className={this.props.classes.teamsDiv} >
              <div className={this.props.classes.teamsInnerDiv} >
                <Typography className={this.props.classes.userInformationHeading4}>
                  Teams
                </Typography>
              </div>
              <div className={this.props.classes.teamsInnerDiv2} >
                <span className={this.props.classes.userInformationHeading5}>All users in selected teams will inherit content assigned.</span>
              </div>
            </div>
            <div className={this.props.classes.assignContentBtnDiv} >
              <div className={this.props.classes.assignContentBtnInnerDiv} data-test-id="addTeam" onClick={this.toggleDropdown}>
                <img className={this.props.classes.assignContentImg} src={plusIcon} />
                <span className={this.props.classes.userInformationAssignText2}>Add Teams</span>
              </div>
              {this.state.dropdownAddTeams && (
                <div className={this.props.classes.dropdownOutsideDiv} ref={this.dropdownRef}>
                  <MaterialUIBox
                    p={3}
                    className={this.props.classes.teamsDropdown}
                  >
                    <DropdownContent>
                      <TextFieldSearch
                        data-test-id="teamSearch"
                        placeholder="Search Team Name"
                        variant="outlined"
                        value={this.state.placeholderSearchTeamsText}
                        onChange={this.handleTeamsSearchChange}
                        InputProps={{
                          startAdornment: <img src={searchIcon}
                            className={this.props.classes.teamSearchIcon}
                          />,
                        }}
                      />
                      <MaterialUIBox data-test-id="checkData1" className="checkboxList">
                        {this.state.teamsListing && this.state.teamsListing
                          .filter((team: any) => team.attributes.name.toLowerCase().includes(this.state.placeholderSearchTeamsText.toLowerCase()))
                          .map((team: any) => {
                            const isChecked = this.state.teamsSelectedItems && this.state.teamsSelectedItems.find((item: { id: number, value: string }) => item.value === team.attributes.name);
                            return (
                              <FormControlLabel
                                key={team.id}
                                className={isChecked ? "checkboxContent selectedStyle" : "checkboxContent"}
                                control={
                                  <CheckboxMain
                                    data-test-id="teamLabelsTestId"
                                    checked={isChecked ? true : false}
                                    name={team.attributes.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.tagsCheckboxChangeHandler(event, team.id);
                                    }}
                                  />
                                }
                                label={team.attributes.name}
                              />
                            );
                          })}
                      </MaterialUIBox>
                    </DropdownContent>
                  </MaterialUIBox>
                </div>
              )}
              {this.state.teamsSelectedItems.map(team => (
                <div key={team.id}>
                  <Chip
                    data-test-id="addTeamName"
                    label={team.value}
                    onClick={() => {
                      this.navigateToManageTeam(team.id)
                    }}
                    onDelete={() => this.handleClearTeamNames(team)}
                    variant="outlined"
                    className={this.props.classes.selectedTeamsList}
                    deleteIcon={<img src={closeIcon}
                      className={this.props.classes.selectedTeamsDeleteIcon}
                    />}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={this.props.classes.saveChangesTeamsBtnDiv}>
            <SaveAndCreate
              data-test-id="saveChangesBtn"
              onClick={this.teamLessonDialog}
              className={this.state.teamsSelectedItems.length > 0 ? this.props.classes.saveChangesEnableBtn : this.props.classes.saveChangesDisableBtn}
              disabled={!this.state.teamsSelectedItems.length}>
              <Typography className={this.props.classes.typo}>Save Changes</Typography>
            </SaveAndCreate>
          </div>
          {this.renderBilling()}

          
            { this.state.isEditBranchScreen &&
            <Box className={this.props.classes.contractHeading}>
              <Grid container justifyContent={"space-between"} style={{padding: "16px"}}>
              <Typography variant="h6">
                Contracts
              </Typography>

              <Box>
                  <Button
                    startIcon={<img src={AddIcon} />}
                    onClick={() => this.openPDFFileSelect()}
                    data-test-id="add-pdf-button"
                  >
                    <span className={this.props.classes.addPDFText}>Add PDF</span>
                  </Button>
                  <input
                    type="file"
                    accept="application/pdf"
                    data-test-id="upload-pdf"
                    style={{display: "none"}}
                    ref={this.fileInputRef}
                    onChange={this.handleFileChange}
                  />
              </Box>
              </Grid>

              <Paper elevation={1} className={this.props.classes.contractListWrapper}>
                {this.state.contracts?.length > 0 &&
                this.state.contracts.map((contract: AddContractResponse , index) => {
                  const currentDate = new Date(contract.attributes?.created_at); 
                  const formattedDate = moment(currentDate).format('MM-DD-YYYY');
                  return(
                  <Box
                  key={index}
                  className={this.props.classes.documentRow}
                  >
                    <Box className={this.props.classes.contractWrapperName}>
                      <img src={Document} style={{paddingRight: "16px"}} />
                      <Typography className={this.props.classes.wrapName}>{contract.attributes.file.filename}</Typography>
                    </Box>

                    <Typography color="textSecondary">{formattedDate}</Typography>

                    <Box style={{ display: 'flex', gap: 1 }}>
                      <IconButton color="primary" data-test-id="download-pdf" onClick={() => this.downloadFile(contract.attributes.file.filename ,contract.attributes.file.url)}>
                        <img src={Download}/>
                      </IconButton>
                      <IconButton color="secondary" data-test-id="delete-pdf" onClick={() => this.deleteContractFile(Number(contract.id))}>
                        <img src={Delete}/>
                      </IconButton>
                    </Box>
                  </Box>
                  )
              })}

              {this.state.contracts.length === 0 &&
                  <Box
                    className={this.props.classes.noDocumentFound}
                  >
                    There are no contracts uploaded yet.
                  </Box>
              }
                

              </Paper>
            </Box>
            }
          <div className={this.props.classes.saveChangesDiv} >
            {<ModalMainBody
                  open={this.state.openTeamDialog}
                >
                  <ModalContent>
                    <IconAlign onClick={this.teamLessonDialog}><CloseIcon /></IconAlign>
                    <ModalText style={{marginBottom:"30px"}}>{`Are you sure you want to save changes?`}</ModalText>
                    <div style={{ width: "85%" }}>
                      <div className={this.props.classes.buttonContainer}>
                        <button data-test-id="cancelBranchData" className={this.props.classes.cancelButton} onClick={this.teamLessonDialog}>Cancel</button>
                        <button data-test-id="SaveChangesData" className={this.props.classes.deleteButton} onClick={() =>  this.updateTeamsToUser(this.state.teamsSelectedItems)}>Save</button>
                      </div>
                    </div>
                  </ModalContent>
                </ModalMainBody>}
          </div>
        </div>
        )}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    saveChangesTeamsBtnDiv: {
      display: "flex",
      justifyContent: "center",
      margin: "25px 0px 10px 0px"
    },
    saveChangesDiv: {
      display: "flex",
      justifyContent: "center",
      marginTop: "35px",
      width: "97%"
    },
    saveChangesDisableBtn: {
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E5E2E1",
      borderRadius: "16px",
      color: "#929090",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      width: "411px",
      height: "56px",
      font: "normal 600 16px Poppins",
      textTransform: "capitalize"
    },
    saveChangesEnableBtn: {
      width: "411px",
      height: "56px",
      display: "flex",
      padding: "20px 32px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      border: "1px solid #E47004",
      borderRadius: "16px",
      color: "#E47004",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "capitalize"

    },
    selectedTeamsDeleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '-15px',
      width: "24px",
      height: "24px",
      cursor: "pointer"
    },
    selectedTeamsList: {
      borderColor: '#652786',
      color: '#652786',
      width: "170px",
      height: "40px",
      borderRadius: "12px !important",
      border: "1px solid #652786 !important",
      backgroundColor: "#FFFFFF !important",
      fontSize: "14px",
      fontFamily: "Poppins !important",
      fontWeight: 400,
      textAlign: "center",
      lineHeight: "16px",
      margin: "0 0 20px 15px",
      position: "relative",
      "@media (max-width: 630px)": {
      margin: "15px 0 0px 15px",
      },
    },
    teamSearchIcon: {
      width: "20px",
      height: "20px",
      position: "relative",
      zIndex: 999,
      marginRight: "6px"
    },
    teamsDropdown: {
      padding: '16px 16px 20px 16px',
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      width: "380px",
      boxSizing: "border-box",
      position: "absolute",
      zIndex: 9999,
      marginTop: "8px",
      overflow: "auto",
      height: "auto"
    },
    dropdownOutsideDiv: {
      position: "relative",
      top: "42px",
      right: "185px"
    },
    container: {
      width: "100%",
      margin: "0px 20px"
    },
    root2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    root3: {
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      marginTop: "25px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    root4: {
      width: "97%",
      backgroundColor: "#FFFFFF",
      padding: "24px",
      margin: "25px 0px",
      borderRadius: "16px",
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.0677)',
    },
    statusStyle: {
      fontSize: "14px",
      width: '96px',
      height: '40px',
      borderRadius: '88px',
      backgroundColor: '#E0D4E7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '6px 9px',
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#542070",
      textTransform: "capitalize"
    },
    paymentPaidStatus: {
      fontSize: "14px",
      width: '96px',
      height: '40px',
      borderRadius: '88px',
      backgroundColor: '#D7FFE7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '6px 9px',
      gap: "10px",
      fontFamily: "Poppins",
      fontWeight: 400,
      color: "#005B1D",
      textTransform: "capitalize"
    },
    userInfoDiv: {
      borderBottom: "1px solid #E5E2E1",
      padding: "15px 0px 22px 0px",
      width: "97%",
      display: "flex",
      '@media (max-width: 1024px)': {
        flexWrap: "wrap"
      },
    },
    userInfoInnerDiv: {
      display: "flex",
      width: "97%",
      flexDirection: "column"
    },
    userInfoInnerDiv3: {
      display: "flex",
      flexDirection: "column",
      '@media (max-width: 1024px)': {
        flexDirection: "row",
      },
      '@media (max-width: 500px)': {
        flexWrap: "wrap"
      },
    },
    userInfoInnerDivStatus: {
      display: "flex", 
      alignItems: "left", 
      justifyContent: "end", 
      margin: "0 15px 24px 0",
      '@media (max-width: 1024px)': {
        margin: "20px 20px 0 0",
      },
    },
    userInfoInnerDiv4: {
      display: "flex",
      '@media (max-width: 600px)': {
        flexWrap:"wrap"
      },
    },
    userInfoDiv10:{
      display: "flex",
    },
    userInfoDiv11:{
      display: "flex",
      '@media (max-width: 600px)': {
        marginTop: "15px"
      },
      borderLeft: "1px solid #E5E2E1",
      borderRight: "1px solid #E5E2E1",
    },
    userInfoInnerDiv5: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      '@media (max-width: 600px)': {
        margin:"0 5px 0px 0px"
      },

    },
    branchInfoInnerDiv5: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      paddingLeft: "18px",
      '@media (max-width: 600px)': {
        paddingLeft: "0px",
        borderLeft: "none",
      },
    },
    userInfoInnerDiv6: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "20px",
      gap: "8px",
      paddingRight: "15px"
    },
    userInfoDeleteUserDiv: {
      display: "flex",
      alignItems: "end",

      '@media (max-width: 960px)': {
        marginTop: "20px",
      },
    },
    userInfoInnerDiv11: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "20px",
      gap: "8px",
      paddingRight: "15px",
      '@media (max-width: 600px)': {
        margin: "0px"
      },
    },
    userInfoDeleteUserInnerDiv: {
      display: "flex",
      alignItems: "center",
      '@media (max-width: 960px)': {
        flexWrap: "wrap"
      },
    },
    assignContentDiv: {
      padding: "18px 0px 18px 0px",
      width: "97%"
    },
    assignContentInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    userInfoInnerDiv2: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px"
    },
    statusInactiveStyle: {
      height: '40px',
      width: '96px',
      backgroundColor: '#FFD9DF',
      borderRadius: '88px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      gap: "10px",
      padding: '6px 9px',
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#E70E6B",
      textTransform: "capitalize",
      '@media (max-width: 600px)': {
        marginLeft:"5px"
      },
    },
    paymentPendingStatus: {
      height: '40px',
      width: '142px',
      backgroundColor: '#FFD9DF',
      borderRadius: '88px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      gap: "10px",
      padding: '6px 9px',
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#E70E6B",
      textTransform: "capitalize"
    },
    userInformationHeading2: {
      font: "600 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationName: {
      font: "400 18px Poppins",
      color: "#1C1B1B",
      margin:"0px"
    },
    userInformationActiveUser: {
      font: "400 18px Poppins",
      color: "#1C1B1B",
      margin: "0px 8px 0px 0px"
    },
    userInformationsubName: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationBranchName: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B",
    },
    userInformationsubName2: {
      font: "normal 600 18px Poppins",
      color: "#1C1B1B",
      textAlign: "center"
    },
    userInformationDeleteDiv: {
      display: "flex",
      alignItems: "center",
      width: "160px",
      height: "44px",
      cursor: 'pointer !important'
    },
    userInformationDeleteText: {
      font: "normal 600 16px Poppins",
      color: "#652786",
      marginLeft: "14px",
      width: "100%"
    },
    makeInactiveDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "180px",
      height: "44px",
      cursor: 'pointer !important',
      border: "1px solid var(--sys-color-Secondary, #E47004)",
      borderRadius: "12px",
      padding: "16px 32px",
      marginLeft: "20px"
    },
    makeInactiveText: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#E47004"
    },
    userInformationHeading3: {
      font: "normal 500 22px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText: {
      font: "normal 600 16px Poppins",
      width: "183px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "#FFFFFF",
      border: "none",
      cursor: 'pointer !important',
      "@media (max-width: 820px)": {
        marginTop: "15px"
      },
    },
    userInformationHeading4: {
      font: "normal 500 22px Poppins",
      color: "#1C1B1B",
      display: "flex",
      alignItems: "center"
    },
    billingHeading: {
      font: "normal 600 22px Poppins",
      color: "#1C1B1B",
      display: "flex",
      alignItems: "center"
    },
    billingSubHeading: {
      font: "normal 400 16px Poppins",
      color: "#1C1B1B"
    },
    userInformationHeading5: {
      font: "normal 400 18px Poppins",
      color: "#1C1B1B"
    },
    userInformationAssignText2: {
      font: "normal 600 16px Poppins",
      color: "#FFFFFF",
    },
    assignContentInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      textAlign: "center",
      "@media (max-width: 820px)": {
        alignItems: "flex-start",
        textAlign: "left",
        flexWrap: "wrap"
      },
    },
    teamsDiv: {
      padding: "20px 0px 20px 0px",
      marginLeft: "20px"
    },
    billingDiv: {
      padding: "0px 0px 20px 0px",
      marginLeft: "20px"
    },
    billingNameStatusWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    teamsInnerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "97%",
      marginBottom: "7px"
    },
    teamsInnerDiv2: {
      display: "flex",
      justifyContent: "space-between",
      width: "97%",
      alignItems: "center",
      textAlign: "center"
    },
    assignContentBtnDiv: {
      border: "1px solid #E5E2E1",
      padding: "20px",
      borderRadius: "12px",
      width: "97%",
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "20px",
      background: "#FCF8F8"
    },
    assignContentBtnInnerDiv: {
      width: "182px",
      height: "42px",
      borderRadius: "12px",
      backgroundColor: "#652786",
      color: "white",
      gap: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: 'pointer !important'
    },
    assignContentImg: {
      height: "24px",
      width: "24px"
    },
    editIconImg: {
      height: "24px",
      width: "24px",
      marginLeft: "15px",
      cursor: "pointer"
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "0px 2px 0px 2px"
    },
    cancelButton: {
      width: "48%",
      height: "42px",
      fontWeight: 600,
      background: "white",
      fontSize: "16px",
      border: "1px solid #E47004",
      borderRadius: "12px",
      color: "#E47004",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    deleteButton: {
      width: "48%",
      border: "none",
      height: "42px",
      background: "#652786",
      borderRadius: "12px",
      fontWeight: 600,
      fontSize: "16px",
      color: "white",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    contractHeading: {
      width: "97%",
      padding: "20px 24px",
      boxShadow: "1px 2px 10px 0px #00000011",
      borderRadius: "18px"
    },
     contractListWrapper : {
      padding: "20px",
      backgroundColor: "#FCF8F8",
      border: "1px solid var(--sys-surface-DisabledContainer, #E5E2E1)",
      borderRadius: "18px",
      gap: "20px",
      display: "flex",
      flexDirection: "column"
     },
     addPDFText : {
      fontSize: "16px",
      fontWeight: 600,
      color: "#652786"
     },
     documentRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "18px 24px",
      borderBottom: "1px solid rgb(224, 224, 224)",
      boxShadow: "1px 2px 10px 0px #00000011",
      background: "var(--sys-color-OnPrimary, #FFFFFF)",
      borderRadius: "12px",
     },
     noDocumentFound: {
      color: "1C1B1B",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px"
     },
     addPaymentButtonDiv: {
      display: "flex",
      justifyContent: "end",
      marginTop: "10px",
      paddingRight:"20px"
    },
    addPaymentButton: {
      font: "normal 600 16px Poppins",
      width: "183px",
      border: "none",
      height: "42px",
      background: "#652786",
      borderRadius: "12px",
      color: "white",
      textTransform: "none",
      cursor: 'pointer !important'
    },
    addPaymentButtonDisable:{
      font: "normal 600 16px Poppins",
      width: "183px",
      border: "none",
      height: "42px",
      borderRadius: "12px",
      color: "white",
      textTransform: "none",
    },
    toggleBtnText: {
      font: "normal 600 18px Poppins",
      color: "#484646",
      '@media (max-width: 1024px)': {
        width: "100%",
        height:"auto"
      },
    },
    toggleBtnText2: {
      font: "normal 400 16px Poppins",
      color: "#1C1B1B"
    },
    toggleBtnTextOption: {
      font: "normal 600 18px Poppins",
      color: "#484646",
      '@media (max-width: 1024px)': {
        width: "100%",
        height:"auto"
      },
      '@media (max-width: 600px)': {
        margin:"10px 0" 
      },
    },
    switchBase: {
      color: "#545650",
      "&$disabled": {
        color: "#CCB7D7"
      },
      "&$checked": {
        color: "#6C328B"
      },
      "&$checked + $track": {
        background: "#c4aed0",
        mixBlendMode: "normal",
        opacity: "0.37"
      }
    },
    track: {
      background: "#c8cbd2",
      mixBlendMode: "normal",
      opacity: "0.37"
    },
    toggleSectionsDiv: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      height: "42px"
    },
    TieredSectionDiv: {
      display: "flex",
      alignItems: "center",
      width: "97%",
      height: "35px",
      justifyContent: "space-between",
      margin: "0px 12px"
    },
    perActiveUserDiv: {
      display: "flex",
      alignItems: "center",
      height: "35px",
      width: "100%",
      justifyContent: "space-between",
      margin: "0px 12px"
    },
    activeUsers: {
      margin: "4px 0",
      font: "normal 400 16px Poppins",
      color: "#484646"
    },
    BillingSectionInputs: {
      width: "291px",
      borderRadius: "8px",
      '@media (max-width: 1024px)': {
        width: "100%",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield", 
        "-webkit-appearance": "none",  
        margin: 0,
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",  
        margin: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none", 
      },
      "& .Mui-disabled": {
        backgroundColor: "#E5E2E1"
      }
    },
    flatFeeSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      '@media (max-width: 1024px)': {
        width: "100%",
        height:"auto"
      },
    },
    flatFeeSectionFiled:{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      '@media (max-width: 1024px)': {
        width: "100%",
        height:"auto !important" 
      },
      '@media (max-width: 600px)': {
        flexDirection:"column"
      },
    },
    payPerUserSectionFiled:{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "52px",
      '@media (max-width: 1024px)': {
        width: "100%",
        height:"auto !important" 
      },
      '@media (max-width: 600px)': {
        flexDirection:"column"
      },
    },
    InfoIcon1: {
      cursor: "pointer",
      height: "16px",
      width: "16px",
      marginLeft: "10px"
    },
    InfoIcon3: {
      cursor: "pointer",
      height: "16px",
      width: "16px",
      marginLeft: "10px"
    },
    InfoIcon2: {
      cursor: "pointer",
      height: "16px",
      width: "16px",
      marginLeft: "5px"
    },
    adminSideRightSideContainer: {
      marginTop: "20px",
      paddingRight:"15px",
      display: "flex",
      flexDirection: "column",
      width: "47%",
      '@media (max-width: 1024px)': {
        width: "100%",
      },
    },
    adminSideLeftSideContainer: {
      width: "53%",
      '@media (max-width: 1024px)': {
        width: "100%",
      },
    },
    flatFeeToggle: {
      display: "flex",
      flexDirection: "column"
    },
    EstimatedTotalText: {
      font: "normal 600 20px Poppins",
      color: "#1C1B1B"
    },
    adminsideLeftSideBorderContainer:{
      borderLeft: "1px solid #E5E2E1",
      padding: "0px 5px 0px 25px ",
      marginTop: "20px",
      '@media (max-width: 1024px)': {
        borderLeft: "none",
         padding: "0px",
      },
    },
    paymentPeriod:{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "none",
      '@media (max-width: 600px)': {
        flexDirection:"column"
      },
    },
    userDriven:{
      marginLeft: "6px",
      borderRight: "1px solid #929090",
      paddingRight: "15px" 
    },
    userRange:{
      margin: "4px 0",
      font: "normal 600 18px Poppins",
      color: "#484646",
      width:"60%" 
    },
    payPerPrice:{
      margin: "4px 0",
      font: "normal 600 18px Poppins",
      color: "#484646",
      width:"50%"
    },
    minText:{
      margin: "4px 0",
      font: "normal 400 16px Poppins",
      color: "#787776"
    },
    addTierDiv:{
      display:"flex",
      justifyContent:"end",
      width: "100px",
      margin: "20px 0 20px auto",
      cursor: "pointer"
    },
    disabledButton:{
      display:"flex",
      justifyContent:"end",
      width: "100px",
      margin: "20px 0 20px auto",
      cursor: "pointer"
    },
    addTierTitle:{
      font: "normal 600 16px Poppins",
      color: "#652786",
      marginLeft:"8px"
    },
    tierDeleteIcon:{
      width:"24px",
      height:"24px",
      display:"flex",
      alignSelf:"end",
      marginLeft:"15px",
      cursor: "pointer"
    },
    setPriceTitle:{
      margin: "4px 0",
      font: "normal 400 16px Poppins",
      color: "#1C1B1B" 
    },
    billingSection:{
      display: "flex",
      marginLeft: "23px",
      borderTop:"1px solid #E5E2E1",
      '@media (max-width: 1024px)': {
        flexDirection: "column"
      },
    },
    pricePerUser:{
      '@media (max-width: 1024px)': {
        width:"100%"
      },
    },
    maximumInput: {
      background: "#FCF8F8",
      borderRadius: "8px",
      "@media (max-width: 1024px)": {
        width: "100%",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield", 
        "-webkit-appearance": "none",  
        margin: 0,
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",  
        margin: 0,
      },
      "& .Mui-disabled": {
        backgroundColor: "#E5E2E1",
        boxSizing: "border-box"
      }
    },
    setPriceInput: {
      width:"259.5px",
      background: "#FCF8F8",
      borderRadius: "8px",
      "@media (max-width: 1300px)": {
        width: "100%",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield", 
        "-webkit-appearance": "none",  
        margin: 0,
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",  
        margin: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      "& .Mui-disabled": {
        backgroundColor: "#E5E2E1",
        boxSizing: "border-box"
      }
    },
    maxRangeDiv:{
      margin: "0 58px 0 10px",
      '@media (max-width: 650px)': {
        margin: "0 10px 0 10px",
      },
      '@media (max-width: 400px)': {
        margin: "0 0px 0 0px !important",
      },
    },
    minMaxRangeDiv:{
      display: "flex",
      margin:"5px 0px",
      '@media (max-width: 650px)': {
       flexWrap:"wrap"
      },
    },
    dollarIconStyle:{
      height:"15.28px",
      width:"8.33px"
    },
    monthlyPaymentInputs: {
      width: "291px",
      color: "#FCF8F8",
      borderRadius: "8px",
      '@media (max-width: 1024px)': {
        width: "100%",
      },
      "& .MuiSelect-iconOutlined": {
        right:"10px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-disabled": {
        backgroundColor: "#E5E2E1",
        color: "#787776",
        borderRadius: "8px"
      }
    },
    mininumInput: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
     contractWrapperName: {
      display: 'flex', 
      alignItems: 'center', 
      gap: 1, 
      width: "40%",
      '@media (max-width: 768px)': {
        width: "auto",
        paddingRight: "5px"
      },
     },
     wrapName: {
      wordBreak: "break-all"
     },
     minMaxContainer:{
     display:"flex",
     '@media (max-width: 400px)': {
      flexWrap:"wrap"
     },
    },
  });

const ModalMainBody = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});

const ModalContent = styled(Box)({
  position: "absolute",
  maxWidth: "525px",
  width: "100%",
  height: "auto",
  padding: "9px",
  backgroundColor: "#fff",
  display: "flex",
  alignContent: "center",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  paddingTop: "20px",
  paddingBottom: "25px",
  "@media (max-width: 656px)": {
    maxWidth: "400px",
  },
  "@media (max-width: 400px)": {
    maxWidth: "330px",
  }
});

const ModalText = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 600,
  fontSize: "22px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const ModalText1 = styled(Typography)({
  width: "100%",
  display: "flex",
  fontStyle: "Poppins",
  fontWeight: 400,
  fontSize: "16px",
  justifyContent: "left",
  padding: "5px 20px 5px 20px"
});

const IconAlign = styled(Box)({
  position: "absolute",
  top: "-10",
  right: "-10",
  backgroundColor: "#652786",
  borderRadius: "50%",
  color: "white",
  cursor: 'pointer !important'
});

const DropdownContent = styled(MaterialUIBox)({
  width: "348px",
  maxHeight: "204px",
  "& .checkboxList": {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "3px"
  },
  "& .checkboxContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid lightgray",
    fontFamily: "Poppins",
    textTransform: "capitalize"
  }, "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#484646"
  },
  "& .selectedStyle": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent !important",
  },
  "& .selectedStyle .MuiTypography-body1": {
    color: "#652786 !important"
  },
  "& .checkboxContent:hover": {
    backgroundColor: "#EEE8F2",
    borderBottom: "1px solid transparent",
  },
  "& .applyBtn": {
    display: "block",
    margin: "auto",
    color: "#542070",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  "& .MuiFormControlLabel-root": {
    margin: 0,
    borderBottom: "1px solid #E5E2E1",
    fontFamily: "Poppins"
  },
  "& .react-calendar": {
    border: "unset",
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .react-calendar abbr": {
    textDecoration: "none"
  },
  "& .react-calendar__month-view__days__day": {
    width: "31px",
    height: "31px",
    borderRadius: "50%",
    fontWeight: 400,
    fontSize: "14px !important"
  },
  "& .react-calendar__month-view__weekdays": {
    fontWeight: 400,
    fontSize: "10px !important"
  },
  "& .react-calendar__tile--now:enabled": {
    backgroundColor: "#652786",
    color: "#fff"
  },
  "& .react-calendar__tile--now:enabled:hover": {
    backgroundColor: "#652786"
  },
  "& .react-calendar__month-view__days__day--weekend": {
    color: "rgba(0, 0, 0, 0.87)"
  },
  "& .react-calendar__month-view__days__day--neighboringMonth": {
    color: "#757575"
  },
  "& .react-calendar__tile--active": {
    color: "#fff",
    backgroundColor: "#652786 !important"
  },
  "& .react-calendar__year-view__months__month": {
    width: "74px",
    height: "74px",
    borderRadius: "50%"
  },
  "& .react-calendar__navigation__prev2-button": {
    display: "none"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  "& .react-calendar__navigation button:enabled:hover": {
    backgroundColor: "unset"
  },
  "& .react-calendar__navigation button": {
    minWidth: "auto"
  },
  "& .calendarBtnContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px"
  }
});
const TextFieldSearch = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: '#1C1B1B',
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    backgroundColor: "#FFFFFF",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
    border: "none !important"
  },
  "& .MuiSvgIcon-root": {
    position: "relative",
    zIndex: 1,
    color: "gray"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    height: "56px",
    width: "100%",
    padding: "16px 20px",
    border: "1px solid #652786 !important"
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "6px"
  }
});
const CheckboxMain = styled(Checkbox)({
  color: '#652786 !important',
});

const SaveAndCreate = styled(Button)({
  width: "411px",
  height: "56px",
  padding: "20px 32px",
  "@media (max-width:960px)": {
    width: "240px",
    height: "56px",
    padding: "10px 21px",
  },
})

export default withStyles(styles)(BranchDetailsEdit);
// Customizable Area End