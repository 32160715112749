import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  handleClose: any;
  id: string;
  dataId: any;
  open: any;
  mainModalClose: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  allXpandLibararyData: any;
  themeDataDelete: any;
  grapValues: any;
  postXpandLibararyData: any;
  search: any;
  themeMode: boolean,
  themeModeDelete: boolean,
  Modalopen: boolean,
  dialogopen: boolean,
  createFolder: any,
  allVideoData: any,
  newId: any,
  rename: boolean,
  editRename: any,
  popupOpen: boolean,
  dataID: any,
  allSelected: boolean,
  selectedItems: any,
  check: any,
  expanded: any,
  showDeleteButtons: boolean,
  showLockButtons: boolean,
  open: boolean,
  open1: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageContentFolderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  PostCeateNewFolder: any;
  FolderLock: any;
  UpdateRename: any;
  GetVideoData: any;
  GetDeleteVideo: any;
  GetSearchData: any;
  GetdeleteFolder: any;
  GetHardDeleteVideo: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      allXpandLibararyData: {},
      themeDataDelete: {},
      postXpandLibararyData: {},
      search: "",
      themeMode: false,
      themeModeDelete: false,
      Modalopen: false,
      open: false,
      open1: false,
      dialogopen: false,
      rename: false,
      popupOpen: false,
      createFolder: '',
      editRename: '',
      newId: '',
      allVideoData: {},
      dataID: {},
      allSelected: false,
      selectedItems: [],
      check: '',
      expanded: '',
      showDeleteButtons: false,
      showLockButtons: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
    this.getXpandLibraryData();
    this.getVideoData();
    // console.log(this.props.history.push('/ManageContentLibrary'))
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getXpandLibraryData();
      this.getVideoData();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetXpandLibraryData) {
          this.setState({ allXpandLibararyData: responseJson }, () => {
          });
        }
        if (apiRequestCallId === this.GetVideoData) {
          this.setState({ allVideoData: responseJson })

        }
        if (apiRequestCallId === this.GetDeleteVideo) {
          this.getVideoData();
          this.getXpandLibraryData();
          this.handleCloseBulkdelete();
          this.cancelAction();
          this.setState({ allSelected: false })
        }

        if (apiRequestCallId == this.PostCeateNewFolder) {
          // this.getXpandLibraryData();
          // this.setState({ dialogopen: false })
          if (responseJson.folder_type === "content_library") {
            this.getXpandLibraryData();
            this.setState({ dialogopen: false });
            this.setState({ createFolder: "" })
            toast.success("Folder created successfully!");
          } else if (responseJson.errors && responseJson.errors.length > 0) {
            const errorMessages = responseJson.errors.map((error: { message: any; }) => error.message);
            toast.error(errorMessages[0]);
          } else {
            toast.success("Folder creation failed. Unknown error.");
          }
        }
        if (apiRequestCallId === this.FolderLock) {
          this.getXpandLibraryData();
          toast.success("Folder Lock Succesfully");
          this.getVideoData();
          this.setState({ Modalopen: false })
          this.setState({ rename: false })
        }
        if (apiRequestCallId === this.UpdateRename) {
          this.getXpandLibraryData();
          this.getVideoData();
          this.setState({ Modalopen: false })
          this.setState({ rename: false })
        }
        if (apiRequestCallId === this.GetSearchData) {
          this.setState({ allXpandLibararyData: responseJson });
          if (this.state.search === "") {
            this.getXpandLibraryData();
          } else if (responseJson.data.length === 0 && responseJson.videos.data.length === 0) {
            toast.error("No data available here")
          }
        }
        if (apiRequestCallId === this.GetdeleteFolder) {
          this.getXpandLibraryData();
          this.props.history.push('/ManageContentLibrary')
          // this.props.navigation.goBack()
          this.setState({ Modalopen: false })
        }
        if (apiRequestCallId === this.GetHardDeleteVideo) {
          this.getXpandLibraryData();
        }

      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  backToCurrentFolder = (id: number) => {
     this.props.history.push(`/ManageContentFolder/${id}`, { id: id });
  }

  tostSuccessfully = () => {
    toast.success("Coming soon");
    this.setState({ Modalopen: false })
    this.ModalhandleClose();
  }

  handleChangeExpanded = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  NavigatToMainPage = () => {
    {
      this.props.open === true ? "" :
      this.props.history.push('/ManageContentLibrary')
    }
  }

  showDeleteButtons = () => {
    this.setState({
      showDeleteButtons: true,
      showLockButtons: false,
      expanded: null,
    });
  };

  showLockButtons = () => {
    this.setState({
      showDeleteButtons: false,
      showLockButtons: true,
      expanded: null,
    });
  };

  cancelAction = () => {
    this.setState({
      showDeleteButtons: false,
      showLockButtons: false,
    });
  };


  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClosedelete = () => {
    this.setState({ open: false })
  }

  handleOpenBulkdelete = () => {
    this.setState({ open1: true })
  }

  handleCloseBulkdelete = () => {
    this.setState({ open1: false })
  }

  handleOpendelete = () => {
    this.setState({ open: true })
  }

  ModalhandleClose = () => {
    this.setState({ Modalopen: false })
  }

  ModalhandleOpen = (data: any) => {
    this.setState({ Modalopen: true })
    this.setState({ editRename: data })
  }

  dialogHandleClose = () => {
    this.setState({ dialogopen: false })
  }

  dialogHandleOpen = () => {
    this.setState({ dialogopen: true })
  }
  RenameHandleOpen = () => {
    this.setState({ rename: true })
    this.ModalhandleClose();
  }

  RenameHandleClose = () => {
    this.setState({ rename: false })
  }

  toggleItemSelect(itemId: any, index: any) {
    const { selectedItems } = this.state;
    const selectedIndex = selectedItems.indexOf(itemId);
    if (selectedIndex === -1) {
      // If item is not already selected, add it to the selected items array
      selectedItems.push(itemId);
    } else {
      // If item is already selected, remove it from the selected items array
      selectedItems.splice(selectedIndex, 1);
    }
    this.setState({ selectedItems });
  }

  toggleSelectAll() {
    const { allSelected } = this.state;
    if (allSelected) {
      // If "Select All" is currently checked, clear the selected items array
      this.setState({ selectedItems: [], allSelected: false });
    } else {
      // If "Select All" is not checked, select all items
      // You would typically get your list of items from props or state and extract their IDs
      // For this example, let's assume your items are in an array called "items"
      const allItemIds = this.state.allXpandLibararyData?.videos?.data?.map((item: any) => item.attributes.id);
      this.setState({ selectedItems: allItemIds, allSelected: true });
    }
  }

  selectData = (data: any) => {
    this.props.handleClose();
    this.props.mainModalClose(data);
    this.popupHandleClose();
    console.log(data, 'hhhhhhhhhhhhhh');
  }


  addNewVideo = () => {
    this.props.history.push(`/Addvideo/${this.props.navigation.getParam('id')}`, { id: this.props.history.location.state.id })
  }

  viewQuizBank = () => {
    this.props.history.push(`/create-quiz`, { id: this.props.history.location.state.id })
  }

  Undo_folder = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "ids": [id],
      "is_delete": false // pass is_delete true for soft delete , pass false for undo button
    }
    const httpBodyJSON = JSON.stringify(httpBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetDeleteVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/delete_videos`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodyJSON
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  delete_folder = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "ids": [id],
      "is_delete": true // pass is_delete true for soft delete , pass false for undo button
    }
    const httpBodyJSON = JSON.stringify(httpBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetDeleteVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/delete_videos`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodyJSON
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  BulkDelete_folder = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "ids": this.state.selectedItems,
      "is_delete": true // pass is_delete true for soft delete , pass false for undo button
    }
    const httpBodyJSON = JSON.stringify(httpBody);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetDeleteVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/delete_videos`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBodyJSON
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getVideoData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetVideoData = requestMessage.messageId;
    const folderId = this.props.history.location.state?.id || this.props.dataId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/?folder_id=${folderId}&folder_type=content_library&delete_list=${this.state.themeModeDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigate = (data: any) => {
    if (this.props.open === true) {
      this.popupHandleOpen();
      this.setState({ dataID: data })
    } else {
      this.props.history.push(`/ManageContentFolder/${data}`, { id: data });
      this.setState({ newId: data }, () => {
        this.getXpandLibraryData()
      })
    }
  };

  popupHandleOpen = () => {
    this.setState({ popupOpen: true })
  }

  popupHandleClose = () => {
    this.setState({ popupOpen: false })
  }

  viewDelete = () => {
    // Then toggle themeModeDelete and call getXpandLibraryData
    this.setState({ themeModeDelete: !this.state.themeModeDelete }, () =>
      this.getXpandLibraryData()
    );
  }

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    const folderId = this.props.history.location.state?.id || this.props.dataId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/?folder_id=${folderId}&folder_type=content_library&delete_list=${this.state.themeModeDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  postplayButton = async (id: any) => {
    this.props.history.push(`/course-detail/${id}`, { name: id })
  }


  createNewFolder = () => {

    let formdata = new FormData();
    formdata.append("name", this.state.createFolder);
    formdata.append("folder_type", 'content_library');
    formdata.append("parent_folder_id", this.props.history.location.state.id || this.props.dataId)

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCeateNewFolder = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchData = async (event: any) => {
    this.setState({ search: event.target.value })
    console.log(this.state.search);

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    const folderId = this.props.history.location.state?.id || this.props.dataId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder?folder_id=${folderId}&folder_type=content_library&search=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  DeleteFolderAPI = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetdeleteFolder = requestMessage.messageId;
    const folderId = this.props.history.location.state?.id || this.props.dataId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/${folderId}?hard_delete=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hardDeleteVideo = (Id: number) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetHardDeleteVideo = requestMessage.messageId;
    const VideoId = Id;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/${VideoId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  FolderRenameAPI = () => {
    let formdata = new FormData();
    formdata.append("name", this.state.editRename);


    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateRename = requestMessage.messageId;
    const folderId = this.props.history.location.state?.id || this.props.dataId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/${folderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Put'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  FolderLockAPI = () => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "folder_id": this.props.history.location.state.id || this.props.dataId
    };

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FolderLock = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/lock_folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}