import React from 'react'

const LinearProgress = (props: any) => {
    const { totalVideo, video } = props;

    let percent = video && totalVideo && video/totalVideo*100;
    let bg = percent > 80 ?'linear-gradient(90deg, rgba(122,48,139,1) 0%, rgba(215,35,121,1) 100%)' : 'linear-gradient(90deg, rgba(242,55,75,1) 0%, rgba(253,134,65,1) 100%)'
    const webStyle = {
        labelStyles : {
            padding: 5,
            color: '#fff',
            fontWeight: 'bold',
          },
          fillerStyles  :{
            height: '100%',
            width: `${percent}%`,
            background: `${bg}`,
            textAlign: 'right',
            borderRadius: 5,
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          },
          containerStyles : {
            height: 30,
            width: '97%',
            backgroundColor: "#d9d9d9",
            borderRadius: 10,
          }
    }
  
    return (
        <>
        {/*@ts-ignore*/}
      <div style={webStyle.containerStyles}>
        {/*@ts-ignore*/}
        <div style={webStyle.fillerStyles}>   
          {/*@ts-ignore*/}
        {video > 0 ?<span style={webStyle.labelStyles}>{`Video ${video}/${totalVideo}`}</span> : <></>}  
        </div>
      </div>
      </>
    );
  };
  
  export default LinearProgress;