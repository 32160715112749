// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { check, error} from "./assets";

import ShoppingController, {
  Props
} from "./ShoopingController.web";



export default class ShoppingCartModal extends ShoppingController {
  constructor(props: Props) {
    super(props);

  }
  
  
  render() {

  
    return (
      <Box style={{width:"40vw",marginTop:'15px',backgroundColor:"white",padding:'30px',borderRadius:"20px"}}>
      <Box style={{display:'flex',justifyContent:'space-between'}}>
       <Typography variant="h5" style={{fontWeight:"bold"}}></Typography>
       <Button style={{borderRadius:"50px",width:"50px"}} onClick={this.props.handleClose}><CloseIcon /></Button>
     </Box>
      {/* @ts-ignore */}
     <Box style={styles.cartInner}>
     { this.props.message == "Congratulations" ? <img src={check} alt=""/>: <img src={error} alt=""/>}
       
      {/* @ts-ignore */}
       <Typography variant="h5" style={styles.cartHeading}>{this.props.message}!</Typography>
       <Typography variant="h5" style={styles.cartPara}>{ this.props.message == "Congratulations" ? `Your order is successfully placed, Please go to the Personal
Library to view your courses.` : `Your order has failed for insufficient-funds. Please enter correct card details or check with your bank`}</Typography>
<Typography variant="h5" style={styles.cartGoHome} onClick={this.props.handleBackHome} >Go to Home Page</Typography>
      {/* @ts-ignore */}
      {this.props.message == "Congratulations" ? <Button style={styles.payNowBtn} variant="contained" onClick={this.props.handleBackPersonal}>Go to Personal Library</Button>: <Button style={styles.errorBtn} variant="contained" onClick={this.props.handleClose}>Try Again</Button>}
     </Box>
     </Box>
    );
  }
}

const styles = {
  cartInner:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:'50px'
  },
  cartHeading:{
    fontWeight:"bold",
    fontSize:'30px',
    marginTop:'30px',
    color:'#1E1F20',

  },
  cartPara:{
    fontSize:'22px',
    fontWeight: 500,
    color: '#787979',
    textAlign:'center' as const ,
    // fontFamily:'Avenir',
    marginTop:'15px',
  },
  cartGoHome:{
    color: '#1A1A1A',
    fontSize:'20px',
    fontWeight: 700,
    marginTop:'30px',
    cursor:'pointer'
  },
  payNowBtn:{
    width: '50%',
    height: '64px',
    borderRadius: '8px',
    backgroundColor: '#6c328b',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
    marginTop:'15px',
    boxShadow:'0px -10px 12px rgba(0, 0, 0, 0.159337)'
   },
   errorBtn:{
    width: '50%',
    height: '64px',
    borderRadius: '8px',
    backgroundColor: '#EF2B4B',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
    marginTop:'15px',
    boxShadow:'0px -10px 12px rgba(0, 0, 0, 0.159337)'
   },

}


// Customizable Area End
