// Customizable Area Start
import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NEW_COURSE_ASSIGNED, DUE_REMINDER } from "./reminderTypes";

interface IOutlinedBtn {
  onClick: () => void;
  isLoading: boolean;
}

interface IFilledBtn extends RouteComponentProps {
  type: string;
  url: string;
}

export function FilledButton({ type, url, history }: IFilledBtn) {
  const classes = useStyles();

  const handleClick = () => {
    history.push(url);
  };

  if (type === NEW_COURSE_ASSIGNED || type === DUE_REMINDER) {
    return (
      <Button
        className={`${classes.btn} ${classes.containBtn}`}
        variant="contained"
        fullWidth
        onClick={handleClick}
      >
        {type === DUE_REMINDER ? "continue learning" : "check it out now"}
      </Button>
    );
  }
  return null;
}

export const OrangeButton = withRouter(FilledButton);

export function OutlinedButton({ onClick, isLoading }: IOutlinedBtn) {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.btn} ${classes.outlineBtn}`}
      variant="outlined"
      fullWidth
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress size={20} className={classes.progress} />
      ) : (
        "Dismiss"
      )}
    </Button>
  );
}


const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      color: "#FF8B3F",
    },
    btn: {
      borderRadius: "16px",
      padding: "15px",
      textTransform: "capitalize",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
    },
    containBtn: {
      background: "#ff8b3f",
      color: "#FFFFFF",
      "&:hover": {
        background: "#ff8b3f",
      },
    },
    outlineBtn: {
      background: "#FFFFFF",
      color: "#FF8B3F",
      border: "1px solid #FF8B3F",
      marginTop: 15,
    },
  })
);
// Customizable Area End