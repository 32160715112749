import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import MessageEnum, {
    getName,
} from "./../../../framework/src/Messages/MessageEnum";
import { IBlock } from "./../../../framework/src/IBlock";
import { Message } from "./../../../framework/src/Message";
import { runEngine } from "./../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { toast } from "react-toastify";
import * as H from "history";
const navigation = require("react-navigation");

interface APIValidResponseType {
    data : Course[]
}
interface ThumbnailImage {
    id: number;
    url: string;
}

interface ContentType  {
    id: number;
    title: string;
    status: "active" | "inactive";
    thumbnail_image: ThumbnailImage;
    content_id: number;
    content_type: string;
    assign_type: string;
    assign_name: string | null;
    is_assign_by_branch: boolean;
}
  
interface CourseAttributes {
    id: number;
    content_type: string;
    content_id: number;
    content: ContentType;
}
  
interface Course {
    id: string;
    type: string;
    attributes: CourseAttributes
}

export interface Item {
    id: string; type: string; attributes: CourseAttributes
}

interface PropsData {
    createData: {
        ownerId: string;
        achievementName: string;
        status: string;
        dueDate: string;
        contentToggle: string;
        daysLoggedInToggle: string;
        daysLoggedIn: string;
        coursesCompletedToggle: string;
        coursesCompleted: string;
        lessonsCompletedToggle: string;
        lessonsCompleted: string;
        loggedInRowToggle: string;
        loggedInRow: string;
        personName: string;
        iconPreview: {
            attributes: {
                id: number;
                title: string;
                image: {
                    id: string;
                    url: string;
                    filename: string;
                }
            }
        },
        rewardFieldvalue: string;
        rewardId: string;
    }
}

// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    navigation: typeof navigation;
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    courseListData:  Course[];
    programListData: Course[];
    assignedData: Item[];
    selectedUnassigned: Array<string>;
    selectedAssigned: Array<string>;
    isCourseSelected: boolean;
    propsData : PropsData;
    achievementData: PropsData;
    achiName: string | null;
    isLeave: boolean;
    icon: string;
    isRewardValue: string | null;
    isPresent: string | null;
    idsToRemove: string[];
    dataToAdd : Item[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class SelectContentController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getCourseLsitApiCallId: string = "";
    getProgramLsitApiCallId: string = "";
    getCourseListWithRewardIDApiCallId: string = "";
    getProgramListWithRewardIDApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            courseListData: [],
            programListData: [],
            assignedData: [],
            selectedUnassigned: [],
            selectedAssigned: [],
            isCourseSelected: true,
            propsData: {
                createData: {
                    ownerId: "",
                    achievementName: "",
                    status: "",
                    dueDate: "",
                    contentToggle: "",
                    daysLoggedInToggle: "",
                    daysLoggedIn: "",
                    coursesCompletedToggle: "",
                    coursesCompleted: "",
                    lessonsCompletedToggle: "",
                    lessonsCompleted: "",
                    loggedInRowToggle: "",
                    loggedInRow: "",
                    personName: "",
                    iconPreview: {
                        attributes: {
                            id: 0,
                            title: "",
                            image: {
                                id: "",
                                url: "",
                                filename: ""
                            }
                        }
                    },
                    rewardFieldvalue: "",
                    rewardId: "",
                },
            },
            achievementData: {
                createData: {
                    ownerId: "",
                    achievementName: "",
                    status: "",
                    dueDate: "",
                    contentToggle: "",
                    daysLoggedInToggle: "",
                    daysLoggedIn: "",
                    coursesCompletedToggle: "",
                    coursesCompleted: "",
                    lessonsCompletedToggle: "",
                    lessonsCompleted: "",
                    loggedInRowToggle: "",
                    loggedInRow: "",
                    personName: "",
                    iconPreview: {
                        attributes: {
                            id: 0,
                            title: "",
                            image: {
                                id: "",
                                url: "",
                                filename: ""
                            }
                        }
                    },
                    rewardFieldvalue: "",
                    rewardId: "",
                },
            },
            achiName: "",
            isLeave: false,
            icon: "",
            isRewardValue: "",
            isPresent: "",
            idsToRemove: [],
            dataToAdd: [],
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getCourseListListApiCall("");
        this.getProgramistListApiCall("");
        const name = localStorage.getItem("achievement")
        this.setState({achiName: name});
        const isReward = localStorage.getItem("rewardName")
        this.setState({isRewardValue: isReward});
        const isPresent = localStorage.getItem("isReward")
        this.setState({isPresent: isPresent});
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson) {
                if (responseJson.data) {
                    this.apiSuccessCallBack(apiRequestCallId, responseJson);
                }
            }
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            let value = message.getData(getName(MessageEnum.NavigationPayLoadMessage1));
            this.setState({ propsData: data } , () => {
                this.setState({icon: data.createData?.iconPreview?.attributes?.image.url});
                this.getCourseListListApiCall("", data.createData.personName ?? "", data.createData.rewardId ?? "");
                this.getProgramistListApiCall("", data.createData.personName ?? "", data.createData.rewardId ?? "");
            });
            this.setState({achievementData: value})
            {
               data.assigned && this.setState({assignedData: data.assigned});
            }
        }
    };

    selectContentApiCall = async (valueData: {
        method?: string;
        endPoint?: string;
        body?: {};
        contentType?: string;
        type?: string;
    }) => {
        let token = localStorage.getItem("token");
        const { contentType, method, endPoint, body } = valueData;
        let header = {
            "Content-Type": contentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    apiSuccessCallBack = async (apiCallId: string, response: APIValidResponseType) => {
        if (apiCallId === this.getCourseLsitApiCallId) {
            this.setState({courseListData: response.data});
        }
        if (apiCallId === this.getProgramLsitApiCallId) {
            this.setState({programListData: response.data});
        }
    };

    getCourseListListApiCall = async (search: string, branchId:string ="", rewardId:string = "") => {
        let params = '';
        if (branchId) {
            params += `&user_id=${branchId}`;
        }
        if (rewardId) {
            const rewardParam = this.state.isPresent === 'false' ? `&achievement_id=${rewardId}` : `&reward_id=${rewardId}`
            this.getCourseLsitApiCallId = await this.selectContentApiCall({
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
                endPoint: `${configJSON.getCourseListEndpoint}?user_type=BxBlockOrganisationhierarchy::Branch${params}${rewardParam}&search=${search}`
            });
        } else {
            this.getCourseLsitApiCallId = await this.selectContentApiCall({
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
                endPoint: `${configJSON.getCourseListEndpoint}?user_type=BxBlockOrganisationhierarchy::Branch${params}&search=${search}`
            });
        }
    };

    getProgramistListApiCall = async (search: string, branchId:string ="", rewardId:string = "") => {
        let params = '';
        if (branchId) {
            params += `&user_id=${branchId}`;
        }
        if (rewardId) {
            const rewardParam = this.state.isPresent === 'false' ? `&achievement_id=${rewardId}` :`&reward_id=${rewardId}`
            this.getProgramLsitApiCallId = await this.selectContentApiCall({
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
                endPoint: `${configJSON.getProgramListEndPoint}?user_type=BxBlockOrganisationhierarchy::Branch${params}${rewardParam}&search=${search}`
            });
        } else {
            this.getProgramLsitApiCallId = await this.selectContentApiCall({
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
                endPoint: `${configJSON.getProgramListEndPoint}?user_type=BxBlockOrganisationhierarchy::Branch${params}&search=${search}`
            });
        }

    };

    handleToggle = (value: unknown) => {
        this.setState((prevState) => ({
            isCourseSelected: value === "course" ? true : false}));
    };

    handleSelect = (id: string, type: string) => {
        const selectedKey = type === 'unassigned' ? 'selectedUnassigned' : 'selectedAssigned';
        const selectedItems = [...this.state[selectedKey]];
        if (selectedItems.includes(id)) {
            this.setState({
                [selectedKey]: selectedItems.filter((itemId) => itemId !== id),
            } as unknown as Pick<State, keyof State>);
        } else {
            this.setState({
                [selectedKey]: [...selectedItems, id],
            }as unknown as Pick<State, keyof State>);
        }
    };

    handleAdd = () => {
        const { isCourseSelected, courseListData, programListData, selectedUnassigned } = this.state;
        const newAssignedCourses = courseListData.filter((item) => selectedUnassigned.includes(item.id));
        const newAssignedPrograms = programListData.filter((item) => selectedUnassigned.includes(item.id));
        const newAssigned = [...newAssignedCourses, ...newAssignedPrograms];
        this.setState((prevState) => {
            const updatedCourseListData = prevState.courseListData.filter((item) => !selectedUnassigned.includes(item.id));
            const updatedProgramListData = prevState.programListData.filter((item) => !selectedUnassigned.includes(item.id));
            return {
                assignedData: [...prevState.assignedData, ...newAssigned],
                selectedUnassigned: [],
                courseListData: isCourseSelected ? updatedCourseListData : prevState.courseListData,
                programListData: !isCourseSelected ? updatedProgramListData : prevState.programListData,
                dataToAdd: [...newAssigned]
            };
        });
    };

    handleRemove = () => {
        const { assignedData, selectedAssigned, isCourseSelected } = this.state;
        const removedItems = assignedData.filter((item) => selectedAssigned.includes(item.id));
        this.setState((prevState) => {
            const updatedUnassigned = isCourseSelected
                ? [...prevState.courseListData, ...removedItems]
                : [...prevState.programListData, ...removedItems];
            const updatedAssigned = assignedData.filter((item) => !selectedAssigned.includes(item.id));
            return {
                assignedData: updatedAssigned,
                selectedAssigned: [],
                courseListData: isCourseSelected ? updatedUnassigned : prevState.courseListData,
                programListData: !isCourseSelected ? updatedUnassigned : prevState.programListData,
                idsToRemove:selectedAssigned
            };
        });
        
        const unassignedData = assignedData.filter(
            (item) => selectedAssigned.includes(item.id)
        );
          
        const unassignedCourses = unassignedData.filter(
            (item) => item.attributes.content_type === "BxBlockCoursecreation::Course"
        ).length;
          
        const unassignedPrograms = unassignedData.filter(
            (item) => item.attributes.content_type === "BxBlockCoursecreation::Program"
        ).length;

        if (unassignedCourses > 0) {
            toast.success(`${unassignedCourses} Courses removed successfully`, { icon: false, closeButton: false, })
        }

        if (unassignedPrograms > 0) {
            toast.success(`${unassignedPrograms} Programs removed successfully`, { icon: false, closeButton: false, })
        }
    };

    handleGoBack = () => {
        if (this.state.propsData.createData.rewardId) {
            const propsData = {
                propsData: this.state.propsData,
                assignedData: JSON.stringify(this.state.assignedData),
                idsToRemove: this.state.idsToRemove,
                dataToAdd: this.state.dataToAdd,
            };
            
            localStorage.setItem('assignedData', JSON.stringify(propsData));
            this.setState({idsToRemove: []})
            if (this.state.isPresent == "false") {
                this.props.navigation.navigate('EditAchievement', {
                    achievementId: this.state.propsData.createData.rewardId,
                });
            } else {
                this.props.navigation.navigate('EditReward', {
                    rewardId: this.state.propsData.createData.rewardId,
                });
            }
        } else {
            const manageAccountNavigationId: Message = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            const raiseMessage = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { propsData: this.state.propsData, assignedData: JSON.stringify(this.state.assignedData) });
            {
                this.state.isPresent == "false" ?
                    manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "CreateAchievement")
                    :
                    manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "CreateReward")
            }
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(manageAccountNavigationId);
        }
    };

    handleLeaveClose = () => {
      this.setState({isLeave: false});
    };

    handleBackAchievement = () => {
        if (this.state.propsData.createData.rewardId) {
            const propsData = {
                propsData: this.state.propsData,
                assignedData: JSON.stringify(this.state.assignedData),
            };
            
            localStorage.setItem('assignedData', JSON.stringify(propsData));
            if (this.state.isPresent == "false") {
                this.props.navigation.navigate('EditAchievement', {
                    achievementId: this.state.propsData.createData.rewardId,
                });
            } else {
                this.props.navigation.navigate('EditReward', {
                    rewardId: this.state.propsData.createData.rewardId,
                });
            }

        } else {
            const manageAccountNavigationId: Message = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            const raiseMessage = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { propsData: this.state.propsData, assignedData: JSON.stringify(this.state.assignedData), achievement: this.state.achievementData });
            {
                this.state.isPresent == "false" ?
                    manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "CreateAchievement")
                    :
                    manageAccountNavigationId.addData(getName(MessageEnum.NavigationTargetMessage), "CreateReward")
            }
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
            manageAccountNavigationId.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(manageAccountNavigationId);
        }
    };

    handleBackCreate = () => {
      this.setState({isLeave: true});
    };
    // Customizable Area End
}