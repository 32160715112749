import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import WarningDialog from "../../../components/src/WarningDialog";
import DialogTitle from "./DialogTitle.web";
import Faqs from "./Faq.web";
import NotificationSettingsWeb from "./NotificationSettings.web";
import { Faq, NotificationSettings } from "./Settings5Controller.web";
import SettingsList from "./SettingsList.web";

interface Props extends RouteComponentProps {
  faqs: Faq[];
  open: boolean;
  isLoading: boolean;
  getFaqs: () => void;
  getNotificationSettings: () => void;
  resetState: () => void;
  handleSettingsClose: () => void;
  deactivateAccount: () => void;
  receivedNotificationSettings: boolean;
  notificationSettings: NotificationSettings;
  updateNotificationSettings: (key: string) => void;
  handleLogout: () => void;
  accountDeactivated: boolean;
}

function UserSettingsDialog({
  faqs,
  open,
  getFaqs,
  history,
  isLoading,
  handleSettingsClose,
  resetState,
  getNotificationSettings,
  receivedNotificationSettings,
  notificationSettings,
  updateNotificationSettings,
  deactivateAccount,
  handleLogout,
  accountDeactivated,
}: Props) {
  const classes = useStyles();
  const fullScreen = useMediaQuery("(max-width:600px)");
  const [curContent, setCurContent] = useState("settings-list");
  const [title, setTitle] = useState("Settings");
  const [showWarning, setShowWarning] = useState(false);
  const [warningTitle, setWarningTitle] = useState("");
  const [warningDescription, setWarningDescription] = useState("");

  const handleClose = () => {
    handleSettingsClose();
    resetState();
    setCurContent("settings-list");
  };

  const handleClickBack = () => {
    setTitle("Settings");
    setCurContent("settings-list");
    resetState();
  };

  const handleCurContent = (contentName: string) => {
    setCurContent(contentName);
  };

  // Handler for clicking the cancel button of the warning dialog
  const handleCancelClick = () => {
    setShowWarning(false);
    setCurContent("settings-list");
  };

  useEffect(() => {
    switch (curContent) {
      case "settings-list":
        setTitle("Settings");
        break;
      case "Contact Support":
        toast.success("Coming soon");
        break;
      case "About Xpand":
        toast.success("Coming soon");
        break;
      case "Notification Settings":
        getNotificationSettings();
        break;
      case "Terms of Use":
        history.push("/terms");
        break;
      case "Our Policies":
        history.push("/terms");
        break;
      case "FAQs":
        getFaqs();
        break;
      case "Deactivate Account":
        setShowWarning(true);
        setWarningTitle("Are you sure?");
        setWarningDescription(
          "You are about to deactivate your account. You will not be able to use this account any more."
        );
        break;
      case "Sign out":
        setShowWarning(true);
        setWarningTitle("Are you sure?");
        setWarningDescription(
          "You are about to sign out from this application."
        );
        break;

      default:
        break;
    }
  }, [curContent]);

  // Change title for FAQs
  useEffect(() => {
    if (faqs.length > 0) {
      setTitle("FAQs");
    }
  }, [faqs]);

  // Change title for notification settings
  useEffect(() => {
    if (receivedNotificationSettings) {
      setTitle("Notification Settings");
    }
  }, [receivedNotificationSettings]);

  useEffect(() => {
    if (accountDeactivated) {
      handleLogout();
    }
  }, [accountDeactivated]);

  let content;
  if (!isLoading && curContent === "FAQs" && faqs.length > 0) {
    content = <Faqs faqs={faqs} />;
  } else if (
    !isLoading &&
    curContent === "Notification Settings" &&
    receivedNotificationSettings
  ) {
    content = (
      <NotificationSettingsWeb
        settings={notificationSettings}
        updateSettings={updateNotificationSettings}
      />
    );
  } else {
    content = <SettingsList handleCurContent={handleCurContent} />;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="settings"
        open={open}
        fullScreen={fullScreen}
        className={classes.dialog}
        maxWidth="md"
      >
        <DialogTitle
          id="settings"
          onClose={handleClose}
          handleClickBack={handleClickBack}
        >
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box style={{ opacity: isLoading ? "0.7" : "1" }}>
            {isLoading && (
              <Box className={classes.progressContainer}>
                <CircularProgress />
              </Box>
            )}
            {content}
          </Box>
        </DialogContent>
      </Dialog>
      <WarningDialog
        open={showWarning}
        onCancelClick={handleCancelClick}
        onOkClick={curContent === "Sign out" ? handleLogout : deactivateAccount}
        title={warningTitle}
        description={warningDescription}
      />
    </>
  );
}

export default withRouter(UserSettingsDialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
        [theme.breakpoints.up("sm")]: {
          borderRadius: "24px",
        },
      },
    },
    content: {
      padding: "16px 10px",
      [theme.breakpoints.up("sm")]: {
        padding: "16px 45px",
      },
    },
    actions: {
      margin: 0,
      padding: theme.spacing(1),
    },
    progressContainer: {
      position: "absolute",
      inset: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
