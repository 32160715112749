import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(253 253 253 / 40%)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  circularProgress: {
    color: "#6C328B",
  },
}));

export default function Loader({ loading }: Props) {
  const classes = useStyles();
  if (!loading) return null;
  return (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <CircularProgress className={classes.circularProgress} />
      </div>
    </div>
  );
}