import React, { useRef } from "react";
import {
    Box,
    Button, IconButton,
    Paper, Typography
} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';

const play1 = require("./assets/Object.png")

interface Props {
    style: any;
    tostSuccessfully: () => void;
    handleNavigate: (type: string, id: any, pathname: string, state: any) => void;
    last_trackVideo: (data: number) => void;
    CorporateData: any;
}

export default function CorporateSlider({ tostSuccessfully, CorporateData, handleNavigate, last_trackVideo }: Props) {
    let slider = React.createRef<any>();

    const HandleChange = (data: any) => {
        if (data?.attributes?.collection?.attributes?.expand_type === "Course") {
            handleNavigate("Course", data.id, `/course-detail/${data?.attributes?.collection?.attributes?.expand_type}/${data?.attributes?.collection?.id}/${data?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: data?.attributes?.collection })
        } else if (data?.attributes?.collection?.attributes?.expand_type === "Program") {
            handleNavigate("Program", data.id, `/program-detail/${data?.attributes?.collection?.attributes?.expand_type}/${data?.attributes?.collection?.id}/${data?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: data?.attributes?.collection })
        }
    }

    const next = () => {
        // @ts-ignore
        slider.slickNext();
    }
    const previous = () => {
        // @ts-ignore
        slider.slickPrev();
    }
    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <>
            {CorporateData?.corporate_courses?.data?.length !== 0 ?
                <Slider {...settings}
                    ref={(c: any) => (
                        // @ts-ignore
                        slider = c
                    )}

                    className="slider1"
                >
                    {CorporateData?.corporate_courses?.data?.map((data: any, index: any) => (
                        <Box key={index}>
                            <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                                <CardMedia
                                    style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                                    image={data.attributes.collection.attributes.thumbnail_image.url}
                                />
                                <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '172px' }}>
                                    <Typography style={{ cursor: 'pointer', margin: 'auto', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}
                                        onClick={() => HandleChange(data)}>
                                        {data.attributes.collection.attributes.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p"
                                        style={{ margin: 'auto', marginTop: '-15px', marginBottom: '3px', padding: '0 17px', color: 'black' }}>
                                        {data.attributes.collection.attributes.created_by}
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p"
                                        style={{
                                            margin: 'auto', fontSize: '12px', marginBottom: '5px', padding: '0 17px',
                                            color: '#44444F'
                                        }}>
                                        Due in {data.attributes.due_in_days} Days
                                    </Typography>

                                    <Card style={{
                                        width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                                        marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                                    }}>
                                        <CardActionArea onClick={() => last_trackVideo(data)}>
                                            <img src={play1} style={{ margin: 'auto', padding: '8px', display: 'flex' }} />
                                        </CardActionArea>
                                    </Card>
                                </Paper>
                            </Box>
                        </Box>
                    ))}
                </Slider>
                :
                <><h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Course available</h2></>}
            <Box>
                <IconButton aria-label="delete" onClick={next} style={{ position: 'absolute', right: 0, top: '-45%', background: 'black' }}>
                    <NavigateNextOutlinedIcon style={{ color: '#fff' }} />
                </IconButton>
            </Box>
        </>
    )
}

const styles = {
    IconButton: {
        position: 'absolute' as 'absolute',
        right: 0,
        top: '45%',
        background: 'black'
    },
    course_box: {
        height: '500px',
        maxWidth: '83vw',
        overflow: 'hidden',
        flex: 1
    },
    course_h3: {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontWeight: 700,
    },
    NavigateNextOutlinedIcon: {
        color: '#fff'
    },
    img_play: {
        margin: 'auto',
        padding: '8px',
        display: 'flex'
    },
    img_card: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'right' as 'right',
        marginTop: '-2px',
        marginRight: '25px',
        background: '#6C328B'
    },
    group_img: {
        margin: 'auto',
        padding: '14px',
        display: 'flex'
    },
    group_cardImg: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'left' as 'left',
        marginTop: '-2px',
        marginLeft: '25px',
        background: '#EF2B4B'
    },
    shop_img: {
        margin: 'auto',
        padding: '27px',
        display: 'flex',
        marginTop: '-21px',
        width: '100%',
        height: 'auto'
    },
    card_shop: {
        width: '87px',
        height: '45px',
        borderRadius: '30px',
        float: 'right' as 'right',
        marginTop: '-2px',
        marginRight: '20px',
        background: '#1D1F4F'
    },
    typo_due: {
        margin: 'auto',
        fontSize: '12px',
        marginBottom: '10px',
        padding: '0 17px',
        color: '#44444F'
    },
    typo_create: {
        margin: 'auto',
        marginTop: '-15px',
        padding: '0 17px',
        color: 'black'
    },
    typo_title: {
        width: '100%',
        minHeight: 65,
        marginBottom: '-10px',
        padding: '17px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        cursor: 'pointer'
    },
    paper_data: {
        width: '100%',
        borderRadius: '25px',
        marginTop: '-20px',
        height: '168px'
    },
    img_media: {
        height: "240px",
        width: '100%',
        borderRadius: '25px 25px 0 0'
    },
    box_data: {
        padding: "0 10px 0 10px",
        borderRadius: '25px 25px 0 0',
        marginTop: '20px'
    },
    main_cardBox: {
        position: 'relative' as 'relative'
    },
}