import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import debounce from "lodash.debounce";
import { ContentRolesType } from "./ContentRoleController.web";
import { toast } from "react-toastify";
interface ThumbnailType {
  id: string,
  type: string,
  attributes: {
    title: string,
    created_at: string,
    image: {
      id: string,
      url: string,
      filename: string
    },
    created_by: string
  }
}
export interface ExpertListing {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name: string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}
export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}
export interface CheckboxSelectedListTypes { value: string, id: string }
interface ThumbnailResponse {
  data: [ThumbnailType]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open?: boolean;
  onClose?: any;
  CloseModal?: any;

  displayAddContentRoleDialogue: boolean;
  toggleAddContentDialogue: Function;
  handleSelectedContentIds: Function;
  previousSelectedContentIds: { id: number, name: string }[];
  screen: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  selectedContentIds: number[];
  contentRoleList: ContentRolesType[];
  anchorFilterContentEl: null | HTMLElement;
  anchorEl: null | HTMLElement;
  dropdownType: string ;
  isCalendarOpen: boolean;

  selectedStatus: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  creationDate:  string;

  searchInputValue: string;
  showList: boolean;
  filteredList: string[];
  isInitialContentListResponse: boolean;
  initialContentRoleList: ContentRolesType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommonAddContentRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContentRoleListDataCallID: string = ""
  getAdvanceSearchListDataCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      selectedContentIds: [],
      contentRoleList: [],
      anchorFilterContentEl: null,
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      selectedStatus: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      creationDate: "",
      showList: false,
      searchInputValue: '',
      filteredList: [],
      isInitialContentListResponse: true,
      initialContentRoleList: []
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValue + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId === this.getContentRoleListDataCallID) {
      this.getContentListResponse(responseJson)

      this.parseApiCatchErrorResponse(errorResponse);
      this.setState({ isLoading: false })
    } else if(apiRequestCallId === this.getAdvanceSearchListDataCallID) {
      this.getAdvanceSearchResponse(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {
    this.setState({ isLoading: true })
    super.componentDidMount();
    this.getContentRoleList();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.displayAddContentRoleDialogue !== this.props.displayAddContentRoleDialogue) {
      // when user again open add content role dialogue clear search and filters
      this.setState({
        searchInputValue: ""
      }, () => this.handleClearFilter());
    }
  }

  getContentListResponse = (responseJson: { data: ContentRolesType[] }) => {
    this.setState({ contentRoleList: responseJson.data }, () => {
      if(this.state.isInitialContentListResponse) {
        this.setState({ initialContentRoleList: responseJson.data, isInitialContentListResponse: false });
      }
    })
  }

  getAdvanceSearchResponse = (responseJson: {data : string[]}) => {
    this.setState({
      filteredList: responseJson?.data?.length ? responseJson.data : []
    }, () => {
      if(!responseJson.data?.length) {
       this.getContentRoleList()
      }
    })
  }

  RowClick = (contentId: number) => {
    let selectedIds = [];
    const isContentIdAlreadyPresent = this.state.selectedContentIds.includes(contentId);

    if (isContentIdAlreadyPresent) {
      selectedIds = this.state.selectedContentIds.filter((selectedContentId => selectedContentId !== contentId));
    } else {
      selectedIds = [...this.state.selectedContentIds, contentId]
    }
    this.setState({ selectedContentIds: [...selectedIds] })
  }

  constructEndPoint() {
    let endPoint = "";

    if (this.state.searchInputValue) {
      endPoint += `&search=${this.state.searchInputValue}`;
    }
    endPoint += this.addStatusParam();
    endPoint += this.addCreationDateParam();
    endPoint += this.addCreationDateRangeParam();

    return endPoint;
  }

  private addStatusParam(): string {
    return this.state.selectedStatus ? `&q[status]=${this.state.selectedStatus}` : '';
  }

  addCreationDateParam = (): string  => {
    const dateParam = this.getDateParam();
    return dateParam ? `&q[created_at]=${dateParam}` : ''
  }

  addCreationDateRangeParam = (): string  => {
    const { start_date, end_date } = this.state.creationDateParams;
    if (start_date && end_date) {
      const rangeObj = {
        start_date: start_date,
        end_date: end_date
      };
      const queryString = encodeURIComponent(JSON.stringify(rangeObj));
      return `&q[custom_range]=${queryString}`;
    }
    return '';
  }
  

  getDateParam = (): string  => {
    switch (this.state.creationDate) {
      case "Last Week":
        return "last_week";
      case "Last Month":
        return "last_month";
      case "Last Year":
        return "last_year";
      default:
        return '';
    }
  }

  getContentRoleList = async (sortingField?: string, sortOrder?: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleListDataCallID = requestMessages.messageId
    const field = sortingField ? `&sort=${sortingField}` : "";
    const order = sortOrder ? `&sort_type=${sortOrder}` : "";
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContentRoleListApiEndPoint}?${this.constructEndPoint()}${field}${order}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }
  formatDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${year}`;
  };
  sortContentList = (dataType: string, keyType: string, sortType: string) => {
    this.getContentRoleList(keyType, sortType)
  };

  handleSelectctedContentIds = () => {
    const selectedContentRole = this.state.initialContentRoleList.filter((contentRole) => this.state.selectedContentIds.includes(contentRole.attributes.id))
      .map(result => ({
        id: result.attributes.id,
        name: result.attributes.title
      }));
    if(selectedContentRole.length){
      toast.success(`${this.props.screen} added to Content Roles`);
    }
    this.props.handleSelectedContentIds(selectedContentRole)
  }

  handleCancel = () => {
    const previousSelectedContentIds = this.props.previousSelectedContentIds.map((content) => content.id)
    this.setState({ selectedContentIds: previousSelectedContentIds })
    this.props.toggleAddContentDialogue();
  }

  filterDeletedContentId = (deleteContentId: number) => {
    const contentIds = this.state.selectedContentIds.filter(selectedContentId => selectedContentId !== deleteContentId);
    this.setState({ selectedContentIds: contentIds })
  }

  preSelectedContentIdEditFlow = (selectedIds: {id: number , name: string}[]) => {
    const selecetdIdWhileEdit = selectedIds.map(selectedId => selectedId.id);
    this.setState({ selectedContentIds: selecetdIdWhileEdit })
  }

  handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      // filterItemsData: !this.state.filterItemsData,
      anchorFilterContentEl: event.currentTarget,
    })
  };
  filterContainerDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContentEl: null
    })
  }

  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false
    })
  }

  handleCatalogueChangeStatus = (status: string) => {
    this.setState({
      selectedStatus: status,
      anchorEl: null,
      dropdownType: ""
    })
  }

  handleOpenCalendor = () => {
    this.setState({
      isCalendarOpen: true
    })
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creationDate: event.target.value
    })
  }

  handleChangeCalendor = (value: CalendarValue) => {
    const calanderStartDate = new Date(value[0]);
    const calanderEndDate = new Date(value[1]);
    const formattedStartDate = calanderStartDate.getDate();
    const formattedStartDateNum = calanderStartDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedStartMonthNum = calanderStartDate.toLocaleDateString("en-US", { month: "2-digit" });
    const formattedStartYear = calanderStartDate.getFullYear();
    const formattedEndDate = calanderEndDate.getDate();
    const formattedEndDateNum = calanderEndDate.toLocaleDateString("en-US", { day: "2-digit" });
    const formattedEndMonth = calanderEndDate.toLocaleDateString("en-US", { month: "long" });
    const formattedEndYear = calanderEndDate.getFullYear();
    const formattedEndMonthNum = calanderEndDate.toLocaleDateString("en-US", { month: "2-digit" });
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      creationDate: dateRange,
      creationDateParams: {
        start_date,
        end_date
      }
    });
  }

  handleClickFilter = () => {
    this.getContentRoleList();
    this.setState({
      anchorFilterContentEl: null,
    })
  }

  handleClearFilter = () => {
    this.setState({
      anchorFilterContentEl: null,
      selectedStatus: "",
      creationDate: "",
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      creationDateParams: {
        start_date: "",
        end_date: ""
      }
    }, () => {
      this.getContentRoleList()
    })
  }

  // search functionality
  handleInputChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.setState({
      searchInputValue: value,
      showList: value !== "",
    }, () => {
      this.advanceSearchList(value)
    });
  }

  advanceSearchList = (value: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getAdvanceSearchListDataCallID = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.catagoryAdvanceSearch + `?search=${value}&type=content_role`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;

  }

  handleListItemClickSuggestion = (item: string) => {
    this.setState({
      searchInputValue: item,
      showList: false
    }, () => {
      this.getContentRoleList()
    })
  }

  // Customizable Area End
}
