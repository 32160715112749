import React from 'react'
import {Line} from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const LineChartAdmin1 =({data}:{data:any})=>{
    console.log(data,'datatatatatatata')
      const data1 = {
        responsive: true,
        labels: data?.data?.this_week_data?.map((data:any)=>{
              return data.track_date
               }),
        datasets: [
    
          {
            id: 1,
            label: '',
            data: data?.data?.this_week_data?.map((data:any)=>{
                      return data.count
                       }),
            backgroundColor: 'transparent',
            borderColor: '#6C5DD3',
            pointBorderColor: 'transparent',
            pointBorderWidth: 4,
            tension: 0.5,
          },
          {
            id: 1,
            label: '',
            data: data?.data?.last_time_data?.map((data:any)=>{
                      return data.count
                       }),
            backgroundColor: 'transparent',
            borderColor: 'rgb(143 138 138)',
            pointBorderColor: 'transparent',
            pointBorderWidth: 4,
            tension: 0.5,
          },
    
        ]
      }
    
      const options: any = {
        plugins: {
          legend: false
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            min: 0,
            max: 25,
            ticks: {
              stepSize: 5,
              callback: (value: any) => value
            },
          }
        },
      };  
      return (
        <>
          <div style={{ width: '385px', height: '200px', marginTop: '20px' }}>
            <Line width="385px" height="200px" data={data1} options={options}></Line>
          </div>
        </>
      )
    
}


export default LineChartAdmin1
