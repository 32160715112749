// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";
import { getStorageData } from "framework/src/Utilities";

const configJSON = require("./config.js");

export interface CheckboxSelectedListType { id: number, value: string, _destroy:boolean }

interface UserTeams {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    is_selected: boolean,
  };
}

export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  email: string | null;
  phone_number: number;
  country_code: number;
  prefix_title: string | null;
  teams: AddToTeams[];
}

export interface AddToTeams {
  id: number,
  team_id: number,
  team_name: string,
  _destroy: boolean
}


export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  isLoading: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: number;
  countryCode: number;
  UserData: Array<UserResponse>;
  openUserDeleteDialog: boolean;
  status: string,
  dropdownAddTeams: boolean
  teamsSelectedItems: Array<CheckboxSelectedListType>;
  teamsListing: Array<UserTeams>;
  placeholderSearchTeamsText: string;
  anchorEl: HTMLElement | null;
  teamParams: string;
  userId: number;
  team_id: number;
  teams: Array<UserResponse>;
  teamsToDelete: Array<CheckboxSelectedListType>;
  resendInvitePopup:boolean;
  selectedCheck:string;
  copyValue:string;
  copiedAlert:boolean;
  copiedMessage:string;
}

interface SS {
  id: any;
}

export default class UserManageController extends BlockComponent<
  Props,
  S,
  SS
> {
  handleSetSubmittingCallback: Function = () => { };
  userListApiCallId: string = "";
  deleteUserDataApiCallId: string = "";
  getTeamListApiCallId: string = "";
  updateTeamsToUserApiCallId: string = "";
  activateLinkId:string = "";
  resendInviteMailId:string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;

  private childRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      email: "",
      phoneNumber: 0,
      countryCode: 0,
      UserData: [],
      openUserDeleteDialog: false,
      status: "",
      dropdownAddTeams: false,
      teamsSelectedItems: [],
      teamsListing: [],
      placeholderSearchTeamsText: "",
      anchorEl: null,
      teamParams: "",
      userId: 0,
      team_id: 0,
      teams: [],
      teamsToDelete: [],
      resendInvitePopup:false,
      selectedCheck:'email',
      copyValue:'',
      copiedAlert:false,
      copiedMessage:''
    };
    this.dropdownRef = React.createRef();
    this.childRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    let userDataId = this.props?.navigation?.getParam('id')
    this.setState({ teamsSelectedItems: [] });
    this.getUserList(Number(userDataId));
    this.getTeamsListings();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.deleteUserDataApiCallId) {
        toast.success("Account deleted successfully");
        this.props.navigation.navigate("UserManagement");
      }
      if (apiRequestCallId === this.updateTeamsToUserApiCallId) {
        this.updateTeamsToUserResponse(from, message, responseJson)
      }
      if (apiRequestCallId === this.userListApiCallId) {
        const phoneNumber = responseJson.data.attributes.phone_number;
        const teamsSelectedItemsData = responseJson.data.attributes.teams.map((team: any) => ({
          id: Number(team.id),       
          value: team.team_name  
        }));
        const uniqueTeams: CheckboxSelectedListType[] = Array.from(new Set(teamsSelectedItemsData.map((team: CheckboxSelectedListType) => team.id)))
        .map(id => teamsSelectedItemsData.find((team: CheckboxSelectedListType) => team.id === id)!);
        this.setState({
          UserData: responseJson.data,
          userId: responseJson.data.attributes.id,
          middleName: responseJson.data.attributes.middle_name,
          firstName: responseJson.data.attributes.first_name,
          title: responseJson.data.attributes.prefix_title,
          lastName: responseJson.data.attributes.last_name,
          countryCode: responseJson.data.attributes.country_code,
          email: responseJson.data.attributes.email,
          phoneNumber: phoneNumber,
          status: responseJson.data.attributes.status,
          isLoading: false,
          teamsSelectedItems: teamsSelectedItemsData,
          teamsToDelete: uniqueTeams
        })
      } else if (this.getTeamListApiCallId === apiRequestCallId) {
        this.setState({
          teamsListing: responseJson.data
        })
      }
      if(apiRequestCallId === this.activateLinkId){
        this.getActiveLinkResponse(responseJson)
      }
      if(apiRequestCallId === this.resendInviteMailId){
        this.getResendInviteResponse(responseJson)
      }
    }
  }

  formatPhoneNumber = (phoneNumber: number) => {
    const cleaned = phoneNumber.toString().replace(/\D/g, ''); 
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); 
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`; 
    }
    return phoneNumber.toString(); 
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.dropdownHandlerClose();
    }
  };

  updateTeamsToUserResponse = (from: string, message: Message, responseJson: any) => {
    if (responseJson?.data) {
      toast.success(`Teams update successfully`);
      this.props.navigation.navigate("UserManagement");
    } else {
      responseJson?.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
  };

  handleEditUsersData = (id: number) =>{
    this.props.navigation.navigate("EditUser", {id: id});
  }

  handleAssignContent = (id: number) =>{
    this.props.navigation.navigate("UserAssignContent", {id: id});
    this.getUserList(id);
  }

  getUserList = async (userId: number) => {
    this.setState({ isLoading: true });
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}/${userId}`,
    });
  };

  cancelLessonDeleteDialog = () => {
    this.setState({ openUserDeleteDialog: !this.state.openUserDeleteDialog })
  }

  handleDeleteUserData = () => {
    this.deleteUserValue();
  }

  deleteUserValue() {
    let userData = this.props?.navigation?.getParam('id')
    this.deleteUserData({
      content_Types: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      end_Point: `${configJSON.userListEndPoint}/${userData}`,
    });
  }

  deleteUserData(data: {
    content_Types?: string;
    end_Point?: string;
    method?: string;
    body?: {};
    type?: string;
  }) {
    const { content_Types, method, end_Point } = data;
    const header = {
      "Content-Type": content_Types,
      token: localStorage.getItem("token"),
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.deleteUserDataApiCallId = requestMessages.messageId
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      end_Point
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownAddTeams: !prevState.dropdownAddTeams
    }));
  }

  handleTeamsSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      placeholderSearchTeamsText: event.target.value,
    })
  }

  tagsCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, teamId: number) => {
    const { checked, name } = event.target;
    const parsedTeamId = Number(teamId);
    if (checked) {
      const isAlreadySelected = this.state.teamsSelectedItems.find(team => team.id === parsedTeamId);
      if (!isAlreadySelected) {
        this.setState(prevState => ({
          teamsSelectedItems: [...prevState.teamsSelectedItems, { id: parsedTeamId, value: name, _destroy: false }]
        }));
      }
    } else {
      this.setState(prevState => ({
        teamsSelectedItems: prevState.teamsSelectedItems.filter(team => team.id !== parsedTeamId)
      }));
    }
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownAddTeams: false,
    })
  }

  getTeamsListings = () => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTeamListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamListingApiEndPoint}?search=${this.state.placeholderSearchTeamsText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleClearTeamNames = (team: CheckboxSelectedListType) => {
    const numericTeamId = Number(team.id);
    this.setState(prevState => ({
      teamsSelectedItems: prevState.teamsSelectedItems.filter(t => t.id !== numericTeamId),
    }));
  };

  UpdateData = ( teamsAccountsAttributes: any[]) => {
    const selectedTeamIds = this.state.teamsSelectedItems.map((team: any) => team.id);
    const teamsToDeleteIds = this.state.teamsToDelete.map((team: any) => team.id
    );
    const teamsToRemove = teamsToDeleteIds.filter((id: number) => !selectedTeamIds.includes(id));
    const teamsToAdd = selectedTeamIds.filter((id: number) => !teamsToDeleteIds.includes(id));
    if (teamsToRemove.length) {
      teamsToRemove.forEach((id: number) => {
        teamsAccountsAttributes.push({
          id: id,        
          _destroy: true,
        });
      });
    }
    if (teamsToAdd.length) {
      teamsToAdd.forEach((team_id: number) => {
        teamsAccountsAttributes.push({
          team_id: team_id, 
          _destroy: false,
        });
      });
    }
    return teamsAccountsAttributes;
  };
  
  updateTeamsToUser = async (values: any) => {
    const teamsAccountsAttributes: any[] = [];
    this.UpdateData(teamsAccountsAttributes);
    let userData = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    const userApiData = {
      "account": {
        "teams_accounts_attributes": teamsAccountsAttributes
      }
    };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateTeamsToUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userApiData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.createUserEndPoint}/${userData}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  handleResendInvite = () => {
    this.setState({
      resendInvitePopup:true
    })
  }
  handleCloseResendInvite = () => {
    this.setState({
      resendInvitePopup:false
    })
  }
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selectedCheck:event.target.value
    })
    if(event.target.value === "copy"){
      this.callActiveLinkApi()
    }
  }
  callActiveLinkApi = () => {
    let userId = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.activateLinkId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateLinkAPIEndPoint + userId + configJSON.activeLinkRemainingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getActiveLinkResponse = (response:{invitation_link:string}) => {
    if(response.invitation_link){
      this.setState({
        copyValue:response.invitation_link
      })
    }
    
  }

  handleCopyClick = async () => {
    try {
        await navigator.clipboard.writeText(this.state.copyValue);
        this.setState({ copiedAlert: true,copiedMessage:'Link copied' });
    } catch (error) {
      this.setState({ copiedAlert: true,copiedMessage:'Failed to copy!' });
    }
  };

  handleSendLink = () => {
    let adminId = this.props?.navigation?.getParam('id')
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendInviteMailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateLinkAPIEndPoint + adminId + configJSON.resendInviteAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getResendInviteResponse = (response:{message:string}) => {
    if (response.message) {
      toast.success(`Invite resent`);
      this.handleCloseResendInvite()
    } else {
      toast.success(`Invite not resent`);
    }
  }

}
// Customizable Area End