import React from "react";
import { Typography, Box } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { RightArrow, UpgradeBanner } from "./assets";

const list = [
  "Notification Settings",
  "Contact Support",
  "About Xpand",
  "FAQs",
  "Our Policies",
  "Terms of Use",
  "Sign out",
  "Deactivate Account",
];

interface Props {
  handleCurContent: (item: string) => void;
}

export default function SettingsList({ handleCurContent }: Props) {
  const classes = useStyles();

  return (
    <>
      {list.map((item: string, index: number) => (
        <Box
          tabIndex={0}
          className={classes.listItem}
          key={index}
          onClick={() => handleCurContent(item)}
        >
          <Typography component="p" className={classes.listItemText}>
            {item}
          </Typography>
          <img src={RightArrow} alt={item} />
        </Box>
      ))}
      <Box
        tabIndex={0}
        className={`${classes.listItem} ${classes.upgradeBannerContainer}`}
        onClick={() => toast.success("Coming soon")}
      >
        <img
          src={UpgradeBanner}
          alt="Upgrade banner"
          className={classes.upgradeBanner}
        />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F3F3F3",
      padding: "12px 10px",
      marginBottom: "25px",
      borderRadius: "8px",
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        width: "430px",
        padding: "12px 20px",
      },
      [theme.breakpoints.up("md")]: {
        width: "570px",
      },
    },
    listItemText: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#11142D",
      lineHeight: "20px",
    },
    upgradeBannerContainer: {
      padding: 0,
    },
    upgradeBanner: {
      width: "100%",
    },
  })
);
