import React from "react";
import {
  Box, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Pagination from '@material-ui/lab/Pagination';
import XpandLibraryController from './XpandLibraryController.web';
import SearchIcon from '@material-ui/icons/Search';
// Customizable Area Start
const play = require("../assets/Object.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")
const filterimg = require("../assets/filter.png")



// Customizable Area End

export default class XpandLibrary extends XpandLibraryController {
  //   constructor() {
  // super();
  // Customizable Area Start
  // Customizable Area End
  //   }

  // Customizable Area Start

  // Customizable Area End


  render() {
    return (
      <>
        <Grid container style={{ paddingTop: "0px" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className="profile_header" style={{ display: 'contents' }}>Xpand Collection</Typography>
            <Grid container style={{ marginTop: '30px' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField id="search-course-input" className="inputRounded" inputProps={{ maxlength: 50 }} onChange={this.getSearchData} value={this.state.search} style={{ width: '373px', borderRadius: '12px' }} placeholder=" Search" variant="outlined" />
                <Box style={{ marginTop: '-38px', marginLeft: '8px' }}>
                  <SearchIcon />
                </Box>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "25px" }}>
              {this.state.allXpandLibararyData?.data?.length !== 0 ?
                this.state.allXpandLibararyData.data?.map((data: any, index: any) => {
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                      <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }}>
                        <CardMedia
                          style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                          image={data?.attributes?.thumbnail_image?.url}
                        />
                        <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                          <Typography
                            id="handle-title-click"
                            style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                            onClick={() => this.handleNavigate(data)}
                          >
                            {data?.attributes?.title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p"
                            style={{ margin: 'auto', marginTop: '-15px', padding: '0 25px', color: 'black' }}>
                            {data?.attributes?.created_by}
                          </Typography>

                          <Typography variant="body2" color="textSecondary" component="p"
                            style={{
                              margin: 'auto', fontSize: '12px', marginBottom: '10px', padding: '0 25px',
                              color: '#44444F'
                            }}>
                            Due in {data?.attributes?.course_timeline} Days
                          </Typography>

                          {data?.attributes?.is_paid ?
                            <Card style={{
                              width: '110px', height: '45px', borderRadius: '30px', float: 'right',
                              marginTop: '-2px', marginRight: '20px', background: '#1D1F4F'
                            }}>
                              {data.attributes.subscription_period === "one_time_purchase" ?
                                <CardActionArea onClick={
                                  (e: any) => {
                                    e.stopPropagation();
                                    this.addCartItem(data.id, data.attributes.expand_type)
                                  }
                                }
                                  id="one-time-purchase-btn"
                                >
                                  <img src={shop} style={{ margin: 'auto', padding: '27px', display: 'flex', marginTop: '-21px', width: '80%', height: 'auto' }} />
                                </CardActionArea> :
                                <CardActionArea
                                  onClick={
                                    (e: any) => {
                                      e.stopPropagation();
                                      this.addCartItem(data?.id, data?.attributes?.expand_type)
                                    }
                                  }
                                  id="add-to-cart-btn"
                                >
                                  <Typography style={{ display: 'flex', width: '100%', lineHeight: '45px', justifyContent: 'center', color: '#fff' }}>Subscribe</Typography>
                                </CardActionArea>
                              }

                            </Card>
                            :
                            <>
                              {!data?.attributes?.is_enrolled && <Card style={{
                                width: '87px', height: '45px', borderRadius: '30px', float: 'left',
                                marginTop: '-2px', marginLeft: '25px', background: '#EF2B4B'
                              }}>
                                <CardActionArea
                                  id="post-xpand-data-btn"
                                  onClick={() => this.postXpandLibraryData(data?.id, data?.attributes?.expand_type)}
                                >
                                  <img src={group} style={{ margin: 'auto', padding: '14px', display: 'flex' }} />
                                </CardActionArea>
                              </Card>
                              }

                              <Card style={{
                                width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                                marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                              }}>
                                <CardActionArea
                                  id="post-xpand-data-play-btn"
                                  onClick={() => this.postplayButton(data)}>
                                  <img src={play} style={{ margin: 'auto', padding: '8px', display: 'flex' }} />
                                </CardActionArea>
                              </Card>
                            </>}
                        </Paper>
                      </Box>
                    </Grid>
                  )
                }) :
                <h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2>}
            </Grid>

          </Grid>

        </Grid>
        <Paper style={{ marginTop: '25px', height: '50px' }}>
          <Pagination
            count={this.state.allXpandLibararyData?.meta?.pagination?.total_pages}
            page={this.state.page}
            color="primary"
            onChange={this.pagination}
            style={{ display: 'flex', justifyContent: 'space-around' }}
          />
        </Paper>
      </>



    )

  }

}
