// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';

interface CountryData {
  id: string,
  type: string,
  attributes: {
    name: string,
    emoji_flag: string,
    country_code: string
  }
}

interface UserAccountDetails {
  id: string;
  type: string;
  attributes: {
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
    fullPhoneNumber: string;
    phoneNumber: string;
    fullName: string;
    flagCode: string;
  };
}

export interface ResponseContentData {
  id: string
  data: [];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location?: any;
  classes: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  userId: string;
  lastName: string;
  email: string;
  countryCode: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  open: boolean;
  showPassword: boolean;
  CountryFlagCode: Array<CountryData>;
  selectOpen: boolean;
  userAccountDetails: UserAccountDetails;
  token: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountSetupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  putSetupRegisteredApiCallIds: string = "";
  getCountryCodeFlagCallIds: string = "";
  getSetupAccountDetailsApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      userId: "",
      lastName: "",
      email: "",
      countryCode: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      open: false,
      showPassword: false,
      CountryFlagCode: [],
      selectOpen: false,
      userAccountDetails: {
        id: "",
        type: "",
        attributes: {
          countryCode: "",
          email: "",
          firstName: "",
          lastName: "",
          fullPhoneNumber: "",
          phoneNumber: "",
          fullName: "",
          flagCode: "",
        },
      },
      token: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getSetupAccountDetailsApiCallId === apiRequestCallId) {
        if (responseJson) {
          const formattedPhone = this.formatEditPhoneNumber(
            responseJson.data.attributes.phone_number
          );
          this.setState({
            userAccountDetails: responseJson.data,
            userId: responseJson.data.id,
            email: responseJson.data.attributes.email,
            firstName: responseJson.data.attributes.first_name,
            lastName: responseJson.data.attributes.last_name,
            countryCode: responseJson.data.attributes.flag_code,
            phone: formattedPhone,
          })
        } else {
          toast.error("Url not valid", { autoClose: 2000 });
        }
      } else if (this.getCountryCodeFlagCallIds === apiRequestCallId) {
        this.getCountryRes(responseJson)
      } else if (this.putSetupRegisteredApiCallIds === apiRequestCallId) {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg)
      }
      else if (responseJson && responseJson.errors) {
        toast.error("Something went wrong", { autoClose: 2000 });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.CountryCodeApi();
    const url = new URLSearchParams(window.location.search)
    const token = url.get("token")
    this.setState({ token: token || "" }, () => {
      this.getSetupAccountDetails();
    })
  }

  getRegistered(data: any) {
    const { userId } = this.state;
    this.doRegisterUser({
      contentType: configJSON.formDataContentType,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.setupAccountEditDetailsApiEndPoint}/${userId}`,
      body: JSON.stringify(data)
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleEditPhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const formattedPhoneNumber = this.formatEditPhoneNumber(event.target.value);
    this.setState({ phone: formattedPhoneNumber }); 
  };

  formatEditPhoneNumber = (phoneNumber: string) => {
    const phoneNumberCleaned = phoneNumber.replace(/\D/g, "");
    if (phoneNumberCleaned.length === 0) {
      return "";
    }
    if (phoneNumberCleaned.length <= 3) {
      return `(${phoneNumberCleaned}`;
    }
    if (phoneNumberCleaned.length <= 6) {
      return `(${phoneNumberCleaned.slice(0, 3)}) ${phoneNumberCleaned.slice(3)}`;
    }
    return `(${phoneNumberCleaned.slice(0, 3)}) ${phoneNumberCleaned.slice(
      3,
      6
    )} ${phoneNumberCleaned.slice(6, 10)}`;
  };

  handleSubmit(user: any) {
    const formattedPhoneNumber = this.cleanFormatPhoneNumber(user.mobileNumber);
    let payload = {
      email: user.email,
      full_phone_number: "1" + formattedPhoneNumber,
      first_name: user.firstName,
      last_name: user.lastName,
      password: user.password,
      confirm_password: user.confirmPassword,
    };
    this.getRegistered(payload);
  }

  cleanFormatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, "");
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderFirstNameError = (formikProps: any) => {
    const { touched, errors } = formikProps;
    if (!touched.firstName || !errors.firstName) return null;
  
    return (
      <p
        style={{
          fontSize: "0.75rem",
          marginTop: "2px",
          color: "#f44336",
          height: "20px",
          width: "315px"
        }}
      >
        {errors.firstName}
      </p>
    );
  };

  renderEditLastNameError = (formikProps: any) => {
    const { touched, errors } = formikProps;
    if (!touched.lastName || !errors.lastName) return null;
  
    return (
      <p
        style={{
          fontSize: "0.75rem",
          marginTop: "2px",
          color: "#f44336",
          height: "20px",
          width: "315px"
        }}
      >
        {errors.lastName}
      </p>
    );
  };

  renderEditPhoneError = (formikProps: any) => {
    const { touched, errors } = formikProps;
    if (!touched.mobileNumber || !errors.mobileNumber) return null;
  
    return (
      <p
        style={{
          fontSize: "0.75rem",
          marginTop: "3px",
          color: "#f44336",
        }}
      >
        {errors.mobileNumber}
      </p>
    );
  };

  renderPasswordError = (formikProps: any) => {
    const { touched, errors } = formikProps;
    if (!touched.password || !errors.password) return null;
  
    return (
      <p
        style={{
          fontSize: "0.75rem",
          marginTop: "2px",
          color: "#f44336",
          height: "20px",
        }}
      >
        {errors.password}
      </p>
    );
  };

  renderConfirmPasswordError = (formikProps: any) => {
    const { touched, errors } = formikProps;
    if (!touched.confirmPassword || !errors.confirmPassword) return null;
  
    return (
      <p
        style={{
          fontSize: "0.75rem",
          marginTop: "2px",
          color: "#f44336",
          height: "20px",
        }}
      >
        {errors.confirmPassword}
      </p>
    );
  };

  handleSelectOpen = () => {
    this.setState({ selectOpen: true })
  };

  handleSelectClose = () => {
    this.setState({ selectOpen: false })
  };

  doRegisterUser(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.putSetupRegisteredApiCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  CountryCodeApi = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCountryCodeFlagCallIds = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CountryFlagApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getSetupAccountDetails = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getSetupAccountDetailsApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setupAccountDetailsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCountryRes = (responseJSON: ResponseContentData) => {
    this.setState({ CountryFlagCode: responseJSON.data })
  }
}
// Customizable Area End