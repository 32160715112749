import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { UploadVideoBody, VideoData } from "./types";
import { sortTableData } from "../../utilities/src/TableHelpers";
import { toast } from "react-toastify";
import { ChangeEvent, LegacyRef, createRef } from "react";
import ReactPlayer from "react-player";
interface Pagination {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

interface Meta {
  pagination: Pagination;
}

export interface CheckboxSelectedListType { value: string, id: string }


interface PaginationNew {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

export interface InvalidResponseType {
  errors: {
    message: string;
  }[];
}

export interface Listing  {
      id: number,
      type: string,
      attributes: {
        first_name: string,
        last_name: string,
        email: string,
        profession: string,
        avatar: null,
        is_admin: true
}
}

export interface ResponseContentData {
  id: string
  data: [];
  message: ""
  meta: {
    pagination: Pagination;
  };
};

interface ContentItem {
  id: string;
  title: string;
  content_type: string;
  created_at: string;
  expert_name: string;
  description: string;
  thumbnail: {
    id: string;
    url: string;
  };
  [key: string]: string | { id: string; url: string };
}

  export interface ContentData {
        id: number,
        type: string,
        attributes: {
          id: string,
          title: string,
          content_type?: string,
          created_at: string,
          expert_name?: string,
          description?: string,
          thumbnail?: {
            id: string,
            url: string
          },
          image?:{
            id: string,
            url: string,
            filename:string
        },
          created_by?:string,
          created_on?: string,
          courses_or_programs?:  {
            id: number;
            title: string;
            type: string;
            course_title?: string;
          }[],
          contents?: 
            {
                id: number;
                title: string;
                type:  string;
            }[],
            rewards?:{id:number, name:string}[],
            achievements?:{id:number, name:string}[]
        }  
  }

  export interface ContentItemDetail {
    data:{
      id: string | number,
      type: string,
      attributes: {
        id: string | number,
        title: string,
        description: string,
        content_type: string,
        expert_type: string,
        expert_id: string,
        attachment_key: string | null,
        attachment_status: string,
        created_at: string,
        updated_at: string,
        file_extension?:string;
        attachment: {
          url: string;
          filename?:string
        },
        thumbnail: {
          id: string,
          url: string,
        },
        thumbnail_image_id?:string;
        expert_name: string,
        tags: {
          id: number,
          name: string
        }[],
        lessons: {lesson_title:string,course_title:string,id:string}[]
      }
    }
    
  };

  export interface ExpertAccount {
    id: string;
    type: string;
    attributes: {
        name: string;
        last_name: string;
        email: string;
        profession: string;
        avatar?: {
            id: number;
            url: string;
        } | null;
        is_admin: boolean;
    };
}



  interface NumberArray {
    map(arg0: (id: number) => string): unknown;
    length: number;
    [index: number]: number;
}

export interface CalendarValue {
  0: string | number | Date;
  1: string | number | Date;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  data?: VideoData[]
  themeData?: { id: string, title: string }[];
  themeItem?: { id: string, title: string }
  handleSelectTheme?: Function
  handleBack?: Function
  selectedTheme?: string
  uploadVideo: Function,
  handleItemLoad? : Function
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  isItemLoadStart: boolean,
  themeData: {id: string, title: string}[];
  isThemeSelected: boolean;
  selectedTheme: string;
  videoListingData:VideoData[];
  meta: Meta;
  bulkDelete: NumberArray;
  isContentModal: boolean;
  contentItemDetail: ContentItemDetail;
  showFullDescription: boolean;
  numOfPages: number;
  currPageNumber: number;
  fullScreen: boolean;
  latestId: number;
  deleteModalOpen: boolean;
  deleteAllOpen: boolean;
  pagination: PaginationNew;
  contentUserData: Array<ContentData> | null;
  openContentNavigate: boolean;
  anchorFilterContentEl: null | HTMLElement;
  createrNameListing:Array<ExpertAccount>;
  tagsListing: Array<ExpertAccount>;
  tagsSelectedItems: Array<CheckboxSelectedListType>;
  anchorEl: HTMLElement | null;
  dropdownType: string;
  isCalendarOpen: boolean;
  tagsSearchText: string;
  creatorSearchText:string;
  creationSelectedItems: Array<{
    value: string, id: string
  }>;
  contentCreationDate: Array<CheckboxSelectedListType>;
  fileSelectedItems: Array<{value:string, id:string}>;
  tagParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  creationNameParams: string,
  fileTypeParams: string;
  searchContentValue: string;
  showContentList: boolean;
  filteredContentList: Array<string>
  RemoveDeleteButton:boolean;
  videoCurrentTime:number;
  isVideoPlaying:boolean;
  isActionButtonShow:boolean;
  isVideomute:boolean;
  videoDuration:number;
  playbackRate:number;
  pdfActivePage:number;
  pdfTotalPages:number;
  isAudioPlaying:boolean;
  audioLessonTotalDuration:number;
  audioActiveTime:number;
  isAudioMuted:boolean;
  speedData:number[];
  isFullScreenView:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getThemeApiID: string = "";
  getAllVideosFromSelectedTheme: string = ""
  sendPostApiCallId: string = ""
  getCotentLibrayListingApiCallId: string = "";
  getTagListingApiCallId: string = "";
  getCreaterNaListingApiCallId: string = "";
  deleteContentApiCallId: string = "";
  deleteBulkContentApiCallId: string = "";
  contentDetailApiCallId: string = "";
  getCotentAdvanceSearchApiCallId: string = ""
  myRef: LegacyRef<ReactPlayer> | undefined;
  videoPlayerRef: any;
  videoContainerRef: any;
  audioRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.myRef = createRef();
    this.videoPlayerRef = createRef();
    this.audioRef = createRef();
    this.videoContainerRef = createRef<HTMLDivElement>();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: true,
      isItemLoadStart: false,
      themeData: [],
      isThemeSelected: false,
      selectedTheme: "",
      videoListingData: [],
      
      meta: {
        pagination: {
          current_page: 0,
          next_page: null,
          prev_page: null
        }
      },
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      },
      openContentNavigate: false,
      bulkDelete: [],
      contentUserData: [],
      isContentModal: false,
      showFullDescription: false,
      contentItemDetail:{
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            title: "",
            description: "",
            content_type: "",
            file_extension:"",
            expert_type: "",
            expert_id: "",
            attachment_key: "",
            attachment_status: "",
            created_at: "",
            updated_at: "",
            expert_name: "",
            thumbnail: {
              id: "",
              url: ""
            },

            attachment: {
              url: ""
            },
            tags: [
              {
                id: 0,
                name: "",
              }
            ],
            lessons: []
          }
        },
      },
      numOfPages: 0,
      currPageNumber: 2,
      fullScreen: false,
      latestId: 0,
      deleteModalOpen: false,
      deleteAllOpen: false,
      anchorFilterContentEl: null,
      createrNameListing: [],
      tagsListing: [],
      tagsSelectedItems: [],
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      tagsSearchText: "",
      creatorSearchText: "",
      creationSelectedItems: [],
      contentCreationDate: [],
      fileSelectedItems: [],
      tagParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      creationNameParams: "",
      fileTypeParams: "",
      searchContentValue: "",
      showContentList: false,
      filteredContentList: [],
      RemoveDeleteButton:false,
      videoCurrentTime:0,
      isVideoPlaying:false,
      isActionButtonShow:true,
      isVideomute:false,
      videoDuration:0,
      playbackRate:1,
  pdfActivePage:1,
  pdfTotalPages:0,
  isAudioPlaying:false,
  audioLessonTotalDuration:0,
  audioActiveTime:0,
  isAudioMuted:false,
  speedData: [1,1.25,1.5,1.75,2],
  isFullScreenView:false
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar){
      const value=message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar
      toast.success(value, { autoClose: 2000 });
    }
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

if (apiRequestCallId === this.getThemeApiID || apiRequestCallId === this.getAllVideosFromSelectedTheme || apiRequestCallId === this.sendPostApiCallId) {
  this.setState({ isLoading: false });
  if (errorResponse) {
    this.showAlert("Error", configJSON.errorRepostMessage);
  } else {
    if (apiRequestCallId === this.getThemeApiID) {
      this.setState({ themeData: responseJson.data });
    } else if (apiRequestCallId === this.getAllVideosFromSelectedTheme) {
      this.setState({ videoListingData: responseJson.data });
      this.state.videoListingData.forEach(item => {
        item.isItemLoading = false;
      });
    } else if (apiRequestCallId === this.sendPostApiCallId) {
      this.showAlert("Success", `${responseJson.data.attributes.title} uploaded successfully`);
    }
  }
  this.parseApiCatchErrorResponse(errorResponse);
}
if (this.isValidResponse(responseJson)) {
  this.apiSuccessCallBacks(apiRequestCallId, responseJson);
}

if (this.isValidResponseTwo(responseJson)) {
  this.apiSuccessCallBacksTwo(apiRequestCallId, responseJson);
}

if (this.isInValidResponse(responseJson)) {
  this.apiFailureCallBacks(apiRequestCallId);
}
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  gotoContent = (type:string) => {
    sessionStorage.setItem("createContentType",type)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BulkUploading");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationBulkUploadingMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  
  gotoEditContent = (type:string,contentid:number) => {
    sessionStorage.setItem("createContentType",type)
    sessionStorage.setItem("editContentId",JSON.stringify(contentid))
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EditBulkUploading");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationBulkUploadingMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }; 
  async componentDidMount() {
    super.componentDidMount();
    this.getContentLibraryListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)
    this.getAdminTagListing();
    this.getAdminCreaterListing();
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('fullscreenchange', this.handleFullScreenChange);
  }

  isValidResponse = (responseJson: ResponseContentData) => {
    return responseJson && (responseJson?.data || responseJson?.data === null) || responseJson?.message ;
  };


  isValidResponseTwo = (responseJson: ContentItemDetail) => {
    return responseJson && responseJson.data ;
  }

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson.errors;
  };


  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ResponseContentData) => {
    
    if (this.getCotentLibrayListingApiCallId === apiRequestCallId) {
      const pagination: PaginationNew = {
        curPage: responseJson?.meta?.pagination?.current_page,
        nextPage: responseJson?.meta?.pagination?.next_page,
        prevPage: responseJson?.meta?.pagination?.prev_page,
      }
         
      this.setState({ contentUserData: responseJson.data,meta: responseJson.meta, pagination },() =>{
      });
    }

    if(this.getCreaterNaListingApiCallId === apiRequestCallId) {
      this.setState({
        createrNameListing: responseJson.data
      })
    }

    if(this.getTagListingApiCallId === apiRequestCallId) {
      this.setState({
        tagsListing: responseJson.data
      })
    }

    if (this.deleteContentApiCallId === apiRequestCallId) {
      toast.success(responseJson.message, { autoClose: 1000 });
      this.getContentLibraryListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)
      this.setState({
        deleteModalOpen: false,
        isContentModal: false,
          videoCurrentTime:0,
      isVideoPlaying:false,
      isActionButtonShow:true,
      isVideomute:false,
      videoDuration:0,
      playbackRate:1,
  pdfActivePage:1,
  pdfTotalPages:0,
  isAudioPlaying:false,
  audioLessonTotalDuration:0,
  audioActiveTime:0,
  isAudioMuted:false,
  isFullScreenView:false
      })
    }

    if(this.deleteBulkContentApiCallId === apiRequestCallId) {
      toast.success(responseJson.message, { autoClose: 1000 });
      this.getContentLibraryListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)
      this.setState({
       deleteAllOpen: false,
       RemoveDeleteButton:false
      })
    }

    if(this.getCotentAdvanceSearchApiCallId === apiRequestCallId) {
      this.setState({
        filteredContentList: responseJson.data
      })
    }
  }

  apiSuccessCallBacksTwo = (apiRequestCallId: string, responseJson: ContentItemDetail) => {
    if(this.contentDetailApiCallId === apiRequestCallId) {
      this.setState({
        contentItemDetail: responseJson,
          isContentModal: true,
          videoCurrentTime:0,
      isVideoPlaying:false,
      isActionButtonShow:true,
      isVideomute:false,
      videoDuration:0,
      playbackRate:1,
  pdfActivePage:1,
  pdfTotalPages:0,
  isAudioPlaying:false,
  audioLessonTotalDuration:0,
  audioActiveTime:0,
  isAudioMuted:false,
  isFullScreenView:false
      })
    }
  }

  apiFailureCallBacks = (apiRequestCallId: string) => {
    
  }


  handleListItemClickFilter = (item: string) => {
    this.setState({
     searchContentValue: item,
     showContentList: false
    },() => {
      this.getContentLibraryListing({ search: this.state.searchContentValue, page: "", per: "", }, 1)
    })
}

handleInputChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
  const value: string = event.target.value;
  if(value === ""){
    this.getContentLibraryListing({ search: "", page: "", per: "", }, 1)
  }
  this.setState({
    searchContentValue: value,
    showContentList: value !== "",
  }, () => {
    if (value !== "") {
      this.getContentLibraryAdvanceSearch(value);
    }
  });
}

getContentLibraryAdvanceSearch = (value: string) => {
  this.doGetContentLibraryAdvanceSearch({
    contentType: configJSON.ApiContentType,
    method: configJSON.apiGetMethod,
    endPoint: configJSON.contentLibraryAdvanceSearch+ `?search=${value}&type=content`,
  });

}


  getContentLibraryListing(params: { search: string, page: string, per: string }, pagination: number | PaginationNew) {
    let customEndPoint = this.constructEndPoint(params, pagination);

    this.doGetCourseAndProgram({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.contentLibraryAPiEndPoint+customEndPoint,
    });

    return customEndPoint;

  }
  updateChildStates = (newState:any) => {
    this.setState({ RemoveDeleteButton: newState });
  };

constructEndPoint(params: { search: string , page: string, per: string }, pagination: number |  PaginationNew): string {
  let endPoint = `?per=${params.per}&page=${pagination}`;
  if (params.search) {
    endPoint += `&search=${params.search}`;
}
  endPoint += this.addTagParams();
endPoint += this.addcreationNameParams();
endPoint += this.addFileTypeParams();
endPoint += this.addCreationContentDateParam();
endPoint += this.addCreationContentDateRangeParam();

 return endPoint;
}

private addTagParams(): string {
  return this.state.tagParams ? `&${this.state.tagParams}` : '';
}

private addcreationNameParams(): string {
  return this.state.creationNameParams ? `&${this.state.creationNameParams}` : '';
}

private addFileTypeParams(): string {
  return this.state.fileTypeParams ? `&${this.state.fileTypeParams}` : '';
}

private addCreationContentDateParam(): string {
  const dateParam = this.getDateParam();
  return dateParam ? `&q[created_at]=${dateParam}` : ''
}

private addCreationContentDateRangeParam(): string {
const { start_date, end_date } = this.state.creationDateParams;
if (start_date && end_date) {
  const rangeObj = {
    start_date: start_date,
    end_date: end_date
  };
  const queryString = encodeURIComponent(JSON.stringify(rangeObj));
  return `&q[custom_range]=${queryString}`;
}
return '';
}

private getDateParam(): string {
  switch (this.state.contentCreationDate[0]?.value) {
    case "Last Week":
      return "last_week";
    case "Last Month":
      return "last_month";
    case "Last Year":
      return "last_year";
    default:
      return '';
  }
}

handleClickContentFilter = () => {
  this.getContentLibraryListing({ search: "", page: "", per: "", }, 1)
  this.setState({
    anchorFilterContentEl: null
  })
}


  doGetContentLibraryAdvanceSearch(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCotentAdvanceSearchApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  doGetCourseAndProgram(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCotentLibrayListingApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getThemes = () => {
    this.setState({isLoading: true});
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getThemeApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_getAllTheme
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_get
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleItemLoad = (video_id:string,state: boolean) =>{
    let newVideoLibrary : VideoData[] = []
    this.state.videoListingData.forEach((item)=>{
      if (item.video_title === video_id){
        item.isItemLoading = state
      }
      newVideoLibrary.push(item)
    })
    this.setState({
      videoListingData : newVideoLibrary
    })
  }

  getVideosFromSelectedTheme = (themeId: string) =>{
    this.setState({isLoading: true});
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllVideosFromSelectedTheme = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_getVideosFromSelectedTheme + "?theme_id=" +themeId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_get
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  uploadVideo = (body: UploadVideoBody) => {
    this.setState({isLoading: true})
    let formData = new FormData();
      formData.append("theme_id", body.theme_id);
      formData.append("title", body.title);
      formData.append("description", body.description);
      formData.append("video", body.video);
    const header = {
      "Content-Type": configJSON.contentFormData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.end_point_uploadVideo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType_post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBack = () => {
    this.setState({ isThemeSelected: false })
  }

  handleSelectTheme = async (themeId: string) => {
    this.setState({ isThemeSelected: !this.state.isThemeSelected, selectedTheme: themeId, })
    this.getVideosFromSelectedTheme(themeId);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    if (this.state.pagination.curPage !== prevState.pagination.curPage) {
      this.getContentLibraryListing({ search: "", page: "", per: "", }, this.state.pagination.curPage)
    }
  }

  handlePrevNavigation = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage - 1,
        }
      }
    })
  }

  handleClearFilter = () => {
    this.setState({
      tagsSelectedItems: [],
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false,
      tagsSearchText: "",
      creatorSearchText: "",
      creationSelectedItems: [],
      contentCreationDate: [],
      fileSelectedItems: [],
      tagParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      creationNameParams: "",
      anchorFilterContentEl: null,
      fileTypeParams: ""
    },() => {
      this.getContentLibraryListing({ search: "", page: "", per: "", }, 1)
    })
  }

  handleFilterOpen = (event:React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      anchorFilterContentEl: event?.currentTarget,
    })
  }

  filterContentDropdownCloseHandler = () => {
    this.setState({
      anchorFilterContentEl: null,
    })
  }


  getSelectedIDChecbox = (list:Array<number>) => {
    this.setState({
      bulkDelete: list
    })
  }

  getAdminCreaterListing() {
    this.doGetCreaterNameLIsting({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.contentCreaterNameAPiEndPoint,
    });
  }

  getAdminTagListing() {
    this.doGetTagListing({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.contentTagsAPiEndPoint,
    });
  }


  doGetCreaterNameLIsting(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreaterNaListingApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


  doGetTagListing(data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTagListingApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  dropdownHandlerOpen = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      dropdownType
    })
  }

  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: null,
      dropdownType: "",
      isCalendarOpen: false
    })
  }

  tagsCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        tagsSelectedItems: [...prevState.tagsSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.tagsSelectedItems.map((items: { value: string, id: string }) => `q[tag_ids][]=${items.id}`).join("&")
        this.setState({
          tagParams: params
        })
      })
    } else {
      this.setState(prevState => ({
        tagsSelectedItems: prevState.tagsSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.tagsSelectedItems.map((item: { value: string, id: string }) =>`q[tag_ids][]=${item.id}`).join("&");
        this.setState({
          tagParams: params
        });
      });
    }
  }

  handleTagsSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      tagsSearchText: event.target.value
    })
  }

  handleCreatorSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      creatorSearchText: event.target.value
    })
  }

  creationCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        creationSelectedItems: [...prevState.creationSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.creationSelectedItems.map((items: { value: string, id: string }) => `q[created_by][]=${items.id}`).join("&")
        this.setState({
          creationNameParams: params
        })
      })
    } else {
      this.setState(prevState => ({
        creationSelectedItems: prevState.creationSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.creationSelectedItems.map((item: { value: string, id: string }) =>`q[created_by][]=${item.id}`).join("&");
        this.setState({
          creationNameParams: params
        });
      });
    }
  }

  creationChangeHandlerDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      contentCreationDate:[{value: event.target.value, id: "1"}]
    })
  }

  fileCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const { checked, name } = event.target;
    if (checked) {
      this.setState(prevState => ({
        fileSelectedItems: [...prevState.fileSelectedItems, { value: name, id: itemId }]
      }), () => {
        const params = this.state.fileSelectedItems.map((items: { value: string, id: string }) => `q[file_type][]=${items.value}`).join("&")
        this.setState({
          fileTypeParams: params
        })
      })
    } else {
      this.setState(prevState => ({
        fileSelectedItems: prevState.fileSelectedItems.filter((item: { value: string, id: string }) => item.id !== itemId)
      }), () => {
        const params = this.state.fileSelectedItems.map((item: { value: string, id: string }) =>`q[file_type][]=${item.value}`).join("&");
        this.setState({
          fileTypeParams: params
        });
      });
    }
  }

  handleOpenCalendar = () => {
    this.setState({
      isCalendarOpen: true
    })
  }

  handleChangeCalendar = (value: CalendarValue) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    const formattedStartDate = startDate.getDate();
    const formattedStartDateNum = startDate.toLocaleDateString("en-US", {day: "2-digit"});
    const formattedStartMonthNum = startDate.toLocaleDateString("en-US", {month: "2-digit"});
    const formattedStartYear = startDate.getFullYear();
    const formattedEndDate = endDate.getDate();
    const formattedEndDateNum = endDate.toLocaleDateString("en-US", {day: "2-digit"});
    const formattedEndMonth = endDate.toLocaleDateString("en-US",{month:"long"});
    const formattedEndYear = endDate.getFullYear();
    const formattedEndMonthNum = endDate.toLocaleDateString("en-US", {month: "2-digit"});
    const dateRange = `${formattedStartDate}-${formattedEndDate} ${formattedEndMonth} ${formattedEndYear}`;
    const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedStartDateNum}`;
    const end_date = `${formattedEndYear}-${formattedEndMonthNum}-${formattedEndDateNum}`;
    this.setState({
      contentCreationDate:[{value: dateRange, id: "1"}],
        creationDateParams: {
            start_date,
            end_date
        }
    });
}

sortUsers = (dataType: string, keyType: string, sortType: string) => {
  const sortedUsers = sortTableData(
    this.state.contentUserData,
    dataType,
    keyType,
    sortType,
  );
  this.setState({ contentUserData: sortedUsers });
};

handleNextNavigation = () => {
  this.setState((curState) => {
    return {
      ...curState,
      pagination: {
        ...curState.pagination,
        curPage: curState.pagination.curPage + 1,
      }
    }
  })
}


handleOpen = () => {
  this.setState({
    openContentNavigate: true
  })
}

handleClose = () => {
  this.setState({
    openContentNavigate: false,
  })
}

handleCloseModal = () => {
  this.setState({
    isContentModal: false
  })
}

handleOpenModalObject = (idContentDetail: number) => {
  this.getContentItemDetail(idContentDetail)
}

getContentItemDetail(idContentDetail: number) {
  this.doContentItemDetail({
    contentType: configJSON.ApiContentType,
    method: configJSON.apiGetMethod,
    endPoint: `${configJSON.contentLibraryDetailAPiEndPoint}/${idContentDetail}`
  });
}

doContentItemDetail(data: {
contentType?: string;
method?: string;
endPoint?: string;
body?: {};
type?: string;
}) {
const { contentType, method, endPoint } = data;
const header = {
  "Content-Type": contentType,
  token: localStorage.getItem("token"),
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)

);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
this.contentDetailApiCallId = requestMessage.messageId
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;
}

toggleDescription = () => {
  this.setState(prevState => ({
    showFullDescription: !prevState.showFullDescription
  }));
};

 formatDateModal = (inputDate: string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dayFormat = date.getDate();

  return `${month < 10 ? '0' + month : month}/${dayFormat < 10 ? '0' + dayFormat : dayFormat}/${year}`;
};



deleteItemSingle = (idType: number) => {
 this.setState({
  latestId:idType,
  deleteModalOpen: true
 })  
  } 

handleDeleteContent = (latestId: number) => {
  this.deleteContentItem(latestId);
}

deleteContentItem(latestID: number) {
    this.doDeleteContentItem({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.contentLibraryDeleteApiEndPInt}/${latestID}`
    });
}

doDeleteContentItem(data: {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: {};
  type?: string;
}) {
  const { contentType, method, endPoint } = data;
  const header = {
    "Content-Type": contentType,
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.deleteContentApiCallId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

cancelContentAllDeleteDialog = () => {
  this.setState({
    deleteAllOpen: false
  })
}

handleDeleteBulk = () => {
  this.setState({
    deleteAllOpen: true
  })
}

handleDeleteAllContent = () => {
this.deleteBulkContentItem() 
}


deleteBulkContentItem() {
  const bulkDeleteIds = (this.state.bulkDelete as number[])?.map(contentId => `content_ids[]=${contentId}`).join('&');
  const endPoint = `${configJSON.contentBulkDeleteApiEndPoint}?${bulkDeleteIds}`;
  this.doDeleteBulkContentItem({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: endPoint
  });
}

doDeleteBulkContentItem(data: {
contentType?: string;
method?: string;
endPoint?: string;
body?: {};
type?: string;
}) {
const { contentType, method, endPoint } = data;
const header = {
  "Content-Type": contentType,
  token: localStorage.getItem("token"),
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)

);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
this.deleteBulkContentApiCallId = requestMessage.messageId
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;
}

cancelContentDeleteDialog = () => {
this.setState({
  deleteModalOpen: false
})
}

handleMouseLeave = () => {
  setTimeout(() => {
    this.setState({isActionButtonShow:false});
  }, 2000);
};

handleVideoEnd = () => {
  this.setState({ isVideoPlaying:false });
}

handleDuration = (duration: number) => {
  this.setState({ videoDuration :duration});
};

handleProgress = (state: { playedSeconds: number }) => {
  this.setState({ videoCurrentTime: state.playedSeconds });
};

handleToggleVideo = () => {
  this.setState({isVideoPlaying:!this.state.isVideoPlaying})
    }

    handleSeekForward = () => {
      if(this.videoPlayerRef && this.videoPlayerRef.current){
        const currentTime = this.videoPlayerRef.current.getCurrentTime();
        this.videoPlayerRef.current.seekTo(currentTime + 10);
      }
    };

    handleSeekBackward = () => {
      if(this.videoPlayerRef && this.videoPlayerRef?.current){
         const currentTime = this.videoPlayerRef?.current.getCurrentTime();
      this.videoPlayerRef.current.seekTo(currentTime - 10);
      }
    };

handleMouseEnter = () => {
  this.setState({isActionButtonShow:true});
  setTimeout(() => {
    this.setState({isActionButtonShow:false});
  }, 5000);
};

handleSeekChange = (event: React.ChangeEvent<{}>,value:number | number[]) => {
  const seekTo = value;
  this.videoPlayerRef.current?.seekTo(seekTo);
  this.setState({ videoCurrentTime: seekTo as number});
};

formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  } else {
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  }
};

handleToggleMute = () => {
  this.setState((prevState) => ({ isVideomute: !prevState.isVideomute }));
};

handleToggleAudioMute = () => {
  this.setState((prevState) => ({ isAudioMuted: !prevState.isAudioMuted }));
};

downloadFile = () => {
  const link = document.createElement('a');
  link.href = this.state.contentItemDetail?.data?.attributes?.attachment?.url;
  link.download = '';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

onDocumentLoadSuccess = ({ numPages }:any) => {
  this.setState({pdfTotalPages: numPages});
}

setPageNumber = (value:number) => {
  this.setState({pdfActivePage:value})
}

handleSeekBackwardAudio = () => {
  if(this.audioRef.current){
     const currentTime = this.audioRef.current.getCurrentTime();
  this.audioRef.current.seekTo(currentTime - 10);
  }
};

handleSeekForwardAudio = () => {
  if(this.audioRef.current){
    const currentTime = this.audioRef.current.getCurrentTime();
    this.audioRef.current.seekTo(currentTime + 10);
  }
};

handleAudioEnd = () => {
  this.setState({ isAudioPlaying:false });
}

togglePlay = () => {
  this.setState(prevState => ({ isAudioPlaying: !prevState.isAudioPlaying }));
};

handleProgressAudio = (progress:any) => {
  this.setState({
    audioActiveTime: progress.playedSeconds,
  });
};

handleAudioDuration = (duration: number) => {
  this.setState({audioLessonTotalDuration: duration });
};

handleSliderChange = (event:any, newValue:any) => {
  this.setState({ audioActiveTime: newValue });
  this.audioRef.current?.seekTo(newValue);
};

handleSpeedChange = (event:any) => {
    this.setState({ playbackRate: event.target.value });
};

handleFullScreenToggle = () => {
  const videoContainer = this.videoContainerRef.current;

  if (!this.state.isFullScreenView && videoContainer) {
    if (videoContainer.requestFullscreen) {
      videoContainer.requestFullscreen();
    } else if ((videoContainer).webkitRequestFullscreen) {
      (videoContainer).webkitRequestFullscreen();
    } else if ((videoContainer).mozRequestFullScreen) {
      (videoContainer).mozRequestFullScreen();
    } else if ((videoContainer).msRequestFullscreen) {
      (videoContainer).msRequestFullscreen();
    }

    this.setState({ isFullScreenView: true });
  } else if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }

    this.setState({ isFullScreenView: false });
  }
};

handleFullScreenChange = () => {
  const isFullScreenView = !!document.fullscreenElement;
  this.setState({ isFullScreenView });
};


async componentWillUnmount() {
  window.removeEventListener('keydown', this.handleKeyDown);
  document.removeEventListener('fullscreenchange', this.handleFullScreenChange);
}

handleKeyDown = (event:any) => {
  if (event.key === 'Escape') {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.setState({ isFullScreenView: false });
  }
};
  // Customizable Area End
}
