import React from "react";
import { Select, InputBase, MenuItem } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "24px",
      color: "#545650",
      padding: "5px 26px 5px 12px",
      paddingRight: "55px !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      boxShadow: "0px 0px 16px 0px #BFBEBE59",
      "&:focus": {
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px 0px #BFBEBE59",
      },
    },
  })
)(InputBase);

export default function SelectDropdown({
  options,
  currentValue,
  handleChange,
  name,
}: any) {
  return (
      <Select
        name={name}
        value={currentValue}
        onChange={handleChange}
        input={<BootstrapInput />}
        IconComponent={ExpandMoreIcon}
        displayEmpty
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.id}
            value={option.value}
            style={{ fontSize: 12 }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
  );
}
